import React from 'react';
import {
  AddOne,
  AlphabeticalSorting,
  ArrowLeft,
  AtSign,
  Attention,
  BranchOne,
  Check,
  CheckOne,
  CheckSmall,
  ClickToFold,
  Close,
  CloseOne,
  CloseSmall,
  CloseWifi,
  Comment,
  Comments,
  CopyOne,
  CopyLink,
  Delete,
  Detection,
  DoneAll,
  Dot,
  Down,
  Download,
  Drag,
  Edit,
  EveryUser,
  ExpandTextInput,
  FileCode,
  Filter,
  HamburgerButton,
  HandleX,
  HeadsetOne,
  Help,
  Inbox,
  Left,
  LinkOne,
  LinkTwo,
  ListTop,
  LoadingFour,
  Lock,
  Logout,
  MoreOne,
  Next,
  Paperclip,
  PayCodeOne,
  Play,
  PlayOne,
  PlayCycle,
  PreviewCloseOne,
  PreviewOpen,
  Redo,
  Refresh,
  Return,
  Right,
  RightBar,
  RobotOne,
  STurnLeft,
  Send,
  SettingTwo,
  SlightlySmilingFace,
  SpeakerOne,
  Time,
  Unlock,
  Up,
  ViewList,
} from '@icon-park/react';
import { IIconProps } from '@icon-park/react/lib/runtime';

import './index.less';

import { getDefaultIfUndefined } from '../../../../utils/typeUtil';

import { ReactComponent as Search } from './search.svg';
import { ReactComponent as Telegram } from './telegram_line.svg';
import { ReactComponent as WhatsApp } from './whatsapp_line.svg';
import { ReactComponent as Viber } from './viber_line.svg';
import { ReactComponent as LiveChat } from './livechat_line.svg';

const SIZE_DEFAULT = 24;
const COLOR_DEFAULT = '#A4A4A4';
const MAIN_CLASS_NAME = 'ib-icon';

export type IbIconName =
  | 'attention'
  | 'paperclip'
  | 'add-one'
  | 'every-user'
  | 'comment'
  | 'comments'
  | 'speaker-one'
  | 'branch-one'
  | 'inbox'
  | 'check-one'
  | 'check-small'
  | 'edit'
  | 'slightly-smiling-face'
  | 'view-list'
  | 'check'
  | 'delete'
  | 'link-two'
  | 'headset-one'
  | 'alphabetical-sorting'
  | 'at-sign'
  | 'lock'
  | 'unlock'
  | 'next'
  | 'send'
  | 'up'
  | 'done-all'
  | 'down'
  | 'search'
  | 'setting-two'
  | 'help'
  | 'logout'
  | 'pay-code-one'
  | 'filter'
  | 'close-small'
  | 'close'
  | 'more-one'
  | 'right-bar'
  | 'hamburger-button'
  | 'arrow-left'
  | 'refresh'
  | 'preview-open'
  | 'preview-close-one'
  | 'return'
  | 'close-wifi'
  | 'time'
  | 'dot'
  | 'drag'
  | 'play'
  | 'play-one'
  | 'play-cycle'
  | 'copy-one'
  | 'copy-link'
  | 'detection'
  | 'left'
  | 'right'
  | 'robot-one'
  | 'close-one'
  | 'download'
  | 'list-top'
  | 'handle-x'
  | 's-turn-left'
  | 'redo'
  | 'click-to-fold'
  | 'expand-text-input'
  | 'loading-four'
  | 'link-one'
  | 'file-code'
  | 'telegram'
  | 'viber'
  | 'whatsapp'
  | 'livechat'
  | 'directline';

export type IbIconTheme = 'outline' | 'filled' | 'two-tone' | 'multi-color';

export interface IIbIconProps {
  className?: string;
  iconName: IbIconName;
  size?: number;
  theme?: IbIconTheme;
  color?: string;
  fill?: string;
  onClick?: () => void;
}

const IbIcon: React.FC<IIbIconProps> = ({
  className,
  iconName,
  size = SIZE_DEFAULT,
  theme,
  color = COLOR_DEFAULT,
  fill,
  onClick,
}) => {
  size = getDefaultIfUndefined(size, SIZE_DEFAULT);
  color = getDefaultIfUndefined(color, COLOR_DEFAULT);

  const classes = [MAIN_CLASS_NAME];
  className && classes.push(className);

  const props = {
    className: classes.join(' '),
    style: {
      width: size,
      height: size,
      color,
    },
    theme,
    fill,
    onClick,
  } as IIconProps;

  switch (iconName) {
    case 'attention':
      return <Attention {...props} />;
    case 'paperclip':
      return <Paperclip {...props} />;
    case 'add-one':
      return <AddOne {...props} />;
    case 'every-user':
      return <EveryUser {...props} />;
    case 'comment':
      return <Comment {...props} />;
    case 'comments':
      return <Comments {...props} />;
    case 'speaker-one':
      return <SpeakerOne {...props} />;
    case 'branch-one':
      return <BranchOne {...props} />;
    case 'inbox':
      return <Inbox {...props} />;
    case 'check-one':
      return <CheckOne {...props} />;
    case 'check-small':
      return <CheckSmall {...props} />;
    case 'edit':
      return <Edit {...props} />;
    case 'slightly-smiling-face':
      return <SlightlySmilingFace {...props} />;
    case 'view-list':
      return <ViewList {...props} />;
    case 'check':
      return <Check {...props} />;
    case 'delete':
      return <Delete {...props} />;
    case 'done-all':
      return <DoneAll {...props} />;
    case 'link-two':
      return <LinkTwo {...props} />;
    case 'headset-one':
      return <HeadsetOne {...props} />;
    case 'alphabetical-sorting':
      return <AlphabeticalSorting {...props} />;
    case 'at-sign':
      return <AtSign {...props} />;
    case 'lock':
      return <Lock {...props} />;
    case 'unlock':
      return <Unlock {...props} />;
    case 'next':
      return <Next {...props} />;
    case 'send':
      return <Send {...props} />;
    case 'up':
      return <Up {...props} />;
    case 'down':
      return <Down {...props} />;
    case 'search':
      return (
        <span {...props}>
          <Search />
        </span>
      );
    case 'telegram':
      return (
        <span {...props}>
          <Telegram />
        </span>
      );
    case 'viber':
      return (
        <span {...props}>
          <Viber />
        </span>
      );
    case 'whatsapp':
      return (
        <span {...props}>
          <WhatsApp />
        </span>
      );
    case 'livechat':
    case 'directline':
      return (
        <span {...props}>
          <LiveChat />
        </span>
      );
    case 'setting-two':
      return <SettingTwo {...props} />;
    case 'help':
      return <Help {...props} />;
    case 'logout':
      return <Logout {...props} />;
    case 'pay-code-one':
      return <PayCodeOne {...props} />;
    case 'filter':
      return <Filter {...props} />;
    case 'close-small':
      return <CloseSmall {...props} />;
    case 'close':
      return <Close {...props} />;
    case 'more-one':
      return <MoreOne {...props} />;
    case 'right-bar':
      return <RightBar {...props} />;
    case 'hamburger-button':
      return <HamburgerButton {...props} />;
    case 'arrow-left':
      return <ArrowLeft {...props} />;
    case 'refresh':
      return <Refresh {...props} />;
    case 'preview-open':
      return <PreviewOpen {...props} />;
    case 'preview-close-one':
      return <PreviewCloseOne {...props} />;
    case 'return':
      return <Return {...props} />;
    case 'close-wifi':
      return <CloseWifi {...props} />;
    case 'time':
      return <Time {...props} />;
    case 'dot':
      return <Dot {...props} />;
    case 'drag':
      return <Drag {...props} />;
    case 'play':
      return <Play {...props} />;
    case 'play-one':
      return <PlayOne {...props} />;
    case 'play-cycle':
      return <PlayCycle {...props} />;
    case 'copy-one':
      return <CopyOne {...props} />;
    case 'copy-link':
      return <CopyLink {...props} />;
    case 'detection':
      return <Detection {...props} />;
    case 'left':
      return <Left {...props} />;
    case 'right':
      return <Right {...props} />;
    case 'robot-one':
      return <RobotOne {...props} />;
    case 'close-one':
      return <CloseOne {...props} />;
    case 'download':
      return <Download {...props} />;
    case 'list-top':
      return <ListTop {...props} />;
    case 'handle-x':
      return <HandleX {...props} />;
    case 's-turn-left':
      return <STurnLeft {...props} />;
    case 'redo':
      return <Redo {...props} />;
    case 'click-to-fold':
      return <ClickToFold {...props} />;
    case 'expand-text-input':
      return <ExpandTextInput {...props} />;
    case 'loading-four':
      return <LoadingFour {...props} />;
    case 'link-one':
      return <LinkOne {...props} />;
    case 'file-code':
      return <FileCode {...props} />;
    default:
      return null;
  }
};

export default IbIcon;
