import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useSetRecoilState } from 'recoil';
import { Resizable } from 're-resizable';
import Skeleton from 'react-loading-skeleton';

import './index.less';

import { renderActiveDialogCount, renderDialogCount } from '../../../utils/stringUtil';
import IbButton from '../../components/common/IbButton';
import IbIcon from '../../components/common/IbIcon';
import IbTag from '../../components/common/IbTag';
import { agentApi, botApi, conversationApi } from '../../../apis';
import { AlertTypes } from '../../../constants';
import { ConversationModel, ConversationStatus } from '../../../../api';
import { alertsSelectorAdd } from '../../../recoil/alerts';
import DialogHistory from '../../../simple-bot/pages/dialogs/DialogsCard/DialogHistory';
import { useQuery } from '../../../utils/urlUtil';

import DialogList from './DialogList';
import {
  CONTENT_ROW_CLASS_NAME,
  DIALOG_LIST_CLASS_NAME,
  HEADER_BACK_BUTTON_CLASS_NAME,
  HEADER_CLASS_NAME,
  HEADER_CONTENT_CLASS_NAME,
  HEADER_CONTENT_DESCRIPTION_CLASS_NAME,
  HEADER_CONTENT_TITLE_CLASS_NAME,
  HEADER_UPDATE_BUTTON_CLASS_NAME,
  MAIN_CLASS_NAME,
  RESIZABLE_COLUMN_CLASS_NAME,
} from './const';

interface IDialogsPageProps {
  isInbox?: boolean;
}

const DialogsPage: React.FC<IDialogsPageProps> = ({ isInbox }) => {
  const addAlert = useSetRecoilState(alertsSelectorAdd);
  const { id } = useParams<{ id: string }>();
  const { push } = useHistory();
  const query = useQuery();

  const selectedConversationId = query.get('conversationId');

  const [forceUpdate, setForceUpdate] = useState(false);
  const [loading, setLoading] = useState(true);
  const [agentStageId, setAgentStageId] = useState<string>();
  const [selectedConversation, setSelectedConversation] = useState<ConversationModel>();
  const [totalConversationCount, setTotalConversationCount] = useState(0);
  const [activeConversationCount, setActiveConversationCount] = useState(0);

  const loadDialogsCount = async (agentStageId: string) => {
    try {
      const totalConversationCountResponse = await conversationApi.getConversationCount(undefined, agentStageId);
      setTotalConversationCount(totalConversationCountResponse.data);

      const conversationStatus = ConversationStatus.Closed;
      const closedConversationCountResponse = await conversationApi.getConversationCount(
        undefined,
        agentStageId,
        undefined,
        undefined,
        conversationStatus
      );
      setActiveConversationCount(totalConversationCountResponse.data - closedConversationCountResponse.data);
    } catch (e) {
      addAlert({
        type: AlertTypes.ERROR,
        message: 'Ошибка при загрузке количества диалогов',
        error: e,
      });
    }
  };

  const loadAgentStageId = async () => {
    try {
      const botResponse = await botApi.getBot(id);
      const agentResponse = await agentApi.getAgent(botResponse.data.originStage.agentId);
      return agentResponse.data.productionAgent.id;
    } catch (e) {
      addAlert({
        type: AlertTypes.ERROR,
        message: 'Ошибка при загрузке данных бота',
        error: e,
      });
    }
  };

  const loadDataAsync = async () => {
    const agentStageId = await loadAgentStageId();
    if (agentStageId) {
      setAgentStageId(agentStageId);
      await loadDialogsCount(agentStageId);
    }
    setLoading(false);
  };

  const loadData = () => {
    setLoading(true);
    loadDataAsync().finally();
  };
  useEffect(loadData, [id]);

  const loadConversationAsync = async () => {
    if (!selectedConversationId) {
      setSelectedConversation(undefined);
      return;
    }

    try {
      const conversationResponse = await conversationApi.getConversation(selectedConversationId);
      setSelectedConversation(conversationResponse.data);
    } catch (e) {
      addAlert({
        type: AlertTypes.ERROR,
        message: 'Ошибка при загрузке данных диалога',
        error: e,
      });
    }
  };

  const loadConversationData = () => {
    loadConversationAsync().finally();
  };
  useEffect(loadConversationData, [selectedConversationId]);

  const onBackButtonClick = () => {
    if (isInbox) {
      push(`/inbox/bots/${id}`);
      return;
    }
    push(`/simple-bots/${id}`);
  };

  const renderDialogList = () => {
    return (
      <div className={DIALOG_LIST_CLASS_NAME}>
        <DialogList agentStageId={agentStageId} forceUpdate={forceUpdate} />
      </div>
    );
  };

  const onPageUpdateClick = () => {
    setForceUpdate(!forceUpdate);
    loadData();
  };

  return (
    <div className={MAIN_CLASS_NAME}>
      <div className={HEADER_CLASS_NAME}>
        <div className={HEADER_BACK_BUTTON_CLASS_NAME}>
          <IbButton icon={<IbIcon iconName="left" />} type="link" onClick={onBackButtonClick} />
        </div>
        <div className={HEADER_CONTENT_CLASS_NAME}>
          <div className={HEADER_CONTENT_TITLE_CLASS_NAME}>
            Диалоги
            {loading ? <Skeleton width={100} /> : <IbTag content={renderActiveDialogCount(activeConversationCount)} />}
          </div>
          <div className={HEADER_CONTENT_DESCRIPTION_CLASS_NAME}>
            {loading ? <Skeleton width={80} /> : renderDialogCount(totalConversationCount)}
          </div>
        </div>
        <div className={HEADER_UPDATE_BUTTON_CLASS_NAME}>
          <IbButton icon={<IbIcon iconName="refresh" />} type="icon-bordered" onClick={onPageUpdateClick} />
        </div>
      </div>
      <div className={CONTENT_ROW_CLASS_NAME}>
        <Resizable className={RESIZABLE_COLUMN_CLASS_NAME} defaultSize={{ width: 360 }} enable={{ right: true }}>
          {renderDialogList()}
        </Resizable>
        <DialogHistory conversation={selectedConversation} forceReload={forceUpdate} />
      </div>
    </div>
  );
};

export default DialogsPage;
