import React from 'react';
import { convertFromRaw, EditorState, CompositeDecorator, DraftDecorator, Modifier } from 'draft-js';
import { markdownToDraft } from 'markdown-draft-js';
import { findWithRegex } from '@draft-js-plugins/utils';

import { EntityMutability, EntityType, getImageDecorator, getLinkDecorator } from '../../../../utils/markdownEditor';
import { VariableSchema } from '../../../../../api';
import { NEW_LINE_DIVIDER } from '../../../../constants/editors';

const variablesRegex = new RegExp('{.*?}', 'gi');

export const getDecorators = (search?: string, variables?: VariableSchema[]): DraftDecorator[] => {
  const highlightDecorator: DraftDecorator = {
    strategy: (block, callback) => {
      if (search) {
        // eslint-disable-next-line security/detect-non-literal-regexp
        const highlightRegex = new RegExp(search, 'gi');
        findWithRegex(highlightRegex, block, callback);
      }
    },

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    component: (props: any) => {
      return (
        <span className="highlighted" data-offset-key={props.offsetKey}>
          {props.children}
        </span>
      );
    },
  };

  const variableDecorator: DraftDecorator = {
    strategy: (contentBlock, callback) => {
      if (variables) {
        // eslint-disable-next-line security/detect-non-literal-regexp
        findWithRegex(new RegExp(variablesRegex, 'gi'), contentBlock, callback);
      }
    },

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    component: (props: any) => {
      const variableId = props.decoratedText.replace('{', '').replace('}', '');
      const variable = variables?.find((v) => v.id === variableId);
      return variable ? (
        <span className="variable-container" contentEditable={false} data-offset-key={props.offsetKey}>
          &nbsp;{variable.name}&nbsp;
        </span>
      ) : (
        props.children
      );
    },
  };

  return [getLinkDecorator(), getImageDecorator(), highlightDecorator, variableDecorator];
};

export const insertEntity = (editorState: EditorState, value: string, type: string): EditorState => {
  const contentState = editorState.getCurrentContent();
  const contentStateWithEntity = contentState.createEntity(type, EntityMutability.IMMUTABLE);
  const entityKey = contentStateWithEntity.getLastCreatedEntityKey();
  const currentSelectionState = editorState.getSelection();

  const afterRemovalContentState = Modifier.removeRange(contentState, currentSelectionState, 'backward');

  const targetSelection = afterRemovalContentState.getSelectionAfter();

  let resultContentState = Modifier.insertText(afterRemovalContentState, targetSelection, value, undefined, entityKey);
  resultContentState = Modifier.insertText(resultContentState, resultContentState.getSelectionAfter(), ' ');

  const newEditorState = EditorState.push(editorState, resultContentState, 'insert-fragment');
  return EditorState.forceSelection(newEditorState, resultContentState.getSelectionAfter());
};

export const markdownToEditorState = (
  markdown: string,
  readOnly: boolean,
  search?: string,
  variables?: VariableSchema[]
): EditorState => {
  const preparedMarkdown = markdown
    .split(NEW_LINE_DIVIDER)
    .map((line) => line.trimEnd())
    .join(NEW_LINE_DIVIDER);
  const draft = markdownToDraft(preparedMarkdown);
  let contentState = convertFromRaw(draft);
  const editorState = EditorState.createWithContent(contentState);
  const currentSelectionState = editorState.getSelection();

  for (const block of contentState.getBlocksAsArray()) {
    const text = block.getText();
    const allMatches = Array.from(text.matchAll(variablesRegex));
    for (const match of allMatches) {
      const contentStateWithEntity = contentState.createEntity(EntityType.VARIABLE, EntityMutability.IMMUTABLE);
      const entityKey = contentStateWithEntity.getLastCreatedEntityKey();

      const selectionState = currentSelectionState.merge({
        hasFocus: false,
        focusKey: block.getKey(),
        anchorKey: block.getKey(),
        anchorOffset: match.index,
        focusOffset: (match.index || 0) + match[0].length,
      });

      contentState = Modifier.replaceText(contentState, selectionState, match[0], undefined, entityKey);
    }

    if (text.endsWith('}')) {
      contentState = Modifier.insertText(contentState, contentState.getSelectionAfter(), ' ');
    }
  }

  if (readOnly) {
    return EditorState.createWithContent(contentState, new CompositeDecorator(getDecorators(search, variables)));
  }

  return EditorState.createWithContent(contentState, new CompositeDecorator(getDecorators(search, variables)));
};
