export const MAIN_CLASS_NAME = 'ib-dialogs-page';
export const CONTENT_ROW_CLASS_NAME = `${MAIN_CLASS_NAME}__content-row`;
export const HEADER_CLASS_NAME = `${MAIN_CLASS_NAME}__header`;
export const HEADER_BACK_BUTTON_CLASS_NAME = `${HEADER_CLASS_NAME}__back-button`;
export const HEADER_CONTENT_CLASS_NAME = `${HEADER_CLASS_NAME}__content`;
export const HEADER_CONTENT_TITLE_CLASS_NAME = `${HEADER_CONTENT_CLASS_NAME}__title`;
export const HEADER_CONTENT_DESCRIPTION_CLASS_NAME = `${HEADER_CONTENT_CLASS_NAME}__description`;
export const HEADER_UPDATE_BUTTON_CLASS_NAME = `${HEADER_CLASS_NAME}__update-button`;
export const RESIZABLE_COLUMN_CLASS_NAME = 'ib-column-resizable';
export const DIALOG_LIST_CLASS_NAME = `${CONTENT_ROW_CLASS_NAME}__dialog-list`;
