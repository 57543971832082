import React from 'react';
import { Redirect, Route, Router, Switch } from 'react-router';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { Flags } from 'react-feature-flags';

import { FeatureFlag } from '../api';

import { FeatureFlagNames } from './constants';
import { browserHistory } from './utils/historyUtil';
import BaseLayout from './layouts/BaseLayout';
import EmptyLayout from './layouts/EmptyLayout';
import AgentList from './pages/agents/AgentList';
import AgentAdd from './pages/agents/AgentAdd';
import AgentCard from './pages/agents/AgentCard';
import AgentEdit from './pages/agents/AgentEdit';
import AgentStageEdit from './pages/agents/stages/AgentStageEdit';
import ViberChannelAdd from './pages/agents/channels/ViberChannelAdd';
import TelegramChannelAdd from './pages/agents/channels/TelegramChannelAdd';
import ELMA365ChannelAdd from './pages/agents/channels/ELMA365ChannelAdd';
import ELMA365ChannelEdit from './pages/agents/channels/ELMA365ChannelEdit';
import LiveChatChannelAdd from './pages/agents/channels/LiveChatChannelAdd';
import LiveChatChannelEdit from './pages/agents/channels/LiveChatChannelEdit';
import CampaignList from './pages/campaigns/CampaignList';
import CampaignAdd from './pages/campaigns/CampaignAdd';
import CampaignCard from './pages/campaigns/CampaignCard';
import GroupList from './pages/groups/GroupList';
import GroupAdd from './pages/groups/GroupAdd';
import GroupEdit from './pages/groups/GroupEdit';
import GroupCard from './pages/groups/GroupCard';
import PersonList from './pages/persons/PersonList';
import PersonAdd from './pages/persons/PersonAdd';
import PersonCard from './pages/persons/PersonCard';
import PersonGroups from './pages/persons/PersonGroups';
import KnowledgeBaseList from './pages/knowledge-bases/KnowledgeBaseList';
import KnowledgeBaseAdd from './pages/knowledge-bases/KnowledgeBaseAdd';
import KnowledgeBaseEdit from './pages/knowledge-bases/KnowledgeBaseEdit';
import KnowledgeBaseCard from './pages/knowledge-bases/KnowledgeBaseCard';
import VersionAdd from './pages/knowledge-bases/VersionAdd';
import VersionCard from './pages/knowledge-bases/VersionCard';
import DraftAdd from './pages/knowledge-bases/DraftAdd';
import DraftCard from './pages/knowledge-bases/DraftCard';
import ConversationCard from './pages/conversations/ConversationCard';
import SimpleBotBaseLayout from './simple-bot/layouts/SimpleBotBaseLayout';
import SimpleBotEmptyLayout from './simple-bot/layouts/SimpleBotEmptyLayout';
import SimpleBotList from './simple-bot/pages/bots/SimpleBotList';
import SimpleBotCard from './simple-bot/pages/bots/SimpleBotCard';
import SimpleBotRouter from './simple-bot/pages/bots/SimpleBotRouter';
import ScenarioCard from './simple-bot/pages/scenarios/ScenarioCard';
import SimpleKnowledgeBaseCard from './simple-bot/pages/knowledge-bases/SimpleKnowledgeBaseCard';
import { Dispatcher, dispatcherState } from './recoil/scenarioStructure';
import createDispatcher from './recoil/scenarioStructure/dispatcher';
import LoggedInCard from './simple-bot/pages/employee/auth/LoggedInCard';
import ErrorCard from './simple-bot/pages/employee/auth/ErrorCard';
import SimpleBotEmployeeLayout from './simple-bot/layouts/SimpleBotEmployeeLayout';
import SimpleBotWebChat from './simple-bot/pages/SimpleBotWebChat';
import AnalyticsCard from './simple-bot/pages/analytics/AnalyticsCard';
import DialogsCard from './simple-bot/pages/dialogs/DialogsCard';
import UserList from './simple-bot/pages/users/UserList';
import ChannelsCard from './simple-bot/pages/channels/ChannelsCard';
import InboxLayoutBase from './inbox/layouts/InboxLayoutBase';
import BotsPage from './inbox/pages/bots/BotsPage';
import BotPage from './inbox/pages/bots/BotPage';
import BotScenariosPage from './inbox/pages/bots/BotScenariosPage';
import ChatsPage from './inbox/pages/ChatsPage';
import ContactsPage from './inbox/pages/ContactsPage';
// import MailingPage from './inbox/pages/MailingPage';
import OperatorsPage from './inbox/pages/OperatorsPage';
import { NetworkConnectionStatus, networkConnectionSelector } from './inbox/recoil';
import ChannelsPage from './inbox/pages/ChannelsPage';
import IbAccountWidgetWrapper from './inbox/wrappers/IbAccountWidgetWrapper';
import DialogsPage from './inbox/pages/dialogs';

function App(): JSX.Element {
  const setDispatcher = useSetRecoilState(dispatcherState);

  // NOTE: useRef нужен для обеспечения наличия единственного экземпляра
  const dispatcherRef = React.useRef<Dispatcher>(createDispatcher());

  React.useEffect(() => {
    setDispatcher(dispatcherRef.current);
  }, [setDispatcher]);

  const networkConnection = useRecoilValue(networkConnectionSelector);
  const networkConnectionDisabled = networkConnection.status === NetworkConnectionStatus.Off;

  const accountWidget = <IbAccountWidgetWrapper />;

  const goToInboxMainPage = () => browserHistory.push('/inbox');

  return (
    <Router history={browserHistory}>
      <Switch>
        <Route
          exact
          path="/agents"
          render={() => (
            <Flags authorizedFlags={[FeatureFlagNames.BOTS_ADMIN]}>
              <BaseLayout>
                <AgentList />
              </BaseLayout>
            </Flags>
          )}
        />
        <Route
          exact
          path="/agents/add"
          render={() => (
            <Flags authorizedFlags={[FeatureFlagNames.BOTS_ADMIN]}>
              <BaseLayout>
                <AgentAdd />
              </BaseLayout>
            </Flags>
          )}
        />
        <Route
          exact
          path="/agents/:id/edit"
          render={() => (
            <Flags authorizedFlags={[FeatureFlagNames.BOTS_ADMIN]}>
              <BaseLayout>
                <AgentEdit />
              </BaseLayout>
            </Flags>
          )}
        />
        <Route
          exact
          path="/agents/:id/viber/add"
          render={() => (
            <Flags authorizedFlags={[FeatureFlagNames.BOTS_ADMIN]}>
              <BaseLayout>
                <ViberChannelAdd />
              </BaseLayout>
            </Flags>
          )}
        />
        <Route
          exact
          path="/agents/:id/telegram/add"
          render={() => (
            <Flags authorizedFlags={[FeatureFlagNames.BOTS_ADMIN]}>
              <BaseLayout>
                <TelegramChannelAdd />
              </BaseLayout>
            </Flags>
          )}
        />
        <Route
          exact
          path="/agents/:id/elma365/add"
          render={() => (
            <Flags authorizedFlags={[FeatureFlagNames.BOTS_ADMIN]}>
              <BaseLayout>
                <ELMA365ChannelAdd />
              </BaseLayout>
            </Flags>
          )}
        />
        <Route
          exact
          path="/agents/:id/elma365/edit/:accountId"
          render={() => (
            <Flags authorizedFlags={[FeatureFlagNames.BOTS_ADMIN]}>
              <BaseLayout>
                <ELMA365ChannelEdit />
              </BaseLayout>
            </Flags>
          )}
        />
        <Route
          exact
          path="/agents/:id/livechat/add"
          render={() => (
            <Flags exactFlags authorizedFlags={[FeatureFlagNames.BOTS_ADMIN, FeatureFlagNames.LIVE_CHAT_CHANNEL]}>
              <BaseLayout>
                <LiveChatChannelAdd />
              </BaseLayout>
            </Flags>
          )}
        />
        <Route
          exact
          path="/agents/:id/livechat/edit/:accountId"
          render={() => (
            <Flags exactFlags authorizedFlags={[FeatureFlagNames.BOTS_ADMIN, FeatureFlagNames.LIVE_CHAT_CHANNEL]}>
              <BaseLayout>
                <LiveChatChannelEdit />
              </BaseLayout>
            </Flags>
          )}
        />
        <Route
          exact
          path="/agents/:id/:stageType?"
          render={() => (
            <Flags authorizedFlags={[FeatureFlagNames.BOTS_ADMIN]}>
              <BaseLayout>
                <AgentCard />
              </BaseLayout>
            </Flags>
          )}
        />
        <Route
          exact
          path="/agents/stages/:id/edit"
          render={() => (
            <Flags authorizedFlags={[FeatureFlagNames.BOTS_ADMIN]}>
              <BaseLayout>
                <AgentStageEdit />
              </BaseLayout>
            </Flags>
          )}
        />
        <Route
          exact
          path="/campaigns"
          render={() => (
            <Flags authorizedFlags={[FeatureFlagNames.ADMIN]}>
              <BaseLayout>
                <CampaignList />
              </BaseLayout>
            </Flags>
          )}
        />
        <Route
          exact
          path="/campaigns/add"
          render={() => (
            <Flags authorizedFlags={[FeatureFlagNames.ADMIN]}>
              <BaseLayout>
                <CampaignAdd />
              </BaseLayout>
            </Flags>
          )}
        />
        <Route
          exact
          path="/campaigns/:id"
          render={() => (
            <Flags authorizedFlags={[FeatureFlagNames.ADMIN]}>
              <BaseLayout>
                <CampaignCard />
              </BaseLayout>
            </Flags>
          )}
        />
        <Route
          exact
          path="/persons"
          render={() => (
            <Flags authorizedFlags={[FeatureFlagNames.ADMIN]}>
              <BaseLayout>
                <PersonList />
              </BaseLayout>
            </Flags>
          )}
        />
        <Route
          exact
          path="/persons/add"
          render={() => (
            <Flags authorizedFlags={[FeatureFlagNames.ADMIN]}>
              <BaseLayout>
                <PersonAdd />
              </BaseLayout>
            </Flags>
          )}
        />
        <Route
          exact
          path="/persons/:id"
          render={() => (
            <Flags authorizedFlags={[FeatureFlagNames.ADMIN]}>
              <BaseLayout>
                <PersonCard />
              </BaseLayout>
            </Flags>
          )}
        />
        <Route
          exact
          path="/persons/:id/groups"
          render={() => (
            <Flags authorizedFlags={[FeatureFlagNames.ADMIN]}>
              <BaseLayout>
                <PersonGroups />
              </BaseLayout>
            </Flags>
          )}
        />
        <Route
          exact
          path="/groups"
          render={() => (
            <Flags authorizedFlags={[FeatureFlagNames.ADMIN]}>
              <BaseLayout>
                <GroupList />
              </BaseLayout>
            </Flags>
          )}
        />
        <Route
          exact
          path="/groups/add"
          render={() => (
            <Flags authorizedFlags={[FeatureFlagNames.ADMIN]}>
              <BaseLayout>
                <GroupAdd />
              </BaseLayout>
            </Flags>
          )}
        />
        <Route
          exact
          path="/groups/edit/:id"
          render={() => (
            <Flags authorizedFlags={[FeatureFlagNames.ADMIN]}>
              <BaseLayout>
                <GroupEdit />
              </BaseLayout>
            </Flags>
          )}
        />
        <Route
          exact
          path="/groups/:id"
          render={() => (
            <Flags authorizedFlags={[FeatureFlagNames.ADMIN]}>
              <BaseLayout>
                <GroupCard />
              </BaseLayout>
            </Flags>
          )}
        />
        <Route
          exact
          path="/knowledge-bases"
          render={() => (
            <Flags authorizedFlags={[FeatureFlagNames.BOTS_ADMIN]}>
              <BaseLayout>
                <KnowledgeBaseList />
              </BaseLayout>
            </Flags>
          )}
        />
        <Route
          exact
          path="/knowledge-bases/add"
          render={() => (
            <Flags authorizedFlags={[FeatureFlagNames.BOTS_ADMIN]}>
              <BaseLayout>
                <KnowledgeBaseAdd />
              </BaseLayout>
            </Flags>
          )}
        />
        <Route
          exact
          path="/knowledge-bases/:id"
          render={() => (
            <Flags authorizedFlags={[FeatureFlagNames.BOTS_ADMIN]}>
              <BaseLayout>
                <KnowledgeBaseCard />
              </BaseLayout>
            </Flags>
          )}
        />
        <Route
          exact
          path="/knowledge-bases/:id/edit"
          render={() => (
            <Flags authorizedFlags={[FeatureFlagNames.BOTS_ADMIN]}>
              <BaseLayout>
                <KnowledgeBaseEdit />
              </BaseLayout>
            </Flags>
          )}
        />
        <Route
          exact
          path="/knowledge-bases/:id/versions/add"
          render={() => (
            <Flags authorizedFlags={[FeatureFlagNames.BOTS_ADMIN]}>
              <BaseLayout>
                <VersionAdd />
              </BaseLayout>
            </Flags>
          )}
        />
        <Route
          exact
          path="/knowledge-bases/:id/versions/:versionId"
          render={() => (
            <Flags authorizedFlags={[FeatureFlagNames.BOTS_ADMIN]}>
              <BaseLayout>
                <VersionCard />
              </BaseLayout>
            </Flags>
          )}
        />
        <Route
          exact
          path="/knowledge-bases/:id/drafts/add"
          render={() => (
            <Flags authorizedFlags={[FeatureFlagNames.BOTS_ADMIN]}>
              <BaseLayout>
                <DraftAdd />
              </BaseLayout>
            </Flags>
          )}
        />
        <Route
          exact
          path="/knowledge-bases/:id/drafts/:draftId"
          render={() => (
            <Flags authorizedFlags={[FeatureFlagNames.BOTS_ADMIN]}>
              <BaseLayout>
                <DraftCard />
              </BaseLayout>
            </Flags>
          )}
        />
        <Route
          exact
          path="/conversations/:id"
          render={() => (
            <Flags authorizedFlags={[FeatureFlagNames.BOTS_ADMIN]}>
              <EmptyLayout>
                <ConversationCard />
              </EmptyLayout>
            </Flags>
          )}
        />
        <Route
          exact
          path="/simple-bots"
          render={() => (
            <Flags
              authorizedFlags={[FeatureFlagNames.BOTS_ADMIN, FeatureFlagNames.INBOX_OPERATOR]}
              renderOff={() => <Redirect to="/users" />}
              renderOn={(authorizedFlags: FeatureFlag[]) =>
                authorizedFlags.some((f) => f.name === FeatureFlagNames.BOTS_ADMIN) ? (
                  <SimpleBotBaseLayout>
                    <SimpleBotList />
                  </SimpleBotBaseLayout>
                ) : (
                  <Redirect to="/inbox" />
                )
              }
            />
          )}
        />
        <Route
          exact
          path="/simple-bots/router"
          render={() => (
            <Flags authorizedFlags={[FeatureFlagNames.BOTS_ADMIN]}>
              <SimpleBotBaseLayout>
                <SimpleBotRouter />
              </SimpleBotBaseLayout>
            </Flags>
          )}
        />
        <Route
          exact
          path="/simple-bots/:id"
          render={() => (
            <Flags authorizedFlags={[FeatureFlagNames.BOTS_ADMIN]}>
              <SimpleBotBaseLayout>
                <SimpleBotCard />
              </SimpleBotBaseLayout>
            </Flags>
          )}
        />
        <Route
          exact
          path="/simple-bots/:id/scenario/:scenarioStructureId"
          render={() => (
            <Flags authorizedFlags={[FeatureFlagNames.BOTS_ADMIN]}>
              <SimpleBotEmptyLayout>
                <ScenarioCard />
              </SimpleBotEmptyLayout>
            </Flags>
          )}
        />
        <Route
          exact
          path="/simple-bots/:id/knowledge-base"
          render={() => (
            <Flags authorizedFlags={[FeatureFlagNames.BOTS_ADMIN]}>
              <SimpleBotEmptyLayout>
                <SimpleKnowledgeBaseCard />
              </SimpleBotEmptyLayout>
            </Flags>
          )}
        />
        <Route
          exact
          path="/employee/auth/logged-in"
          render={() => (
            <SimpleBotEmployeeLayout>
              <LoggedInCard />
            </SimpleBotEmployeeLayout>
          )}
        />
        <Route
          exact
          path="/employee/auth/error"
          render={() => (
            <SimpleBotEmployeeLayout>
              <ErrorCard />
            </SimpleBotEmployeeLayout>
          )}
        />
        <Route
          exact
          path="/webchat"
          render={() => (
            <EmptyLayout>
              <SimpleBotWebChat />
            </EmptyLayout>
          )}
        />
        <Route
          exact
          path="/simple-bots/:id/analytics/:tabPaneKey?"
          render={() => (
            <Flags exactFlags authorizedFlags={[FeatureFlagNames.ANALYTICS, FeatureFlagNames.BOTS_ADMIN]}>
              <SimpleBotEmptyLayout>
                <AnalyticsCard />
              </SimpleBotEmptyLayout>
            </Flags>
          )}
        />
        <Route
          exact
          path="/simple-bots/:id/old-dialogs"
          render={() => (
            <Flags authorizedFlags={[FeatureFlagNames.BOTS_ADMIN]}>
              <SimpleBotEmptyLayout>
                <DialogsCard />
              </SimpleBotEmptyLayout>
            </Flags>
          )}
        />
        <Route
          exact
          path="/simple-bots/:id/dialogs"
          render={() => (
            <Flags authorizedFlags={[FeatureFlagNames.BOTS_ADMIN]}>
              <SimpleBotEmptyLayout>
                <DialogsPage />
              </SimpleBotEmptyLayout>
            </Flags>
          )}
        />
        <Route
          exact
          path="/users"
          render={() => (
            <Flags authorizedFlags={[FeatureFlagNames.USERS_MANAGEMENT]}>
              <SimpleBotBaseLayout>
                <UserList />
              </SimpleBotBaseLayout>
            </Flags>
          )}
        />
        <Route
          exact
          path="/simple-bots/:id/channels"
          render={() => (
            <Flags
              exactFlags
              authorizedFlags={[FeatureFlagNames.INBOX_DISPLAY, FeatureFlagNames.SIMPLE_BOT_CHANNELS_MANAGEMENT]}
              renderOff={() => (
                <Flags authorizedFlags={[FeatureFlagNames.SIMPLE_BOT_CHANNELS_MANAGEMENT]}>
                  <SimpleBotEmptyLayout>
                    <ChannelsCard />
                  </SimpleBotEmptyLayout>
                </Flags>
              )}
            />
          )}
        />
        <Route
          exact
          path="/inbox/bots"
          render={() => (
            <Flags authorizedFlags={[FeatureFlagNames.INBOX_DISPLAY, FeatureFlagNames.ADMIN]}>
              <InboxLayoutBase
                accountWidget={accountWidget}
                networkConnectionDisabled={networkConnectionDisabled}
                selectedItem="bots"
                onGoToMainPage={goToInboxMainPage}
              >
                <BotsPage />
              </InboxLayoutBase>
            </Flags>
          )}
        />
        <Route
          exact
          path="/inbox/bots/:id/scenario/:scenarioStructureId"
          render={() => (
            <Flags authorizedFlags={[FeatureFlagNames.INBOX_DISPLAY, FeatureFlagNames.ADMIN]}>
              <SimpleBotEmptyLayout>
                <ScenarioCard isInbox />
              </SimpleBotEmptyLayout>
            </Flags>
          )}
        />
        <Route
          exact
          path="/inbox/bots/:id/dialogs"
          render={() => (
            <Flags authorizedFlags={[FeatureFlagNames.INBOX_DISPLAY, FeatureFlagNames.ADMIN]}>
              <SimpleBotEmptyLayout>
                <DialogsPage isInbox />
              </SimpleBotEmptyLayout>
            </Flags>
          )}
        />
        <Route
          exact
          path="/inbox/bots/:id/analytics/:tabPaneKey?"
          render={() => (
            <Flags authorizedFlags={[FeatureFlagNames.INBOX_DISPLAY, FeatureFlagNames.ADMIN]}>
              <SimpleBotEmptyLayout>
                <AnalyticsCard isInbox />
              </SimpleBotEmptyLayout>
            </Flags>
          )}
        />
        <Route
          exact
          path="/inbox/bots/:id/knowledge-base"
          render={() => (
            <Flags authorizedFlags={[FeatureFlagNames.INBOX_DISPLAY, FeatureFlagNames.ADMIN]}>
              <SimpleBotEmptyLayout>
                <SimpleKnowledgeBaseCard isInbox />
              </SimpleBotEmptyLayout>
            </Flags>
          )}
        />
        <Route
          exact
          path="/inbox/bots/:id/scenarios"
          render={() => (
            <Flags authorizedFlags={[FeatureFlagNames.INBOX_DISPLAY, FeatureFlagNames.ADMIN]}>
              <InboxLayoutBase
                accountWidget={accountWidget}
                networkConnectionDisabled={networkConnectionDisabled}
                selectedItem="bots"
                onGoToMainPage={goToInboxMainPage}
              >
                <BotScenariosPage />
              </InboxLayoutBase>
            </Flags>
          )}
        />
        <Route
          exact
          path="/inbox/bots/:id"
          render={() => (
            <Flags authorizedFlags={[FeatureFlagNames.INBOX_DISPLAY, FeatureFlagNames.ADMIN]}>
              <InboxLayoutBase
                accountWidget={accountWidget}
                networkConnectionDisabled={networkConnectionDisabled}
                selectedItem="bots"
                onGoToMainPage={goToInboxMainPage}
              >
                <BotPage />
              </InboxLayoutBase>
            </Flags>
          )}
        />
        <Route
          exact
          path="/inbox/channels"
          render={() => (
            <Flags authorizedFlags={[FeatureFlagNames.INBOX_DISPLAY]}>
              <InboxLayoutBase
                accountWidget={accountWidget}
                networkConnectionDisabled={networkConnectionDisabled}
                selectedItem="channels"
                onGoToMainPage={goToInboxMainPage}
              >
                <ChannelsPage />
              </InboxLayoutBase>
            </Flags>
          )}
        />
        <Route
          exact
          path="/inbox/chats/:tabKey?/:chatId?"
          render={({ match }) => (
            <Flags authorizedFlags={[FeatureFlagNames.INBOX_DISPLAY]}>
              <InboxLayoutBase
                accountWidget={accountWidget}
                chatSelected={!!match.params.chatId}
                networkConnectionDisabled={networkConnectionDisabled}
                selectedItem="chats"
                onGoToMainPage={goToInboxMainPage}
              >
                <ChatsPage />
              </InboxLayoutBase>
            </Flags>
          )}
        />
        <Route
          exact
          path="/inbox/contacts"
          render={() => (
            <Flags authorizedFlags={[FeatureFlagNames.INBOX_DISPLAY]}>
              <InboxLayoutBase
                accountWidget={accountWidget}
                networkConnectionDisabled={networkConnectionDisabled}
                selectedItem="contacts"
                onGoToMainPage={goToInboxMainPage}
              >
                <ContactsPage />
              </InboxLayoutBase>
            </Flags>
          )}
        />
        {/*<Route*/}
        {/*  exact*/}
        {/*  path="/inbox/mailing"*/}
        {/*  render={() => (*/}
        {/*    <Flags authorizedFlags={[FeatureFlagNames.INBOX_DISPLAY]}>*/}
        {/*      <InboxLayoutBase*/}
        {/*        accountWidget={accountWidget}*/}
        {/*        networkConnectionDisabled={networkConnectionDisabled}*/}
        {/*        selectedItem="mailing"*/}
        {/*        onGoToMainPage={goToInboxMainPage}*/}
        {/*      >*/}
        {/*        <MailingPage />*/}
        {/*      </InboxLayoutBase>*/}
        {/*    </Flags>*/}
        {/*  )}*/}
        {/*/>*/}
        <Route
          exact
          path="/inbox/operators/:tabKey?"
          render={() => (
            <Flags authorizedFlags={[FeatureFlagNames.INBOX_DISPLAY]}>
              <InboxLayoutBase
                accountWidget={accountWidget}
                networkConnectionDisabled={networkConnectionDisabled}
                selectedItem="operators"
                onGoToMainPage={goToInboxMainPage}
              >
                <OperatorsPage />
              </InboxLayoutBase>
            </Flags>
          )}
        />
        <Route exact path="/inbox">
          <Flags
            authorizedFlags={[FeatureFlagNames.ADMIN, FeatureFlagNames.INBOX_SUPERVISOR]}
            renderOff={() => <Redirect to="/inbox/chats" />}
            renderOn={() => <Redirect to="/inbox/channels" />}
          />
        </Route>
        <Route exact path="/inbox/chats">
          <Redirect to="/inbox/chats/queue" />
        </Route>
        <Route path="*">
          <Flags
            authorizedFlags={[FeatureFlagNames.INBOX_DISPLAY]}
            renderOff={() => <Redirect to="/simple-bots" />}
            renderOn={() => <Redirect to="/inbox" />}
          />
        </Route>
      </Switch>
    </Router>
  );
}

export default App;
