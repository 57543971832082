/* tslint:disable */
/* eslint-disable */
/**
 * Менеджер ELMA Bot
 * Управление сотрудниками, ботами, каналами, кампаниями и т.д.
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from './configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
import type { RequestArgs } from './base';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, BaseAPI, RequiredError, operationServerMap } from './base';

/**
 * Модель информации о приложении.
 * @export
 * @interface AboutModel
 */
export interface AboutModel {
    /**
     * Наименование.
     * @type {string}
     * @memberof AboutModel
     */
    'name'?: string | null;
    /**
     * Описание.
     * @type {string}
     * @memberof AboutModel
     */
    'description'?: string | null;
    /**
     * Конечная точка.
     * @type {string}
     * @memberof AboutModel
     */
    'endpoint'?: string | null;
    /**
     * Версия.
     * @type {string}
     * @memberof AboutModel
     */
    'version'?: string | null;
}
/**
 * Модель контакта между аккаунтами.
 * @export
 * @interface AccountContactModel
 */
export interface AccountContactModel {
    /**
     * ИД контакта.
     * @type {string}
     * @memberof AccountContactModel
     */
    'id': string;
    /**
     * ИД канала.
     * @type {string}
     * @memberof AccountContactModel
     */
    'channelId': string;
    /**
     * ИД стадии бота.
     * @type {string}
     * @memberof AccountContactModel
     */
    'agentStageId'?: string | null;
    /**
     * ИД сотрудника.
     * @type {string}
     * @memberof AccountContactModel
     */
    'personId': string;
    /**
     * ИД аккаунта стадии бота.
     * @type {string}
     * @memberof AccountContactModel
     */
    'agentStageAccountId': string;
    /**
     * ИД аккаунта сотрудника.
     * @type {string}
     * @memberof AccountContactModel
     */
    'personAccountId': string;
    /**
     * 
     * @type {AccountContactState}
     * @memberof AccountContactModel
     */
    'state'?: AccountContactState;
    /**
     * ИД контакта в канале.
     * @type {string}
     * @memberof AccountContactModel
     */
    'externalUserId'?: string | null;
}


/**
 * 
 * @export
 * @interface AccountContactModelPaginationResponse
 */
export interface AccountContactModelPaginationResponse {
    /**
     * 
     * @type {Array<AccountContactModel>}
     * @memberof AccountContactModelPaginationResponse
     */
    'items'?: Array<AccountContactModel> | null;
    /**
     * 
     * @type {boolean}
     * @memberof AccountContactModelPaginationResponse
     */
    'hasMore'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof AccountContactModelPaginationResponse
     */
    'pageIndex'?: number;
    /**
     * 
     * @type {number}
     * @memberof AccountContactModelPaginationResponse
     */
    'pageSize'?: number;
    /**
     * 
     * @type {number}
     * @memberof AccountContactModelPaginationResponse
     */
    'totalItemCount'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof AccountContactModelPaginationResponse
     */
    'totalPageCount'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof AccountContactModelPaginationResponse
     */
    'fromItemInclusive'?: number;
    /**
     * 
     * @type {number}
     * @memberof AccountContactModelPaginationResponse
     */
    'toItemExclusive'?: number;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const AccountContactState = {
    NUMBER_0: 0,
    NUMBER_1: 1,
    NUMBER_2: 2,
    NUMBER_3: 3,
    NUMBER_4: 4
} as const;

export type AccountContactState = typeof AccountContactState[keyof typeof AccountContactState];


/**
 * 
 * @export
 * @interface ActionGroupSchema
 */
export interface ActionGroupSchema extends EntitySchema {
    [key: string]: any;

    /**
     * 
     * @type {string}
     * @memberof ActionGroupSchema
     */
    '$kind': string;
    /**
     * 
     * @type {string}
     * @memberof ActionGroupSchema
     */
    'name': string;
}
/**
 * 
 * @export
 * @interface ActionSchema
 */
export interface ActionSchema extends EntitySchema {
    [key: string]: any;

    /**
     * 
     * @type {string}
     * @memberof ActionSchema
     */
    '$kind': string;
}
/**
 * 
 * @export
 * @interface Activity
 */
export interface Activity {
    [key: string]: any;

    /**
     * 
     * @type {string}
     * @memberof Activity
     */
    'type'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Activity
     */
    'id'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Activity
     */
    'timestamp'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Activity
     */
    'localTimestamp'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Activity
     */
    'serviceUrl'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Activity
     */
    'channelId'?: string | null;
    /**
     * 
     * @type {ChannelAccount}
     * @memberof Activity
     */
    'from'?: ChannelAccount;
    /**
     * 
     * @type {ConversationAccount}
     * @memberof Activity
     */
    'conversation'?: ConversationAccount;
    /**
     * 
     * @type {ChannelAccount}
     * @memberof Activity
     */
    'recipient'?: ChannelAccount;
    /**
     * 
     * @type {string}
     * @memberof Activity
     */
    'textFormat'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Activity
     */
    'attachmentLayout'?: string | null;
    /**
     * 
     * @type {Array<ChannelAccount>}
     * @memberof Activity
     */
    'membersAdded'?: Array<ChannelAccount> | null;
    /**
     * 
     * @type {Array<ChannelAccount>}
     * @memberof Activity
     */
    'membersRemoved'?: Array<ChannelAccount> | null;
    /**
     * 
     * @type {string}
     * @memberof Activity
     */
    'topicName'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof Activity
     */
    'historyDisclosed'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof Activity
     */
    'locale'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Activity
     */
    'text'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Activity
     */
    'speak'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Activity
     */
    'inputHint'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Activity
     */
    'summary'?: string | null;
    /**
     * 
     * @type {SuggestedActions}
     * @memberof Activity
     */
    'suggestedActions'?: SuggestedActions;
    /**
     * 
     * @type {Array<Attachment>}
     * @memberof Activity
     */
    'attachments'?: Array<Attachment> | null;
    /**
     * 
     * @type {Array<Entity>}
     * @memberof Activity
     */
    'entities'?: Array<Entity> | null;
    /**
     * 
     * @type {any}
     * @memberof Activity
     */
    'channelData'?: any | null;
    /**
     * 
     * @type {string}
     * @memberof Activity
     */
    'action'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Activity
     */
    'replyToId'?: string | null;
    /**
     * 
     * @type {any}
     * @memberof Activity
     */
    'value'?: any | null;
    /**
     * 
     * @type {string}
     * @memberof Activity
     */
    'name'?: string | null;
    /**
     * 
     * @type {ConversationReference}
     * @memberof Activity
     */
    'relatesTo'?: ConversationReference;
    /**
     * 
     * @type {string}
     * @memberof Activity
     */
    'code'?: string | null;
}
/**
 * 
 * @export
 * @interface ActivityAttachment
 */
export interface ActivityAttachment {
    /**
     * 
     * @type {string}
     * @memberof ActivityAttachment
     */
    'contentType'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ActivityAttachment
     */
    'contentUrl'?: string | null;
    /**
     * 
     * @type {any}
     * @memberof ActivityAttachment
     */
    'content'?: any | null;
    /**
     * 
     * @type {string}
     * @memberof ActivityAttachment
     */
    'name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ActivityAttachment
     */
    'thumbnailUrl'?: string | null;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const ActivityDirection = {
    Inbound: 'Inbound',
    Outbound: 'Outbound',
    Internal: 'Internal'
} as const;

export type ActivityDirection = typeof ActivityDirection[keyof typeof ActivityDirection];


/**
 * Модель активности.
 * @export
 * @interface ActivityModel
 */
export interface ActivityModel {
    /**
     * ИД активности.
     * @type {string}
     * @memberof ActivityModel
     */
    'id'?: string | null;
    /**
     * Внешний ИД.
     * @type {string}
     * @memberof ActivityModel
     */
    'externalId'?: string | null;
    /**
     * ИД канала.
     * @type {string}
     * @memberof ActivityModel
     */
    'channelId'?: string | null;
    /**
     * ИД бота.
     * @type {string}
     * @memberof ActivityModel
     */
    'agentId'?: string | null;
    /**
     * ИД стадии бота.
     * @type {string}
     * @memberof ActivityModel
     */
    'agentStageId'?: string | null;
    /**
     * Дата создания.
     * @type {string}
     * @memberof ActivityModel
     */
    'createdOn'?: string;
    /**
     * ИД сотрудника.
     * @type {string}
     * @memberof ActivityModel
     */
    'personId'?: string | null;
    /**
     * ИД беседы.
     * @type {string}
     * @memberof ActivityModel
     */
    'conversationId'?: string | null;
    /**
     * ИД корреляции.
     * @type {string}
     * @memberof ActivityModel
     */
    'correlationId'?: string | null;
    /**
     * 
     * @type {ActivityDirection}
     * @memberof ActivityModel
     */
    'direction'?: ActivityDirection;
    /**
     * Тип активности.
     * @type {string}
     * @memberof ActivityModel
     */
    'type'?: string | null;
    /**
     * Наименование активности.
     * @type {string}
     * @memberof ActivityModel
     */
    'name'?: string | null;
    /**
     * Текст активности.
     * @type {string}
     * @memberof ActivityModel
     */
    'text'?: string | null;
    /**
     * Дата.
     * @type {string}
     * @memberof ActivityModel
     */
    'date'?: string | null;
    /**
     * Value
     * @type {any}
     * @memberof ActivityModel
     */
    'value'?: any | null;
    /**
     * Наименования доступных кнопок.
     * @type {Array<string>}
     * @memberof ActivityModel
     */
    'suggestedActions'?: Array<string> | null;
    /**
     * Вложения.
     * @type {Array<ActivityAttachment>}
     * @memberof ActivityModel
     */
    'attachments'?: Array<ActivityAttachment> | null;
    /**
     * Свойства.
     * @type {any}
     * @memberof ActivityModel
     */
    'properties': any;
}


/**
 * 
 * @export
 * @interface ActivityModelKeysetPaginationResponse
 */
export interface ActivityModelKeysetPaginationResponse {
    /**
     * 
     * @type {Array<ActivityModel>}
     * @memberof ActivityModelKeysetPaginationResponse
     */
    'items'?: Array<ActivityModel> | null;
    /**
     * 
     * @type {ActivityModel}
     * @memberof ActivityModelKeysetPaginationResponse
     */
    'reference'?: ActivityModel;
    /**
     * 
     * @type {number}
     * @memberof ActivityModelKeysetPaginationResponse
     */
    'pageSize'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof ActivityModelKeysetPaginationResponse
     */
    'hasMore'?: boolean;
}
/**
 * 
 * @export
 * @interface ActivityModelPaginationResponse
 */
export interface ActivityModelPaginationResponse {
    /**
     * 
     * @type {Array<ActivityModel>}
     * @memberof ActivityModelPaginationResponse
     */
    'items'?: Array<ActivityModel> | null;
    /**
     * 
     * @type {boolean}
     * @memberof ActivityModelPaginationResponse
     */
    'hasMore'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof ActivityModelPaginationResponse
     */
    'pageIndex'?: number;
    /**
     * 
     * @type {number}
     * @memberof ActivityModelPaginationResponse
     */
    'pageSize'?: number;
    /**
     * 
     * @type {number}
     * @memberof ActivityModelPaginationResponse
     */
    'totalItemCount'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ActivityModelPaginationResponse
     */
    'totalPageCount'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ActivityModelPaginationResponse
     */
    'fromItemInclusive'?: number;
    /**
     * 
     * @type {number}
     * @memberof ActivityModelPaginationResponse
     */
    'toItemExclusive'?: number;
}
/**
 * 
 * @export
 * @interface ActivitySet
 */
export interface ActivitySet {
    /**
     * 
     * @type {Array<Activity>}
     * @memberof ActivitySet
     */
    'activities'?: Array<Activity> | null;
    /**
     * 
     * @type {string}
     * @memberof ActivitySet
     */
    'watermark'?: string | null;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const ActivitySortDirection = {
    None: 'None',
    CreatedOnAscending: 'CreatedOnAscending',
    CreatedOnDescending: 'CreatedOnDescending'
} as const;

export type ActivitySortDirection = typeof ActivitySortDirection[keyof typeof ActivitySortDirection];


/**
 * 
 * @export
 * @interface AdditionalSettings
 */
export interface AdditionalSettings {
    /**
     * 
     * @type {string}
     * @memberof AdditionalSettings
     */
    'greeting'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof AdditionalSettings
     */
    'transferToOperatorByUser'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof AdditionalSettings
     */
    'transferToOperatorInUnknownIntent'?: boolean;
    /**
     * 
     * @type {Array<AgentStageKnowledgeBase>}
     * @memberof AdditionalSettings
     */
    'knowledgeBases'?: Array<AgentStageKnowledgeBase> | null;
}
/**
 * 
 * @export
 * @interface Agent
 */
export interface Agent {
    /**
     * 
     * @type {AgentType}
     * @memberof Agent
     */
    'type'?: AgentType;
    /**
     * 
     * @type {string}
     * @memberof Agent
     */
    'name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Agent
     */
    'description'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Agent
     */
    'stagingAgentStageId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Agent
     */
    'productionAgentStageId'?: string | null;
    /**
     * 
     * @type {AgentCreationStatus}
     * @memberof Agent
     */
    'creationStatus'?: AgentCreationStatus;
    /**
     * 
     * @type {string}
     * @memberof Agent
     */
    'tenantId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Agent
     */
    'id'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Agent
     */
    'version'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Agent
     */
    'createdBy'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Agent
     */
    'modifiedBy'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Agent
     */
    'createdOn'?: string;
    /**
     * 
     * @type {string}
     * @memberof Agent
     */
    'modifiedOn'?: string;
}


/**
 * 
 * @export
 * @enum {string}
 */

export const AgentCreationMode = {
    NUMBER_0: 0,
    NUMBER_1: 1
} as const;

export type AgentCreationMode = typeof AgentCreationMode[keyof typeof AgentCreationMode];


/**
 * Запрос на создание бота.
 * @export
 * @interface AgentCreationRequest
 */
export interface AgentCreationRequest {
    /**
     * Наименование бота.
     * @type {string}
     * @memberof AgentCreationRequest
     */
    'name': string;
    /**
     * 
     * @type {AgentCreationMode}
     * @memberof AgentCreationRequest
     */
    'creationMode': AgentCreationMode;
    /**
     * Описание бота.
     * @type {string}
     * @memberof AgentCreationRequest
     */
    'description'?: string | null;
    /**
     * Код готового шаблона бота.
     * @type {string}
     * @memberof AgentCreationRequest
     */
    'templateCode'?: string | null;
    /**
     * Архив с ботом.
     * @type {string}
     * @memberof AgentCreationRequest
     */
    'botZip'?: string | null;
    /**
     * 
     * @type {AgentWizardConfig}
     * @memberof AgentCreationRequest
     */
    'config'?: AgentWizardConfig;
}


/**
 * Ответ на создание бота.
 * @export
 * @interface AgentCreationResponse
 */
export interface AgentCreationResponse {
    /**
     * ИД бота.
     * @type {string}
     * @memberof AgentCreationResponse
     */
    'id'?: string | null;
    /**
     * Дата/время создания бота.
     * @type {string}
     * @memberof AgentCreationResponse
     */
    'createdOn'?: string;
    /**
     * 
     * @type {AgentStageCreationResponse}
     * @memberof AgentCreationResponse
     */
    'stagingAgent'?: AgentStageCreationResponse;
    /**
     * 
     * @type {AgentStageCreationResponse}
     * @memberof AgentCreationResponse
     */
    'productionAgent'?: AgentStageCreationResponse;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const AgentCreationState = {
    NUMBER_0: 0,
    NUMBER_1: 1,
    NUMBER_2: 2,
    NUMBER_3: 3
} as const;

export type AgentCreationState = typeof AgentCreationState[keyof typeof AgentCreationState];


/**
 * 
 * @export
 * @interface AgentCreationStatus
 */
export interface AgentCreationStatus {
    /**
     * 
     * @type {AgentCreationState}
     * @memberof AgentCreationStatus
     */
    'state'?: AgentCreationState;
    /**
     * 
     * @type {string}
     * @memberof AgentCreationStatus
     */
    'errorMessage'?: string | null;
}


/**
 * Модель бота.
 * @export
 * @interface AgentModel
 */
export interface AgentModel {
    /**
     * ИД бота.
     * @type {string}
     * @memberof AgentModel
     */
    'id': string;
    /**
     * Дата/время создания бота.
     * @type {string}
     * @memberof AgentModel
     */
    'createdOn'?: string;
    /**
     * Наименование бота.
     * @type {string}
     * @memberof AgentModel
     */
    'name': string;
    /**
     * Описание бота.
     * @type {string}
     * @memberof AgentModel
     */
    'description'?: string | null;
    /**
     * 
     * @type {AgentStageModel}
     * @memberof AgentModel
     */
    'stagingAgent': AgentStageModel;
    /**
     * 
     * @type {AgentStageModel}
     * @memberof AgentModel
     */
    'productionAgent': AgentStageModel;
    /**
     * 
     * @type {AgentCreationStatus}
     * @memberof AgentModel
     */
    'creationStatus': AgentCreationStatus;
}
/**
 * 
 * @export
 * @interface AgentModelPaginationResponse
 */
export interface AgentModelPaginationResponse {
    /**
     * 
     * @type {Array<AgentModel>}
     * @memberof AgentModelPaginationResponse
     */
    'items'?: Array<AgentModel> | null;
    /**
     * 
     * @type {boolean}
     * @memberof AgentModelPaginationResponse
     */
    'hasMore'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof AgentModelPaginationResponse
     */
    'pageIndex'?: number;
    /**
     * 
     * @type {number}
     * @memberof AgentModelPaginationResponse
     */
    'pageSize'?: number;
    /**
     * 
     * @type {number}
     * @memberof AgentModelPaginationResponse
     */
    'totalItemCount'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof AgentModelPaginationResponse
     */
    'totalPageCount'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof AgentModelPaginationResponse
     */
    'fromItemInclusive'?: number;
    /**
     * 
     * @type {number}
     * @memberof AgentModelPaginationResponse
     */
    'toItemExclusive'?: number;
}
/**
 * Модель проактивного диалога бота.
 * @export
 * @interface AgentProactiveDialogModel
 */
export interface AgentProactiveDialogModel {
    /**
     * Id проактивного диалога.
     * @type {string}
     * @memberof AgentProactiveDialogModel
     */
    'id'?: string | null;
    /**
     * Отображаемое название проактивного диалога.
     * @type {string}
     * @memberof AgentProactiveDialogModel
     */
    'name'?: string | null;
    /**
     * Id стадии агента, к которой относится проактивный диалог.
     * @type {string}
     * @memberof AgentProactiveDialogModel
     */
    'agentStageId'?: string | null;
    /**
     * Стадия агента, к которой относится проактивный диалог.
     * @type {string}
     * @memberof AgentProactiveDialogModel
     */
    'agentStageType'?: string | null;
    /**
     * Id агента, к которому относится проактивный диалог.
     * @type {string}
     * @memberof AgentProactiveDialogModel
     */
    'agentId'?: string | null;
    /**
     * Название агента, к которому относится проактивный диалог.
     * @type {string}
     * @memberof AgentProactiveDialogModel
     */
    'agentName'?: string | null;
    /**
     * Параметры проактивного диалога.
     * @type {{ [key: string]: AgentProactiveDialogPropertyModel; }}
     * @memberof AgentProactiveDialogModel
     */
    'properties'?: { [key: string]: AgentProactiveDialogPropertyModel; } | null;
}
/**
 * Свойство запуска проактивного диалога.
 * @export
 * @interface AgentProactiveDialogPropertyModel
 */
export interface AgentProactiveDialogPropertyModel {
    /**
     * Тип свойства.
     * @type {string}
     * @memberof AgentProactiveDialogPropertyModel
     */
    'type'?: string | null;
    /**
     * Значение свойства по умолчанию.
     * @type {string}
     * @memberof AgentProactiveDialogPropertyModel
     */
    'defaultValue'?: string | null;
    /**
     * Отображаемое имя свойства.
     * @type {string}
     * @memberof AgentProactiveDialogPropertyModel
     */
    'displayName'?: string | null;
}
/**
 * 
 * @export
 * @interface AgentPublishRequest
 */
export interface AgentPublishRequest {
    /**
     * ИД версии бота из хранилища ботов.
     * @type {string}
     * @memberof AgentPublishRequest
     */
    'versionId'?: string | null;
    /**
     * ИД бота.
     * @type {string}
     * @memberof AgentPublishRequest
     */
    'agentId'?: string | null;
    /**
     * 
     * @type {PublishStage}
     * @memberof AgentPublishRequest
     */
    'publishStage'?: PublishStage;
}


/**
 * 
 * @export
 * @interface AgentRetrainRequest
 */
export interface AgentRetrainRequest {
    /**
     * ИД бота.
     * @type {string}
     * @memberof AgentRetrainRequest
     */
    'agentId'?: string | null;
    /**
     * 
     * @type {PublishStage}
     * @memberof AgentRetrainRequest
     */
    'publishStage'?: PublishStage;
}


/**
 * Запрос на создание аккаунта стадии бота в канале.
 * @export
 * @interface AgentStageAccountCreationRequest
 */
export interface AgentStageAccountCreationRequest {
    /**
     * ИД канала.
     * @type {string}
     * @memberof AgentStageAccountCreationRequest
     */
    'channelId': string;
    /**
     * ИД стадии бота.
     * @type {string}
     * @memberof AgentStageAccountCreationRequest
     */
    'agentStageId': string;
    /**
     * Отображаемое наименование.
     * @type {string}
     * @memberof AgentStageAccountCreationRequest
     */
    'displayName'?: string | null;
    /**
     * Токен аутентификации канала.
     * @type {string}
     * @memberof AgentStageAccountCreationRequest
     */
    'authToken': string;
    /**
     * Набор дополнительных свойств.
     * @type {{ [key: string]: any; }}
     * @memberof AgentStageAccountCreationRequest
     */
    'properties'?: { [key: string]: any; } | null;
}
/**
 * Ответ на создание аккаунта стадии бота в канале.
 * @export
 * @interface AgentStageAccountCreationResponse
 */
export interface AgentStageAccountCreationResponse {
    /**
     * ИД аккаунта.
     * @type {string}
     * @memberof AgentStageAccountCreationResponse
     */
    'id'?: string | null;
    /**
     * 
     * @type {AgentStageAccountStatus}
     * @memberof AgentStageAccountCreationResponse
     */
    'status': AgentStageAccountStatus;
    /**
     * Дата/время создания аккаунта стадии бота.
     * @type {string}
     * @memberof AgentStageAccountCreationResponse
     */
    'createdOn'?: string;
    /**
     * URI аккаунта стадии бота.
     * @type {string}
     * @memberof AgentStageAccountCreationResponse
     */
    'botUri'?: string | null;
    /**
     * URL перенаправления на чат со стадией бота.
     * @type {string}
     * @memberof AgentStageAccountCreationResponse
     */
    'redirectUrl': string;
    /**
     * URL обратного вызова для взаимодействия со стадией бота.
     * @type {string}
     * @memberof AgentStageAccountCreationResponse
     */
    'callbackUrl': string;
}


/**
 * Модель аккаунта стадии бота в канале.
 * @export
 * @interface AgentStageAccountModel
 */
export interface AgentStageAccountModel {
    /**
     * ИД аккаунта.
     * @type {string}
     * @memberof AgentStageAccountModel
     */
    'id': string;
    /**
     * 
     * @type {AgentStageAccountStatus}
     * @memberof AgentStageAccountModel
     */
    'status': AgentStageAccountStatus;
    /**
     * Дата/время создания аккаунта стадии бота.
     * @type {string}
     * @memberof AgentStageAccountModel
     */
    'createdOn'?: string;
    /**
     * ИД канала.
     * @type {string}
     * @memberof AgentStageAccountModel
     */
    'channelId': string;
    /**
     * ИД стадии бота.
     * @type {string}
     * @memberof AgentStageAccountModel
     */
    'agentStageId'?: string | null;
    /**
     * Отображаемое наименование.
     * @type {string}
     * @memberof AgentStageAccountModel
     */
    'displayName'?: string | null;
    /**
     * Токен аутентификации канала.
     * @type {string}
     * @memberof AgentStageAccountModel
     */
    'authToken': string;
    /**
     * URI аккаунта стадии бота.
     * @type {string}
     * @memberof AgentStageAccountModel
     */
    'botUri': string;
    /**
     * Внешнее наименование.
     * @type {string}
     * @memberof AgentStageAccountModel
     */
    'externalName': string;
    /**
     * URL перенаправления на чат со стадией бота.
     * @type {string}
     * @memberof AgentStageAccountModel
     */
    'redirectUrl': string;
    /**
     * URL обратного вызова для взаимодействия со стадией бота.
     * @type {string}
     * @memberof AgentStageAccountModel
     */
    'callbackUrl': string;
    /**
     * 
     * @type {AgentStageAccountSetupResult}
     * @memberof AgentStageAccountModel
     */
    'setupResult': AgentStageAccountSetupResult;
    /**
     * Набор дополнительных свойств.
     * @type {{ [key: string]: any; }}
     * @memberof AgentStageAccountModel
     */
    'properties'?: { [key: string]: any; } | null;
}


/**
 * 
 * @export
 * @interface AgentStageAccountModelPaginationResponse
 */
export interface AgentStageAccountModelPaginationResponse {
    /**
     * 
     * @type {Array<AgentStageAccountModel>}
     * @memberof AgentStageAccountModelPaginationResponse
     */
    'items'?: Array<AgentStageAccountModel> | null;
    /**
     * 
     * @type {boolean}
     * @memberof AgentStageAccountModelPaginationResponse
     */
    'hasMore'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof AgentStageAccountModelPaginationResponse
     */
    'pageIndex'?: number;
    /**
     * 
     * @type {number}
     * @memberof AgentStageAccountModelPaginationResponse
     */
    'pageSize'?: number;
    /**
     * 
     * @type {number}
     * @memberof AgentStageAccountModelPaginationResponse
     */
    'totalItemCount'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof AgentStageAccountModelPaginationResponse
     */
    'totalPageCount'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof AgentStageAccountModelPaginationResponse
     */
    'fromItemInclusive'?: number;
    /**
     * 
     * @type {number}
     * @memberof AgentStageAccountModelPaginationResponse
     */
    'toItemExclusive'?: number;
}
/**
 * 
 * @export
 * @interface AgentStageAccountSetupResult
 */
export interface AgentStageAccountSetupResult {
    /**
     * 
     * @type {SetupStatus}
     * @memberof AgentStageAccountSetupResult
     */
    'status'?: SetupStatus;
    /**
     * 
     * @type {string}
     * @memberof AgentStageAccountSetupResult
     */
    'errorMessage'?: string | null;
}


/**
 * 
 * @export
 * @enum {string}
 */

export const AgentStageAccountSortDirection = {
    StatusAscending: 'StatusAscending',
    NameAscending: 'NameAscending',
    StatusDescending: 'StatusDescending',
    NameDescending: 'NameDescending'
} as const;

export type AgentStageAccountSortDirection = typeof AgentStageAccountSortDirection[keyof typeof AgentStageAccountSortDirection];


/**
 * 
 * @export
 * @enum {string}
 */

export const AgentStageAccountStatus = {
    Disabled: 'Disabled',
    Enabled: 'Enabled'
} as const;

export type AgentStageAccountStatus = typeof AgentStageAccountStatus[keyof typeof AgentStageAccountStatus];


/**
 * Запрос на обновление аккаунта стадии бота в канале.
 * @export
 * @interface AgentStageAccountUpdatingRequest
 */
export interface AgentStageAccountUpdatingRequest {
    /**
     * 
     * @type {AgentStageAccountStatus}
     * @memberof AgentStageAccountUpdatingRequest
     */
    'status': AgentStageAccountStatus;
    /**
     * ИД стадии бота.
     * @type {string}
     * @memberof AgentStageAccountUpdatingRequest
     */
    'agentStageId'?: string | null;
    /**
     * Отображаемое наименование.
     * @type {string}
     * @memberof AgentStageAccountUpdatingRequest
     */
    'displayName'?: string | null;
    /**
     * Токен аутентификации канала.
     * @type {string}
     * @memberof AgentStageAccountUpdatingRequest
     */
    'authToken': string;
    /**
     * Набор дополнительных свойств.
     * @type {{ [key: string]: any; }}
     * @memberof AgentStageAccountUpdatingRequest
     */
    'properties'?: { [key: string]: any; } | null;
}


/**
 * Ответ на обновление аккаунта стадии бота в канале.
 * @export
 * @interface AgentStageAccountUpdatingResponse
 */
export interface AgentStageAccountUpdatingResponse {
    /**
     * 
     * @type {AgentStageAccountStatus}
     * @memberof AgentStageAccountUpdatingResponse
     */
    'status': AgentStageAccountStatus;
    /**
     * URI аккаунта стадии бота.
     * @type {string}
     * @memberof AgentStageAccountUpdatingResponse
     */
    'botUri'?: string | null;
    /**
     * URL перенаправления на чат со стадией бота.
     * @type {string}
     * @memberof AgentStageAccountUpdatingResponse
     */
    'redirectUrl': string;
    /**
     * URL обратного вызова для взаимодействия со стадией бота.
     * @type {string}
     * @memberof AgentStageAccountUpdatingResponse
     */
    'callbackUrl': string;
}


/**
 * Ответ на создание стадии бота.
 * @export
 * @interface AgentStageCreationResponse
 */
export interface AgentStageCreationResponse {
    /**
     * ИД стадии бота.
     * @type {string}
     * @memberof AgentStageCreationResponse
     */
    'id'?: string | null;
    /**
     * Дата/время создания стадии бота.
     * @type {string}
     * @memberof AgentStageCreationResponse
     */
    'createdOn'?: string;
}
/**
 * 
 * @export
 * @interface AgentStageKnowledgeBase
 */
export interface AgentStageKnowledgeBase {
    /**
     * 
     * @type {string}
     * @memberof AgentStageKnowledgeBase
     */
    'id'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof AgentStageKnowledgeBase
     */
    'enabled'?: boolean;
}
/**
 * Модель стадии бота.
 * @export
 * @interface AgentStageModel
 */
export interface AgentStageModel {
    /**
     * ИД стадии бота.
     * @type {string}
     * @memberof AgentStageModel
     */
    'id': string;
    /**
     * Дата/время создания стадии бота.
     * @type {string}
     * @memberof AgentStageModel
     */
    'createdOn'?: string;
    /**
     * Включено или нет версионирование диалогов.
     * @type {boolean}
     * @memberof AgentStageModel
     */
    'dialogsVersioningEnabled': boolean;
    /**
     * Ссылка для тестирования в эмуляторе.
     * @type {string}
     * @memberof AgentStageModel
     */
    'emulatorUrl': string;
    /**
     * Ссылка для тестирования в веб-чате.
     * @type {string}
     * @memberof AgentStageModel
     */
    'webChatUrl': string;
    /**
     * 
     * @type {TrainResult}
     * @memberof AgentStageModel
     */
    'trainResult': TrainResult;
    /**
     * 
     * @type {AdditionalSettings}
     * @memberof AgentStageModel
     */
    'settings'?: AdditionalSettings;
    /**
     * 
     * @type {ConversationSettings}
     * @memberof AgentStageModel
     */
    'conversationSettings'?: ConversationSettings;
}
/**
 * Запрос на обновление стадии бота.
 * @export
 * @interface AgentStageUpdatingRequest
 */
export interface AgentStageUpdatingRequest {
    /**
     * Включено или нет версионирование диалогов.
     * @type {boolean}
     * @memberof AgentStageUpdatingRequest
     */
    'dialogsVersioningEnabled'?: boolean;
}
/**
 * Тестовый бот.
 * @export
 * @interface AgentTestInstanceResponse
 */
export interface AgentTestInstanceResponse {
    /**
     * ИД стадии тестового бота.
     * @type {string}
     * @memberof AgentTestInstanceResponse
     */
    'agentStageId': string;
    /**
     * Ссылка для тестирования в эмуляторе.
     * @type {string}
     * @memberof AgentTestInstanceResponse
     */
    'emulatorUrl': string;
    /**
     * 
     * @type {TrainResult}
     * @memberof AgentTestInstanceResponse
     */
    'trainResult': TrainResult;
    /**
     * Дата создания.
     * @type {string}
     * @memberof AgentTestInstanceResponse
     */
    'createdOn'?: string;
    /**
     * Дата изменения.
     * @type {string}
     * @memberof AgentTestInstanceResponse
     */
    'modifiedOn'?: string;
    /**
     * ИД версии бота.
     * @type {string}
     * @memberof AgentTestInstanceResponse
     */
    'botVersionId': string;
    /**
     * Название версии бота.
     * @type {string}
     * @memberof AgentTestInstanceResponse
     */
    'botVersionName': string;
    /**
     * Версия бота является тестовой.
     * @type {boolean}
     * @memberof AgentTestInstanceResponse
     */
    'botVersionIsStaging'?: boolean;
    /**
     * Версия бота является релизной.
     * @type {boolean}
     * @memberof AgentTestInstanceResponse
     */
    'botVersionIsProduction'?: boolean;
}
/**
 * 
 * @export
 * @interface AgentTestInstanceResponsePaginationResponse
 */
export interface AgentTestInstanceResponsePaginationResponse {
    /**
     * 
     * @type {Array<AgentTestInstanceResponse>}
     * @memberof AgentTestInstanceResponsePaginationResponse
     */
    'items'?: Array<AgentTestInstanceResponse> | null;
    /**
     * 
     * @type {boolean}
     * @memberof AgentTestInstanceResponsePaginationResponse
     */
    'hasMore'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof AgentTestInstanceResponsePaginationResponse
     */
    'pageIndex'?: number;
    /**
     * 
     * @type {number}
     * @memberof AgentTestInstanceResponsePaginationResponse
     */
    'pageSize'?: number;
    /**
     * 
     * @type {number}
     * @memberof AgentTestInstanceResponsePaginationResponse
     */
    'totalItemCount'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof AgentTestInstanceResponsePaginationResponse
     */
    'totalPageCount'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof AgentTestInstanceResponsePaginationResponse
     */
    'fromItemInclusive'?: number;
    /**
     * 
     * @type {number}
     * @memberof AgentTestInstanceResponsePaginationResponse
     */
    'toItemExclusive'?: number;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const AgentType = {
    NUMBER_0: 0,
    NUMBER_1: 1
} as const;

export type AgentType = typeof AgentType[keyof typeof AgentType];


/**
 * Запрос на обновление бота.
 * @export
 * @interface AgentUpdatingRequest
 */
export interface AgentUpdatingRequest {
    /**
     * Наименование бота.
     * @type {string}
     * @memberof AgentUpdatingRequest
     */
    'name': string;
    /**
     * Описание бота.
     * @type {string}
     * @memberof AgentUpdatingRequest
     */
    'description'?: string | null;
}
/**
 * 
 * @export
 * @interface AgentWizardConfig
 */
export interface AgentWizardConfig {
    /**
     * 
     * @type {string}
     * @memberof AgentWizardConfig
     */
    'greeting'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof AgentWizardConfig
     */
    'transferToOperatorByUser'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof AgentWizardConfig
     */
    'transferToOperatorInUnknownIntent'?: boolean;
    /**
     * 
     * @type {BinaryKnowledgeData}
     * @memberof AgentWizardConfig
     */
    'knowledgeData'?: BinaryKnowledgeData;
    /**
     * 
     * @type {boolean}
     * @memberof AgentWizardConfig
     */
    'chitchatEnabled'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof AgentWizardConfig
     */
    'chitchatCode'?: string | null;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const AnalyticsEventMode = {
    Testing: 'Testing',
    Release: 'Release'
} as const;

export type AnalyticsEventMode = typeof AnalyticsEventMode[keyof typeof AnalyticsEventMode];


/**
 * Общие данные по аналитике.
 * @export
 * @interface AnalyticsReportsGeneralDataModel
 */
export interface AnalyticsReportsGeneralDataModel {
    /**
     * Общее количество бесед.
     * @type {number}
     * @memberof AnalyticsReportsGeneralDataModel
     */
    'conversationCount'?: number;
}
/**
 * 
 * @export
 * @interface Attachment
 */
export interface Attachment {
    /**
     * 
     * @type {string}
     * @memberof Attachment
     */
    'contentType'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Attachment
     */
    'contentUrl'?: string | null;
    /**
     * 
     * @type {any}
     * @memberof Attachment
     */
    'content'?: any | null;
    /**
     * 
     * @type {string}
     * @memberof Attachment
     */
    'name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Attachment
     */
    'thumbnailUrl'?: string | null;
}
/**
 * 
 * @export
 * @interface AttachmentSchema
 */
export interface AttachmentSchema extends EntitySchema {
    [key: string]: any;

    /**
     * 
     * @type {SchemaKind}
     * @memberof AttachmentSchema
     */
    '$kind': SchemaKind;
    /**
     * 
     * @type {string}
     * @memberof AttachmentSchema
     */
    'fileId': string;
    /**
     * 
     * @type {string}
     * @memberof AttachmentSchema
     */
    'contentType': string;
    /**
     * 
     * @type {string}
     * @memberof AttachmentSchema
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof AttachmentSchema
     */
    'content'?: string | null;
}


/**
 * 
 * @export
 * @enum {string}
 */

export const BinaryConditionOperator = {
    Equals: 'Equals',
    NotEquals: 'NotEquals',
    Greater: 'Greater',
    GreaterOrEquals: 'GreaterOrEquals',
    Less: 'Less',
    LessOrEquals: 'LessOrEquals'
} as const;

export type BinaryConditionOperator = typeof BinaryConditionOperator[keyof typeof BinaryConditionOperator];


/**
 * 
 * @export
 * @interface BinaryConditionSchema
 */
export interface BinaryConditionSchema extends ConditionSchema {
    [key: string]: any;

    /**
     * 
     * @type {SchemaKind}
     * @memberof BinaryConditionSchema
     */
    '$kind': SchemaKind;
    /**
     * 
     * @type {BinaryConditionOperator}
     * @memberof BinaryConditionSchema
     */
    'operator': BinaryConditionOperator;
    /**
     * 
     * @type {OperandSchema}
     * @memberof BinaryConditionSchema
     */
    'leftOperand': OperandSchema;
    /**
     * 
     * @type {OperandSchema}
     * @memberof BinaryConditionSchema
     */
    'rightOperand': OperandSchema;
}


/**
 * 
 * @export
 * @interface BinaryKnowledgeData
 */
export interface BinaryKnowledgeData {
    /**
     * 
     * @type {BinarySourceFormat}
     * @memberof BinaryKnowledgeData
     */
    'format'?: BinarySourceFormat;
    /**
     * 
     * @type {string}
     * @memberof BinaryKnowledgeData
     */
    'data'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BinaryKnowledgeData
     */
    'name'?: string | null;
}


/**
 * 
 * @export
 * @enum {string}
 */

export const BinarySourceFormat = {
    Csv: 'Csv',
    Xls: 'Xls',
    Xlsx: 'Xlsx',
    Doc: 'Doc',
    Docx: 'Docx',
    Pdf: 'Pdf'
} as const;

export type BinarySourceFormat = typeof BinarySourceFormat[keyof typeof BinarySourceFormat];


/**
 * 
 * @export
 * @interface BindingSchema
 */
export interface BindingSchema extends EntitySchema {
    [key: string]: any;

    /**
     * 
     * @type {SchemaKind}
     * @memberof BindingSchema
     */
    '$kind': SchemaKind;
    /**
     * 
     * @type {string}
     * @memberof BindingSchema
     */
    'sourceEntityId': string;
    /**
     * 
     * @type {string}
     * @memberof BindingSchema
     */
    'targetEntityId': string;
}


/**
 * 
 * @export
 * @enum {string}
 */

export const BotContentFormat = {
    Json: 'Json',
    Zip: 'Zip'
} as const;

export type BotContentFormat = typeof BotContentFormat[keyof typeof BotContentFormat];


/**
 * 
 * @export
 * @interface BotCreationRequest
 */
export interface BotCreationRequest {
    /**
     * Название бота.
     * @type {string}
     * @memberof BotCreationRequest
     */
    'botName'?: string | null;
    /**
     * Структура бота.
     * @type {any}
     * @memberof BotCreationRequest
     */
    'botStructure'?: any | null;
    /**
     * 
     * @type {FileContentData}
     * @memberof BotCreationRequest
     */
    'botFile'?: FileContentData;
    /**
     * Код шаблона, на основе которого создается бот.
     * @type {string}
     * @memberof BotCreationRequest
     */
    'botTemplateCode'?: string | null;
}
/**
 * 
 * @export
 * @interface BotCreationResponse
 */
export interface BotCreationResponse {
    /**
     * 
     * @type {BotEntryCreationResponse}
     * @memberof BotCreationResponse
     */
    'entry': BotEntryCreationResponse;
    /**
     * 
     * @type {BotStageCreationResponse}
     * @memberof BotCreationResponse
     */
    'originStage': BotStageCreationResponse;
    /**
     * 
     * @type {BotEditionCreationResponse}
     * @memberof BotCreationResponse
     */
    'originSourceEdition': BotEditionCreationResponse;
    /**
     * 
     * @type {BotEditionCreationResponse}
     * @memberof BotCreationResponse
     */
    'originCurrentEdition': BotEditionCreationResponse;
}
/**
 * 
 * @export
 * @interface BotEditionAddingRequest
 */
export interface BotEditionAddingRequest {
    /**
     * 
     * @type {string}
     * @memberof BotEditionAddingRequest
     */
    'botEditionId': string;
}
/**
 * 
 * @export
 * @interface BotEditionCreationResponse
 */
export interface BotEditionCreationResponse {
    /**
     * 
     * @type {string}
     * @memberof BotEditionCreationResponse
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof BotEditionCreationResponse
     */
    'agentVersionId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BotEditionCreationResponse
     */
    'botEntryId': string;
    /**
     * 
     * @type {string}
     * @memberof BotEditionCreationResponse
     */
    'description': string;
    /**
     * 
     * @type {StructureValidationResult}
     * @memberof BotEditionCreationResponse
     */
    'validation': StructureValidationResult;
}
/**
 * 
 * @export
 * @interface BotEditionModel
 */
export interface BotEditionModel {
    /**
     * ИД редакции бота.
     * @type {string}
     * @memberof BotEditionModel
     */
    'id': string;
    /**
     * ИД версии сложного бота.
     * @type {string}
     * @memberof BotEditionModel
     */
    'agentVersionId'?: string | null;
    /**
     * ИД бота.
     * @type {string}
     * @memberof BotEditionModel
     */
    'botEntryId': string;
    /**
     * Описание.
     * @type {string}
     * @memberof BotEditionModel
     */
    'description': string;
    /**
     * 
     * @type {BotSchema}
     * @memberof BotEditionModel
     */
    'structure': BotSchema;
    /**
     * 
     * @type {StructureValidationResult}
     * @memberof BotEditionModel
     */
    'validation': StructureValidationResult;
}
/**
 * 
 * @export
 * @interface BotEditionReferenceModel
 */
export interface BotEditionReferenceModel {
    /**
     * ИД редакции бота.
     * @type {string}
     * @memberof BotEditionReferenceModel
     */
    'botEditionId': string;
    /**
     * ИД структуры бота.
     * @type {string}
     * @memberof BotEditionReferenceModel
     */
    'botStructureId': string;
    /**
     * Дата создания редакции бота.
     * @type {string}
     * @memberof BotEditionReferenceModel
     */
    'createdOn': string;
    /**
     * 
     * @type {UserReferenceModel}
     * @memberof BotEditionReferenceModel
     */
    'createdBy': UserReferenceModel;
    /**
     * Описание.
     * @type {string}
     * @memberof BotEditionReferenceModel
     */
    'description': string;
    /**
     * Список сценариев.
     * @type {Array<ScenarioEditionReferenceModel>}
     * @memberof BotEditionReferenceModel
     */
    'scenarios': Array<ScenarioEditionReferenceModel>;
}
/**
 * 
 * @export
 * @interface BotEntryChannelModel
 */
export interface BotEntryChannelModel {
    /**
     * Идентификатор аккаунта канала, привязанного к стадии бота.
     * @type {string}
     * @memberof BotEntryChannelModel
     */
    'agentStageAccountId': string;
    /**
     * Идентификатор канала.
     * @type {string}
     * @memberof BotEntryChannelModel
     */
    'channelId': string;
    /**
     * 
     * @type {AgentStageAccountStatus}
     * @memberof BotEntryChannelModel
     */
    'status': AgentStageAccountStatus;
    /**
     * Отображаемое название.
     * @type {string}
     * @memberof BotEntryChannelModel
     */
    'displayName': string;
    /**
     * 
     * @type {AgentStageAccountSetupResult}
     * @memberof BotEntryChannelModel
     */
    'setupResult'?: AgentStageAccountSetupResult;
}


/**
 * 
 * @export
 * @interface BotEntryCreationResponse
 */
export interface BotEntryCreationResponse {
    /**
     * ИД бота.
     * @type {string}
     * @memberof BotEntryCreationResponse
     */
    'id': string;
    /**
     * ИД сложного бота.
     * @type {string}
     * @memberof BotEntryCreationResponse
     */
    'agentId': string;
    /**
     * Название бота.
     * @type {string}
     * @memberof BotEntryCreationResponse
     */
    'name': string;
    /**
     * Код шаблона, на основе которого создан бот.
     * @type {string}
     * @memberof BotEntryCreationResponse
     */
    'botTemplateCode'?: string | null;
}
/**
 * 
 * @export
 * @interface BotEntryModel
 */
export interface BotEntryModel {
    /**
     * ИД бота.
     * @type {string}
     * @memberof BotEntryModel
     */
    'id': string;
    /**
     * ИД сложного бота.
     * @type {string}
     * @memberof BotEntryModel
     */
    'agentId': string;
    /**
     * Название бота.
     * @type {string}
     * @memberof BotEntryModel
     */
    'name': string;
    /**
     * Код шаблона, на основе которого создан бот.
     * @type {string}
     * @memberof BotEntryModel
     */
    'botTemplateCode'?: string | null;
    /**
     * ИД подключенной базы знаний.
     * @type {string}
     * @memberof BotEntryModel
     */
    'knowledgeBaseId'?: string | null;
    /**
     * Список подключенных к боту каналов.
     * @type {Array<BotEntryChannelModel>}
     * @memberof BotEntryModel
     */
    'channels': Array<BotEntryChannelModel>;
}
/**
 * 
 * @export
 * @interface BotExternalEventTrigger
 */
export interface BotExternalEventTrigger {
    /**
     * 
     * @type {string}
     * @memberof BotExternalEventTrigger
     */
    'externalEventId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BotExternalEventTrigger
     */
    'externalEventName'?: string | null;
    /**
     * 
     * @type {{ [key: string]: BotExternalEventVariable; }}
     * @memberof BotExternalEventTrigger
     */
    'parameters'?: { [key: string]: BotExternalEventVariable; } | null;
}
/**
 * 
 * @export
 * @interface BotExternalEventVariable
 */
export interface BotExternalEventVariable {
    /**
     * 
     * @type {string}
     * @memberof BotExternalEventVariable
     */
    'name'?: string | null;
    /**
     * 
     * @type {VariableType}
     * @memberof BotExternalEventVariable
     */
    'type'?: VariableType;
    /**
     * 
     * @type {any}
     * @memberof BotExternalEventVariable
     */
    'value'?: any | null;
    /**
     * 
     * @type {any}
     * @memberof BotExternalEventVariable
     */
    'rawValue'?: any | null;
}


/**
 * 
 * @export
 * @interface BotImportRequest
 */
export interface BotImportRequest {
    /**
     * 
     * @type {FileContentData}
     * @memberof BotImportRequest
     */
    'botFile': FileContentData;
}
/**
 * 
 * @export
 * @interface BotSchema
 */
export interface BotSchema extends EntitySchema {
    [key: string]: any;

    /**
     * 
     * @type {SchemaKind}
     * @memberof BotSchema
     */
    '$kind': SchemaKind;
    /**
     * 
     * @type {string}
     * @memberof BotSchema
     */
    'version': string;
    /**
     * 
     * @type {string}
     * @memberof BotSchema
     */
    'name': string;
    /**
     * 
     * @type {Array<ScenarioSchema>}
     * @memberof BotSchema
     */
    'scenarios': Array<ScenarioSchema>;
    /**
     * 
     * @type {Array<VariableSchema>}
     * @memberof BotSchema
     */
    'variables': Array<VariableSchema>;
    /**
     * 
     * @type {BotSettingsSchema}
     * @memberof BotSchema
     */
    'settings': BotSettingsSchema;
}


/**
 * 
 * @export
 * @interface BotSettingsSchema
 */
export interface BotSettingsSchema extends EntitySchema {
    [key: string]: any;

    /**
     * 
     * @type {SchemaKind}
     * @memberof BotSettingsSchema
     */
    '$kind': SchemaKind;
    /**
     * 
     * @type {ScriptSettingsSchema}
     * @memberof BotSettingsSchema
     */
    'script': ScriptSettingsSchema;
    /**
     * 
     * @type {Array<IntegrationSettingsSchema>}
     * @memberof BotSettingsSchema
     */
    'integrations': Array<IntegrationSettingsSchema>;
    /**
     * 
     * @type {LocalizationSettingsSchema}
     * @memberof BotSettingsSchema
     */
    'localization': LocalizationSettingsSchema;
    /**
     * 
     * @type {SmtpSettingsSchema}
     * @memberof BotSettingsSchema
     */
    'smtp': SmtpSettingsSchema;
    /**
     * 
     * @type {RecognitionSettingsSchema}
     * @memberof BotSettingsSchema
     */
    'recognition': RecognitionSettingsSchema;
    /**
     * 
     * @type {ConversationClosingSettingsSchema}
     * @memberof BotSettingsSchema
     */
    'conversationClosing': ConversationClosingSettingsSchema;
}


/**
 * 
 * @export
 * @enum {string}
 */

export const BotSortDirection = {
    StageModifiedOnDescending: 'StageModifiedOnDescending',
    EntryNameAscending: 'EntryNameAscending'
} as const;

export type BotSortDirection = typeof BotSortDirection[keyof typeof BotSortDirection];


/**
 * 
 * @export
 * @interface BotStageCreationResponse
 */
export interface BotStageCreationResponse {
    /**
     * ИД стадии бота.
     * @type {string}
     * @memberof BotStageCreationResponse
     */
    'id': string;
    /**
     * ИД сложного бота.
     * @type {string}
     * @memberof BotStageCreationResponse
     */
    'agentId': string;
    /**
     * ИД бота.
     * @type {string}
     * @memberof BotStageCreationResponse
     */
    'botEntryId': string;
    /**
     * 
     * @type {BotStageType}
     * @memberof BotStageCreationResponse
     */
    'type': BotStageType;
    /**
     * Дата изменения.
     * @type {string}
     * @memberof BotStageCreationResponse
     */
    'modifiedOn': string;
    /**
     * 
     * @type {BotEditionReferenceModel}
     * @memberof BotStageCreationResponse
     */
    'sourceEdition': BotEditionReferenceModel;
    /**
     * 
     * @type {BotEditionReferenceModel}
     * @memberof BotStageCreationResponse
     */
    'currentEdition': BotEditionReferenceModel;
    /**
     * История изменений.
     * @type {Array<BotEditionReferenceModel>}
     * @memberof BotStageCreationResponse
     */
    'editionHistory': Array<BotEditionReferenceModel>;
}


/**
 * 
 * @export
 * @interface BotStageMergeRequest
 */
export interface BotStageMergeRequest {
    /**
     * Исходная стадия бота.
     * @type {string}
     * @memberof BotStageMergeRequest
     */
    'sourceBotStageId': string;
    /**
     * Краткое описание изменений.
     * @type {string}
     * @memberof BotStageMergeRequest
     */
    'description': string;
    /**
     * Признак, что необходимо перезаписать предыдущие изменения.
     * @type {boolean}
     * @memberof BotStageMergeRequest
     */
    'force': boolean;
}
/**
 * 
 * @export
 * @interface BotStageMergeResponse
 */
export interface BotStageMergeResponse {
    /**
     * 
     * @type {MergeBotStageStatus}
     * @memberof BotStageMergeResponse
     */
    'status': MergeBotStageStatus;
}


/**
 * 
 * @export
 * @interface BotStageModel
 */
export interface BotStageModel {
    /**
     * ИД стадии бота.
     * @type {string}
     * @memberof BotStageModel
     */
    'id': string;
    /**
     * ИД сложного бота.
     * @type {string}
     * @memberof BotStageModel
     */
    'agentId': string;
    /**
     * ИД бота.
     * @type {string}
     * @memberof BotStageModel
     */
    'botEntryId': string;
    /**
     * 
     * @type {BotStageType}
     * @memberof BotStageModel
     */
    'type': BotStageType;
    /**
     * Дата изменения.
     * @type {string}
     * @memberof BotStageModel
     */
    'modifiedOn': string;
    /**
     * 
     * @type {BotEditionReferenceModel}
     * @memberof BotStageModel
     */
    'sourceEdition': BotEditionReferenceModel;
    /**
     * 
     * @type {BotEditionReferenceModel}
     * @memberof BotStageModel
     */
    'currentEdition': BotEditionReferenceModel;
    /**
     * История изменений.
     * @type {Array<BotEditionReferenceModel>}
     * @memberof BotStageModel
     */
    'editionHistory': Array<BotEditionReferenceModel>;
}


/**
 * 
 * @export
 * @interface BotStageTestingResponse
 */
export interface BotStageTestingResponse {
    /**
     * 
     * @type {AgentStageModel}
     * @memberof BotStageTestingResponse
     */
    'agentStage': AgentStageModel;
    /**
     * Ссылка для тестирования в эмуляторе.
     * @type {string}
     * @memberof BotStageTestingResponse
     */
    'emulatorUrl': string;
    /**
     * URL обратного вызова.
     * @type {string}
     * @memberof BotStageTestingResponse
     */
    'callbackUrl': string;
    /**
     * Ссылка для тестирования в веб-чате.
     * @type {string}
     * @memberof BotStageTestingResponse
     */
    'webchatUrl': string;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const BotStageType = {
    Origin: 'Origin',
    Draft: 'Draft'
} as const;

export type BotStageType = typeof BotStageType[keyof typeof BotStageType];


/**
 * Шаблон для создания бота.
 * @export
 * @interface BotTemplateModel
 */
export interface BotTemplateModel {
    /**
     * Код шаблона.
     * @type {string}
     * @memberof BotTemplateModel
     */
    'code': string;
    /**
     * Отображаемое название шаблона.
     * @type {string}
     * @memberof BotTemplateModel
     */
    'name'?: string | null;
    /**
     * Описание.
     * @type {string}
     * @memberof BotTemplateModel
     */
    'description'?: string | null;
    /**
     * Признак того, что шаблон является шаблоном по-умолчанию.
     * @type {boolean}
     * @memberof BotTemplateModel
     */
    'isDefault': boolean;
}
/**
 * 
 * @export
 * @interface BotTrainingModelModel
 */
export interface BotTrainingModelModel {
    /**
     * Код модели.
     * @type {string}
     * @memberof BotTrainingModelModel
     */
    'code': string;
    /**
     * Отображаемое название.
     * @type {string}
     * @memberof BotTrainingModelModel
     */
    'displayName': string;
    /**
     * Описание.
     * @type {string}
     * @memberof BotTrainingModelModel
     */
    'description': string;
}
/**
 * 
 * @export
 * @interface BotUpdatingRequest
 */
export interface BotUpdatingRequest {
    /**
     * ИД базы знаний.
     * @type {string}
     * @memberof BotUpdatingRequest
     */
    'knowledgeBaseId'?: string | null;
    /**
     * Название бота.
     * @type {string}
     * @memberof BotUpdatingRequest
     */
    'name'?: string | null;
    /**
     * 
     * @type {UpdateBotOperationType}
     * @memberof BotUpdatingRequest
     */
    'operationType': UpdateBotOperationType;
}


/**
 * 
 * @export
 * @interface BotVersionCreationRequest
 */
export interface BotVersionCreationRequest {
    /**
     * 
     * @type {string}
     * @memberof BotVersionCreationRequest
     */
    'agentId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BotVersionCreationRequest
     */
    'name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BotVersionCreationRequest
     */
    'description'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BotVersionCreationRequest
     */
    'file'?: string | null;
}
/**
 * 
 * @export
 * @interface BotVersionCreationResponse
 */
export interface BotVersionCreationResponse {
    /**
     * 
     * @type {string}
     * @memberof BotVersionCreationResponse
     */
    'id'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BotVersionCreationResponse
     */
    'agentId'?: string | null;
}
/**
 * 
 * @export
 * @interface BotVersionModel
 */
export interface BotVersionModel {
    /**
     * 
     * @type {string}
     * @memberof BotVersionModel
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof BotVersionModel
     */
    'agentId': string;
    /**
     * 
     * @type {string}
     * @memberof BotVersionModel
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof BotVersionModel
     */
    'description'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof BotVersionModel
     */
    'isStaging': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof BotVersionModel
     */
    'isProduction': boolean;
    /**
     * 
     * @type {string}
     * @memberof BotVersionModel
     */
    'createdOn'?: string;
    /**
     * 
     * @type {string}
     * @memberof BotVersionModel
     */
    'editUrl': string;
    /**
     * 
     * @type {string}
     * @memberof BotVersionModel
     */
    'hash': string;
}
/**
 * 
 * @export
 * @interface BotVersionModelPaginationResponse
 */
export interface BotVersionModelPaginationResponse {
    /**
     * 
     * @type {Array<BotVersionModel>}
     * @memberof BotVersionModelPaginationResponse
     */
    'items'?: Array<BotVersionModel> | null;
    /**
     * 
     * @type {boolean}
     * @memberof BotVersionModelPaginationResponse
     */
    'hasMore'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof BotVersionModelPaginationResponse
     */
    'pageIndex'?: number;
    /**
     * 
     * @type {number}
     * @memberof BotVersionModelPaginationResponse
     */
    'pageSize'?: number;
    /**
     * 
     * @type {number}
     * @memberof BotVersionModelPaginationResponse
     */
    'totalItemCount'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof BotVersionModelPaginationResponse
     */
    'totalPageCount'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof BotVersionModelPaginationResponse
     */
    'fromItemInclusive'?: number;
    /**
     * 
     * @type {number}
     * @memberof BotVersionModelPaginationResponse
     */
    'toItemExclusive'?: number;
}
/**
 * 
 * @export
 * @interface ButtonSchema
 */
export interface ButtonSchema extends EntitySchema {
    [key: string]: any;

    /**
     * 
     * @type {SchemaKind}
     * @memberof ButtonSchema
     */
    '$kind': SchemaKind;
    /**
     * 
     * @type {string}
     * @memberof ButtonSchema
     */
    'value': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof ButtonSchema
     */
    'synonyms': Array<string>;
    /**
     * 
     * @type {string}
     * @memberof ButtonSchema
     */
    'outputBindingId'?: string | null;
    /**
     * 
     * @type {ButtonType}
     * @memberof ButtonSchema
     */
    'type': ButtonType;
    /**
     * 
     * @type {string}
     * @memberof ButtonSchema
     */
    'url'?: string | null;
}


/**
 * 
 * @export
 * @enum {string}
 */

export const ButtonType = {
    Transit: 'Transit',
    Url: 'Url'
} as const;

export type ButtonType = typeof ButtonType[keyof typeof ButtonType];


/**
 * 
 * @export
 * @interface Campaign
 */
export interface Campaign {
    /**
     * 
     * @type {string}
     * @memberof Campaign
     */
    'name'?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof Campaign
     */
    'personRecipients'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof Campaign
     */
    'groupRecipients'?: Array<string> | null;
    /**
     * 
     * @type {string}
     * @memberof Campaign
     */
    'agentId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Campaign
     */
    'agentStageId'?: string | null;
    /**
     * 
     * @type {Array<CampaignAccount>}
     * @memberof Campaign
     */
    'agentStageAccounts'?: Array<CampaignAccount> | null;
    /**
     * 
     * @type {string}
     * @memberof Campaign
     */
    'proactiveDialogId'?: string | null;
    /**
     * 
     * @type {Schedule}
     * @memberof Campaign
     */
    'schedule'?: Schedule;
    /**
     * 
     * @type {{ [key: string]: any; }}
     * @memberof Campaign
     */
    'properties'?: { [key: string]: any; } | null;
    /**
     * 
     * @type {string}
     * @memberof Campaign
     */
    'tenantId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Campaign
     */
    'id'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Campaign
     */
    'version'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Campaign
     */
    'createdBy'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Campaign
     */
    'modifiedBy'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Campaign
     */
    'createdOn'?: string;
    /**
     * 
     * @type {string}
     * @memberof Campaign
     */
    'modifiedOn'?: string;
}
/**
 * 
 * @export
 * @interface CampaignAccount
 */
export interface CampaignAccount {
    /**
     * 
     * @type {string}
     * @memberof CampaignAccount
     */
    'channelId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CampaignAccount
     */
    'accountId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CampaignAccount
     */
    'displayName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CampaignAccount
     */
    'externalName'?: string | null;
}
/**
 * Модель аккаунта рассылки.
 * @export
 * @interface CampaignAccountModel
 */
export interface CampaignAccountModel {
    /**
     * ИД канала.
     * @type {string}
     * @memberof CampaignAccountModel
     */
    'channelId': string;
    /**
     * ИД аккаунта.
     * @type {string}
     * @memberof CampaignAccountModel
     */
    'accountId': string;
    /**
     * Отображаемое имя аккаунта.
     * @type {string}
     * @memberof CampaignAccountModel
     */
    'displayName'?: string | null;
    /**
     * Внешнее имя аккаунта.
     * @type {string}
     * @memberof CampaignAccountModel
     */
    'externalName': string;
}
/**
 * Запрос на создание рассылки.
 * @export
 * @interface CampaignCreationRequest
 */
export interface CampaignCreationRequest {
    /**
     * Название рассылки.
     * @type {string}
     * @memberof CampaignCreationRequest
     */
    'name': string;
    /**
     * Список ИД сотрудников.
     * @type {Array<string>}
     * @memberof CampaignCreationRequest
     */
    'personRecipients': Array<string>;
    /**
     * Список ИД групп.
     * @type {Array<string>}
     * @memberof CampaignCreationRequest
     */
    'groupRecipients'?: Array<string> | null;
    /**
     * ИД бота.
     * @type {string}
     * @memberof CampaignCreationRequest
     */
    'agentId'?: string | null;
    /**
     * ИД стадии бота.
     * @type {string}
     * @memberof CampaignCreationRequest
     */
    'agentStageId'?: string | null;
    /**
     * Аккаунты стадии бота.
     * @type {Array<CampaignAccountModel>}
     * @memberof CampaignCreationRequest
     */
    'agentStageAccounts': Array<CampaignAccountModel>;
    /**
     * ИД проактивного диалога.
     * @type {string}
     * @memberof CampaignCreationRequest
     */
    'proactiveDialogId'?: string | null;
    /**
     * 
     * @type {Schedule}
     * @memberof CampaignCreationRequest
     */
    'schedule'?: Schedule;
    /**
     * Свойства запуска проактивного диалога.
     * @type {{ [key: string]: any; }}
     * @memberof CampaignCreationRequest
     */
    'properties'?: { [key: string]: any; } | null;
}
/**
 * Ответ на создание рассылки.
 * @export
 * @interface CampaignCreationResponse
 */
export interface CampaignCreationResponse {
    /**
     * ИД рассылки.
     * @type {string}
     * @memberof CampaignCreationResponse
     */
    'id': string;
}
/**
 * 
 * @export
 * @interface CampaignJobDescription
 */
export interface CampaignJobDescription {
    /**
     * 
     * @type {string}
     * @memberof CampaignJobDescription
     */
    'id': string;
    /**
     * 
     * @type {Campaign}
     * @memberof CampaignJobDescription
     */
    'campaign'?: Campaign;
    /**
     * 
     * @type {{ [key: string]: Array<DialogProgressState>; }}
     * @memberof CampaignJobDescription
     */
    'states'?: { [key: string]: Array<DialogProgressState>; } | null;
}
/**
 * Запуск рассылки.
 * @export
 * @interface CampaignJobModel
 */
export interface CampaignJobModel {
    /**
     * ИД запуска рассылки.
     * @type {string}
     * @memberof CampaignJobModel
     */
    'id': string;
    /**
     * ИД рассылки.
     * @type {string}
     * @memberof CampaignJobModel
     */
    'campaignId': string;
    /**
     * Отменена.
     * @type {boolean}
     * @memberof CampaignJobModel
     */
    'canceled': boolean;
    /**
     * Завершена.
     * @type {boolean}
     * @memberof CampaignJobModel
     */
    'completed': boolean;
    /**
     * Список конечных получателей, материализованный из групп перед запуском рассылки
     * @type {Array<Person>}
     * @memberof CampaignJobModel
     */
    'personRecipients': Array<Person>;
    /**
     * Результаты рассылок.
     * @type {Array<PersonalCampaignResult>}
     * @memberof CampaignJobModel
     */
    'results': Array<PersonalCampaignResult>;
}
/**
 * 
 * @export
 * @interface CampaignJobModelPaginationResponse
 */
export interface CampaignJobModelPaginationResponse {
    /**
     * 
     * @type {Array<CampaignJobModel>}
     * @memberof CampaignJobModelPaginationResponse
     */
    'items'?: Array<CampaignJobModel> | null;
    /**
     * 
     * @type {boolean}
     * @memberof CampaignJobModelPaginationResponse
     */
    'hasMore'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof CampaignJobModelPaginationResponse
     */
    'pageIndex'?: number;
    /**
     * 
     * @type {number}
     * @memberof CampaignJobModelPaginationResponse
     */
    'pageSize'?: number;
    /**
     * 
     * @type {number}
     * @memberof CampaignJobModelPaginationResponse
     */
    'totalItemCount'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof CampaignJobModelPaginationResponse
     */
    'totalPageCount'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof CampaignJobModelPaginationResponse
     */
    'fromItemInclusive'?: number;
    /**
     * 
     * @type {number}
     * @memberof CampaignJobModelPaginationResponse
     */
    'toItemExclusive'?: number;
}
/**
 * 
 * @export
 * @interface CampaignJobState
 */
export interface CampaignJobState {
    /**
     * 
     * @type {string}
     * @memberof CampaignJobState
     */
    'id'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof CampaignJobState
     */
    'finished'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CampaignJobState
     */
    'hasErrors'?: boolean;
    /**
     * 
     * @type {Array<PersonalCampaignResult>}
     * @memberof CampaignJobState
     */
    'recipientState'?: Array<PersonalCampaignResult> | null;
}
/**
 * Модель рассылки.
 * @export
 * @interface CampaignModel
 */
export interface CampaignModel {
    /**
     * ИД рассылки.
     * @type {string}
     * @memberof CampaignModel
     */
    'id'?: string | null;
    /**
     * Название рассылки.
     * @type {string}
     * @memberof CampaignModel
     */
    'name': string;
    /**
     * Список ИД сотрудников.
     * @type {Array<string>}
     * @memberof CampaignModel
     */
    'personRecipients': Array<string>;
    /**
     * Список ИД групп.
     * @type {Array<string>}
     * @memberof CampaignModel
     */
    'groupRecipients'?: Array<string> | null;
    /**
     * ИД бота.
     * @type {string}
     * @memberof CampaignModel
     */
    'agentId'?: string | null;
    /**
     * ИД стадии бота.
     * @type {string}
     * @memberof CampaignModel
     */
    'agentStageId'?: string | null;
    /**
     * Аккаунты стадии бота.
     * @type {Array<CampaignAccountModel>}
     * @memberof CampaignModel
     */
    'agentStageAccounts': Array<CampaignAccountModel>;
    /**
     * ИД проактивного диалога.
     * @type {string}
     * @memberof CampaignModel
     */
    'proactiveDialogId'?: string | null;
    /**
     * 
     * @type {Schedule}
     * @memberof CampaignModel
     */
    'schedule'?: Schedule;
    /**
     * Дата создания.
     * @type {string}
     * @memberof CampaignModel
     */
    'createdOn'?: string;
    /**
     * Данные по сотрудникам.
     * @type {Array<Person>}
     * @memberof CampaignModel
     */
    'persons'?: Array<Person> | null;
    /**
     * 
     * @type {Agent}
     * @memberof CampaignModel
     */
    'agent'?: Agent;
}
/**
 * 
 * @export
 * @interface CampaignModelPaginationResponse
 */
export interface CampaignModelPaginationResponse {
    /**
     * 
     * @type {Array<CampaignModel>}
     * @memberof CampaignModelPaginationResponse
     */
    'items'?: Array<CampaignModel> | null;
    /**
     * 
     * @type {boolean}
     * @memberof CampaignModelPaginationResponse
     */
    'hasMore'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof CampaignModelPaginationResponse
     */
    'pageIndex'?: number;
    /**
     * 
     * @type {number}
     * @memberof CampaignModelPaginationResponse
     */
    'pageSize'?: number;
    /**
     * 
     * @type {number}
     * @memberof CampaignModelPaginationResponse
     */
    'totalItemCount'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof CampaignModelPaginationResponse
     */
    'totalPageCount'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof CampaignModelPaginationResponse
     */
    'fromItemInclusive'?: number;
    /**
     * 
     * @type {number}
     * @memberof CampaignModelPaginationResponse
     */
    'toItemExclusive'?: number;
}
/**
 * 
 * @export
 * @interface CardAction
 */
export interface CardAction {
    /**
     * 
     * @type {string}
     * @memberof CardAction
     */
    'type'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CardAction
     */
    'title'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CardAction
     */
    'image'?: string | null;
    /**
     * 
     * @type {any}
     * @memberof CardAction
     */
    'value'?: any | null;
}
/**
 * 
 * @export
 * @interface CaseActionSchema
 */
export interface CaseActionSchema extends ActionSchema {
    [key: string]: any;

    /**
     * 
     * @type {string}
     * @memberof CaseActionSchema
     */
    '$kind': string;
    /**
     * 
     * @type {string}
     * @memberof CaseActionSchema
     */
    'outputBindingId'?: string | null;
}
/**
 * 
 * @export
 * @interface ChannelAccount
 */
export interface ChannelAccount {
    /**
     * 
     * @type {string}
     * @memberof ChannelAccount
     */
    'id'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ChannelAccount
     */
    'name'?: string | null;
}
/**
 * 
 * @export
 * @interface ChoiceTransitSchema
 */
export interface ChoiceTransitSchema extends TransitActionSchema {
    [key: string]: any;

    /**
     * 
     * @type {SchemaKind}
     * @memberof ChoiceTransitSchema
     */
    '$kind': SchemaKind;
}


/**
 * 
 * @export
 * @interface CloseConversationSchema
 */
export interface CloseConversationSchema extends ActionSchema {
    [key: string]: any;

    /**
     * 
     * @type {SchemaKind}
     * @memberof CloseConversationSchema
     */
    '$kind': SchemaKind;
    /**
     * 
     * @type {string}
     * @memberof CloseConversationSchema
     */
    'closingMessage': string;
}


/**
 * 
 * @export
 * @interface CommandTriggerSchema
 */
export interface CommandTriggerSchema extends TriggerSchema {
    [key: string]: any;

    /**
     * 
     * @type {SchemaKind}
     * @memberof CommandTriggerSchema
     */
    '$kind': SchemaKind;
    /**
     * 
     * @type {string}
     * @memberof CommandTriggerSchema
     */
    'command': string;
    /**
     * 
     * @type {string}
     * @memberof CommandTriggerSchema
     */
    'description'?: string | null;
}


/**
 * 
 * @export
 * @interface ComparisonValidatorSchema
 */
export interface ComparisonValidatorSchema extends ValidatorSchema {
    [key: string]: any;

    /**
     * 
     * @type {string}
     * @memberof ComparisonValidatorSchema
     */
    '$kind': string;
    /**
     * 
     * @type {BinaryConditionOperator}
     * @memberof ComparisonValidatorSchema
     */
    'operator': BinaryConditionOperator;
}
/**
 * 
 * @export
 * @interface ConditionSchema
 */
export interface ConditionSchema extends EntitySchema {
    [key: string]: any;

    /**
     * 
     * @type {string}
     * @memberof ConditionSchema
     */
    '$kind': string;
}
/**
 * 
 * @export
 * @interface ConfirmTransitSchema
 */
export interface ConfirmTransitSchema extends TransitActionSchema {
    [key: string]: any;

    /**
     * 
     * @type {SchemaKind}
     * @memberof ConfirmTransitSchema
     */
    '$kind': SchemaKind;
}


/**
 * 
 * @export
 * @interface ConstantOperandSchema
 */
export interface ConstantOperandSchema extends OperandSchema {
    [key: string]: any;

    /**
     * 
     * @type {SchemaKind}
     * @memberof ConstantOperandSchema
     */
    '$kind': SchemaKind;
    /**
     * 
     * @type {any}
     * @memberof ConstantOperandSchema
     */
    'value'?: any | null;
}


/**
 * 
 * @export
 * @enum {string}
 */

export const ContactIdentifierKind = {
    Phone: 'phone',
    Email: 'email'
} as const;

export type ContactIdentifierKind = typeof ContactIdentifierKind[keyof typeof ContactIdentifierKind];


/**
 * Элемент контекста.
 * @export
 * @interface ContextElement
 */
export interface ContextElement {
    [key: string]: any;

    /**
     * 
     * @type {string}
     * @memberof ContextElement
     */
    'code': string;
    /**
     * 
     * @type {string}
     * @memberof ContextElement
     */
    'type': string;
    /**
     * 
     * @type {boolean}
     * @memberof ContextElement
     */
    'searchable': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ContextElement
     */
    'indexed': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ContextElement
     */
    'deleted': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ContextElement
     */
    'array': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ContextElement
     */
    'required': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ContextElement
     */
    'single': boolean;
    /**
     * 
     * @type {any}
     * @memberof ContextElement
     */
    'defaultValue'?: any | null;
    /**
     * 
     * @type {boolean}
     * @memberof ContextElement
     */
    'calcByFormula': boolean;
    /**
     * 
     * @type {string}
     * @memberof ContextElement
     */
    'formula': string;
    /**
     * 
     * @type {ContextElementData}
     * @memberof ContextElement
     */
    'data': ContextElementData;
    /**
     * 
     * @type {ContextElementView}
     * @memberof ContextElement
     */
    'view': ContextElementView;
}
/**
 * Данные элемента контекста.
 * @export
 * @interface ContextElementData
 */
export interface ContextElementData {
    [key: string]: any;

    /**
     * 
     * @type {Array<EnumItem>}
     * @memberof ContextElementData
     */
    'enumItems'?: Array<EnumItem> | null;
}
/**
 * Настройки отображения элемента контекста.
 * @export
 * @interface ContextElementView
 */
export interface ContextElementView {
    [key: string]: any;

    /**
     * 
     * @type {string}
     * @memberof ContextElementView
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof ContextElementView
     */
    'tooltip'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof ContextElementView
     */
    'system'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof ContextElementView
     */
    'hidden'?: boolean | null;
    /**
     * 
     * @type {ContextElementViewData}
     * @memberof ContextElementView
     */
    'data'?: ContextElementViewData;
}
/**
 * Данные для настройки отображения элемента контекста.
 * @export
 * @interface ContextElementViewData
 */
export interface ContextElementViewData {
    [key: string]: any;

    /**
     * 
     * @type {string}
     * @memberof ContextElementViewData
     */
    'additionalType'?: string | null;
    /**
     * Валюта.
     * @type {string}
     * @memberof ContextElementViewData
     */
    'currency'?: string | null;
    /**
     * Дополнительное уточнение типа параметра.
     * @type {any}
     * @memberof ContextElementViewData
     */
    'type'?: any | null;
}
/**
 * 
 * @export
 * @interface Conversation
 */
export interface Conversation {
    /**
     * 
     * @type {string}
     * @memberof Conversation
     */
    'conversationId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Conversation
     */
    'token'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof Conversation
     */
    'expires_in'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof Conversation
     */
    'streamUrl'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Conversation
     */
    'referenceGrammarId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Conversation
     */
    'eTag'?: string | null;
}
/**
 * 
 * @export
 * @interface ConversationAccount
 */
export interface ConversationAccount {
    /**
     * 
     * @type {boolean}
     * @memberof ConversationAccount
     */
    'isGroup'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof ConversationAccount
     */
    'id'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ConversationAccount
     */
    'name'?: string | null;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const ConversationClosingReason = {
    TimedOut: 'TimedOut',
    ClosedByScenarioAction: 'ClosedByScenarioAction',
    ClosedByOperator: 'ClosedByOperator',
    ConversationRestarted: 'ConversationRestarted'
} as const;

export type ConversationClosingReason = typeof ConversationClosingReason[keyof typeof ConversationClosingReason];


/**
 * 
 * @export
 * @interface ConversationClosingSettingsSchema
 */
export interface ConversationClosingSettingsSchema {
    /**
     * 
     * @type {boolean}
     * @memberof ConversationClosingSettingsSchema
     */
    'closingEnabled': boolean;
    /**
     * 
     * @type {number}
     * @memberof ConversationClosingSettingsSchema
     */
    'closingTimeoutSeconds'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ConversationClosingSettingsSchema
     */
    'closingMessage'?: string | null;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const ConversationClosingStatus = {
    NotInitiated: 'NotInitiated',
    Initiated: 'Initiated',
    ClosingMessageSent: 'ClosingMessageSent',
    DialogResetEventSent: 'DialogResetEventSent',
    SessionClosed: 'SessionClosed'
} as const;

export type ConversationClosingStatus = typeof ConversationClosingStatus[keyof typeof ConversationClosingStatus];


/**
 * 
 * @export
 * @interface ConversationExportFilterParams
 */
export interface ConversationExportFilterParams {
    /**
     * 
     * @type {string}
     * @memberof ConversationExportFilterParams
     */
    'agentStageId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ConversationExportFilterParams
     */
    'startFromDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ConversationExportFilterParams
     */
    'startToDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ConversationExportFilterParams
     */
    'latestMessageFromDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ConversationExportFilterParams
     */
    'latestMessageToDate'?: string | null;
    /**
     * 
     * @type {ConversationStatus}
     * @memberof ConversationExportFilterParams
     */
    'status'?: ConversationStatus;
    /**
     * 
     * @type {string}
     * @memberof ConversationExportFilterParams
     */
    'channelId'?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof ConversationExportFilterParams
     */
    'ids'?: Array<string> | null;
}


/**
 * 
 * @export
 * @enum {string}
 */

export const ConversationExportStatus = {
    Processing: 'Processing',
    Success: 'Success',
    Error: 'Error'
} as const;

export type ConversationExportStatus = typeof ConversationExportStatus[keyof typeof ConversationExportStatus];


/**
 * 
 * @export
 * @interface ConversationExportStatusResultModel
 */
export interface ConversationExportStatusResultModel {
    /**
     * 
     * @type {ConversationExportStatus}
     * @memberof ConversationExportStatusResultModel
     */
    'status'?: ConversationExportStatus;
    /**
     * 
     * @type {string}
     * @memberof ConversationExportStatusResultModel
     */
    'fileUrl'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ConversationExportStatusResultModel
     */
    'errorMessage'?: string | null;
}


/**
 * Модель беседы.
 * @export
 * @interface ConversationModel
 */
export interface ConversationModel {
    /**
     * ИД беседы.
     * @type {string}
     * @memberof ConversationModel
     */
    'id': string;
    /**
     * Внешний ИД беседы.
     * @type {string}
     * @memberof ConversationModel
     */
    'externalId': string;
    /**
     * ИД канала.
     * @type {string}
     * @memberof ConversationModel
     */
    'channelId': string;
    /**
     * ИД бота.
     * @type {string}
     * @memberof ConversationModel
     */
    'agentId': string;
    /**
     * ИД стадии бота.
     * @type {string}
     * @memberof ConversationModel
     */
    'agentStageId': string;
    /**
     * ИД сотрудника.
     * @type {string}
     * @memberof ConversationModel
     */
    'personId': string;
    /**
     * Дата и время когда беседа была начата.
     * @type {string}
     * @memberof ConversationModel
     */
    'startedOn': string;
    /**
     * Дата и время когда беседа завершилась.
     * @type {string}
     * @memberof ConversationModel
     */
    'finishedOn': string;
    /**
     * Service Url беседы.
     * @type {string}
     * @memberof ConversationModel
     */
    'serviceUrl': string;
    /**
     * Общее количество сообщений в беседе (activity с типом \"message\").
     * @type {number}
     * @memberof ConversationModel
     */
    'messagesCount': number;
    /**
     * Свойства беседы.
     * @type {any}
     * @memberof ConversationModel
     */
    'properties'?: any | null;
    /**
     * Текст последнего сообщения или null, если сообщений нет.
     * @type {string}
     * @memberof ConversationModel
     */
    'latestMessageText'?: string | null;
    /**
     * 
     * @type {ActivityDirection}
     * @memberof ConversationModel
     */
    'latestMessageDirection'?: ActivityDirection;
    /**
     * Дата и время последнего сообщения в беседе.
     * @type {string}
     * @memberof ConversationModel
     */
    'latestMessageOn'?: string | null;
    /**
     * 
     * @type {ConversationStatus}
     * @memberof ConversationModel
     */
    'status'?: ConversationStatus;
    /**
     * 
     * @type {ConversationClosingStatus}
     * @memberof ConversationModel
     */
    'closingStatus'?: ConversationClosingStatus;
    /**
     * 
     * @type {ConversationClosingReason}
     * @memberof ConversationModel
     */
    'closingReason'?: ConversationClosingReason;
    /**
     * Имя участника беседы
     * @type {string}
     * @memberof ConversationModel
     */
    'userName'?: string | null;
}


/**
 * 
 * @export
 * @interface ConversationModelPaginationResponse
 */
export interface ConversationModelPaginationResponse {
    /**
     * 
     * @type {Array<ConversationModel>}
     * @memberof ConversationModelPaginationResponse
     */
    'items'?: Array<ConversationModel> | null;
    /**
     * 
     * @type {boolean}
     * @memberof ConversationModelPaginationResponse
     */
    'hasMore'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof ConversationModelPaginationResponse
     */
    'pageIndex'?: number;
    /**
     * 
     * @type {number}
     * @memberof ConversationModelPaginationResponse
     */
    'pageSize'?: number;
    /**
     * 
     * @type {number}
     * @memberof ConversationModelPaginationResponse
     */
    'totalItemCount'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ConversationModelPaginationResponse
     */
    'totalPageCount'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ConversationModelPaginationResponse
     */
    'fromItemInclusive'?: number;
    /**
     * 
     * @type {number}
     * @memberof ConversationModelPaginationResponse
     */
    'toItemExclusive'?: number;
}
/**
 * 
 * @export
 * @interface ConversationReference
 */
export interface ConversationReference {
    /**
     * 
     * @type {string}
     * @memberof ConversationReference
     */
    'activityId'?: string | null;
    /**
     * 
     * @type {ChannelAccount}
     * @memberof ConversationReference
     */
    'user'?: ChannelAccount;
    /**
     * 
     * @type {ChannelAccount}
     * @memberof ConversationReference
     */
    'bot'?: ChannelAccount;
    /**
     * 
     * @type {ConversationAccount}
     * @memberof ConversationReference
     */
    'conversation'?: ConversationAccount;
    /**
     * 
     * @type {string}
     * @memberof ConversationReference
     */
    'channelId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ConversationReference
     */
    'serviceUrl'?: string | null;
}
/**
 * 
 * @export
 * @interface ConversationSettings
 */
export interface ConversationSettings {
    /**
     * 
     * @type {boolean}
     * @memberof ConversationSettings
     */
    'timeoutEnabled'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ConversationSettings
     */
    'closingTimeout'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ConversationSettings
     */
    'closingMessage'?: string | null;
    /**
     * 
     * @type {Elma365IntegrationSettings}
     * @memberof ConversationSettings
     */
    'elma365IntegrationSettings'?: Elma365IntegrationSettings;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const ConversationSortDirection = {
    None: 'None',
    StartedOnAscending: 'StartedOnAscending',
    StartedOnDescending: 'StartedOnDescending',
    FinishedOnAscending: 'FinishedOnAscending',
    FinishedOnDescending: 'FinishedOnDescending',
    LatestMessageOnAscending: 'LatestMessageOnAscending',
    LatestMessageOnDescending: 'LatestMessageOnDescending'
} as const;

export type ConversationSortDirection = typeof ConversationSortDirection[keyof typeof ConversationSortDirection];


/**
 * 
 * @export
 * @enum {string}
 */

export const ConversationStatus = {
    Active: 'Active',
    Closed: 'Closed',
    Operator: 'Operator'
} as const;

export type ConversationStatus = typeof ConversationStatus[keyof typeof ConversationStatus];


/**
 * Аналитика по закрытию бесед в день.
 * @export
 * @interface ConversationsClosedPerDayModel
 */
export interface ConversationsClosedPerDayModel {
    /**
     * Дата.
     * @type {string}
     * @memberof ConversationsClosedPerDayModel
     */
    'date': string;
    /**
     * Количество закрытых бесед оператором.
     * @type {number}
     * @memberof ConversationsClosedPerDayModel
     */
    'byOperatorCount': number;
    /**
     * Количество закрытых бесед элементом сценария.
     * @type {number}
     * @memberof ConversationsClosedPerDayModel
     */
    'byScenarioActionCount': number;
    /**
     * Количество закрытых бесед по таймауту.
     * @type {number}
     * @memberof ConversationsClosedPerDayModel
     */
    'timedOutCount': number;
    /**
     * Количество закрытых бесед по событию рестарта беседы.
     * @type {number}
     * @memberof ConversationsClosedPerDayModel
     */
    'conversationRestartedCount': number;
    /**
     * Общее количество закрытых бесед.
     * @type {number}
     * @memberof ConversationsClosedPerDayModel
     */
    'totalCount': number;
}
/**
 * Аналитика по закрытым беседам в разрезе сценария.
 * @export
 * @interface ConversationsClosedPerScenarioModel
 */
export interface ConversationsClosedPerScenarioModel {
    /**
     * Название сценария.
     * @type {string}
     * @memberof ConversationsClosedPerScenarioModel
     */
    'scenarioName': string;
    /**
     * ИД сценария.
     * @type {string}
     * @memberof ConversationsClosedPerScenarioModel
     */
    'scenarioStructureId': string;
    /**
     * Количество закрытых бесед оператором.
     * @type {number}
     * @memberof ConversationsClosedPerScenarioModel
     */
    'byOperatorCount': number;
    /**
     * Количество закрытых бесед элементом сценария.
     * @type {number}
     * @memberof ConversationsClosedPerScenarioModel
     */
    'byScenarioActionCount': number;
    /**
     * Количество закрытых бесед по таймауту.
     * @type {number}
     * @memberof ConversationsClosedPerScenarioModel
     */
    'timedOutCount': number;
    /**
     * Количество закрытых бесед по событию рестарта беседы.
     * @type {number}
     * @memberof ConversationsClosedPerScenarioModel
     */
    'conversationRestartedCount': number;
    /**
     * Общее количество закрытых бесед.
     * @type {number}
     * @memberof ConversationsClosedPerScenarioModel
     */
    'totalCount': number;
}
/**
 * Аналитика по беседам в день.
 * @export
 * @interface ConversationsPerDayModel
 */
export interface ConversationsPerDayModel {
    /**
     * Дата.
     * @type {string}
     * @memberof ConversationsPerDayModel
     */
    'date': string;
    /**
     * Общее количество бесед.
     * @type {number}
     * @memberof ConversationsPerDayModel
     */
    'totalCount': number;
    /**
     * Количество бесед по каждому каналу.
     * @type {{ [key: string]: number; }}
     * @memberof ConversationsPerDayModel
     */
    'channelCount': { [key: string]: number; };
}
/**
 * 
 * @export
 * @interface CreateElma365AppElementActionSchema
 */
export interface CreateElma365AppElementActionSchema extends ActionSchema {
    [key: string]: any;

    /**
     * 
     * @type {SchemaKind}
     * @memberof CreateElma365AppElementActionSchema
     */
    '$kind': SchemaKind;
    /**
     * 
     * @type {string}
     * @memberof CreateElma365AppElementActionSchema
     */
    'appCode'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateElma365AppElementActionSchema
     */
    'appNamespace'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateElma365AppElementActionSchema
     */
    'appUpdatedAt'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateElma365AppElementActionSchema
     */
    'appTitle'?: string | null;
    /**
     * 
     * @type {Array<Elma365FormPropertySchema>}
     * @memberof CreateElma365AppElementActionSchema
     */
    'formProperties': Array<Elma365FormPropertySchema>;
    /**
     * 
     * @type {string}
     * @memberof CreateElma365AppElementActionSchema
     */
    'variableId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateElma365AppElementActionSchema
     */
    'outputBindingId'?: string | null;
    /**
     * 
     * @type {Elma365SessionType}
     * @memberof CreateElma365AppElementActionSchema
     */
    'sessionType'?: Elma365SessionType;
}


/**
 * 
 * @export
 * @interface CurrentDateTimeComparisonValidatorSchema
 */
export interface CurrentDateTimeComparisonValidatorSchema extends ComparisonValidatorSchema {
    [key: string]: any;

    /**
     * 
     * @type {SchemaKind}
     * @memberof CurrentDateTimeComparisonValidatorSchema
     */
    '$kind': SchemaKind;
}


/**
 * Аналитика по пользовательским событиям в день.
 * @export
 * @interface CustomEventsPerDayModel
 */
export interface CustomEventsPerDayModel {
    /**
     * Дата.
     * @type {string}
     * @memberof CustomEventsPerDayModel
     */
    'date': string;
    /**
     * Общее количество пользовательских событий в день.
     * @type {number}
     * @memberof CustomEventsPerDayModel
     */
    'totalCount': number;
    /**
     * Топ пользовательских событий.
     * @type {{ [key: string]: number; }}
     * @memberof CustomEventsPerDayModel
     */
    'topEvents': { [key: string]: number; };
}
/**
 * 
 * @export
 * @interface DateTimeInputSchema
 */
export interface DateTimeInputSchema extends InputActionSchema {
    [key: string]: any;

    /**
     * 
     * @type {SchemaKind}
     * @memberof DateTimeInputSchema
     */
    '$kind': SchemaKind;
}


/**
 * 
 * @export
 * @interface DateTimeTypeValidatorSchema
 */
export interface DateTimeTypeValidatorSchema extends ValidatorSchema {
    [key: string]: any;

    /**
     * 
     * @type {SchemaKind}
     * @memberof DateTimeTypeValidatorSchema
     */
    '$kind': SchemaKind;
    /**
     * 
     * @type {DateTimeValueType}
     * @memberof DateTimeTypeValidatorSchema
     */
    'expectedType': DateTimeValueType;
}


/**
 * 
 * @export
 * @interface DateTimeValueComparisonValidatorSchema
 */
export interface DateTimeValueComparisonValidatorSchema extends ValueComparisonValidatorSchema {
    [key: string]: any;

    /**
     * 
     * @type {SchemaKind}
     * @memberof DateTimeValueComparisonValidatorSchema
     */
    '$kind': SchemaKind;
    /**
     * 
     * @type {string}
     * @memberof DateTimeValueComparisonValidatorSchema
     */
    'value': string;
}


/**
 * 
 * @export
 * @enum {string}
 */

export const DateTimeValueType = {
    DateWithTime: 'DateWithTime',
    Date: 'Date',
    Time: 'Time'
} as const;

export type DateTimeValueType = typeof DateTimeValueType[keyof typeof DateTimeValueType];


/**
 * 
 * @export
 * @interface DefaultActionGroupSchema
 */
export interface DefaultActionGroupSchema extends ActionGroupSchema {
    [key: string]: any;

    /**
     * 
     * @type {SchemaKind}
     * @memberof DefaultActionGroupSchema
     */
    '$kind': SchemaKind;
    /**
     * 
     * @type {Array<ActionSchema>}
     * @memberof DefaultActionGroupSchema
     */
    'actions': Array<ActionSchema>;
    /**
     * 
     * @type {Array<string>}
     * @memberof DefaultActionGroupSchema
     */
    'inputBindingIds': Array<string>;
    /**
     * 
     * @type {string}
     * @memberof DefaultActionGroupSchema
     */
    'outputBindingId'?: string | null;
}


/**
 * 
 * @export
 * @interface DefaultScenarioSchema
 */
export interface DefaultScenarioSchema extends ScenarioSchema {
    [key: string]: any;

    /**
     * 
     * @type {SchemaKind}
     * @memberof DefaultScenarioSchema
     */
    '$kind': SchemaKind;
    /**
     * 
     * @type {TriggerGroupSchema}
     * @memberof DefaultScenarioSchema
     */
    'triggerGroup': TriggerGroupSchema;
    /**
     * 
     * @type {Array<ActionGroupSchema>}
     * @memberof DefaultScenarioSchema
     */
    'actionGroups': Array<ActionGroupSchema>;
    /**
     * 
     * @type {Array<IntentContentSchema>}
     * @memberof DefaultScenarioSchema
     */
    'intents': Array<IntentContentSchema>;
    /**
     * 
     * @type {Array<BindingSchema>}
     * @memberof DefaultScenarioSchema
     */
    'bindings': Array<BindingSchema>;
}


/**
 * 
 * @export
 * @interface DefaultTriggerGroupSchema
 */
export interface DefaultTriggerGroupSchema extends TriggerGroupSchema {
    [key: string]: any;

    /**
     * 
     * @type {SchemaKind}
     * @memberof DefaultTriggerGroupSchema
     */
    '$kind': SchemaKind;
    /**
     * 
     * @type {Array<TriggerSchema>}
     * @memberof DefaultTriggerGroupSchema
     */
    'triggers': Array<TriggerSchema>;
}


/**
 * 
 * @export
 * @interface DesignerSchema
 */
export interface DesignerSchema {
    [key: string]: any;

    /**
     * 
     * @type {string}
     * @memberof DesignerSchema
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof DesignerSchema
     */
    'name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DesignerSchema
     */
    'description'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof DesignerSchema
     */
    'positionX'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof DesignerSchema
     */
    'positionY'?: number | null;
}
/**
 * 
 * @export
 * @interface DialogProgressState
 */
export interface DialogProgressState {
    /**
     * 
     * @type {number}
     * @memberof DialogProgressState
     */
    'number': number;
    /**
     * 
     * @type {string}
     * @memberof DialogProgressState
     */
    'code': string;
    /**
     * 
     * @type {string}
     * @memberof DialogProgressState
     */
    'name': string;
    /**
     * 
     * @type {boolean}
     * @memberof DialogProgressState
     */
    'isFinal': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DialogProgressState
     */
    'isError': boolean;
}
/**
 * Модель токена для беседы в канале DirectLine.
 * @export
 * @interface DirectLineTokenModel
 */
export interface DirectLineTokenModel {
    /**
     * Токен.
     * @type {string}
     * @memberof DirectLineTokenModel
     */
    'token': string;
    /**
     * Внешний ИД беседы.
     * @type {string}
     * @memberof DirectLineTokenModel
     */
    'conversationId': string;
    /**
     * ИД пользователя.
     * @type {string}
     * @memberof DirectLineTokenModel
     */
    'userId': string;
}
/**
 * Запрос на получение внешнего события.
 * @export
 * @interface DispatchExternalEventRequest
 */
export interface DispatchExternalEventRequest {
    /**
     * ИД беседы.
     * @type {string}
     * @memberof DispatchExternalEventRequest
     */
    'conversationId'?: string | null;
    /**
     * Внешний ИД беседы.
     * @type {string}
     * @memberof DispatchExternalEventRequest
     */
    'externalConversationId'?: string | null;
    /**
     * ИД внешнего события.
     * @type {string}
     * @memberof DispatchExternalEventRequest
     */
    'externalEventId': string;
    /**
     * Коллекция значений переменных, которые будут переданы в сценарий в момент запуска события.
     * @type {{ [key: string]: any; }}
     * @memberof DispatchExternalEventRequest
     */
    'externalEventPayload'?: { [key: string]: any; } | null;
}
/**
 * Приложение ELMA365.
 * @export
 * @interface Elma365App
 */
export interface Elma365App {
    [key: string]: any;

    /**
     * ИД приложения.
     * @type {string}
     * @memberof Elma365App
     */
    '__id': string;
    /**
     * Название приложения.
     * @type {string}
     * @memberof Elma365App
     */
    'name': string;
    /**
     * Название элемента приложения.
     * @type {string}
     * @memberof Elma365App
     */
    'elementName': string;
    /**
     * Код раздела.
     * @type {string}
     * @memberof Elma365App
     */
    'namespace': string;
    /**
     * Код.
     * @type {string}
     * @memberof Elma365App
     */
    'code': string;
    /**
     * Тип.
     * @type {string}
     * @memberof Elma365App
     */
    'type': string;
    /**
     * Поля.
     * @type {Array<ContextElement>}
     * @memberof Elma365App
     */
    'fields': Array<ContextElement>;
    /**
     * Фильтры.
     * @type {any}
     * @memberof Elma365App
     */
    'filters'?: any | null;
    /**
     * Настройки.
     * @type {any}
     * @memberof Elma365App
     */
    'settings': any;
    /**
     * Дата изменения.
     * @type {string}
     * @memberof Elma365App
     */
    '__updatedAt': string;
}
/**
 * 
 * @export
 * @interface Elma365AppElementInputSchema
 */
export interface Elma365AppElementInputSchema extends InputActionSchema {
    [key: string]: any;

    /**
     * 
     * @type {SchemaKind}
     * @memberof Elma365AppElementInputSchema
     */
    '$kind': SchemaKind;
    /**
     * 
     * @type {string}
     * @memberof Elma365AppElementInputSchema
     */
    'appCode'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Elma365AppElementInputSchema
     */
    'appNamespace'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Elma365AppElementInputSchema
     */
    'appTitle'?: string | null;
}


/**
 * Шаблон бизнес-процесса ELMA365.
 * @export
 * @interface Elma365BpmTemplate
 */
export interface Elma365BpmTemplate {
    [key: string]: any;

    /**
     * ИД шаблона.
     * @type {string}
     * @memberof Elma365BpmTemplate
     */
    '__id': string;
    /**
     * Код раздела.
     * @type {string}
     * @memberof Elma365BpmTemplate
     */
    'namespace': string;
    /**
     * Код.
     * @type {string}
     * @memberof Elma365BpmTemplate
     */
    'code': string;
    /**
     * Категория.
     * @type {string}
     * @memberof Elma365BpmTemplate
     */
    'category': string;
    /**
     * Название шаблона.
     * @type {string}
     * @memberof Elma365BpmTemplate
     */
    '__name': string;
    /**
     * Тип.
     * @type {string}
     * @memberof Elma365BpmTemplate
     */
    'type': string;
    /**
     * Признак черновика.
     * @type {boolean}
     * @memberof Elma365BpmTemplate
     */
    'draft': boolean;
    /**
     * Версия.
     * @type {number}
     * @memberof Elma365BpmTemplate
     */
    'version': number;
    /**
     * 
     * @type {Process}
     * @memberof Elma365BpmTemplate
     */
    'process': Process;
    /**
     * Кoнтекст.
     * @type {Array<ContextElement>}
     * @memberof Elma365BpmTemplate
     */
    'context': Array<ContextElement>;
    /**
     * Возможность ручного запуска.
     * @type {boolean}
     * @memberof Elma365BpmTemplate
     */
    'manualRun': boolean;
    /**
     * Скрыть в списке.
     * @type {boolean}
     * @memberof Elma365BpmTemplate
     */
    'hideInList': boolean;
    /**
     * Только для чтения.
     * @type {boolean}
     * @memberof Elma365BpmTemplate
     */
    'readonly': boolean;
}
/**
 * 
 * @export
 * @interface Elma365EnumInputSchema
 */
export interface Elma365EnumInputSchema extends InputActionSchema {
    [key: string]: any;

    /**
     * 
     * @type {SchemaKind}
     * @memberof Elma365EnumInputSchema
     */
    '$kind': SchemaKind;
    /**
     * 
     * @type {Elma365EnumSourceType}
     * @memberof Elma365EnumInputSchema
     */
    'enumSourceType': Elma365EnumSourceType;
    /**
     * 
     * @type {string}
     * @memberof Elma365EnumInputSchema
     */
    'appCode'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Elma365EnumInputSchema
     */
    'appNamespace'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Elma365EnumInputSchema
     */
    'bpmTemplateNamespace'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Elma365EnumInputSchema
     */
    'bpmTemplateCode'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Elma365EnumInputSchema
     */
    'enumCode'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Elma365EnumInputSchema
     */
    'enumName'?: string | null;
}


/**
 * 
 * @export
 * @enum {string}
 */

export const Elma365EnumSourceType = {
    Application: 'Application',
    Process: 'Process'
} as const;

export type Elma365EnumSourceType = typeof Elma365EnumSourceType[keyof typeof Elma365EnumSourceType];


/**
 * 
 * @export
 * @interface Elma365FormPropertySchema
 */
export interface Elma365FormPropertySchema extends EntitySchema {
    [key: string]: any;

    /**
     * 
     * @type {SchemaKind}
     * @memberof Elma365FormPropertySchema
     */
    '$kind': SchemaKind;
    /**
     * 
     * @type {string}
     * @memberof Elma365FormPropertySchema
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof Elma365FormPropertySchema
     */
    'code': string;
    /**
     * 
     * @type {boolean}
     * @memberof Elma365FormPropertySchema
     */
    'required'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof Elma365FormPropertySchema
     */
    'dataType': string;
    /**
     * 
     * @type {string}
     * @memberof Elma365FormPropertySchema
     */
    'additionalDataType'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Elma365FormPropertySchema
     */
    'variableId'?: string | null;
}


/**
 * 
 * @export
 * @interface Elma365IntegrationSettings
 */
export interface Elma365IntegrationSettings {
    /**
     * 
     * @type {string}
     * @memberof Elma365IntegrationSettings
     */
    'apiUrl'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Elma365IntegrationSettings
     */
    'integrationId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Elma365IntegrationSettings
     */
    'organizationId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Elma365IntegrationSettings
     */
    'xToken'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Elma365IntegrationSettings
     */
    'name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Elma365IntegrationSettings
     */
    'code'?: string | null;
}
/**
 * 
 * @export
 * @interface Elma365IntegrationSettingsSchema
 */
export interface Elma365IntegrationSettingsSchema extends IntegrationSettingsSchema {
    [key: string]: any;

    /**
     * 
     * @type {SchemaKind}
     * @memberof Elma365IntegrationSettingsSchema
     */
    '$kind': SchemaKind;
    /**
     * 
     * @type {string}
     * @memberof Elma365IntegrationSettingsSchema
     */
    'apiUrl': string;
    /**
     * 
     * @type {string}
     * @memberof Elma365IntegrationSettingsSchema
     */
    'integrationId': string;
    /**
     * 
     * @type {string}
     * @memberof Elma365IntegrationSettingsSchema
     */
    'organizationId': string;
    /**
     * 
     * @type {string}
     * @memberof Elma365IntegrationSettingsSchema
     */
    'xToken': string;
}


/**
 * 
 * @export
 * @interface Elma365Namespace
 */
export interface Elma365Namespace {
    /**
     * 
     * @type {string}
     * @memberof Elma365Namespace
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof Elma365Namespace
     */
    'namespace': string;
    /**
     * 
     * @type {string}
     * @memberof Elma365Namespace
     */
    'code': string;
    /**
     * 
     * @type {string}
     * @memberof Elma365Namespace
     */
    'icon': string;
    /**
     * 
     * @type {number}
     * @memberof Elma365Namespace
     */
    'sort': number;
    /**
     * 
     * @type {boolean}
     * @memberof Elma365Namespace
     */
    'hidden': boolean;
    /**
     * 
     * @type {string}
     * @memberof Elma365Namespace
     */
    '__createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof Elma365Namespace
     */
    '__updatedAt': string;
    /**
     * 
     * @type {string}
     * @memberof Elma365Namespace
     */
    '__deletedAt': string;
    /**
     * 
     * @type {string}
     * @memberof Elma365Namespace
     */
    '__createdBy': string;
    /**
     * 
     * @type {string}
     * @memberof Elma365Namespace
     */
    '__updatedBy': string;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const Elma365ProcessStartType = {
    ByStartEvent: 'ByStartEvent',
    ByContext: 'ByContext'
} as const;

export type Elma365ProcessStartType = typeof Elma365ProcessStartType[keyof typeof Elma365ProcessStartType];


/**
 * 
 * @export
 * @enum {string}
 */

export const Elma365SessionType = {
    None: 'None',
    RegisterMessage: 'RegisterMessage',
    BindAccount: 'BindAccount'
} as const;

export type Elma365SessionType = typeof Elma365SessionType[keyof typeof Elma365SessionType];


/**
 * 
 * @export
 * @interface Elma365UserInputSchema
 */
export interface Elma365UserInputSchema extends InputActionSchema {
    [key: string]: any;

    /**
     * 
     * @type {SchemaKind}
     * @memberof Elma365UserInputSchema
     */
    '$kind': SchemaKind;
}


/**
 * 
 * @export
 * @interface Elma4IntegrationSettings
 */
export interface Elma4IntegrationSettings {
    /**
     * 
     * @type {string}
     * @memberof Elma4IntegrationSettings
     */
    'apiUrl'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Elma4IntegrationSettings
     */
    'name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Elma4IntegrationSettings
     */
    'code'?: string | null;
}
/**
 * 
 * @export
 * @interface Elma4IntegrationSettingsSchema
 */
export interface Elma4IntegrationSettingsSchema extends IntegrationSettingsSchema {
    [key: string]: any;

    /**
     * 
     * @type {SchemaKind}
     * @memberof Elma4IntegrationSettingsSchema
     */
    '$kind': SchemaKind;
    /**
     * 
     * @type {string}
     * @memberof Elma4IntegrationSettingsSchema
     */
    'apiUrl': string;
}


/**
 * 
 * @export
 * @interface ElseCaseActionSchema
 */
export interface ElseCaseActionSchema extends CaseActionSchema {
    [key: string]: any;

    /**
     * 
     * @type {SchemaKind}
     * @memberof ElseCaseActionSchema
     */
    '$kind': SchemaKind;
}


/**
 * 
 * @export
 * @interface EmailAddressInputSchema
 */
export interface EmailAddressInputSchema extends InputActionSchema {
    [key: string]: any;

    /**
     * 
     * @type {SchemaKind}
     * @memberof EmailAddressInputSchema
     */
    '$kind': SchemaKind;
}


/**
 * Завершение сценария.  TODO: убрать возможность генерации сообщений и переименовать схему, т.к. в UI нет настройки сообщений.
 * @export
 * @interface EndScenarioOutputSchema
 */
export interface EndScenarioOutputSchema extends OutputActionSchema {
    [key: string]: any;

    /**
     * 
     * @type {SchemaKind}
     * @memberof EndScenarioOutputSchema
     */
    '$kind': SchemaKind;
    /**
     * 
     * @type {string}
     * @memberof EndScenarioOutputSchema
     */
    'transitionScenarioId'?: string | null;
}


/**
 * 
 * @export
 * @interface Entity
 */
export interface Entity {
    [key: string]: any;

    /**
     * 
     * @type {string}
     * @memberof Entity
     */
    'type'?: string | null;
}
/**
 * 
 * @export
 * @interface EntitySchema
 */
export interface EntitySchema {
    [key: string]: any;

    /**
     * 
     * @type {string}
     * @memberof EntitySchema
     */
    '$kind': string;
    /**
     * 
     * @type {string}
     * @memberof EntitySchema
     */
    'id': string;
    /**
     * 
     * @type {DesignerSchema}
     * @memberof EntitySchema
     */
    '$designer'?: DesignerSchema;
}
/**
 * 
 * @export
 * @interface EntityValidationResult
 */
export interface EntityValidationResult {
    /**
     * 
     * @type {string}
     * @memberof EntityValidationResult
     */
    'entityId': string;
    /**
     * 
     * @type {Array<FieldValidationResult>}
     * @memberof EntityValidationResult
     */
    'fieldResults': Array<FieldValidationResult>;
}
/**
 * Элемент категории.
 * @export
 * @interface EnumItem
 */
export interface EnumItem {
    [key: string]: any;

    /**
     * 
     * @type {string}
     * @memberof EnumItem
     */
    'code': string;
    /**
     * 
     * @type {string}
     * @memberof EnumItem
     */
    'name': string;
    /**
     * 
     * @type {boolean}
     * @memberof EnumItem
     */
    'checked': boolean;
}
/**
 * Аналитика по переводам на оператора в день.
 * @export
 * @interface EscalationsPerDayModel
 */
export interface EscalationsPerDayModel {
    /**
     * Дата.
     * @type {string}
     * @memberof EscalationsPerDayModel
     */
    'date': string;
    /**
     * Количество уникальных бесед, переведенных на оператора.
     * @type {number}
     * @memberof EscalationsPerDayModel
     */
    'uniqueEscalationCount': number;
    /**
     * Общее количество переводов на оператора.
     * @type {number}
     * @memberof EscalationsPerDayModel
     */
    'totalEscalationCount': number;
    /**
     * Количество бесед.
     * @type {number}
     * @memberof EscalationsPerDayModel
     */
    'conversationCount': number;
    /**
     * Количество переводов на оператора по сценариям.
     * @type {Array<ScenarioEventCountModel>}
     * @memberof EscalationsPerDayModel
     */
    'topScenarioEscalations': Array<ScenarioEventCountModel>;
}
/**
 * Модель события.
 * @export
 * @interface EventModel
 */
export interface EventModel {
    /**
     * ИД события.
     * @type {string}
     * @memberof EventModel
     */
    'id'?: string | null;
    /**
     * Тип события.
     * @type {string}
     * @memberof EventModel
     */
    'type'?: string | null;
    /**
     * Аргументы события.
     * @type {{ [key: string]: any; }}
     * @memberof EventModel
     */
    'arguments'?: { [key: string]: any; } | null;
}
/**
 * 
 * @export
 * @interface EventModelPaginationResponse
 */
export interface EventModelPaginationResponse {
    /**
     * 
     * @type {Array<EventModel>}
     * @memberof EventModelPaginationResponse
     */
    'items'?: Array<EventModel> | null;
    /**
     * 
     * @type {boolean}
     * @memberof EventModelPaginationResponse
     */
    'hasMore'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof EventModelPaginationResponse
     */
    'pageIndex'?: number;
    /**
     * 
     * @type {number}
     * @memberof EventModelPaginationResponse
     */
    'pageSize'?: number;
    /**
     * 
     * @type {number}
     * @memberof EventModelPaginationResponse
     */
    'totalItemCount'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof EventModelPaginationResponse
     */
    'totalPageCount'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof EventModelPaginationResponse
     */
    'fromItemInclusive'?: number;
    /**
     * 
     * @type {number}
     * @memberof EventModelPaginationResponse
     */
    'toItemExclusive'?: number;
}
/**
 * 
 * @export
 * @interface ExternalEventTriggerSchema
 */
export interface ExternalEventTriggerSchema extends TriggerSchema {
    [key: string]: any;

    /**
     * 
     * @type {SchemaKind}
     * @memberof ExternalEventTriggerSchema
     */
    '$kind': SchemaKind;
    /**
     * 
     * @type {string}
     * @memberof ExternalEventTriggerSchema
     */
    'externalEventName': string;
    /**
     * 
     * @type {string}
     * @memberof ExternalEventTriggerSchema
     */
    'externalEventId': string;
    /**
     * 
     * @type {Array<VariableSchema>}
     * @memberof ExternalEventTriggerSchema
     */
    'variables'?: Array<VariableSchema> | null;
}


/**
 * 
 * @export
 * @interface ExternalSignInSchema
 */
export interface ExternalSignInSchema extends ActionSchema {
    [key: string]: any;

    /**
     * 
     * @type {SchemaKind}
     * @memberof ExternalSignInSchema
     */
    '$kind': SchemaKind;
    /**
     * 
     * @type {number}
     * @memberof ExternalSignInSchema
     */
    'maxTurnCount': number;
    /**
     * 
     * @type {string}
     * @memberof ExternalSignInSchema
     */
    'prompt': string;
    /**
     * 
     * @type {string}
     * @memberof ExternalSignInSchema
     */
    'text': string;
    /**
     * 
     * @type {string}
     * @memberof ExternalSignInSchema
     */
    'title': string;
    /**
     * 
     * @type {LoginProtocolSchema}
     * @memberof ExternalSignInSchema
     */
    'protocol': LoginProtocolSchema;
}


/**
 * 
 * @export
 * @interface FeatureFlag
 */
export interface FeatureFlag {
    /**
     * 
     * @type {string}
     * @memberof FeatureFlag
     */
    'name'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof FeatureFlag
     */
    'isActive'?: boolean;
}
/**
 * 
 * @export
 * @interface FieldValidationResult
 */
export interface FieldValidationResult {
    /**
     * 
     * @type {string}
     * @memberof FieldValidationResult
     */
    'fieldPath': string;
    /**
     * 
     * @type {Array<ValidationRuleResult>}
     * @memberof FieldValidationResult
     */
    'ruleResults': Array<ValidationRuleResult>;
}
/**
 * 
 * @export
 * @interface FileContentData
 */
export interface FileContentData {
    /**
     * 
     * @type {string}
     * @memberof FileContentData
     */
    'fileName': string;
    /**
     * 
     * @type {string}
     * @memberof FileContentData
     */
    'mimeType': string;
    /**
     * 
     * @type {string}
     * @memberof FileContentData
     */
    'content': string;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const FileInputMode = {
    Single: 'single',
    Multiple: 'multiple',
    MultipleWithConfirm: 'multipleWithConfirm'
} as const;

export type FileInputMode = typeof FileInputMode[keyof typeof FileInputMode];


/**
 * 
 * @export
 * @interface FileInputSchema
 */
export interface FileInputSchema extends InputActionSchema {
    [key: string]: any;

    /**
     * 
     * @type {SchemaKind}
     * @memberof FileInputSchema
     */
    '$kind': SchemaKind;
    /**
     * 
     * @type {FileInputMode}
     * @memberof FileInputSchema
     */
    'fileInputMode': FileInputMode;
}


/**
 * 
 * @export
 * @interface FileUploadingResponse
 */
export interface FileUploadingResponse {
    /**
     * 
     * @type {string}
     * @memberof FileUploadingResponse
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof FileUploadingResponse
     */
    'url': string;
}
/**
 * Поле формы.
 * @export
 * @interface FormField
 */
export interface FormField {
    [key: string]: any;

    /**
     * 
     * @type {string}
     * @memberof FormField
     */
    'code': string;
    /**
     * 
     * @type {string}
     * @memberof FormField
     */
    'display'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FormField
     */
    'tooltip': string;
    /**
     * 
     * @type {boolean}
     * @memberof FormField
     */
    'readonly': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof FormField
     */
    'required': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof FormField
     */
    'hideEmpty'?: boolean | null;
}
/**
 * 
 * @export
 * @interface GeneratedDataItem
 */
export interface GeneratedDataItem {
    /**
     * 
     * @type {string}
     * @memberof GeneratedDataItem
     */
    'luFileName'?: string | null;
    /**
     * 
     * @type {OmegaGenerated}
     * @memberof GeneratedDataItem
     */
    'omegaGenerated'?: OmegaGenerated;
}
/**
 * Запрос на создание группы сотрудников.
 * @export
 * @interface GroupCreationRequest
 */
export interface GroupCreationRequest {
    /**
     * Имя группы.
     * @type {string}
     * @memberof GroupCreationRequest
     */
    'name': string;
}
/**
 * Ответ на создание группы сотрудников.
 * @export
 * @interface GroupCreationResponse
 */
export interface GroupCreationResponse {
    /**
     * ИД группы.
     * @type {string}
     * @memberof GroupCreationResponse
     */
    'id'?: string | null;
}
/**
 * 
 * @export
 * @interface GroupEditionModel
 */
export interface GroupEditionModel {
    /**
     * 
     * @type {string}
     * @memberof GroupEditionModel
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof GroupEditionModel
     */
    'groupEntryId': string;
    /**
     * 
     * @type {string}
     * @memberof GroupEditionModel
     */
    'code': string;
    /**
     * 
     * @type {string}
     * @memberof GroupEditionModel
     */
    'semVer': string;
}
/**
 * 
 * @export
 * @interface GroupEditionModelPaginationResponse
 */
export interface GroupEditionModelPaginationResponse {
    /**
     * 
     * @type {Array<GroupEditionModel>}
     * @memberof GroupEditionModelPaginationResponse
     */
    'items'?: Array<GroupEditionModel> | null;
    /**
     * 
     * @type {boolean}
     * @memberof GroupEditionModelPaginationResponse
     */
    'hasMore'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof GroupEditionModelPaginationResponse
     */
    'pageIndex'?: number;
    /**
     * 
     * @type {number}
     * @memberof GroupEditionModelPaginationResponse
     */
    'pageSize'?: number;
    /**
     * 
     * @type {number}
     * @memberof GroupEditionModelPaginationResponse
     */
    'totalItemCount'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof GroupEditionModelPaginationResponse
     */
    'totalPageCount'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof GroupEditionModelPaginationResponse
     */
    'fromItemInclusive'?: number;
    /**
     * 
     * @type {number}
     * @memberof GroupEditionModelPaginationResponse
     */
    'toItemExclusive'?: number;
}
/**
 * 
 * @export
 * @interface GroupEntryModel
 */
export interface GroupEntryModel {
    /**
     * 
     * @type {string}
     * @memberof GroupEntryModel
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof GroupEntryModel
     */
    'code': string;
    /**
     * 
     * @type {string}
     * @memberof GroupEntryModel
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof GroupEntryModel
     */
    'description'?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof GroupEntryModel
     */
    'tags': Array<string>;
    /**
     * 
     * @type {string}
     * @memberof GroupEntryModel
     */
    'currentSemVer': string;
}
/**
 * 
 * @export
 * @interface GroupEntryModelPaginationResponse
 */
export interface GroupEntryModelPaginationResponse {
    /**
     * 
     * @type {Array<GroupEntryModel>}
     * @memberof GroupEntryModelPaginationResponse
     */
    'items'?: Array<GroupEntryModel> | null;
    /**
     * 
     * @type {boolean}
     * @memberof GroupEntryModelPaginationResponse
     */
    'hasMore'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof GroupEntryModelPaginationResponse
     */
    'pageIndex'?: number;
    /**
     * 
     * @type {number}
     * @memberof GroupEntryModelPaginationResponse
     */
    'pageSize'?: number;
    /**
     * 
     * @type {number}
     * @memberof GroupEntryModelPaginationResponse
     */
    'totalItemCount'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof GroupEntryModelPaginationResponse
     */
    'totalPageCount'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof GroupEntryModelPaginationResponse
     */
    'fromItemInclusive'?: number;
    /**
     * 
     * @type {number}
     * @memberof GroupEntryModelPaginationResponse
     */
    'toItemExclusive'?: number;
}
/**
 * Модель группы сотрудников.
 * @export
 * @interface GroupModel
 */
export interface GroupModel {
    /**
     * ИД группы.
     * @type {string}
     * @memberof GroupModel
     */
    'id': string;
    /**
     * Имя группы.
     * @type {string}
     * @memberof GroupModel
     */
    'name': string;
    /**
     * Список сотрудников, входящих в группу.
     * @type {Array<PersonModel>}
     * @memberof GroupModel
     */
    'persons'?: Array<PersonModel> | null;
}
/**
 * 
 * @export
 * @interface GroupModelPaginationResponse
 */
export interface GroupModelPaginationResponse {
    /**
     * 
     * @type {Array<GroupModel>}
     * @memberof GroupModelPaginationResponse
     */
    'items'?: Array<GroupModel> | null;
    /**
     * 
     * @type {boolean}
     * @memberof GroupModelPaginationResponse
     */
    'hasMore'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof GroupModelPaginationResponse
     */
    'pageIndex'?: number;
    /**
     * 
     * @type {number}
     * @memberof GroupModelPaginationResponse
     */
    'pageSize'?: number;
    /**
     * 
     * @type {number}
     * @memberof GroupModelPaginationResponse
     */
    'totalItemCount'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof GroupModelPaginationResponse
     */
    'totalPageCount'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof GroupModelPaginationResponse
     */
    'fromItemInclusive'?: number;
    /**
     * 
     * @type {number}
     * @memberof GroupModelPaginationResponse
     */
    'toItemExclusive'?: number;
}
/**
 * Запрос на изменение группы сотрудников.
 * @export
 * @interface GroupUpdatingRequest
 */
export interface GroupUpdatingRequest {
    /**
     * Имя группы.
     * @type {string}
     * @memberof GroupUpdatingRequest
     */
    'name': string;
}
/**
 * Модель информации о работоспособности.
 * @export
 * @interface HealthModel
 */
export interface HealthModel {
    /**
     * Статус работоспособности.
     * @type {string}
     * @memberof HealthModel
     */
    'status'?: string | null;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const HttpTransportType = {
    None: 'None',
    WebSockets: 'WebSockets',
    ServerSentEvents: 'ServerSentEvents',
    LongPolling: 'LongPolling'
} as const;

export type HttpTransportType = typeof HttpTransportType[keyof typeof HttpTransportType];


/**
 * 
 * @export
 * @interface ITariffRuleResult
 */
export interface ITariffRuleResult {
    /**
     * 
     * @type {string}
     * @memberof ITariffRuleResult
     */
    'name'?: string | null;
    /**
     * 
     * @type {TariffStatus}
     * @memberof ITariffRuleResult
     */
    'status'?: TariffStatus;
    /**
     * 
     * @type {string}
     * @memberof ITariffRuleResult
     */
    'message'?: string | null;
}


/**
 * Модель связанной активности вложения Inbox.
 * @export
 * @interface InboxAttachmentActivityModel
 */
export interface InboxAttachmentActivityModel {
    /**
     * ИД активности.
     * @type {string}
     * @memberof InboxAttachmentActivityModel
     */
    'id': string;
}
/**
 * Модель связанного чата вложения Inbox.
 * @export
 * @interface InboxAttachmentChatModel
 */
export interface InboxAttachmentChatModel {
    /**
     * ИД чата.
     * @type {string}
     * @memberof InboxAttachmentChatModel
     */
    'id': string;
}
/**
 * Модель внешних данных вложения Inbox.
 * @export
 * @interface InboxAttachmentExternalModel
 */
export interface InboxAttachmentExternalModel {
    /**
     * ИД данных.
     * @type {string}
     * @memberof InboxAttachmentExternalModel
     */
    'id': string;
}
/**
 * Модель вложения Inbox.
 * @export
 * @interface InboxAttachmentModel
 */
export interface InboxAttachmentModel {
    /**
     * ИД вложения.
     * @type {string}
     * @memberof InboxAttachmentModel
     */
    'id': string;
    /**
     * ИД тенанта.
     * @type {string}
     * @memberof InboxAttachmentModel
     */
    'tenantId': string;
    /**
     * Дата создания вложения.
     * @type {string}
     * @memberof InboxAttachmentModel
     */
    'createdOn': string;
    /**
     * 
     * @type {InboxFileModel}
     * @memberof InboxAttachmentModel
     */
    'file': InboxFileModel;
    /**
     * Миниатюры вложения.
     * @type {Array<InboxThumbnailModel>}
     * @memberof InboxAttachmentModel
     */
    'thumbnails': Array<InboxThumbnailModel>;
    /**
     * 
     * @type {InboxAttachmentExternalModel}
     * @memberof InboxAttachmentModel
     */
    'external': InboxAttachmentExternalModel;
    /**
     * 
     * @type {InboxAttachmentRecordModel}
     * @memberof InboxAttachmentModel
     */
    'record': InboxAttachmentRecordModel;
    /**
     * 
     * @type {InboxAttachmentActivityModel}
     * @memberof InboxAttachmentModel
     */
    'activity': InboxAttachmentActivityModel;
    /**
     * 
     * @type {InboxAttachmentChatModel}
     * @memberof InboxAttachmentModel
     */
    'chat': InboxAttachmentChatModel;
}
/**
 * 
 * @export
 * @interface InboxAttachmentModelPaginationResponse
 */
export interface InboxAttachmentModelPaginationResponse {
    /**
     * 
     * @type {Array<InboxAttachmentModel>}
     * @memberof InboxAttachmentModelPaginationResponse
     */
    'items'?: Array<InboxAttachmentModel> | null;
    /**
     * 
     * @type {boolean}
     * @memberof InboxAttachmentModelPaginationResponse
     */
    'hasMore'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof InboxAttachmentModelPaginationResponse
     */
    'pageIndex'?: number;
    /**
     * 
     * @type {number}
     * @memberof InboxAttachmentModelPaginationResponse
     */
    'pageSize'?: number;
    /**
     * 
     * @type {number}
     * @memberof InboxAttachmentModelPaginationResponse
     */
    'totalItemCount'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof InboxAttachmentModelPaginationResponse
     */
    'totalPageCount'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof InboxAttachmentModelPaginationResponse
     */
    'fromItemInclusive'?: number;
    /**
     * 
     * @type {number}
     * @memberof InboxAttachmentModelPaginationResponse
     */
    'toItemExclusive'?: number;
}
/**
 * Модель связанной записи вложения Inbox.
 * @export
 * @interface InboxAttachmentRecordModel
 */
export interface InboxAttachmentRecordModel {
    /**
     * ИД записи.
     * @type {string}
     * @memberof InboxAttachmentRecordModel
     */
    'id': string;
}
/**
 * Запрос на создание канала Inbox.
 * @export
 * @interface InboxChannelCreationRequest
 */
export interface InboxChannelCreationRequest {
    /**
     * ИД канала.
     * @type {string}
     * @memberof InboxChannelCreationRequest
     */
    'channelId': string;
    /**
     * 
     * @type {AgentStageAccountStatus}
     * @memberof InboxChannelCreationRequest
     */
    'status': AgentStageAccountStatus;
    /**
     * Отображаемое имя.
     * @type {string}
     * @memberof InboxChannelCreationRequest
     */
    'displayName': string;
    /**
     * Токен аутентификации канала.
     * @type {string}
     * @memberof InboxChannelCreationRequest
     */
    'authToken': string;
    /**
     * Набор дополнительных свойств.
     * @type {{ [key: string]: any; }}
     * @memberof InboxChannelCreationRequest
     */
    'properties'?: { [key: string]: any; } | null;
    /**
     * 
     * @type {InboxChannelRoutingModel}
     * @memberof InboxChannelCreationRequest
     */
    'routing': InboxChannelRoutingModel;
}


/**
 * Ответ на запрос создания канала Inbox.
 * @export
 * @interface InboxChannelCreationResponse
 */
export interface InboxChannelCreationResponse {
    /**
     * ИД.
     * @type {string}
     * @memberof InboxChannelCreationResponse
     */
    'id': string;
    /**
     * ИД стадии аккаунта бота.
     * @type {string}
     * @memberof InboxChannelCreationResponse
     */
    'agentStageAccountId': string;
}
/**
 * Модель канала Inbox.
 * @export
 * @interface InboxChannelModel
 */
export interface InboxChannelModel {
    /**
     * ИД.
     * @type {string}
     * @memberof InboxChannelModel
     */
    'id': string;
    /**
     * ИД канала.
     * @type {string}
     * @memberof InboxChannelModel
     */
    'channelId': string;
    /**
     * 
     * @type {AgentStageAccountStatus}
     * @memberof InboxChannelModel
     */
    'status': AgentStageAccountStatus;
    /**
     * Отображаемое имя.
     * @type {string}
     * @memberof InboxChannelModel
     */
    'displayName': string;
    /**
     * Токен аутентификации канала.
     * @type {string}
     * @memberof InboxChannelModel
     */
    'authToken': string;
    /**
     * Набор дополнительных свойств.
     * @type {{ [key: string]: any; }}
     * @memberof InboxChannelModel
     */
    'properties'?: { [key: string]: any; } | null;
    /**
     * 
     * @type {InboxChannelRoutingModel}
     * @memberof InboxChannelModel
     */
    'routing': InboxChannelRoutingModel;
    /**
     * 
     * @type {AgentStageAccountModel}
     * @memberof InboxChannelModel
     */
    'agentStageAccount': AgentStageAccountModel;
}


/**
 * 
 * @export
 * @interface InboxChannelModelPaginationResponse
 */
export interface InboxChannelModelPaginationResponse {
    /**
     * 
     * @type {Array<InboxChannelModel>}
     * @memberof InboxChannelModelPaginationResponse
     */
    'items'?: Array<InboxChannelModel> | null;
    /**
     * 
     * @type {boolean}
     * @memberof InboxChannelModelPaginationResponse
     */
    'hasMore'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof InboxChannelModelPaginationResponse
     */
    'pageIndex'?: number;
    /**
     * 
     * @type {number}
     * @memberof InboxChannelModelPaginationResponse
     */
    'pageSize'?: number;
    /**
     * 
     * @type {number}
     * @memberof InboxChannelModelPaginationResponse
     */
    'totalItemCount'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof InboxChannelModelPaginationResponse
     */
    'totalPageCount'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof InboxChannelModelPaginationResponse
     */
    'fromItemInclusive'?: number;
    /**
     * 
     * @type {number}
     * @memberof InboxChannelModelPaginationResponse
     */
    'toItemExclusive'?: number;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const InboxChannelOperatorsRoutingType = {
    Initiative: 'Initiative',
    Auto: 'Auto'
} as const;

export type InboxChannelOperatorsRoutingType = typeof InboxChannelOperatorsRoutingType[keyof typeof InboxChannelOperatorsRoutingType];


/**
 * 
 * @export
 * @enum {string}
 */

export const InboxChannelRoutingBotAssignmentType = {
    Manually: 'Manually',
    Always: 'Always',
    NonWorkTime: 'NonWorkTime'
} as const;

export type InboxChannelRoutingBotAssignmentType = typeof InboxChannelRoutingBotAssignmentType[keyof typeof InboxChannelRoutingBotAssignmentType];


/**
 * Настройки бота в канале.
 * @export
 * @interface InboxChannelRoutingBotModel
 */
export interface InboxChannelRoutingBotModel {
    /**
     * Вкл. / Откл.
     * @type {boolean}
     * @memberof InboxChannelRoutingBotModel
     */
    'enabled'?: boolean;
    /**
     * 
     * @type {BotEntryModel}
     * @memberof InboxChannelRoutingBotModel
     */
    'botEntry'?: BotEntryModel;
    /**
     * 
     * @type {InboxChannelRoutingBotAssignmentType}
     * @memberof InboxChannelRoutingBotModel
     */
    'assignmentType': InboxChannelRoutingBotAssignmentType;
}


/**
 * Модель настроек маршрутизации в канале Inbox.
 * @export
 * @interface InboxChannelRoutingModel
 */
export interface InboxChannelRoutingModel {
    /**
     * 
     * @type {InboxChannelRoutingOperatorsModel}
     * @memberof InboxChannelRoutingModel
     */
    'operators': InboxChannelRoutingOperatorsModel;
    /**
     * 
     * @type {InboxChannelRoutingBotModel}
     * @memberof InboxChannelRoutingModel
     */
    'bot': InboxChannelRoutingBotModel;
    /**
     * 
     * @type {InboxChannelRoutingWorkTimeModel}
     * @memberof InboxChannelRoutingModel
     */
    'workTime': InboxChannelRoutingWorkTimeModel;
}
/**
 * Настройки маршрутизации на оператора.
 * @export
 * @interface InboxChannelRoutingOperatorsModel
 */
export interface InboxChannelRoutingOperatorsModel {
    /**
     * 
     * @type {InboxChannelOperatorsRoutingType}
     * @memberof InboxChannelRoutingOperatorsModel
     */
    'routingType': InboxChannelOperatorsRoutingType;
    /**
     * Максимальное количество диалогов на оператора.
     * @type {number}
     * @memberof InboxChannelRoutingOperatorsModel
     */
    'maxChatCountPerOperator'?: number | null;
    /**
     * Время ответа оператора.
     * @type {string}
     * @memberof InboxChannelRoutingOperatorsModel
     */
    'operatorResponseTime'?: string | null;
    /**
     * 
     * @type {InboxOperatorGroupModel}
     * @memberof InboxChannelRoutingOperatorsModel
     */
    'defaultGroup'?: InboxOperatorGroupModel;
    /**
     * Дополнительные группы операторов.
     * @type {Array<InboxOperatorGroupModel>}
     * @memberof InboxChannelRoutingOperatorsModel
     */
    'additionalGroups': Array<InboxOperatorGroupModel>;
}


/**
 * Настройки рабочего дня.
 * @export
 * @interface InboxChannelRoutingWorkTimeDayModel
 */
export interface InboxChannelRoutingWorkTimeDayModel {
    /**
     * Вкл. / Откл.
     * @type {boolean}
     * @memberof InboxChannelRoutingWorkTimeDayModel
     */
    'enabled': boolean;
    /**
     * Начало рабочего дня.
     * @type {string}
     * @memberof InboxChannelRoutingWorkTimeDayModel
     */
    'start': string;
    /**
     * Конец рабочего дня.
     * @type {string}
     * @memberof InboxChannelRoutingWorkTimeDayModel
     */
    'end': string;
}
/**
 * Настройки рабочего времени.
 * @export
 * @interface InboxChannelRoutingWorkTimeModel
 */
export interface InboxChannelRoutingWorkTimeModel {
    /**
     * Вкл. / Откл.
     * @type {boolean}
     * @memberof InboxChannelRoutingWorkTimeModel
     */
    'enabled': boolean;
    /**
     * 
     * @type {InboxChannelRoutingWorkTimeDayModel}
     * @memberof InboxChannelRoutingWorkTimeModel
     */
    'monday': InboxChannelRoutingWorkTimeDayModel;
    /**
     * 
     * @type {InboxChannelRoutingWorkTimeDayModel}
     * @memberof InboxChannelRoutingWorkTimeModel
     */
    'tuesday': InboxChannelRoutingWorkTimeDayModel;
    /**
     * 
     * @type {InboxChannelRoutingWorkTimeDayModel}
     * @memberof InboxChannelRoutingWorkTimeModel
     */
    'wednesday': InboxChannelRoutingWorkTimeDayModel;
    /**
     * 
     * @type {InboxChannelRoutingWorkTimeDayModel}
     * @memberof InboxChannelRoutingWorkTimeModel
     */
    'thursday': InboxChannelRoutingWorkTimeDayModel;
    /**
     * 
     * @type {InboxChannelRoutingWorkTimeDayModel}
     * @memberof InboxChannelRoutingWorkTimeModel
     */
    'friday': InboxChannelRoutingWorkTimeDayModel;
    /**
     * 
     * @type {InboxChannelRoutingWorkTimeDayModel}
     * @memberof InboxChannelRoutingWorkTimeModel
     */
    'saturday': InboxChannelRoutingWorkTimeDayModel;
    /**
     * 
     * @type {InboxChannelRoutingWorkTimeDayModel}
     * @memberof InboxChannelRoutingWorkTimeModel
     */
    'sunday': InboxChannelRoutingWorkTimeDayModel;
}
/**
 * Запрос на обновление канала Inbox.
 * @export
 * @interface InboxChannelUpdatingRequest
 */
export interface InboxChannelUpdatingRequest {
    /**
     * 
     * @type {AgentStageAccountStatus}
     * @memberof InboxChannelUpdatingRequest
     */
    'status': AgentStageAccountStatus;
    /**
     * Отображаемое имя.
     * @type {string}
     * @memberof InboxChannelUpdatingRequest
     */
    'displayName': string;
    /**
     * Токен аутентификации канала.
     * @type {string}
     * @memberof InboxChannelUpdatingRequest
     */
    'authToken': string;
    /**
     * Набор дополнительных свойств.
     * @type {{ [key: string]: any; }}
     * @memberof InboxChannelUpdatingRequest
     */
    'properties'?: { [key: string]: any; } | null;
    /**
     * 
     * @type {InboxChannelRoutingModel}
     * @memberof InboxChannelUpdatingRequest
     */
    'routing': InboxChannelRoutingModel;
}


/**
 * Действие чата Inbox.
 * @export
 * @interface InboxChatActionModel
 */
export interface InboxChatActionModel {
    /**
     * ИД активности.
     * @type {string}
     * @memberof InboxChatActionModel
     */
    'activityId'?: string | null;
    /**
     * Метка времени.
     * @type {string}
     * @memberof InboxChatActionModel
     */
    'timestamp'?: string | null;
}
/**
 * Модель активности чата Inbox.
 * @export
 * @interface InboxChatActivityModel
 */
export interface InboxChatActivityModel {
    /**
     * ИД активности.
     * @type {string}
     * @memberof InboxChatActivityModel
     */
    'id': string;
}
/**
 * Вложение чата Inbox.
 * @export
 * @interface InboxChatAttachmentModel
 */
export interface InboxChatAttachmentModel {
    /**
     * ИД файла.
     * @type {string}
     * @memberof InboxChatAttachmentModel
     */
    'fileId': string;
    /**
     * Наименование файла.
     * @type {string}
     * @memberof InboxChatAttachmentModel
     */
    'fileName': string;
    /**
     * Тип содержимого.
     * @type {string}
     * @memberof InboxChatAttachmentModel
     */
    'contentType': string;
    /**
     * URL содержимого.
     * @type {string}
     * @memberof InboxChatAttachmentModel
     */
    'contentUrl': string;
}
/**
 * Вызов чата Inbox.
 * @export
 * @interface InboxChatInvocationModel
 */
export interface InboxChatInvocationModel {
    /**
     * Наименование вызова.
     * @type {string}
     * @memberof InboxChatInvocationModel
     */
    'name': string;
    /**
     * ИД сущности (ИД оператора или ИД группы операторов).
     * @type {string}
     * @memberof InboxChatInvocationModel
     */
    'entityId'?: string | null;
    /**
     * ИД активности.
     * @type {string}
     * @memberof InboxChatInvocationModel
     */
    'activityId'?: string | null;
    /**
     * Метка времени.
     * @type {string}
     * @memberof InboxChatInvocationModel
     */
    'timestamp'?: string | null;
}
/**
 * Сообщение чата Inbox.
 * @export
 * @interface InboxChatMessageModel
 */
export interface InboxChatMessageModel {
    /**
     * 
     * @type {InboxMessageContentModel}
     * @memberof InboxChatMessageModel
     */
    'content': InboxMessageContentModel;
    /**
     * 
     * @type {InboxDirection}
     * @memberof InboxChatMessageModel
     */
    'direction': InboxDirection;
    /**
     * Упоминания участников чата.
     * @type {Array<InboxMentionModel>}
     * @memberof InboxChatMessageModel
     */
    'mentions': Array<InboxMentionModel>;
    /**
     * Вложения сообщения.
     * @type {Array<InboxChatAttachmentModel>}
     * @memberof InboxChatMessageModel
     */
    'attachments': Array<InboxChatAttachmentModel>;
    /**
     * Идентификаторы цитируемых сообщений.
     * @type {Array<string>}
     * @memberof InboxChatMessageModel
     */
    'quotedMessagesIds': Array<string>;
    /**
     * ИД активности.
     * @type {string}
     * @memberof InboxChatMessageModel
     */
    'activityId'?: string | null;
    /**
     * Метка времени.
     * @type {string}
     * @memberof InboxChatMessageModel
     */
    'timestamp'?: string | null;
}


/**
 * Модель чата Inbox.
 * @export
 * @interface InboxChatModel
 */
export interface InboxChatModel {
    /**
     * ИД чата.
     * @type {string}
     * @memberof InboxChatModel
     */
    'id': string;
    /**
     * ИД тенанта.
     * @type {string}
     * @memberof InboxChatModel
     */
    'tenantId': string;
    /**
     * Дата и время начала чата.
     * @type {string}
     * @memberof InboxChatModel
     */
    'createdOn': string;
    /**
     * 
     * @type {InboxChatStatus}
     * @memberof InboxChatModel
     */
    'status': InboxChatStatus;
    /**
     * ИД канала .
     * @type {string}
     * @memberof InboxChatModel
     */
    'channelId': string;
    /**
     * 
     * @type {InboxChatRecordModel}
     * @memberof InboxChatModel
     */
    'record': InboxChatRecordModel;
    /**
     * 
     * @type {InboxChatActivityModel}
     * @memberof InboxChatModel
     */
    'activity': InboxChatActivityModel;
    /**
     * Участники чата.
     * @type {Array<InboxChatParticipantModel>}
     * @memberof InboxChatModel
     */
    'participants': Array<InboxChatParticipantModel>;
    /**
     * 
     * @type {InboxChatStatisticsModel}
     * @memberof InboxChatModel
     */
    'statistics': InboxChatStatisticsModel;
    /**
     * 
     * @type {InboxChannelModel}
     * @memberof InboxChatModel
     */
    'channel'?: InboxChannelModel;
    /**
     * 
     * @type {LiveChatConversationStateModel}
     * @memberof InboxChatModel
     */
    'liveChatConversationState'?: LiveChatConversationStateModel;
    /**
     * 
     * @type {InboxOperatorGroupModel}
     * @memberof InboxChatModel
     */
    'assignedOperatorGroup'?: InboxOperatorGroupModel;
    /**
     * Тэги.
     * @type {Array<TagModel>}
     * @memberof InboxChatModel
     */
    'tags': Array<TagModel>;
}


/**
 * 
 * @export
 * @interface InboxChatModelPaginationResponse
 */
export interface InboxChatModelPaginationResponse {
    /**
     * 
     * @type {Array<InboxChatModel>}
     * @memberof InboxChatModelPaginationResponse
     */
    'items'?: Array<InboxChatModel> | null;
    /**
     * 
     * @type {boolean}
     * @memberof InboxChatModelPaginationResponse
     */
    'hasMore'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof InboxChatModelPaginationResponse
     */
    'pageIndex'?: number;
    /**
     * 
     * @type {number}
     * @memberof InboxChatModelPaginationResponse
     */
    'pageSize'?: number;
    /**
     * 
     * @type {number}
     * @memberof InboxChatModelPaginationResponse
     */
    'totalItemCount'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof InboxChatModelPaginationResponse
     */
    'totalPageCount'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof InboxChatModelPaginationResponse
     */
    'fromItemInclusive'?: number;
    /**
     * 
     * @type {number}
     * @memberof InboxChatModelPaginationResponse
     */
    'toItemExclusive'?: number;
}
/**
 * Модель участника чата Inbox.
 * @export
 * @interface InboxChatParticipantModel
 */
export interface InboxChatParticipantModel {
    /**
     * ИД участника.
     * @type {string}
     * @memberof InboxChatParticipantModel
     */
    'id': string;
    /**
     * 
     * @type {InboxChatParticipantStatus}
     * @memberof InboxChatParticipantModel
     */
    'status': InboxChatParticipantStatus;
    /**
     * 
     * @type {InboxChatParticipantSubjectModel}
     * @memberof InboxChatParticipantModel
     */
    'subject': InboxChatParticipantSubjectModel;
    /**
     * 
     * @type {InboxChatParticipantStatisticsModel}
     * @memberof InboxChatParticipantModel
     */
    'statistics': InboxChatParticipantStatisticsModel;
}


/**
 * Модель статистики участника чата Inbox.
 * @export
 * @interface InboxChatParticipantStatisticsModel
 */
export interface InboxChatParticipantStatisticsModel {
    /**
     * Количество непрочитанных сообщений.
     * @type {number}
     * @memberof InboxChatParticipantStatisticsModel
     */
    'unreadMessageCount': number;
    /**
     * Есть ли непрочитанные сообщения.
     * @type {boolean}
     * @memberof InboxChatParticipantStatisticsModel
     */
    'hasUnreadMessages': boolean;
    /**
     * Есть ли непросмотренные упоминания.
     * @type {boolean}
     * @memberof InboxChatParticipantStatisticsModel
     */
    'hasUnreadMentions': boolean;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const InboxChatParticipantStatus = {
    Undefined: 'Undefined',
    Queued: 'Queued',
    Assigned: 'Assigned',
    Closed: 'Closed',
    Mentioned: 'Mentioned'
} as const;

export type InboxChatParticipantStatus = typeof InboxChatParticipantStatus[keyof typeof InboxChatParticipantStatus];


/**
 * Модель субъекта участника чата Inbox.
 * @export
 * @interface InboxChatParticipantSubjectModel
 */
export interface InboxChatParticipantSubjectModel {
    /**
     * ИД субъекта.
     * @type {string}
     * @memberof InboxChatParticipantSubjectModel
     */
    'id': string;
    /**
     * Роль субъекта.
     * @type {string}
     * @memberof InboxChatParticipantSubjectModel
     */
    'role': string;
    /**
     * 
     * @type {InboxPersonModel}
     * @memberof InboxChatParticipantSubjectModel
     */
    'person': InboxPersonModel;
}
/**
 * Модель записи чата Inbox.
 * @export
 * @interface InboxChatRecordModel
 */
export interface InboxChatRecordModel {
    /**
     * ИД записи.
     * @type {string}
     * @memberof InboxChatRecordModel
     */
    'id': string;
}
/**
 * Модель статистики сообщения чата Inbox.
 * @export
 * @interface InboxChatStatisticsMessageModel
 */
export interface InboxChatStatisticsMessageModel {
    /**
     * Дата и время сообщения.
     * @type {string}
     * @memberof InboxChatStatisticsMessageModel
     */
    'anyOn'?: string | null;
    /**
     * Дата и время входящего сообщения.
     * @type {string}
     * @memberof InboxChatStatisticsMessageModel
     */
    'inboundOn'?: string | null;
    /**
     * Дата и время исходящего сообщения.
     * @type {string}
     * @memberof InboxChatStatisticsMessageModel
     */
    'outboundOn'?: string | null;
    /**
     * Дата и время внутреннего сообщения.
     * @type {string}
     * @memberof InboxChatStatisticsMessageModel
     */
    'internalOn'?: string | null;
    /**
     * 
     * @type {InboxDirection}
     * @memberof InboxChatStatisticsMessageModel
     */
    'direction': InboxDirection;
    /**
     * 
     * @type {InboxMessageContentModel}
     * @memberof InboxChatStatisticsMessageModel
     */
    'content'?: InboxMessageContentModel;
    /**
     * Упоминания пользователей в сообщении.
     * @type {Array<InboxMentionModel>}
     * @memberof InboxChatStatisticsMessageModel
     */
    'mentions': Array<InboxMentionModel>;
    /**
     * Идентификатор участника чата, который отправил сообщение.
     * @type {string}
     * @memberof InboxChatStatisticsMessageModel
     */
    'senderParticipantId'?: string | null;
}


/**
 * Модель статистики чата Inbox.
 * @export
 * @interface InboxChatStatisticsModel
 */
export interface InboxChatStatisticsModel {
    /**
     * Количество сообщений.
     * @type {number}
     * @memberof InboxChatStatisticsModel
     */
    'messageCount': number;
    /**
     * Количество входящих сообщений.
     * @type {number}
     * @memberof InboxChatStatisticsModel
     */
    'inboundMessageCount': number;
    /**
     * Количество исходящих сообщений.
     * @type {number}
     * @memberof InboxChatStatisticsModel
     */
    'outboundMessageCount': number;
    /**
     * Количество внутренних сообщений.
     * @type {number}
     * @memberof InboxChatStatisticsModel
     */
    'internalMessageCount': number;
    /**
     * 
     * @type {InboxChatStatisticsMessageModel}
     * @memberof InboxChatStatisticsModel
     */
    'lastMessage': InboxChatStatisticsMessageModel;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const InboxChatStatus = {
    Created: 'Created',
    Queued: 'Queued',
    Assigned: 'Assigned',
    Closed: 'Closed'
} as const;

export type InboxChatStatus = typeof InboxChatStatus[keyof typeof InboxChatStatus];


/**
 * Запрос на изменение чата инбокса.
 * @export
 * @interface InboxChatUpdatingRequest
 */
export interface InboxChatUpdatingRequest {
    /**
     * Список идентификаторов тэгов.
     * @type {Array<string>}
     * @memberof InboxChatUpdatingRequest
     */
    'tagIds'?: Array<string> | null;
}
/**
 * Модель активности контакта в чатах Inbox.
 * @export
 * @interface InboxContactActivityModel
 */
export interface InboxContactActivityModel {
    /**
     * 
     * @type {InboxChannelModel}
     * @memberof InboxContactActivityModel
     */
    'channel': InboxChannelModel;
    /**
     * ИД чата.
     * @type {string}
     * @memberof InboxContactActivityModel
     */
    'chatId': string;
    /**
     * Дата и время последней активности в чате.
     * @type {string}
     * @memberof InboxContactActivityModel
     */
    'anyOn'?: string | null;
}
/**
 * Модель контакта Inbox.
 * @export
 * @interface InboxContactModel
 */
export interface InboxContactModel {
    /**
     * 
     * @type {PersonModel}
     * @memberof InboxContactModel
     */
    'person': PersonModel;
    /**
     * Активности контакта в чатах Inbox.
     * @type {Array<InboxContactActivityModel>}
     * @memberof InboxContactModel
     */
    'activities': Array<InboxContactActivityModel>;
}
/**
 * 
 * @export
 * @interface InboxContactModelPaginationResponse
 */
export interface InboxContactModelPaginationResponse {
    /**
     * 
     * @type {Array<InboxContactModel>}
     * @memberof InboxContactModelPaginationResponse
     */
    'items'?: Array<InboxContactModel> | null;
    /**
     * 
     * @type {boolean}
     * @memberof InboxContactModelPaginationResponse
     */
    'hasMore'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof InboxContactModelPaginationResponse
     */
    'pageIndex'?: number;
    /**
     * 
     * @type {number}
     * @memberof InboxContactModelPaginationResponse
     */
    'pageSize'?: number;
    /**
     * 
     * @type {number}
     * @memberof InboxContactModelPaginationResponse
     */
    'totalItemCount'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof InboxContactModelPaginationResponse
     */
    'totalPageCount'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof InboxContactModelPaginationResponse
     */
    'fromItemInclusive'?: number;
    /**
     * 
     * @type {number}
     * @memberof InboxContactModelPaginationResponse
     */
    'toItemExclusive'?: number;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const InboxDirection = {
    Inbound: 'Inbound',
    Outbound: 'Outbound',
    Internal: 'Internal'
} as const;

export type InboxDirection = typeof InboxDirection[keyof typeof InboxDirection];


/**
 * Модель метаданных архивного файла Inbox.
 * @export
 * @interface InboxFileArchiveMetadataModel
 */
export interface InboxFileArchiveMetadataModel {
    /**
     * Дополнительные свойтсва файла.
     * @type {object}
     * @memberof InboxFileArchiveMetadataModel
     */
    'properties': object;
}
/**
 * Модель метаданных аудио файла Inbox.
 * @export
 * @interface InboxFileAudioMetadataModel
 */
export interface InboxFileAudioMetadataModel {
    /**
     * Длительность.
     * @type {string}
     * @memberof InboxFileAudioMetadataModel
     */
    'duration': string;
    /**
     * Дополнительные свойтсва файла.
     * @type {object}
     * @memberof InboxFileAudioMetadataModel
     */
    'properties': object;
}
/**
 * Модель буферизованного содержимого файла Inbox.
 * @export
 * @interface InboxFileBufferContentModel
 */
export interface InboxFileBufferContentModel {
    /**
     * Буферизованные данные (массив байтов).
     * @type {string}
     * @memberof InboxFileBufferContentModel
     */
    'data': string;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const InboxFileCategory = {
    Unknown: 'Unknown',
    Document: 'Document',
    Archive: 'Archive',
    Image: 'Image',
    Audio: 'Audio',
    Video: 'Video'
} as const;

export type InboxFileCategory = typeof InboxFileCategory[keyof typeof InboxFileCategory];


/**
 * Модель метаданных файла документа Inbox.
 * @export
 * @interface InboxFileDocumentMetadataModel
 */
export interface InboxFileDocumentMetadataModel {
    /**
     * Дополнительные свойтсва файла.
     * @type {object}
     * @memberof InboxFileDocumentMetadataModel
     */
    'properties': object;
}
/**
 * Модель метаданных неизвестного типа файла Inbox.
 * @export
 * @interface InboxFileGeneralMetadataModel
 */
export interface InboxFileGeneralMetadataModel {
    /**
     * Дополнительные свойтсва файла.
     * @type {object}
     * @memberof InboxFileGeneralMetadataModel
     */
    'properties': object;
}
/**
 * Модель метаданных файла изображения Inbox.
 * @export
 * @interface InboxFileImageMetadataModel
 */
export interface InboxFileImageMetadataModel {
    /**
     * Ширина.
     * @type {number}
     * @memberof InboxFileImageMetadataModel
     */
    'width': number;
    /**
     * Высота.
     * @type {number}
     * @memberof InboxFileImageMetadataModel
     */
    'height': number;
    /**
     * Дополнительные свойтсва файла.
     * @type {object}
     * @memberof InboxFileImageMetadataModel
     */
    'properties': object;
}
/**
 * Модель ссылочного содержимого файла Inbox.
 * @export
 * @interface InboxFileLinkContentModel
 */
export interface InboxFileLinkContentModel {
    /**
     * 
     * @type {InboxFileLinkSource}
     * @memberof InboxFileLinkContentModel
     */
    'source': InboxFileLinkSource;
    /**
     * URL содержимого файла.
     * @type {string}
     * @memberof InboxFileLinkContentModel
     */
    'url': string;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const InboxFileLinkSource = {
    Content: 'Content',
    Storage: 'Storage',
    Channel: 'Channel'
} as const;

export type InboxFileLinkSource = typeof InboxFileLinkSource[keyof typeof InboxFileLinkSource];


/**
 * 
 * @export
 * @enum {string}
 */

export const InboxFileMediaGroup = {
    Unknown: 'Unknown',
    Document: 'Document',
    DocumentText: 'DocumentText',
    DocumentTextPlain: 'DocumentTextPlain',
    DocumentTextRich: 'DocumentTextRich',
    DocumentSpreadsheet: 'DocumentSpreadsheet',
    DocumentPresentation: 'DocumentPresentation',
    DocumentPublication: 'DocumentPublication',
    DocumentMarkup: 'DocumentMarkup',
    DocumentCode: 'DocumentCode',
    DocumentData: 'DocumentData',
    Archive: 'Archive',
    Image: 'Image',
    ImageBitmap: 'ImageBitmap',
    ImageVector: 'ImageVector',
    Audio: 'Audio',
    Video: 'Video'
} as const;

export type InboxFileMediaGroup = typeof InboxFileMediaGroup[keyof typeof InboxFileMediaGroup];


/**
 * 
 * @export
 * @enum {string}
 */

export const InboxFileMediaType = {
    Unknown: 'Unknown',
    Document: 'Document',
    DocumentText: 'DocumentText',
    DocumentTextPlain: 'DocumentTextPlain',
    DocumentTextRich: 'DocumentTextRich',
    DocumentTextRichRtf: 'DocumentTextRichRtf',
    DocumentTextRichDoc: 'DocumentTextRichDoc',
    DocumentTextRichDocx: 'DocumentTextRichDocx',
    DocumentTextRichOdt: 'DocumentTextRichOdt',
    DocumentSpreadsheet: 'DocumentSpreadsheet',
    DocumentSpreadsheetXls: 'DocumentSpreadsheetXls',
    DocumentSpreadsheetXlsx: 'DocumentSpreadsheetXlsx',
    DocumentSpreadsheetOds: 'DocumentSpreadsheetOds',
    DocumentPresentation: 'DocumentPresentation',
    DocumentPresentationPpt: 'DocumentPresentationPpt',
    DocumentPresentationPptx: 'DocumentPresentationPptx',
    DocumentPresentationOdp: 'DocumentPresentationOdp',
    DocumentPublication: 'DocumentPublication',
    DocumentPublicationPdf: 'DocumentPublicationPdf',
    DocumentPublicationXps: 'DocumentPublicationXps',
    DocumentPublicationPs: 'DocumentPublicationPs',
    DocumentPublicationDjVu: 'DocumentPublicationDjVu',
    DocumentMarkup: 'DocumentMarkup',
    DocumentMarkupHtml: 'DocumentMarkupHtml',
    DocumentMarkupXml: 'DocumentMarkupXml',
    DocumentMarkupXhtml: 'DocumentMarkupXhtml',
    DocumentMarkupTeX: 'DocumentMarkupTeX',
    DocumentMarkupLaTeX: 'DocumentMarkupLaTeX',
    DocumentMarkupMd: 'DocumentMarkupMd',
    DocumentCode: 'DocumentCode',
    DocumentCodeCmd: 'DocumentCodeCmd',
    DocumentCodeCss: 'DocumentCodeCss',
    DocumentCodePhp: 'DocumentCodePhp',
    DocumentCodeJs: 'DocumentCodeJs',
    DocumentCodeTs: 'DocumentCodeTs',
    DocumentCodeSh: 'DocumentCodeSh',
    DocumentData: 'DocumentData',
    DocumentDataCsv: 'DocumentDataCsv',
    DocumentDataJson: 'DocumentDataJson',
    DocumentDataJsonLd: 'DocumentDataJsonLd',
    Archive: 'Archive',
    ArchiveZip: 'ArchiveZip',
    ArchiveGZip: 'ArchiveGZip',
    ArchiveRar: 'ArchiveRar',
    ArchiveTar: 'ArchiveTar',
    Archive7Z: 'Archive7Z',
    ArchiveBZip: 'ArchiveBZip',
    ArchiveBZip2: 'ArchiveBZip2',
    Image: 'Image',
    ImageBitmap: 'ImageBitmap',
    ImageBitmapBmp: 'ImageBitmapBmp',
    ImageBitmapPng: 'ImageBitmapPng',
    ImageBitmapGif: 'ImageBitmapGif',
    ImageBitmapTiff: 'ImageBitmapTiff',
    ImageBitmapJpeg: 'ImageBitmapJpeg',
    ImageBitmapIco: 'ImageBitmapIco',
    ImageBitmapWBmp: 'ImageBitmapWBmp',
    ImageBitmapWebP: 'ImageBitmapWebP',
    ImageBitmapAvif: 'ImageBitmapAvif',
    ImageBitmapPsd: 'ImageBitmapPsd',
    ImageBitmapXcf: 'ImageBitmapXcf',
    ImageVector: 'ImageVector',
    ImageVectorSvg: 'ImageVectorSvg',
    Audio: 'Audio',
    AudioOgg: 'AudioOgg',
    AudioMp3: 'AudioMp3',
    AudioMp4: 'AudioMp4',
    AudioBasic: 'AudioBasic',
    AudioL24: 'AudioL24',
    AudioAac: 'AudioAac',
    AudioVorbis: 'AudioVorbis',
    AudioWma: 'AudioWma',
    AudioRa: 'AudioRa',
    AudioWav: 'AudioWav',
    AudioWebM: 'AudioWebM',
    AudioCda: 'AudioCda',
    AudioMidi: 'AudioMidi',
    Video: 'Video',
    VideoOgg: 'VideoOgg',
    VideoMp4: 'VideoMp4',
    VideoWebM: 'VideoWebM',
    VideoMpeg: 'VideoMpeg',
    Video3Gpp: 'Video3Gpp',
    Video3Gpp2: 'Video3Gpp2',
    VideoQuickTime: 'VideoQuickTime',
    VideoWmv: 'VideoWmv',
    VideoFlv: 'VideoFlv',
    VideoAvi: 'VideoAvi'
} as const;

export type InboxFileMediaType = typeof InboxFileMediaType[keyof typeof InboxFileMediaType];


/**
 * Модель метаданных файла Inbox.
 * @export
 * @interface InboxFileMetadataModel
 */
export interface InboxFileMetadataModel {
    /**
     * Дополнительные свойтсва файла.
     * @type {any}
     * @memberof InboxFileMetadataModel
     */
    'properties': any;
}
/**
 * Модель файла Inbox.
 * @export
 * @interface InboxFileModel
 */
export interface InboxFileModel {
    /**
     * ИД.
     * @type {string}
     * @memberof InboxFileModel
     */
    'id': string;
    /**
     * Наименование.
     * @type {string}
     * @memberof InboxFileModel
     */
    'name': string;
    /**
     * Путь.
     * @type {string}
     * @memberof InboxFileModel
     */
    'path': string;
    /**
     * Размер.
     * @type {number}
     * @memberof InboxFileModel
     */
    'size': number;
    /**
     * Зашоловок.
     * @type {string}
     * @memberof InboxFileModel
     */
    'title': string;
    /**
     * Расширение.
     * @type {string}
     * @memberof InboxFileModel
     */
    'extension': string;
    /**
     * Тип MIME.
     * @type {string}
     * @memberof InboxFileModel
     */
    'mimeType': string;
    /**
     * 
     * @type {InboxFileMediaType}
     * @memberof InboxFileModel
     */
    'mediaType': InboxFileMediaType;
    /**
     * 
     * @type {InboxFileMediaGroup}
     * @memberof InboxFileModel
     */
    'mediaGroup': InboxFileMediaGroup;
    /**
     * 
     * @type {InboxFileCategory}
     * @memberof InboxFileModel
     */
    'category': InboxFileCategory;
    /**
     * 
     * @type {InboxFileMetadataModel}
     * @memberof InboxFileModel
     */
    'metadata': InboxFileMetadataModel;
    /**
     * Содержимое.
     * @type {Array<object>}
     * @memberof InboxFileModel
     */
    'content': Array<object>;
}


/**
 * 
 * @export
 * @enum {string}
 */

export const InboxFileStorageBucket = {
    NUMBER_0: 0,
    NUMBER_1: 1,
    NUMBER_2: 2
} as const;

export type InboxFileStorageBucket = typeof InboxFileStorageBucket[keyof typeof InboxFileStorageBucket];


/**
 * Модель сохраненного содержимого файла Inbox.
 * @export
 * @interface InboxFileStorageContentModel
 */
export interface InboxFileStorageContentModel {
    /**
     * Сервер хранилища.
     * @type {string}
     * @memberof InboxFileStorageContentModel
     */
    'server'?: string | null;
    /**
     * 
     * @type {InboxFileStorageBucket}
     * @memberof InboxFileStorageContentModel
     */
    'bucket': InboxFileStorageBucket;
    /**
     * Ключ данных.
     * @type {string}
     * @memberof InboxFileStorageContentModel
     */
    'key': string;
}
/**
 * Модель метаданных видео файла Inbox.
 * @export
 * @interface InboxFileVideoMetadataModel
 */
export interface InboxFileVideoMetadataModel {
    /**
     * Ширина.
     * @type {number}
     * @memberof InboxFileVideoMetadataModel
     */
    'width': number;
    /**
     * Высота.
     * @type {number}
     * @memberof InboxFileVideoMetadataModel
     */
    'height': number;
    /**
     * Длительность.
     * @type {string}
     * @memberof InboxFileVideoMetadataModel
     */
    'duration': string;
    /**
     * Дополнительные свойтсва файла.
     * @type {object}
     * @memberof InboxFileVideoMetadataModel
     */
    'properties': object;
}
/**
 * Модель упоминания в сообщении.
 * @export
 * @interface InboxMentionModel
 */
export interface InboxMentionModel {
    /**
     * ИД упомянутого пользователя.
     * @type {string}
     * @memberof InboxMentionModel
     */
    'mentionedUserId': string;
    /**
     * ИД упомянутого участника.
     * @type {string}
     * @memberof InboxMentionModel
     */
    'mentionedParticipantId': string;
    /**
     * Позиция начала упоминания в тексте сообщения.
     * @type {number}
     * @memberof InboxMentionModel
     */
    'startPosition': number;
    /**
     * Позиция конца упоминания в тексте сообщения.
     * @type {number}
     * @memberof InboxMentionModel
     */
    'endPosition': number;
}
/**
 * Модель активности сообщения Inbox.
 * @export
 * @interface InboxMessageActivityModel
 */
export interface InboxMessageActivityModel {
    /**
     * ИД активности.
     * @type {string}
     * @memberof InboxMessageActivityModel
     */
    'id': string;
}
/**
 * Модель внешних данных вложения сообщения Inbox.
 * @export
 * @interface InboxMessageAttachmentExternalModel
 */
export interface InboxMessageAttachmentExternalModel {
    /**
     * ИД данных.
     * @type {string}
     * @memberof InboxMessageAttachmentExternalModel
     */
    'id': string;
}
/**
 * Модель вложения сообщения Inbox.
 * @export
 * @interface InboxMessageAttachmentModel
 */
export interface InboxMessageAttachmentModel {
    /**
     * ИД вложения.
     * @type {string}
     * @memberof InboxMessageAttachmentModel
     */
    'id': string;
    /**
     * 
     * @type {InboxFileModel}
     * @memberof InboxMessageAttachmentModel
     */
    'file': InboxFileModel;
    /**
     * Миниатюры вложения.
     * @type {Array<InboxThumbnailModel>}
     * @memberof InboxMessageAttachmentModel
     */
    'thumbnails': Array<InboxThumbnailModel>;
    /**
     * 
     * @type {InboxMessageAttachmentExternalModel}
     * @memberof InboxMessageAttachmentModel
     */
    'external': InboxMessageAttachmentExternalModel;
}
/**
 * Модель чата сообщения Inbox.
 * @export
 * @interface InboxMessageChatModel
 */
export interface InboxMessageChatModel {
    /**
     * ИД чата.
     * @type {string}
     * @memberof InboxMessageChatModel
     */
    'id': string;
}
/**
 * Модель содержимого сообщения Inbox.
 * @export
 * @interface InboxMessageContentModel
 */
export interface InboxMessageContentModel {
    /**
     * Тип содержимого.
     * @type {string}
     * @memberof InboxMessageContentModel
     */
    'type': string;
    /**
     * Текст содержимого.
     * @type {string}
     * @memberof InboxMessageContentModel
     */
    'text': string;
}
/**
 * Модель внешних данных сообщения Inbox.
 * @export
 * @interface InboxMessageExternalModel
 */
export interface InboxMessageExternalModel {
    /**
     * ИД данных.
     * @type {string}
     * @memberof InboxMessageExternalModel
     */
    'id'?: string | null;
}
/**
 * Модель сообщения Inbox.
 * @export
 * @interface InboxMessageModel
 */
export interface InboxMessageModel {
    /**
     * ИД сообщения.
     * @type {string}
     * @memberof InboxMessageModel
     */
    'id': string;
    /**
     * ИД тенанта.
     * @type {string}
     * @memberof InboxMessageModel
     */
    'tenantId': string;
    /**
     * Дата и время создания сообщения.
     * @type {string}
     * @memberof InboxMessageModel
     */
    'createdOn': string;
    /**
     * 
     * @type {InboxMessageType}
     * @memberof InboxMessageModel
     */
    'type': InboxMessageType;
    /**
     * Наименование сообщения.
     * @type {string}
     * @memberof InboxMessageModel
     */
    'name': string;
    /**
     * 
     * @type {InboxMessageStatus}
     * @memberof InboxMessageModel
     */
    'status': InboxMessageStatus;
    /**
     * 
     * @type {InboxDirection}
     * @memberof InboxMessageModel
     */
    'direction': InboxDirection;
    /**
     * Метка времени.
     * @type {string}
     * @memberof InboxMessageModel
     */
    'timestamp': string;
    /**
     * 
     * @type {InboxMessageContentModel}
     * @memberof InboxMessageModel
     */
    'content': InboxMessageContentModel;
    /**
     * 
     * @type {InboxMessageRecordModel}
     * @memberof InboxMessageModel
     */
    'record': InboxMessageRecordModel;
    /**
     * 
     * @type {InboxMessageActivityModel}
     * @memberof InboxMessageModel
     */
    'activity': InboxMessageActivityModel;
    /**
     * 
     * @type {InboxMessageChatModel}
     * @memberof InboxMessageModel
     */
    'chat': InboxMessageChatModel;
    /**
     * 
     * @type {InboxMessageSessionModel}
     * @memberof InboxMessageModel
     */
    'session': InboxMessageSessionModel;
    /**
     * Участники сообщения.
     * @type {Array<InboxMessageParticipantModel>}
     * @memberof InboxMessageModel
     */
    'participants': Array<InboxMessageParticipantModel>;
    /**
     * Назначения участников.
     * @type {Array<InboxMessageParticipantModel>}
     * @memberof InboxMessageModel
     */
    'assignments': Array<InboxMessageParticipantModel>;
    /**
     * Вложения сообщения.
     * @type {Array<InboxMessageAttachmentModel>}
     * @memberof InboxMessageModel
     */
    'attachments': Array<InboxMessageAttachmentModel>;
    /**
     * Упоминания участников чата.
     * @type {Array<InboxMentionModel>}
     * @memberof InboxMessageModel
     */
    'mentions': Array<InboxMentionModel>;
    /**
     * Цитируемые сообщения.
     * @type {Array<InboxMessageModel>}
     * @memberof InboxMessageModel
     */
    'quotedMessages': Array<InboxMessageModel>;
    /**
     * 
     * @type {InboxMessageParticipantModel}
     * @memberof InboxMessageModel
     */
    'senderParticipant'?: InboxMessageParticipantModel;
    /**
     * 
     * @type {InboxMessageExternalModel}
     * @memberof InboxMessageModel
     */
    'external': InboxMessageExternalModel;
    /**
     * 
     * @type {InboxOperatorGroupModel}
     * @memberof InboxMessageModel
     */
    'assignedOperatorGroup'?: InboxOperatorGroupModel;
}


/**
 * 
 * @export
 * @interface InboxMessageModelPaginationResponse
 */
export interface InboxMessageModelPaginationResponse {
    /**
     * 
     * @type {Array<InboxMessageModel>}
     * @memberof InboxMessageModelPaginationResponse
     */
    'items'?: Array<InboxMessageModel> | null;
    /**
     * 
     * @type {boolean}
     * @memberof InboxMessageModelPaginationResponse
     */
    'hasMore'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof InboxMessageModelPaginationResponse
     */
    'pageIndex'?: number;
    /**
     * 
     * @type {number}
     * @memberof InboxMessageModelPaginationResponse
     */
    'pageSize'?: number;
    /**
     * 
     * @type {number}
     * @memberof InboxMessageModelPaginationResponse
     */
    'totalItemCount'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof InboxMessageModelPaginationResponse
     */
    'totalPageCount'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof InboxMessageModelPaginationResponse
     */
    'fromItemInclusive'?: number;
    /**
     * 
     * @type {number}
     * @memberof InboxMessageModelPaginationResponse
     */
    'toItemExclusive'?: number;
}
/**
 * Модель участника сообщения Inbox.
 * @export
 * @interface InboxMessageParticipantModel
 */
export interface InboxMessageParticipantModel {
    /**
     * ИД участника.
     * @type {string}
     * @memberof InboxMessageParticipantModel
     */
    'id': string;
    /**
     * 
     * @type {InboxMessageParticipantStatus}
     * @memberof InboxMessageParticipantModel
     */
    'status': InboxMessageParticipantStatus;
    /**
     * 
     * @type {InboxMessageParticipantSubjectModel}
     * @memberof InboxMessageParticipantModel
     */
    'subject': InboxMessageParticipantSubjectModel;
}


/**
 * 
 * @export
 * @enum {string}
 */

export const InboxMessageParticipantStatus = {
    Undefined: 'Undefined',
    Queued: 'Queued',
    Assigned: 'Assigned',
    Closed: 'Closed'
} as const;

export type InboxMessageParticipantStatus = typeof InboxMessageParticipantStatus[keyof typeof InboxMessageParticipantStatus];


/**
 * Модель субъекта участника сообщений Inbox.
 * @export
 * @interface InboxMessageParticipantSubjectModel
 */
export interface InboxMessageParticipantSubjectModel {
    /**
     * ИД субъекта.
     * @type {string}
     * @memberof InboxMessageParticipantSubjectModel
     */
    'id': string;
    /**
     * Роль субъекта.
     * @type {string}
     * @memberof InboxMessageParticipantSubjectModel
     */
    'role': string;
    /**
     * Полное имя субъекта.
     * @type {string}
     * @memberof InboxMessageParticipantSubjectModel
     */
    'fullName': string;
    /**
     * Краткое имя субъекта.
     * @type {string}
     * @memberof InboxMessageParticipantSubjectModel
     */
    'shortName': string;
    /**
     * Аватар.
     * @type {string}
     * @memberof InboxMessageParticipantSubjectModel
     */
    'avatar'?: string | null;
}
/**
 * Модель записи сообщения Inbox.
 * @export
 * @interface InboxMessageRecordModel
 */
export interface InboxMessageRecordModel {
    /**
     * ИД записи.
     * @type {string}
     * @memberof InboxMessageRecordModel
     */
    'id': string;
}
/**
 * Модель сеанса сообщения Inbox.
 * @export
 * @interface InboxMessageSessionModel
 */
export interface InboxMessageSessionModel {
    /**
     * ИД сеанса.
     * @type {string}
     * @memberof InboxMessageSessionModel
     */
    'id': string;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const InboxMessageStatus = {
    Pending: 'Pending',
    Processing: 'Processing',
    Ready: 'Ready'
} as const;

export type InboxMessageStatus = typeof InboxMessageStatus[keyof typeof InboxMessageStatus];


/**
 * 
 * @export
 * @enum {string}
 */

export const InboxMessageType = {
    Content: 'Content',
    History: 'History'
} as const;

export type InboxMessageType = typeof InboxMessageType[keyof typeof InboxMessageType];


/**
 * Запрос на создание группы операторов Inbox.
 * @export
 * @interface InboxOperatorGroupCreationRequest
 */
export interface InboxOperatorGroupCreationRequest {
    /**
     * Название группы.
     * @type {string}
     * @memberof InboxOperatorGroupCreationRequest
     */
    'name': string;
    /**
     * Описание группы.
     * @type {string}
     * @memberof InboxOperatorGroupCreationRequest
     */
    'description'?: string | null;
    /**
     * Список ИД операторов группы.
     * @type {Array<string>}
     * @memberof InboxOperatorGroupCreationRequest
     */
    'authUserIds': Array<string>;
}
/**
 * Ответ на запрос создания группы операторов Inbox.
 * @export
 * @interface InboxOperatorGroupCreationResponse
 */
export interface InboxOperatorGroupCreationResponse {
    /**
     * ИД.
     * @type {string}
     * @memberof InboxOperatorGroupCreationResponse
     */
    'id': string;
}
/**
 * Модель группы операторов Inbox.
 * @export
 * @interface InboxOperatorGroupModel
 */
export interface InboxOperatorGroupModel {
    /**
     * ИД группы.
     * @type {string}
     * @memberof InboxOperatorGroupModel
     */
    'id': string;
    /**
     * Название группы.
     * @type {string}
     * @memberof InboxOperatorGroupModel
     */
    'name': string;
    /**
     * Описание группы.
     * @type {string}
     * @memberof InboxOperatorGroupModel
     */
    'description'?: string | null;
    /**
     * Список операторов группы.
     * @type {Array<InboxOperatorGroupOperatorModel>}
     * @memberof InboxOperatorGroupModel
     */
    'operators': Array<InboxOperatorGroupOperatorModel>;
}
/**
 * 
 * @export
 * @interface InboxOperatorGroupModelPaginationResponse
 */
export interface InboxOperatorGroupModelPaginationResponse {
    /**
     * 
     * @type {Array<InboxOperatorGroupModel>}
     * @memberof InboxOperatorGroupModelPaginationResponse
     */
    'items'?: Array<InboxOperatorGroupModel> | null;
    /**
     * 
     * @type {boolean}
     * @memberof InboxOperatorGroupModelPaginationResponse
     */
    'hasMore'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof InboxOperatorGroupModelPaginationResponse
     */
    'pageIndex'?: number;
    /**
     * 
     * @type {number}
     * @memberof InboxOperatorGroupModelPaginationResponse
     */
    'pageSize'?: number;
    /**
     * 
     * @type {number}
     * @memberof InboxOperatorGroupModelPaginationResponse
     */
    'totalItemCount'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof InboxOperatorGroupModelPaginationResponse
     */
    'totalPageCount'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof InboxOperatorGroupModelPaginationResponse
     */
    'fromItemInclusive'?: number;
    /**
     * 
     * @type {number}
     * @memberof InboxOperatorGroupModelPaginationResponse
     */
    'toItemExclusive'?: number;
}
/**
 * Модель оператора в группе операторов Inbox.
 * @export
 * @interface InboxOperatorGroupOperatorModel
 */
export interface InboxOperatorGroupOperatorModel {
    /**
     * 
     * @type {UserModel}
     * @memberof InboxOperatorGroupOperatorModel
     */
    'operator': UserModel;
    /**
     * Список групп оператора.
     * @type {Array<InboxOperatorGroupModel>}
     * @memberof InboxOperatorGroupOperatorModel
     */
    'groups': Array<InboxOperatorGroupModel>;
}
/**
 * Запрос на обновление группы операторов Inbox.
 * @export
 * @interface InboxOperatorGroupUpdatingRequest
 */
export interface InboxOperatorGroupUpdatingRequest {
    /**
     * Название группы.
     * @type {string}
     * @memberof InboxOperatorGroupUpdatingRequest
     */
    'name': string;
    /**
     * Описание группы.
     * @type {string}
     * @memberof InboxOperatorGroupUpdatingRequest
     */
    'description'?: string | null;
    /**
     * Список ИД операторов группы.
     * @type {Array<string>}
     * @memberof InboxOperatorGroupUpdatingRequest
     */
    'authUserIds': Array<string>;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const InboxParticipantActivityEvent = {
    ActionInvoked: 'ActionInvoked',
    SetOffline: 'SetOffline',
    SetOnline: 'SetOnline',
    HubDisconnected: 'HubDisconnected',
    HubConnected: 'HubConnected',
    TabHidden: 'TabHidden',
    TabShowed: 'TabShowed',
    TabAbandoned: 'TabAbandoned',
    TabActivated: 'TabActivated'
} as const;

export type InboxParticipantActivityEvent = typeof InboxParticipantActivityEvent[keyof typeof InboxParticipantActivityEvent];


/**
 * 
 * @export
 * @enum {string}
 */

export const InboxParticipantActivityStatus = {
    Idle: 'Idle',
    Active: 'Active'
} as const;

export type InboxParticipantActivityStatus = typeof InboxParticipantActivityStatus[keyof typeof InboxParticipantActivityStatus];


/**
 * Запрос на обновление активноти участника Inbox.
 * @export
 * @interface InboxParticipantActivityUpdatingRequest
 */
export interface InboxParticipantActivityUpdatingRequest {
    /**
     * 
     * @type {InboxParticipantActivityEvent}
     * @memberof InboxParticipantActivityUpdatingRequest
     */
    'event': InboxParticipantActivityEvent;
}


/**
 * Запрос на приглашение участника.
 * @export
 * @interface InboxParticipantInvitationRequest
 */
export interface InboxParticipantInvitationRequest {
    /**
     * ФИО.
     * @type {string}
     * @memberof InboxParticipantInvitationRequest
     */
    'fullName': string;
    /**
     * Фамилия.
     * @type {string}
     * @memberof InboxParticipantInvitationRequest
     */
    'familyName': string;
    /**
     * Имя.
     * @type {string}
     * @memberof InboxParticipantInvitationRequest
     */
    'givenName': string;
    /**
     * Отчество.
     * @type {string}
     * @memberof InboxParticipantInvitationRequest
     */
    'middleName'?: string | null;
    /**
     * Краткое имя.
     * @type {string}
     * @memberof InboxParticipantInvitationRequest
     */
    'shortName'?: string | null;
    /**
     * Email.
     * @type {string}
     * @memberof InboxParticipantInvitationRequest
     */
    'email': string;
    /**
     * Номер телефона.
     * @type {string}
     * @memberof InboxParticipantInvitationRequest
     */
    'phoneNumber'?: string | null;
    /**
     * Наименования ролей.
     * @type {Array<string>}
     * @memberof InboxParticipantInvitationRequest
     */
    'roleNames': Array<string>;
    /**
     * ИД групп операторов.
     * @type {Array<string>}
     * @memberof InboxParticipantInvitationRequest
     */
    'operatorGroupIds': Array<string>;
    /**
     * 
     * @type {InboxParticipantSettingsModel}
     * @memberof InboxParticipantInvitationRequest
     */
    'settings': InboxParticipantSettingsModel;
}
/**
 * Модель участника Inbox.
 * @export
 * @interface InboxParticipantModel
 */
export interface InboxParticipantModel {
    /**
     * ИД участника.
     * @type {string}
     * @memberof InboxParticipantModel
     */
    'id': string;
    /**
     * ИД тенанта.
     * @type {string}
     * @memberof InboxParticipantModel
     */
    'tenantId': string;
    /**
     * Дата создания участника.
     * @type {string}
     * @memberof InboxParticipantModel
     */
    'createdOn': string;
    /**
     * Дата изменения участника.
     * @type {string}
     * @memberof InboxParticipantModel
     */
    'modifiedOn': string;
    /**
     * 
     * @type {InboxParticipantSubjectModel}
     * @memberof InboxParticipantModel
     */
    'subject': InboxParticipantSubjectModel;
    /**
     * 
     * @type {InboxParticipantStatisticsModel}
     * @memberof InboxParticipantModel
     */
    'statistics': InboxParticipantStatisticsModel;
    /**
     * 
     * @type {InboxParticipantSettingsModel}
     * @memberof InboxParticipantModel
     */
    'settings': InboxParticipantSettingsModel;
    /**
     * 
     * @type {InboxParticipantStatus}
     * @memberof InboxParticipantModel
     */
    'status': InboxParticipantStatus;
    /**
     * Список групп для участника-оператора.
     * @type {Array<InboxOperatorGroupModel>}
     * @memberof InboxParticipantModel
     */
    'operatorGroups': Array<InboxOperatorGroupModel>;
    /**
     * 
     * @type {TariffServiceResultModel}
     * @memberof InboxParticipantModel
     */
    'tariffResult': TariffServiceResultModel;
}


/**
 * 
 * @export
 * @interface InboxParticipantModelPaginationResponse
 */
export interface InboxParticipantModelPaginationResponse {
    /**
     * 
     * @type {Array<InboxParticipantModel>}
     * @memberof InboxParticipantModelPaginationResponse
     */
    'items'?: Array<InboxParticipantModel> | null;
    /**
     * 
     * @type {boolean}
     * @memberof InboxParticipantModelPaginationResponse
     */
    'hasMore'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof InboxParticipantModelPaginationResponse
     */
    'pageIndex'?: number;
    /**
     * 
     * @type {number}
     * @memberof InboxParticipantModelPaginationResponse
     */
    'pageSize'?: number;
    /**
     * 
     * @type {number}
     * @memberof InboxParticipantModelPaginationResponse
     */
    'totalItemCount'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof InboxParticipantModelPaginationResponse
     */
    'totalPageCount'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof InboxParticipantModelPaginationResponse
     */
    'fromItemInclusive'?: number;
    /**
     * 
     * @type {number}
     * @memberof InboxParticipantModelPaginationResponse
     */
    'toItemExclusive'?: number;
}
/**
 * Модель настроек уведомлений участника Inbox.
 * @export
 * @interface InboxParticipantNotificationsSettingsModel
 */
export interface InboxParticipantNotificationsSettingsModel {
    /**
     * Включены ли уведомления?
     * @type {boolean}
     * @memberof InboxParticipantNotificationsSettingsModel
     */
    'enabled': boolean;
    /**
     * Звук появления чата в очереди.
     * @type {string}
     * @memberof InboxParticipantNotificationsSettingsModel
     */
    'chatQueuedSound'?: string | null;
    /**
     * Звук назначения чата на оператора.
     * @type {string}
     * @memberof InboxParticipantNotificationsSettingsModel
     */
    'chatAssignedSound'?: string | null;
    /**
     * Звук получения входящего сообщения.
     * @type {string}
     * @memberof InboxParticipantNotificationsSettingsModel
     */
    'messageReceivedSound'?: string | null;
}
/**
 * Модель настроек участника Inbox.
 * @export
 * @interface InboxParticipantSettingsModel
 */
export interface InboxParticipantSettingsModel {
    /**
     * 
     * @type {InboxParticipantNotificationsSettingsModel}
     * @memberof InboxParticipantSettingsModel
     */
    'notifications': InboxParticipantNotificationsSettingsModel;
}
/**
 * Модель активности участника Inbox.
 * @export
 * @interface InboxParticipantStatisticsActivityModel
 */
export interface InboxParticipantStatisticsActivityModel {
    /**
     * 
     * @type {InboxParticipantActivityEvent}
     * @memberof InboxParticipantStatisticsActivityModel
     */
    'event'?: InboxParticipantActivityEvent;
    /**
     * 
     * @type {InboxParticipantActivityStatus}
     * @memberof InboxParticipantStatisticsActivityModel
     */
    'status'?: InboxParticipantActivityStatus;
}


/**
 * Модель статистики участника Inbox.
 * @export
 * @interface InboxParticipantStatisticsModel
 */
export interface InboxParticipantStatisticsModel {
    /**
     * Количество чатов в месяц.
     * @type {number}
     * @memberof InboxParticipantStatisticsModel
     */
    'monthlyChatCount': number;
    /**
     * Количество чатов в очереди.
     * @type {number}
     * @memberof InboxParticipantStatisticsModel
     */
    'queuedChatCount': number;
    /**
     * Количество назначенных чатов.
     * @type {number}
     * @memberof InboxParticipantStatisticsModel
     */
    'assignedChatCount': number;
    /**
     * 
     * @type {InboxParticipantStatisticsActivityModel}
     * @memberof InboxParticipantStatisticsModel
     */
    'lastActivity': InboxParticipantStatisticsActivityModel;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const InboxParticipantStatus = {
    Offline: 'Offline',
    Online: 'Online'
} as const;

export type InboxParticipantStatus = typeof InboxParticipantStatus[keyof typeof InboxParticipantStatus];


/**
 * Запрос на обновление статуса участника Inbox.
 * @export
 * @interface InboxParticipantStatusUpdatingRequest
 */
export interface InboxParticipantStatusUpdatingRequest {
    /**
     * 
     * @type {InboxParticipantStatus}
     * @memberof InboxParticipantStatusUpdatingRequest
     */
    'status': InboxParticipantStatus;
}


/**
 * Модель субъекта участника Inbox.
 * @export
 * @interface InboxParticipantSubjectModel
 */
export interface InboxParticipantSubjectModel {
    /**
     * ИД субъекта.
     * @type {string}
     * @memberof InboxParticipantSubjectModel
     */
    'id': string;
    /**
     * Роль субъекта.
     * @type {string}
     * @memberof InboxParticipantSubjectModel
     */
    'role': string;
    /**
     * 
     * @type {InboxPersonModel}
     * @memberof InboxParticipantSubjectModel
     */
    'person': InboxPersonModel;
}
/**
 * Запрос на обновление участника.
 * @export
 * @interface InboxParticipantUpdatingRequest
 */
export interface InboxParticipantUpdatingRequest {
    /**
     * ФИО.
     * @type {string}
     * @memberof InboxParticipantUpdatingRequest
     */
    'fullName': string;
    /**
     * Фамилия.
     * @type {string}
     * @memberof InboxParticipantUpdatingRequest
     */
    'familyName': string;
    /**
     * Имя.
     * @type {string}
     * @memberof InboxParticipantUpdatingRequest
     */
    'givenName': string;
    /**
     * Отчество.
     * @type {string}
     * @memberof InboxParticipantUpdatingRequest
     */
    'middleName'?: string | null;
    /**
     * Краткое имя.
     * @type {string}
     * @memberof InboxParticipantUpdatingRequest
     */
    'shortName'?: string | null;
    /**
     * Email.
     * @type {string}
     * @memberof InboxParticipantUpdatingRequest
     */
    'email': string;
    /**
     * Номер телефона.
     * @type {string}
     * @memberof InboxParticipantUpdatingRequest
     */
    'phoneNumber'?: string | null;
    /**
     * Наименования ролей.
     * @type {Array<string>}
     * @memberof InboxParticipantUpdatingRequest
     */
    'roleNames': Array<string>;
    /**
     * ИД групп операторов.
     * @type {Array<string>}
     * @memberof InboxParticipantUpdatingRequest
     */
    'operatorGroupIds': Array<string>;
    /**
     * 
     * @type {InboxParticipantSettingsModel}
     * @memberof InboxParticipantUpdatingRequest
     */
    'settings': InboxParticipantSettingsModel;
}
/**
 * Модель контакта Inbox.
 * @export
 * @interface InboxPersonModel
 */
export interface InboxPersonModel {
    /**
     * ИД.
     * @type {string}
     * @memberof InboxPersonModel
     */
    'id'?: string | null;
    /**
     * Аватар.
     * @type {string}
     * @memberof InboxPersonModel
     */
    'avatar'?: string | null;
    /**
     * 
     * @type {UserStatus}
     * @memberof InboxPersonModel
     */
    'status'?: UserStatus;
    /**
     * 
     * @type {UserInvitationResult}
     * @memberof InboxPersonModel
     */
    'invitationResult'?: UserInvitationResult;
    /**
     * 
     * @type {PersonName}
     * @memberof InboxPersonModel
     */
    'name': PersonName;
    /**
     * 
     * @type {PersonContacts}
     * @memberof InboxPersonModel
     */
    'contacts': PersonContacts;
    /**
     * 
     * @type {PersonLocation}
     * @memberof InboxPersonModel
     */
    'location': PersonLocation;
    /**
     * Доменные роли.
     * @type {Array<PersonRole>}
     * @memberof InboxPersonModel
     */
    'roles': Array<PersonRole>;
    /**
     * Теги.
     * @type {Array<TagModel>}
     * @memberof InboxPersonModel
     */
    'tags': Array<TagModel>;
}


/**
 * Модель записи Inbox.
 * @export
 * @interface InboxRecordModel
 */
export interface InboxRecordModel {
    /**
     * ИД Записи.
     * @type {string}
     * @memberof InboxRecordModel
     */
    'id': string;
    /**
     * ИД тенанта.
     * @type {string}
     * @memberof InboxRecordModel
     */
    'tenantId': string;
    /**
     * Дата и время создания записи.
     * @type {string}
     * @memberof InboxRecordModel
     */
    'createdOn': string;
    /**
     * 
     * @type {InboxDirection}
     * @memberof InboxRecordModel
     */
    'direction': InboxDirection;
}


/**
 * 
 * @export
 * @interface InboxRecordModelPaginationResponse
 */
export interface InboxRecordModelPaginationResponse {
    /**
     * 
     * @type {Array<InboxRecordModel>}
     * @memberof InboxRecordModelPaginationResponse
     */
    'items'?: Array<InboxRecordModel> | null;
    /**
     * 
     * @type {boolean}
     * @memberof InboxRecordModelPaginationResponse
     */
    'hasMore'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof InboxRecordModelPaginationResponse
     */
    'pageIndex'?: number;
    /**
     * 
     * @type {number}
     * @memberof InboxRecordModelPaginationResponse
     */
    'pageSize'?: number;
    /**
     * 
     * @type {number}
     * @memberof InboxRecordModelPaginationResponse
     */
    'totalItemCount'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof InboxRecordModelPaginationResponse
     */
    'totalPageCount'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof InboxRecordModelPaginationResponse
     */
    'fromItemInclusive'?: number;
    /**
     * 
     * @type {number}
     * @memberof InboxRecordModelPaginationResponse
     */
    'toItemExclusive'?: number;
}
/**
 * Модель активности сеанса Inbox.
 * @export
 * @interface InboxSessionActivityModel
 */
export interface InboxSessionActivityModel {
    /**
     * ИД активности.
     * @type {string}
     * @memberof InboxSessionActivityModel
     */
    'id': string;
}
/**
 * Модель чата сеанса Inbox.
 * @export
 * @interface InboxSessionChatModel
 */
export interface InboxSessionChatModel {
    /**
     * ИД чата.
     * @type {string}
     * @memberof InboxSessionChatModel
     */
    'id': string;
}
/**
 * Модель сеанса Inbox.
 * @export
 * @interface InboxSessionModel
 */
export interface InboxSessionModel {
    /**
     * ИД сеанса.
     * @type {string}
     * @memberof InboxSessionModel
     */
    'id': string;
    /**
     * ИД тенанта.
     * @type {string}
     * @memberof InboxSessionModel
     */
    'tenantId': string;
    /**
     * 
     * @type {InboxSessionStatus}
     * @memberof InboxSessionModel
     */
    'status': InboxSessionStatus;
    /**
     * 
     * @type {InboxSessionRecordModel}
     * @memberof InboxSessionModel
     */
    'record': InboxSessionRecordModel;
    /**
     * 
     * @type {InboxSessionActivityModel}
     * @memberof InboxSessionModel
     */
    'activity': InboxSessionActivityModel;
    /**
     * 
     * @type {InboxSessionChatModel}
     * @memberof InboxSessionModel
     */
    'chat': InboxSessionChatModel;
    /**
     * Участники сеанса.
     * @type {Array<InboxSessionParticipantModel>}
     * @memberof InboxSessionModel
     */
    'participants': Array<InboxSessionParticipantModel>;
    /**
     * 
     * @type {InboxSessionStatisticsModel}
     * @memberof InboxSessionModel
     */
    'statistics': InboxSessionStatisticsModel;
}


/**
 * 
 * @export
 * @interface InboxSessionModelPaginationResponse
 */
export interface InboxSessionModelPaginationResponse {
    /**
     * 
     * @type {Array<InboxSessionModel>}
     * @memberof InboxSessionModelPaginationResponse
     */
    'items'?: Array<InboxSessionModel> | null;
    /**
     * 
     * @type {boolean}
     * @memberof InboxSessionModelPaginationResponse
     */
    'hasMore'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof InboxSessionModelPaginationResponse
     */
    'pageIndex'?: number;
    /**
     * 
     * @type {number}
     * @memberof InboxSessionModelPaginationResponse
     */
    'pageSize'?: number;
    /**
     * 
     * @type {number}
     * @memberof InboxSessionModelPaginationResponse
     */
    'totalItemCount'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof InboxSessionModelPaginationResponse
     */
    'totalPageCount'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof InboxSessionModelPaginationResponse
     */
    'fromItemInclusive'?: number;
    /**
     * 
     * @type {number}
     * @memberof InboxSessionModelPaginationResponse
     */
    'toItemExclusive'?: number;
}
/**
 * Модель участника сеанса Inbox.
 * @export
 * @interface InboxSessionParticipantModel
 */
export interface InboxSessionParticipantModel {
    /**
     * ИД участника.
     * @type {string}
     * @memberof InboxSessionParticipantModel
     */
    'id': string;
    /**
     * 
     * @type {InboxSessionParticipantStatus}
     * @memberof InboxSessionParticipantModel
     */
    'status': InboxSessionParticipantStatus;
    /**
     * 
     * @type {InboxSessionParticipantSubjectModel}
     * @memberof InboxSessionParticipantModel
     */
    'subject': InboxSessionParticipantSubjectModel;
    /**
     * 
     * @type {InboxSessionParticipantStatisticsModel}
     * @memberof InboxSessionParticipantModel
     */
    'statistics': InboxSessionParticipantStatisticsModel;
}


/**
 * Модель статистики участника сеанса Inbox.
 * @export
 * @interface InboxSessionParticipantStatisticsModel
 */
export interface InboxSessionParticipantStatisticsModel {
    /**
     * Количество непрочитанных сообщений.
     * @type {number}
     * @memberof InboxSessionParticipantStatisticsModel
     */
    'unreadMessageCount': number;
    /**
     * Есть ли непрочитанные сообщения.
     * @type {boolean}
     * @memberof InboxSessionParticipantStatisticsModel
     */
    'hasUnreadMessages': boolean;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const InboxSessionParticipantStatus = {
    Undefined: 'Undefined',
    Queued: 'Queued',
    Assigned: 'Assigned',
    Closed: 'Closed'
} as const;

export type InboxSessionParticipantStatus = typeof InboxSessionParticipantStatus[keyof typeof InboxSessionParticipantStatus];


/**
 * Модель субъекта участника сеанса Inbox.
 * @export
 * @interface InboxSessionParticipantSubjectModel
 */
export interface InboxSessionParticipantSubjectModel {
    /**
     * ИД субъекта.
     * @type {string}
     * @memberof InboxSessionParticipantSubjectModel
     */
    'id': string;
    /**
     * Роль субъекта.
     * @type {string}
     * @memberof InboxSessionParticipantSubjectModel
     */
    'role': string;
}
/**
 * Модель записи сеанса Inbox.
 * @export
 * @interface InboxSessionRecordModel
 */
export interface InboxSessionRecordModel {
    /**
     * ИД записи.
     * @type {string}
     * @memberof InboxSessionRecordModel
     */
    'id': string;
}
/**
 * Модель статистики сеанса Inbox.
 * @export
 * @interface InboxSessionStatisticsModel
 */
export interface InboxSessionStatisticsModel {
    /**
     * Количество сообщений.
     * @type {number}
     * @memberof InboxSessionStatisticsModel
     */
    'messageCount': number;
    /**
     * Количество входящих сообщений.
     * @type {number}
     * @memberof InboxSessionStatisticsModel
     */
    'inboundMessageCount': number;
    /**
     * Количество исходящих сообщений.
     * @type {number}
     * @memberof InboxSessionStatisticsModel
     */
    'outboundMessageCount': number;
    /**
     * Количество внутренних сообщений.
     * @type {number}
     * @memberof InboxSessionStatisticsModel
     */
    'internalMessageCount': number;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const InboxSessionStatus = {
    Created: 'Created',
    Queued: 'Queued',
    Assigned: 'Assigned',
    Closed: 'Closed'
} as const;

export type InboxSessionStatus = typeof InboxSessionStatus[keyof typeof InboxSessionStatus];


/**
 * Модель миниатюры Inbox.
 * @export
 * @interface InboxThumbnailModel
 */
export interface InboxThumbnailModel {
    /**
     * Наименование.
     * @type {string}
     * @memberof InboxThumbnailModel
     */
    'name': string;
    /**
     * 
     * @type {InboxFileModel}
     * @memberof InboxThumbnailModel
     */
    'file': InboxFileModel;
}
/**
 * 
 * @export
 * @interface InputActionSchema
 */
export interface InputActionSchema extends ActionSchema {
    [key: string]: any;

    /**
     * 
     * @type {string}
     * @memberof InputActionSchema
     */
    '$kind': string;
    /**
     * 
     * @type {Array<MessageSchema>}
     * @memberof InputActionSchema
     */
    'messages': Array<MessageSchema>;
    /**
     * 
     * @type {string}
     * @memberof InputActionSchema
     */
    'variableId'?: string | null;
    /**
     * 
     * @type {Array<ValidatorSchema>}
     * @memberof InputActionSchema
     */
    'validators': Array<ValidatorSchema>;
    /**
     * 
     * @type {boolean}
     * @memberof InputActionSchema
     */
    'required': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof InputActionSchema
     */
    'cancellable': boolean;
    /**
     * 
     * @type {Array<MessageSchema>}
     * @memberof InputActionSchema
     */
    'unrecognizedPromptMessages': Array<MessageSchema>;
    /**
     * 
     * @type {Array<MessageSchema>}
     * @memberof InputActionSchema
     */
    'defaultValueResponseMessages': Array<MessageSchema>;
    /**
     * 
     * @type {Array<MessageSchema>}
     * @memberof InputActionSchema
     */
    'customValidationErrorMessages': Array<MessageSchema>;
    /**
     * 
     * @type {number}
     * @memberof InputActionSchema
     */
    'maxTurnCount': number;
    /**
     * 
     * @type {string}
     * @memberof InputActionSchema
     */
    'defaultValue'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof InputActionSchema
     */
    'canShowHelp': boolean;
    /**
     * 
     * @type {Array<MessageSchema>}
     * @memberof InputActionSchema
     */
    'helpMessages': Array<MessageSchema>;
    /**
     * 
     * @type {boolean}
     * @memberof InputActionSchema
     */
    'canTransferToOperator': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof InputActionSchema
     */
    'alwaysPrompt': boolean;
}
/**
 * 
 * @export
 * @interface IntegrationSettings
 */
export interface IntegrationSettings {
    /**
     * 
     * @type {string}
     * @memberof IntegrationSettings
     */
    'name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof IntegrationSettings
     */
    'code'?: string | null;
}
/**
 * 
 * @export
 * @interface IntegrationSettingsSchema
 */
export interface IntegrationSettingsSchema extends EntitySchema {
    [key: string]: any;

    /**
     * 
     * @type {string}
     * @memberof IntegrationSettingsSchema
     */
    '$kind': string;
    /**
     * 
     * @type {string}
     * @memberof IntegrationSettingsSchema
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof IntegrationSettingsSchema
     */
    'code': string;
}
/**
 * 
 * @export
 * @interface IntentContentSchema
 */
export interface IntentContentSchema extends EntitySchema {
    [key: string]: any;

    /**
     * 
     * @type {SchemaKind}
     * @memberof IntentContentSchema
     */
    '$kind': SchemaKind;
    /**
     * 
     * @type {string}
     * @memberof IntentContentSchema
     */
    'name': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof IntentContentSchema
     */
    'utterances': Array<string>;
}


/**
 * 
 * @export
 * @interface IntentEditionModel
 */
export interface IntentEditionModel {
    /**
     * 
     * @type {string}
     * @memberof IntentEditionModel
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof IntentEditionModel
     */
    'groupEntryId': string;
    /**
     * 
     * @type {string}
     * @memberof IntentEditionModel
     */
    'groupEditionId': string;
    /**
     * 
     * @type {string}
     * @memberof IntentEditionModel
     */
    'intentEntryId': string;
    /**
     * 
     * @type {string}
     * @memberof IntentEditionModel
     */
    'groupCode': string;
    /**
     * 
     * @type {string}
     * @memberof IntentEditionModel
     */
    'code': string;
    /**
     * 
     * @type {string}
     * @memberof IntentEditionModel
     */
    'semVer': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof IntentEditionModel
     */
    'utterances': Array<string>;
}
/**
 * 
 * @export
 * @interface IntentEditionModelPaginationResponse
 */
export interface IntentEditionModelPaginationResponse {
    /**
     * 
     * @type {Array<IntentEditionModel>}
     * @memberof IntentEditionModelPaginationResponse
     */
    'items'?: Array<IntentEditionModel> | null;
    /**
     * 
     * @type {boolean}
     * @memberof IntentEditionModelPaginationResponse
     */
    'hasMore'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof IntentEditionModelPaginationResponse
     */
    'pageIndex'?: number;
    /**
     * 
     * @type {number}
     * @memberof IntentEditionModelPaginationResponse
     */
    'pageSize'?: number;
    /**
     * 
     * @type {number}
     * @memberof IntentEditionModelPaginationResponse
     */
    'totalItemCount'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof IntentEditionModelPaginationResponse
     */
    'totalPageCount'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof IntentEditionModelPaginationResponse
     */
    'fromItemInclusive'?: number;
    /**
     * 
     * @type {number}
     * @memberof IntentEditionModelPaginationResponse
     */
    'toItemExclusive'?: number;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const IntentEditionStatus = {
    NUMBER_0: 0,
    NUMBER_1: 1
} as const;

export type IntentEditionStatus = typeof IntentEditionStatus[keyof typeof IntentEditionStatus];


/**
 * 
 * @export
 * @interface IntentEntryModel
 */
export interface IntentEntryModel {
    /**
     * 
     * @type {string}
     * @memberof IntentEntryModel
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof IntentEntryModel
     */
    'groupEntryId': string;
    /**
     * 
     * @type {string}
     * @memberof IntentEntryModel
     */
    'groupCode': string;
    /**
     * 
     * @type {string}
     * @memberof IntentEntryModel
     */
    'code': string;
    /**
     * 
     * @type {string}
     * @memberof IntentEntryModel
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof IntentEntryModel
     */
    'description'?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof IntentEntryModel
     */
    'tags'?: Array<string> | null;
    /**
     * 
     * @type {string}
     * @memberof IntentEntryModel
     */
    'currentSemVer': string;
}
/**
 * 
 * @export
 * @interface IntentEntryModelPaginationResponse
 */
export interface IntentEntryModelPaginationResponse {
    /**
     * 
     * @type {Array<IntentEntryModel>}
     * @memberof IntentEntryModelPaginationResponse
     */
    'items'?: Array<IntentEntryModel> | null;
    /**
     * 
     * @type {boolean}
     * @memberof IntentEntryModelPaginationResponse
     */
    'hasMore'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof IntentEntryModelPaginationResponse
     */
    'pageIndex'?: number;
    /**
     * 
     * @type {number}
     * @memberof IntentEntryModelPaginationResponse
     */
    'pageSize'?: number;
    /**
     * 
     * @type {number}
     * @memberof IntentEntryModelPaginationResponse
     */
    'totalItemCount'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof IntentEntryModelPaginationResponse
     */
    'totalPageCount'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof IntentEntryModelPaginationResponse
     */
    'fromItemInclusive'?: number;
    /**
     * 
     * @type {number}
     * @memberof IntentEntryModelPaginationResponse
     */
    'toItemExclusive'?: number;
}
/**
 * 
 * @export
 * @interface IntentReferenceSchema
 */
export interface IntentReferenceSchema extends EntitySchema {
    [key: string]: any;

    /**
     * 
     * @type {string}
     * @memberof IntentReferenceSchema
     */
    '$kind': string;
}
/**
 * 
 * @export
 * @interface IntentTriggerSchema
 */
export interface IntentTriggerSchema extends TriggerSchema {
    [key: string]: any;

    /**
     * 
     * @type {SchemaKind}
     * @memberof IntentTriggerSchema
     */
    '$kind': SchemaKind;
    /**
     * 
     * @type {IntentReferenceSchema}
     * @memberof IntentTriggerSchema
     */
    'intentReference': IntentReferenceSchema;
    /**
     * 
     * @type {number}
     * @memberof IntentTriggerSchema
     */
    'intentThreshold'?: number | null;
}


/**
 * Аналитика по распознаваниям в день.
 * @export
 * @interface IntentsPerDayModel
 */
export interface IntentsPerDayModel {
    /**
     * Дата.
     * @type {string}
     * @memberof IntentsPerDayModel
     */
    'date': string;
    /**
     * Количество неизвестных интентов.
     * @type {number}
     * @memberof IntentsPerDayModel
     */
    'unknownIntentCount': number;
    /**
     * Количество распознанных интентов.
     * @type {number}
     * @memberof IntentsPerDayModel
     */
    'omegaIntentCount': number;
    /**
     * Количество распознанных вопросов БЗ.
     * @type {number}
     * @memberof IntentsPerDayModel
     */
    'sigmaAnswerCount': number;
    /**
     * Топ интентов.
     * @type {Array<OmegaIntentCountModel>}
     * @memberof IntentsPerDayModel
     */
    'topOmegaIntents': Array<OmegaIntentCountModel>;
    /**
     * Топ вопросов БЗ.
     * @type {Array<SigmaAnswerCountModel>}
     * @memberof IntentsPerDayModel
     */
    'topSigmaAnswers': Array<SigmaAnswerCountModel>;
}
/**
 * Модель приглашения сотрудника в канал стадии бота.
 * @export
 * @interface InvitationCommandModel
 */
export interface InvitationCommandModel {
    /**
     * ИД канала.
     * @type {string}
     * @memberof InvitationCommandModel
     */
    'channelId': string;
    /**
     * ИД стадии бота.
     * @type {string}
     * @memberof InvitationCommandModel
     */
    'agentStageAccountId': string;
    /**
     * ИД сотрудника.
     * @type {string}
     * @memberof InvitationCommandModel
     */
    'personId': string;
    /**
     * Адрес Email.
     * @type {string}
     * @memberof InvitationCommandModel
     */
    'emailAddress': string;
    /**
     * ИД корреляции.
     * @type {string}
     * @memberof InvitationCommandModel
     */
    'correlationId': string;
}
/**
 * Модель вызова проактивного действия.
 * @export
 * @interface InvocationCommandModel
 */
export interface InvocationCommandModel {
    /**
     * ИД канала.
     * @type {string}
     * @memberof InvocationCommandModel
     */
    'channelId': string;
    /**
     * ИД стадии бота.
     * @type {string}
     * @memberof InvocationCommandModel
     */
    'agentStageAccountId': string;
    /**
     * ИД сотрудника.
     * @type {string}
     * @memberof InvocationCommandModel
     */
    'personId': string;
    /**
     * ИД корреляции.
     * @type {string}
     * @memberof InvocationCommandModel
     */
    'correlationId': string;
    /**
     * ИД диалога.
     * @type {string}
     * @memberof InvocationCommandModel
     */
    'dialogId': string;
    /**
     * Аргументы диалога.
     * @type {{ [key: string]: any; }}
     * @memberof InvocationCommandModel
     */
    'dialogArguments'?: { [key: string]: any; } | null;
}
/**
 * 
 * @export
 * @interface KbSettingsSchema
 */
export interface KbSettingsSchema {
    /**
     * 
     * @type {boolean}
     * @memberof KbSettingsSchema
     */
    'enabled'?: boolean | null;
    /**
     * 
     * @type {number}
     * @memberof KbSettingsSchema
     */
    'threshold'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof KbSettingsSchema
     */
    'searchParentsOnlyIfNoContext'?: boolean | null;
}
/**
 * 
 * @export
 * @interface LengthComparisonValidatorSchema
 */
export interface LengthComparisonValidatorSchema extends ComparisonValidatorSchema {
    [key: string]: any;

    /**
     * 
     * @type {SchemaKind}
     * @memberof LengthComparisonValidatorSchema
     */
    '$kind': SchemaKind;
    /**
     * 
     * @type {number}
     * @memberof LengthComparisonValidatorSchema
     */
    'length': number;
}


/**
 * 
 * @export
 * @interface LinkInputSchema
 */
export interface LinkInputSchema extends InputActionSchema {
    [key: string]: any;

    /**
     * 
     * @type {SchemaKind}
     * @memberof LinkInputSchema
     */
    '$kind': SchemaKind;
}


/**
 * 
 * @export
 * @interface ListBotModel
 */
export interface ListBotModel {
    /**
     * 
     * @type {BotEntryModel}
     * @memberof ListBotModel
     */
    'entry': BotEntryModel;
    /**
     * 
     * @type {BotStageModel}
     * @memberof ListBotModel
     */
    'originStage': BotStageModel;
    /**
     * 
     * @type {BotStageModel}
     * @memberof ListBotModel
     */
    'draftStage'?: BotStageModel;
}
/**
 * 
 * @export
 * @interface ListBotModelPaginationResponse
 */
export interface ListBotModelPaginationResponse {
    /**
     * 
     * @type {Array<ListBotModel>}
     * @memberof ListBotModelPaginationResponse
     */
    'items'?: Array<ListBotModel> | null;
    /**
     * 
     * @type {boolean}
     * @memberof ListBotModelPaginationResponse
     */
    'hasMore'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof ListBotModelPaginationResponse
     */
    'pageIndex'?: number;
    /**
     * 
     * @type {number}
     * @memberof ListBotModelPaginationResponse
     */
    'pageSize'?: number;
    /**
     * 
     * @type {number}
     * @memberof ListBotModelPaginationResponse
     */
    'totalItemCount'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ListBotModelPaginationResponse
     */
    'totalPageCount'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ListBotModelPaginationResponse
     */
    'fromItemInclusive'?: number;
    /**
     * 
     * @type {number}
     * @memberof ListBotModelPaginationResponse
     */
    'toItemExclusive'?: number;
}
/**
 * Шаблон простого бота.
 * @export
 * @interface ListBotTemplateModel
 */
export interface ListBotTemplateModel {
    /**
     * Идентификатор шаблона бота.
     * @type {string}
     * @memberof ListBotTemplateModel
     */
    'id': string;
    /**
     * Код шаблона бота.
     * @type {string}
     * @memberof ListBotTemplateModel
     */
    'code': string;
    /**
     * Отображаемое название шаблона бота.
     * @type {string}
     * @memberof ListBotTemplateModel
     */
    'name': string;
    /**
     * Описание.
     * @type {string}
     * @memberof ListBotTemplateModel
     */
    'description'?: string | null;
    /**
     * Название иконпака.
     * @type {string}
     * @memberof ListBotTemplateModel
     */
    'iconPack'?: string | null;
    /**
     * Код иконки.
     * @type {string}
     * @memberof ListBotTemplateModel
     */
    'iconCode'?: string | null;
}
/**
 * 
 * @export
 * @interface ListIntentModel
 */
export interface ListIntentModel {
    /**
     * 
     * @type {IntentEntryModel}
     * @memberof ListIntentModel
     */
    'intentEntry': IntentEntryModel;
    /**
     * 
     * @type {IntentEditionModel}
     * @memberof ListIntentModel
     */
    'intentEdition': IntentEditionModel;
}
/**
 * 
 * @export
 * @interface ListIntentModelPaginationResponse
 */
export interface ListIntentModelPaginationResponse {
    /**
     * 
     * @type {Array<ListIntentModel>}
     * @memberof ListIntentModelPaginationResponse
     */
    'items'?: Array<ListIntentModel> | null;
    /**
     * 
     * @type {boolean}
     * @memberof ListIntentModelPaginationResponse
     */
    'hasMore'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof ListIntentModelPaginationResponse
     */
    'pageIndex'?: number;
    /**
     * 
     * @type {number}
     * @memberof ListIntentModelPaginationResponse
     */
    'pageSize'?: number;
    /**
     * 
     * @type {number}
     * @memberof ListIntentModelPaginationResponse
     */
    'totalItemCount'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ListIntentModelPaginationResponse
     */
    'totalPageCount'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ListIntentModelPaginationResponse
     */
    'fromItemInclusive'?: number;
    /**
     * 
     * @type {number}
     * @memberof ListIntentModelPaginationResponse
     */
    'toItemExclusive'?: number;
}
/**
 * Описание шаблона для создания сценария простого бота.
 * @export
 * @interface ListScenarioTemplateModel
 */
export interface ListScenarioTemplateModel {
    /**
     * Идентификатор шаблона сценария.
     * @type {string}
     * @memberof ListScenarioTemplateModel
     */
    'id': string;
    /**
     * Код шаблона сценария.
     * @type {string}
     * @memberof ListScenarioTemplateModel
     */
    'code': string;
    /**
     * Отображаемое название шаблона сценария.
     * @type {string}
     * @memberof ListScenarioTemplateModel
     */
    'name': string;
    /**
     * Описание.
     * @type {string}
     * @memberof ListScenarioTemplateModel
     */
    'description': string;
    /**
     * Идентификатор шаблона бота.
     * @type {string}
     * @memberof ListScenarioTemplateModel
     */
    'botTemplateId'?: string | null;
    /**
     * Название шаблона бота.
     * @type {string}
     * @memberof ListScenarioTemplateModel
     */
    'botTemplateName'?: string | null;
    /**
     * Название иконпака.
     * @type {string}
     * @memberof ListScenarioTemplateModel
     */
    'iconPack': string;
    /**
     * Код иконки.
     * @type {string}
     * @memberof ListScenarioTemplateModel
     */
    'iconCode': string;
    /**
     * Признак того, что шаблон сценария выбран по умолчанию.
     * @type {boolean}
     * @memberof ListScenarioTemplateModel
     */
    'isSelectedByDefault'?: boolean;
    /**
     * 
     * @type {ScenarioInterruptionMode}
     * @memberof ListScenarioTemplateModel
     */
    'interruption': ScenarioInterruptionMode;
    /**
     * 
     * @type {ScenarioCancellationMode}
     * @memberof ListScenarioTemplateModel
     */
    'cancellation': ScenarioCancellationMode;
}


/**
 * 
 * @export
 * @interface ListScenarioTemplateModelPaginationResponse
 */
export interface ListScenarioTemplateModelPaginationResponse {
    /**
     * 
     * @type {Array<ListScenarioTemplateModel>}
     * @memberof ListScenarioTemplateModelPaginationResponse
     */
    'items'?: Array<ListScenarioTemplateModel> | null;
    /**
     * 
     * @type {boolean}
     * @memberof ListScenarioTemplateModelPaginationResponse
     */
    'hasMore'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof ListScenarioTemplateModelPaginationResponse
     */
    'pageIndex'?: number;
    /**
     * 
     * @type {number}
     * @memberof ListScenarioTemplateModelPaginationResponse
     */
    'pageSize'?: number;
    /**
     * 
     * @type {number}
     * @memberof ListScenarioTemplateModelPaginationResponse
     */
    'totalItemCount'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ListScenarioTemplateModelPaginationResponse
     */
    'totalPageCount'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ListScenarioTemplateModelPaginationResponse
     */
    'fromItemInclusive'?: number;
    /**
     * 
     * @type {number}
     * @memberof ListScenarioTemplateModelPaginationResponse
     */
    'toItemExclusive'?: number;
}
/**
 * Запрос на создание состояния беседы в канале Livechat.
 * @export
 * @interface LiveChatConversationStateCreationRequest
 */
export interface LiveChatConversationStateCreationRequest {
    /**
     * Название страницы начала беседы.
     * @type {string}
     * @memberof LiveChatConversationStateCreationRequest
     */
    'startPageTitle': string;
    /**
     * URL страницы начала беседы.
     * @type {string}
     * @memberof LiveChatConversationStateCreationRequest
     */
    'startPageUrl': string;
}
/**
 * Модель состояния беседы в канале Livechat.
 * @export
 * @interface LiveChatConversationStateModel
 */
export interface LiveChatConversationStateModel {
    /**
     * ИД пользователя.
     * @type {string}
     * @memberof LiveChatConversationStateModel
     */
    'userId'?: string | null;
    /**
     * ИД беседы.
     * @type {string}
     * @memberof LiveChatConversationStateModel
     */
    'conversationId'?: string | null;
    /**
     * Название страницы начала беседы.
     * @type {string}
     * @memberof LiveChatConversationStateModel
     */
    'startPageTitle': string;
    /**
     * URL страницы начала беседы.
     * @type {string}
     * @memberof LiveChatConversationStateModel
     */
    'startPageUrl': string;
}
/**
 * Модель правила валидации внешнего запроса в канале LiveChat.
 * @export
 * @interface LiveChatSecurityValidationRuleModel
 */
export interface LiveChatSecurityValidationRuleModel {
    /**
     * Признак включения/отключения правила.
     * @type {boolean}
     * @memberof LiveChatSecurityValidationRuleModel
     */
    'enabled': boolean;
    /**
     * 
     * @type {LiveChatSecurityValidationRuleType}
     * @memberof LiveChatSecurityValidationRuleModel
     */
    'type': LiveChatSecurityValidationRuleType;
    /**
     * Данные для проверки внешнего запроса (домены, секрет, публичный ключ, ссылка на jwks).
     * @type {string}
     * @memberof LiveChatSecurityValidationRuleModel
     */
    'value'?: string | null;
}


/**
 * 
 * @export
 * @enum {string}
 */

export const LiveChatSecurityValidationRuleType = {
    Cors: 'Cors',
    ValidateTokenBySecret: 'ValidateTokenBySecret',
    ValidateTokenByPublicKey: 'ValidateTokenByPublicKey',
    ValidateTokenByJwks: 'ValidateTokenByJwks'
} as const;

export type LiveChatSecurityValidationRuleType = typeof LiveChatSecurityValidationRuleType[keyof typeof LiveChatSecurityValidationRuleType];


/**
 * Модель настроек чата в канале LiveChat.
 * @export
 * @interface LiveChatSettingsChatModel
 */
export interface LiveChatSettingsChatModel {
    /**
     * Заголовок чата.
     * @type {string}
     * @memberof LiveChatSettingsChatModel
     */
    'title': string;
    /**
     * Основной цвет элементов чата.
     * @type {string}
     * @memberof LiveChatSettingsChatModel
     */
    'primaryColor': string;
    /**
     * Цвет фона чата.
     * @type {string}
     * @memberof LiveChatSettingsChatModel
     */
    'userBubbleBackground': string;
    /**
     * Цвет фона чата.
     * @type {string}
     * @memberof LiveChatSettingsChatModel
     */
    'background': string;
}
/**
 * Модель настроек в канале LiveChat.
 * @export
 * @interface LiveChatSettingsModel
 */
export interface LiveChatSettingsModel {
    /**
     * Адрес апи для чата.
     * @type {string}
     * @memberof LiveChatSettingsModel
     */
    'chatApiUrl': string;
    /**
     * Uri аккаунта бота.
     * @type {string}
     * @memberof LiveChatSettingsModel
     */
    'botUri': string;
    /**
     * 
     * @type {LiveChatSettingsToggleButtonModel}
     * @memberof LiveChatSettingsModel
     */
    'toggleButton': LiveChatSettingsToggleButtonModel;
    /**
     * 
     * @type {LiveChatSettingsChatModel}
     * @memberof LiveChatSettingsModel
     */
    'chat': LiveChatSettingsChatModel;
    /**
     * 
     * @type {LiveChatSettingsSecurityModel}
     * @memberof LiveChatSettingsModel
     */
    'security'?: LiveChatSettingsSecurityModel;
    /**
     * 
     * @type {HttpTransportType}
     * @memberof LiveChatSettingsModel
     */
    'transport'?: HttpTransportType;
}


/**
 * Модель настроек расположения элемента в канале LiveChat.
 * @export
 * @interface LiveChatSettingsOffsetModel
 */
export interface LiveChatSettingsOffsetModel {
    /**
     * Отступ снизу.
     * @type {string}
     * @memberof LiveChatSettingsOffsetModel
     */
    'bottom': string;
    /**
     * Отступ справа.
     * @type {string}
     * @memberof LiveChatSettingsOffsetModel
     */
    'right': string;
}
/**
 * Модель настроек безопасности в канале LiveChat.
 * @export
 * @interface LiveChatSettingsSecurityModel
 */
export interface LiveChatSettingsSecurityModel {
    /**
     * Правила валидации.
     * @type {Array<LiveChatSecurityValidationRuleModel>}
     * @memberof LiveChatSettingsSecurityModel
     */
    'validationRules': Array<LiveChatSecurityValidationRuleModel>;
}
/**
 * Модель настроек кнопки чата в канале LiveChat.
 * @export
 * @interface LiveChatSettingsToggleButtonModel
 */
export interface LiveChatSettingsToggleButtonModel {
    /**
     * Цвет кнопки чата.
     * @type {string}
     * @memberof LiveChatSettingsToggleButtonModel
     */
    'background': string;
    /**
     * Размер кнопки чата.
     * @type {string}
     * @memberof LiveChatSettingsToggleButtonModel
     */
    'size': string;
    /**
     * 
     * @type {LiveChatSettingsOffsetModel}
     * @memberof LiveChatSettingsToggleButtonModel
     */
    'offset': LiveChatSettingsOffsetModel;
}
/**
 * Модель токена для беседы в канале LiveChat.
 * @export
 * @interface LiveChatTokenModel
 */
export interface LiveChatTokenModel {
    /**
     * Токен.
     * @type {string}
     * @memberof LiveChatTokenModel
     */
    'token': string;
    /**
     * Внешний ИД беседы.
     * @type {string}
     * @memberof LiveChatTokenModel
     */
    'conversationId': string;
    /**
     * ИД пользователя.
     * @type {string}
     * @memberof LiveChatTokenModel
     */
    'userId': string;
}
/**
 * 
 * @export
 * @interface LiveChatTokenRequest
 */
export interface LiveChatTokenRequest {
    /**
     * 
     * @type {string}
     * @memberof LiveChatTokenRequest
     */
    'userId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof LiveChatTokenRequest
     */
    'conversationId'?: string | null;
    /**
     * 
     * @type {LiveChatTokenRequestUser}
     * @memberof LiveChatTokenRequest
     */
    'externalUser'?: LiveChatTokenRequestUser;
}
/**
 * 
 * @export
 * @interface LiveChatTokenRequestUser
 */
export interface LiveChatTokenRequestUser {
    /**
     * 
     * @type {string}
     * @memberof LiveChatTokenRequestUser
     */
    'id'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof LiveChatTokenRequestUser
     */
    'name'?: string | null;
}
/**
 * 
 * @export
 * @interface LocalizationSettingsSchema
 */
export interface LocalizationSettingsSchema {
    /**
     * Локаль бота, например \"ru-ru\" или \"en-us\".
     * @type {string}
     * @memberof LocalizationSettingsSchema
     */
    'locale'?: string | null;
}
/**
 * 
 * @export
 * @interface LocationInputSchema
 */
export interface LocationInputSchema extends InputActionSchema {
    [key: string]: any;

    /**
     * 
     * @type {SchemaKind}
     * @memberof LocationInputSchema
     */
    '$kind': SchemaKind;
}


/**
 * 
 * @export
 * @interface LoginProtocolSchema
 */
export interface LoginProtocolSchema extends EntitySchema {
    [key: string]: any;

    /**
     * 
     * @type {string}
     * @memberof LoginProtocolSchema
     */
    '$kind': string;
}
/**
 * 
 * @export
 * @interface MainCaseActionSchema
 */
export interface MainCaseActionSchema extends CaseActionSchema {
    [key: string]: any;

    /**
     * 
     * @type {SchemaKind}
     * @memberof MainCaseActionSchema
     */
    '$kind': SchemaKind;
    /**
     * 
     * @type {Array<ConditionSchema>}
     * @memberof MainCaseActionSchema
     */
    'conditions': Array<ConditionSchema>;
}


/**
 * 
 * @export
 * @interface MenuActionSchema
 */
export interface MenuActionSchema extends OutputActionSchema {
    [key: string]: any;

    /**
     * 
     * @type {SchemaKind}
     * @memberof MenuActionSchema
     */
    '$kind': SchemaKind;
    /**
     * 
     * @type {MenuOptionsFillingMode}
     * @memberof MenuActionSchema
     */
    'optionsFillingMode': MenuOptionsFillingMode;
    /**
     * 
     * @type {string}
     * @memberof MenuActionSchema
     */
    'category'?: string | null;
    /**
     * 
     * @type {Array<MenuButtonSchema>}
     * @memberof MenuActionSchema
     */
    'buttons': Array<MenuButtonSchema>;
}


/**
 * 
 * @export
 * @interface MenuButtonSchema
 */
export interface MenuButtonSchema extends EntitySchema {
    [key: string]: any;

    /**
     * 
     * @type {SchemaKind}
     * @memberof MenuButtonSchema
     */
    '$kind': SchemaKind;
    /**
     * 
     * @type {string}
     * @memberof MenuButtonSchema
     */
    'value': string;
    /**
     * 
     * @type {string}
     * @memberof MenuButtonSchema
     */
    'outputBindingId'?: string | null;
}


/**
 * 
 * @export
 * @interface MenuButtonTriggerSchema
 */
export interface MenuButtonTriggerSchema extends TriggerSchema {
    [key: string]: any;

    /**
     * 
     * @type {SchemaKind}
     * @memberof MenuButtonTriggerSchema
     */
    '$kind': SchemaKind;
    /**
     * 
     * @type {string}
     * @memberof MenuButtonTriggerSchema
     */
    'value': string;
    /**
     * 
     * @type {string}
     * @memberof MenuButtonTriggerSchema
     */
    'category'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof MenuButtonTriggerSchema
     */
    'position': number;
}


/**
 * 
 * @export
 * @interface MenuItemSelectionCountModel
 */
export interface MenuItemSelectionCountModel {
    /**
     * Название кнопки меню.
     * @type {string}
     * @memberof MenuItemSelectionCountModel
     */
    'name': string;
    /**
     * Количество.
     * @type {number}
     * @memberof MenuItemSelectionCountModel
     */
    'count': number;
    /**
     * Режим меню.
     * @type {string}
     * @memberof MenuItemSelectionCountModel
     */
    'mode': string;
}
/**
 * Аналитика по нажатиям кнопок меню в день.
 * @export
 * @interface MenuItemSelectionsPerDayModel
 */
export interface MenuItemSelectionsPerDayModel {
    /**
     * Дата.
     * @type {string}
     * @memberof MenuItemSelectionsPerDayModel
     */
    'date': string;
    /**
     * Общее количество нажатий кнопок меню.
     * @type {number}
     * @memberof MenuItemSelectionsPerDayModel
     */
    'totalCount': number;
    /**
     * Топ кнопок меню.
     * @type {Array<MenuItemSelectionCountModel>}
     * @memberof MenuItemSelectionsPerDayModel
     */
    'topMenuItems': Array<MenuItemSelectionCountModel>;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const MenuOptionsFillingMode = {
    Auto: 'auto',
    AutoByCategory: 'autoByCategory',
    Manual: 'manual'
} as const;

export type MenuOptionsFillingMode = typeof MenuOptionsFillingMode[keyof typeof MenuOptionsFillingMode];


/**
 * 
 * @export
 * @enum {string}
 */

export const MergeBotStageStatus = {
    Merged: 'Merged',
    NothingToMerge: 'NothingToMerge',
    HasChanges: 'HasChanges'
} as const;

export type MergeBotStageStatus = typeof MergeBotStageStatus[keyof typeof MergeBotStageStatus];


/**
 * 
 * @export
 * @interface MergeInboxContactsSchema
 */
export interface MergeInboxContactsSchema extends ActionSchema {
    [key: string]: any;

    /**
     * 
     * @type {SchemaKind}
     * @memberof MergeInboxContactsSchema
     */
    '$kind': SchemaKind;
    /**
     * 
     * @type {ContactIdentifierKind}
     * @memberof MergeInboxContactsSchema
     */
    'contactIdentifierKind': ContactIdentifierKind;
    /**
     * 
     * @type {string}
     * @memberof MergeInboxContactsSchema
     */
    'identifierVariableId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MergeInboxContactsSchema
     */
    'resultVariableId'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof MergeInboxContactsSchema
     */
    'mergeFields': boolean;
}


/**
 * 
 * @export
 * @interface MessageSchema
 */
export interface MessageSchema extends EntitySchema {
    [key: string]: any;

    /**
     * 
     * @type {SchemaKind}
     * @memberof MessageSchema
     */
    '$kind': SchemaKind;
    /**
     * 
     * @type {string}
     * @memberof MessageSchema
     */
    'content': string;
    /**
     * 
     * @type {Array<AttachmentSchema>}
     * @memberof MessageSchema
     */
    'attachments': Array<AttachmentSchema>;
}


/**
 * 
 * @export
 * @interface NlpSettingsSchema
 */
export interface NlpSettingsSchema {
    /**
     * Использовать указанную в настройках бота модель обучения.
     * @type {boolean}
     * @memberof NlpSettingsSchema
     */
    'useCustomTrainingModel': boolean;
    /**
     * Модель обучения бота.
     * @type {string}
     * @memberof NlpSettingsSchema
     */
    'customTrainingModel'?: string | null;
}
/**
 * 
 * @export
 * @interface NumberInputSchema
 */
export interface NumberInputSchema extends InputActionSchema {
    [key: string]: any;

    /**
     * 
     * @type {SchemaKind}
     * @memberof NumberInputSchema
     */
    '$kind': SchemaKind;
}


/**
 * 
 * @export
 * @interface NumberValueComparisonValidatorSchema
 */
export interface NumberValueComparisonValidatorSchema extends ValueComparisonValidatorSchema {
    [key: string]: any;

    /**
     * 
     * @type {SchemaKind}
     * @memberof NumberValueComparisonValidatorSchema
     */
    '$kind': SchemaKind;
    /**
     * 
     * @type {number}
     * @memberof NumberValueComparisonValidatorSchema
     */
    'value': number;
}


/**
 * 
 * @export
 * @interface OAuth2LoginProtocolSchema
 */
export interface OAuth2LoginProtocolSchema extends LoginProtocolSchema {
    [key: string]: any;

    /**
     * 
     * @type {SchemaKind}
     * @memberof OAuth2LoginProtocolSchema
     */
    '$kind': SchemaKind;
    /**
     * 
     * @type {string}
     * @memberof OAuth2LoginProtocolSchema
     */
    'authorizeEndpoint': string;
    /**
     * 
     * @type {string}
     * @memberof OAuth2LoginProtocolSchema
     */
    'tokenEndpoint': string;
    /**
     * 
     * @type {string}
     * @memberof OAuth2LoginProtocolSchema
     */
    'jwksEndpoint': string;
    /**
     * 
     * @type {string}
     * @memberof OAuth2LoginProtocolSchema
     */
    'clientId': string;
    /**
     * 
     * @type {string}
     * @memberof OAuth2LoginProtocolSchema
     */
    'clientSecret': string;
    /**
     * 
     * @type {string}
     * @memberof OAuth2LoginProtocolSchema
     */
    'scopes': string;
}


/**
 * 
 * @export
 * @interface OmegaGenerated
 */
export interface OmegaGenerated {
    /**
     * 
     * @type {string}
     * @memberof OmegaGenerated
     */
    '$kind'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OmegaGenerated
     */
    'applicationId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OmegaGenerated
     */
    'endpoint'?: string | null;
}
/**
 * 
 * @export
 * @interface OmegaIntentCountModel
 */
export interface OmegaIntentCountModel {
    /**
     * Название интента.
     * @type {string}
     * @memberof OmegaIntentCountModel
     */
    'intentName': string;
    /**
     * Количество.
     * @type {number}
     * @memberof OmegaIntentCountModel
     */
    'count': number;
    /**
     * ИД интента.
     * @type {string}
     * @memberof OmegaIntentCountModel
     */
    'intentId': string;
}
/**
 * 
 * @export
 * @interface OmegaScoreQuery
 */
export interface OmegaScoreQuery {
    /**
     * 
     * @type {string}
     * @memberof OmegaScoreQuery
     */
    'id'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OmegaScoreQuery
     */
    'query'?: string | null;
    /**
     * 
     * @type {any}
     * @memberof OmegaScoreQuery
     */
    'intents'?: any | null;
    /**
     * 
     * @type {string}
     * @memberof OmegaScoreQuery
     */
    'error'?: string | null;
}
/**
 * 
 * @export
 * @interface OperandSchema
 */
export interface OperandSchema extends EntitySchema {
    [key: string]: any;

    /**
     * 
     * @type {string}
     * @memberof OperandSchema
     */
    '$kind': string;
}
/**
 * 
 * @export
 * @interface OutputActionSchema
 */
export interface OutputActionSchema extends ActionSchema {
    [key: string]: any;

    /**
     * 
     * @type {string}
     * @memberof OutputActionSchema
     */
    '$kind': string;
    /**
     * 
     * @type {Array<MessageSchema>}
     * @memberof OutputActionSchema
     */
    'messages': Array<MessageSchema>;
}
/**
 * 
 * @export
 * @interface Person
 */
export interface Person {
    /**
     * 
     * @type {string}
     * @memberof Person
     */
    'fullName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Person
     */
    'firstName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Person
     */
    'lastName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Person
     */
    'middleName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Person
     */
    'eMail'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Person
     */
    'primaryTel'?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof Person
     */
    'secondaryTels'?: Array<string> | null;
    /**
     * 
     * @type {string}
     * @memberof Person
     */
    'avatarFileId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Person
     */
    'lastActivity'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Person
     */
    'country'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Person
     */
    'city'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Person
     */
    'additionalInfo'?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof Person
     */
    'tagIds'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof Person
     */
    'groupIds'?: Array<string> | null;
    /**
     * 
     * @type {string}
     * @memberof Person
     */
    'tenantId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Person
     */
    'id'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Person
     */
    'version'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Person
     */
    'createdBy'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Person
     */
    'modifiedBy'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Person
     */
    'createdOn'?: string;
    /**
     * 
     * @type {string}
     * @memberof Person
     */
    'modifiedOn'?: string;
}
/**
 * Информация из аккаунта канала.
 * @export
 * @interface PersonAccountExternalInfoModel
 */
export interface PersonAccountExternalInfoModel {
    /**
     * ИД аккаунта в канале.
     * @type {string}
     * @memberof PersonAccountExternalInfoModel
     */
    'id': string;
    /**
     * Полное имя.
     * @type {string}
     * @memberof PersonAccountExternalInfoModel
     */
    'fullName'?: string | null;
    /**
     * Внешний ИД пользователя.
     * @type {string}
     * @memberof PersonAccountExternalInfoModel
     */
    'userId'?: string | null;
    /**
     * Имя.
     * @type {string}
     * @memberof PersonAccountExternalInfoModel
     */
    'firstName'?: string | null;
    /**
     * Фамилия.
     * @type {string}
     * @memberof PersonAccountExternalInfoModel
     */
    'lastName'?: string | null;
    /**
     * Никнейм.
     * @type {string}
     * @memberof PersonAccountExternalInfoModel
     */
    'nickName'?: string | null;
    /**
     * Ссылка на аватар.
     * @type {string}
     * @memberof PersonAccountExternalInfoModel
     */
    'avatar'?: string | null;
    /**
     * Язык.
     * @type {string}
     * @memberof PersonAccountExternalInfoModel
     */
    'language'?: string | null;
    /**
     * Страна.
     * @type {string}
     * @memberof PersonAccountExternalInfoModel
     */
    'country'?: string | null;
}
/**
 * Модель аккаунта сотрудника в канале.
 * @export
 * @interface PersonAccountModel
 */
export interface PersonAccountModel {
    /**
     * ИД аккаунта.
     * @type {string}
     * @memberof PersonAccountModel
     */
    'id': string;
    /**
     * ИД канала.
     * @type {string}
     * @memberof PersonAccountModel
     */
    'channelId': string;
    /**
     * ИД сотрудника.
     * @type {string}
     * @memberof PersonAccountModel
     */
    'personId': string;
    /**
     * 
     * @type {PersonAccountExternalInfoModel}
     * @memberof PersonAccountModel
     */
    'externalInfo': PersonAccountExternalInfoModel;
    /**
     * 
     * @type {PersonAccountState}
     * @memberof PersonAccountModel
     */
    'state'?: PersonAccountState;
}


/**
 * 
 * @export
 * @interface PersonAccountModelPaginationResponse
 */
export interface PersonAccountModelPaginationResponse {
    /**
     * 
     * @type {Array<PersonAccountModel>}
     * @memberof PersonAccountModelPaginationResponse
     */
    'items'?: Array<PersonAccountModel> | null;
    /**
     * 
     * @type {boolean}
     * @memberof PersonAccountModelPaginationResponse
     */
    'hasMore'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof PersonAccountModelPaginationResponse
     */
    'pageIndex'?: number;
    /**
     * 
     * @type {number}
     * @memberof PersonAccountModelPaginationResponse
     */
    'pageSize'?: number;
    /**
     * 
     * @type {number}
     * @memberof PersonAccountModelPaginationResponse
     */
    'totalItemCount'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof PersonAccountModelPaginationResponse
     */
    'totalPageCount'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof PersonAccountModelPaginationResponse
     */
    'fromItemInclusive'?: number;
    /**
     * 
     * @type {number}
     * @memberof PersonAccountModelPaginationResponse
     */
    'toItemExclusive'?: number;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const PersonAccountState = {
    NUMBER_0: 0,
    NUMBER_1: 1,
    NUMBER_2: 2
} as const;

export type PersonAccountState = typeof PersonAccountState[keyof typeof PersonAccountState];


/**
 * Контактные данные контакта.
 * @export
 * @interface PersonContacts
 */
export interface PersonContacts {
    /**
     * Основной номер телефона.
     * @type {string}
     * @memberof PersonContacts
     */
    'primaryTel'?: string | null;
    /**
     * Второстепенные номера телефонов.
     * @type {Array<string>}
     * @memberof PersonContacts
     */
    'secondaryTels': Array<string>;
    /**
     * E-mail.
     * @type {string}
     * @memberof PersonContacts
     */
    'email'?: string | null;
}
/**
 * Запрос на создание контакта.
 * @export
 * @interface PersonCreationRequest
 */
export interface PersonCreationRequest {
    /**
     * ФИО контакта.
     * @type {string}
     * @memberof PersonCreationRequest
     */
    'fullName': string;
    /**
     * Имя контакта.
     * @type {string}
     * @memberof PersonCreationRequest
     */
    'firstName'?: string | null;
    /**
     * Фамилия контакта.
     * @type {string}
     * @memberof PersonCreationRequest
     */
    'lastName'?: string | null;
    /**
     * Отчество контакта.
     * @type {string}
     * @memberof PersonCreationRequest
     */
    'middleName'?: string | null;
    /**
     * Email контакта.
     * @type {string}
     * @memberof PersonCreationRequest
     */
    'eMail'?: string | null;
    /**
     * Основной номер телефона контакта.
     * @type {string}
     * @memberof PersonCreationRequest
     */
    'primaryTel'?: string | null;
    /**
     * Второстепенные номера телефонов контакта.
     * @type {Array<string>}
     * @memberof PersonCreationRequest
     */
    'secondaryTels'?: Array<string> | null;
    /**
     * ИД файла aватара контакта.
     * @type {string}
     * @memberof PersonCreationRequest
     */
    'avatarFileId'?: string | null;
    /**
     * Код страны.
     * @type {string}
     * @memberof PersonCreationRequest
     */
    'country'?: string | null;
    /**
     * Город.
     * @type {string}
     * @memberof PersonCreationRequest
     */
    'city'?: string | null;
    /**
     * Дополнительная информация.
     * @type {string}
     * @memberof PersonCreationRequest
     */
    'additionalInfo'?: string | null;
    /**
     * Список идентификаторов тэгов.
     * @type {Array<string>}
     * @memberof PersonCreationRequest
     */
    'tagIds'?: Array<string> | null;
}
/**
 * Ответ на создание контакта.
 * @export
 * @interface PersonCreationResponse
 */
export interface PersonCreationResponse {
    /**
     * ИД контакта.
     * @type {string}
     * @memberof PersonCreationResponse
     */
    'id'?: string | null;
}
/**
 * Местоположение контакта.
 * @export
 * @interface PersonLocation
 */
export interface PersonLocation {
    /**
     * Код страны.
     * @type {string}
     * @memberof PersonLocation
     */
    'country'?: string | null;
    /**
     * Город.
     * @type {string}
     * @memberof PersonLocation
     */
    'city'?: string | null;
}
/**
 * Запрос на объединение контактов.
 * @export
 * @interface PersonMergingRequest
 */
export interface PersonMergingRequest {
    /**
     * ИД исходных контактов.
     * @type {Array<string>}
     * @memberof PersonMergingRequest
     */
    'sourceIds': Array<string>;
    /**
     * ИД целевого контакта.
     * @type {string}
     * @memberof PersonMergingRequest
     */
    'targetId': string;
    /**
     * ИД аккаунтов контактов.
     * @type {Array<string>}
     * @memberof PersonMergingRequest
     */
    'accountIds': Array<string>;
    /**
     * ФИО контакта.
     * @type {string}
     * @memberof PersonMergingRequest
     */
    'fullName': string;
    /**
     * Имя контакта.
     * @type {string}
     * @memberof PersonMergingRequest
     */
    'firstName'?: string | null;
    /**
     * Фамилия контакта.
     * @type {string}
     * @memberof PersonMergingRequest
     */
    'lastName'?: string | null;
    /**
     * Отчество контакта.
     * @type {string}
     * @memberof PersonMergingRequest
     */
    'middleName'?: string | null;
    /**
     * Email контакта.
     * @type {string}
     * @memberof PersonMergingRequest
     */
    'eMail'?: string | null;
    /**
     * Основной номер телефона контакта.
     * @type {string}
     * @memberof PersonMergingRequest
     */
    'primaryTel'?: string | null;
    /**
     * Второстепенные номера телефонов контакта.
     * @type {Array<string>}
     * @memberof PersonMergingRequest
     */
    'secondaryTels'?: Array<string> | null;
    /**
     * ИД файла aватара контакта.
     * @type {string}
     * @memberof PersonMergingRequest
     */
    'avatarFileId'?: string | null;
    /**
     * Код страны.
     * @type {string}
     * @memberof PersonMergingRequest
     */
    'country'?: string | null;
    /**
     * Город.
     * @type {string}
     * @memberof PersonMergingRequest
     */
    'city'?: string | null;
    /**
     * Дополнительная информация.
     * @type {string}
     * @memberof PersonMergingRequest
     */
    'additionalInfo'?: string | null;
    /**
     * Список идентификаторов тэгов.
     * @type {Array<string>}
     * @memberof PersonMergingRequest
     */
    'tagIds'?: Array<string> | null;
    /**
     * Список идентификаторов групп.
     * @type {Array<string>}
     * @memberof PersonMergingRequest
     */
    'groupIds'?: Array<string> | null;
}
/**
 * Модель контакта.
 * @export
 * @interface PersonModel
 */
export interface PersonModel {
    /**
     * ИД контакта.
     * @type {string}
     * @memberof PersonModel
     */
    'id': string;
    /**
     * ФИО контакта.
     * @type {string}
     * @memberof PersonModel
     */
    'fullName': string;
    /**
     * Имя контакта.
     * @type {string}
     * @memberof PersonModel
     */
    'firstName'?: string | null;
    /**
     * Фамилия контакта.
     * @type {string}
     * @memberof PersonModel
     */
    'lastName'?: string | null;
    /**
     * Отчество контакта.
     * @type {string}
     * @memberof PersonModel
     */
    'middleName'?: string | null;
    /**
     * Email контакта.
     * @type {string}
     * @memberof PersonModel
     */
    'eMail'?: string | null;
    /**
     * Основной номер телефона контакта.
     * @type {string}
     * @memberof PersonModel
     */
    'primaryTel'?: string | null;
    /**
     * Второстепенные номера телефонов контакта.
     * @type {Array<string>}
     * @memberof PersonModel
     */
    'secondaryTels': Array<string>;
    /**
     * ИД файла aватара контакта.
     * @type {string}
     * @memberof PersonModel
     */
    'avatarFileId'?: string | null;
    /**
     * Url файла aватара контакта.
     * @type {string}
     * @memberof PersonModel
     */
    'avatarUrl'?: string | null;
    /**
     * Время последней активности контакта.
     * @type {string}
     * @memberof PersonModel
     */
    'lastActivity'?: string | null;
    /**
     * Код страны.
     * @type {string}
     * @memberof PersonModel
     */
    'country'?: string | null;
    /**
     * Город.
     * @type {string}
     * @memberof PersonModel
     */
    'city'?: string | null;
    /**
     * Дополнительная информация.
     * @type {string}
     * @memberof PersonModel
     */
    'additionalInfo'?: string | null;
    /**
     * Тэги.
     * @type {Array<TagModel>}
     * @memberof PersonModel
     */
    'tags': Array<TagModel>;
    /**
     * Список каналов контакта
     * @type {Array<PersonAccountModel>}
     * @memberof PersonModel
     */
    'personAccounts'?: Array<PersonAccountModel> | null;
    /**
     * Список ботов контакта.
     * @type {Array<AccountContactModel>}
     * @memberof PersonModel
     */
    'accountContacts'?: Array<AccountContactModel> | null;
    /**
     * Данные по ботам.
     * @type {Array<AgentModel>}
     * @memberof PersonModel
     */
    'agents'?: Array<AgentModel> | null;
    /**
     * Группы.
     * @type {Array<GroupModel>}
     * @memberof PersonModel
     */
    'groups'?: Array<GroupModel> | null;
    /**
     * Список идентификаторов групп.
     * @type {Array<string>}
     * @memberof PersonModel
     */
    'groupIds'?: Array<string> | null;
}
/**
 * 
 * @export
 * @interface PersonModelPaginationResponse
 */
export interface PersonModelPaginationResponse {
    /**
     * 
     * @type {Array<PersonModel>}
     * @memberof PersonModelPaginationResponse
     */
    'items'?: Array<PersonModel> | null;
    /**
     * 
     * @type {boolean}
     * @memberof PersonModelPaginationResponse
     */
    'hasMore'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof PersonModelPaginationResponse
     */
    'pageIndex'?: number;
    /**
     * 
     * @type {number}
     * @memberof PersonModelPaginationResponse
     */
    'pageSize'?: number;
    /**
     * 
     * @type {number}
     * @memberof PersonModelPaginationResponse
     */
    'totalItemCount'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof PersonModelPaginationResponse
     */
    'totalPageCount'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof PersonModelPaginationResponse
     */
    'fromItemInclusive'?: number;
    /**
     * 
     * @type {number}
     * @memberof PersonModelPaginationResponse
     */
    'toItemExclusive'?: number;
}
/**
 * Имя контакта.
 * @export
 * @interface PersonName
 */
export interface PersonName {
    /**
     * Полное имя.
     * @type {string}
     * @memberof PersonName
     */
    'fullName'?: string | null;
    /**
     * Краткое имя.
     * @type {string}
     * @memberof PersonName
     */
    'shortName'?: string | null;
    /**
     * Имя.
     * @type {string}
     * @memberof PersonName
     */
    'givenName'?: string | null;
    /**
     * Отчество.
     * @type {string}
     * @memberof PersonName
     */
    'middleName'?: string | null;
    /**
     * Фамилия.
     * @type {string}
     * @memberof PersonName
     */
    'familyName'?: string | null;
    /**
     * Никнейм.
     * @type {string}
     * @memberof PersonName
     */
    'nickName'?: string | null;
}
/**
 * 
 * @export
 * @interface PersonNameInputSchema
 */
export interface PersonNameInputSchema extends InputActionSchema {
    [key: string]: any;

    /**
     * 
     * @type {SchemaKind}
     * @memberof PersonNameInputSchema
     */
    '$kind': SchemaKind;
}


/**
 * Роль из auth-server.
 * @export
 * @interface PersonRole
 */
export interface PersonRole {
    /**
     * Имя роли.
     * @type {string}
     * @memberof PersonRole
     */
    'name': string;
}
/**
 * Запрос на изменение тегов контакта.
 * @export
 * @interface PersonTagsUpdatingRequest
 */
export interface PersonTagsUpdatingRequest {
    /**
     * Список идентификаторов тегов.
     * @type {Array<string>}
     * @memberof PersonTagsUpdatingRequest
     */
    'tagIds': Array<string>;
}
/**
 * Запрос на изменение контакта.
 * @export
 * @interface PersonUpdatingRequest
 */
export interface PersonUpdatingRequest {
    /**
     * ФИО контакта.
     * @type {string}
     * @memberof PersonUpdatingRequest
     */
    'fullName': string;
    /**
     * Имя контакта.
     * @type {string}
     * @memberof PersonUpdatingRequest
     */
    'firstName'?: string | null;
    /**
     * Фамилия контакта.
     * @type {string}
     * @memberof PersonUpdatingRequest
     */
    'lastName'?: string | null;
    /**
     * Отчество контакта.
     * @type {string}
     * @memberof PersonUpdatingRequest
     */
    'middleName'?: string | null;
    /**
     * Email контакта.
     * @type {string}
     * @memberof PersonUpdatingRequest
     */
    'eMail'?: string | null;
    /**
     * Основной номер телефона контакта.
     * @type {string}
     * @memberof PersonUpdatingRequest
     */
    'primaryTel'?: string | null;
    /**
     * Второстепенные номера телефонов контакта.
     * @type {Array<string>}
     * @memberof PersonUpdatingRequest
     */
    'secondaryTels'?: Array<string> | null;
    /**
     * ИД файла aватара контакта.
     * @type {string}
     * @memberof PersonUpdatingRequest
     */
    'avatarFileId'?: string | null;
    /**
     * Код страны.
     * @type {string}
     * @memberof PersonUpdatingRequest
     */
    'country'?: string | null;
    /**
     * Город.
     * @type {string}
     * @memberof PersonUpdatingRequest
     */
    'city'?: string | null;
    /**
     * Дополнительная информация.
     * @type {string}
     * @memberof PersonUpdatingRequest
     */
    'additionalInfo'?: string | null;
    /**
     * Список идентификаторов тэгов.
     * @type {Array<string>}
     * @memberof PersonUpdatingRequest
     */
    'tagIds'?: Array<string> | null;
    /**
     * Список идентификаторов групп.
     * @type {Array<string>}
     * @memberof PersonUpdatingRequest
     */
    'groupIds'?: Array<string> | null;
}
/**
 * 
 * @export
 * @interface PersonalCampaignResult
 */
export interface PersonalCampaignResult {
    /**
     * 
     * @type {string}
     * @memberof PersonalCampaignResult
     */
    'channelId': string;
    /**
     * 
     * @type {string}
     * @memberof PersonalCampaignResult
     */
    'agentStageAccountId': string;
    /**
     * 
     * @type {string}
     * @memberof PersonalCampaignResult
     */
    'personId': string;
    /**
     * 
     * @type {number}
     * @memberof PersonalCampaignResult
     */
    'progress': number;
    /**
     * 
     * @type {string}
     * @memberof PersonalCampaignResult
     */
    'proactiveDialogId': string;
    /**
     * 
     * @type {{ [key: string]: any; }}
     * @memberof PersonalCampaignResult
     */
    'properties': { [key: string]: any; };
    /**
     * 
     * @type {Array<DialogProgressState>}
     * @memberof PersonalCampaignResult
     */
    'progressStates': Array<DialogProgressState>;
}
/**
 * 
 * @export
 * @interface PhoneNumberInputSchema
 */
export interface PhoneNumberInputSchema extends InputActionSchema {
    [key: string]: any;

    /**
     * 
     * @type {SchemaKind}
     * @memberof PhoneNumberInputSchema
     */
    '$kind': SchemaKind;
    /**
     * 
     * @type {boolean}
     * @memberof PhoneNumberInputSchema
     */
    'contactRequired'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof PhoneNumberInputSchema
     */
    'contactButtonText'?: string | null;
}


/**
 * 
 * @export
 * @interface ProblemDetails
 */
export interface ProblemDetails {
    [key: string]: any;

    /**
     * 
     * @type {string}
     * @memberof ProblemDetails
     */
    'type'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProblemDetails
     */
    'title'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ProblemDetails
     */
    'status'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ProblemDetails
     */
    'detail'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProblemDetails
     */
    'instance'?: string | null;
}
/**
 * Бизнес-процесс.
 * @export
 * @interface Process
 */
export interface Process {
    [key: string]: any;

    /**
     * 
     * @type {{ [key: string]: ProcessItem; }}
     * @memberof Process
     */
    'items': { [key: string]: ProcessItem; };
}
/**
 * Элемент бизнес-процесса.
 * @export
 * @interface ProcessItem
 */
export interface ProcessItem {
    [key: string]: any;

    /**
     * 
     * @type {string}
     * @memberof ProcessItem
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof ProcessItem
     */
    'type': string;
    /**
     * 
     * @type {ProcessItemSettings}
     * @memberof ProcessItem
     */
    'settings': ProcessItemSettings;
}
/**
 * Настройки элемента бизнес-процесса.
 * @export
 * @interface ProcessItemSettings
 */
export interface ProcessItemSettings {
    [key: string]: any;

    /**
     * 
     * @type {Array<FormField>}
     * @memberof ProcessItemSettings
     */
    'formFields': Array<FormField>;
    /**
     * 
     * @type {string}
     * @memberof ProcessItemSettings
     */
    'instruction': string;
    /**
     * 
     * @type {string}
     * @memberof ProcessItemSettings
     */
    'notifyMessage': string;
    /**
     * 
     * @type {string}
     * @memberof ProcessItemSettings
     */
    'titleTemplate': string;
    /**
     * 
     * @type {boolean}
     * @memberof ProcessItemSettings
     */
    'applicationForm': boolean;
    /**
     * 
     * @type {string}
     * @memberof ProcessItemSettings
     */
    'titleGenerateMethod': string;
}
/**
 * Аналитика по событиям суфлера в день.
 * @export
 * @interface PrompterModeEventsPerDayModel
 */
export interface PrompterModeEventsPerDayModel {
    /**
     * Дата.
     * @type {string}
     * @memberof PrompterModeEventsPerDayModel
     */
    'date': string;
    /**
     * Количество принятых ответов бота.
     * @type {number}
     * @memberof PrompterModeEventsPerDayModel
     */
    'acceptedCount': number;
    /**
     * Количество отклоненных ответов бота.
     * @type {number}
     * @memberof PrompterModeEventsPerDayModel
     */
    'rejectedCount': number;
    /**
     * Количество ответов бота.
     * @type {number}
     * @memberof PrompterModeEventsPerDayModel
     */
    'botAnswerCount': number;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const PublishStage = {
    NUMBER_0: 0,
    NUMBER_1: 1,
    NUMBER_2: 2
} as const;

export type PublishStage = typeof PublishStage[keyof typeof PublishStage];


/**
 * 
 * @export
 * @interface RecognitionSettingsSchema
 */
export interface RecognitionSettingsSchema {
    /**
     * Порог уверенности распознавания
     * @type {number}
     * @memberof RecognitionSettingsSchema
     */
    'intentThreshold'?: number;
    /**
     * 
     * @type {NlpSettingsSchema}
     * @memberof RecognitionSettingsSchema
     */
    'nlp': NlpSettingsSchema;
    /**
     * 
     * @type {KbSettingsSchema}
     * @memberof RecognitionSettingsSchema
     */
    'kb': KbSettingsSchema;
    /**
     * 
     * @type {ResultSelectionPriorityType}
     * @memberof RecognitionSettingsSchema
     */
    'resultSelectionPriority': ResultSelectionPriorityType;
}


/**
 * 
 * @export
 * @interface ResourceResponse
 */
export interface ResourceResponse {
    /**
     * 
     * @type {string}
     * @memberof ResourceResponse
     */
    'id'?: string | null;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const ResultSelectionPriorityType = {
    Score: 'Score',
    SigmaAnswer: 'SigmaAnswer',
    OmegaIntent: 'OmegaIntent'
} as const;

export type ResultSelectionPriorityType = typeof ResultSelectionPriorityType[keyof typeof ResultSelectionPriorityType];


/**
 * Модель роли.
 * @export
 * @interface RoleModel
 */
export interface RoleModel {
    /**
     * ИД роли.
     * @type {string}
     * @memberof RoleModel
     */
    'id': string;
    /**
     * Наименование роли.
     * @type {string}
     * @memberof RoleModel
     */
    'name': string;
    /**
     * Описание роли.
     * @type {string}
     * @memberof RoleModel
     */
    'description'?: string | null;
}
/**
 * Результат запуска экспорта редакции бота.
 * @export
 * @interface RunBotEditionExportResultModel
 */
export interface RunBotEditionExportResultModel {
    /**
     * ИД запроса.
     * @type {string}
     * @memberof RunBotEditionExportResultModel
     */
    'requestId': string;
}
/**
 * 
 * @export
 * @interface RunConversationExportResultModel
 */
export interface RunConversationExportResultModel {
    /**
     * ИД запроса.
     * @type {string}
     * @memberof RunConversationExportResultModel
     */
    'requestId': string;
}
/**
 * Результат запуска экспорта отчетов аналитики.
 * @export
 * @interface RunExportAnalyticsReportResultModel
 */
export interface RunExportAnalyticsReportResultModel {
    /**
     * ИД запроса.
     * @type {string}
     * @memberof RunExportAnalyticsReportResultModel
     */
    'requestId': string;
}
/**
 * Результат запуска экспорта сценария.
 * @export
 * @interface RunScenarioExportResultModel
 */
export interface RunScenarioExportResultModel {
    /**
     * ИД запроса.
     * @type {string}
     * @memberof RunScenarioExportResultModel
     */
    'requestId': string;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const ScenarioCancellationMode = {
    Disabled: 'Disabled',
    CancelAll: 'CancelAll'
} as const;

export type ScenarioCancellationMode = typeof ScenarioCancellationMode[keyof typeof ScenarioCancellationMode];


/**
 * 
 * @export
 * @enum {string}
 */

export const ScenarioContentFormat = {
    Json: 'Json'
} as const;

export type ScenarioContentFormat = typeof ScenarioContentFormat[keyof typeof ScenarioContentFormat];


/**
 * 
 * @export
 * @interface ScenarioCreationRequest
 */
export interface ScenarioCreationRequest {
    /**
     * Название сценария.
     * @type {string}
     * @memberof ScenarioCreationRequest
     */
    'scenarioName'?: string | null;
    /**
     * Структура сценария.
     * @type {any}
     * @memberof ScenarioCreationRequest
     */
    'scenarioStructure'?: any | null;
    /**
     * 
     * @type {FileContentData}
     * @memberof ScenarioCreationRequest
     */
    'scenarioFile'?: FileContentData;
}
/**
 * 
 * @export
 * @interface ScenarioDuplicationRequest
 */
export interface ScenarioDuplicationRequest {
    /**
     * ИД структуры сценария.
     * @type {string}
     * @memberof ScenarioDuplicationRequest
     */
    'scenarioStructureId': string;
}
/**
 * 
 * @export
 * @interface ScenarioEditionModel
 */
export interface ScenarioEditionModel {
    /**
     * ИД редакции сценария.
     * @type {string}
     * @memberof ScenarioEditionModel
     */
    'id': string;
    /**
     * Структура сценария.
     * @type {any}
     * @memberof ScenarioEditionModel
     */
    'structure': any;
    /**
     * 
     * @type {StructureValidationResult}
     * @memberof ScenarioEditionModel
     */
    'validation': StructureValidationResult;
}
/**
 * 
 * @export
 * @interface ScenarioEditionReferenceModel
 */
export interface ScenarioEditionReferenceModel {
    /**
     * ИД редакции сценария.
     * @type {string}
     * @memberof ScenarioEditionReferenceModel
     */
    'scenarioEditionId': string;
    /**
     * ИД структуры сценария.
     * @type {string}
     * @memberof ScenarioEditionReferenceModel
     */
    'scenarioStructureId': string;
    /**
     * Признак включения/выключения сценария
     * @type {boolean}
     * @memberof ScenarioEditionReferenceModel
     */
    'enabled': boolean;
    /**
     * Название сценария.
     * @type {string}
     * @memberof ScenarioEditionReferenceModel
     */
    'name': string;
    /**
     * Дата создания сценария.
     * @type {string}
     * @memberof ScenarioEditionReferenceModel
     */
    'createdOn': string;
    /**
     * Дата изменения сценария.
     * @type {string}
     * @memberof ScenarioEditionReferenceModel
     */
    'modifiedOn': string;
    /**
     * 
     * @type {ScenarioInterruptionMode}
     * @memberof ScenarioEditionReferenceModel
     */
    'interruption': ScenarioInterruptionMode;
    /**
     * 
     * @type {ScenarioCancellationMode}
     * @memberof ScenarioEditionReferenceModel
     */
    'cancellation': ScenarioCancellationMode;
}


/**
 * 
 * @export
 * @interface ScenarioEventCountModel
 */
export interface ScenarioEventCountModel {
    /**
     * Название сценария.
     * @type {string}
     * @memberof ScenarioEventCountModel
     */
    'scenarioName': string;
    /**
     * Количество событий.
     * @type {number}
     * @memberof ScenarioEventCountModel
     */
    'eventCount': number;
    /**
     * ИД сценария.
     * @type {string}
     * @memberof ScenarioEventCountModel
     */
    'scenarioStructureId': string;
}
/**
 * 
 * @export
 * @interface ScenarioGetUsagesResponse
 */
export interface ScenarioGetUsagesResponse {
    /**
     * Список сценариев.
     * @type {Array<ScenarioEditionReferenceModel>}
     * @memberof ScenarioGetUsagesResponse
     */
    'scenarios': Array<ScenarioEditionReferenceModel>;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const ScenarioInterruptionMode = {
    Disabled: 'Disabled',
    InterruptActive: 'InterruptActive'
} as const;

export type ScenarioInterruptionMode = typeof ScenarioInterruptionMode[keyof typeof ScenarioInterruptionMode];


/**
 * 
 * @export
 * @interface ScenarioSchema
 */
export interface ScenarioSchema extends EntitySchema {
    [key: string]: any;

    /**
     * 
     * @type {string}
     * @memberof ScenarioSchema
     */
    '$kind': string;
    /**
     * 
     * @type {string}
     * @memberof ScenarioSchema
     */
    'version': string;
    /**
     * 
     * @type {string}
     * @memberof ScenarioSchema
     */
    'name': string;
    /**
     * 
     * @type {boolean}
     * @memberof ScenarioSchema
     */
    'enabled': boolean;
    /**
     * 
     * @type {ScenarioInterruptionMode}
     * @memberof ScenarioSchema
     */
    'interruption': ScenarioInterruptionMode;
    /**
     * 
     * @type {ScenarioCancellationMode}
     * @memberof ScenarioSchema
     */
    'cancellation': ScenarioCancellationMode;
    /**
     * 
     * @type {Array<VariableSchema>}
     * @memberof ScenarioSchema
     */
    'variables': Array<VariableSchema>;
}
/**
 * Аналитика по стартам сценариев в день.
 * @export
 * @interface ScenarioStartsPerDayModel
 */
export interface ScenarioStartsPerDayModel {
    /**
     * Дата.
     * @type {string}
     * @memberof ScenarioStartsPerDayModel
     */
    'date': string;
    /**
     * Общее количество стартов сценариев.
     * @type {number}
     * @memberof ScenarioStartsPerDayModel
     */
    'totalScenarioStartCount': number;
    /**
     * Топ сценариев.
     * @type {Array<ScenarioEventCountModel>}
     * @memberof ScenarioStartsPerDayModel
     */
    'topScenarioStarts': Array<ScenarioEventCountModel>;
}
/**
 * 
 * @export
 * @interface ScenarioTestingRequest
 */
export interface ScenarioTestingRequest {
    /**
     * Структура сценария.
     * @type {any}
     * @memberof ScenarioTestingRequest
     */
    'scenarioStructure': any;
}
/**
 * 
 * @export
 * @interface ScenarioTestingResponse
 */
export interface ScenarioTestingResponse {
    /**
     * 
     * @type {AgentStageModel}
     * @memberof ScenarioTestingResponse
     */
    'agentStage': AgentStageModel;
    /**
     * Ссылка для тестирования в эмуляторе.
     * @type {string}
     * @memberof ScenarioTestingResponse
     */
    'emulatorUrl': string;
    /**
     * URL обратного вызова.
     * @type {string}
     * @memberof ScenarioTestingResponse
     */
    'callbackUrl': string;
    /**
     * Ссылка для тестирования в веб-чате.
     * @type {string}
     * @memberof ScenarioTestingResponse
     */
    'webchatUrl': string;
}
/**
 * 
 * @export
 * @interface ScenarioUpdatingRequest
 */
export interface ScenarioUpdatingRequest {
    /**
     * ИД структуры сценария.
     * @type {string}
     * @memberof ScenarioUpdatingRequest
     */
    'scenarioStructureId': string;
    /**
     * 
     * @type {UpdateScenarioOperationType}
     * @memberof ScenarioUpdatingRequest
     */
    'operationType': UpdateScenarioOperationType;
    /**
     * Название сценария.
     * @type {string}
     * @memberof ScenarioUpdatingRequest
     */
    'name'?: string | null;
    /**
     * Структура сценария.
     * @type {any}
     * @memberof ScenarioUpdatingRequest
     */
    'scenarioStructure'?: any | null;
    /**
     * 
     * @type {FileContentData}
     * @memberof ScenarioUpdatingRequest
     */
    'scenarioFile'?: FileContentData;
    /**
     * Признак включен/выключен
     * @type {boolean}
     * @memberof ScenarioUpdatingRequest
     */
    'enabled'?: boolean;
    /**
     * 
     * @type {ScenarioInterruptionMode}
     * @memberof ScenarioUpdatingRequest
     */
    'interruption'?: ScenarioInterruptionMode;
    /**
     * 
     * @type {ScenarioCancellationMode}
     * @memberof ScenarioUpdatingRequest
     */
    'cancellation'?: ScenarioCancellationMode;
}


/**
 * 
 * @export
 * @interface ScenarioValidationRequest
 */
export interface ScenarioValidationRequest {
    /**
     * Структура сценария.
     * @type {any}
     * @memberof ScenarioValidationRequest
     */
    'scenarioStructure'?: any | null;
}
/**
 * 
 * @export
 * @interface ScenarioValidationResponse
 */
export interface ScenarioValidationResponse {
    /**
     * 
     * @type {StructureValidationResult}
     * @memberof ScenarioValidationResponse
     */
    'result': StructureValidationResult;
}
/**
 * 
 * @export
 * @interface Schedule
 */
export interface Schedule {
    /**
     * 
     * @type {ScheduleType}
     * @memberof Schedule
     */
    'type'?: ScheduleType;
}


/**
 * 
 * @export
 * @enum {string}
 */

export const ScheduleType = {
    NUMBER_0: 0,
    NUMBER_1: 1
} as const;

export type ScheduleType = typeof ScheduleType[keyof typeof ScheduleType];


/**
 * 
 * @export
 * @enum {string}
 */

export const SchemaKind = {
    Variable: 'ELMA.Assistant.Variable',
    Message: 'ELMA.Assistant.Message',
    Suggestion: 'ELMA.Assistant.Suggestion',
    Button: 'ELMA.Assistant.Button',
    TextValueComparisonValidator: 'ELMA.Assistant.TextValueComparisonValidator',
    NumberValueComparisonValidator: 'ELMA.Assistant.NumberValueComparisonValidator',
    DateTimeValueComparisonValidator: 'ELMA.Assistant.DateTimeValueComparisonValidator',
    DateTimeTypeValidator: 'ELMA.Assistant.DateTimeTypeValidator',
    VariableComparisonValidator: 'ELMA.Assistant.VariableComparisonValidator',
    CurrentDateTimeComparisonValidator: 'ELMA.Assistant.CurrentDateTimeComparisonValidator',
    LengthComparisonValidator: 'ELMA.Assistant.LengthComparisonValidator',
    IntentContent: 'ELMA.Assistant.IntentContent',
    SystemIntentReference: 'ELMA.Assistant.SystemIntentReference',
    UserIntentReference: 'ELMA.Assistant.UserIntentReference',
    IntentTrigger: 'ELMA.Assistant.IntentTrigger',
    TimerTrigger: 'ELMA.Assistant.TimerTrigger',
    StartTrigger: 'ELMA.Assistant.StartTrigger',
    TransitionTrigger: 'ELMA.Assistant.TransitionTrigger',
    UnknownIntentTrigger: 'ELMA.Assistant.UnknownIntentTrigger',
    TerminalTrigger: 'ELMA.Assistant.TerminalTrigger',
    MenuButtonTrigger: 'ELMA.Assistant.MenuButtonTrigger',
    ExternalEventTrigger: 'ELMA.Assistant.ExternalEventTrigger',
    TextInput: 'ELMA.Assistant.TextInput',
    NumberInput: 'ELMA.Assistant.NumberInput',
    DateTimeInput: 'ELMA.Assistant.DateTimeInput',
    EmailAddressInput: 'ELMA.Assistant.EmailAddressInput',
    PhoneNumberInput: 'ELMA.Assistant.PhoneNumberInput',
    LinkInput: 'ELMA.Assistant.LinkInput',
    LocationInput: 'ELMA.Assistant.LocationInput',
    PersonNameInput: 'ELMA.Assistant.PersonNameInput',
    SuggestionInput: 'ELMA.Assistant.SuggestionInput',
    FileInput: 'ELMA.Assistant.FileInput',
    TextOutput: 'ELMA.Assistant.TextOutput',
    EndScenarioOutput: 'ELMA.Assistant.EndScenarioOutput',
    ConfirmTransit: 'ELMA.Assistant.ConfirmTransit',
    ChoiceTransit: 'ELMA.Assistant.ChoiceTransit',
    DefaultTriggerGroup: 'ELMA.Assistant.DefaultTriggerGroup',
    DefaultActionGroup: 'ELMA.Assistant.DefaultActionGroup',
    Binding: 'ELMA.Assistant.Binding',
    DefaultScenario: 'ELMA.Assistant.DefaultScenario',
    Bot: 'ELMA.Assistant.Bot',
    TransferToOperator: 'ELMA.Assistant.TransferToOperator',
    MenuAction: 'ELMA.Assistant.MenuAction',
    MenuButton: 'ELMA.Assistant.MenuButton',
    ScriptAction: 'ELMA.Assistant.ScriptAction',
    Elma365UserInput: 'ELMA.Assistant.Elma365UserInput',
    Elma365AppElementInput: 'ELMA.Assistant.Elma365AppElementInput',
    Elma365EnumInput: 'ELMA.Assistant.Elma365EnumInput',
    SwitchAction: 'ELMA.Assistant.SwitchAction',
    MainCaseAction: 'ELMA.Assistant.MainCaseAction',
    ElseCaseAction: 'ELMA.Assistant.ElseCaseAction',
    UnaryCondition: 'ELMA.Assistant.UnaryCondition',
    BinaryCondition: 'ELMA.Assistant.BinaryCondition',
    ConstantOperand: 'ELMA.Assistant.ConstantOperand',
    VariableOperand: 'ELMA.Assistant.VariableOperand',
    BotSettings: 'ELMA.Assistant.BotSettings',
    Elma4IntegrationSettings: 'ELMA.Assistant.ELMA4IntegrationSettings',
    Elma365IntegrationSettings: 'ELMA.Assistant.ELMA365IntegrationSettings',
    StartElma365Process: 'ELMA.Assistant.StartElma365Process',
    Elma365FormProperty: 'ELMA.Assistant.Elma365FormProperty',
    CreateElma365AppElement: 'ELMA.Assistant.CreateElma365AppElement',
    Attachment: 'ELMA.Assistant.Attachment',
    ExternalSignIn: 'ELMA.Assistant.ExternalSignIn',
    OAuth2LoginProtocol: 'ELMA.Assistant.OAuth2LoginProtocol',
    SendEmail: 'ELMA.Assistant.SendEmail',
    SendSuccessfullServiceEvent: 'ELMA.Assistant.SendSuccessfullServiceEvent',
    CloseConversation: 'ELMA.Assistant.CloseConversation',
    SendCustomEvent: 'ELMA.Assistant.SendCustomEvent',
    CommandTrigger: 'ELMA.Assistant.CommandTrigger',
    MergeInboxContacts: 'ELMA.Assistant.MergeInboxContacts'
} as const;

export type SchemaKind = typeof SchemaKind[keyof typeof SchemaKind];


/**
 * 
 * @export
 * @interface ScriptActionSchema
 */
export interface ScriptActionSchema extends ActionSchema {
    [key: string]: any;

    /**
     * 
     * @type {SchemaKind}
     * @memberof ScriptActionSchema
     */
    '$kind': SchemaKind;
    /**
     * 
     * @type {string}
     * @memberof ScriptActionSchema
     */
    'content': string;
    /**
     * 
     * @type {string}
     * @memberof ScriptActionSchema
     */
    'version': string;
    /**
     * Таймаут выполнения в секундах.
     * @type {number}
     * @memberof ScriptActionSchema
     */
    'executionTimeout': number;
    /**
     * Количество повторных попыток.
     * @type {number}
     * @memberof ScriptActionSchema
     */
    'retryCount': number;
    /**
     * Задержка между повторными попытками в секундах.
     * @type {number}
     * @memberof ScriptActionSchema
     */
    'retryDelay': number;
    /**
     * 
     * @type {string}
     * @memberof ScriptActionSchema
     */
    'outputBindingId'?: string | null;
}


/**
 * 
 * @export
 * @interface ScriptSettingsSchema
 */
export interface ScriptSettingsSchema {
    /**
     * Текст скрипта.
     * @type {string}
     * @memberof ScriptSettingsSchema
     */
    'content'?: string | null;
    /**
     * Версия скрипта.
     * @type {string}
     * @memberof ScriptSettingsSchema
     */
    'version': string;
    /**
     * Таймаут выполнения скрипта в секундах.
     * @type {number}
     * @memberof ScriptSettingsSchema
     */
    'executionTimeout': number;
    /**
     * Количество повторных попыток выполнения скрипта.
     * @type {number}
     * @memberof ScriptSettingsSchema
     */
    'retryCount': number;
    /**
     * Задержка между повторными попытками выполнения скрипта в секундах.
     * @type {number}
     * @memberof ScriptSettingsSchema
     */
    'retryDelay': number;
    /**
     * 
     * @type {ScriptUserDataSchema}
     * @memberof ScriptSettingsSchema
     */
    'userData'?: ScriptUserDataSchema;
}
/**
 * 
 * @export
 * @interface ScriptUserDataSchema
 */
export interface ScriptUserDataSchema {
    /**
     * 
     * @type {{ [key: string]: any; }}
     * @memberof ScriptUserDataSchema
     */
    'content'?: { [key: string]: any; } | null;
}
/**
 * 
 * @export
 * @interface SendCustomEventSchema
 */
export interface SendCustomEventSchema extends ActionSchema {
    [key: string]: any;

    /**
     * 
     * @type {SchemaKind}
     * @memberof SendCustomEventSchema
     */
    '$kind': SchemaKind;
    /**
     * 
     * @type {string}
     * @memberof SendCustomEventSchema
     */
    'eventName': string;
}


/**
 * 
 * @export
 * @interface SendEmailSchema
 */
export interface SendEmailSchema extends ActionSchema {
    [key: string]: any;

    /**
     * 
     * @type {SchemaKind}
     * @memberof SendEmailSchema
     */
    '$kind': SchemaKind;
    /**
     * 
     * @type {Array<OperandSchema>}
     * @memberof SendEmailSchema
     */
    'recipients': Array<OperandSchema>;
    /**
     * 
     * @type {Array<OperandSchema>}
     * @memberof SendEmailSchema
     */
    'ccRecipients': Array<OperandSchema>;
    /**
     * 
     * @type {Array<OperandSchema>}
     * @memberof SendEmailSchema
     */
    'bccRecipients': Array<OperandSchema>;
    /**
     * 
     * @type {OperandSchema}
     * @memberof SendEmailSchema
     */
    'subject': OperandSchema;
    /**
     * 
     * @type {string}
     * @memberof SendEmailSchema
     */
    'body': string;
    /**
     * 
     * @type {Array<OperandSchema>}
     * @memberof SendEmailSchema
     */
    'attachments': Array<OperandSchema>;
}


/**
 * 
 * @export
 * @interface SendSuccessfullServiceEventSchema
 */
export interface SendSuccessfullServiceEventSchema extends ActionSchema {
    [key: string]: any;

    /**
     * 
     * @type {SchemaKind}
     * @memberof SendSuccessfullServiceEventSchema
     */
    '$kind': SchemaKind;
    /**
     * 
     * @type {SuccessfullServiceEventName}
     * @memberof SendSuccessfullServiceEventSchema
     */
    'eventName': SuccessfullServiceEventName;
}


/**
 * 
 * @export
 * @interface SettingsUpdatingRequest
 */
export interface SettingsUpdatingRequest {
    /**
     * 
     * @type {BotSettingsSchema}
     * @memberof SettingsUpdatingRequest
     */
    'settings': BotSettingsSchema;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const SetupStatus = {
    Pending: 'Pending',
    Success: 'Success',
    Error: 'Error'
} as const;

export type SetupStatus = typeof SetupStatus[keyof typeof SetupStatus];


/**
 * 
 * @export
 * @interface SigmaAnswerCountModel
 */
export interface SigmaAnswerCountModel {
    /**
     * Вопрос.
     * @type {string}
     * @memberof SigmaAnswerCountModel
     */
    'question': string;
    /**
     * Количество.
     * @type {number}
     * @memberof SigmaAnswerCountModel
     */
    'count': number;
    /**
     * ИД ответа базы знаний.
     * @type {string}
     * @memberof SigmaAnswerCountModel
     */
    'answerId': string;
}
/**
 * 
 * @export
 * @interface SingleBotModel
 */
export interface SingleBotModel {
    /**
     * 
     * @type {BotEntryModel}
     * @memberof SingleBotModel
     */
    'entry': BotEntryModel;
    /**
     * 
     * @type {BotStageModel}
     * @memberof SingleBotModel
     */
    'originStage': BotStageModel;
    /**
     * 
     * @type {BotEditionModel}
     * @memberof SingleBotModel
     */
    'originSourceEdition': BotEditionModel;
    /**
     * 
     * @type {BotEditionModel}
     * @memberof SingleBotModel
     */
    'originCurrentEdition': BotEditionModel;
    /**
     * 
     * @type {BotStageModel}
     * @memberof SingleBotModel
     */
    'draftStage'?: BotStageModel;
    /**
     * 
     * @type {BotEditionModel}
     * @memberof SingleBotModel
     */
    'draftSourceEdition'?: BotEditionModel;
    /**
     * 
     * @type {BotEditionModel}
     * @memberof SingleBotModel
     */
    'draftCurrentEdition'?: BotEditionModel;
}
/**
 * 
 * @export
 * @interface SingleIntentModel
 */
export interface SingleIntentModel {
    /**
     * 
     * @type {GroupEntryModel}
     * @memberof SingleIntentModel
     */
    'groupEntry': GroupEntryModel;
    /**
     * 
     * @type {GroupEditionModel}
     * @memberof SingleIntentModel
     */
    'groupEdition': GroupEditionModel;
    /**
     * 
     * @type {IntentEntryModel}
     * @memberof SingleIntentModel
     */
    'intentEntry': IntentEntryModel;
    /**
     * 
     * @type {IntentEditionModel}
     * @memberof SingleIntentModel
     */
    'intentEdition': IntentEditionModel;
}
/**
 * Шаблон для создания сценария простого бота.
 * @export
 * @interface SingleScenarioTemplateModel
 */
export interface SingleScenarioTemplateModel {
    /**
     * Идентификатор шаблона.
     * @type {string}
     * @memberof SingleScenarioTemplateModel
     */
    'id': string;
    /**
     * Код шаблона.
     * @type {string}
     * @memberof SingleScenarioTemplateModel
     */
    'code': string;
    /**
     * Отображаемое название шаблона.
     * @type {string}
     * @memberof SingleScenarioTemplateModel
     */
    'name': string;
    /**
     * Описание.
     * @type {string}
     * @memberof SingleScenarioTemplateModel
     */
    'description': string;
    /**
     * Идентификатор шаблона бота.
     * @type {string}
     * @memberof SingleScenarioTemplateModel
     */
    'botTemplateId'?: string | null;
    /**
     * Название шаблона бота.
     * @type {string}
     * @memberof SingleScenarioTemplateModel
     */
    'botTemplateName'?: string | null;
    /**
     * Название иконпака.
     * @type {string}
     * @memberof SingleScenarioTemplateModel
     */
    'iconPack'?: string | null;
    /**
     * Код иконки.
     * @type {string}
     * @memberof SingleScenarioTemplateModel
     */
    'iconCode'?: string | null;
    /**
     * Содержимое шаблона.
     * @type {string}
     * @memberof SingleScenarioTemplateModel
     */
    'content': string;
}
/**
 * 
 * @export
 * @interface SmtpSettingsSchema
 */
export interface SmtpSettingsSchema {
    /**
     * Адрес сервера
     * @type {string}
     * @memberof SmtpSettingsSchema
     */
    'host'?: string | null;
    /**
     * Имя отправителя
     * @type {string}
     * @memberof SmtpSettingsSchema
     */
    'name'?: string | null;
    /**
     * Имя пользователя
     * @type {string}
     * @memberof SmtpSettingsSchema
     */
    'username'?: string | null;
    /**
     * Пароль
     * @type {string}
     * @memberof SmtpSettingsSchema
     */
    'password'?: string | null;
    /**
     * Порт сервера
     * @type {number}
     * @memberof SmtpSettingsSchema
     */
    'port'?: number;
    /**
     * Использовать SSL
     * @type {boolean}
     * @memberof SmtpSettingsSchema
     */
    'useSsl': boolean;
}
/**
 * 
 * @export
 * @interface StartElma365ProcessActionSchema
 */
export interface StartElma365ProcessActionSchema extends ActionSchema {
    [key: string]: any;

    /**
     * 
     * @type {SchemaKind}
     * @memberof StartElma365ProcessActionSchema
     */
    '$kind': SchemaKind;
    /**
     * 
     * @type {string}
     * @memberof StartElma365ProcessActionSchema
     */
    'bpmTemplateCode'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof StartElma365ProcessActionSchema
     */
    'bpmTemplateNamespace'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof StartElma365ProcessActionSchema
     */
    'bpmTemplateVersion'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof StartElma365ProcessActionSchema
     */
    'processTitle'?: string | null;
    /**
     * 
     * @type {Elma365ProcessStartType}
     * @memberof StartElma365ProcessActionSchema
     */
    'startBy': Elma365ProcessStartType;
    /**
     * 
     * @type {Array<Elma365FormPropertySchema>}
     * @memberof StartElma365ProcessActionSchema
     */
    'formProperties': Array<Elma365FormPropertySchema>;
    /**
     * 
     * @type {string}
     * @memberof StartElma365ProcessActionSchema
     */
    'relatedAppElementVariableId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof StartElma365ProcessActionSchema
     */
    'variableId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof StartElma365ProcessActionSchema
     */
    'outputBindingId'?: string | null;
}


/**
 * 
 * @export
 * @interface StartTriggerSchema
 */
export interface StartTriggerSchema extends TriggerSchema {
    [key: string]: any;

    /**
     * 
     * @type {SchemaKind}
     * @memberof StartTriggerSchema
     */
    '$kind': SchemaKind;
}


/**
 * 
 * @export
 * @enum {string}
 */

export const StructureType = {
    Bot: 'Bot',
    Scenario: 'Scenario'
} as const;

export type StructureType = typeof StructureType[keyof typeof StructureType];


/**
 * 
 * @export
 * @interface StructureValidationResult
 */
export interface StructureValidationResult {
    /**
     * 
     * @type {StructureType}
     * @memberof StructureValidationResult
     */
    'structureType': StructureType;
    /**
     * 
     * @type {Array<EntityValidationResult>}
     * @memberof StructureValidationResult
     */
    'entityResults': Array<EntityValidationResult>;
}


/**
 * 
 * @export
 * @enum {string}
 */

export const SuccessfullServiceEventName = {
    UserAnsweredYes: 'userAnsweredYes',
    UserAnsweredNo: 'userAnsweredNo'
} as const;

export type SuccessfullServiceEventName = typeof SuccessfullServiceEventName[keyof typeof SuccessfullServiceEventName];


/**
 * Аналитика по успешным обслуживаниям в день.
 * @export
 * @interface SuccessfullServiceEventsPerDayModel
 */
export interface SuccessfullServiceEventsPerDayModel {
    /**
     * Дата.
     * @type {string}
     * @memberof SuccessfullServiceEventsPerDayModel
     */
    'date': string;
    /**
     * Количество успешных обслуживаний с ответом Да от пользователя.
     * @type {number}
     * @memberof SuccessfullServiceEventsPerDayModel
     */
    'userAnsweredYesCount': number;
    /**
     * Количество успешных обслуживаний с ответом Нет от пользователя.
     * @type {number}
     * @memberof SuccessfullServiceEventsPerDayModel
     */
    'userAnsweredNoCount': number;
}
/**
 * Аналитика по успешным обслуживаниям в разрезе сценария.
 * @export
 * @interface SuccessfullServiceEventsPerScenarioModel
 */
export interface SuccessfullServiceEventsPerScenarioModel {
    /**
     * Название сценария.
     * @type {string}
     * @memberof SuccessfullServiceEventsPerScenarioModel
     */
    'scenarioName': string;
    /**
     * ИД сценария.
     * @type {string}
     * @memberof SuccessfullServiceEventsPerScenarioModel
     */
    'scenarioStructureId': string;
    /**
     * Количество успешных обслуживаний с ответом Да от пользователя.
     * @type {number}
     * @memberof SuccessfullServiceEventsPerScenarioModel
     */
    'userAnsweredYesCount': number;
    /**
     * Количество успешных обслуживаний с ответом Нет от пользователя.
     * @type {number}
     * @memberof SuccessfullServiceEventsPerScenarioModel
     */
    'userAnsweredNoCount': number;
}
/**
 * 
 * @export
 * @interface SuggestedActions
 */
export interface SuggestedActions {
    /**
     * 
     * @type {Array<string>}
     * @memberof SuggestedActions
     */
    'to'?: Array<string> | null;
    /**
     * 
     * @type {Array<CardAction>}
     * @memberof SuggestedActions
     */
    'actions'?: Array<CardAction> | null;
}
/**
 * 
 * @export
 * @interface SuggestionInputSchema
 */
export interface SuggestionInputSchema extends InputActionSchema {
    [key: string]: any;

    /**
     * 
     * @type {SchemaKind}
     * @memberof SuggestionInputSchema
     */
    '$kind': SchemaKind;
    /**
     * 
     * @type {Array<SuggestionSchema>}
     * @memberof SuggestionInputSchema
     */
    'suggestions': Array<SuggestionSchema>;
}


/**
 * 
 * @export
 * @interface SuggestionSchema
 */
export interface SuggestionSchema extends EntitySchema {
    [key: string]: any;

    /**
     * 
     * @type {SchemaKind}
     * @memberof SuggestionSchema
     */
    '$kind': SchemaKind;
    /**
     * 
     * @type {string}
     * @memberof SuggestionSchema
     */
    'caption': string;
}


/**
 * 
 * @export
 * @interface SwitchActionSchema
 */
export interface SwitchActionSchema extends ActionSchema {
    [key: string]: any;

    /**
     * 
     * @type {SchemaKind}
     * @memberof SwitchActionSchema
     */
    '$kind': SchemaKind;
    /**
     * 
     * @type {Array<MainCaseActionSchema>}
     * @memberof SwitchActionSchema
     */
    'mainCases': Array<MainCaseActionSchema>;
    /**
     * 
     * @type {ElseCaseActionSchema}
     * @memberof SwitchActionSchema
     */
    'elseCase': ElseCaseActionSchema;
}


/**
 * 
 * @export
 * @interface SystemIntentReferenceSchema
 */
export interface SystemIntentReferenceSchema extends IntentReferenceSchema {
    [key: string]: any;

    /**
     * 
     * @type {SchemaKind}
     * @memberof SystemIntentReferenceSchema
     */
    '$kind': SchemaKind;
    /**
     * 
     * @type {string}
     * @memberof SystemIntentReferenceSchema
     */
    'groupCode': string;
    /**
     * 
     * @type {string}
     * @memberof SystemIntentReferenceSchema
     */
    'intentCode': string;
    /**
     * 
     * @type {string}
     * @memberof SystemIntentReferenceSchema
     */
    'semVer': string;
}


/**
 * Запрос на создание тега.
 * @export
 * @interface TagCreationRequest
 */
export interface TagCreationRequest {
    /**
     * Название.
     * @type {string}
     * @memberof TagCreationRequest
     */
    'label': string;
    /**
     * Описание.
     * @type {string}
     * @memberof TagCreationRequest
     */
    'description'?: string | null;
    /**
     * Цвет.
     * @type {string}
     * @memberof TagCreationRequest
     */
    'color': string;
    /**
     * 
     * @type {TagStatus}
     * @memberof TagCreationRequest
     */
    'status': TagStatus;
    /**
     * Порядковый номер.
     * @type {number}
     * @memberof TagCreationRequest
     */
    'order': number;
}


/**
 * Ответ на создание тега.
 * @export
 * @interface TagCreationResponse
 */
export interface TagCreationResponse {
    /**
     * ИД тега.
     * @type {string}
     * @memberof TagCreationResponse
     */
    'id'?: string | null;
}
/**
 * Модель тега.
 * @export
 * @interface TagModel
 */
export interface TagModel {
    /**
     * ИД тега.
     * @type {string}
     * @memberof TagModel
     */
    'id': string;
    /**
     * Название.
     * @type {string}
     * @memberof TagModel
     */
    'label': string;
    /**
     * Описание.
     * @type {string}
     * @memberof TagModel
     */
    'description'?: string | null;
    /**
     * Цвет.
     * @type {string}
     * @memberof TagModel
     */
    'color': string;
    /**
     * 
     * @type {TagStatus}
     * @memberof TagModel
     */
    'status': TagStatus;
    /**
     * Порядковый номер.
     * @type {number}
     * @memberof TagModel
     */
    'order': number;
}


/**
 * 
 * @export
 * @interface TagModelPaginationResponse
 */
export interface TagModelPaginationResponse {
    /**
     * 
     * @type {Array<TagModel>}
     * @memberof TagModelPaginationResponse
     */
    'items'?: Array<TagModel> | null;
    /**
     * 
     * @type {boolean}
     * @memberof TagModelPaginationResponse
     */
    'hasMore'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof TagModelPaginationResponse
     */
    'pageIndex'?: number;
    /**
     * 
     * @type {number}
     * @memberof TagModelPaginationResponse
     */
    'pageSize'?: number;
    /**
     * 
     * @type {number}
     * @memberof TagModelPaginationResponse
     */
    'totalItemCount'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof TagModelPaginationResponse
     */
    'totalPageCount'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof TagModelPaginationResponse
     */
    'fromItemInclusive'?: number;
    /**
     * 
     * @type {number}
     * @memberof TagModelPaginationResponse
     */
    'toItemExclusive'?: number;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const TagStatus = {
    Visible: 'Visible',
    Hidden: 'Hidden'
} as const;

export type TagStatus = typeof TagStatus[keyof typeof TagStatus];


/**
 * Запрос на изменение тега.
 * @export
 * @interface TagUpdatingRequest
 */
export interface TagUpdatingRequest {
    /**
     * Название.
     * @type {string}
     * @memberof TagUpdatingRequest
     */
    'label': string;
    /**
     * Описание.
     * @type {string}
     * @memberof TagUpdatingRequest
     */
    'description'?: string | null;
    /**
     * Цвет.
     * @type {string}
     * @memberof TagUpdatingRequest
     */
    'color': string;
    /**
     * 
     * @type {TagStatus}
     * @memberof TagUpdatingRequest
     */
    'status': TagStatus;
    /**
     * Порядковый номер.
     * @type {number}
     * @memberof TagUpdatingRequest
     */
    'order': number;
}


/**
 * Модель результата тарификации.
 * @export
 * @interface TariffResultModel
 */
export interface TariffResultModel {
    /**
     * 
     * @type {TariffStatus}
     * @memberof TariffResultModel
     */
    'status': TariffStatus;
    /**
     * Сообщение тарификации.
     * @type {string}
     * @memberof TariffResultModel
     */
    'message': string;
}


/**
 * Модель результата правила тарификации.
 * @export
 * @interface TariffRuleResultModel
 */
export interface TariffRuleResultModel {
    /**
     * Наименование правила.
     * @type {string}
     * @memberof TariffRuleResultModel
     */
    'name': string;
    /**
     * 
     * @type {TariffStatus}
     * @memberof TariffRuleResultModel
     */
    'status': TariffStatus;
    /**
     * Сообщение тарификации.
     * @type {string}
     * @memberof TariffRuleResultModel
     */
    'message': string;
}


/**
 * Модель результата службы тарификации.
 * @export
 * @interface TariffServiceResultModel
 */
export interface TariffServiceResultModel {
    /**
     * Код тарифа.
     * @type {string}
     * @memberof TariffServiceResultModel
     */
    'code': string;
    /**
     * Результаты правил.
     * @type {Array<ITariffRuleResult>}
     * @memberof TariffServiceResultModel
     */
    'rules': Array<ITariffRuleResult>;
    /**
     * 
     * @type {TariffStatus}
     * @memberof TariffServiceResultModel
     */
    'status': TariffStatus;
    /**
     * Сообщение тарификации.
     * @type {string}
     * @memberof TariffServiceResultModel
     */
    'message': string;
}


/**
 * 
 * @export
 * @enum {string}
 */

export const TariffStatus = {
    Unknown: 'Unknown',
    Allowed: 'Allowed',
    Forbidden: 'Forbidden'
} as const;

export type TariffStatus = typeof TariffStatus[keyof typeof TariffStatus];


/**
 * 
 * @export
 * @interface TenantModel
 */
export interface TenantModel {
    /**
     * 
     * @type {string}
     * @memberof TenantModel
     */
    'id'?: string | null;
}
/**
 * 
 * @export
 * @interface TerminalTriggerSchema
 */
export interface TerminalTriggerSchema extends TriggerSchema {
    [key: string]: any;

    /**
     * 
     * @type {SchemaKind}
     * @memberof TerminalTriggerSchema
     */
    '$kind': SchemaKind;
}


/**
 * Запрос на получение score для фраз в интенте.
 * @export
 * @interface TestInstanceIntentScoresRequest
 */
export interface TestInstanceIntentScoresRequest {
    /**
     * ИД агента.
     * @type {string}
     * @memberof TestInstanceIntentScoresRequest
     */
    'agentId': string;
    /**
     * ИД версии бота.
     * @type {string}
     * @memberof TestInstanceIntentScoresRequest
     */
    'botVersionId': string;
    /**
     * ИД диалога внутри бота.
     * @type {string}
     * @memberof TestInstanceIntentScoresRequest
     */
    'dialogId': string;
    /**
     * Название интента.
     * @type {string}
     * @memberof TestInstanceIntentScoresRequest
     */
    'intentName': string;
    /**
     * Список фраз для получения score.
     * @type {Array<OmegaScoreQuery>}
     * @memberof TestInstanceIntentScoresRequest
     */
    'queries'?: Array<OmegaScoreQuery> | null;
}
/**
 * Результат расчета score для фраз в интенте
 * @export
 * @interface TestInstanceIntentScoresResponse
 */
export interface TestInstanceIntentScoresResponse {
    /**
     * Список фраз со score
     * @type {Array<OmegaScoreQuery>}
     * @memberof TestInstanceIntentScoresResponse
     */
    'queries'?: Array<OmegaScoreQuery> | null;
}
/**
 * Состояние тестового бота.
 * @export
 * @interface TestInstanceResponse
 */
export interface TestInstanceResponse {
    /**
     * Ссылка для тестирования в эмуляторе.
     * @type {string}
     * @memberof TestInstanceResponse
     */
    'emulatorUrl'?: string | null;
    /**
     * Ссылка для тестирования в веб-чате.
     * @type {string}
     * @memberof TestInstanceResponse
     */
    'callbackUrl'?: string | null;
    /**
     * 
     * @type {TrainResult}
     * @memberof TestInstanceResponse
     */
    'trainResult'?: TrainResult;
}
/**
 * Запрос на запуск тестового бота.
 * @export
 * @interface TestInstanceRunningRequest
 */
export interface TestInstanceRunningRequest {
    /**
     * ИД агента.
     * @type {string}
     * @memberof TestInstanceRunningRequest
     */
    'agentId': string;
    /**
     * ИД версии бота.
     * @type {string}
     * @memberof TestInstanceRunningRequest
     */
    'botVersionId': string;
    /**
     * Архив с ботом.
     * @type {string}
     * @memberof TestInstanceRunningRequest
     */
    'botZip': string;
}
/**
 * 
 * @export
 * @interface TextInputSchema
 */
export interface TextInputSchema extends InputActionSchema {
    [key: string]: any;

    /**
     * 
     * @type {SchemaKind}
     * @memberof TextInputSchema
     */
    '$kind': SchemaKind;
}


/**
 * 
 * @export
 * @interface TextOutputSchema
 */
export interface TextOutputSchema extends OutputActionSchema {
    [key: string]: any;

    /**
     * 
     * @type {SchemaKind}
     * @memberof TextOutputSchema
     */
    '$kind': SchemaKind;
}


/**
 * 
 * @export
 * @interface TextValueComparisonValidatorSchema
 */
export interface TextValueComparisonValidatorSchema extends ValueComparisonValidatorSchema {
    [key: string]: any;

    /**
     * 
     * @type {SchemaKind}
     * @memberof TextValueComparisonValidatorSchema
     */
    '$kind': SchemaKind;
    /**
     * 
     * @type {string}
     * @memberof TextValueComparisonValidatorSchema
     */
    'value': string;
}


/**
 * 
 * @export
 * @interface TimerTriggerSchema
 */
export interface TimerTriggerSchema extends TriggerSchema {
    [key: string]: any;

    /**
     * 
     * @type {SchemaKind}
     * @memberof TimerTriggerSchema
     */
    '$kind': SchemaKind;
    /**
     * 
     * @type {string}
     * @memberof TimerTriggerSchema
     */
    'cron': string;
}


/**
 * Топ распознаваний.
 * @export
 * @interface TopIntentsModel
 */
export interface TopIntentsModel {
    /**
     * Топ интентов.
     * @type {Array<OmegaIntentCountModel>}
     * @memberof TopIntentsModel
     */
    'omegaIntents': Array<OmegaIntentCountModel>;
}
/**
 * 
 * @export
 * @interface TrainResult
 */
export interface TrainResult {
    /**
     * 
     * @type {TrainStatus}
     * @memberof TrainResult
     */
    'status'?: TrainStatus;
    /**
     * 
     * @type {string}
     * @memberof TrainResult
     */
    'errorMessage'?: string | null;
    /**
     * 
     * @type {Array<GeneratedDataItem>}
     * @memberof TrainResult
     */
    'generatedData'?: Array<GeneratedDataItem> | null;
}


/**
 * 
 * @export
 * @enum {string}
 */

export const TrainStatus = {
    Pending: 'Pending',
    Success: 'Success',
    Error: 'Error'
} as const;

export type TrainStatus = typeof TrainStatus[keyof typeof TrainStatus];


/**
 * 
 * @export
 * @interface TransferToOperatorSchema
 */
export interface TransferToOperatorSchema extends ActionSchema {
    [key: string]: any;

    /**
     * 
     * @type {SchemaKind}
     * @memberof TransferToOperatorSchema
     */
    '$kind': SchemaKind;
}


/**
 * 
 * @export
 * @interface TransitActionSchema
 */
export interface TransitActionSchema extends InputActionSchema {
    [key: string]: any;

    /**
     * 
     * @type {string}
     * @memberof TransitActionSchema
     */
    '$kind': string;
    /**
     * 
     * @type {Array<ButtonSchema>}
     * @memberof TransitActionSchema
     */
    'buttons': Array<ButtonSchema>;
}
/**
 * 
 * @export
 * @interface TransitionTriggerSchema
 */
export interface TransitionTriggerSchema extends TriggerSchema {
    [key: string]: any;

    /**
     * 
     * @type {SchemaKind}
     * @memberof TransitionTriggerSchema
     */
    '$kind': SchemaKind;
}


/**
 * 
 * @export
 * @interface TriggerGroupSchema
 */
export interface TriggerGroupSchema extends EntitySchema {
    [key: string]: any;

    /**
     * 
     * @type {string}
     * @memberof TriggerGroupSchema
     */
    '$kind': string;
    /**
     * 
     * @type {string}
     * @memberof TriggerGroupSchema
     */
    'name': string;
}
/**
 * 
 * @export
 * @interface TriggerSchema
 */
export interface TriggerSchema extends EntitySchema {
    [key: string]: any;

    /**
     * 
     * @type {string}
     * @memberof TriggerSchema
     */
    '$kind': string;
    /**
     * 
     * @type {string}
     * @memberof TriggerSchema
     */
    'outputBindingId'?: string | null;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const UnaryConditionOperator = {
    Empty: 'Empty',
    NotEmpty: 'NotEmpty'
} as const;

export type UnaryConditionOperator = typeof UnaryConditionOperator[keyof typeof UnaryConditionOperator];


/**
 * 
 * @export
 * @interface UnaryConditionSchema
 */
export interface UnaryConditionSchema extends ConditionSchema {
    [key: string]: any;

    /**
     * 
     * @type {SchemaKind}
     * @memberof UnaryConditionSchema
     */
    '$kind': SchemaKind;
    /**
     * 
     * @type {UnaryConditionOperator}
     * @memberof UnaryConditionSchema
     */
    'operator': UnaryConditionOperator;
    /**
     * 
     * @type {OperandSchema}
     * @memberof UnaryConditionSchema
     */
    'operand': OperandSchema;
}


/**
 * 
 * @export
 * @interface UnknownIntentTriggerSchema
 */
export interface UnknownIntentTriggerSchema extends TriggerSchema {
    [key: string]: any;

    /**
     * 
     * @type {SchemaKind}
     * @memberof UnknownIntentTriggerSchema
     */
    '$kind': SchemaKind;
}


/**
 * 
 * @export
 * @enum {string}
 */

export const UpdateBotOperationType = {
    Rename: 'Rename',
    UpdateKnowledgeBase: 'UpdateKnowledgeBase'
} as const;

export type UpdateBotOperationType = typeof UpdateBotOperationType[keyof typeof UpdateBotOperationType];


/**
 * 
 * @export
 * @enum {string}
 */

export const UpdateScenarioOperationType = {
    Rename: 'Rename',
    Edit: 'Edit',
    Import: 'Import',
    EnableDisable: 'EnableDisable',
    ChangeSettings: 'ChangeSettings'
} as const;

export type UpdateScenarioOperationType = typeof UpdateScenarioOperationType[keyof typeof UpdateScenarioOperationType];


/**
 * 
 * @export
 * @interface UserIntentReferenceSchema
 */
export interface UserIntentReferenceSchema extends IntentReferenceSchema {
    [key: string]: any;

    /**
     * 
     * @type {SchemaKind}
     * @memberof UserIntentReferenceSchema
     */
    '$kind': SchemaKind;
    /**
     * 
     * @type {string}
     * @memberof UserIntentReferenceSchema
     */
    'intentId': string;
}


/**
 * Результат отправки приглашения.
 * @export
 * @interface UserInvitationResult
 */
export interface UserInvitationResult {
    /**
     * 
     * @type {boolean}
     * @memberof UserInvitationResult
     */
    'success'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof UserInvitationResult
     */
    'errorMessage'?: string | null;
}
/**
 * Модель информации о пользователе из внешнего поставщика.
 * @export
 * @interface UserLoginInfoModel
 */
export interface UserLoginInfoModel {
    /**
     * ИД внешнего поставщика.
     * @type {string}
     * @memberof UserLoginInfoModel
     */
    'loginProvider': string;
    /**
     * ИД пользователя в поставщике.
     * @type {string}
     * @memberof UserLoginInfoModel
     */
    'providerKey': string;
    /**
     * Отображаемое имя пользователя в поставщике.
     * @type {string}
     * @memberof UserLoginInfoModel
     */
    'providerDisplayName'?: string | null;
}
/**
 * Модель пользователя.
 * @export
 * @interface UserModel
 */
export interface UserModel {
    /**
     * ИД пользователя.
     * @type {string}
     * @memberof UserModel
     */
    'id': string;
    /**
     * Логин пользователя.
     * @type {string}
     * @memberof UserModel
     */
    'login': string;
    /**
     * Пароль пользователя.
     * @type {string}
     * @memberof UserModel
     */
    'password': string;
    /**
     * Email пользователя.
     * @type {string}
     * @memberof UserModel
     */
    'email': string;
    /**
     * Номер телефона пользователя.
     * @type {string}
     * @memberof UserModel
     */
    'phoneNumber': string;
    /**
     * Полное имя пользователя.
     * @type {string}
     * @memberof UserModel
     */
    'fullName': string;
    /**
     * Краткое имя пользователя.
     * @type {string}
     * @memberof UserModel
     */
    'shortName': string;
    /**
     * Фамилия пользователя.
     * @type {string}
     * @memberof UserModel
     */
    'familyName': string;
    /**
     * Имя пользователя.
     * @type {string}
     * @memberof UserModel
     */
    'givenName': string;
    /**
     * Отчество пользователя.
     * @type {string}
     * @memberof UserModel
     */
    'middleName'?: string | null;
    /**
     * Наименование тенантов пользователя.
     * @type {string}
     * @memberof UserModel
     */
    'tenantNames': string;
    /**
     * Наименования ролей пользователя.
     * @type {Array<RoleModel>}
     * @memberof UserModel
     */
    'roles': Array<RoleModel>;
    /**
     * Информация о связанных пользователях из внешних поставщиков.
     * @type {Array<UserLoginInfoModel>}
     * @memberof UserModel
     */
    'logins': Array<UserLoginInfoModel>;
    /**
     * Дата создания.
     * @type {string}
     * @memberof UserModel
     */
    'createdOn': string;
    /**
     * 
     * @type {UserStatus}
     * @memberof UserModel
     */
    'status': UserStatus;
    /**
     * 
     * @type {UserInvitationResult}
     * @memberof UserModel
     */
    'invitationResult'?: UserInvitationResult;
}


/**
 * 
 * @export
 * @interface UserReferenceModel
 */
export interface UserReferenceModel {
    /**
     * ИД пользователя.
     * @type {string}
     * @memberof UserReferenceModel
     */
    'userId': string;
    /**
     * Полное имя пользователя.
     * @type {string}
     * @memberof UserReferenceModel
     */
    'fullName': string;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const UserStatus = {
    Invited: 'Invited',
    Active: 'Active',
    Blocked: 'Blocked'
} as const;

export type UserStatus = typeof UserStatus[keyof typeof UserStatus];


/**
 * 
 * @export
 * @interface ValidationProblemDetails
 */
export interface ValidationProblemDetails {
    [key: string]: any;

    /**
     * 
     * @type {{ [key: string]: Array<string>; }}
     * @memberof ValidationProblemDetails
     */
    'errors'?: { [key: string]: Array<string>; } | null;
    /**
     * 
     * @type {string}
     * @memberof ValidationProblemDetails
     */
    'type'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ValidationProblemDetails
     */
    'title'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ValidationProblemDetails
     */
    'status'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ValidationProblemDetails
     */
    'detail'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ValidationProblemDetails
     */
    'instance'?: string | null;
}
/**
 * 
 * @export
 * @interface ValidationRuleResult
 */
export interface ValidationRuleResult {
    /**
     * 
     * @type {string}
     * @memberof ValidationRuleResult
     */
    'code': string;
    /**
     * 
     * @type {ValidationRuleStatus}
     * @memberof ValidationRuleResult
     */
    'status': ValidationRuleStatus;
    /**
     * 
     * @type {string}
     * @memberof ValidationRuleResult
     */
    'message': string;
}


/**
 * 
 * @export
 * @enum {string}
 */

export const ValidationRuleStatus = {
    Validating: 'Validating',
    Success: 'Success',
    Warning: 'Warning',
    Error: 'Error'
} as const;

export type ValidationRuleStatus = typeof ValidationRuleStatus[keyof typeof ValidationRuleStatus];


/**
 * 
 * @export
 * @interface ValidatorSchema
 */
export interface ValidatorSchema extends EntitySchema {
    [key: string]: any;

    /**
     * 
     * @type {string}
     * @memberof ValidatorSchema
     */
    '$kind': string;
}
/**
 * 
 * @export
 * @interface ValueComparisonValidatorSchema
 */
export interface ValueComparisonValidatorSchema extends ComparisonValidatorSchema {
    [key: string]: any;

    /**
     * 
     * @type {string}
     * @memberof ValueComparisonValidatorSchema
     */
    '$kind': string;
    /**
     * 
     * @type {any}
     * @memberof ValueComparisonValidatorSchema
     */
    'value': any;
}
/**
 * 
 * @export
 * @interface VariableOperandSchema
 */
export interface VariableOperandSchema extends OperandSchema {
    [key: string]: any;

    /**
     * 
     * @type {SchemaKind}
     * @memberof VariableOperandSchema
     */
    '$kind': SchemaKind;
    /**
     * 
     * @type {string}
     * @memberof VariableOperandSchema
     */
    'variableId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof VariableOperandSchema
     */
    'propertyPath'?: string | null;
}


/**
 * 
 * @export
 * @enum {string}
 */

export const VariableOwner = {
    User: 'User',
    System: 'System'
} as const;

export type VariableOwner = typeof VariableOwner[keyof typeof VariableOwner];


/**
 * 
 * @export
 * @interface VariableSchema
 */
export interface VariableSchema extends EntitySchema {
    [key: string]: any;

    /**
     * 
     * @type {SchemaKind}
     * @memberof VariableSchema
     */
    '$kind': SchemaKind;
    /**
     * 
     * @type {string}
     * @memberof VariableSchema
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof VariableSchema
     */
    'code': string;
    /**
     * 
     * @type {VariableType}
     * @memberof VariableSchema
     */
    'type': VariableType;
    /**
     * 
     * @type {VariableScope}
     * @memberof VariableSchema
     */
    'scope': VariableScope;
    /**
     * 
     * @type {VariableState}
     * @memberof VariableSchema
     */
    'state': VariableState;
    /**
     * 
     * @type {VariableOwner}
     * @memberof VariableSchema
     */
    'owner': VariableOwner;
    /**
     * 
     * @type {Array<ValidatorSchema>}
     * @memberof VariableSchema
     */
    'validators': Array<ValidatorSchema>;
}


/**
 * 
 * @export
 * @enum {string}
 */

export const VariableScope = {
    Scenario: 'Scenario',
    Bot: 'Bot',
    User: 'User',
    Session: 'Session'
} as const;

export type VariableScope = typeof VariableScope[keyof typeof VariableScope];


/**
 * 
 * @export
 * @enum {string}
 */

export const VariableState = {
    Exists: 'Exists',
    Deleted: 'Deleted'
} as const;

export type VariableState = typeof VariableState[keyof typeof VariableState];


/**
 * 
 * @export
 * @enum {string}
 */

export const VariableType = {
    String: 'String',
    Number: 'Number',
    Boolean: 'Boolean',
    DateTime: 'DateTime',
    PersonName: 'PersonName',
    ComplexObject: 'ComplexObject',
    SessionProfile: 'SessionProfile',
    File: 'File',
    PersonContacts: 'PersonContacts'
} as const;

export type VariableType = typeof VariableType[keyof typeof VariableType];



/**
 * AccountContactApi - axios parameter creator
 * @export
 */
export const AccountContactApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Получить контакт аккаунта.
         * @param {string} id ИД контакта аккаунта.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAccountContact: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getAccountContact', 'id', id)
            const localVarPath = `/api/v1/accounts/contacts/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth20 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth20", ["elma.assistant.resources.identity.context", "elma.assistant.scopes.api.admin", "email", "offline_access", "openid", "phone", "profile"], configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Выполнить поиск контактов аккаунтов.
         * @param {Array<string>} [channelIds] ИД каналов.
         * @param {Array<string>} [agentStageIds] ИД стадиий ботов.
         * @param {Array<string>} [personIds] ИД сотрудников.
         * @param {Array<string>} [agentStageAccountIds] ИД аккаунтов стадий ботов.
         * @param {Array<string>} [personAccountIds] ИД аккаунтов сотрудников.
         * @param {Array<string>} [externalUserIds] Список внешних идентификаторов.
         * @param {number} [pageIndex] 
         * @param {number} [pageSize] 
         * @param {boolean} [withTotal] 
         * @param {number} [fromItemInclusive] 
         * @param {number} [toItemExclusive] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchAccountContacts: async (channelIds?: Array<string>, agentStageIds?: Array<string>, personIds?: Array<string>, agentStageAccountIds?: Array<string>, personAccountIds?: Array<string>, externalUserIds?: Array<string>, pageIndex?: number, pageSize?: number, withTotal?: boolean, fromItemInclusive?: number, toItemExclusive?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/accounts/contacts`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth20 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth20", ["elma.assistant.resources.identity.context", "elma.assistant.scopes.api.admin", "email", "offline_access", "openid", "phone", "profile"], configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (channelIds) {
                localVarQueryParameter['ChannelIds'] = channelIds;
            }

            if (agentStageIds) {
                localVarQueryParameter['AgentStageIds'] = agentStageIds;
            }

            if (personIds) {
                localVarQueryParameter['PersonIds'] = personIds;
            }

            if (agentStageAccountIds) {
                localVarQueryParameter['AgentStageAccountIds'] = agentStageAccountIds;
            }

            if (personAccountIds) {
                localVarQueryParameter['PersonAccountIds'] = personAccountIds;
            }

            if (externalUserIds) {
                localVarQueryParameter['ExternalUserIds'] = externalUserIds;
            }

            if (pageIndex !== undefined) {
                localVarQueryParameter['PageIndex'] = pageIndex;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['PageSize'] = pageSize;
            }

            if (withTotal !== undefined) {
                localVarQueryParameter['WithTotal'] = withTotal;
            }

            if (fromItemInclusive !== undefined) {
                localVarQueryParameter['FromItemInclusive'] = fromItemInclusive;
            }

            if (toItemExclusive !== undefined) {
                localVarQueryParameter['ToItemExclusive'] = toItemExclusive;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AccountContactApi - functional programming interface
 * @export
 */
export const AccountContactApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AccountContactApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Получить контакт аккаунта.
         * @param {string} id ИД контакта аккаунта.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAccountContact(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AccountContactModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAccountContact(id, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['AccountContactApi.getAccountContact']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Выполнить поиск контактов аккаунтов.
         * @param {Array<string>} [channelIds] ИД каналов.
         * @param {Array<string>} [agentStageIds] ИД стадиий ботов.
         * @param {Array<string>} [personIds] ИД сотрудников.
         * @param {Array<string>} [agentStageAccountIds] ИД аккаунтов стадий ботов.
         * @param {Array<string>} [personAccountIds] ИД аккаунтов сотрудников.
         * @param {Array<string>} [externalUserIds] Список внешних идентификаторов.
         * @param {number} [pageIndex] 
         * @param {number} [pageSize] 
         * @param {boolean} [withTotal] 
         * @param {number} [fromItemInclusive] 
         * @param {number} [toItemExclusive] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async searchAccountContacts(channelIds?: Array<string>, agentStageIds?: Array<string>, personIds?: Array<string>, agentStageAccountIds?: Array<string>, personAccountIds?: Array<string>, externalUserIds?: Array<string>, pageIndex?: number, pageSize?: number, withTotal?: boolean, fromItemInclusive?: number, toItemExclusive?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AccountContactModelPaginationResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.searchAccountContacts(channelIds, agentStageIds, personIds, agentStageAccountIds, personAccountIds, externalUserIds, pageIndex, pageSize, withTotal, fromItemInclusive, toItemExclusive, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['AccountContactApi.searchAccountContacts']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
    }
};

/**
 * AccountContactApi - factory interface
 * @export
 */
export const AccountContactApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AccountContactApiFp(configuration)
    return {
        /**
         * 
         * @summary Получить контакт аккаунта.
         * @param {string} id ИД контакта аккаунта.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAccountContact(id: string, options?: any): AxiosPromise<AccountContactModel> {
            return localVarFp.getAccountContact(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Выполнить поиск контактов аккаунтов.
         * @param {Array<string>} [channelIds] ИД каналов.
         * @param {Array<string>} [agentStageIds] ИД стадиий ботов.
         * @param {Array<string>} [personIds] ИД сотрудников.
         * @param {Array<string>} [agentStageAccountIds] ИД аккаунтов стадий ботов.
         * @param {Array<string>} [personAccountIds] ИД аккаунтов сотрудников.
         * @param {Array<string>} [externalUserIds] Список внешних идентификаторов.
         * @param {number} [pageIndex] 
         * @param {number} [pageSize] 
         * @param {boolean} [withTotal] 
         * @param {number} [fromItemInclusive] 
         * @param {number} [toItemExclusive] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchAccountContacts(channelIds?: Array<string>, agentStageIds?: Array<string>, personIds?: Array<string>, agentStageAccountIds?: Array<string>, personAccountIds?: Array<string>, externalUserIds?: Array<string>, pageIndex?: number, pageSize?: number, withTotal?: boolean, fromItemInclusive?: number, toItemExclusive?: number, options?: any): AxiosPromise<AccountContactModelPaginationResponse> {
            return localVarFp.searchAccountContacts(channelIds, agentStageIds, personIds, agentStageAccountIds, personAccountIds, externalUserIds, pageIndex, pageSize, withTotal, fromItemInclusive, toItemExclusive, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AccountContactApi - object-oriented interface
 * @export
 * @class AccountContactApi
 * @extends {BaseAPI}
 */
export class AccountContactApi extends BaseAPI {
    /**
     * 
     * @summary Получить контакт аккаунта.
     * @param {string} id ИД контакта аккаунта.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountContactApi
     */
    public getAccountContact(id: string, options?: RawAxiosRequestConfig) {
        return AccountContactApiFp(this.configuration).getAccountContact(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Выполнить поиск контактов аккаунтов.
     * @param {Array<string>} [channelIds] ИД каналов.
     * @param {Array<string>} [agentStageIds] ИД стадиий ботов.
     * @param {Array<string>} [personIds] ИД сотрудников.
     * @param {Array<string>} [agentStageAccountIds] ИД аккаунтов стадий ботов.
     * @param {Array<string>} [personAccountIds] ИД аккаунтов сотрудников.
     * @param {Array<string>} [externalUserIds] Список внешних идентификаторов.
     * @param {number} [pageIndex] 
     * @param {number} [pageSize] 
     * @param {boolean} [withTotal] 
     * @param {number} [fromItemInclusive] 
     * @param {number} [toItemExclusive] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountContactApi
     */
    public searchAccountContacts(channelIds?: Array<string>, agentStageIds?: Array<string>, personIds?: Array<string>, agentStageAccountIds?: Array<string>, personAccountIds?: Array<string>, externalUserIds?: Array<string>, pageIndex?: number, pageSize?: number, withTotal?: boolean, fromItemInclusive?: number, toItemExclusive?: number, options?: RawAxiosRequestConfig) {
        return AccountContactApiFp(this.configuration).searchAccountContacts(channelIds, agentStageIds, personIds, agentStageAccountIds, personAccountIds, externalUserIds, pageIndex, pageSize, withTotal, fromItemInclusive, toItemExclusive, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * ActivityApi - axios parameter creator
 * @export
 */
export const ActivityApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Экспортировать активности.
         * @param {string} [channelId] ИД канала.
         * @param {string} [agentStageId] ИД стадии бота.
         * @param {string} [personId] ИД сотрудника.
         * @param {string} [conversationId] ИД беседы.
         * @param {ActivityDirection} [direction] Направление активности.
         * @param {Array<string>} [types] Типы активностей.
         * @param {string} [name] Наименование активности.
         * @param {boolean} [excludeInternal] Не включать в результаты внутренние сообщения между операторами.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        exportActivities: async (channelId?: string, agentStageId?: string, personId?: string, conversationId?: string, direction?: ActivityDirection, types?: Array<string>, name?: string, excludeInternal?: boolean, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/runtime/activities/export`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth20 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth20", ["elma.assistant.resources.identity.context", "elma.assistant.scopes.api.admin", "email", "offline_access", "openid", "phone", "profile"], configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (channelId !== undefined) {
                localVarQueryParameter['ChannelId'] = channelId;
            }

            if (agentStageId !== undefined) {
                localVarQueryParameter['AgentStageId'] = agentStageId;
            }

            if (personId !== undefined) {
                localVarQueryParameter['PersonId'] = personId;
            }

            if (conversationId !== undefined) {
                localVarQueryParameter['ConversationId'] = conversationId;
            }

            if (direction !== undefined) {
                localVarQueryParameter['Direction'] = direction;
            }

            if (types) {
                localVarQueryParameter['Types'] = types;
            }

            if (name !== undefined) {
                localVarQueryParameter['Name'] = name;
            }

            if (excludeInternal !== undefined) {
                localVarQueryParameter['ExcludeInternal'] = excludeInternal;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Получить активность.
         * @param {string} id ИД активности.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getActivity: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getActivity', 'id', id)
            const localVarPath = `/api/v1/runtime/activities/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth20 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth20", ["elma.assistant.resources.identity.context", "elma.assistant.scopes.api.admin", "email", "offline_access", "openid", "phone", "profile"], configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Выполнить поиск активностей.
         * @param {string} [channelId] ИД канала.
         * @param {string} [agentStageId] ИД стадии бота.
         * @param {string} [personId] ИД сотрудника.
         * @param {string} [conversationId] ИД беседы.
         * @param {ActivityDirection} [direction] Направление активности.
         * @param {Array<string>} [types] Типы активностей.
         * @param {string} [name] Наименование активности.
         * @param {boolean} [excludeInternal] Не включать в результаты внутренние сообщения между операторами.
         * @param {number} [pageIndex] 
         * @param {number} [pageSize] 
         * @param {boolean} [withTotal] 
         * @param {number} [fromItemInclusive] 
         * @param {number} [toItemExclusive] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchActivities: async (channelId?: string, agentStageId?: string, personId?: string, conversationId?: string, direction?: ActivityDirection, types?: Array<string>, name?: string, excludeInternal?: boolean, pageIndex?: number, pageSize?: number, withTotal?: boolean, fromItemInclusive?: number, toItemExclusive?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/runtime/activities`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth20 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth20", ["elma.assistant.resources.identity.context", "elma.assistant.scopes.api.admin", "email", "offline_access", "openid", "phone", "profile"], configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (channelId !== undefined) {
                localVarQueryParameter['ChannelId'] = channelId;
            }

            if (agentStageId !== undefined) {
                localVarQueryParameter['AgentStageId'] = agentStageId;
            }

            if (personId !== undefined) {
                localVarQueryParameter['PersonId'] = personId;
            }

            if (conversationId !== undefined) {
                localVarQueryParameter['ConversationId'] = conversationId;
            }

            if (direction !== undefined) {
                localVarQueryParameter['Direction'] = direction;
            }

            if (types) {
                localVarQueryParameter['Types'] = types;
            }

            if (name !== undefined) {
                localVarQueryParameter['Name'] = name;
            }

            if (excludeInternal !== undefined) {
                localVarQueryParameter['ExcludeInternal'] = excludeInternal;
            }

            if (pageIndex !== undefined) {
                localVarQueryParameter['PageIndex'] = pageIndex;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['PageSize'] = pageSize;
            }

            if (withTotal !== undefined) {
                localVarQueryParameter['WithTotal'] = withTotal;
            }

            if (fromItemInclusive !== undefined) {
                localVarQueryParameter['FromItemInclusive'] = fromItemInclusive;
            }

            if (toItemExclusive !== undefined) {
                localVarQueryParameter['ToItemExclusive'] = toItemExclusive;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Выполнить поиск активностей.
         * @param {string} [channelId] ИД канала.
         * @param {string} [agentStageId] ИД стадии бота.
         * @param {string} [personId] ИД сотрудника.
         * @param {string} [conversationId] ИД беседы.
         * @param {ActivityDirection} [direction] Направление активности.
         * @param {Array<string>} [types] Типы активностей.
         * @param {string} [name] Наименование активности.
         * @param {boolean} [excludeInternal] Не включать в результаты внутренние сообщения между операторами.
         * @param {ActivitySortDirection} [sorting] Параметры сортировки.
         * @param {number} [pageSize] 
         * @param {string} [referenceId] 
         * @param {string} [referenceCreatedOn] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchActivitiesByKeyset: async (channelId?: string, agentStageId?: string, personId?: string, conversationId?: string, direction?: ActivityDirection, types?: Array<string>, name?: string, excludeInternal?: boolean, sorting?: ActivitySortDirection, pageSize?: number, referenceId?: string, referenceCreatedOn?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/runtime/activities/by-keyset`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth20 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth20", ["elma.assistant.resources.identity.context", "elma.assistant.scopes.api.admin", "email", "offline_access", "openid", "phone", "profile"], configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (channelId !== undefined) {
                localVarQueryParameter['ChannelId'] = channelId;
            }

            if (agentStageId !== undefined) {
                localVarQueryParameter['AgentStageId'] = agentStageId;
            }

            if (personId !== undefined) {
                localVarQueryParameter['PersonId'] = personId;
            }

            if (conversationId !== undefined) {
                localVarQueryParameter['ConversationId'] = conversationId;
            }

            if (direction !== undefined) {
                localVarQueryParameter['Direction'] = direction;
            }

            if (types) {
                localVarQueryParameter['Types'] = types;
            }

            if (name !== undefined) {
                localVarQueryParameter['Name'] = name;
            }

            if (excludeInternal !== undefined) {
                localVarQueryParameter['ExcludeInternal'] = excludeInternal;
            }

            if (sorting !== undefined) {
                localVarQueryParameter['sorting'] = sorting;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['PageSize'] = pageSize;
            }

            if (referenceId !== undefined) {
                localVarQueryParameter['ReferenceId'] = referenceId;
            }

            if (referenceCreatedOn !== undefined) {
                localVarQueryParameter['ReferenceCreatedOn'] = (referenceCreatedOn as any instanceof Date) ?
                    (referenceCreatedOn as any).toISOString() :
                    referenceCreatedOn;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ActivityApi - functional programming interface
 * @export
 */
export const ActivityApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ActivityApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Экспортировать активности.
         * @param {string} [channelId] ИД канала.
         * @param {string} [agentStageId] ИД стадии бота.
         * @param {string} [personId] ИД сотрудника.
         * @param {string} [conversationId] ИД беседы.
         * @param {ActivityDirection} [direction] Направление активности.
         * @param {Array<string>} [types] Типы активностей.
         * @param {string} [name] Наименование активности.
         * @param {boolean} [excludeInternal] Не включать в результаты внутренние сообщения между операторами.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async exportActivities(channelId?: string, agentStageId?: string, personId?: string, conversationId?: string, direction?: ActivityDirection, types?: Array<string>, name?: string, excludeInternal?: boolean, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<File>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.exportActivities(channelId, agentStageId, personId, conversationId, direction, types, name, excludeInternal, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['ActivityApi.exportActivities']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Получить активность.
         * @param {string} id ИД активности.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getActivity(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ActivityModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getActivity(id, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['ActivityApi.getActivity']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Выполнить поиск активностей.
         * @param {string} [channelId] ИД канала.
         * @param {string} [agentStageId] ИД стадии бота.
         * @param {string} [personId] ИД сотрудника.
         * @param {string} [conversationId] ИД беседы.
         * @param {ActivityDirection} [direction] Направление активности.
         * @param {Array<string>} [types] Типы активностей.
         * @param {string} [name] Наименование активности.
         * @param {boolean} [excludeInternal] Не включать в результаты внутренние сообщения между операторами.
         * @param {number} [pageIndex] 
         * @param {number} [pageSize] 
         * @param {boolean} [withTotal] 
         * @param {number} [fromItemInclusive] 
         * @param {number} [toItemExclusive] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async searchActivities(channelId?: string, agentStageId?: string, personId?: string, conversationId?: string, direction?: ActivityDirection, types?: Array<string>, name?: string, excludeInternal?: boolean, pageIndex?: number, pageSize?: number, withTotal?: boolean, fromItemInclusive?: number, toItemExclusive?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ActivityModelPaginationResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.searchActivities(channelId, agentStageId, personId, conversationId, direction, types, name, excludeInternal, pageIndex, pageSize, withTotal, fromItemInclusive, toItemExclusive, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['ActivityApi.searchActivities']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Выполнить поиск активностей.
         * @param {string} [channelId] ИД канала.
         * @param {string} [agentStageId] ИД стадии бота.
         * @param {string} [personId] ИД сотрудника.
         * @param {string} [conversationId] ИД беседы.
         * @param {ActivityDirection} [direction] Направление активности.
         * @param {Array<string>} [types] Типы активностей.
         * @param {string} [name] Наименование активности.
         * @param {boolean} [excludeInternal] Не включать в результаты внутренние сообщения между операторами.
         * @param {ActivitySortDirection} [sorting] Параметры сортировки.
         * @param {number} [pageSize] 
         * @param {string} [referenceId] 
         * @param {string} [referenceCreatedOn] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async searchActivitiesByKeyset(channelId?: string, agentStageId?: string, personId?: string, conversationId?: string, direction?: ActivityDirection, types?: Array<string>, name?: string, excludeInternal?: boolean, sorting?: ActivitySortDirection, pageSize?: number, referenceId?: string, referenceCreatedOn?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ActivityModelKeysetPaginationResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.searchActivitiesByKeyset(channelId, agentStageId, personId, conversationId, direction, types, name, excludeInternal, sorting, pageSize, referenceId, referenceCreatedOn, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['ActivityApi.searchActivitiesByKeyset']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
    }
};

/**
 * ActivityApi - factory interface
 * @export
 */
export const ActivityApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ActivityApiFp(configuration)
    return {
        /**
         * 
         * @summary Экспортировать активности.
         * @param {string} [channelId] ИД канала.
         * @param {string} [agentStageId] ИД стадии бота.
         * @param {string} [personId] ИД сотрудника.
         * @param {string} [conversationId] ИД беседы.
         * @param {ActivityDirection} [direction] Направление активности.
         * @param {Array<string>} [types] Типы активностей.
         * @param {string} [name] Наименование активности.
         * @param {boolean} [excludeInternal] Не включать в результаты внутренние сообщения между операторами.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        exportActivities(channelId?: string, agentStageId?: string, personId?: string, conversationId?: string, direction?: ActivityDirection, types?: Array<string>, name?: string, excludeInternal?: boolean, options?: any): AxiosPromise<File> {
            return localVarFp.exportActivities(channelId, agentStageId, personId, conversationId, direction, types, name, excludeInternal, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Получить активность.
         * @param {string} id ИД активности.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getActivity(id: string, options?: any): AxiosPromise<ActivityModel> {
            return localVarFp.getActivity(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Выполнить поиск активностей.
         * @param {string} [channelId] ИД канала.
         * @param {string} [agentStageId] ИД стадии бота.
         * @param {string} [personId] ИД сотрудника.
         * @param {string} [conversationId] ИД беседы.
         * @param {ActivityDirection} [direction] Направление активности.
         * @param {Array<string>} [types] Типы активностей.
         * @param {string} [name] Наименование активности.
         * @param {boolean} [excludeInternal] Не включать в результаты внутренние сообщения между операторами.
         * @param {number} [pageIndex] 
         * @param {number} [pageSize] 
         * @param {boolean} [withTotal] 
         * @param {number} [fromItemInclusive] 
         * @param {number} [toItemExclusive] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchActivities(channelId?: string, agentStageId?: string, personId?: string, conversationId?: string, direction?: ActivityDirection, types?: Array<string>, name?: string, excludeInternal?: boolean, pageIndex?: number, pageSize?: number, withTotal?: boolean, fromItemInclusive?: number, toItemExclusive?: number, options?: any): AxiosPromise<ActivityModelPaginationResponse> {
            return localVarFp.searchActivities(channelId, agentStageId, personId, conversationId, direction, types, name, excludeInternal, pageIndex, pageSize, withTotal, fromItemInclusive, toItemExclusive, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Выполнить поиск активностей.
         * @param {string} [channelId] ИД канала.
         * @param {string} [agentStageId] ИД стадии бота.
         * @param {string} [personId] ИД сотрудника.
         * @param {string} [conversationId] ИД беседы.
         * @param {ActivityDirection} [direction] Направление активности.
         * @param {Array<string>} [types] Типы активностей.
         * @param {string} [name] Наименование активности.
         * @param {boolean} [excludeInternal] Не включать в результаты внутренние сообщения между операторами.
         * @param {ActivitySortDirection} [sorting] Параметры сортировки.
         * @param {number} [pageSize] 
         * @param {string} [referenceId] 
         * @param {string} [referenceCreatedOn] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchActivitiesByKeyset(channelId?: string, agentStageId?: string, personId?: string, conversationId?: string, direction?: ActivityDirection, types?: Array<string>, name?: string, excludeInternal?: boolean, sorting?: ActivitySortDirection, pageSize?: number, referenceId?: string, referenceCreatedOn?: string, options?: any): AxiosPromise<ActivityModelKeysetPaginationResponse> {
            return localVarFp.searchActivitiesByKeyset(channelId, agentStageId, personId, conversationId, direction, types, name, excludeInternal, sorting, pageSize, referenceId, referenceCreatedOn, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ActivityApi - object-oriented interface
 * @export
 * @class ActivityApi
 * @extends {BaseAPI}
 */
export class ActivityApi extends BaseAPI {
    /**
     * 
     * @summary Экспортировать активности.
     * @param {string} [channelId] ИД канала.
     * @param {string} [agentStageId] ИД стадии бота.
     * @param {string} [personId] ИД сотрудника.
     * @param {string} [conversationId] ИД беседы.
     * @param {ActivityDirection} [direction] Направление активности.
     * @param {Array<string>} [types] Типы активностей.
     * @param {string} [name] Наименование активности.
     * @param {boolean} [excludeInternal] Не включать в результаты внутренние сообщения между операторами.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ActivityApi
     */
    public exportActivities(channelId?: string, agentStageId?: string, personId?: string, conversationId?: string, direction?: ActivityDirection, types?: Array<string>, name?: string, excludeInternal?: boolean, options?: RawAxiosRequestConfig) {
        return ActivityApiFp(this.configuration).exportActivities(channelId, agentStageId, personId, conversationId, direction, types, name, excludeInternal, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Получить активность.
     * @param {string} id ИД активности.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ActivityApi
     */
    public getActivity(id: string, options?: RawAxiosRequestConfig) {
        return ActivityApiFp(this.configuration).getActivity(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Выполнить поиск активностей.
     * @param {string} [channelId] ИД канала.
     * @param {string} [agentStageId] ИД стадии бота.
     * @param {string} [personId] ИД сотрудника.
     * @param {string} [conversationId] ИД беседы.
     * @param {ActivityDirection} [direction] Направление активности.
     * @param {Array<string>} [types] Типы активностей.
     * @param {string} [name] Наименование активности.
     * @param {boolean} [excludeInternal] Не включать в результаты внутренние сообщения между операторами.
     * @param {number} [pageIndex] 
     * @param {number} [pageSize] 
     * @param {boolean} [withTotal] 
     * @param {number} [fromItemInclusive] 
     * @param {number} [toItemExclusive] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ActivityApi
     */
    public searchActivities(channelId?: string, agentStageId?: string, personId?: string, conversationId?: string, direction?: ActivityDirection, types?: Array<string>, name?: string, excludeInternal?: boolean, pageIndex?: number, pageSize?: number, withTotal?: boolean, fromItemInclusive?: number, toItemExclusive?: number, options?: RawAxiosRequestConfig) {
        return ActivityApiFp(this.configuration).searchActivities(channelId, agentStageId, personId, conversationId, direction, types, name, excludeInternal, pageIndex, pageSize, withTotal, fromItemInclusive, toItemExclusive, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Выполнить поиск активностей.
     * @param {string} [channelId] ИД канала.
     * @param {string} [agentStageId] ИД стадии бота.
     * @param {string} [personId] ИД сотрудника.
     * @param {string} [conversationId] ИД беседы.
     * @param {ActivityDirection} [direction] Направление активности.
     * @param {Array<string>} [types] Типы активностей.
     * @param {string} [name] Наименование активности.
     * @param {boolean} [excludeInternal] Не включать в результаты внутренние сообщения между операторами.
     * @param {ActivitySortDirection} [sorting] Параметры сортировки.
     * @param {number} [pageSize] 
     * @param {string} [referenceId] 
     * @param {string} [referenceCreatedOn] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ActivityApi
     */
    public searchActivitiesByKeyset(channelId?: string, agentStageId?: string, personId?: string, conversationId?: string, direction?: ActivityDirection, types?: Array<string>, name?: string, excludeInternal?: boolean, sorting?: ActivitySortDirection, pageSize?: number, referenceId?: string, referenceCreatedOn?: string, options?: RawAxiosRequestConfig) {
        return ActivityApiFp(this.configuration).searchActivitiesByKeyset(channelId, agentStageId, personId, conversationId, direction, types, name, excludeInternal, sorting, pageSize, referenceId, referenceCreatedOn, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * AgentApi - axios parameter creator
 * @export
 */
export const AgentApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Создать бота.
         * @param {AgentCreationRequest} [agentCreationRequest] Запрос на создание бота.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createAgent: async (agentCreationRequest?: AgentCreationRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/runtime/agents`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth20 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth20", ["elma.assistant.resources.identity.context", "elma.assistant.scopes.api.admin", "email", "offline_access", "openid", "phone", "profile"], configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(agentCreationRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Удалить бота.
         * @param {string} id ИД бота.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAgent: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteAgent', 'id', id)
            const localVarPath = `/api/v1/runtime/agents/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth20 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth20", ["elma.assistant.resources.identity.context", "elma.assistant.scopes.api.admin", "email", "offline_access", "openid", "phone", "profile"], configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Получить бота.
         * @param {string} id ИД бота.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAgent: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getAgent', 'id', id)
            const localVarPath = `/api/v1/runtime/agents/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth20 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth20", ["elma.assistant.resources.identity.context", "elma.assistant.scopes.api.admin", "email", "offline_access", "openid", "phone", "profile"], configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Публикация версии бота.
         * @param {AgentPublishRequest} [agentPublishRequest] Запрос на публикацию.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        publishBotFromVersion: async (agentPublishRequest?: AgentPublishRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/runtime/agents/publish`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth20 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth20", ["elma.assistant.resources.identity.context", "elma.assistant.scopes.api.admin", "email", "offline_access", "openid", "phone", "profile"], configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(agentPublishRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Переобучение стадии бота.
         * @param {AgentRetrainRequest} [agentRetrainRequest] Запрос на переобучение.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retrain: async (agentRetrainRequest?: AgentRetrainRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/runtime/agents/retrain`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth20 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth20", ["elma.assistant.resources.identity.context", "elma.assistant.scopes.api.admin", "email", "offline_access", "openid", "phone", "profile"], configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(agentRetrainRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Выполнить поиск ботов.
         * @param {number} [pageIndex] 
         * @param {number} [pageSize] 
         * @param {boolean} [withTotal] 
         * @param {number} [fromItemInclusive] 
         * @param {number} [toItemExclusive] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchAgents: async (pageIndex?: number, pageSize?: number, withTotal?: boolean, fromItemInclusive?: number, toItemExclusive?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/runtime/agents`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth20 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth20", ["elma.assistant.resources.identity.context", "elma.assistant.scopes.api.admin", "email", "offline_access", "openid", "phone", "profile"], configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (pageIndex !== undefined) {
                localVarQueryParameter['PageIndex'] = pageIndex;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['PageSize'] = pageSize;
            }

            if (withTotal !== undefined) {
                localVarQueryParameter['WithTotal'] = withTotal;
            }

            if (fromItemInclusive !== undefined) {
                localVarQueryParameter['FromItemInclusive'] = fromItemInclusive;
            }

            if (toItemExclusive !== undefined) {
                localVarQueryParameter['ToItemExclusive'] = toItemExclusive;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Выполнить поиск проактивных диалогов.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchProactiveDialogs: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/runtime/agents/proactive-dialogs`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth20 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth20", ["elma.assistant.resources.identity.context", "elma.assistant.scopes.api.admin", "email", "offline_access", "openid", "phone", "profile"], configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Обновить бота.
         * @param {string} id ИД бота.
         * @param {AgentUpdatingRequest} [agentUpdatingRequest] Запрос на обновление бота.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAgent: async (id: string, agentUpdatingRequest?: AgentUpdatingRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateAgent', 'id', id)
            const localVarPath = `/api/v1/runtime/agents/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth20 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth20", ["elma.assistant.resources.identity.context", "elma.assistant.scopes.api.admin", "email", "offline_access", "openid", "phone", "profile"], configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(agentUpdatingRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AgentApi - functional programming interface
 * @export
 */
export const AgentApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AgentApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Создать бота.
         * @param {AgentCreationRequest} [agentCreationRequest] Запрос на создание бота.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createAgent(agentCreationRequest?: AgentCreationRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AgentCreationResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createAgent(agentCreationRequest, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['AgentApi.createAgent']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Удалить бота.
         * @param {string} id ИД бота.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteAgent(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteAgent(id, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['AgentApi.deleteAgent']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Получить бота.
         * @param {string} id ИД бота.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAgent(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AgentModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAgent(id, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['AgentApi.getAgent']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Публикация версии бота.
         * @param {AgentPublishRequest} [agentPublishRequest] Запрос на публикацию.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async publishBotFromVersion(agentPublishRequest?: AgentPublishRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.publishBotFromVersion(agentPublishRequest, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['AgentApi.publishBotFromVersion']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Переобучение стадии бота.
         * @param {AgentRetrainRequest} [agentRetrainRequest] Запрос на переобучение.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async retrain(agentRetrainRequest?: AgentRetrainRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.retrain(agentRetrainRequest, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['AgentApi.retrain']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Выполнить поиск ботов.
         * @param {number} [pageIndex] 
         * @param {number} [pageSize] 
         * @param {boolean} [withTotal] 
         * @param {number} [fromItemInclusive] 
         * @param {number} [toItemExclusive] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async searchAgents(pageIndex?: number, pageSize?: number, withTotal?: boolean, fromItemInclusive?: number, toItemExclusive?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AgentModelPaginationResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.searchAgents(pageIndex, pageSize, withTotal, fromItemInclusive, toItemExclusive, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['AgentApi.searchAgents']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Выполнить поиск проактивных диалогов.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async searchProactiveDialogs(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<AgentProactiveDialogModel>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.searchProactiveDialogs(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['AgentApi.searchProactiveDialogs']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Обновить бота.
         * @param {string} id ИД бота.
         * @param {AgentUpdatingRequest} [agentUpdatingRequest] Запрос на обновление бота.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateAgent(id: string, agentUpdatingRequest?: AgentUpdatingRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateAgent(id, agentUpdatingRequest, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['AgentApi.updateAgent']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
    }
};

/**
 * AgentApi - factory interface
 * @export
 */
export const AgentApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AgentApiFp(configuration)
    return {
        /**
         * 
         * @summary Создать бота.
         * @param {AgentCreationRequest} [agentCreationRequest] Запрос на создание бота.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createAgent(agentCreationRequest?: AgentCreationRequest, options?: any): AxiosPromise<AgentCreationResponse> {
            return localVarFp.createAgent(agentCreationRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Удалить бота.
         * @param {string} id ИД бота.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAgent(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteAgent(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Получить бота.
         * @param {string} id ИД бота.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAgent(id: string, options?: any): AxiosPromise<AgentModel> {
            return localVarFp.getAgent(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Публикация версии бота.
         * @param {AgentPublishRequest} [agentPublishRequest] Запрос на публикацию.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        publishBotFromVersion(agentPublishRequest?: AgentPublishRequest, options?: any): AxiosPromise<void> {
            return localVarFp.publishBotFromVersion(agentPublishRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Переобучение стадии бота.
         * @param {AgentRetrainRequest} [agentRetrainRequest] Запрос на переобучение.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retrain(agentRetrainRequest?: AgentRetrainRequest, options?: any): AxiosPromise<void> {
            return localVarFp.retrain(agentRetrainRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Выполнить поиск ботов.
         * @param {number} [pageIndex] 
         * @param {number} [pageSize] 
         * @param {boolean} [withTotal] 
         * @param {number} [fromItemInclusive] 
         * @param {number} [toItemExclusive] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchAgents(pageIndex?: number, pageSize?: number, withTotal?: boolean, fromItemInclusive?: number, toItemExclusive?: number, options?: any): AxiosPromise<AgentModelPaginationResponse> {
            return localVarFp.searchAgents(pageIndex, pageSize, withTotal, fromItemInclusive, toItemExclusive, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Выполнить поиск проактивных диалогов.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchProactiveDialogs(options?: any): AxiosPromise<Array<AgentProactiveDialogModel>> {
            return localVarFp.searchProactiveDialogs(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Обновить бота.
         * @param {string} id ИД бота.
         * @param {AgentUpdatingRequest} [agentUpdatingRequest] Запрос на обновление бота.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAgent(id: string, agentUpdatingRequest?: AgentUpdatingRequest, options?: any): AxiosPromise<object> {
            return localVarFp.updateAgent(id, agentUpdatingRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AgentApi - object-oriented interface
 * @export
 * @class AgentApi
 * @extends {BaseAPI}
 */
export class AgentApi extends BaseAPI {
    /**
     * 
     * @summary Создать бота.
     * @param {AgentCreationRequest} [agentCreationRequest] Запрос на создание бота.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AgentApi
     */
    public createAgent(agentCreationRequest?: AgentCreationRequest, options?: RawAxiosRequestConfig) {
        return AgentApiFp(this.configuration).createAgent(agentCreationRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Удалить бота.
     * @param {string} id ИД бота.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AgentApi
     */
    public deleteAgent(id: string, options?: RawAxiosRequestConfig) {
        return AgentApiFp(this.configuration).deleteAgent(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Получить бота.
     * @param {string} id ИД бота.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AgentApi
     */
    public getAgent(id: string, options?: RawAxiosRequestConfig) {
        return AgentApiFp(this.configuration).getAgent(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Публикация версии бота.
     * @param {AgentPublishRequest} [agentPublishRequest] Запрос на публикацию.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AgentApi
     */
    public publishBotFromVersion(agentPublishRequest?: AgentPublishRequest, options?: RawAxiosRequestConfig) {
        return AgentApiFp(this.configuration).publishBotFromVersion(agentPublishRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Переобучение стадии бота.
     * @param {AgentRetrainRequest} [agentRetrainRequest] Запрос на переобучение.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AgentApi
     */
    public retrain(agentRetrainRequest?: AgentRetrainRequest, options?: RawAxiosRequestConfig) {
        return AgentApiFp(this.configuration).retrain(agentRetrainRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Выполнить поиск ботов.
     * @param {number} [pageIndex] 
     * @param {number} [pageSize] 
     * @param {boolean} [withTotal] 
     * @param {number} [fromItemInclusive] 
     * @param {number} [toItemExclusive] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AgentApi
     */
    public searchAgents(pageIndex?: number, pageSize?: number, withTotal?: boolean, fromItemInclusive?: number, toItemExclusive?: number, options?: RawAxiosRequestConfig) {
        return AgentApiFp(this.configuration).searchAgents(pageIndex, pageSize, withTotal, fromItemInclusive, toItemExclusive, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Выполнить поиск проактивных диалогов.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AgentApi
     */
    public searchProactiveDialogs(options?: RawAxiosRequestConfig) {
        return AgentApiFp(this.configuration).searchProactiveDialogs(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Обновить бота.
     * @param {string} id ИД бота.
     * @param {AgentUpdatingRequest} [agentUpdatingRequest] Запрос на обновление бота.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AgentApi
     */
    public updateAgent(id: string, agentUpdatingRequest?: AgentUpdatingRequest, options?: RawAxiosRequestConfig) {
        return AgentApiFp(this.configuration).updateAgent(id, agentUpdatingRequest, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * AgentStageApi - axios parameter creator
 * @export
 */
export const AgentStageApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Получить стадию бота.
         * @param {string} id ИД стадии бота.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAgentStage: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getAgentStage', 'id', id)
            const localVarPath = `/api/v1/runtime/agentstages/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth20 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth20", ["elma.assistant.resources.identity.context", "elma.assistant.scopes.api.admin", "email", "offline_access", "openid", "phone", "profile"], configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Обновить стадию бота.
         * @param {string} id ИД бота.
         * @param {AgentStageUpdatingRequest} [agentStageUpdatingRequest] Запрос на обновление стадии бота.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAgentStage: async (id: string, agentStageUpdatingRequest?: AgentStageUpdatingRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateAgentStage', 'id', id)
            const localVarPath = `/api/v1/runtime/agentstages/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth20 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth20", ["elma.assistant.resources.identity.context", "elma.assistant.scopes.api.admin", "email", "offline_access", "openid", "phone", "profile"], configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(agentStageUpdatingRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AgentStageApi - functional programming interface
 * @export
 */
export const AgentStageApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AgentStageApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Получить стадию бота.
         * @param {string} id ИД стадии бота.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAgentStage(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AgentStageModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAgentStage(id, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['AgentStageApi.getAgentStage']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Обновить стадию бота.
         * @param {string} id ИД бота.
         * @param {AgentStageUpdatingRequest} [agentStageUpdatingRequest] Запрос на обновление стадии бота.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateAgentStage(id: string, agentStageUpdatingRequest?: AgentStageUpdatingRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateAgentStage(id, agentStageUpdatingRequest, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['AgentStageApi.updateAgentStage']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
    }
};

/**
 * AgentStageApi - factory interface
 * @export
 */
export const AgentStageApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AgentStageApiFp(configuration)
    return {
        /**
         * 
         * @summary Получить стадию бота.
         * @param {string} id ИД стадии бота.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAgentStage(id: string, options?: any): AxiosPromise<AgentStageModel> {
            return localVarFp.getAgentStage(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Обновить стадию бота.
         * @param {string} id ИД бота.
         * @param {AgentStageUpdatingRequest} [agentStageUpdatingRequest] Запрос на обновление стадии бота.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAgentStage(id: string, agentStageUpdatingRequest?: AgentStageUpdatingRequest, options?: any): AxiosPromise<object> {
            return localVarFp.updateAgentStage(id, agentStageUpdatingRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AgentStageApi - object-oriented interface
 * @export
 * @class AgentStageApi
 * @extends {BaseAPI}
 */
export class AgentStageApi extends BaseAPI {
    /**
     * 
     * @summary Получить стадию бота.
     * @param {string} id ИД стадии бота.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AgentStageApi
     */
    public getAgentStage(id: string, options?: RawAxiosRequestConfig) {
        return AgentStageApiFp(this.configuration).getAgentStage(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Обновить стадию бота.
     * @param {string} id ИД бота.
     * @param {AgentStageUpdatingRequest} [agentStageUpdatingRequest] Запрос на обновление стадии бота.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AgentStageApi
     */
    public updateAgentStage(id: string, agentStageUpdatingRequest?: AgentStageUpdatingRequest, options?: RawAxiosRequestConfig) {
        return AgentStageApiFp(this.configuration).updateAgentStage(id, agentStageUpdatingRequest, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * AgentStageAccountApi - axios parameter creator
 * @export
 */
export const AgentStageAccountApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Создать аккаунт бота.
         * @param {AgentStageAccountCreationRequest} [agentStageAccountCreationRequest] Запрос на создание аккаунта бота.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createAgentStageAccount: async (agentStageAccountCreationRequest?: AgentStageAccountCreationRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/accounts/agentstages`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth20 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth20", ["elma.assistant.resources.identity.context", "elma.assistant.scopes.api.admin", "email", "offline_access", "openid", "phone", "profile"], configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(agentStageAccountCreationRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Удалить аккаунт бота.
         * @param {string} id ИД аккаунт бота.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAgentStageAccount: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteAgentStageAccount', 'id', id)
            const localVarPath = `/api/v1/accounts/agentstages/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth20 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth20", ["elma.assistant.resources.identity.context", "elma.assistant.scopes.api.admin", "email", "offline_access", "openid", "phone", "profile"], configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Получить аккаунт бота.
         * @param {string} id ИД аккаунта бота.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAgentStageAccount: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getAgentStageAccount', 'id', id)
            const localVarPath = `/api/v1/accounts/agentstages/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth20 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth20", ["elma.assistant.resources.identity.context", "elma.assistant.scopes.api.admin", "email", "offline_access", "openid", "phone", "profile"], configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Выполнить поиск аккаунтов ботов.
         * @param {string} [channelId] ИД канала.
         * @param {string} [agentStageId] ИД стадии бота.
         * @param {string} [searchText] Текст для поиска.
         * @param {AgentStageAccountSortDirection} [sorting] Параметры сортировки.
         * @param {number} [pageIndex] 
         * @param {number} [pageSize] 
         * @param {boolean} [withTotal] 
         * @param {number} [fromItemInclusive] 
         * @param {number} [toItemExclusive] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchAgentStageAccounts: async (channelId?: string, agentStageId?: string, searchText?: string, sorting?: AgentStageAccountSortDirection, pageIndex?: number, pageSize?: number, withTotal?: boolean, fromItemInclusive?: number, toItemExclusive?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/accounts/agentstages`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth20 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth20", ["elma.assistant.resources.identity.context", "elma.assistant.scopes.api.admin", "email", "offline_access", "openid", "phone", "profile"], configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (channelId !== undefined) {
                localVarQueryParameter['ChannelId'] = channelId;
            }

            if (agentStageId !== undefined) {
                localVarQueryParameter['AgentStageId'] = agentStageId;
            }

            if (searchText !== undefined) {
                localVarQueryParameter['SearchText'] = searchText;
            }

            if (sorting !== undefined) {
                localVarQueryParameter['sorting'] = sorting;
            }

            if (pageIndex !== undefined) {
                localVarQueryParameter['PageIndex'] = pageIndex;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['PageSize'] = pageSize;
            }

            if (withTotal !== undefined) {
                localVarQueryParameter['WithTotal'] = withTotal;
            }

            if (fromItemInclusive !== undefined) {
                localVarQueryParameter['FromItemInclusive'] = fromItemInclusive;
            }

            if (toItemExclusive !== undefined) {
                localVarQueryParameter['ToItemExclusive'] = toItemExclusive;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Обновить аккаунт бота.
         * @param {string} id ИД аккаунта бота.
         * @param {AgentStageAccountUpdatingRequest} [agentStageAccountUpdatingRequest] Запрос на обновление аккаунта бота.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAgentStageAccount: async (id: string, agentStageAccountUpdatingRequest?: AgentStageAccountUpdatingRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateAgentStageAccount', 'id', id)
            const localVarPath = `/api/v1/accounts/agentstages/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth20 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth20", ["elma.assistant.resources.identity.context", "elma.assistant.scopes.api.admin", "email", "offline_access", "openid", "phone", "profile"], configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(agentStageAccountUpdatingRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AgentStageAccountApi - functional programming interface
 * @export
 */
export const AgentStageAccountApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AgentStageAccountApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Создать аккаунт бота.
         * @param {AgentStageAccountCreationRequest} [agentStageAccountCreationRequest] Запрос на создание аккаунта бота.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createAgentStageAccount(agentStageAccountCreationRequest?: AgentStageAccountCreationRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AgentStageAccountCreationResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createAgentStageAccount(agentStageAccountCreationRequest, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['AgentStageAccountApi.createAgentStageAccount']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Удалить аккаунт бота.
         * @param {string} id ИД аккаунт бота.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteAgentStageAccount(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteAgentStageAccount(id, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['AgentStageAccountApi.deleteAgentStageAccount']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Получить аккаунт бота.
         * @param {string} id ИД аккаунта бота.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAgentStageAccount(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AgentStageAccountModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAgentStageAccount(id, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['AgentStageAccountApi.getAgentStageAccount']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Выполнить поиск аккаунтов ботов.
         * @param {string} [channelId] ИД канала.
         * @param {string} [agentStageId] ИД стадии бота.
         * @param {string} [searchText] Текст для поиска.
         * @param {AgentStageAccountSortDirection} [sorting] Параметры сортировки.
         * @param {number} [pageIndex] 
         * @param {number} [pageSize] 
         * @param {boolean} [withTotal] 
         * @param {number} [fromItemInclusive] 
         * @param {number} [toItemExclusive] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async searchAgentStageAccounts(channelId?: string, agentStageId?: string, searchText?: string, sorting?: AgentStageAccountSortDirection, pageIndex?: number, pageSize?: number, withTotal?: boolean, fromItemInclusive?: number, toItemExclusive?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AgentStageAccountModelPaginationResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.searchAgentStageAccounts(channelId, agentStageId, searchText, sorting, pageIndex, pageSize, withTotal, fromItemInclusive, toItemExclusive, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['AgentStageAccountApi.searchAgentStageAccounts']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Обновить аккаунт бота.
         * @param {string} id ИД аккаунта бота.
         * @param {AgentStageAccountUpdatingRequest} [agentStageAccountUpdatingRequest] Запрос на обновление аккаунта бота.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateAgentStageAccount(id: string, agentStageAccountUpdatingRequest?: AgentStageAccountUpdatingRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AgentStageAccountUpdatingResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateAgentStageAccount(id, agentStageAccountUpdatingRequest, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['AgentStageAccountApi.updateAgentStageAccount']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
    }
};

/**
 * AgentStageAccountApi - factory interface
 * @export
 */
export const AgentStageAccountApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AgentStageAccountApiFp(configuration)
    return {
        /**
         * 
         * @summary Создать аккаунт бота.
         * @param {AgentStageAccountCreationRequest} [agentStageAccountCreationRequest] Запрос на создание аккаунта бота.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createAgentStageAccount(agentStageAccountCreationRequest?: AgentStageAccountCreationRequest, options?: any): AxiosPromise<AgentStageAccountCreationResponse> {
            return localVarFp.createAgentStageAccount(agentStageAccountCreationRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Удалить аккаунт бота.
         * @param {string} id ИД аккаунт бота.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAgentStageAccount(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteAgentStageAccount(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Получить аккаунт бота.
         * @param {string} id ИД аккаунта бота.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAgentStageAccount(id: string, options?: any): AxiosPromise<AgentStageAccountModel> {
            return localVarFp.getAgentStageAccount(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Выполнить поиск аккаунтов ботов.
         * @param {string} [channelId] ИД канала.
         * @param {string} [agentStageId] ИД стадии бота.
         * @param {string} [searchText] Текст для поиска.
         * @param {AgentStageAccountSortDirection} [sorting] Параметры сортировки.
         * @param {number} [pageIndex] 
         * @param {number} [pageSize] 
         * @param {boolean} [withTotal] 
         * @param {number} [fromItemInclusive] 
         * @param {number} [toItemExclusive] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchAgentStageAccounts(channelId?: string, agentStageId?: string, searchText?: string, sorting?: AgentStageAccountSortDirection, pageIndex?: number, pageSize?: number, withTotal?: boolean, fromItemInclusive?: number, toItemExclusive?: number, options?: any): AxiosPromise<AgentStageAccountModelPaginationResponse> {
            return localVarFp.searchAgentStageAccounts(channelId, agentStageId, searchText, sorting, pageIndex, pageSize, withTotal, fromItemInclusive, toItemExclusive, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Обновить аккаунт бота.
         * @param {string} id ИД аккаунта бота.
         * @param {AgentStageAccountUpdatingRequest} [agentStageAccountUpdatingRequest] Запрос на обновление аккаунта бота.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAgentStageAccount(id: string, agentStageAccountUpdatingRequest?: AgentStageAccountUpdatingRequest, options?: any): AxiosPromise<AgentStageAccountUpdatingResponse> {
            return localVarFp.updateAgentStageAccount(id, agentStageAccountUpdatingRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AgentStageAccountApi - object-oriented interface
 * @export
 * @class AgentStageAccountApi
 * @extends {BaseAPI}
 */
export class AgentStageAccountApi extends BaseAPI {
    /**
     * 
     * @summary Создать аккаунт бота.
     * @param {AgentStageAccountCreationRequest} [agentStageAccountCreationRequest] Запрос на создание аккаунта бота.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AgentStageAccountApi
     */
    public createAgentStageAccount(agentStageAccountCreationRequest?: AgentStageAccountCreationRequest, options?: RawAxiosRequestConfig) {
        return AgentStageAccountApiFp(this.configuration).createAgentStageAccount(agentStageAccountCreationRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Удалить аккаунт бота.
     * @param {string} id ИД аккаунт бота.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AgentStageAccountApi
     */
    public deleteAgentStageAccount(id: string, options?: RawAxiosRequestConfig) {
        return AgentStageAccountApiFp(this.configuration).deleteAgentStageAccount(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Получить аккаунт бота.
     * @param {string} id ИД аккаунта бота.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AgentStageAccountApi
     */
    public getAgentStageAccount(id: string, options?: RawAxiosRequestConfig) {
        return AgentStageAccountApiFp(this.configuration).getAgentStageAccount(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Выполнить поиск аккаунтов ботов.
     * @param {string} [channelId] ИД канала.
     * @param {string} [agentStageId] ИД стадии бота.
     * @param {string} [searchText] Текст для поиска.
     * @param {AgentStageAccountSortDirection} [sorting] Параметры сортировки.
     * @param {number} [pageIndex] 
     * @param {number} [pageSize] 
     * @param {boolean} [withTotal] 
     * @param {number} [fromItemInclusive] 
     * @param {number} [toItemExclusive] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AgentStageAccountApi
     */
    public searchAgentStageAccounts(channelId?: string, agentStageId?: string, searchText?: string, sorting?: AgentStageAccountSortDirection, pageIndex?: number, pageSize?: number, withTotal?: boolean, fromItemInclusive?: number, toItemExclusive?: number, options?: RawAxiosRequestConfig) {
        return AgentStageAccountApiFp(this.configuration).searchAgentStageAccounts(channelId, agentStageId, searchText, sorting, pageIndex, pageSize, withTotal, fromItemInclusive, toItemExclusive, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Обновить аккаунт бота.
     * @param {string} id ИД аккаунта бота.
     * @param {AgentStageAccountUpdatingRequest} [agentStageAccountUpdatingRequest] Запрос на обновление аккаунта бота.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AgentStageAccountApi
     */
    public updateAgentStageAccount(id: string, agentStageAccountUpdatingRequest?: AgentStageAccountUpdatingRequest, options?: RawAxiosRequestConfig) {
        return AgentStageAccountApiFp(this.configuration).updateAgentStageAccount(id, agentStageAccountUpdatingRequest, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * AnalyticsReportsApi - axios parameter creator
 * @export
 */
export const AnalyticsReportsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Аналитика по закрытию бесед в день.
         * @param {string} agentStageId ИД стадии агента.
         * @param {string} [fromDate] Начальная дата.
         * @param {string} [toDate] Конечная дата.
         * @param {AnalyticsEventMode} [eventMode] Признак тестового события.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getConversationsClosedPerDayList: async (agentStageId: string, fromDate?: string, toDate?: string, eventMode?: AnalyticsEventMode, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'agentStageId' is not null or undefined
            assertParamExists('getConversationsClosedPerDayList', 'agentStageId', agentStageId)
            const localVarPath = `/api/v1/analytics/reports/{agentStageId}/conversations/closed/grouping/day`
                .replace(`{${"agentStageId"}}`, encodeURIComponent(String(agentStageId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth20 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth20", ["elma.assistant.resources.identity.context", "elma.assistant.scopes.api.admin", "email", "offline_access", "openid", "phone", "profile"], configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (fromDate !== undefined) {
                localVarQueryParameter['FromDate'] = (fromDate as any instanceof Date) ?
                    (fromDate as any).toISOString() :
                    fromDate;
            }

            if (toDate !== undefined) {
                localVarQueryParameter['ToDate'] = (toDate as any instanceof Date) ?
                    (toDate as any).toISOString() :
                    toDate;
            }

            if (eventMode !== undefined) {
                localVarQueryParameter['EventMode'] = eventMode;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Аналитика по закрытию бесед в разрезе сценариев.
         * @param {string} agentStageId ИД стадии агента.
         * @param {string} [fromDate] Начальная дата.
         * @param {string} [toDate] Конечная дата.
         * @param {AnalyticsEventMode} [eventMode] Признак тестового события.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getConversationsClosedPerScenarioList: async (agentStageId: string, fromDate?: string, toDate?: string, eventMode?: AnalyticsEventMode, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'agentStageId' is not null or undefined
            assertParamExists('getConversationsClosedPerScenarioList', 'agentStageId', agentStageId)
            const localVarPath = `/api/v1/analytics/reports/{agentStageId}/conversations/closed/grouping/scenario`
                .replace(`{${"agentStageId"}}`, encodeURIComponent(String(agentStageId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth20 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth20", ["elma.assistant.resources.identity.context", "elma.assistant.scopes.api.admin", "email", "offline_access", "openid", "phone", "profile"], configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (fromDate !== undefined) {
                localVarQueryParameter['FromDate'] = (fromDate as any instanceof Date) ?
                    (fromDate as any).toISOString() :
                    fromDate;
            }

            if (toDate !== undefined) {
                localVarQueryParameter['ToDate'] = (toDate as any instanceof Date) ?
                    (toDate as any).toISOString() :
                    toDate;
            }

            if (eventMode !== undefined) {
                localVarQueryParameter['EventMode'] = eventMode;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Получить аналитику по беседам.
         * @param {string} agentStageId ИД стадии агента.
         * @param {string} [fromDate] Начальная дата.
         * @param {string} [toDate] Конечная дата.
         * @param {AnalyticsEventMode} [eventMode] Признак тестового события.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getConversationsPerDayList: async (agentStageId: string, fromDate?: string, toDate?: string, eventMode?: AnalyticsEventMode, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'agentStageId' is not null or undefined
            assertParamExists('getConversationsPerDayList', 'agentStageId', agentStageId)
            const localVarPath = `/api/v1/analytics/reports/{agentStageId}/conversations/grouping/day`
                .replace(`{${"agentStageId"}}`, encodeURIComponent(String(agentStageId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth20 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth20", ["elma.assistant.resources.identity.context", "elma.assistant.scopes.api.admin", "email", "offline_access", "openid", "phone", "profile"], configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (fromDate !== undefined) {
                localVarQueryParameter['FromDate'] = (fromDate as any instanceof Date) ?
                    (fromDate as any).toISOString() :
                    fromDate;
            }

            if (toDate !== undefined) {
                localVarQueryParameter['ToDate'] = (toDate as any instanceof Date) ?
                    (toDate as any).toISOString() :
                    toDate;
            }

            if (eventMode !== undefined) {
                localVarQueryParameter['EventMode'] = eventMode;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Аналитика по пользовательским событиям в день.
         * @param {string} agentStageId ИД стадии агента.
         * @param {string} [fromDate] Начальная дата.
         * @param {string} [toDate] Конечная дата.
         * @param {AnalyticsEventMode} [eventMode] Признак тестового события.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCustomEventsPerDayList: async (agentStageId: string, fromDate?: string, toDate?: string, eventMode?: AnalyticsEventMode, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'agentStageId' is not null or undefined
            assertParamExists('getCustomEventsPerDayList', 'agentStageId', agentStageId)
            const localVarPath = `/api/v1/analytics/reports/{agentStageId}/custom-events/grouping/day`
                .replace(`{${"agentStageId"}}`, encodeURIComponent(String(agentStageId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth20 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth20", ["elma.assistant.resources.identity.context", "elma.assistant.scopes.api.admin", "email", "offline_access", "openid", "phone", "profile"], configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (fromDate !== undefined) {
                localVarQueryParameter['FromDate'] = (fromDate as any instanceof Date) ?
                    (fromDate as any).toISOString() :
                    fromDate;
            }

            if (toDate !== undefined) {
                localVarQueryParameter['ToDate'] = (toDate as any instanceof Date) ?
                    (toDate as any).toISOString() :
                    toDate;
            }

            if (eventMode !== undefined) {
                localVarQueryParameter['EventMode'] = eventMode;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Аналитика по переводам на оператора.
         * @param {string} agentStageId ИД стадии агента.
         * @param {string} [fromDate] Начальная дата.
         * @param {string} [toDate] Конечная дата.
         * @param {AnalyticsEventMode} [eventMode] Признак тестового события.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEsalationsPerDayList: async (agentStageId: string, fromDate?: string, toDate?: string, eventMode?: AnalyticsEventMode, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'agentStageId' is not null or undefined
            assertParamExists('getEsalationsPerDayList', 'agentStageId', agentStageId)
            const localVarPath = `/api/v1/analytics/reports/{agentStageId}/escalations/grouping/day`
                .replace(`{${"agentStageId"}}`, encodeURIComponent(String(agentStageId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth20 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth20", ["elma.assistant.resources.identity.context", "elma.assistant.scopes.api.admin", "email", "offline_access", "openid", "phone", "profile"], configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (fromDate !== undefined) {
                localVarQueryParameter['FromDate'] = (fromDate as any instanceof Date) ?
                    (fromDate as any).toISOString() :
                    fromDate;
            }

            if (toDate !== undefined) {
                localVarQueryParameter['ToDate'] = (toDate as any instanceof Date) ?
                    (toDate as any).toISOString() :
                    toDate;
            }

            if (eventMode !== undefined) {
                localVarQueryParameter['EventMode'] = eventMode;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Получить общую аналитику.
         * @param {string} agentStageId ИД стадии агента.
         * @param {AnalyticsEventMode} [eventMode] Признак тестового события.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getGeneralAnalytics: async (agentStageId: string, eventMode?: AnalyticsEventMode, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'agentStageId' is not null or undefined
            assertParamExists('getGeneralAnalytics', 'agentStageId', agentStageId)
            const localVarPath = `/api/v1/analytics/reports/{agentStageId}/general`
                .replace(`{${"agentStageId"}}`, encodeURIComponent(String(agentStageId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth20 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth20", ["elma.assistant.resources.identity.context", "elma.assistant.scopes.api.admin", "email", "offline_access", "openid", "phone", "profile"], configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (eventMode !== undefined) {
                localVarQueryParameter['EventMode'] = eventMode;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Получить аналитику по распознаваниям.
         * @param {string} agentStageId ИД стадии агента.
         * @param {string} [fromDate] Начальная дата.
         * @param {string} [toDate] Конечная дата.
         * @param {AnalyticsEventMode} [eventMode] Признак тестового события.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getIntentsPerDayList: async (agentStageId: string, fromDate?: string, toDate?: string, eventMode?: AnalyticsEventMode, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'agentStageId' is not null or undefined
            assertParamExists('getIntentsPerDayList', 'agentStageId', agentStageId)
            const localVarPath = `/api/v1/analytics/reports/{agentStageId}/intents/grouping/day`
                .replace(`{${"agentStageId"}}`, encodeURIComponent(String(agentStageId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth20 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth20", ["elma.assistant.resources.identity.context", "elma.assistant.scopes.api.admin", "email", "offline_access", "openid", "phone", "profile"], configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (fromDate !== undefined) {
                localVarQueryParameter['FromDate'] = (fromDate as any instanceof Date) ?
                    (fromDate as any).toISOString() :
                    fromDate;
            }

            if (toDate !== undefined) {
                localVarQueryParameter['ToDate'] = (toDate as any instanceof Date) ?
                    (toDate as any).toISOString() :
                    toDate;
            }

            if (eventMode !== undefined) {
                localVarQueryParameter['EventMode'] = eventMode;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Аналитика по нажатиям кнопок меню.
         * @param {string} agentStageId ИД стадии агента.
         * @param {string} [fromDate] Начальная дата.
         * @param {string} [toDate] Конечная дата.
         * @param {AnalyticsEventMode} [eventMode] Признак тестового события.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMenuItemSelectionsPerDayList: async (agentStageId: string, fromDate?: string, toDate?: string, eventMode?: AnalyticsEventMode, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'agentStageId' is not null or undefined
            assertParamExists('getMenuItemSelectionsPerDayList', 'agentStageId', agentStageId)
            const localVarPath = `/api/v1/analytics/reports/{agentStageId}/menu/items/selections/grouping/day`
                .replace(`{${"agentStageId"}}`, encodeURIComponent(String(agentStageId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth20 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth20", ["elma.assistant.resources.identity.context", "elma.assistant.scopes.api.admin", "email", "offline_access", "openid", "phone", "profile"], configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (fromDate !== undefined) {
                localVarQueryParameter['FromDate'] = (fromDate as any instanceof Date) ?
                    (fromDate as any).toISOString() :
                    fromDate;
            }

            if (toDate !== undefined) {
                localVarQueryParameter['ToDate'] = (toDate as any instanceof Date) ?
                    (toDate as any).toISOString() :
                    toDate;
            }

            if (eventMode !== undefined) {
                localVarQueryParameter['EventMode'] = eventMode;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Аналитика по событиям суфлера в день.
         * @param {string} agentStageId ИД стадии агента.
         * @param {string} [fromDate] Начальная дата.
         * @param {string} [toDate] Конечная дата.
         * @param {AnalyticsEventMode} [eventMode] Признак тестового события.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPrompterModeEventsPerDayList: async (agentStageId: string, fromDate?: string, toDate?: string, eventMode?: AnalyticsEventMode, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'agentStageId' is not null or undefined
            assertParamExists('getPrompterModeEventsPerDayList', 'agentStageId', agentStageId)
            const localVarPath = `/api/v1/analytics/reports/{agentStageId}/prompter-mode/events/grouping/day`
                .replace(`{${"agentStageId"}}`, encodeURIComponent(String(agentStageId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth20 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth20", ["elma.assistant.resources.identity.context", "elma.assistant.scopes.api.admin", "email", "offline_access", "openid", "phone", "profile"], configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (fromDate !== undefined) {
                localVarQueryParameter['FromDate'] = (fromDate as any instanceof Date) ?
                    (fromDate as any).toISOString() :
                    fromDate;
            }

            if (toDate !== undefined) {
                localVarQueryParameter['ToDate'] = (toDate as any instanceof Date) ?
                    (toDate as any).toISOString() :
                    toDate;
            }

            if (eventMode !== undefined) {
                localVarQueryParameter['EventMode'] = eventMode;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Аналитика по стартам сценариев.
         * @param {string} agentStageId ИД стадии агента.
         * @param {string} [fromDate] Начальная дата.
         * @param {string} [toDate] Конечная дата.
         * @param {AnalyticsEventMode} [eventMode] Признак тестового события.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getScenarioStartsPerDayList: async (agentStageId: string, fromDate?: string, toDate?: string, eventMode?: AnalyticsEventMode, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'agentStageId' is not null or undefined
            assertParamExists('getScenarioStartsPerDayList', 'agentStageId', agentStageId)
            const localVarPath = `/api/v1/analytics/reports/{agentStageId}/scenarios/starts/grouping/day`
                .replace(`{${"agentStageId"}}`, encodeURIComponent(String(agentStageId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth20 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth20", ["elma.assistant.resources.identity.context", "elma.assistant.scopes.api.admin", "email", "offline_access", "openid", "phone", "profile"], configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (fromDate !== undefined) {
                localVarQueryParameter['FromDate'] = (fromDate as any instanceof Date) ?
                    (fromDate as any).toISOString() :
                    fromDate;
            }

            if (toDate !== undefined) {
                localVarQueryParameter['ToDate'] = (toDate as any instanceof Date) ?
                    (toDate as any).toISOString() :
                    toDate;
            }

            if (eventMode !== undefined) {
                localVarQueryParameter['EventMode'] = eventMode;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Аналитика по успешным обслуживаниям в день.
         * @param {string} agentStageId ИД стадии агента.
         * @param {string} [fromDate] Начальная дата.
         * @param {string} [toDate] Конечная дата.
         * @param {AnalyticsEventMode} [eventMode] Признак тестового события.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSuccessfullServiceEventsPerDayList: async (agentStageId: string, fromDate?: string, toDate?: string, eventMode?: AnalyticsEventMode, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'agentStageId' is not null or undefined
            assertParamExists('getSuccessfullServiceEventsPerDayList', 'agentStageId', agentStageId)
            const localVarPath = `/api/v1/analytics/reports/{agentStageId}/successfull-service/events/grouping/day`
                .replace(`{${"agentStageId"}}`, encodeURIComponent(String(agentStageId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth20 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth20", ["elma.assistant.resources.identity.context", "elma.assistant.scopes.api.admin", "email", "offline_access", "openid", "phone", "profile"], configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (fromDate !== undefined) {
                localVarQueryParameter['FromDate'] = (fromDate as any instanceof Date) ?
                    (fromDate as any).toISOString() :
                    fromDate;
            }

            if (toDate !== undefined) {
                localVarQueryParameter['ToDate'] = (toDate as any instanceof Date) ?
                    (toDate as any).toISOString() :
                    toDate;
            }

            if (eventMode !== undefined) {
                localVarQueryParameter['EventMode'] = eventMode;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Аналитика по успешным обслуживаниям в разрезе сценариев.
         * @param {string} agentStageId ИД стадии агента.
         * @param {string} [fromDate] Начальная дата.
         * @param {string} [toDate] Конечная дата.
         * @param {AnalyticsEventMode} [eventMode] Признак тестового события.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSuccessfullServiceEventsPerScenarioList: async (agentStageId: string, fromDate?: string, toDate?: string, eventMode?: AnalyticsEventMode, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'agentStageId' is not null or undefined
            assertParamExists('getSuccessfullServiceEventsPerScenarioList', 'agentStageId', agentStageId)
            const localVarPath = `/api/v1/analytics/reports/{agentStageId}/successfull-service/events/grouping/scenario`
                .replace(`{${"agentStageId"}}`, encodeURIComponent(String(agentStageId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth20 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth20", ["elma.assistant.resources.identity.context", "elma.assistant.scopes.api.admin", "email", "offline_access", "openid", "phone", "profile"], configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (fromDate !== undefined) {
                localVarQueryParameter['FromDate'] = (fromDate as any instanceof Date) ?
                    (fromDate as any).toISOString() :
                    fromDate;
            }

            if (toDate !== undefined) {
                localVarQueryParameter['ToDate'] = (toDate as any instanceof Date) ?
                    (toDate as any).toISOString() :
                    toDate;
            }

            if (eventMode !== undefined) {
                localVarQueryParameter['EventMode'] = eventMode;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Получить аналитику по топу распознаваний.
         * @param {string} agentStageId ИД стадии агента.
         * @param {string} [fromDate] Начальная дата.
         * @param {string} [toDate] Конечная дата.
         * @param {AnalyticsEventMode} [eventMode] Признак тестового события.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTopIntents: async (agentStageId: string, fromDate?: string, toDate?: string, eventMode?: AnalyticsEventMode, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'agentStageId' is not null or undefined
            assertParamExists('getTopIntents', 'agentStageId', agentStageId)
            const localVarPath = `/api/v1/analytics/reports/{agentStageId}/intents/top`
                .replace(`{${"agentStageId"}}`, encodeURIComponent(String(agentStageId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth20 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth20", ["elma.assistant.resources.identity.context", "elma.assistant.scopes.api.admin", "email", "offline_access", "openid", "phone", "profile"], configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (fromDate !== undefined) {
                localVarQueryParameter['FromDate'] = (fromDate as any instanceof Date) ?
                    (fromDate as any).toISOString() :
                    fromDate;
            }

            if (toDate !== undefined) {
                localVarQueryParameter['ToDate'] = (toDate as any instanceof Date) ?
                    (toDate as any).toISOString() :
                    toDate;
            }

            if (eventMode !== undefined) {
                localVarQueryParameter['EventMode'] = eventMode;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Запустить экспорт отчета.
         * @param {string} agentStageId ИД стадии агента.
         * @param {string} [fromDate] Начальная дата.
         * @param {string} [toDate] Конечная дата.
         * @param {AnalyticsEventMode} [eventMode] Признак тестового события.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        runExportAnalyticsReport: async (agentStageId: string, fromDate?: string, toDate?: string, eventMode?: AnalyticsEventMode, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'agentStageId' is not null or undefined
            assertParamExists('runExportAnalyticsReport', 'agentStageId', agentStageId)
            const localVarPath = `/api/v1/analytics/reports/{agentStageId}/export`
                .replace(`{${"agentStageId"}}`, encodeURIComponent(String(agentStageId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth20 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth20", ["elma.assistant.resources.identity.context", "elma.assistant.scopes.api.admin", "email", "offline_access", "openid", "phone", "profile"], configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (fromDate !== undefined) {
                localVarQueryParameter['FromDate'] = (fromDate as any instanceof Date) ?
                    (fromDate as any).toISOString() :
                    fromDate;
            }

            if (toDate !== undefined) {
                localVarQueryParameter['ToDate'] = (toDate as any instanceof Date) ?
                    (toDate as any).toISOString() :
                    toDate;
            }

            if (eventMode !== undefined) {
                localVarQueryParameter['EventMode'] = eventMode;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AnalyticsReportsApi - functional programming interface
 * @export
 */
export const AnalyticsReportsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AnalyticsReportsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Аналитика по закрытию бесед в день.
         * @param {string} agentStageId ИД стадии агента.
         * @param {string} [fromDate] Начальная дата.
         * @param {string} [toDate] Конечная дата.
         * @param {AnalyticsEventMode} [eventMode] Признак тестового события.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getConversationsClosedPerDayList(agentStageId: string, fromDate?: string, toDate?: string, eventMode?: AnalyticsEventMode, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ConversationsClosedPerDayModel>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getConversationsClosedPerDayList(agentStageId, fromDate, toDate, eventMode, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['AnalyticsReportsApi.getConversationsClosedPerDayList']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Аналитика по закрытию бесед в разрезе сценариев.
         * @param {string} agentStageId ИД стадии агента.
         * @param {string} [fromDate] Начальная дата.
         * @param {string} [toDate] Конечная дата.
         * @param {AnalyticsEventMode} [eventMode] Признак тестового события.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getConversationsClosedPerScenarioList(agentStageId: string, fromDate?: string, toDate?: string, eventMode?: AnalyticsEventMode, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ConversationsClosedPerScenarioModel>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getConversationsClosedPerScenarioList(agentStageId, fromDate, toDate, eventMode, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['AnalyticsReportsApi.getConversationsClosedPerScenarioList']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Получить аналитику по беседам.
         * @param {string} agentStageId ИД стадии агента.
         * @param {string} [fromDate] Начальная дата.
         * @param {string} [toDate] Конечная дата.
         * @param {AnalyticsEventMode} [eventMode] Признак тестового события.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getConversationsPerDayList(agentStageId: string, fromDate?: string, toDate?: string, eventMode?: AnalyticsEventMode, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ConversationsPerDayModel>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getConversationsPerDayList(agentStageId, fromDate, toDate, eventMode, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['AnalyticsReportsApi.getConversationsPerDayList']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Аналитика по пользовательским событиям в день.
         * @param {string} agentStageId ИД стадии агента.
         * @param {string} [fromDate] Начальная дата.
         * @param {string} [toDate] Конечная дата.
         * @param {AnalyticsEventMode} [eventMode] Признак тестового события.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCustomEventsPerDayList(agentStageId: string, fromDate?: string, toDate?: string, eventMode?: AnalyticsEventMode, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CustomEventsPerDayModel>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCustomEventsPerDayList(agentStageId, fromDate, toDate, eventMode, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['AnalyticsReportsApi.getCustomEventsPerDayList']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Аналитика по переводам на оператора.
         * @param {string} agentStageId ИД стадии агента.
         * @param {string} [fromDate] Начальная дата.
         * @param {string} [toDate] Конечная дата.
         * @param {AnalyticsEventMode} [eventMode] Признак тестового события.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getEsalationsPerDayList(agentStageId: string, fromDate?: string, toDate?: string, eventMode?: AnalyticsEventMode, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<EscalationsPerDayModel>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getEsalationsPerDayList(agentStageId, fromDate, toDate, eventMode, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['AnalyticsReportsApi.getEsalationsPerDayList']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Получить общую аналитику.
         * @param {string} agentStageId ИД стадии агента.
         * @param {AnalyticsEventMode} [eventMode] Признак тестового события.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getGeneralAnalytics(agentStageId: string, eventMode?: AnalyticsEventMode, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AnalyticsReportsGeneralDataModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getGeneralAnalytics(agentStageId, eventMode, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['AnalyticsReportsApi.getGeneralAnalytics']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Получить аналитику по распознаваниям.
         * @param {string} agentStageId ИД стадии агента.
         * @param {string} [fromDate] Начальная дата.
         * @param {string} [toDate] Конечная дата.
         * @param {AnalyticsEventMode} [eventMode] Признак тестового события.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getIntentsPerDayList(agentStageId: string, fromDate?: string, toDate?: string, eventMode?: AnalyticsEventMode, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<IntentsPerDayModel>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getIntentsPerDayList(agentStageId, fromDate, toDate, eventMode, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['AnalyticsReportsApi.getIntentsPerDayList']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Аналитика по нажатиям кнопок меню.
         * @param {string} agentStageId ИД стадии агента.
         * @param {string} [fromDate] Начальная дата.
         * @param {string} [toDate] Конечная дата.
         * @param {AnalyticsEventMode} [eventMode] Признак тестового события.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMenuItemSelectionsPerDayList(agentStageId: string, fromDate?: string, toDate?: string, eventMode?: AnalyticsEventMode, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<MenuItemSelectionsPerDayModel>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMenuItemSelectionsPerDayList(agentStageId, fromDate, toDate, eventMode, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['AnalyticsReportsApi.getMenuItemSelectionsPerDayList']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Аналитика по событиям суфлера в день.
         * @param {string} agentStageId ИД стадии агента.
         * @param {string} [fromDate] Начальная дата.
         * @param {string} [toDate] Конечная дата.
         * @param {AnalyticsEventMode} [eventMode] Признак тестового события.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPrompterModeEventsPerDayList(agentStageId: string, fromDate?: string, toDate?: string, eventMode?: AnalyticsEventMode, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<PrompterModeEventsPerDayModel>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPrompterModeEventsPerDayList(agentStageId, fromDate, toDate, eventMode, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['AnalyticsReportsApi.getPrompterModeEventsPerDayList']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Аналитика по стартам сценариев.
         * @param {string} agentStageId ИД стадии агента.
         * @param {string} [fromDate] Начальная дата.
         * @param {string} [toDate] Конечная дата.
         * @param {AnalyticsEventMode} [eventMode] Признак тестового события.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getScenarioStartsPerDayList(agentStageId: string, fromDate?: string, toDate?: string, eventMode?: AnalyticsEventMode, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ScenarioStartsPerDayModel>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getScenarioStartsPerDayList(agentStageId, fromDate, toDate, eventMode, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['AnalyticsReportsApi.getScenarioStartsPerDayList']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Аналитика по успешным обслуживаниям в день.
         * @param {string} agentStageId ИД стадии агента.
         * @param {string} [fromDate] Начальная дата.
         * @param {string} [toDate] Конечная дата.
         * @param {AnalyticsEventMode} [eventMode] Признак тестового события.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSuccessfullServiceEventsPerDayList(agentStageId: string, fromDate?: string, toDate?: string, eventMode?: AnalyticsEventMode, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<SuccessfullServiceEventsPerDayModel>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSuccessfullServiceEventsPerDayList(agentStageId, fromDate, toDate, eventMode, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['AnalyticsReportsApi.getSuccessfullServiceEventsPerDayList']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Аналитика по успешным обслуживаниям в разрезе сценариев.
         * @param {string} agentStageId ИД стадии агента.
         * @param {string} [fromDate] Начальная дата.
         * @param {string} [toDate] Конечная дата.
         * @param {AnalyticsEventMode} [eventMode] Признак тестового события.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSuccessfullServiceEventsPerScenarioList(agentStageId: string, fromDate?: string, toDate?: string, eventMode?: AnalyticsEventMode, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<SuccessfullServiceEventsPerScenarioModel>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSuccessfullServiceEventsPerScenarioList(agentStageId, fromDate, toDate, eventMode, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['AnalyticsReportsApi.getSuccessfullServiceEventsPerScenarioList']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Получить аналитику по топу распознаваний.
         * @param {string} agentStageId ИД стадии агента.
         * @param {string} [fromDate] Начальная дата.
         * @param {string} [toDate] Конечная дата.
         * @param {AnalyticsEventMode} [eventMode] Признак тестового события.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTopIntents(agentStageId: string, fromDate?: string, toDate?: string, eventMode?: AnalyticsEventMode, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TopIntentsModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTopIntents(agentStageId, fromDate, toDate, eventMode, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['AnalyticsReportsApi.getTopIntents']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Запустить экспорт отчета.
         * @param {string} agentStageId ИД стадии агента.
         * @param {string} [fromDate] Начальная дата.
         * @param {string} [toDate] Конечная дата.
         * @param {AnalyticsEventMode} [eventMode] Признак тестового события.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async runExportAnalyticsReport(agentStageId: string, fromDate?: string, toDate?: string, eventMode?: AnalyticsEventMode, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RunExportAnalyticsReportResultModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.runExportAnalyticsReport(agentStageId, fromDate, toDate, eventMode, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['AnalyticsReportsApi.runExportAnalyticsReport']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
    }
};

/**
 * AnalyticsReportsApi - factory interface
 * @export
 */
export const AnalyticsReportsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AnalyticsReportsApiFp(configuration)
    return {
        /**
         * 
         * @summary Аналитика по закрытию бесед в день.
         * @param {string} agentStageId ИД стадии агента.
         * @param {string} [fromDate] Начальная дата.
         * @param {string} [toDate] Конечная дата.
         * @param {AnalyticsEventMode} [eventMode] Признак тестового события.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getConversationsClosedPerDayList(agentStageId: string, fromDate?: string, toDate?: string, eventMode?: AnalyticsEventMode, options?: any): AxiosPromise<Array<ConversationsClosedPerDayModel>> {
            return localVarFp.getConversationsClosedPerDayList(agentStageId, fromDate, toDate, eventMode, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Аналитика по закрытию бесед в разрезе сценариев.
         * @param {string} agentStageId ИД стадии агента.
         * @param {string} [fromDate] Начальная дата.
         * @param {string} [toDate] Конечная дата.
         * @param {AnalyticsEventMode} [eventMode] Признак тестового события.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getConversationsClosedPerScenarioList(agentStageId: string, fromDate?: string, toDate?: string, eventMode?: AnalyticsEventMode, options?: any): AxiosPromise<Array<ConversationsClosedPerScenarioModel>> {
            return localVarFp.getConversationsClosedPerScenarioList(agentStageId, fromDate, toDate, eventMode, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Получить аналитику по беседам.
         * @param {string} agentStageId ИД стадии агента.
         * @param {string} [fromDate] Начальная дата.
         * @param {string} [toDate] Конечная дата.
         * @param {AnalyticsEventMode} [eventMode] Признак тестового события.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getConversationsPerDayList(agentStageId: string, fromDate?: string, toDate?: string, eventMode?: AnalyticsEventMode, options?: any): AxiosPromise<Array<ConversationsPerDayModel>> {
            return localVarFp.getConversationsPerDayList(agentStageId, fromDate, toDate, eventMode, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Аналитика по пользовательским событиям в день.
         * @param {string} agentStageId ИД стадии агента.
         * @param {string} [fromDate] Начальная дата.
         * @param {string} [toDate] Конечная дата.
         * @param {AnalyticsEventMode} [eventMode] Признак тестового события.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCustomEventsPerDayList(agentStageId: string, fromDate?: string, toDate?: string, eventMode?: AnalyticsEventMode, options?: any): AxiosPromise<Array<CustomEventsPerDayModel>> {
            return localVarFp.getCustomEventsPerDayList(agentStageId, fromDate, toDate, eventMode, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Аналитика по переводам на оператора.
         * @param {string} agentStageId ИД стадии агента.
         * @param {string} [fromDate] Начальная дата.
         * @param {string} [toDate] Конечная дата.
         * @param {AnalyticsEventMode} [eventMode] Признак тестового события.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEsalationsPerDayList(agentStageId: string, fromDate?: string, toDate?: string, eventMode?: AnalyticsEventMode, options?: any): AxiosPromise<Array<EscalationsPerDayModel>> {
            return localVarFp.getEsalationsPerDayList(agentStageId, fromDate, toDate, eventMode, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Получить общую аналитику.
         * @param {string} agentStageId ИД стадии агента.
         * @param {AnalyticsEventMode} [eventMode] Признак тестового события.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getGeneralAnalytics(agentStageId: string, eventMode?: AnalyticsEventMode, options?: any): AxiosPromise<AnalyticsReportsGeneralDataModel> {
            return localVarFp.getGeneralAnalytics(agentStageId, eventMode, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Получить аналитику по распознаваниям.
         * @param {string} agentStageId ИД стадии агента.
         * @param {string} [fromDate] Начальная дата.
         * @param {string} [toDate] Конечная дата.
         * @param {AnalyticsEventMode} [eventMode] Признак тестового события.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getIntentsPerDayList(agentStageId: string, fromDate?: string, toDate?: string, eventMode?: AnalyticsEventMode, options?: any): AxiosPromise<Array<IntentsPerDayModel>> {
            return localVarFp.getIntentsPerDayList(agentStageId, fromDate, toDate, eventMode, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Аналитика по нажатиям кнопок меню.
         * @param {string} agentStageId ИД стадии агента.
         * @param {string} [fromDate] Начальная дата.
         * @param {string} [toDate] Конечная дата.
         * @param {AnalyticsEventMode} [eventMode] Признак тестового события.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMenuItemSelectionsPerDayList(agentStageId: string, fromDate?: string, toDate?: string, eventMode?: AnalyticsEventMode, options?: any): AxiosPromise<Array<MenuItemSelectionsPerDayModel>> {
            return localVarFp.getMenuItemSelectionsPerDayList(agentStageId, fromDate, toDate, eventMode, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Аналитика по событиям суфлера в день.
         * @param {string} agentStageId ИД стадии агента.
         * @param {string} [fromDate] Начальная дата.
         * @param {string} [toDate] Конечная дата.
         * @param {AnalyticsEventMode} [eventMode] Признак тестового события.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPrompterModeEventsPerDayList(agentStageId: string, fromDate?: string, toDate?: string, eventMode?: AnalyticsEventMode, options?: any): AxiosPromise<Array<PrompterModeEventsPerDayModel>> {
            return localVarFp.getPrompterModeEventsPerDayList(agentStageId, fromDate, toDate, eventMode, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Аналитика по стартам сценариев.
         * @param {string} agentStageId ИД стадии агента.
         * @param {string} [fromDate] Начальная дата.
         * @param {string} [toDate] Конечная дата.
         * @param {AnalyticsEventMode} [eventMode] Признак тестового события.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getScenarioStartsPerDayList(agentStageId: string, fromDate?: string, toDate?: string, eventMode?: AnalyticsEventMode, options?: any): AxiosPromise<Array<ScenarioStartsPerDayModel>> {
            return localVarFp.getScenarioStartsPerDayList(agentStageId, fromDate, toDate, eventMode, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Аналитика по успешным обслуживаниям в день.
         * @param {string} agentStageId ИД стадии агента.
         * @param {string} [fromDate] Начальная дата.
         * @param {string} [toDate] Конечная дата.
         * @param {AnalyticsEventMode} [eventMode] Признак тестового события.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSuccessfullServiceEventsPerDayList(agentStageId: string, fromDate?: string, toDate?: string, eventMode?: AnalyticsEventMode, options?: any): AxiosPromise<Array<SuccessfullServiceEventsPerDayModel>> {
            return localVarFp.getSuccessfullServiceEventsPerDayList(agentStageId, fromDate, toDate, eventMode, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Аналитика по успешным обслуживаниям в разрезе сценариев.
         * @param {string} agentStageId ИД стадии агента.
         * @param {string} [fromDate] Начальная дата.
         * @param {string} [toDate] Конечная дата.
         * @param {AnalyticsEventMode} [eventMode] Признак тестового события.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSuccessfullServiceEventsPerScenarioList(agentStageId: string, fromDate?: string, toDate?: string, eventMode?: AnalyticsEventMode, options?: any): AxiosPromise<Array<SuccessfullServiceEventsPerScenarioModel>> {
            return localVarFp.getSuccessfullServiceEventsPerScenarioList(agentStageId, fromDate, toDate, eventMode, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Получить аналитику по топу распознаваний.
         * @param {string} agentStageId ИД стадии агента.
         * @param {string} [fromDate] Начальная дата.
         * @param {string} [toDate] Конечная дата.
         * @param {AnalyticsEventMode} [eventMode] Признак тестового события.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTopIntents(agentStageId: string, fromDate?: string, toDate?: string, eventMode?: AnalyticsEventMode, options?: any): AxiosPromise<TopIntentsModel> {
            return localVarFp.getTopIntents(agentStageId, fromDate, toDate, eventMode, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Запустить экспорт отчета.
         * @param {string} agentStageId ИД стадии агента.
         * @param {string} [fromDate] Начальная дата.
         * @param {string} [toDate] Конечная дата.
         * @param {AnalyticsEventMode} [eventMode] Признак тестового события.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        runExportAnalyticsReport(agentStageId: string, fromDate?: string, toDate?: string, eventMode?: AnalyticsEventMode, options?: any): AxiosPromise<RunExportAnalyticsReportResultModel> {
            return localVarFp.runExportAnalyticsReport(agentStageId, fromDate, toDate, eventMode, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AnalyticsReportsApi - object-oriented interface
 * @export
 * @class AnalyticsReportsApi
 * @extends {BaseAPI}
 */
export class AnalyticsReportsApi extends BaseAPI {
    /**
     * 
     * @summary Аналитика по закрытию бесед в день.
     * @param {string} agentStageId ИД стадии агента.
     * @param {string} [fromDate] Начальная дата.
     * @param {string} [toDate] Конечная дата.
     * @param {AnalyticsEventMode} [eventMode] Признак тестового события.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AnalyticsReportsApi
     */
    public getConversationsClosedPerDayList(agentStageId: string, fromDate?: string, toDate?: string, eventMode?: AnalyticsEventMode, options?: RawAxiosRequestConfig) {
        return AnalyticsReportsApiFp(this.configuration).getConversationsClosedPerDayList(agentStageId, fromDate, toDate, eventMode, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Аналитика по закрытию бесед в разрезе сценариев.
     * @param {string} agentStageId ИД стадии агента.
     * @param {string} [fromDate] Начальная дата.
     * @param {string} [toDate] Конечная дата.
     * @param {AnalyticsEventMode} [eventMode] Признак тестового события.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AnalyticsReportsApi
     */
    public getConversationsClosedPerScenarioList(agentStageId: string, fromDate?: string, toDate?: string, eventMode?: AnalyticsEventMode, options?: RawAxiosRequestConfig) {
        return AnalyticsReportsApiFp(this.configuration).getConversationsClosedPerScenarioList(agentStageId, fromDate, toDate, eventMode, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Получить аналитику по беседам.
     * @param {string} agentStageId ИД стадии агента.
     * @param {string} [fromDate] Начальная дата.
     * @param {string} [toDate] Конечная дата.
     * @param {AnalyticsEventMode} [eventMode] Признак тестового события.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AnalyticsReportsApi
     */
    public getConversationsPerDayList(agentStageId: string, fromDate?: string, toDate?: string, eventMode?: AnalyticsEventMode, options?: RawAxiosRequestConfig) {
        return AnalyticsReportsApiFp(this.configuration).getConversationsPerDayList(agentStageId, fromDate, toDate, eventMode, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Аналитика по пользовательским событиям в день.
     * @param {string} agentStageId ИД стадии агента.
     * @param {string} [fromDate] Начальная дата.
     * @param {string} [toDate] Конечная дата.
     * @param {AnalyticsEventMode} [eventMode] Признак тестового события.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AnalyticsReportsApi
     */
    public getCustomEventsPerDayList(agentStageId: string, fromDate?: string, toDate?: string, eventMode?: AnalyticsEventMode, options?: RawAxiosRequestConfig) {
        return AnalyticsReportsApiFp(this.configuration).getCustomEventsPerDayList(agentStageId, fromDate, toDate, eventMode, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Аналитика по переводам на оператора.
     * @param {string} agentStageId ИД стадии агента.
     * @param {string} [fromDate] Начальная дата.
     * @param {string} [toDate] Конечная дата.
     * @param {AnalyticsEventMode} [eventMode] Признак тестового события.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AnalyticsReportsApi
     */
    public getEsalationsPerDayList(agentStageId: string, fromDate?: string, toDate?: string, eventMode?: AnalyticsEventMode, options?: RawAxiosRequestConfig) {
        return AnalyticsReportsApiFp(this.configuration).getEsalationsPerDayList(agentStageId, fromDate, toDate, eventMode, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Получить общую аналитику.
     * @param {string} agentStageId ИД стадии агента.
     * @param {AnalyticsEventMode} [eventMode] Признак тестового события.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AnalyticsReportsApi
     */
    public getGeneralAnalytics(agentStageId: string, eventMode?: AnalyticsEventMode, options?: RawAxiosRequestConfig) {
        return AnalyticsReportsApiFp(this.configuration).getGeneralAnalytics(agentStageId, eventMode, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Получить аналитику по распознаваниям.
     * @param {string} agentStageId ИД стадии агента.
     * @param {string} [fromDate] Начальная дата.
     * @param {string} [toDate] Конечная дата.
     * @param {AnalyticsEventMode} [eventMode] Признак тестового события.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AnalyticsReportsApi
     */
    public getIntentsPerDayList(agentStageId: string, fromDate?: string, toDate?: string, eventMode?: AnalyticsEventMode, options?: RawAxiosRequestConfig) {
        return AnalyticsReportsApiFp(this.configuration).getIntentsPerDayList(agentStageId, fromDate, toDate, eventMode, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Аналитика по нажатиям кнопок меню.
     * @param {string} agentStageId ИД стадии агента.
     * @param {string} [fromDate] Начальная дата.
     * @param {string} [toDate] Конечная дата.
     * @param {AnalyticsEventMode} [eventMode] Признак тестового события.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AnalyticsReportsApi
     */
    public getMenuItemSelectionsPerDayList(agentStageId: string, fromDate?: string, toDate?: string, eventMode?: AnalyticsEventMode, options?: RawAxiosRequestConfig) {
        return AnalyticsReportsApiFp(this.configuration).getMenuItemSelectionsPerDayList(agentStageId, fromDate, toDate, eventMode, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Аналитика по событиям суфлера в день.
     * @param {string} agentStageId ИД стадии агента.
     * @param {string} [fromDate] Начальная дата.
     * @param {string} [toDate] Конечная дата.
     * @param {AnalyticsEventMode} [eventMode] Признак тестового события.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AnalyticsReportsApi
     */
    public getPrompterModeEventsPerDayList(agentStageId: string, fromDate?: string, toDate?: string, eventMode?: AnalyticsEventMode, options?: RawAxiosRequestConfig) {
        return AnalyticsReportsApiFp(this.configuration).getPrompterModeEventsPerDayList(agentStageId, fromDate, toDate, eventMode, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Аналитика по стартам сценариев.
     * @param {string} agentStageId ИД стадии агента.
     * @param {string} [fromDate] Начальная дата.
     * @param {string} [toDate] Конечная дата.
     * @param {AnalyticsEventMode} [eventMode] Признак тестового события.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AnalyticsReportsApi
     */
    public getScenarioStartsPerDayList(agentStageId: string, fromDate?: string, toDate?: string, eventMode?: AnalyticsEventMode, options?: RawAxiosRequestConfig) {
        return AnalyticsReportsApiFp(this.configuration).getScenarioStartsPerDayList(agentStageId, fromDate, toDate, eventMode, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Аналитика по успешным обслуживаниям в день.
     * @param {string} agentStageId ИД стадии агента.
     * @param {string} [fromDate] Начальная дата.
     * @param {string} [toDate] Конечная дата.
     * @param {AnalyticsEventMode} [eventMode] Признак тестового события.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AnalyticsReportsApi
     */
    public getSuccessfullServiceEventsPerDayList(agentStageId: string, fromDate?: string, toDate?: string, eventMode?: AnalyticsEventMode, options?: RawAxiosRequestConfig) {
        return AnalyticsReportsApiFp(this.configuration).getSuccessfullServiceEventsPerDayList(agentStageId, fromDate, toDate, eventMode, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Аналитика по успешным обслуживаниям в разрезе сценариев.
     * @param {string} agentStageId ИД стадии агента.
     * @param {string} [fromDate] Начальная дата.
     * @param {string} [toDate] Конечная дата.
     * @param {AnalyticsEventMode} [eventMode] Признак тестового события.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AnalyticsReportsApi
     */
    public getSuccessfullServiceEventsPerScenarioList(agentStageId: string, fromDate?: string, toDate?: string, eventMode?: AnalyticsEventMode, options?: RawAxiosRequestConfig) {
        return AnalyticsReportsApiFp(this.configuration).getSuccessfullServiceEventsPerScenarioList(agentStageId, fromDate, toDate, eventMode, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Получить аналитику по топу распознаваний.
     * @param {string} agentStageId ИД стадии агента.
     * @param {string} [fromDate] Начальная дата.
     * @param {string} [toDate] Конечная дата.
     * @param {AnalyticsEventMode} [eventMode] Признак тестового события.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AnalyticsReportsApi
     */
    public getTopIntents(agentStageId: string, fromDate?: string, toDate?: string, eventMode?: AnalyticsEventMode, options?: RawAxiosRequestConfig) {
        return AnalyticsReportsApiFp(this.configuration).getTopIntents(agentStageId, fromDate, toDate, eventMode, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Запустить экспорт отчета.
     * @param {string} agentStageId ИД стадии агента.
     * @param {string} [fromDate] Начальная дата.
     * @param {string} [toDate] Конечная дата.
     * @param {AnalyticsEventMode} [eventMode] Признак тестового события.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AnalyticsReportsApi
     */
    public runExportAnalyticsReport(agentStageId: string, fromDate?: string, toDate?: string, eventMode?: AnalyticsEventMode, options?: RawAxiosRequestConfig) {
        return AnalyticsReportsApiFp(this.configuration).runExportAnalyticsReport(agentStageId, fromDate, toDate, eventMode, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * ApplicationApi - axios parameter creator
 * @export
 */
export const ApplicationApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Получить информацию о приложении.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAboutInfo: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/info/application/about`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Получить информацию о работоспособности.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getHealthInfo: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/info/application/health`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ApplicationApi - functional programming interface
 * @export
 */
export const ApplicationApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ApplicationApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Получить информацию о приложении.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAboutInfo(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AboutModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAboutInfo(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['ApplicationApi.getAboutInfo']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Получить информацию о работоспособности.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getHealthInfo(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<HealthModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getHealthInfo(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['ApplicationApi.getHealthInfo']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
    }
};

/**
 * ApplicationApi - factory interface
 * @export
 */
export const ApplicationApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ApplicationApiFp(configuration)
    return {
        /**
         * 
         * @summary Получить информацию о приложении.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAboutInfo(options?: any): AxiosPromise<AboutModel> {
            return localVarFp.getAboutInfo(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Получить информацию о работоспособности.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getHealthInfo(options?: any): AxiosPromise<HealthModel> {
            return localVarFp.getHealthInfo(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ApplicationApi - object-oriented interface
 * @export
 * @class ApplicationApi
 * @extends {BaseAPI}
 */
export class ApplicationApi extends BaseAPI {
    /**
     * 
     * @summary Получить информацию о приложении.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApplicationApi
     */
    public getAboutInfo(options?: RawAxiosRequestConfig) {
        return ApplicationApiFp(this.configuration).getAboutInfo(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Получить информацию о работоспособности.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApplicationApi
     */
    public getHealthInfo(options?: RawAxiosRequestConfig) {
        return ApplicationApiFp(this.configuration).getHealthInfo(options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * BotApi - axios parameter creator
 * @export
 */
export const BotApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Создать бота.
         * @param {BotCreationRequest} [botCreationRequest] Запрос на создание бота.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createBot: async (botCreationRequest?: BotCreationRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/runtime/simple/bots`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth20 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth20", ["elma.assistant.resources.identity.context", "elma.assistant.scopes.api.admin", "email", "offline_access", "openid", "phone", "profile"], configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(botCreationRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Создать дубликат бота.
         * @param {string} id ИД бота.
         * @param {object} [body] Запрос на создание дубликата бота.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        duplicateBot: async (id: string, body?: object, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('duplicateBot', 'id', id)
            const localVarPath = `/api/v1/runtime/simple/bots/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth20 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth20", ["elma.assistant.resources.identity.context", "elma.assistant.scopes.api.admin", "email", "offline_access", "openid", "phone", "profile"], configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Получить бота.
         * @param {string} id ИД записи бота.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBot: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getBot', 'id', id)
            const localVarPath = `/api/v1/runtime/simple/bots/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth20 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth20", ["elma.assistant.resources.identity.context", "elma.assistant.scopes.api.admin", "email", "offline_access", "openid", "phone", "profile"], configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Получить бота по id сложного бота.
         * @param {string} agentId ИД записи сложного бота.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBotByAgentId: async (agentId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'agentId' is not null or undefined
            assertParamExists('getBotByAgentId', 'agentId', agentId)
            const localVarPath = `/api/v1/runtime/simple/bots/get-by-agent-id/{agentId}`
                .replace(`{${"agentId"}}`, encodeURIComponent(String(agentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth20 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth20", ["elma.assistant.resources.identity.context", "elma.assistant.scopes.api.admin", "email", "offline_access", "openid", "phone", "profile"], configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Получить список доступных моделей обучения бота.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBotTrainingModels: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/runtime/simple/bots/nlp-models`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth20 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth20", ["elma.assistant.resources.identity.context", "elma.assistant.scopes.api.admin", "email", "offline_access", "openid", "phone", "profile"], configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Выполнить поиск ботов.
         * @param {string} [search] Текст для поиска.
         * @param {BotSortDirection} [sorting] Параметры сортировки.
         * @param {number} [pageIndex] 
         * @param {number} [pageSize] 
         * @param {boolean} [withTotal] 
         * @param {number} [fromItemInclusive] 
         * @param {number} [toItemExclusive] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchBots: async (search?: string, sorting?: BotSortDirection, pageIndex?: number, pageSize?: number, withTotal?: boolean, fromItemInclusive?: number, toItemExclusive?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/runtime/simple/bots`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth20 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth20", ["elma.assistant.resources.identity.context", "elma.assistant.scopes.api.admin", "email", "offline_access", "openid", "phone", "profile"], configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (search !== undefined) {
                localVarQueryParameter['Search'] = search;
            }

            if (sorting !== undefined) {
                localVarQueryParameter['sorting'] = sorting;
            }

            if (pageIndex !== undefined) {
                localVarQueryParameter['PageIndex'] = pageIndex;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['PageSize'] = pageSize;
            }

            if (withTotal !== undefined) {
                localVarQueryParameter['WithTotal'] = withTotal;
            }

            if (fromItemInclusive !== undefined) {
                localVarQueryParameter['FromItemInclusive'] = fromItemInclusive;
            }

            if (toItemExclusive !== undefined) {
                localVarQueryParameter['ToItemExclusive'] = toItemExclusive;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Обновить бота.
         * @param {string} id ИД бота.
         * @param {BotUpdatingRequest} [botUpdatingRequest] Запрос на обновление бота.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateBot: async (id: string, botUpdatingRequest?: BotUpdatingRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateBot', 'id', id)
            const localVarPath = `/api/v1/runtime/simple/bots/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth20 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth20", ["elma.assistant.resources.identity.context", "elma.assistant.scopes.api.admin", "email", "offline_access", "openid", "phone", "profile"], configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(botUpdatingRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * BotApi - functional programming interface
 * @export
 */
export const BotApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = BotApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Создать бота.
         * @param {BotCreationRequest} [botCreationRequest] Запрос на создание бота.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createBot(botCreationRequest?: BotCreationRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BotCreationResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createBot(botCreationRequest, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['BotApi.createBot']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Создать дубликат бота.
         * @param {string} id ИД бота.
         * @param {object} [body] Запрос на создание дубликата бота.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async duplicateBot(id: string, body?: object, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BotCreationResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.duplicateBot(id, body, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['BotApi.duplicateBot']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Получить бота.
         * @param {string} id ИД записи бота.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getBot(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SingleBotModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getBot(id, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['BotApi.getBot']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Получить бота по id сложного бота.
         * @param {string} agentId ИД записи сложного бота.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getBotByAgentId(agentId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SingleBotModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getBotByAgentId(agentId, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['BotApi.getBotByAgentId']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Получить список доступных моделей обучения бота.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getBotTrainingModels(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<BotTrainingModelModel>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getBotTrainingModels(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['BotApi.getBotTrainingModels']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Выполнить поиск ботов.
         * @param {string} [search] Текст для поиска.
         * @param {BotSortDirection} [sorting] Параметры сортировки.
         * @param {number} [pageIndex] 
         * @param {number} [pageSize] 
         * @param {boolean} [withTotal] 
         * @param {number} [fromItemInclusive] 
         * @param {number} [toItemExclusive] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async searchBots(search?: string, sorting?: BotSortDirection, pageIndex?: number, pageSize?: number, withTotal?: boolean, fromItemInclusive?: number, toItemExclusive?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListBotModelPaginationResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.searchBots(search, sorting, pageIndex, pageSize, withTotal, fromItemInclusive, toItemExclusive, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['BotApi.searchBots']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Обновить бота.
         * @param {string} id ИД бота.
         * @param {BotUpdatingRequest} [botUpdatingRequest] Запрос на обновление бота.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateBot(id: string, botUpdatingRequest?: BotUpdatingRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateBot(id, botUpdatingRequest, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['BotApi.updateBot']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
    }
};

/**
 * BotApi - factory interface
 * @export
 */
export const BotApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = BotApiFp(configuration)
    return {
        /**
         * 
         * @summary Создать бота.
         * @param {BotCreationRequest} [botCreationRequest] Запрос на создание бота.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createBot(botCreationRequest?: BotCreationRequest, options?: any): AxiosPromise<BotCreationResponse> {
            return localVarFp.createBot(botCreationRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Создать дубликат бота.
         * @param {string} id ИД бота.
         * @param {object} [body] Запрос на создание дубликата бота.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        duplicateBot(id: string, body?: object, options?: any): AxiosPromise<BotCreationResponse> {
            return localVarFp.duplicateBot(id, body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Получить бота.
         * @param {string} id ИД записи бота.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBot(id: string, options?: any): AxiosPromise<SingleBotModel> {
            return localVarFp.getBot(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Получить бота по id сложного бота.
         * @param {string} agentId ИД записи сложного бота.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBotByAgentId(agentId: string, options?: any): AxiosPromise<SingleBotModel> {
            return localVarFp.getBotByAgentId(agentId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Получить список доступных моделей обучения бота.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBotTrainingModels(options?: any): AxiosPromise<Array<BotTrainingModelModel>> {
            return localVarFp.getBotTrainingModels(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Выполнить поиск ботов.
         * @param {string} [search] Текст для поиска.
         * @param {BotSortDirection} [sorting] Параметры сортировки.
         * @param {number} [pageIndex] 
         * @param {number} [pageSize] 
         * @param {boolean} [withTotal] 
         * @param {number} [fromItemInclusive] 
         * @param {number} [toItemExclusive] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchBots(search?: string, sorting?: BotSortDirection, pageIndex?: number, pageSize?: number, withTotal?: boolean, fromItemInclusive?: number, toItemExclusive?: number, options?: any): AxiosPromise<ListBotModelPaginationResponse> {
            return localVarFp.searchBots(search, sorting, pageIndex, pageSize, withTotal, fromItemInclusive, toItemExclusive, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Обновить бота.
         * @param {string} id ИД бота.
         * @param {BotUpdatingRequest} [botUpdatingRequest] Запрос на обновление бота.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateBot(id: string, botUpdatingRequest?: BotUpdatingRequest, options?: any): AxiosPromise<object> {
            return localVarFp.updateBot(id, botUpdatingRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * BotApi - object-oriented interface
 * @export
 * @class BotApi
 * @extends {BaseAPI}
 */
export class BotApi extends BaseAPI {
    /**
     * 
     * @summary Создать бота.
     * @param {BotCreationRequest} [botCreationRequest] Запрос на создание бота.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BotApi
     */
    public createBot(botCreationRequest?: BotCreationRequest, options?: RawAxiosRequestConfig) {
        return BotApiFp(this.configuration).createBot(botCreationRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Создать дубликат бота.
     * @param {string} id ИД бота.
     * @param {object} [body] Запрос на создание дубликата бота.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BotApi
     */
    public duplicateBot(id: string, body?: object, options?: RawAxiosRequestConfig) {
        return BotApiFp(this.configuration).duplicateBot(id, body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Получить бота.
     * @param {string} id ИД записи бота.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BotApi
     */
    public getBot(id: string, options?: RawAxiosRequestConfig) {
        return BotApiFp(this.configuration).getBot(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Получить бота по id сложного бота.
     * @param {string} agentId ИД записи сложного бота.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BotApi
     */
    public getBotByAgentId(agentId: string, options?: RawAxiosRequestConfig) {
        return BotApiFp(this.configuration).getBotByAgentId(agentId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Получить список доступных моделей обучения бота.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BotApi
     */
    public getBotTrainingModels(options?: RawAxiosRequestConfig) {
        return BotApiFp(this.configuration).getBotTrainingModels(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Выполнить поиск ботов.
     * @param {string} [search] Текст для поиска.
     * @param {BotSortDirection} [sorting] Параметры сортировки.
     * @param {number} [pageIndex] 
     * @param {number} [pageSize] 
     * @param {boolean} [withTotal] 
     * @param {number} [fromItemInclusive] 
     * @param {number} [toItemExclusive] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BotApi
     */
    public searchBots(search?: string, sorting?: BotSortDirection, pageIndex?: number, pageSize?: number, withTotal?: boolean, fromItemInclusive?: number, toItemExclusive?: number, options?: RawAxiosRequestConfig) {
        return BotApiFp(this.configuration).searchBots(search, sorting, pageIndex, pageSize, withTotal, fromItemInclusive, toItemExclusive, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Обновить бота.
     * @param {string} id ИД бота.
     * @param {BotUpdatingRequest} [botUpdatingRequest] Запрос на обновление бота.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BotApi
     */
    public updateBot(id: string, botUpdatingRequest?: BotUpdatingRequest, options?: RawAxiosRequestConfig) {
        return BotApiFp(this.configuration).updateBot(id, botUpdatingRequest, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * BotEditionApi - axios parameter creator
 * @export
 */
export const BotEditionApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Экспортировать редакцию бота.
         * @param {string} id ИД редакции бота.
         * @param {BotContentFormat} contentFormat Формат содержимого файла.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        exportBotEdition: async (id: string, contentFormat: BotContentFormat, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('exportBotEdition', 'id', id)
            // verify required parameter 'contentFormat' is not null or undefined
            assertParamExists('exportBotEdition', 'contentFormat', contentFormat)
            const localVarPath = `/api/v1/runtime/simple/bot-editions/{id}/export`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth20 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth20", ["elma.assistant.resources.identity.context", "elma.assistant.scopes.api.admin", "email", "offline_access", "openid", "phone", "profile"], configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (contentFormat !== undefined) {
                localVarQueryParameter['ContentFormat'] = contentFormat;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Запустить процедуру экспорта редакции бота.
         * @param {string} id ИД редакции бота.
         * @param {BotContentFormat} contentFormat Формат содержимого файла.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        runBotEditionExport: async (id: string, contentFormat: BotContentFormat, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('runBotEditionExport', 'id', id)
            // verify required parameter 'contentFormat' is not null or undefined
            assertParamExists('runBotEditionExport', 'contentFormat', contentFormat)
            const localVarPath = `/api/v1/runtime/simple/bot-editions/{id}/export`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth20 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth20", ["elma.assistant.resources.identity.context", "elma.assistant.scopes.api.admin", "email", "offline_access", "openid", "phone", "profile"], configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (contentFormat !== undefined) {
                localVarQueryParameter['ContentFormat'] = contentFormat;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * BotEditionApi - functional programming interface
 * @export
 */
export const BotEditionApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = BotEditionApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Экспортировать редакцию бота.
         * @param {string} id ИД редакции бота.
         * @param {BotContentFormat} contentFormat Формат содержимого файла.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async exportBotEdition(id: string, contentFormat: BotContentFormat, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<File>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.exportBotEdition(id, contentFormat, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['BotEditionApi.exportBotEdition']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Запустить процедуру экспорта редакции бота.
         * @param {string} id ИД редакции бота.
         * @param {BotContentFormat} contentFormat Формат содержимого файла.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async runBotEditionExport(id: string, contentFormat: BotContentFormat, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RunBotEditionExportResultModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.runBotEditionExport(id, contentFormat, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['BotEditionApi.runBotEditionExport']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
    }
};

/**
 * BotEditionApi - factory interface
 * @export
 */
export const BotEditionApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = BotEditionApiFp(configuration)
    return {
        /**
         * 
         * @summary Экспортировать редакцию бота.
         * @param {string} id ИД редакции бота.
         * @param {BotContentFormat} contentFormat Формат содержимого файла.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        exportBotEdition(id: string, contentFormat: BotContentFormat, options?: any): AxiosPromise<File> {
            return localVarFp.exportBotEdition(id, contentFormat, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Запустить процедуру экспорта редакции бота.
         * @param {string} id ИД редакции бота.
         * @param {BotContentFormat} contentFormat Формат содержимого файла.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        runBotEditionExport(id: string, contentFormat: BotContentFormat, options?: any): AxiosPromise<RunBotEditionExportResultModel> {
            return localVarFp.runBotEditionExport(id, contentFormat, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * BotEditionApi - object-oriented interface
 * @export
 * @class BotEditionApi
 * @extends {BaseAPI}
 */
export class BotEditionApi extends BaseAPI {
    /**
     * 
     * @summary Экспортировать редакцию бота.
     * @param {string} id ИД редакции бота.
     * @param {BotContentFormat} contentFormat Формат содержимого файла.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BotEditionApi
     */
    public exportBotEdition(id: string, contentFormat: BotContentFormat, options?: RawAxiosRequestConfig) {
        return BotEditionApiFp(this.configuration).exportBotEdition(id, contentFormat, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Запустить процедуру экспорта редакции бота.
     * @param {string} id ИД редакции бота.
     * @param {BotContentFormat} contentFormat Формат содержимого файла.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BotEditionApi
     */
    public runBotEditionExport(id: string, contentFormat: BotContentFormat, options?: RawAxiosRequestConfig) {
        return BotEditionApiFp(this.configuration).runBotEditionExport(id, contentFormat, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * BotStageApi - axios parameter creator
 * @export
 */
export const BotStageApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Добавить существующую редакцию в стадию бота.
         * @param {string} id ИД стадии бота.
         * @param {BotEditionAddingRequest} [botEditionAddingRequest] Запрос на добавление существующей редакции.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addBotEdition: async (id: string, botEditionAddingRequest?: BotEditionAddingRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('addBotEdition', 'id', id)
            const localVarPath = `/api/v1/runtime/simple/bot-stages/{id}/add-bot-edition`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth20 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth20", ["elma.assistant.resources.identity.context", "elma.assistant.scopes.api.admin", "email", "offline_access", "openid", "phone", "profile"], configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(botEditionAddingRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Создать черновую стадию бота.
         * @param {string} id ИД исходной стадии бота.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createDraftBotStage: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('createDraftBotStage', 'id', id)
            const localVarPath = `/api/v1/runtime/simple/bot-stages/{id}/create-draft`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth20 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth20", ["elma.assistant.resources.identity.context", "elma.assistant.scopes.api.admin", "email", "offline_access", "openid", "phone", "profile"], configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Создать сценарий.
         * @param {string} id ИД стадии бота.
         * @param {ScenarioCreationRequest} [scenarioCreationRequest] Запрос на создание сценария.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createScenario: async (id: string, scenarioCreationRequest?: ScenarioCreationRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('createScenario', 'id', id)
            const localVarPath = `/api/v1/runtime/simple/bot-stages/{id}/create-scenario`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth20 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth20", ["elma.assistant.resources.identity.context", "elma.assistant.scopes.api.admin", "email", "offline_access", "openid", "phone", "profile"], configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(scenarioCreationRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Удалить стадию бота.
         * @param {string} id ИД стадии бота.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteBotStage: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteBotStage', 'id', id)
            const localVarPath = `/api/v1/runtime/simple/bot-stages/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth20 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth20", ["elma.assistant.resources.identity.context", "elma.assistant.scopes.api.admin", "email", "offline_access", "openid", "phone", "profile"], configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Удалить сценарий.
         * @param {string} id ИД стадии бота.
         * @param {string} scenarioStructureId ИД структуры сценария для удаления.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteScenario: async (id: string, scenarioStructureId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteScenario', 'id', id)
            // verify required parameter 'scenarioStructureId' is not null or undefined
            assertParamExists('deleteScenario', 'scenarioStructureId', scenarioStructureId)
            const localVarPath = `/api/v1/runtime/simple/bot-stages/{id}/delete-scenario/{scenarioStructureId}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"scenarioStructureId"}}`, encodeURIComponent(String(scenarioStructureId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth20 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth20", ["elma.assistant.resources.identity.context", "elma.assistant.scopes.api.admin", "email", "offline_access", "openid", "phone", "profile"], configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Дублировать сценарий.
         * @param {string} id ИД стадии бота.
         * @param {ScenarioDuplicationRequest} [scenarioDuplicationRequest] Запрос на дублирование сценария.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        duplicateScenario: async (id: string, scenarioDuplicationRequest?: ScenarioDuplicationRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('duplicateScenario', 'id', id)
            const localVarPath = `/api/v1/runtime/simple/bot-stages/{id}/duplicate-scenario`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth20 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth20", ["elma.assistant.resources.identity.context", "elma.assistant.scopes.api.admin", "email", "offline_access", "openid", "phone", "profile"], configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(scenarioDuplicationRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Экспортировать сценарий.
         * @param {string} id ИД стадии бота.
         * @param {string} scenarioStructureId ИД структуры сценария.
         * @param {ScenarioContentFormat} contentFormat Формат содержимого файла.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        exportScenario: async (id: string, scenarioStructureId: string, contentFormat: ScenarioContentFormat, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('exportScenario', 'id', id)
            // verify required parameter 'scenarioStructureId' is not null or undefined
            assertParamExists('exportScenario', 'scenarioStructureId', scenarioStructureId)
            // verify required parameter 'contentFormat' is not null or undefined
            assertParamExists('exportScenario', 'contentFormat', contentFormat)
            const localVarPath = `/api/v1/runtime/simple/bot-stages/{id}/export-scenario`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth20 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth20", ["elma.assistant.resources.identity.context", "elma.assistant.scopes.api.admin", "email", "offline_access", "openid", "phone", "profile"], configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (scenarioStructureId !== undefined) {
                localVarQueryParameter['ScenarioStructureId'] = scenarioStructureId;
            }

            if (contentFormat !== undefined) {
                localVarQueryParameter['ContentFormat'] = contentFormat;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Получить данные для тестирования стадии бота.
         * @param {string} id ИД стадии бота.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBotStageTestingState: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getBotStageTestingState', 'id', id)
            const localVarPath = `/api/v1/runtime/simple/bot-stages/{id}/test-bot`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth20 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth20", ["elma.assistant.resources.identity.context", "elma.assistant.scopes.api.admin", "email", "offline_access", "openid", "phone", "profile"], configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Получить сценарий.
         * @param {string} id ИД стадии бота.
         * @param {string} scenarioStructureId ИД структуры сценария.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getScenario: async (id: string, scenarioStructureId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getScenario', 'id', id)
            // verify required parameter 'scenarioStructureId' is not null or undefined
            assertParamExists('getScenario', 'scenarioStructureId', scenarioStructureId)
            const localVarPath = `/api/v1/runtime/simple/bot-stages/{id}/get-scenario`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth20 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth20", ["elma.assistant.resources.identity.context", "elma.assistant.scopes.api.admin", "email", "offline_access", "openid", "phone", "profile"], configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (scenarioStructureId !== undefined) {
                localVarQueryParameter['ScenarioStructureId'] = scenarioStructureId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Получить данные для тестирования сценария.
         * @param {string} id ИД стадии бота.
         * @param {string} scenarioStructureId Структура сценария.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getScenarioTestingState: async (id: string, scenarioStructureId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getScenarioTestingState', 'id', id)
            // verify required parameter 'scenarioStructureId' is not null or undefined
            assertParamExists('getScenarioTestingState', 'scenarioStructureId', scenarioStructureId)
            const localVarPath = `/api/v1/runtime/simple/bot-stages/{id}/test-scenario`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth20 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth20", ["elma.assistant.resources.identity.context", "elma.assistant.scopes.api.admin", "email", "offline_access", "openid", "phone", "profile"], configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (scenarioStructureId !== undefined) {
                localVarQueryParameter['ScenarioStructureId'] = scenarioStructureId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Выполнить поиск сценариев, в которых используется указанный сценарий.
         * @param {string} id ИД стадии бота.
         * @param {string} [scenarioStructureId] ИД структуры сценария.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getScenarioUsages: async (id: string, scenarioStructureId?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getScenarioUsages', 'id', id)
            const localVarPath = `/api/v1/runtime/simple/bot-stages/{id}/scenario-usages`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth20 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth20", ["elma.assistant.resources.identity.context", "elma.assistant.scopes.api.admin", "email", "offline_access", "openid", "phone", "profile"], configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (scenarioStructureId !== undefined) {
                localVarQueryParameter['ScenarioStructureId'] = scenarioStructureId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Импортировать бота.
         * @param {string} id ИД стадии бота.
         * @param {BotImportRequest} [botImportRequest] Запрос на импорт бота.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        importBot: async (id: string, botImportRequest?: BotImportRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('importBot', 'id', id)
            const localVarPath = `/api/v1/runtime/simple/bot-stages/{id}/import-bot`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth20 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth20", ["elma.assistant.resources.identity.context", "elma.assistant.scopes.api.admin", "email", "offline_access", "openid", "phone", "profile"], configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(botImportRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Объединить одну стадию бота с другой.
         * @param {string} id ИД исходной стадии бота.
         * @param {BotStageMergeRequest} [botStageMergeRequest] Запрос на объединение с исходной стадией бота.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mergeBotStage: async (id: string, botStageMergeRequest?: BotStageMergeRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('mergeBotStage', 'id', id)
            const localVarPath = `/api/v1/runtime/simple/bot-stages/{id}/merge`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth20 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth20", ["elma.assistant.resources.identity.context", "elma.assistant.scopes.api.admin", "email", "offline_access", "openid", "phone", "profile"], configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(botStageMergeRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Запустить процедуру экспорта сценария.
         * @param {string} id ИД стадии бота.
         * @param {string} scenarioStructureId ИД структуры сценария.
         * @param {ScenarioContentFormat} contentFormat Формат содержимого файла.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        runScenarioExport: async (id: string, scenarioStructureId: string, contentFormat: ScenarioContentFormat, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('runScenarioExport', 'id', id)
            // verify required parameter 'scenarioStructureId' is not null or undefined
            assertParamExists('runScenarioExport', 'scenarioStructureId', scenarioStructureId)
            // verify required parameter 'contentFormat' is not null or undefined
            assertParamExists('runScenarioExport', 'contentFormat', contentFormat)
            const localVarPath = `/api/v1/runtime/simple/bot-stages/{id}/run-scenario-export`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth20 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth20", ["elma.assistant.resources.identity.context", "elma.assistant.scopes.api.admin", "email", "offline_access", "openid", "phone", "profile"], configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (scenarioStructureId !== undefined) {
                localVarQueryParameter['ScenarioStructureId'] = scenarioStructureId;
            }

            if (contentFormat !== undefined) {
                localVarQueryParameter['ContentFormat'] = contentFormat;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Тестировать стадию бота.
         * @param {string} id ИД стадии бота.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testBotStage: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('testBotStage', 'id', id)
            const localVarPath = `/api/v1/runtime/simple/bot-stages/{id}/test-bot`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth20 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth20", ["elma.assistant.resources.identity.context", "elma.assistant.scopes.api.admin", "email", "offline_access", "openid", "phone", "profile"], configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Тестировать сценарий.
         * @param {string} id ИД стадии бота.
         * @param {ScenarioTestingRequest} [scenarioTestingRequest] Запрос на тестирование сценария.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testScenario: async (id: string, scenarioTestingRequest?: ScenarioTestingRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('testScenario', 'id', id)
            const localVarPath = `/api/v1/runtime/simple/bot-stages/{id}/test-scenario`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth20 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth20", ["elma.assistant.resources.identity.context", "elma.assistant.scopes.api.admin", "email", "offline_access", "openid", "phone", "profile"], configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(scenarioTestingRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Обновить сценарий.
         * @param {string} id ИД стадии бота.
         * @param {ScenarioUpdatingRequest} [scenarioUpdatingRequest] Запрос на сохранение сценария.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateScenario: async (id: string, scenarioUpdatingRequest?: ScenarioUpdatingRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateScenario', 'id', id)
            const localVarPath = `/api/v1/runtime/simple/bot-stages/{id}/update-scenario`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth20 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth20", ["elma.assistant.resources.identity.context", "elma.assistant.scopes.api.admin", "email", "offline_access", "openid", "phone", "profile"], configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(scenarioUpdatingRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Обновить настройки бота.
         * @param {string} id ИД стадии бота.
         * @param {SettingsUpdatingRequest} [settingsUpdatingRequest] Запрос на обновление настроек бота.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSettings: async (id: string, settingsUpdatingRequest?: SettingsUpdatingRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateSettings', 'id', id)
            const localVarPath = `/api/v1/runtime/simple/bot-stages/{id}/update-settings`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth20 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth20", ["elma.assistant.resources.identity.context", "elma.assistant.scopes.api.admin", "email", "offline_access", "openid", "phone", "profile"], configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(settingsUpdatingRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Выполнить валидацию сценария.
         * @param {string} id ИД стадии бота.
         * @param {ScenarioValidationRequest} [scenarioValidationRequest] Запрос на валидацию сценария.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        validateScenario: async (id: string, scenarioValidationRequest?: ScenarioValidationRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('validateScenario', 'id', id)
            const localVarPath = `/api/v1/runtime/simple/bot-stages/{id}/validate-scenario`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth20 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth20", ["elma.assistant.resources.identity.context", "elma.assistant.scopes.api.admin", "email", "offline_access", "openid", "phone", "profile"], configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(scenarioValidationRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * BotStageApi - functional programming interface
 * @export
 */
export const BotStageApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = BotStageApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Добавить существующую редакцию в стадию бота.
         * @param {string} id ИД стадии бота.
         * @param {BotEditionAddingRequest} [botEditionAddingRequest] Запрос на добавление существующей редакции.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addBotEdition(id: string, botEditionAddingRequest?: BotEditionAddingRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BotStageModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addBotEdition(id, botEditionAddingRequest, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['BotStageApi.addBotEdition']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Создать черновую стадию бота.
         * @param {string} id ИД исходной стадии бота.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createDraftBotStage(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BotStageCreationResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createDraftBotStage(id, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['BotStageApi.createDraftBotStage']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Создать сценарий.
         * @param {string} id ИД стадии бота.
         * @param {ScenarioCreationRequest} [scenarioCreationRequest] Запрос на создание сценария.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createScenario(id: string, scenarioCreationRequest?: ScenarioCreationRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BotStageModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createScenario(id, scenarioCreationRequest, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['BotStageApi.createScenario']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Удалить стадию бота.
         * @param {string} id ИД стадии бота.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteBotStage(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteBotStage(id, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['BotStageApi.deleteBotStage']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Удалить сценарий.
         * @param {string} id ИД стадии бота.
         * @param {string} scenarioStructureId ИД структуры сценария для удаления.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteScenario(id: string, scenarioStructureId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BotStageModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteScenario(id, scenarioStructureId, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['BotStageApi.deleteScenario']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Дублировать сценарий.
         * @param {string} id ИД стадии бота.
         * @param {ScenarioDuplicationRequest} [scenarioDuplicationRequest] Запрос на дублирование сценария.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async duplicateScenario(id: string, scenarioDuplicationRequest?: ScenarioDuplicationRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BotStageModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.duplicateScenario(id, scenarioDuplicationRequest, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['BotStageApi.duplicateScenario']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Экспортировать сценарий.
         * @param {string} id ИД стадии бота.
         * @param {string} scenarioStructureId ИД структуры сценария.
         * @param {ScenarioContentFormat} contentFormat Формат содержимого файла.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async exportScenario(id: string, scenarioStructureId: string, contentFormat: ScenarioContentFormat, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<File>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.exportScenario(id, scenarioStructureId, contentFormat, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['BotStageApi.exportScenario']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Получить данные для тестирования стадии бота.
         * @param {string} id ИД стадии бота.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getBotStageTestingState(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BotStageTestingResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getBotStageTestingState(id, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['BotStageApi.getBotStageTestingState']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Получить сценарий.
         * @param {string} id ИД стадии бота.
         * @param {string} scenarioStructureId ИД структуры сценария.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getScenario(id: string, scenarioStructureId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ScenarioEditionModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getScenario(id, scenarioStructureId, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['BotStageApi.getScenario']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Получить данные для тестирования сценария.
         * @param {string} id ИД стадии бота.
         * @param {string} scenarioStructureId Структура сценария.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getScenarioTestingState(id: string, scenarioStructureId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ScenarioTestingResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getScenarioTestingState(id, scenarioStructureId, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['BotStageApi.getScenarioTestingState']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Выполнить поиск сценариев, в которых используется указанный сценарий.
         * @param {string} id ИД стадии бота.
         * @param {string} [scenarioStructureId] ИД структуры сценария.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getScenarioUsages(id: string, scenarioStructureId?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ScenarioGetUsagesResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getScenarioUsages(id, scenarioStructureId, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['BotStageApi.getScenarioUsages']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Импортировать бота.
         * @param {string} id ИД стадии бота.
         * @param {BotImportRequest} [botImportRequest] Запрос на импорт бота.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async importBot(id: string, botImportRequest?: BotImportRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BotStageModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.importBot(id, botImportRequest, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['BotStageApi.importBot']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Объединить одну стадию бота с другой.
         * @param {string} id ИД исходной стадии бота.
         * @param {BotStageMergeRequest} [botStageMergeRequest] Запрос на объединение с исходной стадией бота.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async mergeBotStage(id: string, botStageMergeRequest?: BotStageMergeRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BotStageMergeResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.mergeBotStage(id, botStageMergeRequest, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['BotStageApi.mergeBotStage']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Запустить процедуру экспорта сценария.
         * @param {string} id ИД стадии бота.
         * @param {string} scenarioStructureId ИД структуры сценария.
         * @param {ScenarioContentFormat} contentFormat Формат содержимого файла.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async runScenarioExport(id: string, scenarioStructureId: string, contentFormat: ScenarioContentFormat, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RunScenarioExportResultModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.runScenarioExport(id, scenarioStructureId, contentFormat, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['BotStageApi.runScenarioExport']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Тестировать стадию бота.
         * @param {string} id ИД стадии бота.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async testBotStage(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BotStageTestingResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.testBotStage(id, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['BotStageApi.testBotStage']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Тестировать сценарий.
         * @param {string} id ИД стадии бота.
         * @param {ScenarioTestingRequest} [scenarioTestingRequest] Запрос на тестирование сценария.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async testScenario(id: string, scenarioTestingRequest?: ScenarioTestingRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ScenarioTestingResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.testScenario(id, scenarioTestingRequest, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['BotStageApi.testScenario']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Обновить сценарий.
         * @param {string} id ИД стадии бота.
         * @param {ScenarioUpdatingRequest} [scenarioUpdatingRequest] Запрос на сохранение сценария.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateScenario(id: string, scenarioUpdatingRequest?: ScenarioUpdatingRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BotStageModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateScenario(id, scenarioUpdatingRequest, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['BotStageApi.updateScenario']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Обновить настройки бота.
         * @param {string} id ИД стадии бота.
         * @param {SettingsUpdatingRequest} [settingsUpdatingRequest] Запрос на обновление настроек бота.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateSettings(id: string, settingsUpdatingRequest?: SettingsUpdatingRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BotStageModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateSettings(id, settingsUpdatingRequest, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['BotStageApi.updateSettings']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Выполнить валидацию сценария.
         * @param {string} id ИД стадии бота.
         * @param {ScenarioValidationRequest} [scenarioValidationRequest] Запрос на валидацию сценария.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async validateScenario(id: string, scenarioValidationRequest?: ScenarioValidationRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ScenarioValidationResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.validateScenario(id, scenarioValidationRequest, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['BotStageApi.validateScenario']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
    }
};

/**
 * BotStageApi - factory interface
 * @export
 */
export const BotStageApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = BotStageApiFp(configuration)
    return {
        /**
         * 
         * @summary Добавить существующую редакцию в стадию бота.
         * @param {string} id ИД стадии бота.
         * @param {BotEditionAddingRequest} [botEditionAddingRequest] Запрос на добавление существующей редакции.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addBotEdition(id: string, botEditionAddingRequest?: BotEditionAddingRequest, options?: any): AxiosPromise<BotStageModel> {
            return localVarFp.addBotEdition(id, botEditionAddingRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Создать черновую стадию бота.
         * @param {string} id ИД исходной стадии бота.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createDraftBotStage(id: string, options?: any): AxiosPromise<BotStageCreationResponse> {
            return localVarFp.createDraftBotStage(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Создать сценарий.
         * @param {string} id ИД стадии бота.
         * @param {ScenarioCreationRequest} [scenarioCreationRequest] Запрос на создание сценария.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createScenario(id: string, scenarioCreationRequest?: ScenarioCreationRequest, options?: any): AxiosPromise<BotStageModel> {
            return localVarFp.createScenario(id, scenarioCreationRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Удалить стадию бота.
         * @param {string} id ИД стадии бота.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteBotStage(id: string, options?: any): AxiosPromise<object> {
            return localVarFp.deleteBotStage(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Удалить сценарий.
         * @param {string} id ИД стадии бота.
         * @param {string} scenarioStructureId ИД структуры сценария для удаления.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteScenario(id: string, scenarioStructureId: string, options?: any): AxiosPromise<BotStageModel> {
            return localVarFp.deleteScenario(id, scenarioStructureId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Дублировать сценарий.
         * @param {string} id ИД стадии бота.
         * @param {ScenarioDuplicationRequest} [scenarioDuplicationRequest] Запрос на дублирование сценария.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        duplicateScenario(id: string, scenarioDuplicationRequest?: ScenarioDuplicationRequest, options?: any): AxiosPromise<BotStageModel> {
            return localVarFp.duplicateScenario(id, scenarioDuplicationRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Экспортировать сценарий.
         * @param {string} id ИД стадии бота.
         * @param {string} scenarioStructureId ИД структуры сценария.
         * @param {ScenarioContentFormat} contentFormat Формат содержимого файла.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        exportScenario(id: string, scenarioStructureId: string, contentFormat: ScenarioContentFormat, options?: any): AxiosPromise<File> {
            return localVarFp.exportScenario(id, scenarioStructureId, contentFormat, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Получить данные для тестирования стадии бота.
         * @param {string} id ИД стадии бота.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBotStageTestingState(id: string, options?: any): AxiosPromise<BotStageTestingResponse> {
            return localVarFp.getBotStageTestingState(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Получить сценарий.
         * @param {string} id ИД стадии бота.
         * @param {string} scenarioStructureId ИД структуры сценария.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getScenario(id: string, scenarioStructureId: string, options?: any): AxiosPromise<ScenarioEditionModel> {
            return localVarFp.getScenario(id, scenarioStructureId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Получить данные для тестирования сценария.
         * @param {string} id ИД стадии бота.
         * @param {string} scenarioStructureId Структура сценария.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getScenarioTestingState(id: string, scenarioStructureId: string, options?: any): AxiosPromise<ScenarioTestingResponse> {
            return localVarFp.getScenarioTestingState(id, scenarioStructureId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Выполнить поиск сценариев, в которых используется указанный сценарий.
         * @param {string} id ИД стадии бота.
         * @param {string} [scenarioStructureId] ИД структуры сценария.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getScenarioUsages(id: string, scenarioStructureId?: string, options?: any): AxiosPromise<ScenarioGetUsagesResponse> {
            return localVarFp.getScenarioUsages(id, scenarioStructureId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Импортировать бота.
         * @param {string} id ИД стадии бота.
         * @param {BotImportRequest} [botImportRequest] Запрос на импорт бота.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        importBot(id: string, botImportRequest?: BotImportRequest, options?: any): AxiosPromise<BotStageModel> {
            return localVarFp.importBot(id, botImportRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Объединить одну стадию бота с другой.
         * @param {string} id ИД исходной стадии бота.
         * @param {BotStageMergeRequest} [botStageMergeRequest] Запрос на объединение с исходной стадией бота.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mergeBotStage(id: string, botStageMergeRequest?: BotStageMergeRequest, options?: any): AxiosPromise<BotStageMergeResponse> {
            return localVarFp.mergeBotStage(id, botStageMergeRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Запустить процедуру экспорта сценария.
         * @param {string} id ИД стадии бота.
         * @param {string} scenarioStructureId ИД структуры сценария.
         * @param {ScenarioContentFormat} contentFormat Формат содержимого файла.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        runScenarioExport(id: string, scenarioStructureId: string, contentFormat: ScenarioContentFormat, options?: any): AxiosPromise<RunScenarioExportResultModel> {
            return localVarFp.runScenarioExport(id, scenarioStructureId, contentFormat, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Тестировать стадию бота.
         * @param {string} id ИД стадии бота.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testBotStage(id: string, options?: any): AxiosPromise<BotStageTestingResponse> {
            return localVarFp.testBotStage(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Тестировать сценарий.
         * @param {string} id ИД стадии бота.
         * @param {ScenarioTestingRequest} [scenarioTestingRequest] Запрос на тестирование сценария.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testScenario(id: string, scenarioTestingRequest?: ScenarioTestingRequest, options?: any): AxiosPromise<ScenarioTestingResponse> {
            return localVarFp.testScenario(id, scenarioTestingRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Обновить сценарий.
         * @param {string} id ИД стадии бота.
         * @param {ScenarioUpdatingRequest} [scenarioUpdatingRequest] Запрос на сохранение сценария.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateScenario(id: string, scenarioUpdatingRequest?: ScenarioUpdatingRequest, options?: any): AxiosPromise<BotStageModel> {
            return localVarFp.updateScenario(id, scenarioUpdatingRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Обновить настройки бота.
         * @param {string} id ИД стадии бота.
         * @param {SettingsUpdatingRequest} [settingsUpdatingRequest] Запрос на обновление настроек бота.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSettings(id: string, settingsUpdatingRequest?: SettingsUpdatingRequest, options?: any): AxiosPromise<BotStageModel> {
            return localVarFp.updateSettings(id, settingsUpdatingRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Выполнить валидацию сценария.
         * @param {string} id ИД стадии бота.
         * @param {ScenarioValidationRequest} [scenarioValidationRequest] Запрос на валидацию сценария.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        validateScenario(id: string, scenarioValidationRequest?: ScenarioValidationRequest, options?: any): AxiosPromise<ScenarioValidationResponse> {
            return localVarFp.validateScenario(id, scenarioValidationRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * BotStageApi - object-oriented interface
 * @export
 * @class BotStageApi
 * @extends {BaseAPI}
 */
export class BotStageApi extends BaseAPI {
    /**
     * 
     * @summary Добавить существующую редакцию в стадию бота.
     * @param {string} id ИД стадии бота.
     * @param {BotEditionAddingRequest} [botEditionAddingRequest] Запрос на добавление существующей редакции.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BotStageApi
     */
    public addBotEdition(id: string, botEditionAddingRequest?: BotEditionAddingRequest, options?: RawAxiosRequestConfig) {
        return BotStageApiFp(this.configuration).addBotEdition(id, botEditionAddingRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Создать черновую стадию бота.
     * @param {string} id ИД исходной стадии бота.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BotStageApi
     */
    public createDraftBotStage(id: string, options?: RawAxiosRequestConfig) {
        return BotStageApiFp(this.configuration).createDraftBotStage(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Создать сценарий.
     * @param {string} id ИД стадии бота.
     * @param {ScenarioCreationRequest} [scenarioCreationRequest] Запрос на создание сценария.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BotStageApi
     */
    public createScenario(id: string, scenarioCreationRequest?: ScenarioCreationRequest, options?: RawAxiosRequestConfig) {
        return BotStageApiFp(this.configuration).createScenario(id, scenarioCreationRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Удалить стадию бота.
     * @param {string} id ИД стадии бота.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BotStageApi
     */
    public deleteBotStage(id: string, options?: RawAxiosRequestConfig) {
        return BotStageApiFp(this.configuration).deleteBotStage(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Удалить сценарий.
     * @param {string} id ИД стадии бота.
     * @param {string} scenarioStructureId ИД структуры сценария для удаления.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BotStageApi
     */
    public deleteScenario(id: string, scenarioStructureId: string, options?: RawAxiosRequestConfig) {
        return BotStageApiFp(this.configuration).deleteScenario(id, scenarioStructureId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Дублировать сценарий.
     * @param {string} id ИД стадии бота.
     * @param {ScenarioDuplicationRequest} [scenarioDuplicationRequest] Запрос на дублирование сценария.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BotStageApi
     */
    public duplicateScenario(id: string, scenarioDuplicationRequest?: ScenarioDuplicationRequest, options?: RawAxiosRequestConfig) {
        return BotStageApiFp(this.configuration).duplicateScenario(id, scenarioDuplicationRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Экспортировать сценарий.
     * @param {string} id ИД стадии бота.
     * @param {string} scenarioStructureId ИД структуры сценария.
     * @param {ScenarioContentFormat} contentFormat Формат содержимого файла.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BotStageApi
     */
    public exportScenario(id: string, scenarioStructureId: string, contentFormat: ScenarioContentFormat, options?: RawAxiosRequestConfig) {
        return BotStageApiFp(this.configuration).exportScenario(id, scenarioStructureId, contentFormat, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Получить данные для тестирования стадии бота.
     * @param {string} id ИД стадии бота.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BotStageApi
     */
    public getBotStageTestingState(id: string, options?: RawAxiosRequestConfig) {
        return BotStageApiFp(this.configuration).getBotStageTestingState(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Получить сценарий.
     * @param {string} id ИД стадии бота.
     * @param {string} scenarioStructureId ИД структуры сценария.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BotStageApi
     */
    public getScenario(id: string, scenarioStructureId: string, options?: RawAxiosRequestConfig) {
        return BotStageApiFp(this.configuration).getScenario(id, scenarioStructureId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Получить данные для тестирования сценария.
     * @param {string} id ИД стадии бота.
     * @param {string} scenarioStructureId Структура сценария.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BotStageApi
     */
    public getScenarioTestingState(id: string, scenarioStructureId: string, options?: RawAxiosRequestConfig) {
        return BotStageApiFp(this.configuration).getScenarioTestingState(id, scenarioStructureId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Выполнить поиск сценариев, в которых используется указанный сценарий.
     * @param {string} id ИД стадии бота.
     * @param {string} [scenarioStructureId] ИД структуры сценария.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BotStageApi
     */
    public getScenarioUsages(id: string, scenarioStructureId?: string, options?: RawAxiosRequestConfig) {
        return BotStageApiFp(this.configuration).getScenarioUsages(id, scenarioStructureId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Импортировать бота.
     * @param {string} id ИД стадии бота.
     * @param {BotImportRequest} [botImportRequest] Запрос на импорт бота.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BotStageApi
     */
    public importBot(id: string, botImportRequest?: BotImportRequest, options?: RawAxiosRequestConfig) {
        return BotStageApiFp(this.configuration).importBot(id, botImportRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Объединить одну стадию бота с другой.
     * @param {string} id ИД исходной стадии бота.
     * @param {BotStageMergeRequest} [botStageMergeRequest] Запрос на объединение с исходной стадией бота.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BotStageApi
     */
    public mergeBotStage(id: string, botStageMergeRequest?: BotStageMergeRequest, options?: RawAxiosRequestConfig) {
        return BotStageApiFp(this.configuration).mergeBotStage(id, botStageMergeRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Запустить процедуру экспорта сценария.
     * @param {string} id ИД стадии бота.
     * @param {string} scenarioStructureId ИД структуры сценария.
     * @param {ScenarioContentFormat} contentFormat Формат содержимого файла.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BotStageApi
     */
    public runScenarioExport(id: string, scenarioStructureId: string, contentFormat: ScenarioContentFormat, options?: RawAxiosRequestConfig) {
        return BotStageApiFp(this.configuration).runScenarioExport(id, scenarioStructureId, contentFormat, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Тестировать стадию бота.
     * @param {string} id ИД стадии бота.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BotStageApi
     */
    public testBotStage(id: string, options?: RawAxiosRequestConfig) {
        return BotStageApiFp(this.configuration).testBotStage(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Тестировать сценарий.
     * @param {string} id ИД стадии бота.
     * @param {ScenarioTestingRequest} [scenarioTestingRequest] Запрос на тестирование сценария.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BotStageApi
     */
    public testScenario(id: string, scenarioTestingRequest?: ScenarioTestingRequest, options?: RawAxiosRequestConfig) {
        return BotStageApiFp(this.configuration).testScenario(id, scenarioTestingRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Обновить сценарий.
     * @param {string} id ИД стадии бота.
     * @param {ScenarioUpdatingRequest} [scenarioUpdatingRequest] Запрос на сохранение сценария.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BotStageApi
     */
    public updateScenario(id: string, scenarioUpdatingRequest?: ScenarioUpdatingRequest, options?: RawAxiosRequestConfig) {
        return BotStageApiFp(this.configuration).updateScenario(id, scenarioUpdatingRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Обновить настройки бота.
     * @param {string} id ИД стадии бота.
     * @param {SettingsUpdatingRequest} [settingsUpdatingRequest] Запрос на обновление настроек бота.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BotStageApi
     */
    public updateSettings(id: string, settingsUpdatingRequest?: SettingsUpdatingRequest, options?: RawAxiosRequestConfig) {
        return BotStageApiFp(this.configuration).updateSettings(id, settingsUpdatingRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Выполнить валидацию сценария.
     * @param {string} id ИД стадии бота.
     * @param {ScenarioValidationRequest} [scenarioValidationRequest] Запрос на валидацию сценария.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BotStageApi
     */
    public validateScenario(id: string, scenarioValidationRequest?: ScenarioValidationRequest, options?: RawAxiosRequestConfig) {
        return BotStageApiFp(this.configuration).validateScenario(id, scenarioValidationRequest, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * BotTemplateApi - axios parameter creator
 * @export
 */
export const BotTemplateApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Получить список шаблонов ботов.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllBotTemplates: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/runtime/simple/templates/bot`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth20 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth20", ["elma.assistant.resources.identity.context", "elma.assistant.scopes.api.admin", "email", "offline_access", "openid", "phone", "profile"], configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * BotTemplateApi - functional programming interface
 * @export
 */
export const BotTemplateApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = BotTemplateApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Получить список шаблонов ботов.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllBotTemplates(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ListBotTemplateModel>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllBotTemplates(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['BotTemplateApi.getAllBotTemplates']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
    }
};

/**
 * BotTemplateApi - factory interface
 * @export
 */
export const BotTemplateApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = BotTemplateApiFp(configuration)
    return {
        /**
         * 
         * @summary Получить список шаблонов ботов.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllBotTemplates(options?: any): AxiosPromise<Array<ListBotTemplateModel>> {
            return localVarFp.getAllBotTemplates(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * BotTemplateApi - object-oriented interface
 * @export
 * @class BotTemplateApi
 * @extends {BaseAPI}
 */
export class BotTemplateApi extends BaseAPI {
    /**
     * 
     * @summary Получить список шаблонов ботов.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BotTemplateApi
     */
    public getAllBotTemplates(options?: RawAxiosRequestConfig) {
        return BotTemplateApiFp(this.configuration).getAllBotTemplates(options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * BotTemplatesApi - axios parameter creator
 * @export
 */
export const BotTemplatesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Получить список готовых шаблонов ботов.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBotTemplates: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/botstorage/templates`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth20 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth20", ["elma.assistant.resources.identity.context", "elma.assistant.scopes.api.admin", "email", "offline_access", "openid", "phone", "profile"], configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * BotTemplatesApi - functional programming interface
 * @export
 */
export const BotTemplatesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = BotTemplatesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Получить список готовых шаблонов ботов.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getBotTemplates(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<BotTemplateModel>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getBotTemplates(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['BotTemplatesApi.getBotTemplates']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
    }
};

/**
 * BotTemplatesApi - factory interface
 * @export
 */
export const BotTemplatesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = BotTemplatesApiFp(configuration)
    return {
        /**
         * 
         * @summary Получить список готовых шаблонов ботов.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBotTemplates(options?: any): AxiosPromise<Array<BotTemplateModel>> {
            return localVarFp.getBotTemplates(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * BotTemplatesApi - object-oriented interface
 * @export
 * @class BotTemplatesApi
 * @extends {BaseAPI}
 */
export class BotTemplatesApi extends BaseAPI {
    /**
     * 
     * @summary Получить список готовых шаблонов ботов.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BotTemplatesApi
     */
    public getBotTemplates(options?: RawAxiosRequestConfig) {
        return BotTemplatesApiFp(this.configuration).getBotTemplates(options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * BotVersionApi - axios parameter creator
 * @export
 */
export const BotVersionApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Добавление новой версии бота.
         * @param {BotVersionCreationRequest} [botVersionCreationRequest] Запрос на добавление версии бота.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createBotVersion: async (botVersionCreationRequest?: BotVersionCreationRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/botstorage/versions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth20 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth20", ["elma.assistant.resources.identity.context", "elma.assistant.scopes.api.admin", "email", "offline_access", "openid", "phone", "profile"], configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(botVersionCreationRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Получить ссылку на редактирование последней версии бота.
         * @param {string} [agentId] ИД бота.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBotEditUrl: async (agentId?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/botstorage/versions/get-bot-edit-url`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth20 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth20", ["elma.assistant.resources.identity.context", "elma.assistant.scopes.api.admin", "email", "offline_access", "openid", "phone", "profile"], configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (agentId !== undefined) {
                localVarQueryParameter['agentId'] = agentId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Получить файлы версии бота.
         * @param {string} [versionId] ИД версии бота.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBotFiles: async (versionId?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/botstorage/versions/get-bot-files`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth20 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth20", ["elma.assistant.resources.identity.context", "elma.assistant.scopes.api.admin", "email", "offline_access", "openid", "phone", "profile"], configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (versionId !== undefined) {
                localVarQueryParameter['versionId'] = versionId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Получить версию бота.
         * @param {string} id ИД версии бота.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBotVersion: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getBotVersion', 'id', id)
            const localVarPath = `/api/v1/botstorage/versions/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth20 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth20", ["elma.assistant.resources.identity.context", "elma.assistant.scopes.api.admin", "email", "offline_access", "openid", "phone", "profile"], configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Выполнить поиск версий бота.
         * @param {string} [agentId] ИД агента.
         * @param {string} [hash] Хэш.
         * @param {number} [pageIndex] 
         * @param {number} [pageSize] 
         * @param {boolean} [withTotal] 
         * @param {number} [fromItemInclusive] 
         * @param {number} [toItemExclusive] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchBotVersions: async (agentId?: string, hash?: string, pageIndex?: number, pageSize?: number, withTotal?: boolean, fromItemInclusive?: number, toItemExclusive?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/botstorage/versions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth20 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth20", ["elma.assistant.resources.identity.context", "elma.assistant.scopes.api.admin", "email", "offline_access", "openid", "phone", "profile"], configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (agentId !== undefined) {
                localVarQueryParameter['AgentId'] = agentId;
            }

            if (hash !== undefined) {
                localVarQueryParameter['Hash'] = hash;
            }

            if (pageIndex !== undefined) {
                localVarQueryParameter['PageIndex'] = pageIndex;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['PageSize'] = pageSize;
            }

            if (withTotal !== undefined) {
                localVarQueryParameter['WithTotal'] = withTotal;
            }

            if (fromItemInclusive !== undefined) {
                localVarQueryParameter['FromItemInclusive'] = fromItemInclusive;
            }

            if (toItemExclusive !== undefined) {
                localVarQueryParameter['ToItemExclusive'] = toItemExclusive;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * BotVersionApi - functional programming interface
 * @export
 */
export const BotVersionApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = BotVersionApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Добавление новой версии бота.
         * @param {BotVersionCreationRequest} [botVersionCreationRequest] Запрос на добавление версии бота.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createBotVersion(botVersionCreationRequest?: BotVersionCreationRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BotVersionCreationResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createBotVersion(botVersionCreationRequest, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['BotVersionApi.createBotVersion']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Получить ссылку на редактирование последней версии бота.
         * @param {string} [agentId] ИД бота.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getBotEditUrl(agentId?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getBotEditUrl(agentId, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['BotVersionApi.getBotEditUrl']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Получить файлы версии бота.
         * @param {string} [versionId] ИД версии бота.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getBotFiles(versionId?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<File>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getBotFiles(versionId, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['BotVersionApi.getBotFiles']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Получить версию бота.
         * @param {string} id ИД версии бота.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getBotVersion(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BotVersionModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getBotVersion(id, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['BotVersionApi.getBotVersion']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Выполнить поиск версий бота.
         * @param {string} [agentId] ИД агента.
         * @param {string} [hash] Хэш.
         * @param {number} [pageIndex] 
         * @param {number} [pageSize] 
         * @param {boolean} [withTotal] 
         * @param {number} [fromItemInclusive] 
         * @param {number} [toItemExclusive] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async searchBotVersions(agentId?: string, hash?: string, pageIndex?: number, pageSize?: number, withTotal?: boolean, fromItemInclusive?: number, toItemExclusive?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BotVersionModelPaginationResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.searchBotVersions(agentId, hash, pageIndex, pageSize, withTotal, fromItemInclusive, toItemExclusive, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['BotVersionApi.searchBotVersions']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
    }
};

/**
 * BotVersionApi - factory interface
 * @export
 */
export const BotVersionApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = BotVersionApiFp(configuration)
    return {
        /**
         * 
         * @summary Добавление новой версии бота.
         * @param {BotVersionCreationRequest} [botVersionCreationRequest] Запрос на добавление версии бота.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createBotVersion(botVersionCreationRequest?: BotVersionCreationRequest, options?: any): AxiosPromise<BotVersionCreationResponse> {
            return localVarFp.createBotVersion(botVersionCreationRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Получить ссылку на редактирование последней версии бота.
         * @param {string} [agentId] ИД бота.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBotEditUrl(agentId?: string, options?: any): AxiosPromise<string> {
            return localVarFp.getBotEditUrl(agentId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Получить файлы версии бота.
         * @param {string} [versionId] ИД версии бота.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBotFiles(versionId?: string, options?: any): AxiosPromise<File> {
            return localVarFp.getBotFiles(versionId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Получить версию бота.
         * @param {string} id ИД версии бота.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBotVersion(id: string, options?: any): AxiosPromise<BotVersionModel> {
            return localVarFp.getBotVersion(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Выполнить поиск версий бота.
         * @param {string} [agentId] ИД агента.
         * @param {string} [hash] Хэш.
         * @param {number} [pageIndex] 
         * @param {number} [pageSize] 
         * @param {boolean} [withTotal] 
         * @param {number} [fromItemInclusive] 
         * @param {number} [toItemExclusive] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchBotVersions(agentId?: string, hash?: string, pageIndex?: number, pageSize?: number, withTotal?: boolean, fromItemInclusive?: number, toItemExclusive?: number, options?: any): AxiosPromise<BotVersionModelPaginationResponse> {
            return localVarFp.searchBotVersions(agentId, hash, pageIndex, pageSize, withTotal, fromItemInclusive, toItemExclusive, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * BotVersionApi - object-oriented interface
 * @export
 * @class BotVersionApi
 * @extends {BaseAPI}
 */
export class BotVersionApi extends BaseAPI {
    /**
     * 
     * @summary Добавление новой версии бота.
     * @param {BotVersionCreationRequest} [botVersionCreationRequest] Запрос на добавление версии бота.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BotVersionApi
     */
    public createBotVersion(botVersionCreationRequest?: BotVersionCreationRequest, options?: RawAxiosRequestConfig) {
        return BotVersionApiFp(this.configuration).createBotVersion(botVersionCreationRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Получить ссылку на редактирование последней версии бота.
     * @param {string} [agentId] ИД бота.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BotVersionApi
     */
    public getBotEditUrl(agentId?: string, options?: RawAxiosRequestConfig) {
        return BotVersionApiFp(this.configuration).getBotEditUrl(agentId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Получить файлы версии бота.
     * @param {string} [versionId] ИД версии бота.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BotVersionApi
     */
    public getBotFiles(versionId?: string, options?: RawAxiosRequestConfig) {
        return BotVersionApiFp(this.configuration).getBotFiles(versionId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Получить версию бота.
     * @param {string} id ИД версии бота.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BotVersionApi
     */
    public getBotVersion(id: string, options?: RawAxiosRequestConfig) {
        return BotVersionApiFp(this.configuration).getBotVersion(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Выполнить поиск версий бота.
     * @param {string} [agentId] ИД агента.
     * @param {string} [hash] Хэш.
     * @param {number} [pageIndex] 
     * @param {number} [pageSize] 
     * @param {boolean} [withTotal] 
     * @param {number} [fromItemInclusive] 
     * @param {number} [toItemExclusive] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BotVersionApi
     */
    public searchBotVersions(agentId?: string, hash?: string, pageIndex?: number, pageSize?: number, withTotal?: boolean, fromItemInclusive?: number, toItemExclusive?: number, options?: RawAxiosRequestConfig) {
        return BotVersionApiFp(this.configuration).searchBotVersions(agentId, hash, pageIndex, pageSize, withTotal, fromItemInclusive, toItemExclusive, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * CampaignApi - axios parameter creator
 * @export
 */
export const CampaignApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Создать рассылку.
         * @param {CampaignCreationRequest} [campaignCreationRequest] Запрос на создание рассылки.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createCampaign: async (campaignCreationRequest?: CampaignCreationRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/campaigns/campaigns`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth20 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth20", ["elma.assistant.resources.identity.context", "elma.assistant.scopes.api.admin", "email", "offline_access", "openid", "phone", "profile"], configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(campaignCreationRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Удалить рассылку.
         * @param {string} id ИД рассылки.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteCampaign: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteCampaign', 'id', id)
            const localVarPath = `/api/v1/campaigns/campaigns/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth20 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth20", ["elma.assistant.resources.identity.context", "elma.assistant.scopes.api.admin", "email", "offline_access", "openid", "phone", "profile"], configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Получить рассылку.
         * @param {string} id ИД рассылки.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCampaign: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getCampaign', 'id', id)
            const localVarPath = `/api/v1/campaigns/campaigns/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth20 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth20", ["elma.assistant.resources.identity.context", "elma.assistant.scopes.api.admin", "email", "offline_access", "openid", "phone", "profile"], configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Загрузить список рассылок.
         * @param {number} [pageIndex] 
         * @param {number} [pageSize] 
         * @param {boolean} [withTotal] 
         * @param {number} [fromItemInclusive] 
         * @param {number} [toItemExclusive] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listCampaigns: async (pageIndex?: number, pageSize?: number, withTotal?: boolean, fromItemInclusive?: number, toItemExclusive?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/campaigns/campaigns`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth20 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth20", ["elma.assistant.resources.identity.context", "elma.assistant.scopes.api.admin", "email", "offline_access", "openid", "phone", "profile"], configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (pageIndex !== undefined) {
                localVarQueryParameter['PageIndex'] = pageIndex;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['PageSize'] = pageSize;
            }

            if (withTotal !== undefined) {
                localVarQueryParameter['WithTotal'] = withTotal;
            }

            if (fromItemInclusive !== undefined) {
                localVarQueryParameter['FromItemInclusive'] = fromItemInclusive;
            }

            if (toItemExclusive !== undefined) {
                localVarQueryParameter['ToItemExclusive'] = toItemExclusive;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        runCampaign: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('runCampaign', 'id', id)
            const localVarPath = `/api/v1/campaigns/campaigns/{id}/run`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth20 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth20", ["elma.assistant.resources.identity.context", "elma.assistant.scopes.api.admin", "email", "offline_access", "openid", "phone", "profile"], configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Обновить рассылку.
         * @param {string} id 
         * @param {CampaignModel} [campaignModel] Модель рассылки.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCampaign: async (id: string, campaignModel?: CampaignModel, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateCampaign', 'id', id)
            const localVarPath = `/api/v1/campaigns/campaigns/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth20 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth20", ["elma.assistant.resources.identity.context", "elma.assistant.scopes.api.admin", "email", "offline_access", "openid", "phone", "profile"], configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(campaignModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CampaignApi - functional programming interface
 * @export
 */
export const CampaignApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CampaignApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Создать рассылку.
         * @param {CampaignCreationRequest} [campaignCreationRequest] Запрос на создание рассылки.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createCampaign(campaignCreationRequest?: CampaignCreationRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CampaignCreationResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createCampaign(campaignCreationRequest, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['CampaignApi.createCampaign']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Удалить рассылку.
         * @param {string} id ИД рассылки.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteCampaign(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteCampaign(id, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['CampaignApi.deleteCampaign']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Получить рассылку.
         * @param {string} id ИД рассылки.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCampaign(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CampaignModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCampaign(id, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['CampaignApi.getCampaign']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Загрузить список рассылок.
         * @param {number} [pageIndex] 
         * @param {number} [pageSize] 
         * @param {boolean} [withTotal] 
         * @param {number} [fromItemInclusive] 
         * @param {number} [toItemExclusive] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listCampaigns(pageIndex?: number, pageSize?: number, withTotal?: boolean, fromItemInclusive?: number, toItemExclusive?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CampaignModelPaginationResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listCampaigns(pageIndex, pageSize, withTotal, fromItemInclusive, toItemExclusive, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['CampaignApi.listCampaigns']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async runCampaign(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.runCampaign(id, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['CampaignApi.runCampaign']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Обновить рассылку.
         * @param {string} id 
         * @param {CampaignModel} [campaignModel] Модель рассылки.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateCampaign(id: string, campaignModel?: CampaignModel, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateCampaign(id, campaignModel, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['CampaignApi.updateCampaign']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
    }
};

/**
 * CampaignApi - factory interface
 * @export
 */
export const CampaignApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CampaignApiFp(configuration)
    return {
        /**
         * 
         * @summary Создать рассылку.
         * @param {CampaignCreationRequest} [campaignCreationRequest] Запрос на создание рассылки.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createCampaign(campaignCreationRequest?: CampaignCreationRequest, options?: any): AxiosPromise<CampaignCreationResponse> {
            return localVarFp.createCampaign(campaignCreationRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Удалить рассылку.
         * @param {string} id ИД рассылки.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteCampaign(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteCampaign(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Получить рассылку.
         * @param {string} id ИД рассылки.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCampaign(id: string, options?: any): AxiosPromise<CampaignModel> {
            return localVarFp.getCampaign(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Загрузить список рассылок.
         * @param {number} [pageIndex] 
         * @param {number} [pageSize] 
         * @param {boolean} [withTotal] 
         * @param {number} [fromItemInclusive] 
         * @param {number} [toItemExclusive] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listCampaigns(pageIndex?: number, pageSize?: number, withTotal?: boolean, fromItemInclusive?: number, toItemExclusive?: number, options?: any): AxiosPromise<CampaignModelPaginationResponse> {
            return localVarFp.listCampaigns(pageIndex, pageSize, withTotal, fromItemInclusive, toItemExclusive, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        runCampaign(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.runCampaign(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Обновить рассылку.
         * @param {string} id 
         * @param {CampaignModel} [campaignModel] Модель рассылки.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCampaign(id: string, campaignModel?: CampaignModel, options?: any): AxiosPromise<void> {
            return localVarFp.updateCampaign(id, campaignModel, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CampaignApi - object-oriented interface
 * @export
 * @class CampaignApi
 * @extends {BaseAPI}
 */
export class CampaignApi extends BaseAPI {
    /**
     * 
     * @summary Создать рассылку.
     * @param {CampaignCreationRequest} [campaignCreationRequest] Запрос на создание рассылки.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CampaignApi
     */
    public createCampaign(campaignCreationRequest?: CampaignCreationRequest, options?: RawAxiosRequestConfig) {
        return CampaignApiFp(this.configuration).createCampaign(campaignCreationRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Удалить рассылку.
     * @param {string} id ИД рассылки.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CampaignApi
     */
    public deleteCampaign(id: string, options?: RawAxiosRequestConfig) {
        return CampaignApiFp(this.configuration).deleteCampaign(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Получить рассылку.
     * @param {string} id ИД рассылки.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CampaignApi
     */
    public getCampaign(id: string, options?: RawAxiosRequestConfig) {
        return CampaignApiFp(this.configuration).getCampaign(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Загрузить список рассылок.
     * @param {number} [pageIndex] 
     * @param {number} [pageSize] 
     * @param {boolean} [withTotal] 
     * @param {number} [fromItemInclusive] 
     * @param {number} [toItemExclusive] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CampaignApi
     */
    public listCampaigns(pageIndex?: number, pageSize?: number, withTotal?: boolean, fromItemInclusive?: number, toItemExclusive?: number, options?: RawAxiosRequestConfig) {
        return CampaignApiFp(this.configuration).listCampaigns(pageIndex, pageSize, withTotal, fromItemInclusive, toItemExclusive, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CampaignApi
     */
    public runCampaign(id: string, options?: RawAxiosRequestConfig) {
        return CampaignApiFp(this.configuration).runCampaign(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Обновить рассылку.
     * @param {string} id 
     * @param {CampaignModel} [campaignModel] Модель рассылки.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CampaignApi
     */
    public updateCampaign(id: string, campaignModel?: CampaignModel, options?: RawAxiosRequestConfig) {
        return CampaignApiFp(this.configuration).updateCampaign(id, campaignModel, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * CampaignJobApi - axios parameter creator
 * @export
 */
export const CampaignJobApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCampaignJob: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getCampaignJob', 'id', id)
            const localVarPath = `/api/v1/campaigns/jobs/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth20 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth20", ["elma.assistant.resources.identity.context", "elma.assistant.scopes.api.admin", "email", "offline_access", "openid", "phone", "profile"], configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCampaignJobDescription: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getCampaignJobDescription', 'id', id)
            const localVarPath = `/api/v1/campaigns/jobs/{id}/description`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth20 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth20", ["elma.assistant.resources.identity.context", "elma.assistant.scopes.api.admin", "email", "offline_access", "openid", "phone", "profile"], configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCampaignJobState: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getCampaignJobState', 'id', id)
            const localVarPath = `/api/v1/campaigns/jobs/{id}/state`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth20 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth20", ["elma.assistant.resources.identity.context", "elma.assistant.scopes.api.admin", "email", "offline_access", "openid", "phone", "profile"], configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Загрузить список запусков рассылки.
         * @param {string} [campaignId] ИД рассылки.
         * @param {number} [pageIndex] 
         * @param {number} [pageSize] 
         * @param {boolean} [withTotal] 
         * @param {number} [fromItemInclusive] 
         * @param {number} [toItemExclusive] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listCampaignJobs: async (campaignId?: string, pageIndex?: number, pageSize?: number, withTotal?: boolean, fromItemInclusive?: number, toItemExclusive?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/campaigns/jobs`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth20 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth20", ["elma.assistant.resources.identity.context", "elma.assistant.scopes.api.admin", "email", "offline_access", "openid", "phone", "profile"], configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (campaignId !== undefined) {
                localVarQueryParameter['CampaignId'] = campaignId;
            }

            if (pageIndex !== undefined) {
                localVarQueryParameter['PageIndex'] = pageIndex;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['PageSize'] = pageSize;
            }

            if (withTotal !== undefined) {
                localVarQueryParameter['WithTotal'] = withTotal;
            }

            if (fromItemInclusive !== undefined) {
                localVarQueryParameter['FromItemInclusive'] = fromItemInclusive;
            }

            if (toItemExclusive !== undefined) {
                localVarQueryParameter['ToItemExclusive'] = toItemExclusive;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CampaignJobApi - functional programming interface
 * @export
 */
export const CampaignJobApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CampaignJobApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCampaignJob(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CampaignJobModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCampaignJob(id, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['CampaignJobApi.getCampaignJob']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCampaignJobDescription(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CampaignJobDescription>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCampaignJobDescription(id, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['CampaignJobApi.getCampaignJobDescription']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCampaignJobState(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CampaignJobState>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCampaignJobState(id, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['CampaignJobApi.getCampaignJobState']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Загрузить список запусков рассылки.
         * @param {string} [campaignId] ИД рассылки.
         * @param {number} [pageIndex] 
         * @param {number} [pageSize] 
         * @param {boolean} [withTotal] 
         * @param {number} [fromItemInclusive] 
         * @param {number} [toItemExclusive] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listCampaignJobs(campaignId?: string, pageIndex?: number, pageSize?: number, withTotal?: boolean, fromItemInclusive?: number, toItemExclusive?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CampaignJobModelPaginationResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listCampaignJobs(campaignId, pageIndex, pageSize, withTotal, fromItemInclusive, toItemExclusive, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['CampaignJobApi.listCampaignJobs']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
    }
};

/**
 * CampaignJobApi - factory interface
 * @export
 */
export const CampaignJobApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CampaignJobApiFp(configuration)
    return {
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCampaignJob(id: string, options?: any): AxiosPromise<CampaignJobModel> {
            return localVarFp.getCampaignJob(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCampaignJobDescription(id: string, options?: any): AxiosPromise<CampaignJobDescription> {
            return localVarFp.getCampaignJobDescription(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCampaignJobState(id: string, options?: any): AxiosPromise<CampaignJobState> {
            return localVarFp.getCampaignJobState(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Загрузить список запусков рассылки.
         * @param {string} [campaignId] ИД рассылки.
         * @param {number} [pageIndex] 
         * @param {number} [pageSize] 
         * @param {boolean} [withTotal] 
         * @param {number} [fromItemInclusive] 
         * @param {number} [toItemExclusive] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listCampaignJobs(campaignId?: string, pageIndex?: number, pageSize?: number, withTotal?: boolean, fromItemInclusive?: number, toItemExclusive?: number, options?: any): AxiosPromise<CampaignJobModelPaginationResponse> {
            return localVarFp.listCampaignJobs(campaignId, pageIndex, pageSize, withTotal, fromItemInclusive, toItemExclusive, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CampaignJobApi - object-oriented interface
 * @export
 * @class CampaignJobApi
 * @extends {BaseAPI}
 */
export class CampaignJobApi extends BaseAPI {
    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CampaignJobApi
     */
    public getCampaignJob(id: string, options?: RawAxiosRequestConfig) {
        return CampaignJobApiFp(this.configuration).getCampaignJob(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CampaignJobApi
     */
    public getCampaignJobDescription(id: string, options?: RawAxiosRequestConfig) {
        return CampaignJobApiFp(this.configuration).getCampaignJobDescription(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CampaignJobApi
     */
    public getCampaignJobState(id: string, options?: RawAxiosRequestConfig) {
        return CampaignJobApiFp(this.configuration).getCampaignJobState(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Загрузить список запусков рассылки.
     * @param {string} [campaignId] ИД рассылки.
     * @param {number} [pageIndex] 
     * @param {number} [pageSize] 
     * @param {boolean} [withTotal] 
     * @param {number} [fromItemInclusive] 
     * @param {number} [toItemExclusive] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CampaignJobApi
     */
    public listCampaignJobs(campaignId?: string, pageIndex?: number, pageSize?: number, withTotal?: boolean, fromItemInclusive?: number, toItemExclusive?: number, options?: RawAxiosRequestConfig) {
        return CampaignJobApiFp(this.configuration).listCampaignJobs(campaignId, pageIndex, pageSize, withTotal, fromItemInclusive, toItemExclusive, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * ChannelApi - axios parameter creator
 * @export
 */
export const ChannelApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Обработать обратный вызов от канала
         * @param {string} channelId ИД канала.
         * @param {string} botUri URI бота.
         * @param {string} signature Подпись.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ChannelsChannelIdBotsBotUriCallbackSignatureGet: async (channelId: string, botUri: string, signature: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'channelId' is not null or undefined
            assertParamExists('apiV1ChannelsChannelIdBotsBotUriCallbackSignatureGet', 'channelId', channelId)
            // verify required parameter 'botUri' is not null or undefined
            assertParamExists('apiV1ChannelsChannelIdBotsBotUriCallbackSignatureGet', 'botUri', botUri)
            // verify required parameter 'signature' is not null or undefined
            assertParamExists('apiV1ChannelsChannelIdBotsBotUriCallbackSignatureGet', 'signature', signature)
            const localVarPath = `/api/v1/channels/{channelId}/bots/{botUri}/callback/{signature}`
                .replace(`{${"channelId"}}`, encodeURIComponent(String(channelId)))
                .replace(`{${"botUri"}}`, encodeURIComponent(String(botUri)))
                .replace(`{${"signature"}}`, encodeURIComponent(String(signature)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Обработать обратный вызов от канала
         * @param {string} channelId ИД канала.
         * @param {string} botUri URI бота.
         * @param {string} signature Подпись.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ChannelsChannelIdBotsBotUriCallbackSignaturePost: async (channelId: string, botUri: string, signature: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'channelId' is not null or undefined
            assertParamExists('apiV1ChannelsChannelIdBotsBotUriCallbackSignaturePost', 'channelId', channelId)
            // verify required parameter 'botUri' is not null or undefined
            assertParamExists('apiV1ChannelsChannelIdBotsBotUriCallbackSignaturePost', 'botUri', botUri)
            // verify required parameter 'signature' is not null or undefined
            assertParamExists('apiV1ChannelsChannelIdBotsBotUriCallbackSignaturePost', 'signature', signature)
            const localVarPath = `/api/v1/channels/{channelId}/bots/{botUri}/callback/{signature}`
                .replace(`{${"channelId"}}`, encodeURIComponent(String(channelId)))
                .replace(`{${"botUri"}}`, encodeURIComponent(String(botUri)))
                .replace(`{${"signature"}}`, encodeURIComponent(String(signature)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Выполнить перенаправление на канал.
         * @param {string} channelId ИД канала.
         * @param {string} botUri URI бота.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ChannelsChannelIdBotsBotUriRedirectGet: async (channelId: string, botUri: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'channelId' is not null or undefined
            assertParamExists('apiV1ChannelsChannelIdBotsBotUriRedirectGet', 'channelId', channelId)
            // verify required parameter 'botUri' is not null or undefined
            assertParamExists('apiV1ChannelsChannelIdBotsBotUriRedirectGet', 'botUri', botUri)
            const localVarPath = `/api/v1/channels/{channelId}/bots/{botUri}/redirect`
                .replace(`{${"channelId"}}`, encodeURIComponent(String(channelId)))
                .replace(`{${"botUri"}}`, encodeURIComponent(String(botUri)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ChannelApi - functional programming interface
 * @export
 */
export const ChannelApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ChannelApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Обработать обратный вызов от канала
         * @param {string} channelId ИД канала.
         * @param {string} botUri URI бота.
         * @param {string} signature Подпись.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1ChannelsChannelIdBotsBotUriCallbackSignatureGet(channelId: string, botUri: string, signature: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1ChannelsChannelIdBotsBotUriCallbackSignatureGet(channelId, botUri, signature, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['ChannelApi.apiV1ChannelsChannelIdBotsBotUriCallbackSignatureGet']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Обработать обратный вызов от канала
         * @param {string} channelId ИД канала.
         * @param {string} botUri URI бота.
         * @param {string} signature Подпись.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1ChannelsChannelIdBotsBotUriCallbackSignaturePost(channelId: string, botUri: string, signature: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1ChannelsChannelIdBotsBotUriCallbackSignaturePost(channelId, botUri, signature, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['ChannelApi.apiV1ChannelsChannelIdBotsBotUriCallbackSignaturePost']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Выполнить перенаправление на канал.
         * @param {string} channelId ИД канала.
         * @param {string} botUri URI бота.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1ChannelsChannelIdBotsBotUriRedirectGet(channelId: string, botUri: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1ChannelsChannelIdBotsBotUriRedirectGet(channelId, botUri, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['ChannelApi.apiV1ChannelsChannelIdBotsBotUriRedirectGet']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
    }
};

/**
 * ChannelApi - factory interface
 * @export
 */
export const ChannelApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ChannelApiFp(configuration)
    return {
        /**
         * 
         * @summary Обработать обратный вызов от канала
         * @param {string} channelId ИД канала.
         * @param {string} botUri URI бота.
         * @param {string} signature Подпись.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ChannelsChannelIdBotsBotUriCallbackSignatureGet(channelId: string, botUri: string, signature: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiV1ChannelsChannelIdBotsBotUriCallbackSignatureGet(channelId, botUri, signature, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Обработать обратный вызов от канала
         * @param {string} channelId ИД канала.
         * @param {string} botUri URI бота.
         * @param {string} signature Подпись.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ChannelsChannelIdBotsBotUriCallbackSignaturePost(channelId: string, botUri: string, signature: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiV1ChannelsChannelIdBotsBotUriCallbackSignaturePost(channelId, botUri, signature, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Выполнить перенаправление на канал.
         * @param {string} channelId ИД канала.
         * @param {string} botUri URI бота.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ChannelsChannelIdBotsBotUriRedirectGet(channelId: string, botUri: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiV1ChannelsChannelIdBotsBotUriRedirectGet(channelId, botUri, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ChannelApi - object-oriented interface
 * @export
 * @class ChannelApi
 * @extends {BaseAPI}
 */
export class ChannelApi extends BaseAPI {
    /**
     * 
     * @summary Обработать обратный вызов от канала
     * @param {string} channelId ИД канала.
     * @param {string} botUri URI бота.
     * @param {string} signature Подпись.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ChannelApi
     */
    public apiV1ChannelsChannelIdBotsBotUriCallbackSignatureGet(channelId: string, botUri: string, signature: string, options?: RawAxiosRequestConfig) {
        return ChannelApiFp(this.configuration).apiV1ChannelsChannelIdBotsBotUriCallbackSignatureGet(channelId, botUri, signature, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Обработать обратный вызов от канала
     * @param {string} channelId ИД канала.
     * @param {string} botUri URI бота.
     * @param {string} signature Подпись.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ChannelApi
     */
    public apiV1ChannelsChannelIdBotsBotUriCallbackSignaturePost(channelId: string, botUri: string, signature: string, options?: RawAxiosRequestConfig) {
        return ChannelApiFp(this.configuration).apiV1ChannelsChannelIdBotsBotUriCallbackSignaturePost(channelId, botUri, signature, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Выполнить перенаправление на канал.
     * @param {string} channelId ИД канала.
     * @param {string} botUri URI бота.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ChannelApi
     */
    public apiV1ChannelsChannelIdBotsBotUriRedirectGet(channelId: string, botUri: string, options?: RawAxiosRequestConfig) {
        return ChannelApiFp(this.configuration).apiV1ChannelsChannelIdBotsBotUriRedirectGet(channelId, botUri, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * CommandApi - axios parameter creator
 * @export
 */
export const CommandApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Пригласить сотрудника в канал бота.
         * @param {InvitationCommandModel} [invitationCommandModel] Модель приглашения сотрудника в канал бота.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        invitePerson: async (invitationCommandModel?: InvitationCommandModel, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/accounts/commands/invitations`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth20 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth20", ["elma.assistant.resources.identity.context", "elma.assistant.scopes.api.admin", "email", "offline_access", "openid", "phone", "profile"], configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(invitationCommandModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Вызвать проактивное действие.
         * @param {InvocationCommandModel} [invocationCommandModel] Модель вызова проактивного действия.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        invokeAction: async (invocationCommandModel?: InvocationCommandModel, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/runtime/commands/invocations`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth20 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth20", ["elma.assistant.resources.identity.context", "elma.assistant.scopes.api.admin", "email", "offline_access", "openid", "phone", "profile"], configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(invocationCommandModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CommandApi - functional programming interface
 * @export
 */
export const CommandApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CommandApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Пригласить сотрудника в канал бота.
         * @param {InvitationCommandModel} [invitationCommandModel] Модель приглашения сотрудника в канал бота.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async invitePerson(invitationCommandModel?: InvitationCommandModel, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.invitePerson(invitationCommandModel, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['CommandApi.invitePerson']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Вызвать проактивное действие.
         * @param {InvocationCommandModel} [invocationCommandModel] Модель вызова проактивного действия.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async invokeAction(invocationCommandModel?: InvocationCommandModel, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.invokeAction(invocationCommandModel, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['CommandApi.invokeAction']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
    }
};

/**
 * CommandApi - factory interface
 * @export
 */
export const CommandApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CommandApiFp(configuration)
    return {
        /**
         * 
         * @summary Пригласить сотрудника в канал бота.
         * @param {InvitationCommandModel} [invitationCommandModel] Модель приглашения сотрудника в канал бота.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        invitePerson(invitationCommandModel?: InvitationCommandModel, options?: any): AxiosPromise<void> {
            return localVarFp.invitePerson(invitationCommandModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Вызвать проактивное действие.
         * @param {InvocationCommandModel} [invocationCommandModel] Модель вызова проактивного действия.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        invokeAction(invocationCommandModel?: InvocationCommandModel, options?: any): AxiosPromise<void> {
            return localVarFp.invokeAction(invocationCommandModel, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CommandApi - object-oriented interface
 * @export
 * @class CommandApi
 * @extends {BaseAPI}
 */
export class CommandApi extends BaseAPI {
    /**
     * 
     * @summary Пригласить сотрудника в канал бота.
     * @param {InvitationCommandModel} [invitationCommandModel] Модель приглашения сотрудника в канал бота.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CommandApi
     */
    public invitePerson(invitationCommandModel?: InvitationCommandModel, options?: RawAxiosRequestConfig) {
        return CommandApiFp(this.configuration).invitePerson(invitationCommandModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Вызвать проактивное действие.
     * @param {InvocationCommandModel} [invocationCommandModel] Модель вызова проактивного действия.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CommandApi
     */
    public invokeAction(invocationCommandModel?: InvocationCommandModel, options?: RawAxiosRequestConfig) {
        return CommandApiFp(this.configuration).invokeAction(invocationCommandModel, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * ConfigurationApi - axios parameter creator
 * @export
 */
export const ConfigurationApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Получить конфигурацию клиента.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getClientConfig: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/info/configuration/client`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ConfigurationApi - functional programming interface
 * @export
 */
export const ConfigurationApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ConfigurationApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Получить конфигурацию клиента.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getClientConfig(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getClientConfig(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['ConfigurationApi.getClientConfig']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
    }
};

/**
 * ConfigurationApi - factory interface
 * @export
 */
export const ConfigurationApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ConfigurationApiFp(configuration)
    return {
        /**
         * 
         * @summary Получить конфигурацию клиента.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getClientConfig(options?: any): AxiosPromise<void> {
            return localVarFp.getClientConfig(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ConfigurationApi - object-oriented interface
 * @export
 * @class ConfigurationApi
 * @extends {BaseAPI}
 */
export class ConfigurationApi extends BaseAPI {
    /**
     * 
     * @summary Получить конфигурацию клиента.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConfigurationApi
     */
    public getClientConfig(options?: RawAxiosRequestConfig) {
        return ConfigurationApiFp(this.configuration).getClientConfig(options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * ConversationApi - axios parameter creator
 * @export
 */
export const ConversationApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Получить беседу.
         * @param {string} id ИД беседы.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getConversation: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getConversation', 'id', id)
            const localVarPath = `/api/v1/channels/conversations/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth20 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth20", ["elma.assistant.resources.identity.context", "elma.assistant.scopes.api.admin", "email", "offline_access", "openid", "phone", "profile"], configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Получить количество бесед.
         * @param {string} [channelId] ИД канала.
         * @param {string} [agentStageId] ИД стадии бота.
         * @param {string} [agentStageAccountId] ИД аккаунта стадии бота.
         * @param {string} [personId] ИД сотрудника.
         * @param {ConversationStatus} [status] Статус беседы.
         * @param {string} [startFromDate] Дата начала беседы (начало промежутка)
         * @param {string} [startToDate] Дата начала беседы (окончание промежутка)
         * @param {string} [lastMessageFromDate] Дата последнего сообщения (начало промежутка)
         * @param {string} [lastMessageToDate] Дата последнего сообщения (окончание промежутка)
         * @param {string} [latestMessageText] Текст последнего сообщения
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getConversationCount: async (channelId?: string, agentStageId?: string, agentStageAccountId?: string, personId?: string, status?: ConversationStatus, startFromDate?: string, startToDate?: string, lastMessageFromDate?: string, lastMessageToDate?: string, latestMessageText?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/channels/conversations/count`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth20 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth20", ["elma.assistant.resources.identity.context", "elma.assistant.scopes.api.admin", "email", "offline_access", "openid", "phone", "profile"], configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (channelId !== undefined) {
                localVarQueryParameter['ChannelId'] = channelId;
            }

            if (agentStageId !== undefined) {
                localVarQueryParameter['AgentStageId'] = agentStageId;
            }

            if (agentStageAccountId !== undefined) {
                localVarQueryParameter['AgentStageAccountId'] = agentStageAccountId;
            }

            if (personId !== undefined) {
                localVarQueryParameter['PersonId'] = personId;
            }

            if (status !== undefined) {
                localVarQueryParameter['Status'] = status;
            }

            if (startFromDate !== undefined) {
                localVarQueryParameter['StartFromDate'] = (startFromDate as any instanceof Date) ?
                    (startFromDate as any).toISOString() :
                    startFromDate;
            }

            if (startToDate !== undefined) {
                localVarQueryParameter['StartToDate'] = (startToDate as any instanceof Date) ?
                    (startToDate as any).toISOString() :
                    startToDate;
            }

            if (lastMessageFromDate !== undefined) {
                localVarQueryParameter['LastMessageFromDate'] = (lastMessageFromDate as any instanceof Date) ?
                    (lastMessageFromDate as any).toISOString() :
                    lastMessageFromDate;
            }

            if (lastMessageToDate !== undefined) {
                localVarQueryParameter['LastMessageToDate'] = (lastMessageToDate as any instanceof Date) ?
                    (lastMessageToDate as any).toISOString() :
                    lastMessageToDate;
            }

            if (latestMessageText !== undefined) {
                localVarQueryParameter['LatestMessageText'] = latestMessageText;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Получить количество уникальных пользователей бесед.
         * @param {string} [channelId] ИД канала.
         * @param {string} [agentStageId] ИД стадии бота.
         * @param {string} [agentStageAccountId] ИД аккаунта стадии бота.
         * @param {string} [personId] ИД сотрудника.
         * @param {ConversationStatus} [status] Статус беседы.
         * @param {string} [startFromDate] Дата начала беседы (начало промежутка)
         * @param {string} [startToDate] Дата начала беседы (окончание промежутка)
         * @param {string} [lastMessageFromDate] Дата последнего сообщения (начало промежутка)
         * @param {string} [lastMessageToDate] Дата последнего сообщения (окончание промежутка)
         * @param {string} [latestMessageText] Текст последнего сообщения
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getConversationsUniqueUserCount: async (channelId?: string, agentStageId?: string, agentStageAccountId?: string, personId?: string, status?: ConversationStatus, startFromDate?: string, startToDate?: string, lastMessageFromDate?: string, lastMessageToDate?: string, latestMessageText?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/channels/conversations/unique-user-count`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth20 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth20", ["elma.assistant.resources.identity.context", "elma.assistant.scopes.api.admin", "email", "offline_access", "openid", "phone", "profile"], configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (channelId !== undefined) {
                localVarQueryParameter['ChannelId'] = channelId;
            }

            if (agentStageId !== undefined) {
                localVarQueryParameter['AgentStageId'] = agentStageId;
            }

            if (agentStageAccountId !== undefined) {
                localVarQueryParameter['AgentStageAccountId'] = agentStageAccountId;
            }

            if (personId !== undefined) {
                localVarQueryParameter['PersonId'] = personId;
            }

            if (status !== undefined) {
                localVarQueryParameter['Status'] = status;
            }

            if (startFromDate !== undefined) {
                localVarQueryParameter['StartFromDate'] = (startFromDate as any instanceof Date) ?
                    (startFromDate as any).toISOString() :
                    startFromDate;
            }

            if (startToDate !== undefined) {
                localVarQueryParameter['StartToDate'] = (startToDate as any instanceof Date) ?
                    (startToDate as any).toISOString() :
                    startToDate;
            }

            if (lastMessageFromDate !== undefined) {
                localVarQueryParameter['LastMessageFromDate'] = (lastMessageFromDate as any instanceof Date) ?
                    (lastMessageFromDate as any).toISOString() :
                    lastMessageFromDate;
            }

            if (lastMessageToDate !== undefined) {
                localVarQueryParameter['LastMessageToDate'] = (lastMessageToDate as any instanceof Date) ?
                    (lastMessageToDate as any).toISOString() :
                    lastMessageToDate;
            }

            if (latestMessageText !== undefined) {
                localVarQueryParameter['LatestMessageText'] = latestMessageText;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Выполнить поиск бесед.
         * @param {string} [channelId] ИД канала.
         * @param {string} [agentStageId] ИД стадии бота.
         * @param {string} [agentStageAccountId] ИД аккаунта стадии бота.
         * @param {string} [personId] ИД сотрудника.
         * @param {ConversationStatus} [status] Статус беседы.
         * @param {string} [startFromDate] Дата начала беседы (начало промежутка)
         * @param {string} [startToDate] Дата начала беседы (окончание промежутка)
         * @param {string} [lastMessageFromDate] Дата последнего сообщения (начало промежутка)
         * @param {string} [lastMessageToDate] Дата последнего сообщения (окончание промежутка)
         * @param {string} [latestMessageText] Текст последнего сообщения
         * @param {ConversationSortDirection} [sorting] Параметры сортировки.
         * @param {number} [pageIndex] 
         * @param {number} [pageSize] 
         * @param {boolean} [withTotal] 
         * @param {number} [fromItemInclusive] 
         * @param {number} [toItemExclusive] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchConversations: async (channelId?: string, agentStageId?: string, agentStageAccountId?: string, personId?: string, status?: ConversationStatus, startFromDate?: string, startToDate?: string, lastMessageFromDate?: string, lastMessageToDate?: string, latestMessageText?: string, sorting?: ConversationSortDirection, pageIndex?: number, pageSize?: number, withTotal?: boolean, fromItemInclusive?: number, toItemExclusive?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/channels/conversations`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth20 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth20", ["elma.assistant.resources.identity.context", "elma.assistant.scopes.api.admin", "email", "offline_access", "openid", "phone", "profile"], configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (channelId !== undefined) {
                localVarQueryParameter['ChannelId'] = channelId;
            }

            if (agentStageId !== undefined) {
                localVarQueryParameter['AgentStageId'] = agentStageId;
            }

            if (agentStageAccountId !== undefined) {
                localVarQueryParameter['AgentStageAccountId'] = agentStageAccountId;
            }

            if (personId !== undefined) {
                localVarQueryParameter['PersonId'] = personId;
            }

            if (status !== undefined) {
                localVarQueryParameter['Status'] = status;
            }

            if (startFromDate !== undefined) {
                localVarQueryParameter['StartFromDate'] = (startFromDate as any instanceof Date) ?
                    (startFromDate as any).toISOString() :
                    startFromDate;
            }

            if (startToDate !== undefined) {
                localVarQueryParameter['StartToDate'] = (startToDate as any instanceof Date) ?
                    (startToDate as any).toISOString() :
                    startToDate;
            }

            if (lastMessageFromDate !== undefined) {
                localVarQueryParameter['LastMessageFromDate'] = (lastMessageFromDate as any instanceof Date) ?
                    (lastMessageFromDate as any).toISOString() :
                    lastMessageFromDate;
            }

            if (lastMessageToDate !== undefined) {
                localVarQueryParameter['LastMessageToDate'] = (lastMessageToDate as any instanceof Date) ?
                    (lastMessageToDate as any).toISOString() :
                    lastMessageToDate;
            }

            if (latestMessageText !== undefined) {
                localVarQueryParameter['LatestMessageText'] = latestMessageText;
            }

            if (sorting !== undefined) {
                localVarQueryParameter['sorting'] = sorting;
            }

            if (pageIndex !== undefined) {
                localVarQueryParameter['PageIndex'] = pageIndex;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['PageSize'] = pageSize;
            }

            if (withTotal !== undefined) {
                localVarQueryParameter['WithTotal'] = withTotal;
            }

            if (fromItemInclusive !== undefined) {
                localVarQueryParameter['FromItemInclusive'] = fromItemInclusive;
            }

            if (toItemExclusive !== undefined) {
                localVarQueryParameter['ToItemExclusive'] = toItemExclusive;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ConversationApi - functional programming interface
 * @export
 */
export const ConversationApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ConversationApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Получить беседу.
         * @param {string} id ИД беседы.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getConversation(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ConversationModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getConversation(id, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['ConversationApi.getConversation']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Получить количество бесед.
         * @param {string} [channelId] ИД канала.
         * @param {string} [agentStageId] ИД стадии бота.
         * @param {string} [agentStageAccountId] ИД аккаунта стадии бота.
         * @param {string} [personId] ИД сотрудника.
         * @param {ConversationStatus} [status] Статус беседы.
         * @param {string} [startFromDate] Дата начала беседы (начало промежутка)
         * @param {string} [startToDate] Дата начала беседы (окончание промежутка)
         * @param {string} [lastMessageFromDate] Дата последнего сообщения (начало промежутка)
         * @param {string} [lastMessageToDate] Дата последнего сообщения (окончание промежутка)
         * @param {string} [latestMessageText] Текст последнего сообщения
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getConversationCount(channelId?: string, agentStageId?: string, agentStageAccountId?: string, personId?: string, status?: ConversationStatus, startFromDate?: string, startToDate?: string, lastMessageFromDate?: string, lastMessageToDate?: string, latestMessageText?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getConversationCount(channelId, agentStageId, agentStageAccountId, personId, status, startFromDate, startToDate, lastMessageFromDate, lastMessageToDate, latestMessageText, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['ConversationApi.getConversationCount']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Получить количество уникальных пользователей бесед.
         * @param {string} [channelId] ИД канала.
         * @param {string} [agentStageId] ИД стадии бота.
         * @param {string} [agentStageAccountId] ИД аккаунта стадии бота.
         * @param {string} [personId] ИД сотрудника.
         * @param {ConversationStatus} [status] Статус беседы.
         * @param {string} [startFromDate] Дата начала беседы (начало промежутка)
         * @param {string} [startToDate] Дата начала беседы (окончание промежутка)
         * @param {string} [lastMessageFromDate] Дата последнего сообщения (начало промежутка)
         * @param {string} [lastMessageToDate] Дата последнего сообщения (окончание промежутка)
         * @param {string} [latestMessageText] Текст последнего сообщения
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getConversationsUniqueUserCount(channelId?: string, agentStageId?: string, agentStageAccountId?: string, personId?: string, status?: ConversationStatus, startFromDate?: string, startToDate?: string, lastMessageFromDate?: string, lastMessageToDate?: string, latestMessageText?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getConversationsUniqueUserCount(channelId, agentStageId, agentStageAccountId, personId, status, startFromDate, startToDate, lastMessageFromDate, lastMessageToDate, latestMessageText, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['ConversationApi.getConversationsUniqueUserCount']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Выполнить поиск бесед.
         * @param {string} [channelId] ИД канала.
         * @param {string} [agentStageId] ИД стадии бота.
         * @param {string} [agentStageAccountId] ИД аккаунта стадии бота.
         * @param {string} [personId] ИД сотрудника.
         * @param {ConversationStatus} [status] Статус беседы.
         * @param {string} [startFromDate] Дата начала беседы (начало промежутка)
         * @param {string} [startToDate] Дата начала беседы (окончание промежутка)
         * @param {string} [lastMessageFromDate] Дата последнего сообщения (начало промежутка)
         * @param {string} [lastMessageToDate] Дата последнего сообщения (окончание промежутка)
         * @param {string} [latestMessageText] Текст последнего сообщения
         * @param {ConversationSortDirection} [sorting] Параметры сортировки.
         * @param {number} [pageIndex] 
         * @param {number} [pageSize] 
         * @param {boolean} [withTotal] 
         * @param {number} [fromItemInclusive] 
         * @param {number} [toItemExclusive] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async searchConversations(channelId?: string, agentStageId?: string, agentStageAccountId?: string, personId?: string, status?: ConversationStatus, startFromDate?: string, startToDate?: string, lastMessageFromDate?: string, lastMessageToDate?: string, latestMessageText?: string, sorting?: ConversationSortDirection, pageIndex?: number, pageSize?: number, withTotal?: boolean, fromItemInclusive?: number, toItemExclusive?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ConversationModelPaginationResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.searchConversations(channelId, agentStageId, agentStageAccountId, personId, status, startFromDate, startToDate, lastMessageFromDate, lastMessageToDate, latestMessageText, sorting, pageIndex, pageSize, withTotal, fromItemInclusive, toItemExclusive, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['ConversationApi.searchConversations']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
    }
};

/**
 * ConversationApi - factory interface
 * @export
 */
export const ConversationApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ConversationApiFp(configuration)
    return {
        /**
         * 
         * @summary Получить беседу.
         * @param {string} id ИД беседы.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getConversation(id: string, options?: any): AxiosPromise<ConversationModel> {
            return localVarFp.getConversation(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Получить количество бесед.
         * @param {string} [channelId] ИД канала.
         * @param {string} [agentStageId] ИД стадии бота.
         * @param {string} [agentStageAccountId] ИД аккаунта стадии бота.
         * @param {string} [personId] ИД сотрудника.
         * @param {ConversationStatus} [status] Статус беседы.
         * @param {string} [startFromDate] Дата начала беседы (начало промежутка)
         * @param {string} [startToDate] Дата начала беседы (окончание промежутка)
         * @param {string} [lastMessageFromDate] Дата последнего сообщения (начало промежутка)
         * @param {string} [lastMessageToDate] Дата последнего сообщения (окончание промежутка)
         * @param {string} [latestMessageText] Текст последнего сообщения
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getConversationCount(channelId?: string, agentStageId?: string, agentStageAccountId?: string, personId?: string, status?: ConversationStatus, startFromDate?: string, startToDate?: string, lastMessageFromDate?: string, lastMessageToDate?: string, latestMessageText?: string, options?: any): AxiosPromise<number> {
            return localVarFp.getConversationCount(channelId, agentStageId, agentStageAccountId, personId, status, startFromDate, startToDate, lastMessageFromDate, lastMessageToDate, latestMessageText, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Получить количество уникальных пользователей бесед.
         * @param {string} [channelId] ИД канала.
         * @param {string} [agentStageId] ИД стадии бота.
         * @param {string} [agentStageAccountId] ИД аккаунта стадии бота.
         * @param {string} [personId] ИД сотрудника.
         * @param {ConversationStatus} [status] Статус беседы.
         * @param {string} [startFromDate] Дата начала беседы (начало промежутка)
         * @param {string} [startToDate] Дата начала беседы (окончание промежутка)
         * @param {string} [lastMessageFromDate] Дата последнего сообщения (начало промежутка)
         * @param {string} [lastMessageToDate] Дата последнего сообщения (окончание промежутка)
         * @param {string} [latestMessageText] Текст последнего сообщения
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getConversationsUniqueUserCount(channelId?: string, agentStageId?: string, agentStageAccountId?: string, personId?: string, status?: ConversationStatus, startFromDate?: string, startToDate?: string, lastMessageFromDate?: string, lastMessageToDate?: string, latestMessageText?: string, options?: any): AxiosPromise<number> {
            return localVarFp.getConversationsUniqueUserCount(channelId, agentStageId, agentStageAccountId, personId, status, startFromDate, startToDate, lastMessageFromDate, lastMessageToDate, latestMessageText, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Выполнить поиск бесед.
         * @param {string} [channelId] ИД канала.
         * @param {string} [agentStageId] ИД стадии бота.
         * @param {string} [agentStageAccountId] ИД аккаунта стадии бота.
         * @param {string} [personId] ИД сотрудника.
         * @param {ConversationStatus} [status] Статус беседы.
         * @param {string} [startFromDate] Дата начала беседы (начало промежутка)
         * @param {string} [startToDate] Дата начала беседы (окончание промежутка)
         * @param {string} [lastMessageFromDate] Дата последнего сообщения (начало промежутка)
         * @param {string} [lastMessageToDate] Дата последнего сообщения (окончание промежутка)
         * @param {string} [latestMessageText] Текст последнего сообщения
         * @param {ConversationSortDirection} [sorting] Параметры сортировки.
         * @param {number} [pageIndex] 
         * @param {number} [pageSize] 
         * @param {boolean} [withTotal] 
         * @param {number} [fromItemInclusive] 
         * @param {number} [toItemExclusive] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchConversations(channelId?: string, agentStageId?: string, agentStageAccountId?: string, personId?: string, status?: ConversationStatus, startFromDate?: string, startToDate?: string, lastMessageFromDate?: string, lastMessageToDate?: string, latestMessageText?: string, sorting?: ConversationSortDirection, pageIndex?: number, pageSize?: number, withTotal?: boolean, fromItemInclusive?: number, toItemExclusive?: number, options?: any): AxiosPromise<ConversationModelPaginationResponse> {
            return localVarFp.searchConversations(channelId, agentStageId, agentStageAccountId, personId, status, startFromDate, startToDate, lastMessageFromDate, lastMessageToDate, latestMessageText, sorting, pageIndex, pageSize, withTotal, fromItemInclusive, toItemExclusive, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ConversationApi - object-oriented interface
 * @export
 * @class ConversationApi
 * @extends {BaseAPI}
 */
export class ConversationApi extends BaseAPI {
    /**
     * 
     * @summary Получить беседу.
     * @param {string} id ИД беседы.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConversationApi
     */
    public getConversation(id: string, options?: RawAxiosRequestConfig) {
        return ConversationApiFp(this.configuration).getConversation(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Получить количество бесед.
     * @param {string} [channelId] ИД канала.
     * @param {string} [agentStageId] ИД стадии бота.
     * @param {string} [agentStageAccountId] ИД аккаунта стадии бота.
     * @param {string} [personId] ИД сотрудника.
     * @param {ConversationStatus} [status] Статус беседы.
     * @param {string} [startFromDate] Дата начала беседы (начало промежутка)
     * @param {string} [startToDate] Дата начала беседы (окончание промежутка)
     * @param {string} [lastMessageFromDate] Дата последнего сообщения (начало промежутка)
     * @param {string} [lastMessageToDate] Дата последнего сообщения (окончание промежутка)
     * @param {string} [latestMessageText] Текст последнего сообщения
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConversationApi
     */
    public getConversationCount(channelId?: string, agentStageId?: string, agentStageAccountId?: string, personId?: string, status?: ConversationStatus, startFromDate?: string, startToDate?: string, lastMessageFromDate?: string, lastMessageToDate?: string, latestMessageText?: string, options?: RawAxiosRequestConfig) {
        return ConversationApiFp(this.configuration).getConversationCount(channelId, agentStageId, agentStageAccountId, personId, status, startFromDate, startToDate, lastMessageFromDate, lastMessageToDate, latestMessageText, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Получить количество уникальных пользователей бесед.
     * @param {string} [channelId] ИД канала.
     * @param {string} [agentStageId] ИД стадии бота.
     * @param {string} [agentStageAccountId] ИД аккаунта стадии бота.
     * @param {string} [personId] ИД сотрудника.
     * @param {ConversationStatus} [status] Статус беседы.
     * @param {string} [startFromDate] Дата начала беседы (начало промежутка)
     * @param {string} [startToDate] Дата начала беседы (окончание промежутка)
     * @param {string} [lastMessageFromDate] Дата последнего сообщения (начало промежутка)
     * @param {string} [lastMessageToDate] Дата последнего сообщения (окончание промежутка)
     * @param {string} [latestMessageText] Текст последнего сообщения
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConversationApi
     */
    public getConversationsUniqueUserCount(channelId?: string, agentStageId?: string, agentStageAccountId?: string, personId?: string, status?: ConversationStatus, startFromDate?: string, startToDate?: string, lastMessageFromDate?: string, lastMessageToDate?: string, latestMessageText?: string, options?: RawAxiosRequestConfig) {
        return ConversationApiFp(this.configuration).getConversationsUniqueUserCount(channelId, agentStageId, agentStageAccountId, personId, status, startFromDate, startToDate, lastMessageFromDate, lastMessageToDate, latestMessageText, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Выполнить поиск бесед.
     * @param {string} [channelId] ИД канала.
     * @param {string} [agentStageId] ИД стадии бота.
     * @param {string} [agentStageAccountId] ИД аккаунта стадии бота.
     * @param {string} [personId] ИД сотрудника.
     * @param {ConversationStatus} [status] Статус беседы.
     * @param {string} [startFromDate] Дата начала беседы (начало промежутка)
     * @param {string} [startToDate] Дата начала беседы (окончание промежутка)
     * @param {string} [lastMessageFromDate] Дата последнего сообщения (начало промежутка)
     * @param {string} [lastMessageToDate] Дата последнего сообщения (окончание промежутка)
     * @param {string} [latestMessageText] Текст последнего сообщения
     * @param {ConversationSortDirection} [sorting] Параметры сортировки.
     * @param {number} [pageIndex] 
     * @param {number} [pageSize] 
     * @param {boolean} [withTotal] 
     * @param {number} [fromItemInclusive] 
     * @param {number} [toItemExclusive] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConversationApi
     */
    public searchConversations(channelId?: string, agentStageId?: string, agentStageAccountId?: string, personId?: string, status?: ConversationStatus, startFromDate?: string, startToDate?: string, lastMessageFromDate?: string, lastMessageToDate?: string, latestMessageText?: string, sorting?: ConversationSortDirection, pageIndex?: number, pageSize?: number, withTotal?: boolean, fromItemInclusive?: number, toItemExclusive?: number, options?: RawAxiosRequestConfig) {
        return ConversationApiFp(this.configuration).searchConversations(channelId, agentStageId, agentStageAccountId, personId, status, startFromDate, startToDate, lastMessageFromDate, lastMessageToDate, latestMessageText, sorting, pageIndex, pageSize, withTotal, fromItemInclusive, toItemExclusive, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * ConversationExportApi - axios parameter creator
 * @export
 */
export const ConversationExportApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Получить файл экспорта истории диалога.
         * @param {string} conversationId ИД беседы.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        conversationExport: async (conversationId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'conversationId' is not null or undefined
            assertParamExists('conversationExport', 'conversationId', conversationId)
            const localVarPath = `/api/v1/dialogs/export/file/{conversationId}`
                .replace(`{${"conversationId"}}`, encodeURIComponent(String(conversationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth20 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth20", ["elma.assistant.resources.identity.context", "elma.assistant.scopes.api.admin", "email", "offline_access", "openid", "phone", "profile"], configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Проверить статус экспорта истории диалога.
         * @param {string} requestId ИД запроса.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        conversationExportStatus: async (requestId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'requestId' is not null or undefined
            assertParamExists('conversationExportStatus', 'requestId', requestId)
            const localVarPath = `/api/v1/dialogs/export/status/{requestId}`
                .replace(`{${"requestId"}}`, encodeURIComponent(String(requestId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth20 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth20", ["elma.assistant.resources.identity.context", "elma.assistant.scopes.api.admin", "email", "offline_access", "openid", "phone", "profile"], configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Запустить экспорт истории диалога.
         * @param {string} conversationId ИД беседы.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        runConversationExport: async (conversationId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'conversationId' is not null or undefined
            assertParamExists('runConversationExport', 'conversationId', conversationId)
            const localVarPath = `/api/v1/dialogs/export/{conversationId}`
                .replace(`{${"conversationId"}}`, encodeURIComponent(String(conversationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth20 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth20", ["elma.assistant.resources.identity.context", "elma.assistant.scopes.api.admin", "email", "offline_access", "openid", "phone", "profile"], configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Запустить экспорт истории диалогов, выбранных по фильтру.
         * @param {ConversationExportFilterParams} [conversationExportFilterParams] Параметры фильтрации.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        runConversationExportByFilter: async (conversationExportFilterParams?: ConversationExportFilterParams, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/dialogs/export`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth20 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth20", ["elma.assistant.resources.identity.context", "elma.assistant.scopes.api.admin", "email", "offline_access", "openid", "phone", "profile"], configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(conversationExportFilterParams, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ConversationExportApi - functional programming interface
 * @export
 */
export const ConversationExportApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ConversationExportApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Получить файл экспорта истории диалога.
         * @param {string} conversationId ИД беседы.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async conversationExport(conversationId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<File>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.conversationExport(conversationId, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['ConversationExportApi.conversationExport']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Проверить статус экспорта истории диалога.
         * @param {string} requestId ИД запроса.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async conversationExportStatus(requestId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ConversationExportStatusResultModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.conversationExportStatus(requestId, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['ConversationExportApi.conversationExportStatus']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Запустить экспорт истории диалога.
         * @param {string} conversationId ИД беседы.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async runConversationExport(conversationId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RunConversationExportResultModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.runConversationExport(conversationId, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['ConversationExportApi.runConversationExport']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Запустить экспорт истории диалогов, выбранных по фильтру.
         * @param {ConversationExportFilterParams} [conversationExportFilterParams] Параметры фильтрации.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async runConversationExportByFilter(conversationExportFilterParams?: ConversationExportFilterParams, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RunConversationExportResultModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.runConversationExportByFilter(conversationExportFilterParams, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['ConversationExportApi.runConversationExportByFilter']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
    }
};

/**
 * ConversationExportApi - factory interface
 * @export
 */
export const ConversationExportApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ConversationExportApiFp(configuration)
    return {
        /**
         * 
         * @summary Получить файл экспорта истории диалога.
         * @param {string} conversationId ИД беседы.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        conversationExport(conversationId: string, options?: any): AxiosPromise<File> {
            return localVarFp.conversationExport(conversationId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Проверить статус экспорта истории диалога.
         * @param {string} requestId ИД запроса.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        conversationExportStatus(requestId: string, options?: any): AxiosPromise<ConversationExportStatusResultModel> {
            return localVarFp.conversationExportStatus(requestId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Запустить экспорт истории диалога.
         * @param {string} conversationId ИД беседы.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        runConversationExport(conversationId: string, options?: any): AxiosPromise<RunConversationExportResultModel> {
            return localVarFp.runConversationExport(conversationId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Запустить экспорт истории диалогов, выбранных по фильтру.
         * @param {ConversationExportFilterParams} [conversationExportFilterParams] Параметры фильтрации.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        runConversationExportByFilter(conversationExportFilterParams?: ConversationExportFilterParams, options?: any): AxiosPromise<RunConversationExportResultModel> {
            return localVarFp.runConversationExportByFilter(conversationExportFilterParams, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ConversationExportApi - object-oriented interface
 * @export
 * @class ConversationExportApi
 * @extends {BaseAPI}
 */
export class ConversationExportApi extends BaseAPI {
    /**
     * 
     * @summary Получить файл экспорта истории диалога.
     * @param {string} conversationId ИД беседы.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConversationExportApi
     */
    public conversationExport(conversationId: string, options?: RawAxiosRequestConfig) {
        return ConversationExportApiFp(this.configuration).conversationExport(conversationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Проверить статус экспорта истории диалога.
     * @param {string} requestId ИД запроса.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConversationExportApi
     */
    public conversationExportStatus(requestId: string, options?: RawAxiosRequestConfig) {
        return ConversationExportApiFp(this.configuration).conversationExportStatus(requestId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Запустить экспорт истории диалога.
     * @param {string} conversationId ИД беседы.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConversationExportApi
     */
    public runConversationExport(conversationId: string, options?: RawAxiosRequestConfig) {
        return ConversationExportApiFp(this.configuration).runConversationExport(conversationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Запустить экспорт истории диалогов, выбранных по фильтру.
     * @param {ConversationExportFilterParams} [conversationExportFilterParams] Параметры фильтрации.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConversationExportApi
     */
    public runConversationExportByFilter(conversationExportFilterParams?: ConversationExportFilterParams, options?: RawAxiosRequestConfig) {
        return ConversationExportApiFp(this.configuration).runConversationExportByFilter(conversationExportFilterParams, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * DirectLineApi - axios parameter creator
 * @export
 */
export const DirectLineApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Создать беседу.
         * @param {string} agentStageId ИД стадии бота.
         * @param {string} [userId] 
         * @param {string} [userName] 
         * @param {string} [eTag] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createDlConversation: async (agentStageId: string, userId?: string, userName?: string, eTag?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'agentStageId' is not null or undefined
            assertParamExists('createDlConversation', 'agentStageId', agentStageId)
            const localVarPath = `/api/v1/directline/{agentStageId}/conversations`
                .replace(`{${"agentStageId"}}`, encodeURIComponent(String(agentStageId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication OAuth20 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth20", ["elma.assistant.resources.identity.context", "elma.assistant.scopes.api.admin", "email", "offline_access", "openid", "phone", "profile"], configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


            if (userId !== undefined) { 
                localVarFormParams.append('User.Id', userId as any);
            }
    
            if (userName !== undefined) { 
                localVarFormParams.append('User.Name', userName as any);
            }
    
            if (eTag !== undefined) { 
                localVarFormParams.append('ETag', eTag as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Создать беседу.
         * @param {string} agentStageId ИД стадии бота.
         * @param {string} [userId] 
         * @param {string} [userName] 
         * @param {string} [eTag] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createTestingDlConversation: async (agentStageId: string, userId?: string, userName?: string, eTag?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'agentStageId' is not null or undefined
            assertParamExists('createTestingDlConversation', 'agentStageId', agentStageId)
            const localVarPath = `/api/v1/directline/{agentStageId}/testing/conversations`
                .replace(`{${"agentStageId"}}`, encodeURIComponent(String(agentStageId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication OAuth20 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth20", ["elma.assistant.resources.identity.context", "elma.assistant.scopes.api.admin", "email", "offline_access", "openid", "phone", "profile"], configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


            if (userId !== undefined) { 
                localVarFormParams.append('User.Id', userId as any);
            }
    
            if (userName !== undefined) { 
                localVarFormParams.append('User.Name', userName as any);
            }
    
            if (eTag !== undefined) { 
                localVarFormParams.append('ETag', eTag as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Получить действия беседы.
         * @param {string} agentStageId ИД стадии бота.
         * @param {string} conversationId ИД беседы.
         * @param {string} [watermark] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDlActivities: async (agentStageId: string, conversationId: string, watermark?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'agentStageId' is not null or undefined
            assertParamExists('getDlActivities', 'agentStageId', agentStageId)
            // verify required parameter 'conversationId' is not null or undefined
            assertParamExists('getDlActivities', 'conversationId', conversationId)
            const localVarPath = `/api/v1/directline/{agentStageId}/conversations/{conversationId}/activities`
                .replace(`{${"agentStageId"}}`, encodeURIComponent(String(agentStageId)))
                .replace(`{${"conversationId"}}`, encodeURIComponent(String(conversationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth20 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth20", ["elma.assistant.resources.identity.context", "elma.assistant.scopes.api.admin", "email", "offline_access", "openid", "phone", "profile"], configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (watermark !== undefined) {
                localVarQueryParameter['Watermark'] = watermark;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Получить сведения о беседе.
         * @param {string} agentStageId ИД стадии бота.
         * @param {string} conversationId ИД беседы.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDlConversation: async (agentStageId: string, conversationId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'agentStageId' is not null or undefined
            assertParamExists('getDlConversation', 'agentStageId', agentStageId)
            // verify required parameter 'conversationId' is not null or undefined
            assertParamExists('getDlConversation', 'conversationId', conversationId)
            const localVarPath = `/api/v1/directline/{agentStageId}/conversations/{conversationId}`
                .replace(`{${"agentStageId"}}`, encodeURIComponent(String(agentStageId)))
                .replace(`{${"conversationId"}}`, encodeURIComponent(String(conversationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth20 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth20", ["elma.assistant.resources.identity.context", "elma.assistant.scopes.api.admin", "email", "offline_access", "openid", "phone", "profile"], configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Получить действия беседы.
         * @param {string} agentStageId ИД стадии бота.
         * @param {string} conversationId ИД беседы.
         * @param {string} [watermark] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTestingDlActivities: async (agentStageId: string, conversationId: string, watermark?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'agentStageId' is not null or undefined
            assertParamExists('getTestingDlActivities', 'agentStageId', agentStageId)
            // verify required parameter 'conversationId' is not null or undefined
            assertParamExists('getTestingDlActivities', 'conversationId', conversationId)
            const localVarPath = `/api/v1/directline/{agentStageId}/testing/conversations/{conversationId}/activities`
                .replace(`{${"agentStageId"}}`, encodeURIComponent(String(agentStageId)))
                .replace(`{${"conversationId"}}`, encodeURIComponent(String(conversationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth20 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth20", ["elma.assistant.resources.identity.context", "elma.assistant.scopes.api.admin", "email", "offline_access", "openid", "phone", "profile"], configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (watermark !== undefined) {
                localVarQueryParameter['Watermark'] = watermark;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Получить сведения о беседе.
         * @param {string} agentStageId ИД стадии бота.
         * @param {string} conversationId ИД беседы.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTestingDlConversation: async (agentStageId: string, conversationId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'agentStageId' is not null or undefined
            assertParamExists('getTestingDlConversation', 'agentStageId', agentStageId)
            // verify required parameter 'conversationId' is not null or undefined
            assertParamExists('getTestingDlConversation', 'conversationId', conversationId)
            const localVarPath = `/api/v1/directline/{agentStageId}/testing/conversations/{conversationId}`
                .replace(`{${"agentStageId"}}`, encodeURIComponent(String(agentStageId)))
                .replace(`{${"conversationId"}}`, encodeURIComponent(String(conversationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth20 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth20", ["elma.assistant.resources.identity.context", "elma.assistant.scopes.api.admin", "email", "offline_access", "openid", "phone", "profile"], configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Обновить токен.
         * @param {string} agentStageId ИД стадии бота.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        refreshDlToken: async (agentStageId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'agentStageId' is not null or undefined
            assertParamExists('refreshDlToken', 'agentStageId', agentStageId)
            const localVarPath = `/api/v1/directline/{agentStageId}/tokens/refresh`
                .replace(`{${"agentStageId"}}`, encodeURIComponent(String(agentStageId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth20 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth20", ["elma.assistant.resources.identity.context", "elma.assistant.scopes.api.admin", "email", "offline_access", "openid", "phone", "profile"], configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Обновить токен.
         * @param {string} agentStageId ИД стадии бота.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        refreshTestingDlToken: async (agentStageId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'agentStageId' is not null or undefined
            assertParamExists('refreshTestingDlToken', 'agentStageId', agentStageId)
            const localVarPath = `/api/v1/directline/{agentStageId}/testing/tokens/refresh`
                .replace(`{${"agentStageId"}}`, encodeURIComponent(String(agentStageId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth20 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth20", ["elma.assistant.resources.identity.context", "elma.assistant.scopes.api.admin", "email", "offline_access", "openid", "phone", "profile"], configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Сохранить действие в беседу (для ответов от бота).
         * @param {string} agentStageId ИД стадии бота.
         * @param {string} conversationId ИД беседы.
         * @param {Activity} [activity] Запрос на сохранение действия.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveDlBotActivity: async (agentStageId: string, conversationId: string, activity?: Activity, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'agentStageId' is not null or undefined
            assertParamExists('saveDlBotActivity', 'agentStageId', agentStageId)
            // verify required parameter 'conversationId' is not null or undefined
            assertParamExists('saveDlBotActivity', 'conversationId', conversationId)
            const localVarPath = `/api/v1/directline/{agentStageId}/v3/conversations/{conversationId}/activities`
                .replace(`{${"agentStageId"}}`, encodeURIComponent(String(agentStageId)))
                .replace(`{${"conversationId"}}`, encodeURIComponent(String(conversationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth20 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth20", ["elma.assistant.resources.identity.context", "elma.assistant.scopes.api.admin", "email", "offline_access", "openid", "phone", "profile"], configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(activity, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Сохранить действие в беседу (для ответов от бота).
         * @param {string} agentStageId ИД стадии бота.
         * @param {string} conversationId ИД беседы.
         * @param {string} replyToId ИД действия, на который пришел ответ.
         * @param {Activity} [activity] Запрос на сохранение действия.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveDlBotActivityWithReply: async (agentStageId: string, conversationId: string, replyToId: string, activity?: Activity, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'agentStageId' is not null or undefined
            assertParamExists('saveDlBotActivityWithReply', 'agentStageId', agentStageId)
            // verify required parameter 'conversationId' is not null or undefined
            assertParamExists('saveDlBotActivityWithReply', 'conversationId', conversationId)
            // verify required parameter 'replyToId' is not null or undefined
            assertParamExists('saveDlBotActivityWithReply', 'replyToId', replyToId)
            const localVarPath = `/api/v1/directline/{agentStageId}/v3/conversations/{conversationId}/activities/{replyToId}`
                .replace(`{${"agentStageId"}}`, encodeURIComponent(String(agentStageId)))
                .replace(`{${"conversationId"}}`, encodeURIComponent(String(conversationId)))
                .replace(`{${"replyToId"}}`, encodeURIComponent(String(replyToId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth20 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth20", ["elma.assistant.resources.identity.context", "elma.assistant.scopes.api.admin", "email", "offline_access", "openid", "phone", "profile"], configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(activity, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Сохранить действие в беседу (для ответов от бота).
         * @param {string} agentStageId ИД стадии бота.
         * @param {string} conversationId ИД беседы.
         * @param {Activity} [activity] Запрос на сохранение действия.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveTestingDlBotActivity: async (agentStageId: string, conversationId: string, activity?: Activity, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'agentStageId' is not null or undefined
            assertParamExists('saveTestingDlBotActivity', 'agentStageId', agentStageId)
            // verify required parameter 'conversationId' is not null or undefined
            assertParamExists('saveTestingDlBotActivity', 'conversationId', conversationId)
            const localVarPath = `/api/v1/directline/{agentStageId}/testing/v3/conversations/{conversationId}/activities`
                .replace(`{${"agentStageId"}}`, encodeURIComponent(String(agentStageId)))
                .replace(`{${"conversationId"}}`, encodeURIComponent(String(conversationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth20 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth20", ["elma.assistant.resources.identity.context", "elma.assistant.scopes.api.admin", "email", "offline_access", "openid", "phone", "profile"], configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(activity, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Сохранить действие в беседу (для ответов от бота).
         * @param {string} agentStageId ИД стадии бота.
         * @param {string} conversationId ИД беседы.
         * @param {string} replyToId ИД действия, на который пришел ответ.
         * @param {Activity} [activity] Запрос на сохранение действия.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveTestingDlBotActivityWithReply: async (agentStageId: string, conversationId: string, replyToId: string, activity?: Activity, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'agentStageId' is not null or undefined
            assertParamExists('saveTestingDlBotActivityWithReply', 'agentStageId', agentStageId)
            // verify required parameter 'conversationId' is not null or undefined
            assertParamExists('saveTestingDlBotActivityWithReply', 'conversationId', conversationId)
            // verify required parameter 'replyToId' is not null or undefined
            assertParamExists('saveTestingDlBotActivityWithReply', 'replyToId', replyToId)
            const localVarPath = `/api/v1/directline/{agentStageId}/testing/v3/conversations/{conversationId}/activities/{replyToId}`
                .replace(`{${"agentStageId"}}`, encodeURIComponent(String(agentStageId)))
                .replace(`{${"conversationId"}}`, encodeURIComponent(String(conversationId)))
                .replace(`{${"replyToId"}}`, encodeURIComponent(String(replyToId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth20 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth20", ["elma.assistant.resources.identity.context", "elma.assistant.scopes.api.admin", "email", "offline_access", "openid", "phone", "profile"], configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(activity, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Отправить действие в беседу (для запросов от пользователя).
         * @param {string} agentStageId ИД стадии бота.
         * @param {string} conversationId ИД беседы.
         * @param {Activity} [activity] Запрос на отправку действия в беседу.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendDlUserActivity: async (agentStageId: string, conversationId: string, activity?: Activity, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'agentStageId' is not null or undefined
            assertParamExists('sendDlUserActivity', 'agentStageId', agentStageId)
            // verify required parameter 'conversationId' is not null or undefined
            assertParamExists('sendDlUserActivity', 'conversationId', conversationId)
            const localVarPath = `/api/v1/directline/{agentStageId}/conversations/{conversationId}/activities`
                .replace(`{${"agentStageId"}}`, encodeURIComponent(String(agentStageId)))
                .replace(`{${"conversationId"}}`, encodeURIComponent(String(conversationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth20 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth20", ["elma.assistant.resources.identity.context", "elma.assistant.scopes.api.admin", "email", "offline_access", "openid", "phone", "profile"], configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(activity, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Отправить действие в беседу (для запросов от пользователя).
         * @param {string} agentStageId ИД стадии бота.
         * @param {string} conversationId ИД беседы.
         * @param {Activity} [activity] Запрос на отправку действия в беседу.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendTestingDlUserActivity: async (agentStageId: string, conversationId: string, activity?: Activity, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'agentStageId' is not null or undefined
            assertParamExists('sendTestingDlUserActivity', 'agentStageId', agentStageId)
            // verify required parameter 'conversationId' is not null or undefined
            assertParamExists('sendTestingDlUserActivity', 'conversationId', conversationId)
            const localVarPath = `/api/v1/directline/{agentStageId}/testing/conversations/{conversationId}/activities`
                .replace(`{${"agentStageId"}}`, encodeURIComponent(String(agentStageId)))
                .replace(`{${"conversationId"}}`, encodeURIComponent(String(conversationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth20 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth20", ["elma.assistant.resources.identity.context", "elma.assistant.scopes.api.admin", "email", "offline_access", "openid", "phone", "profile"], configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(activity, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Отправить файл в беседу.
         * @param {string} agentStageId ИД стадии бота.
         * @param {string} conversationId ИД беседы.
         * @param {string} [userId] ИД пользователя.
         * @param {Array<File>} [file] 
         * @param {File} [activity] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadDlFile: async (agentStageId: string, conversationId: string, userId?: string, file?: Array<File>, activity?: File, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'agentStageId' is not null or undefined
            assertParamExists('uploadDlFile', 'agentStageId', agentStageId)
            // verify required parameter 'conversationId' is not null or undefined
            assertParamExists('uploadDlFile', 'conversationId', conversationId)
            const localVarPath = `/api/v1/directline/{agentStageId}/conversations/{conversationId}/upload`
                .replace(`{${"agentStageId"}}`, encodeURIComponent(String(agentStageId)))
                .replace(`{${"conversationId"}}`, encodeURIComponent(String(conversationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication OAuth20 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth20", ["elma.assistant.resources.identity.context", "elma.assistant.scopes.api.admin", "email", "offline_access", "openid", "phone", "profile"], configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (userId !== undefined) {
                localVarQueryParameter['userId'] = userId;
            }

            if (file) {
                file.forEach((element) => {
                    localVarFormParams.append('file', element as any);
                })
            }

    
            if (activity !== undefined) { 
                localVarFormParams.append('activity', activity as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Отправить файл в беседу.
         * @param {string} agentStageId ИД стадии бота.
         * @param {string} conversationId ИД беседы.
         * @param {string} [userId] ИД пользователя.
         * @param {Array<File>} [file] 
         * @param {File} [activity] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadTestingDlFile: async (agentStageId: string, conversationId: string, userId?: string, file?: Array<File>, activity?: File, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'agentStageId' is not null or undefined
            assertParamExists('uploadTestingDlFile', 'agentStageId', agentStageId)
            // verify required parameter 'conversationId' is not null or undefined
            assertParamExists('uploadTestingDlFile', 'conversationId', conversationId)
            const localVarPath = `/api/v1/directline/{agentStageId}/testing/conversations/{conversationId}/upload`
                .replace(`{${"agentStageId"}}`, encodeURIComponent(String(agentStageId)))
                .replace(`{${"conversationId"}}`, encodeURIComponent(String(conversationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication OAuth20 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth20", ["elma.assistant.resources.identity.context", "elma.assistant.scopes.api.admin", "email", "offline_access", "openid", "phone", "profile"], configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (userId !== undefined) {
                localVarQueryParameter['userId'] = userId;
            }

            if (file) {
                file.forEach((element) => {
                    localVarFormParams.append('file', element as any);
                })
            }

    
            if (activity !== undefined) { 
                localVarFormParams.append('activity', activity as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DirectLineApi - functional programming interface
 * @export
 */
export const DirectLineApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DirectLineApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Создать беседу.
         * @param {string} agentStageId ИД стадии бота.
         * @param {string} [userId] 
         * @param {string} [userName] 
         * @param {string} [eTag] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createDlConversation(agentStageId: string, userId?: string, userName?: string, eTag?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Conversation>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createDlConversation(agentStageId, userId, userName, eTag, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DirectLineApi.createDlConversation']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Создать беседу.
         * @param {string} agentStageId ИД стадии бота.
         * @param {string} [userId] 
         * @param {string} [userName] 
         * @param {string} [eTag] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createTestingDlConversation(agentStageId: string, userId?: string, userName?: string, eTag?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Conversation>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createTestingDlConversation(agentStageId, userId, userName, eTag, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DirectLineApi.createTestingDlConversation']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Получить действия беседы.
         * @param {string} agentStageId ИД стадии бота.
         * @param {string} conversationId ИД беседы.
         * @param {string} [watermark] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDlActivities(agentStageId: string, conversationId: string, watermark?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ActivitySet>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDlActivities(agentStageId, conversationId, watermark, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DirectLineApi.getDlActivities']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Получить сведения о беседе.
         * @param {string} agentStageId ИД стадии бота.
         * @param {string} conversationId ИД беседы.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDlConversation(agentStageId: string, conversationId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Conversation>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDlConversation(agentStageId, conversationId, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DirectLineApi.getDlConversation']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Получить действия беседы.
         * @param {string} agentStageId ИД стадии бота.
         * @param {string} conversationId ИД беседы.
         * @param {string} [watermark] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTestingDlActivities(agentStageId: string, conversationId: string, watermark?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ActivitySet>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTestingDlActivities(agentStageId, conversationId, watermark, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DirectLineApi.getTestingDlActivities']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Получить сведения о беседе.
         * @param {string} agentStageId ИД стадии бота.
         * @param {string} conversationId ИД беседы.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTestingDlConversation(agentStageId: string, conversationId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Conversation>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTestingDlConversation(agentStageId, conversationId, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DirectLineApi.getTestingDlConversation']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Обновить токен.
         * @param {string} agentStageId ИД стадии бота.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async refreshDlToken(agentStageId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Conversation>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.refreshDlToken(agentStageId, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DirectLineApi.refreshDlToken']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Обновить токен.
         * @param {string} agentStageId ИД стадии бота.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async refreshTestingDlToken(agentStageId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Conversation>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.refreshTestingDlToken(agentStageId, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DirectLineApi.refreshTestingDlToken']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Сохранить действие в беседу (для ответов от бота).
         * @param {string} agentStageId ИД стадии бота.
         * @param {string} conversationId ИД беседы.
         * @param {Activity} [activity] Запрос на сохранение действия.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async saveDlBotActivity(agentStageId: string, conversationId: string, activity?: Activity, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResourceResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.saveDlBotActivity(agentStageId, conversationId, activity, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DirectLineApi.saveDlBotActivity']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Сохранить действие в беседу (для ответов от бота).
         * @param {string} agentStageId ИД стадии бота.
         * @param {string} conversationId ИД беседы.
         * @param {string} replyToId ИД действия, на который пришел ответ.
         * @param {Activity} [activity] Запрос на сохранение действия.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async saveDlBotActivityWithReply(agentStageId: string, conversationId: string, replyToId: string, activity?: Activity, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResourceResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.saveDlBotActivityWithReply(agentStageId, conversationId, replyToId, activity, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DirectLineApi.saveDlBotActivityWithReply']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Сохранить действие в беседу (для ответов от бота).
         * @param {string} agentStageId ИД стадии бота.
         * @param {string} conversationId ИД беседы.
         * @param {Activity} [activity] Запрос на сохранение действия.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async saveTestingDlBotActivity(agentStageId: string, conversationId: string, activity?: Activity, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResourceResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.saveTestingDlBotActivity(agentStageId, conversationId, activity, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DirectLineApi.saveTestingDlBotActivity']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Сохранить действие в беседу (для ответов от бота).
         * @param {string} agentStageId ИД стадии бота.
         * @param {string} conversationId ИД беседы.
         * @param {string} replyToId ИД действия, на который пришел ответ.
         * @param {Activity} [activity] Запрос на сохранение действия.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async saveTestingDlBotActivityWithReply(agentStageId: string, conversationId: string, replyToId: string, activity?: Activity, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResourceResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.saveTestingDlBotActivityWithReply(agentStageId, conversationId, replyToId, activity, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DirectLineApi.saveTestingDlBotActivityWithReply']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Отправить действие в беседу (для запросов от пользователя).
         * @param {string} agentStageId ИД стадии бота.
         * @param {string} conversationId ИД беседы.
         * @param {Activity} [activity] Запрос на отправку действия в беседу.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sendDlUserActivity(agentStageId: string, conversationId: string, activity?: Activity, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResourceResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.sendDlUserActivity(agentStageId, conversationId, activity, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DirectLineApi.sendDlUserActivity']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Отправить действие в беседу (для запросов от пользователя).
         * @param {string} agentStageId ИД стадии бота.
         * @param {string} conversationId ИД беседы.
         * @param {Activity} [activity] Запрос на отправку действия в беседу.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sendTestingDlUserActivity(agentStageId: string, conversationId: string, activity?: Activity, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResourceResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.sendTestingDlUserActivity(agentStageId, conversationId, activity, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DirectLineApi.sendTestingDlUserActivity']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Отправить файл в беседу.
         * @param {string} agentStageId ИД стадии бота.
         * @param {string} conversationId ИД беседы.
         * @param {string} [userId] ИД пользователя.
         * @param {Array<File>} [file] 
         * @param {File} [activity] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async uploadDlFile(agentStageId: string, conversationId: string, userId?: string, file?: Array<File>, activity?: File, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResourceResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.uploadDlFile(agentStageId, conversationId, userId, file, activity, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DirectLineApi.uploadDlFile']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Отправить файл в беседу.
         * @param {string} agentStageId ИД стадии бота.
         * @param {string} conversationId ИД беседы.
         * @param {string} [userId] ИД пользователя.
         * @param {Array<File>} [file] 
         * @param {File} [activity] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async uploadTestingDlFile(agentStageId: string, conversationId: string, userId?: string, file?: Array<File>, activity?: File, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResourceResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.uploadTestingDlFile(agentStageId, conversationId, userId, file, activity, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DirectLineApi.uploadTestingDlFile']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
    }
};

/**
 * DirectLineApi - factory interface
 * @export
 */
export const DirectLineApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DirectLineApiFp(configuration)
    return {
        /**
         * 
         * @summary Создать беседу.
         * @param {string} agentStageId ИД стадии бота.
         * @param {string} [userId] 
         * @param {string} [userName] 
         * @param {string} [eTag] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createDlConversation(agentStageId: string, userId?: string, userName?: string, eTag?: string, options?: any): AxiosPromise<Conversation> {
            return localVarFp.createDlConversation(agentStageId, userId, userName, eTag, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Создать беседу.
         * @param {string} agentStageId ИД стадии бота.
         * @param {string} [userId] 
         * @param {string} [userName] 
         * @param {string} [eTag] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createTestingDlConversation(agentStageId: string, userId?: string, userName?: string, eTag?: string, options?: any): AxiosPromise<Conversation> {
            return localVarFp.createTestingDlConversation(agentStageId, userId, userName, eTag, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Получить действия беседы.
         * @param {string} agentStageId ИД стадии бота.
         * @param {string} conversationId ИД беседы.
         * @param {string} [watermark] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDlActivities(agentStageId: string, conversationId: string, watermark?: string, options?: any): AxiosPromise<ActivitySet> {
            return localVarFp.getDlActivities(agentStageId, conversationId, watermark, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Получить сведения о беседе.
         * @param {string} agentStageId ИД стадии бота.
         * @param {string} conversationId ИД беседы.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDlConversation(agentStageId: string, conversationId: string, options?: any): AxiosPromise<Conversation> {
            return localVarFp.getDlConversation(agentStageId, conversationId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Получить действия беседы.
         * @param {string} agentStageId ИД стадии бота.
         * @param {string} conversationId ИД беседы.
         * @param {string} [watermark] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTestingDlActivities(agentStageId: string, conversationId: string, watermark?: string, options?: any): AxiosPromise<ActivitySet> {
            return localVarFp.getTestingDlActivities(agentStageId, conversationId, watermark, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Получить сведения о беседе.
         * @param {string} agentStageId ИД стадии бота.
         * @param {string} conversationId ИД беседы.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTestingDlConversation(agentStageId: string, conversationId: string, options?: any): AxiosPromise<Conversation> {
            return localVarFp.getTestingDlConversation(agentStageId, conversationId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Обновить токен.
         * @param {string} agentStageId ИД стадии бота.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        refreshDlToken(agentStageId: string, options?: any): AxiosPromise<Conversation> {
            return localVarFp.refreshDlToken(agentStageId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Обновить токен.
         * @param {string} agentStageId ИД стадии бота.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        refreshTestingDlToken(agentStageId: string, options?: any): AxiosPromise<Conversation> {
            return localVarFp.refreshTestingDlToken(agentStageId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Сохранить действие в беседу (для ответов от бота).
         * @param {string} agentStageId ИД стадии бота.
         * @param {string} conversationId ИД беседы.
         * @param {Activity} [activity] Запрос на сохранение действия.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveDlBotActivity(agentStageId: string, conversationId: string, activity?: Activity, options?: any): AxiosPromise<ResourceResponse> {
            return localVarFp.saveDlBotActivity(agentStageId, conversationId, activity, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Сохранить действие в беседу (для ответов от бота).
         * @param {string} agentStageId ИД стадии бота.
         * @param {string} conversationId ИД беседы.
         * @param {string} replyToId ИД действия, на который пришел ответ.
         * @param {Activity} [activity] Запрос на сохранение действия.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveDlBotActivityWithReply(agentStageId: string, conversationId: string, replyToId: string, activity?: Activity, options?: any): AxiosPromise<ResourceResponse> {
            return localVarFp.saveDlBotActivityWithReply(agentStageId, conversationId, replyToId, activity, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Сохранить действие в беседу (для ответов от бота).
         * @param {string} agentStageId ИД стадии бота.
         * @param {string} conversationId ИД беседы.
         * @param {Activity} [activity] Запрос на сохранение действия.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveTestingDlBotActivity(agentStageId: string, conversationId: string, activity?: Activity, options?: any): AxiosPromise<ResourceResponse> {
            return localVarFp.saveTestingDlBotActivity(agentStageId, conversationId, activity, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Сохранить действие в беседу (для ответов от бота).
         * @param {string} agentStageId ИД стадии бота.
         * @param {string} conversationId ИД беседы.
         * @param {string} replyToId ИД действия, на который пришел ответ.
         * @param {Activity} [activity] Запрос на сохранение действия.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveTestingDlBotActivityWithReply(agentStageId: string, conversationId: string, replyToId: string, activity?: Activity, options?: any): AxiosPromise<ResourceResponse> {
            return localVarFp.saveTestingDlBotActivityWithReply(agentStageId, conversationId, replyToId, activity, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Отправить действие в беседу (для запросов от пользователя).
         * @param {string} agentStageId ИД стадии бота.
         * @param {string} conversationId ИД беседы.
         * @param {Activity} [activity] Запрос на отправку действия в беседу.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendDlUserActivity(agentStageId: string, conversationId: string, activity?: Activity, options?: any): AxiosPromise<ResourceResponse> {
            return localVarFp.sendDlUserActivity(agentStageId, conversationId, activity, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Отправить действие в беседу (для запросов от пользователя).
         * @param {string} agentStageId ИД стадии бота.
         * @param {string} conversationId ИД беседы.
         * @param {Activity} [activity] Запрос на отправку действия в беседу.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendTestingDlUserActivity(agentStageId: string, conversationId: string, activity?: Activity, options?: any): AxiosPromise<ResourceResponse> {
            return localVarFp.sendTestingDlUserActivity(agentStageId, conversationId, activity, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Отправить файл в беседу.
         * @param {string} agentStageId ИД стадии бота.
         * @param {string} conversationId ИД беседы.
         * @param {string} [userId] ИД пользователя.
         * @param {Array<File>} [file] 
         * @param {File} [activity] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadDlFile(agentStageId: string, conversationId: string, userId?: string, file?: Array<File>, activity?: File, options?: any): AxiosPromise<ResourceResponse> {
            return localVarFp.uploadDlFile(agentStageId, conversationId, userId, file, activity, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Отправить файл в беседу.
         * @param {string} agentStageId ИД стадии бота.
         * @param {string} conversationId ИД беседы.
         * @param {string} [userId] ИД пользователя.
         * @param {Array<File>} [file] 
         * @param {File} [activity] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadTestingDlFile(agentStageId: string, conversationId: string, userId?: string, file?: Array<File>, activity?: File, options?: any): AxiosPromise<ResourceResponse> {
            return localVarFp.uploadTestingDlFile(agentStageId, conversationId, userId, file, activity, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DirectLineApi - object-oriented interface
 * @export
 * @class DirectLineApi
 * @extends {BaseAPI}
 */
export class DirectLineApi extends BaseAPI {
    /**
     * 
     * @summary Создать беседу.
     * @param {string} agentStageId ИД стадии бота.
     * @param {string} [userId] 
     * @param {string} [userName] 
     * @param {string} [eTag] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DirectLineApi
     */
    public createDlConversation(agentStageId: string, userId?: string, userName?: string, eTag?: string, options?: RawAxiosRequestConfig) {
        return DirectLineApiFp(this.configuration).createDlConversation(agentStageId, userId, userName, eTag, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Создать беседу.
     * @param {string} agentStageId ИД стадии бота.
     * @param {string} [userId] 
     * @param {string} [userName] 
     * @param {string} [eTag] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DirectLineApi
     */
    public createTestingDlConversation(agentStageId: string, userId?: string, userName?: string, eTag?: string, options?: RawAxiosRequestConfig) {
        return DirectLineApiFp(this.configuration).createTestingDlConversation(agentStageId, userId, userName, eTag, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Получить действия беседы.
     * @param {string} agentStageId ИД стадии бота.
     * @param {string} conversationId ИД беседы.
     * @param {string} [watermark] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DirectLineApi
     */
    public getDlActivities(agentStageId: string, conversationId: string, watermark?: string, options?: RawAxiosRequestConfig) {
        return DirectLineApiFp(this.configuration).getDlActivities(agentStageId, conversationId, watermark, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Получить сведения о беседе.
     * @param {string} agentStageId ИД стадии бота.
     * @param {string} conversationId ИД беседы.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DirectLineApi
     */
    public getDlConversation(agentStageId: string, conversationId: string, options?: RawAxiosRequestConfig) {
        return DirectLineApiFp(this.configuration).getDlConversation(agentStageId, conversationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Получить действия беседы.
     * @param {string} agentStageId ИД стадии бота.
     * @param {string} conversationId ИД беседы.
     * @param {string} [watermark] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DirectLineApi
     */
    public getTestingDlActivities(agentStageId: string, conversationId: string, watermark?: string, options?: RawAxiosRequestConfig) {
        return DirectLineApiFp(this.configuration).getTestingDlActivities(agentStageId, conversationId, watermark, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Получить сведения о беседе.
     * @param {string} agentStageId ИД стадии бота.
     * @param {string} conversationId ИД беседы.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DirectLineApi
     */
    public getTestingDlConversation(agentStageId: string, conversationId: string, options?: RawAxiosRequestConfig) {
        return DirectLineApiFp(this.configuration).getTestingDlConversation(agentStageId, conversationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Обновить токен.
     * @param {string} agentStageId ИД стадии бота.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DirectLineApi
     */
    public refreshDlToken(agentStageId: string, options?: RawAxiosRequestConfig) {
        return DirectLineApiFp(this.configuration).refreshDlToken(agentStageId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Обновить токен.
     * @param {string} agentStageId ИД стадии бота.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DirectLineApi
     */
    public refreshTestingDlToken(agentStageId: string, options?: RawAxiosRequestConfig) {
        return DirectLineApiFp(this.configuration).refreshTestingDlToken(agentStageId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Сохранить действие в беседу (для ответов от бота).
     * @param {string} agentStageId ИД стадии бота.
     * @param {string} conversationId ИД беседы.
     * @param {Activity} [activity] Запрос на сохранение действия.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DirectLineApi
     */
    public saveDlBotActivity(agentStageId: string, conversationId: string, activity?: Activity, options?: RawAxiosRequestConfig) {
        return DirectLineApiFp(this.configuration).saveDlBotActivity(agentStageId, conversationId, activity, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Сохранить действие в беседу (для ответов от бота).
     * @param {string} agentStageId ИД стадии бота.
     * @param {string} conversationId ИД беседы.
     * @param {string} replyToId ИД действия, на который пришел ответ.
     * @param {Activity} [activity] Запрос на сохранение действия.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DirectLineApi
     */
    public saveDlBotActivityWithReply(agentStageId: string, conversationId: string, replyToId: string, activity?: Activity, options?: RawAxiosRequestConfig) {
        return DirectLineApiFp(this.configuration).saveDlBotActivityWithReply(agentStageId, conversationId, replyToId, activity, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Сохранить действие в беседу (для ответов от бота).
     * @param {string} agentStageId ИД стадии бота.
     * @param {string} conversationId ИД беседы.
     * @param {Activity} [activity] Запрос на сохранение действия.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DirectLineApi
     */
    public saveTestingDlBotActivity(agentStageId: string, conversationId: string, activity?: Activity, options?: RawAxiosRequestConfig) {
        return DirectLineApiFp(this.configuration).saveTestingDlBotActivity(agentStageId, conversationId, activity, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Сохранить действие в беседу (для ответов от бота).
     * @param {string} agentStageId ИД стадии бота.
     * @param {string} conversationId ИД беседы.
     * @param {string} replyToId ИД действия, на который пришел ответ.
     * @param {Activity} [activity] Запрос на сохранение действия.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DirectLineApi
     */
    public saveTestingDlBotActivityWithReply(agentStageId: string, conversationId: string, replyToId: string, activity?: Activity, options?: RawAxiosRequestConfig) {
        return DirectLineApiFp(this.configuration).saveTestingDlBotActivityWithReply(agentStageId, conversationId, replyToId, activity, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Отправить действие в беседу (для запросов от пользователя).
     * @param {string} agentStageId ИД стадии бота.
     * @param {string} conversationId ИД беседы.
     * @param {Activity} [activity] Запрос на отправку действия в беседу.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DirectLineApi
     */
    public sendDlUserActivity(agentStageId: string, conversationId: string, activity?: Activity, options?: RawAxiosRequestConfig) {
        return DirectLineApiFp(this.configuration).sendDlUserActivity(agentStageId, conversationId, activity, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Отправить действие в беседу (для запросов от пользователя).
     * @param {string} agentStageId ИД стадии бота.
     * @param {string} conversationId ИД беседы.
     * @param {Activity} [activity] Запрос на отправку действия в беседу.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DirectLineApi
     */
    public sendTestingDlUserActivity(agentStageId: string, conversationId: string, activity?: Activity, options?: RawAxiosRequestConfig) {
        return DirectLineApiFp(this.configuration).sendTestingDlUserActivity(agentStageId, conversationId, activity, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Отправить файл в беседу.
     * @param {string} agentStageId ИД стадии бота.
     * @param {string} conversationId ИД беседы.
     * @param {string} [userId] ИД пользователя.
     * @param {Array<File>} [file] 
     * @param {File} [activity] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DirectLineApi
     */
    public uploadDlFile(agentStageId: string, conversationId: string, userId?: string, file?: Array<File>, activity?: File, options?: RawAxiosRequestConfig) {
        return DirectLineApiFp(this.configuration).uploadDlFile(agentStageId, conversationId, userId, file, activity, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Отправить файл в беседу.
     * @param {string} agentStageId ИД стадии бота.
     * @param {string} conversationId ИД беседы.
     * @param {string} [userId] ИД пользователя.
     * @param {Array<File>} [file] 
     * @param {File} [activity] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DirectLineApi
     */
    public uploadTestingDlFile(agentStageId: string, conversationId: string, userId?: string, file?: Array<File>, activity?: File, options?: RawAxiosRequestConfig) {
        return DirectLineApiFp(this.configuration).uploadTestingDlFile(agentStageId, conversationId, userId, file, activity, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * DirectLineStreamApi - axios parameter creator
 * @export
 */
export const DirectLineStreamApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Выполнить потоковую передачу (подключить WebSockets).
         * @param {string} agentStageId ИД стадии бота.
         * @param {string} conversationId ИД беседы.
         * @param {string} [watermark] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        streamDlConversation: async (agentStageId: string, conversationId: string, watermark?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'agentStageId' is not null or undefined
            assertParamExists('streamDlConversation', 'agentStageId', agentStageId)
            // verify required parameter 'conversationId' is not null or undefined
            assertParamExists('streamDlConversation', 'conversationId', conversationId)
            const localVarPath = `/api/v1/directline/streams/{agentStageId}/conversations/{conversationId}`
                .replace(`{${"agentStageId"}}`, encodeURIComponent(String(agentStageId)))
                .replace(`{${"conversationId"}}`, encodeURIComponent(String(conversationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (watermark !== undefined) {
                localVarQueryParameter['Watermark'] = watermark;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Выполнить потоковую передачу (подключить WebSockets).
         * @param {string} agentStageId ИД стадии бота.
         * @param {string} conversationId ИД беседы.
         * @param {string} [watermark] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        streamTestingDlConversation: async (agentStageId: string, conversationId: string, watermark?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'agentStageId' is not null or undefined
            assertParamExists('streamTestingDlConversation', 'agentStageId', agentStageId)
            // verify required parameter 'conversationId' is not null or undefined
            assertParamExists('streamTestingDlConversation', 'conversationId', conversationId)
            const localVarPath = `/api/v1/directline/streams/{agentStageId}/testing/conversations/{conversationId}`
                .replace(`{${"agentStageId"}}`, encodeURIComponent(String(agentStageId)))
                .replace(`{${"conversationId"}}`, encodeURIComponent(String(conversationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (watermark !== undefined) {
                localVarQueryParameter['Watermark'] = watermark;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DirectLineStreamApi - functional programming interface
 * @export
 */
export const DirectLineStreamApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DirectLineStreamApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Выполнить потоковую передачу (подключить WebSockets).
         * @param {string} agentStageId ИД стадии бота.
         * @param {string} conversationId ИД беседы.
         * @param {string} [watermark] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async streamDlConversation(agentStageId: string, conversationId: string, watermark?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.streamDlConversation(agentStageId, conversationId, watermark, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DirectLineStreamApi.streamDlConversation']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Выполнить потоковую передачу (подключить WebSockets).
         * @param {string} agentStageId ИД стадии бота.
         * @param {string} conversationId ИД беседы.
         * @param {string} [watermark] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async streamTestingDlConversation(agentStageId: string, conversationId: string, watermark?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.streamTestingDlConversation(agentStageId, conversationId, watermark, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DirectLineStreamApi.streamTestingDlConversation']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
    }
};

/**
 * DirectLineStreamApi - factory interface
 * @export
 */
export const DirectLineStreamApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DirectLineStreamApiFp(configuration)
    return {
        /**
         * 
         * @summary Выполнить потоковую передачу (подключить WebSockets).
         * @param {string} agentStageId ИД стадии бота.
         * @param {string} conversationId ИД беседы.
         * @param {string} [watermark] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        streamDlConversation(agentStageId: string, conversationId: string, watermark?: string, options?: any): AxiosPromise<void> {
            return localVarFp.streamDlConversation(agentStageId, conversationId, watermark, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Выполнить потоковую передачу (подключить WebSockets).
         * @param {string} agentStageId ИД стадии бота.
         * @param {string} conversationId ИД беседы.
         * @param {string} [watermark] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        streamTestingDlConversation(agentStageId: string, conversationId: string, watermark?: string, options?: any): AxiosPromise<void> {
            return localVarFp.streamTestingDlConversation(agentStageId, conversationId, watermark, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DirectLineStreamApi - object-oriented interface
 * @export
 * @class DirectLineStreamApi
 * @extends {BaseAPI}
 */
export class DirectLineStreamApi extends BaseAPI {
    /**
     * 
     * @summary Выполнить потоковую передачу (подключить WebSockets).
     * @param {string} agentStageId ИД стадии бота.
     * @param {string} conversationId ИД беседы.
     * @param {string} [watermark] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DirectLineStreamApi
     */
    public streamDlConversation(agentStageId: string, conversationId: string, watermark?: string, options?: RawAxiosRequestConfig) {
        return DirectLineStreamApiFp(this.configuration).streamDlConversation(agentStageId, conversationId, watermark, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Выполнить потоковую передачу (подключить WebSockets).
     * @param {string} agentStageId ИД стадии бота.
     * @param {string} conversationId ИД беседы.
     * @param {string} [watermark] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DirectLineStreamApi
     */
    public streamTestingDlConversation(agentStageId: string, conversationId: string, watermark?: string, options?: RawAxiosRequestConfig) {
        return DirectLineStreamApiFp(this.configuration).streamTestingDlConversation(agentStageId, conversationId, watermark, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * DirectLineTokenApi - axios parameter creator
 * @export
 */
export const DirectLineTokenApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Сгенерировать токен.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generateDlToken: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/directline/tokens`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth20 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth20", ["elma.assistant.resources.identity.context", "elma.assistant.scopes.api.admin", "email", "offline_access", "openid", "phone", "profile"], configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DirectLineTokenApi - functional programming interface
 * @export
 */
export const DirectLineTokenApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DirectLineTokenApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Сгенерировать токен.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async generateDlToken(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DirectLineTokenModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.generateDlToken(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DirectLineTokenApi.generateDlToken']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
    }
};

/**
 * DirectLineTokenApi - factory interface
 * @export
 */
export const DirectLineTokenApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DirectLineTokenApiFp(configuration)
    return {
        /**
         * 
         * @summary Сгенерировать токен.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generateDlToken(options?: any): AxiosPromise<DirectLineTokenModel> {
            return localVarFp.generateDlToken(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DirectLineTokenApi - object-oriented interface
 * @export
 * @class DirectLineTokenApi
 * @extends {BaseAPI}
 */
export class DirectLineTokenApi extends BaseAPI {
    /**
     * 
     * @summary Сгенерировать токен.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DirectLineTokenApi
     */
    public generateDlToken(options?: RawAxiosRequestConfig) {
        return DirectLineTokenApiFp(this.configuration).generateDlToken(options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * DirectLineTranscriptApi - axios parameter creator
 * @export
 */
export const DirectLineTranscriptApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Создать беседу.
         * @param {string} conversationId ИД беседы.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createDlExportConversation: async (conversationId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'conversationId' is not null or undefined
            assertParamExists('createDlExportConversation', 'conversationId', conversationId)
            const localVarPath = `/api/v1/runtime/dl-export/{conversationId}/conversations`
                .replace(`{${"conversationId"}}`, encodeURIComponent(String(conversationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth20 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth20", ["elma.assistant.resources.identity.context", "elma.assistant.scopes.api.admin", "email", "offline_access", "openid", "phone", "profile"], configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Сгенерировать токен.
         * @param {string} conversationId ИД беседы.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generateDlExportToken: async (conversationId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'conversationId' is not null or undefined
            assertParamExists('generateDlExportToken', 'conversationId', conversationId)
            const localVarPath = `/api/v1/runtime/dl-export/{conversationId}/tokens/generate`
                .replace(`{${"conversationId"}}`, encodeURIComponent(String(conversationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth20 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth20", ["elma.assistant.resources.identity.context", "elma.assistant.scopes.api.admin", "email", "offline_access", "openid", "phone", "profile"], configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Получить действия беседы.
         * @param {string} conversationId ИД беседы.
         * @param {string} unusedConversationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDlExportActivities: async (conversationId: string, unusedConversationId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'conversationId' is not null or undefined
            assertParamExists('getDlExportActivities', 'conversationId', conversationId)
            // verify required parameter 'unusedConversationId' is not null or undefined
            assertParamExists('getDlExportActivities', 'unusedConversationId', unusedConversationId)
            const localVarPath = `/api/v1/runtime/dl-export/{conversationId}/conversations/{unusedConversationId}/activities`
                .replace(`{${"conversationId"}}`, encodeURIComponent(String(conversationId)))
                .replace(`{${"unusedConversationId"}}`, encodeURIComponent(String(unusedConversationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth20 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth20", ["elma.assistant.resources.identity.context", "elma.assistant.scopes.api.admin", "email", "offline_access", "openid", "phone", "profile"], configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Получить сведения о беседе.
         * @param {string} conversationId ИД беседы.
         * @param {string} unusedConversationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDlExportConversation: async (conversationId: string, unusedConversationId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'conversationId' is not null or undefined
            assertParamExists('getDlExportConversation', 'conversationId', conversationId)
            // verify required parameter 'unusedConversationId' is not null or undefined
            assertParamExists('getDlExportConversation', 'unusedConversationId', unusedConversationId)
            const localVarPath = `/api/v1/runtime/dl-export/{conversationId}/conversations/{unusedConversationId}`
                .replace(`{${"conversationId"}}`, encodeURIComponent(String(conversationId)))
                .replace(`{${"unusedConversationId"}}`, encodeURIComponent(String(unusedConversationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth20 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth20", ["elma.assistant.resources.identity.context", "elma.assistant.scopes.api.admin", "email", "offline_access", "openid", "phone", "profile"], configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Получить ИД пользователя беседы.
         * @param {string} conversationId ИД беседы.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDlExportUserId: async (conversationId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'conversationId' is not null or undefined
            assertParamExists('getDlExportUserId', 'conversationId', conversationId)
            const localVarPath = `/api/v1/runtime/dl-export/{conversationId}/user-id`
                .replace(`{${"conversationId"}}`, encodeURIComponent(String(conversationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth20 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth20", ["elma.assistant.resources.identity.context", "elma.assistant.scopes.api.admin", "email", "offline_access", "openid", "phone", "profile"], configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Обновить токен.
         * @param {string} conversationId ИД беседы.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        refreshDlExportToken: async (conversationId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'conversationId' is not null or undefined
            assertParamExists('refreshDlExportToken', 'conversationId', conversationId)
            const localVarPath = `/api/v1/runtime/dl-export/{conversationId}/tokens/refresh`
                .replace(`{${"conversationId"}}`, encodeURIComponent(String(conversationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth20 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth20", ["elma.assistant.resources.identity.context", "elma.assistant.scopes.api.admin", "email", "offline_access", "openid", "phone", "profile"], configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DirectLineTranscriptApi - functional programming interface
 * @export
 */
export const DirectLineTranscriptApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DirectLineTranscriptApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Создать беседу.
         * @param {string} conversationId ИД беседы.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createDlExportConversation(conversationId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Conversation>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createDlExportConversation(conversationId, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DirectLineTranscriptApi.createDlExportConversation']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Сгенерировать токен.
         * @param {string} conversationId ИД беседы.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async generateDlExportToken(conversationId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Conversation>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.generateDlExportToken(conversationId, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DirectLineTranscriptApi.generateDlExportToken']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Получить действия беседы.
         * @param {string} conversationId ИД беседы.
         * @param {string} unusedConversationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDlExportActivities(conversationId: string, unusedConversationId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ActivitySet>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDlExportActivities(conversationId, unusedConversationId, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DirectLineTranscriptApi.getDlExportActivities']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Получить сведения о беседе.
         * @param {string} conversationId ИД беседы.
         * @param {string} unusedConversationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDlExportConversation(conversationId: string, unusedConversationId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Conversation>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDlExportConversation(conversationId, unusedConversationId, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DirectLineTranscriptApi.getDlExportConversation']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Получить ИД пользователя беседы.
         * @param {string} conversationId ИД беседы.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDlExportUserId(conversationId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDlExportUserId(conversationId, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DirectLineTranscriptApi.getDlExportUserId']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Обновить токен.
         * @param {string} conversationId ИД беседы.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async refreshDlExportToken(conversationId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Conversation>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.refreshDlExportToken(conversationId, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DirectLineTranscriptApi.refreshDlExportToken']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
    }
};

/**
 * DirectLineTranscriptApi - factory interface
 * @export
 */
export const DirectLineTranscriptApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DirectLineTranscriptApiFp(configuration)
    return {
        /**
         * 
         * @summary Создать беседу.
         * @param {string} conversationId ИД беседы.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createDlExportConversation(conversationId: string, options?: any): AxiosPromise<Conversation> {
            return localVarFp.createDlExportConversation(conversationId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Сгенерировать токен.
         * @param {string} conversationId ИД беседы.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generateDlExportToken(conversationId: string, options?: any): AxiosPromise<Conversation> {
            return localVarFp.generateDlExportToken(conversationId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Получить действия беседы.
         * @param {string} conversationId ИД беседы.
         * @param {string} unusedConversationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDlExportActivities(conversationId: string, unusedConversationId: string, options?: any): AxiosPromise<ActivitySet> {
            return localVarFp.getDlExportActivities(conversationId, unusedConversationId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Получить сведения о беседе.
         * @param {string} conversationId ИД беседы.
         * @param {string} unusedConversationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDlExportConversation(conversationId: string, unusedConversationId: string, options?: any): AxiosPromise<Conversation> {
            return localVarFp.getDlExportConversation(conversationId, unusedConversationId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Получить ИД пользователя беседы.
         * @param {string} conversationId ИД беседы.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDlExportUserId(conversationId: string, options?: any): AxiosPromise<string> {
            return localVarFp.getDlExportUserId(conversationId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Обновить токен.
         * @param {string} conversationId ИД беседы.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        refreshDlExportToken(conversationId: string, options?: any): AxiosPromise<Conversation> {
            return localVarFp.refreshDlExportToken(conversationId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DirectLineTranscriptApi - object-oriented interface
 * @export
 * @class DirectLineTranscriptApi
 * @extends {BaseAPI}
 */
export class DirectLineTranscriptApi extends BaseAPI {
    /**
     * 
     * @summary Создать беседу.
     * @param {string} conversationId ИД беседы.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DirectLineTranscriptApi
     */
    public createDlExportConversation(conversationId: string, options?: RawAxiosRequestConfig) {
        return DirectLineTranscriptApiFp(this.configuration).createDlExportConversation(conversationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Сгенерировать токен.
     * @param {string} conversationId ИД беседы.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DirectLineTranscriptApi
     */
    public generateDlExportToken(conversationId: string, options?: RawAxiosRequestConfig) {
        return DirectLineTranscriptApiFp(this.configuration).generateDlExportToken(conversationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Получить действия беседы.
     * @param {string} conversationId ИД беседы.
     * @param {string} unusedConversationId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DirectLineTranscriptApi
     */
    public getDlExportActivities(conversationId: string, unusedConversationId: string, options?: RawAxiosRequestConfig) {
        return DirectLineTranscriptApiFp(this.configuration).getDlExportActivities(conversationId, unusedConversationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Получить сведения о беседе.
     * @param {string} conversationId ИД беседы.
     * @param {string} unusedConversationId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DirectLineTranscriptApi
     */
    public getDlExportConversation(conversationId: string, unusedConversationId: string, options?: RawAxiosRequestConfig) {
        return DirectLineTranscriptApiFp(this.configuration).getDlExportConversation(conversationId, unusedConversationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Получить ИД пользователя беседы.
     * @param {string} conversationId ИД беседы.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DirectLineTranscriptApi
     */
    public getDlExportUserId(conversationId: string, options?: RawAxiosRequestConfig) {
        return DirectLineTranscriptApiFp(this.configuration).getDlExportUserId(conversationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Обновить токен.
     * @param {string} conversationId ИД беседы.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DirectLineTranscriptApi
     */
    public refreshDlExportToken(conversationId: string, options?: RawAxiosRequestConfig) {
        return DirectLineTranscriptApiFp(this.configuration).refreshDlExportToken(conversationId, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * Elma365AppApi - axios parameter creator
 * @export
 */
export const Elma365AppApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Получить приложение ELMA365.
         * @param {string} appNamespace Код раздела.
         * @param {string} appCode Код.
         * @param {string} baseUrl Адрес компании в ELMA365.
         * @param {string} xToken X-Token пользователя.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getElma365App: async (appNamespace: string, appCode: string, baseUrl: string, xToken: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'appNamespace' is not null or undefined
            assertParamExists('getElma365App', 'appNamespace', appNamespace)
            // verify required parameter 'appCode' is not null or undefined
            assertParamExists('getElma365App', 'appCode', appCode)
            // verify required parameter 'baseUrl' is not null or undefined
            assertParamExists('getElma365App', 'baseUrl', baseUrl)
            // verify required parameter 'xToken' is not null or undefined
            assertParamExists('getElma365App', 'xToken', xToken)
            const localVarPath = `/api/v1/clients/elma365/app/get`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth20 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth20", ["elma.assistant.resources.identity.context", "elma.assistant.scopes.api.admin", "email", "offline_access", "openid", "phone", "profile"], configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (appNamespace !== undefined) {
                localVarQueryParameter['AppNamespace'] = appNamespace;
            }

            if (appCode !== undefined) {
                localVarQueryParameter['AppCode'] = appCode;
            }

            if (baseUrl !== undefined) {
                localVarQueryParameter['BaseUrl'] = baseUrl;
            }

            if (xToken !== undefined) {
                localVarQueryParameter['XToken'] = xToken;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Поиск приложений ELMA365.
         * @param {string} baseUrl Адрес компании в ELMA365.
         * @param {string} xToken X-Token пользователя.
         * @param {string} [searchText] Строка поиска.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchElma365Apps: async (baseUrl: string, xToken: string, searchText?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'baseUrl' is not null or undefined
            assertParamExists('searchElma365Apps', 'baseUrl', baseUrl)
            // verify required parameter 'xToken' is not null or undefined
            assertParamExists('searchElma365Apps', 'xToken', xToken)
            const localVarPath = `/api/v1/clients/elma365/app/search`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth20 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth20", ["elma.assistant.resources.identity.context", "elma.assistant.scopes.api.admin", "email", "offline_access", "openid", "phone", "profile"], configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (searchText !== undefined) {
                localVarQueryParameter['SearchText'] = searchText;
            }

            if (baseUrl !== undefined) {
                localVarQueryParameter['BaseUrl'] = baseUrl;
            }

            if (xToken !== undefined) {
                localVarQueryParameter['XToken'] = xToken;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * Elma365AppApi - functional programming interface
 * @export
 */
export const Elma365AppApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = Elma365AppApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Получить приложение ELMA365.
         * @param {string} appNamespace Код раздела.
         * @param {string} appCode Код.
         * @param {string} baseUrl Адрес компании в ELMA365.
         * @param {string} xToken X-Token пользователя.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getElma365App(appNamespace: string, appCode: string, baseUrl: string, xToken: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Elma365App>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getElma365App(appNamespace, appCode, baseUrl, xToken, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['Elma365AppApi.getElma365App']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Поиск приложений ELMA365.
         * @param {string} baseUrl Адрес компании в ELMA365.
         * @param {string} xToken X-Token пользователя.
         * @param {string} [searchText] Строка поиска.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async searchElma365Apps(baseUrl: string, xToken: string, searchText?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Elma365App>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.searchElma365Apps(baseUrl, xToken, searchText, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['Elma365AppApi.searchElma365Apps']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
    }
};

/**
 * Elma365AppApi - factory interface
 * @export
 */
export const Elma365AppApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = Elma365AppApiFp(configuration)
    return {
        /**
         * 
         * @summary Получить приложение ELMA365.
         * @param {string} appNamespace Код раздела.
         * @param {string} appCode Код.
         * @param {string} baseUrl Адрес компании в ELMA365.
         * @param {string} xToken X-Token пользователя.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getElma365App(appNamespace: string, appCode: string, baseUrl: string, xToken: string, options?: any): AxiosPromise<Elma365App> {
            return localVarFp.getElma365App(appNamespace, appCode, baseUrl, xToken, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Поиск приложений ELMA365.
         * @param {string} baseUrl Адрес компании в ELMA365.
         * @param {string} xToken X-Token пользователя.
         * @param {string} [searchText] Строка поиска.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchElma365Apps(baseUrl: string, xToken: string, searchText?: string, options?: any): AxiosPromise<Array<Elma365App>> {
            return localVarFp.searchElma365Apps(baseUrl, xToken, searchText, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Elma365AppApi - object-oriented interface
 * @export
 * @class Elma365AppApi
 * @extends {BaseAPI}
 */
export class Elma365AppApi extends BaseAPI {
    /**
     * 
     * @summary Получить приложение ELMA365.
     * @param {string} appNamespace Код раздела.
     * @param {string} appCode Код.
     * @param {string} baseUrl Адрес компании в ELMA365.
     * @param {string} xToken X-Token пользователя.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof Elma365AppApi
     */
    public getElma365App(appNamespace: string, appCode: string, baseUrl: string, xToken: string, options?: RawAxiosRequestConfig) {
        return Elma365AppApiFp(this.configuration).getElma365App(appNamespace, appCode, baseUrl, xToken, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Поиск приложений ELMA365.
     * @param {string} baseUrl Адрес компании в ELMA365.
     * @param {string} xToken X-Token пользователя.
     * @param {string} [searchText] Строка поиска.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof Elma365AppApi
     */
    public searchElma365Apps(baseUrl: string, xToken: string, searchText?: string, options?: RawAxiosRequestConfig) {
        return Elma365AppApiFp(this.configuration).searchElma365Apps(baseUrl, xToken, searchText, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * Elma365BpmTemplateApi - axios parameter creator
 * @export
 */
export const Elma365BpmTemplateApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Получить список шаблонов бизнес-процессов ELMA365.
         * @param {string} baseUrl Адрес компании в ELMA365.
         * @param {string} xToken X-Token пользователя.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getElma365BpmTemplateList: async (baseUrl: string, xToken: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'baseUrl' is not null or undefined
            assertParamExists('getElma365BpmTemplateList', 'baseUrl', baseUrl)
            // verify required parameter 'xToken' is not null or undefined
            assertParamExists('getElma365BpmTemplateList', 'xToken', xToken)
            const localVarPath = `/api/v1/clients/elma365/bpm/template/list`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth20 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth20", ["elma.assistant.resources.identity.context", "elma.assistant.scopes.api.admin", "email", "offline_access", "openid", "phone", "profile"], configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (baseUrl !== undefined) {
                localVarQueryParameter['BaseUrl'] = baseUrl;
            }

            if (xToken !== undefined) {
                localVarQueryParameter['XToken'] = xToken;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * Elma365BpmTemplateApi - functional programming interface
 * @export
 */
export const Elma365BpmTemplateApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = Elma365BpmTemplateApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Получить список шаблонов бизнес-процессов ELMA365.
         * @param {string} baseUrl Адрес компании в ELMA365.
         * @param {string} xToken X-Token пользователя.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getElma365BpmTemplateList(baseUrl: string, xToken: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Elma365BpmTemplate>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getElma365BpmTemplateList(baseUrl, xToken, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['Elma365BpmTemplateApi.getElma365BpmTemplateList']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
    }
};

/**
 * Elma365BpmTemplateApi - factory interface
 * @export
 */
export const Elma365BpmTemplateApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = Elma365BpmTemplateApiFp(configuration)
    return {
        /**
         * 
         * @summary Получить список шаблонов бизнес-процессов ELMA365.
         * @param {string} baseUrl Адрес компании в ELMA365.
         * @param {string} xToken X-Token пользователя.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getElma365BpmTemplateList(baseUrl: string, xToken: string, options?: any): AxiosPromise<Array<Elma365BpmTemplate>> {
            return localVarFp.getElma365BpmTemplateList(baseUrl, xToken, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Elma365BpmTemplateApi - object-oriented interface
 * @export
 * @class Elma365BpmTemplateApi
 * @extends {BaseAPI}
 */
export class Elma365BpmTemplateApi extends BaseAPI {
    /**
     * 
     * @summary Получить список шаблонов бизнес-процессов ELMA365.
     * @param {string} baseUrl Адрес компании в ELMA365.
     * @param {string} xToken X-Token пользователя.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof Elma365BpmTemplateApi
     */
    public getElma365BpmTemplateList(baseUrl: string, xToken: string, options?: RawAxiosRequestConfig) {
        return Elma365BpmTemplateApiFp(this.configuration).getElma365BpmTemplateList(baseUrl, xToken, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * Elma365MetadataApi - axios parameter creator
 * @export
 */
export const Elma365MetadataApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Получить схему приложения ELMA365.
         * @param {string} namespace Код раздела.
         * @param {string} code Код.
         * @param {string} baseUrl Адрес компании в ELMA365.
         * @param {string} xToken X-Token пользователя.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getElma365AppScheme: async (namespace: string, code: string, baseUrl: string, xToken: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'namespace' is not null or undefined
            assertParamExists('getElma365AppScheme', 'namespace', namespace)
            // verify required parameter 'code' is not null or undefined
            assertParamExists('getElma365AppScheme', 'code', code)
            // verify required parameter 'baseUrl' is not null or undefined
            assertParamExists('getElma365AppScheme', 'baseUrl', baseUrl)
            // verify required parameter 'xToken' is not null or undefined
            assertParamExists('getElma365AppScheme', 'xToken', xToken)
            const localVarPath = `/api/v1/clients/elma365/scheme/app`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth20 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth20", ["elma.assistant.resources.identity.context", "elma.assistant.scopes.api.admin", "email", "offline_access", "openid", "phone", "profile"], configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (namespace !== undefined) {
                localVarQueryParameter['Namespace'] = namespace;
            }

            if (code !== undefined) {
                localVarQueryParameter['Code'] = code;
            }

            if (baseUrl !== undefined) {
                localVarQueryParameter['BaseUrl'] = baseUrl;
            }

            if (xToken !== undefined) {
                localVarQueryParameter['XToken'] = xToken;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Получить схему бизнес-процесса ELMA365.
         * @param {string} namespace Код раздела.
         * @param {string} code Код.
         * @param {string} baseUrl Адрес компании в ELMA365.
         * @param {string} xToken X-Token пользователя.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getElma365BpmTemplateScheme: async (namespace: string, code: string, baseUrl: string, xToken: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'namespace' is not null or undefined
            assertParamExists('getElma365BpmTemplateScheme', 'namespace', namespace)
            // verify required parameter 'code' is not null or undefined
            assertParamExists('getElma365BpmTemplateScheme', 'code', code)
            // verify required parameter 'baseUrl' is not null or undefined
            assertParamExists('getElma365BpmTemplateScheme', 'baseUrl', baseUrl)
            // verify required parameter 'xToken' is not null or undefined
            assertParamExists('getElma365BpmTemplateScheme', 'xToken', xToken)
            const localVarPath = `/api/v1/clients/elma365/scheme/process`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth20 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth20", ["elma.assistant.resources.identity.context", "elma.assistant.scopes.api.admin", "email", "offline_access", "openid", "phone", "profile"], configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (namespace !== undefined) {
                localVarQueryParameter['Namespace'] = namespace;
            }

            if (code !== undefined) {
                localVarQueryParameter['Code'] = code;
            }

            if (baseUrl !== undefined) {
                localVarQueryParameter['BaseUrl'] = baseUrl;
            }

            if (xToken !== undefined) {
                localVarQueryParameter['XToken'] = xToken;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Получить схемы бизнес-процессов ELMA365.
         * @param {string} namespace Код раздела.
         * @param {string} baseUrl Адрес компании в ELMA365.
         * @param {string} xToken X-Token пользователя.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getElma365BpmTemplateSchemeList: async (namespace: string, baseUrl: string, xToken: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'namespace' is not null or undefined
            assertParamExists('getElma365BpmTemplateSchemeList', 'namespace', namespace)
            // verify required parameter 'baseUrl' is not null or undefined
            assertParamExists('getElma365BpmTemplateSchemeList', 'baseUrl', baseUrl)
            // verify required parameter 'xToken' is not null or undefined
            assertParamExists('getElma365BpmTemplateSchemeList', 'xToken', xToken)
            const localVarPath = `/api/v1/clients/elma365/scheme/processes`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth20 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth20", ["elma.assistant.resources.identity.context", "elma.assistant.scopes.api.admin", "email", "offline_access", "openid", "phone", "profile"], configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (namespace !== undefined) {
                localVarQueryParameter['Namespace'] = namespace;
            }

            if (baseUrl !== undefined) {
                localVarQueryParameter['BaseUrl'] = baseUrl;
            }

            if (xToken !== undefined) {
                localVarQueryParameter['XToken'] = xToken;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Получить список схем разделов ELMA365.
         * @param {string} baseUrl Адрес компании в ELMA365.
         * @param {string} xToken X-Token пользователя.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getElma365NamespaceSchemeList: async (baseUrl: string, xToken: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'baseUrl' is not null or undefined
            assertParamExists('getElma365NamespaceSchemeList', 'baseUrl', baseUrl)
            // verify required parameter 'xToken' is not null or undefined
            assertParamExists('getElma365NamespaceSchemeList', 'xToken', xToken)
            const localVarPath = `/api/v1/clients/elma365/scheme/namespaces`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth20 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth20", ["elma.assistant.resources.identity.context", "elma.assistant.scopes.api.admin", "email", "offline_access", "openid", "phone", "profile"], configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (baseUrl !== undefined) {
                localVarQueryParameter['BaseUrl'] = baseUrl;
            }

            if (xToken !== undefined) {
                localVarQueryParameter['XToken'] = xToken;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * Elma365MetadataApi - functional programming interface
 * @export
 */
export const Elma365MetadataApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = Elma365MetadataApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Получить схему приложения ELMA365.
         * @param {string} namespace Код раздела.
         * @param {string} code Код.
         * @param {string} baseUrl Адрес компании в ELMA365.
         * @param {string} xToken X-Token пользователя.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getElma365AppScheme(namespace: string, code: string, baseUrl: string, xToken: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Elma365App>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getElma365AppScheme(namespace, code, baseUrl, xToken, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['Elma365MetadataApi.getElma365AppScheme']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Получить схему бизнес-процесса ELMA365.
         * @param {string} namespace Код раздела.
         * @param {string} code Код.
         * @param {string} baseUrl Адрес компании в ELMA365.
         * @param {string} xToken X-Token пользователя.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getElma365BpmTemplateScheme(namespace: string, code: string, baseUrl: string, xToken: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Elma365BpmTemplate>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getElma365BpmTemplateScheme(namespace, code, baseUrl, xToken, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['Elma365MetadataApi.getElma365BpmTemplateScheme']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Получить схемы бизнес-процессов ELMA365.
         * @param {string} namespace Код раздела.
         * @param {string} baseUrl Адрес компании в ELMA365.
         * @param {string} xToken X-Token пользователя.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getElma365BpmTemplateSchemeList(namespace: string, baseUrl: string, xToken: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Elma365BpmTemplate>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getElma365BpmTemplateSchemeList(namespace, baseUrl, xToken, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['Elma365MetadataApi.getElma365BpmTemplateSchemeList']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Получить список схем разделов ELMA365.
         * @param {string} baseUrl Адрес компании в ELMA365.
         * @param {string} xToken X-Token пользователя.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getElma365NamespaceSchemeList(baseUrl: string, xToken: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Elma365Namespace>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getElma365NamespaceSchemeList(baseUrl, xToken, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['Elma365MetadataApi.getElma365NamespaceSchemeList']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
    }
};

/**
 * Elma365MetadataApi - factory interface
 * @export
 */
export const Elma365MetadataApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = Elma365MetadataApiFp(configuration)
    return {
        /**
         * 
         * @summary Получить схему приложения ELMA365.
         * @param {string} namespace Код раздела.
         * @param {string} code Код.
         * @param {string} baseUrl Адрес компании в ELMA365.
         * @param {string} xToken X-Token пользователя.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getElma365AppScheme(namespace: string, code: string, baseUrl: string, xToken: string, options?: any): AxiosPromise<Elma365App> {
            return localVarFp.getElma365AppScheme(namespace, code, baseUrl, xToken, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Получить схему бизнес-процесса ELMA365.
         * @param {string} namespace Код раздела.
         * @param {string} code Код.
         * @param {string} baseUrl Адрес компании в ELMA365.
         * @param {string} xToken X-Token пользователя.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getElma365BpmTemplateScheme(namespace: string, code: string, baseUrl: string, xToken: string, options?: any): AxiosPromise<Elma365BpmTemplate> {
            return localVarFp.getElma365BpmTemplateScheme(namespace, code, baseUrl, xToken, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Получить схемы бизнес-процессов ELMA365.
         * @param {string} namespace Код раздела.
         * @param {string} baseUrl Адрес компании в ELMA365.
         * @param {string} xToken X-Token пользователя.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getElma365BpmTemplateSchemeList(namespace: string, baseUrl: string, xToken: string, options?: any): AxiosPromise<Array<Elma365BpmTemplate>> {
            return localVarFp.getElma365BpmTemplateSchemeList(namespace, baseUrl, xToken, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Получить список схем разделов ELMA365.
         * @param {string} baseUrl Адрес компании в ELMA365.
         * @param {string} xToken X-Token пользователя.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getElma365NamespaceSchemeList(baseUrl: string, xToken: string, options?: any): AxiosPromise<Array<Elma365Namespace>> {
            return localVarFp.getElma365NamespaceSchemeList(baseUrl, xToken, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Elma365MetadataApi - object-oriented interface
 * @export
 * @class Elma365MetadataApi
 * @extends {BaseAPI}
 */
export class Elma365MetadataApi extends BaseAPI {
    /**
     * 
     * @summary Получить схему приложения ELMA365.
     * @param {string} namespace Код раздела.
     * @param {string} code Код.
     * @param {string} baseUrl Адрес компании в ELMA365.
     * @param {string} xToken X-Token пользователя.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof Elma365MetadataApi
     */
    public getElma365AppScheme(namespace: string, code: string, baseUrl: string, xToken: string, options?: RawAxiosRequestConfig) {
        return Elma365MetadataApiFp(this.configuration).getElma365AppScheme(namespace, code, baseUrl, xToken, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Получить схему бизнес-процесса ELMA365.
     * @param {string} namespace Код раздела.
     * @param {string} code Код.
     * @param {string} baseUrl Адрес компании в ELMA365.
     * @param {string} xToken X-Token пользователя.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof Elma365MetadataApi
     */
    public getElma365BpmTemplateScheme(namespace: string, code: string, baseUrl: string, xToken: string, options?: RawAxiosRequestConfig) {
        return Elma365MetadataApiFp(this.configuration).getElma365BpmTemplateScheme(namespace, code, baseUrl, xToken, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Получить схемы бизнес-процессов ELMA365.
     * @param {string} namespace Код раздела.
     * @param {string} baseUrl Адрес компании в ELMA365.
     * @param {string} xToken X-Token пользователя.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof Elma365MetadataApi
     */
    public getElma365BpmTemplateSchemeList(namespace: string, baseUrl: string, xToken: string, options?: RawAxiosRequestConfig) {
        return Elma365MetadataApiFp(this.configuration).getElma365BpmTemplateSchemeList(namespace, baseUrl, xToken, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Получить список схем разделов ELMA365.
     * @param {string} baseUrl Адрес компании в ELMA365.
     * @param {string} xToken X-Token пользователя.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof Elma365MetadataApi
     */
    public getElma365NamespaceSchemeList(baseUrl: string, xToken: string, options?: RawAxiosRequestConfig) {
        return Elma365MetadataApiFp(this.configuration).getElma365NamespaceSchemeList(baseUrl, xToken, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * EventApi - axios parameter creator
 * @export
 */
export const EventApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Получить событие.
         * @param {string} id ИД события.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEvent: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getEvent', 'id', id)
            const localVarPath = `/api/v1/general/events/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth20 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth20", ["elma.assistant.resources.identity.context", "elma.assistant.scopes.api.admin", "email", "offline_access", "openid", "phone", "profile"], configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Выполнить поиск событий.
         * @param {string} [type] Тип события.
         * @param {number} [pageIndex] 
         * @param {number} [pageSize] 
         * @param {boolean} [withTotal] 
         * @param {number} [fromItemInclusive] 
         * @param {number} [toItemExclusive] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchEvents: async (type?: string, pageIndex?: number, pageSize?: number, withTotal?: boolean, fromItemInclusive?: number, toItemExclusive?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/general/events`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth20 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth20", ["elma.assistant.resources.identity.context", "elma.assistant.scopes.api.admin", "email", "offline_access", "openid", "phone", "profile"], configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (type !== undefined) {
                localVarQueryParameter['Type'] = type;
            }

            if (pageIndex !== undefined) {
                localVarQueryParameter['PageIndex'] = pageIndex;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['PageSize'] = pageSize;
            }

            if (withTotal !== undefined) {
                localVarQueryParameter['WithTotal'] = withTotal;
            }

            if (fromItemInclusive !== undefined) {
                localVarQueryParameter['FromItemInclusive'] = fromItemInclusive;
            }

            if (toItemExclusive !== undefined) {
                localVarQueryParameter['ToItemExclusive'] = toItemExclusive;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * EventApi - functional programming interface
 * @export
 */
export const EventApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = EventApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Получить событие.
         * @param {string} id ИД события.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getEvent(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EventModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getEvent(id, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['EventApi.getEvent']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Выполнить поиск событий.
         * @param {string} [type] Тип события.
         * @param {number} [pageIndex] 
         * @param {number} [pageSize] 
         * @param {boolean} [withTotal] 
         * @param {number} [fromItemInclusive] 
         * @param {number} [toItemExclusive] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async searchEvents(type?: string, pageIndex?: number, pageSize?: number, withTotal?: boolean, fromItemInclusive?: number, toItemExclusive?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EventModelPaginationResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.searchEvents(type, pageIndex, pageSize, withTotal, fromItemInclusive, toItemExclusive, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['EventApi.searchEvents']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
    }
};

/**
 * EventApi - factory interface
 * @export
 */
export const EventApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = EventApiFp(configuration)
    return {
        /**
         * 
         * @summary Получить событие.
         * @param {string} id ИД события.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEvent(id: string, options?: any): AxiosPromise<EventModel> {
            return localVarFp.getEvent(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Выполнить поиск событий.
         * @param {string} [type] Тип события.
         * @param {number} [pageIndex] 
         * @param {number} [pageSize] 
         * @param {boolean} [withTotal] 
         * @param {number} [fromItemInclusive] 
         * @param {number} [toItemExclusive] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchEvents(type?: string, pageIndex?: number, pageSize?: number, withTotal?: boolean, fromItemInclusive?: number, toItemExclusive?: number, options?: any): AxiosPromise<EventModelPaginationResponse> {
            return localVarFp.searchEvents(type, pageIndex, pageSize, withTotal, fromItemInclusive, toItemExclusive, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * EventApi - object-oriented interface
 * @export
 * @class EventApi
 * @extends {BaseAPI}
 */
export class EventApi extends BaseAPI {
    /**
     * 
     * @summary Получить событие.
     * @param {string} id ИД события.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EventApi
     */
    public getEvent(id: string, options?: RawAxiosRequestConfig) {
        return EventApiFp(this.configuration).getEvent(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Выполнить поиск событий.
     * @param {string} [type] Тип события.
     * @param {number} [pageIndex] 
     * @param {number} [pageSize] 
     * @param {boolean} [withTotal] 
     * @param {number} [fromItemInclusive] 
     * @param {number} [toItemExclusive] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EventApi
     */
    public searchEvents(type?: string, pageIndex?: number, pageSize?: number, withTotal?: boolean, fromItemInclusive?: number, toItemExclusive?: number, options?: RawAxiosRequestConfig) {
        return EventApiFp(this.configuration).searchEvents(type, pageIndex, pageSize, withTotal, fromItemInclusive, toItemExclusive, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * ExternalEventTriggerApi - axios parameter creator
 * @export
 */
export const ExternalEventTriggerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Запустить внешнее событие.
         * @param {DispatchExternalEventRequest} [dispatchExternalEventRequest] Параметры запроса.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dispatchExternalEvent: async (dispatchExternalEventRequest?: DispatchExternalEventRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/runtime/simple/external/events`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth20 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth20", ["elma.assistant.resources.identity.context", "elma.assistant.scopes.api.admin", "email", "offline_access", "openid", "phone", "profile"], configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(dispatchExternalEventRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Получить список доступных триггеров внешнего события.
         * @param {string} agentStageId ИД стадии бота.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBotExternalEventTriggers: async (agentStageId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'agentStageId' is not null or undefined
            assertParamExists('getBotExternalEventTriggers', 'agentStageId', agentStageId)
            const localVarPath = `/api/v1/runtime/simple/external/events/{agentStageId}`
                .replace(`{${"agentStageId"}}`, encodeURIComponent(String(agentStageId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth20 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth20", ["elma.assistant.resources.identity.context", "elma.assistant.scopes.api.admin", "email", "offline_access", "openid", "phone", "profile"], configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ExternalEventTriggerApi - functional programming interface
 * @export
 */
export const ExternalEventTriggerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ExternalEventTriggerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Запустить внешнее событие.
         * @param {DispatchExternalEventRequest} [dispatchExternalEventRequest] Параметры запроса.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async dispatchExternalEvent(dispatchExternalEventRequest?: DispatchExternalEventRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.dispatchExternalEvent(dispatchExternalEventRequest, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['ExternalEventTriggerApi.dispatchExternalEvent']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Получить список доступных триггеров внешнего события.
         * @param {string} agentStageId ИД стадии бота.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getBotExternalEventTriggers(agentStageId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<BotExternalEventTrigger>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getBotExternalEventTriggers(agentStageId, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['ExternalEventTriggerApi.getBotExternalEventTriggers']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
    }
};

/**
 * ExternalEventTriggerApi - factory interface
 * @export
 */
export const ExternalEventTriggerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ExternalEventTriggerApiFp(configuration)
    return {
        /**
         * 
         * @summary Запустить внешнее событие.
         * @param {DispatchExternalEventRequest} [dispatchExternalEventRequest] Параметры запроса.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dispatchExternalEvent(dispatchExternalEventRequest?: DispatchExternalEventRequest, options?: any): AxiosPromise<object> {
            return localVarFp.dispatchExternalEvent(dispatchExternalEventRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Получить список доступных триггеров внешнего события.
         * @param {string} agentStageId ИД стадии бота.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBotExternalEventTriggers(agentStageId: string, options?: any): AxiosPromise<Array<BotExternalEventTrigger>> {
            return localVarFp.getBotExternalEventTriggers(agentStageId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ExternalEventTriggerApi - object-oriented interface
 * @export
 * @class ExternalEventTriggerApi
 * @extends {BaseAPI}
 */
export class ExternalEventTriggerApi extends BaseAPI {
    /**
     * 
     * @summary Запустить внешнее событие.
     * @param {DispatchExternalEventRequest} [dispatchExternalEventRequest] Параметры запроса.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExternalEventTriggerApi
     */
    public dispatchExternalEvent(dispatchExternalEventRequest?: DispatchExternalEventRequest, options?: RawAxiosRequestConfig) {
        return ExternalEventTriggerApiFp(this.configuration).dispatchExternalEvent(dispatchExternalEventRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Получить список доступных триггеров внешнего события.
     * @param {string} agentStageId ИД стадии бота.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExternalEventTriggerApi
     */
    public getBotExternalEventTriggers(agentStageId: string, options?: RawAxiosRequestConfig) {
        return ExternalEventTriggerApiFp(this.configuration).getBotExternalEventTriggers(agentStageId, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * FeatureManagementApi - axios parameter creator
 * @export
 */
export const FeatureManagementApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Получить список доступного функционала.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFeatureFlags: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/feature-management`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth20 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth20", ["elma.assistant.resources.identity.context", "elma.assistant.scopes.api.admin", "email", "offline_access", "openid", "phone", "profile"], configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * FeatureManagementApi - functional programming interface
 * @export
 */
export const FeatureManagementApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = FeatureManagementApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Получить список доступного функционала.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getFeatureFlags(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<FeatureFlag>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getFeatureFlags(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['FeatureManagementApi.getFeatureFlags']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
    }
};

/**
 * FeatureManagementApi - factory interface
 * @export
 */
export const FeatureManagementApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = FeatureManagementApiFp(configuration)
    return {
        /**
         * 
         * @summary Получить список доступного функционала.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFeatureFlags(options?: any): AxiosPromise<Array<FeatureFlag>> {
            return localVarFp.getFeatureFlags(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * FeatureManagementApi - object-oriented interface
 * @export
 * @class FeatureManagementApi
 * @extends {BaseAPI}
 */
export class FeatureManagementApi extends BaseAPI {
    /**
     * 
     * @summary Получить список доступного функционала.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FeatureManagementApi
     */
    public getFeatureFlags(options?: RawAxiosRequestConfig) {
        return FeatureManagementApiFp(this.configuration).getFeatureFlags(options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * FileStorageApi - axios parameter creator
 * @export
 */
export const FileStorageApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Получить файл отчета аналитики.
         * @param {string} tenantId ИД тенанта.
         * @param {string} id ИД файла.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAnalyticsReportFile: async (tenantId: string, id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('getAnalyticsReportFile', 'tenantId', tenantId)
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getAnalyticsReportFile', 'id', id)
            const localVarPath = `/api/v1/clients/file-storage/analytics-report/{tenantId}/{id}`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Получить файл отчета аналитики.
         * @param {string} tenantId ИД тенанта.
         * @param {string} id ИД файла.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAnalyticsReportFileHeaders: async (tenantId: string, id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('getAnalyticsReportFileHeaders', 'tenantId', tenantId)
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getAnalyticsReportFileHeaders', 'id', id)
            const localVarPath = `/api/v1/clients/file-storage/analytics-report/{tenantId}/{id}`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'HEAD', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Получить файл аватара.
         * @param {string} tenantId ИД тенанта.
         * @param {string} id ИД файла аватара.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAvatarFile: async (tenantId: string, id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('getAvatarFile', 'tenantId', tenantId)
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getAvatarFile', 'id', id)
            const localVarPath = `/api/v1/clients/file-storage/avatar/{tenantId}/{id}`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Получить файл аватара.
         * @param {string} tenantId ИД тенанта.
         * @param {string} id ИД файла аватара.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAvatarFileHeaders: async (tenantId: string, id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('getAvatarFileHeaders', 'tenantId', tenantId)
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getAvatarFileHeaders', 'id', id)
            const localVarPath = `/api/v1/clients/file-storage/avatar/{tenantId}/{id}`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'HEAD', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Получить Inbox файл.
         * @param {string} tenantId ИД тенанта.
         * @param {string} id ИД файла.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBotInboxFile: async (tenantId: string, id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('getBotInboxFile', 'tenantId', tenantId)
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getBotInboxFile', 'id', id)
            const localVarPath = `/api/v1/clients/file-storage/inbox/{tenantId}/{id}`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Получить Inbox файл.
         * @param {string} tenantId ИД тенанта.
         * @param {string} id ИД файла.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBotInboxFileHeaders: async (tenantId: string, id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('getBotInboxFileHeaders', 'tenantId', tenantId)
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getBotInboxFileHeaders', 'id', id)
            const localVarPath = `/api/v1/clients/file-storage/inbox/{tenantId}/{id}`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'HEAD', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Получить статический файл.
         * @param {string} tenantId ИД тенанта.
         * @param {string} id ИД файла.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBotStaticFile: async (tenantId: string, id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('getBotStaticFile', 'tenantId', tenantId)
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getBotStaticFile', 'id', id)
            const localVarPath = `/api/v1/clients/file-storage/static/{tenantId}/{id}`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Получить статический файл.
         * @param {string} tenantId ИД тенанта.
         * @param {string} id ИД файла.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBotStaticFileHeaders: async (tenantId: string, id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('getBotStaticFileHeaders', 'tenantId', tenantId)
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getBotStaticFileHeaders', 'id', id)
            const localVarPath = `/api/v1/clients/file-storage/static/{tenantId}/{id}`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'HEAD', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Получить временный файл.
         * @param {string} tenantId ИД тенанта.
         * @param {string} id ИД файла.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBotTemporaryFile: async (tenantId: string, id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('getBotTemporaryFile', 'tenantId', tenantId)
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getBotTemporaryFile', 'id', id)
            const localVarPath = `/api/v1/clients/file-storage/temp/{tenantId}/{id}`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Получить временный файл.
         * @param {string} tenantId ИД тенанта.
         * @param {string} id ИД файла.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBotTemporaryFileHeaders: async (tenantId: string, id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('getBotTemporaryFileHeaders', 'tenantId', tenantId)
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getBotTemporaryFileHeaders', 'id', id)
            const localVarPath = `/api/v1/clients/file-storage/temp/{tenantId}/{id}`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'HEAD', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Получить файл экспорта диалога.
         * @param {string} tenantId ИД тенанта.
         * @param {string} id ИД файла.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getConversationExportFile: async (tenantId: string, id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('getConversationExportFile', 'tenantId', tenantId)
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getConversationExportFile', 'id', id)
            const localVarPath = `/api/v1/clients/file-storage/conversation-export/{tenantId}/{id}`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Получить файл экспорта диалога.
         * @param {string} tenantId ИД тенанта.
         * @param {string} id ИД файла.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getConversationExportFileHeaders: async (tenantId: string, id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('getConversationExportFileHeaders', 'tenantId', tenantId)
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getConversationExportFileHeaders', 'id', id)
            const localVarPath = `/api/v1/clients/file-storage/conversation-export/{tenantId}/{id}`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'HEAD', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Загрузить файл аватара.
         * @param {File} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadAvatarFile: async (file?: File, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/clients/file-storage/avatar`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication OAuth20 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth20", ["elma.assistant.resources.identity.context", "elma.assistant.scopes.api.admin", "email", "offline_access", "openid", "phone", "profile"], configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


            if (file !== undefined) { 
                localVarFormParams.append('file', file as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Загрузить статический файл.
         * @param {File} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadBotStaticFile: async (file?: File, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/clients/file-storage/static`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication OAuth20 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth20", ["elma.assistant.resources.identity.context", "elma.assistant.scopes.api.admin", "email", "offline_access", "openid", "phone", "profile"], configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


            if (file !== undefined) { 
                localVarFormParams.append('file', file as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Загрузить временный файл.
         * @param {File} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadBotTemporaryFile: async (file?: File, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/clients/file-storage/temp`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication OAuth20 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth20", ["elma.assistant.resources.identity.context", "elma.assistant.scopes.api.admin", "email", "offline_access", "openid", "phone", "profile"], configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


            if (file !== undefined) { 
                localVarFormParams.append('file', file as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * FileStorageApi - functional programming interface
 * @export
 */
export const FileStorageApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = FileStorageApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Получить файл отчета аналитики.
         * @param {string} tenantId ИД тенанта.
         * @param {string} id ИД файла.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAnalyticsReportFile(tenantId: string, id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<File>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAnalyticsReportFile(tenantId, id, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['FileStorageApi.getAnalyticsReportFile']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Получить файл отчета аналитики.
         * @param {string} tenantId ИД тенанта.
         * @param {string} id ИД файла.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAnalyticsReportFileHeaders(tenantId: string, id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<File>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAnalyticsReportFileHeaders(tenantId, id, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['FileStorageApi.getAnalyticsReportFileHeaders']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Получить файл аватара.
         * @param {string} tenantId ИД тенанта.
         * @param {string} id ИД файла аватара.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAvatarFile(tenantId: string, id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<File>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAvatarFile(tenantId, id, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['FileStorageApi.getAvatarFile']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Получить файл аватара.
         * @param {string} tenantId ИД тенанта.
         * @param {string} id ИД файла аватара.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAvatarFileHeaders(tenantId: string, id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<File>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAvatarFileHeaders(tenantId, id, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['FileStorageApi.getAvatarFileHeaders']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Получить Inbox файл.
         * @param {string} tenantId ИД тенанта.
         * @param {string} id ИД файла.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getBotInboxFile(tenantId: string, id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<File>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getBotInboxFile(tenantId, id, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['FileStorageApi.getBotInboxFile']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Получить Inbox файл.
         * @param {string} tenantId ИД тенанта.
         * @param {string} id ИД файла.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getBotInboxFileHeaders(tenantId: string, id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<File>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getBotInboxFileHeaders(tenantId, id, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['FileStorageApi.getBotInboxFileHeaders']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Получить статический файл.
         * @param {string} tenantId ИД тенанта.
         * @param {string} id ИД файла.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getBotStaticFile(tenantId: string, id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<File>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getBotStaticFile(tenantId, id, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['FileStorageApi.getBotStaticFile']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Получить статический файл.
         * @param {string} tenantId ИД тенанта.
         * @param {string} id ИД файла.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getBotStaticFileHeaders(tenantId: string, id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<File>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getBotStaticFileHeaders(tenantId, id, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['FileStorageApi.getBotStaticFileHeaders']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Получить временный файл.
         * @param {string} tenantId ИД тенанта.
         * @param {string} id ИД файла.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getBotTemporaryFile(tenantId: string, id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<File>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getBotTemporaryFile(tenantId, id, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['FileStorageApi.getBotTemporaryFile']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Получить временный файл.
         * @param {string} tenantId ИД тенанта.
         * @param {string} id ИД файла.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getBotTemporaryFileHeaders(tenantId: string, id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<File>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getBotTemporaryFileHeaders(tenantId, id, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['FileStorageApi.getBotTemporaryFileHeaders']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Получить файл экспорта диалога.
         * @param {string} tenantId ИД тенанта.
         * @param {string} id ИД файла.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getConversationExportFile(tenantId: string, id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<File>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getConversationExportFile(tenantId, id, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['FileStorageApi.getConversationExportFile']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Получить файл экспорта диалога.
         * @param {string} tenantId ИД тенанта.
         * @param {string} id ИД файла.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getConversationExportFileHeaders(tenantId: string, id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<File>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getConversationExportFileHeaders(tenantId, id, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['FileStorageApi.getConversationExportFileHeaders']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Загрузить файл аватара.
         * @param {File} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async uploadAvatarFile(file?: File, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FileUploadingResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.uploadAvatarFile(file, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['FileStorageApi.uploadAvatarFile']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Загрузить статический файл.
         * @param {File} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async uploadBotStaticFile(file?: File, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FileUploadingResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.uploadBotStaticFile(file, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['FileStorageApi.uploadBotStaticFile']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Загрузить временный файл.
         * @param {File} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async uploadBotTemporaryFile(file?: File, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FileUploadingResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.uploadBotTemporaryFile(file, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['FileStorageApi.uploadBotTemporaryFile']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
    }
};

/**
 * FileStorageApi - factory interface
 * @export
 */
export const FileStorageApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = FileStorageApiFp(configuration)
    return {
        /**
         * 
         * @summary Получить файл отчета аналитики.
         * @param {string} tenantId ИД тенанта.
         * @param {string} id ИД файла.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAnalyticsReportFile(tenantId: string, id: string, options?: any): AxiosPromise<File> {
            return localVarFp.getAnalyticsReportFile(tenantId, id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Получить файл отчета аналитики.
         * @param {string} tenantId ИД тенанта.
         * @param {string} id ИД файла.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAnalyticsReportFileHeaders(tenantId: string, id: string, options?: any): AxiosPromise<File> {
            return localVarFp.getAnalyticsReportFileHeaders(tenantId, id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Получить файл аватара.
         * @param {string} tenantId ИД тенанта.
         * @param {string} id ИД файла аватара.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAvatarFile(tenantId: string, id: string, options?: any): AxiosPromise<File> {
            return localVarFp.getAvatarFile(tenantId, id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Получить файл аватара.
         * @param {string} tenantId ИД тенанта.
         * @param {string} id ИД файла аватара.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAvatarFileHeaders(tenantId: string, id: string, options?: any): AxiosPromise<File> {
            return localVarFp.getAvatarFileHeaders(tenantId, id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Получить Inbox файл.
         * @param {string} tenantId ИД тенанта.
         * @param {string} id ИД файла.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBotInboxFile(tenantId: string, id: string, options?: any): AxiosPromise<File> {
            return localVarFp.getBotInboxFile(tenantId, id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Получить Inbox файл.
         * @param {string} tenantId ИД тенанта.
         * @param {string} id ИД файла.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBotInboxFileHeaders(tenantId: string, id: string, options?: any): AxiosPromise<File> {
            return localVarFp.getBotInboxFileHeaders(tenantId, id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Получить статический файл.
         * @param {string} tenantId ИД тенанта.
         * @param {string} id ИД файла.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBotStaticFile(tenantId: string, id: string, options?: any): AxiosPromise<File> {
            return localVarFp.getBotStaticFile(tenantId, id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Получить статический файл.
         * @param {string} tenantId ИД тенанта.
         * @param {string} id ИД файла.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBotStaticFileHeaders(tenantId: string, id: string, options?: any): AxiosPromise<File> {
            return localVarFp.getBotStaticFileHeaders(tenantId, id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Получить временный файл.
         * @param {string} tenantId ИД тенанта.
         * @param {string} id ИД файла.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBotTemporaryFile(tenantId: string, id: string, options?: any): AxiosPromise<File> {
            return localVarFp.getBotTemporaryFile(tenantId, id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Получить временный файл.
         * @param {string} tenantId ИД тенанта.
         * @param {string} id ИД файла.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBotTemporaryFileHeaders(tenantId: string, id: string, options?: any): AxiosPromise<File> {
            return localVarFp.getBotTemporaryFileHeaders(tenantId, id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Получить файл экспорта диалога.
         * @param {string} tenantId ИД тенанта.
         * @param {string} id ИД файла.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getConversationExportFile(tenantId: string, id: string, options?: any): AxiosPromise<File> {
            return localVarFp.getConversationExportFile(tenantId, id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Получить файл экспорта диалога.
         * @param {string} tenantId ИД тенанта.
         * @param {string} id ИД файла.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getConversationExportFileHeaders(tenantId: string, id: string, options?: any): AxiosPromise<File> {
            return localVarFp.getConversationExportFileHeaders(tenantId, id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Загрузить файл аватара.
         * @param {File} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadAvatarFile(file?: File, options?: any): AxiosPromise<FileUploadingResponse> {
            return localVarFp.uploadAvatarFile(file, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Загрузить статический файл.
         * @param {File} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadBotStaticFile(file?: File, options?: any): AxiosPromise<FileUploadingResponse> {
            return localVarFp.uploadBotStaticFile(file, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Загрузить временный файл.
         * @param {File} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadBotTemporaryFile(file?: File, options?: any): AxiosPromise<FileUploadingResponse> {
            return localVarFp.uploadBotTemporaryFile(file, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * FileStorageApi - object-oriented interface
 * @export
 * @class FileStorageApi
 * @extends {BaseAPI}
 */
export class FileStorageApi extends BaseAPI {
    /**
     * 
     * @summary Получить файл отчета аналитики.
     * @param {string} tenantId ИД тенанта.
     * @param {string} id ИД файла.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FileStorageApi
     */
    public getAnalyticsReportFile(tenantId: string, id: string, options?: RawAxiosRequestConfig) {
        return FileStorageApiFp(this.configuration).getAnalyticsReportFile(tenantId, id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Получить файл отчета аналитики.
     * @param {string} tenantId ИД тенанта.
     * @param {string} id ИД файла.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FileStorageApi
     */
    public getAnalyticsReportFileHeaders(tenantId: string, id: string, options?: RawAxiosRequestConfig) {
        return FileStorageApiFp(this.configuration).getAnalyticsReportFileHeaders(tenantId, id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Получить файл аватара.
     * @param {string} tenantId ИД тенанта.
     * @param {string} id ИД файла аватара.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FileStorageApi
     */
    public getAvatarFile(tenantId: string, id: string, options?: RawAxiosRequestConfig) {
        return FileStorageApiFp(this.configuration).getAvatarFile(tenantId, id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Получить файл аватара.
     * @param {string} tenantId ИД тенанта.
     * @param {string} id ИД файла аватара.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FileStorageApi
     */
    public getAvatarFileHeaders(tenantId: string, id: string, options?: RawAxiosRequestConfig) {
        return FileStorageApiFp(this.configuration).getAvatarFileHeaders(tenantId, id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Получить Inbox файл.
     * @param {string} tenantId ИД тенанта.
     * @param {string} id ИД файла.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FileStorageApi
     */
    public getBotInboxFile(tenantId: string, id: string, options?: RawAxiosRequestConfig) {
        return FileStorageApiFp(this.configuration).getBotInboxFile(tenantId, id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Получить Inbox файл.
     * @param {string} tenantId ИД тенанта.
     * @param {string} id ИД файла.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FileStorageApi
     */
    public getBotInboxFileHeaders(tenantId: string, id: string, options?: RawAxiosRequestConfig) {
        return FileStorageApiFp(this.configuration).getBotInboxFileHeaders(tenantId, id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Получить статический файл.
     * @param {string} tenantId ИД тенанта.
     * @param {string} id ИД файла.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FileStorageApi
     */
    public getBotStaticFile(tenantId: string, id: string, options?: RawAxiosRequestConfig) {
        return FileStorageApiFp(this.configuration).getBotStaticFile(tenantId, id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Получить статический файл.
     * @param {string} tenantId ИД тенанта.
     * @param {string} id ИД файла.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FileStorageApi
     */
    public getBotStaticFileHeaders(tenantId: string, id: string, options?: RawAxiosRequestConfig) {
        return FileStorageApiFp(this.configuration).getBotStaticFileHeaders(tenantId, id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Получить временный файл.
     * @param {string} tenantId ИД тенанта.
     * @param {string} id ИД файла.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FileStorageApi
     */
    public getBotTemporaryFile(tenantId: string, id: string, options?: RawAxiosRequestConfig) {
        return FileStorageApiFp(this.configuration).getBotTemporaryFile(tenantId, id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Получить временный файл.
     * @param {string} tenantId ИД тенанта.
     * @param {string} id ИД файла.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FileStorageApi
     */
    public getBotTemporaryFileHeaders(tenantId: string, id: string, options?: RawAxiosRequestConfig) {
        return FileStorageApiFp(this.configuration).getBotTemporaryFileHeaders(tenantId, id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Получить файл экспорта диалога.
     * @param {string} tenantId ИД тенанта.
     * @param {string} id ИД файла.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FileStorageApi
     */
    public getConversationExportFile(tenantId: string, id: string, options?: RawAxiosRequestConfig) {
        return FileStorageApiFp(this.configuration).getConversationExportFile(tenantId, id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Получить файл экспорта диалога.
     * @param {string} tenantId ИД тенанта.
     * @param {string} id ИД файла.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FileStorageApi
     */
    public getConversationExportFileHeaders(tenantId: string, id: string, options?: RawAxiosRequestConfig) {
        return FileStorageApiFp(this.configuration).getConversationExportFileHeaders(tenantId, id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Загрузить файл аватара.
     * @param {File} [file] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FileStorageApi
     */
    public uploadAvatarFile(file?: File, options?: RawAxiosRequestConfig) {
        return FileStorageApiFp(this.configuration).uploadAvatarFile(file, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Загрузить статический файл.
     * @param {File} [file] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FileStorageApi
     */
    public uploadBotStaticFile(file?: File, options?: RawAxiosRequestConfig) {
        return FileStorageApiFp(this.configuration).uploadBotStaticFile(file, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Загрузить временный файл.
     * @param {File} [file] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FileStorageApi
     */
    public uploadBotTemporaryFile(file?: File, options?: RawAxiosRequestConfig) {
        return FileStorageApiFp(this.configuration).uploadBotTemporaryFile(file, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * GroupApi - axios parameter creator
 * @export
 */
export const GroupApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Создать группу сотрудников.
         * @param {GroupCreationRequest} [groupCreationRequest] Запрос на создание группы сотрудников.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createGroup: async (groupCreationRequest?: GroupCreationRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/groups`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth20 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth20", ["elma.assistant.resources.identity.context", "elma.assistant.scopes.api.admin", "email", "offline_access", "openid", "phone", "profile"], configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(groupCreationRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Удалить группу сотрудников.
         * @param {string} id ИД группы.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteGroup: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteGroup', 'id', id)
            const localVarPath = `/api/v1/groups/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth20 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth20", ["elma.assistant.resources.identity.context", "elma.assistant.scopes.api.admin", "email", "offline_access", "openid", "phone", "profile"], configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Получить группу сотрудников.
         * @param {string} id ИД группы.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getGroup: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getGroup', 'id', id)
            const localVarPath = `/api/v1/groups/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth20 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth20", ["elma.assistant.resources.identity.context", "elma.assistant.scopes.api.admin", "email", "offline_access", "openid", "phone", "profile"], configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Загрузить список групп сотрудников
         * @param {number} [pageIndex] 
         * @param {number} [pageSize] 
         * @param {boolean} [withTotal] 
         * @param {number} [fromItemInclusive] 
         * @param {number} [toItemExclusive] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listGroups: async (pageIndex?: number, pageSize?: number, withTotal?: boolean, fromItemInclusive?: number, toItemExclusive?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/groups/list`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth20 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth20", ["elma.assistant.resources.identity.context", "elma.assistant.scopes.api.admin", "email", "offline_access", "openid", "phone", "profile"], configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (pageIndex !== undefined) {
                localVarQueryParameter['PageIndex'] = pageIndex;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['PageSize'] = pageSize;
            }

            if (withTotal !== undefined) {
                localVarQueryParameter['WithTotal'] = withTotal;
            }

            if (fromItemInclusive !== undefined) {
                localVarQueryParameter['FromItemInclusive'] = fromItemInclusive;
            }

            if (toItemExclusive !== undefined) {
                localVarQueryParameter['ToItemExclusive'] = toItemExclusive;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Выполнить поиск групп сотрудников.
         * @param {number} [pageIndex] 
         * @param {number} [pageSize] 
         * @param {boolean} [withTotal] 
         * @param {number} [fromItemInclusive] 
         * @param {number} [toItemExclusive] 
         * @param {string} [search] Параметр поиска.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchGroups: async (pageIndex?: number, pageSize?: number, withTotal?: boolean, fromItemInclusive?: number, toItemExclusive?: number, search?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/groups/search`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth20 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth20", ["elma.assistant.resources.identity.context", "elma.assistant.scopes.api.admin", "email", "offline_access", "openid", "phone", "profile"], configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (pageIndex !== undefined) {
                localVarQueryParameter['PageIndex'] = pageIndex;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['PageSize'] = pageSize;
            }

            if (withTotal !== undefined) {
                localVarQueryParameter['WithTotal'] = withTotal;
            }

            if (fromItemInclusive !== undefined) {
                localVarQueryParameter['FromItemInclusive'] = fromItemInclusive;
            }

            if (toItemExclusive !== undefined) {
                localVarQueryParameter['ToItemExclusive'] = toItemExclusive;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Обновить группу сотрудников.
         * @param {string} id ИД группы.
         * @param {GroupUpdatingRequest} [groupUpdatingRequest] Модель группы.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateGroup: async (id: string, groupUpdatingRequest?: GroupUpdatingRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateGroup', 'id', id)
            const localVarPath = `/api/v1/groups/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth20 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth20", ["elma.assistant.resources.identity.context", "elma.assistant.scopes.api.admin", "email", "offline_access", "openid", "phone", "profile"], configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(groupUpdatingRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * GroupApi - functional programming interface
 * @export
 */
export const GroupApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = GroupApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Создать группу сотрудников.
         * @param {GroupCreationRequest} [groupCreationRequest] Запрос на создание группы сотрудников.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createGroup(groupCreationRequest?: GroupCreationRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GroupCreationResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createGroup(groupCreationRequest, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['GroupApi.createGroup']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Удалить группу сотрудников.
         * @param {string} id ИД группы.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteGroup(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteGroup(id, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['GroupApi.deleteGroup']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Получить группу сотрудников.
         * @param {string} id ИД группы.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getGroup(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GroupModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getGroup(id, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['GroupApi.getGroup']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Загрузить список групп сотрудников
         * @param {number} [pageIndex] 
         * @param {number} [pageSize] 
         * @param {boolean} [withTotal] 
         * @param {number} [fromItemInclusive] 
         * @param {number} [toItemExclusive] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listGroups(pageIndex?: number, pageSize?: number, withTotal?: boolean, fromItemInclusive?: number, toItemExclusive?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GroupModelPaginationResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listGroups(pageIndex, pageSize, withTotal, fromItemInclusive, toItemExclusive, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['GroupApi.listGroups']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Выполнить поиск групп сотрудников.
         * @param {number} [pageIndex] 
         * @param {number} [pageSize] 
         * @param {boolean} [withTotal] 
         * @param {number} [fromItemInclusive] 
         * @param {number} [toItemExclusive] 
         * @param {string} [search] Параметр поиска.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async searchGroups(pageIndex?: number, pageSize?: number, withTotal?: boolean, fromItemInclusive?: number, toItemExclusive?: number, search?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GroupModelPaginationResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.searchGroups(pageIndex, pageSize, withTotal, fromItemInclusive, toItemExclusive, search, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['GroupApi.searchGroups']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Обновить группу сотрудников.
         * @param {string} id ИД группы.
         * @param {GroupUpdatingRequest} [groupUpdatingRequest] Модель группы.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateGroup(id: string, groupUpdatingRequest?: GroupUpdatingRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateGroup(id, groupUpdatingRequest, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['GroupApi.updateGroup']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
    }
};

/**
 * GroupApi - factory interface
 * @export
 */
export const GroupApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = GroupApiFp(configuration)
    return {
        /**
         * 
         * @summary Создать группу сотрудников.
         * @param {GroupCreationRequest} [groupCreationRequest] Запрос на создание группы сотрудников.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createGroup(groupCreationRequest?: GroupCreationRequest, options?: any): AxiosPromise<GroupCreationResponse> {
            return localVarFp.createGroup(groupCreationRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Удалить группу сотрудников.
         * @param {string} id ИД группы.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteGroup(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteGroup(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Получить группу сотрудников.
         * @param {string} id ИД группы.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getGroup(id: string, options?: any): AxiosPromise<GroupModel> {
            return localVarFp.getGroup(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Загрузить список групп сотрудников
         * @param {number} [pageIndex] 
         * @param {number} [pageSize] 
         * @param {boolean} [withTotal] 
         * @param {number} [fromItemInclusive] 
         * @param {number} [toItemExclusive] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listGroups(pageIndex?: number, pageSize?: number, withTotal?: boolean, fromItemInclusive?: number, toItemExclusive?: number, options?: any): AxiosPromise<GroupModelPaginationResponse> {
            return localVarFp.listGroups(pageIndex, pageSize, withTotal, fromItemInclusive, toItemExclusive, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Выполнить поиск групп сотрудников.
         * @param {number} [pageIndex] 
         * @param {number} [pageSize] 
         * @param {boolean} [withTotal] 
         * @param {number} [fromItemInclusive] 
         * @param {number} [toItemExclusive] 
         * @param {string} [search] Параметр поиска.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchGroups(pageIndex?: number, pageSize?: number, withTotal?: boolean, fromItemInclusive?: number, toItemExclusive?: number, search?: string, options?: any): AxiosPromise<GroupModelPaginationResponse> {
            return localVarFp.searchGroups(pageIndex, pageSize, withTotal, fromItemInclusive, toItemExclusive, search, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Обновить группу сотрудников.
         * @param {string} id ИД группы.
         * @param {GroupUpdatingRequest} [groupUpdatingRequest] Модель группы.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateGroup(id: string, groupUpdatingRequest?: GroupUpdatingRequest, options?: any): AxiosPromise<void> {
            return localVarFp.updateGroup(id, groupUpdatingRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * GroupApi - object-oriented interface
 * @export
 * @class GroupApi
 * @extends {BaseAPI}
 */
export class GroupApi extends BaseAPI {
    /**
     * 
     * @summary Создать группу сотрудников.
     * @param {GroupCreationRequest} [groupCreationRequest] Запрос на создание группы сотрудников.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GroupApi
     */
    public createGroup(groupCreationRequest?: GroupCreationRequest, options?: RawAxiosRequestConfig) {
        return GroupApiFp(this.configuration).createGroup(groupCreationRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Удалить группу сотрудников.
     * @param {string} id ИД группы.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GroupApi
     */
    public deleteGroup(id: string, options?: RawAxiosRequestConfig) {
        return GroupApiFp(this.configuration).deleteGroup(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Получить группу сотрудников.
     * @param {string} id ИД группы.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GroupApi
     */
    public getGroup(id: string, options?: RawAxiosRequestConfig) {
        return GroupApiFp(this.configuration).getGroup(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Загрузить список групп сотрудников
     * @param {number} [pageIndex] 
     * @param {number} [pageSize] 
     * @param {boolean} [withTotal] 
     * @param {number} [fromItemInclusive] 
     * @param {number} [toItemExclusive] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GroupApi
     */
    public listGroups(pageIndex?: number, pageSize?: number, withTotal?: boolean, fromItemInclusive?: number, toItemExclusive?: number, options?: RawAxiosRequestConfig) {
        return GroupApiFp(this.configuration).listGroups(pageIndex, pageSize, withTotal, fromItemInclusive, toItemExclusive, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Выполнить поиск групп сотрудников.
     * @param {number} [pageIndex] 
     * @param {number} [pageSize] 
     * @param {boolean} [withTotal] 
     * @param {number} [fromItemInclusive] 
     * @param {number} [toItemExclusive] 
     * @param {string} [search] Параметр поиска.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GroupApi
     */
    public searchGroups(pageIndex?: number, pageSize?: number, withTotal?: boolean, fromItemInclusive?: number, toItemExclusive?: number, search?: string, options?: RawAxiosRequestConfig) {
        return GroupApiFp(this.configuration).searchGroups(pageIndex, pageSize, withTotal, fromItemInclusive, toItemExclusive, search, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Обновить группу сотрудников.
     * @param {string} id ИД группы.
     * @param {GroupUpdatingRequest} [groupUpdatingRequest] Модель группы.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GroupApi
     */
    public updateGroup(id: string, groupUpdatingRequest?: GroupUpdatingRequest, options?: RawAxiosRequestConfig) {
        return GroupApiFp(this.configuration).updateGroup(id, groupUpdatingRequest, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * GroupEditionApi - axios parameter creator
 * @export
 */
export const GroupEditionApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Получить редакцию группы интентов.
         * @param {string} id ИД редакции группы интентов.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getIntentGroupEdition: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getIntentGroupEdition', 'id', id)
            const localVarPath = `/api/v1/intents/group-editions/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth20 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth20", ["elma.assistant.resources.identity.context", "elma.assistant.scopes.api.admin", "email", "offline_access", "openid", "phone", "profile"], configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Выполнить поиск редакций групп интентов.
         * @param {object} [filtration] Параметры фильтрации.
         * @param {number} [pageIndex] 
         * @param {number} [pageSize] 
         * @param {boolean} [withTotal] 
         * @param {number} [fromItemInclusive] 
         * @param {number} [toItemExclusive] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchIntentGroupEditions: async (filtration?: object, pageIndex?: number, pageSize?: number, withTotal?: boolean, fromItemInclusive?: number, toItemExclusive?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/intents/group-editions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth20 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth20", ["elma.assistant.resources.identity.context", "elma.assistant.scopes.api.admin", "email", "offline_access", "openid", "phone", "profile"], configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (filtration !== undefined) {
                for (const [key, value] of Object.entries(filtration)) {
                    localVarQueryParameter[key] = value;
                }
            }

            if (pageIndex !== undefined) {
                localVarQueryParameter['PageIndex'] = pageIndex;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['PageSize'] = pageSize;
            }

            if (withTotal !== undefined) {
                localVarQueryParameter['WithTotal'] = withTotal;
            }

            if (fromItemInclusive !== undefined) {
                localVarQueryParameter['FromItemInclusive'] = fromItemInclusive;
            }

            if (toItemExclusive !== undefined) {
                localVarQueryParameter['ToItemExclusive'] = toItemExclusive;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * GroupEditionApi - functional programming interface
 * @export
 */
export const GroupEditionApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = GroupEditionApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Получить редакцию группы интентов.
         * @param {string} id ИД редакции группы интентов.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getIntentGroupEdition(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GroupEditionModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getIntentGroupEdition(id, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['GroupEditionApi.getIntentGroupEdition']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Выполнить поиск редакций групп интентов.
         * @param {object} [filtration] Параметры фильтрации.
         * @param {number} [pageIndex] 
         * @param {number} [pageSize] 
         * @param {boolean} [withTotal] 
         * @param {number} [fromItemInclusive] 
         * @param {number} [toItemExclusive] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async searchIntentGroupEditions(filtration?: object, pageIndex?: number, pageSize?: number, withTotal?: boolean, fromItemInclusive?: number, toItemExclusive?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GroupEditionModelPaginationResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.searchIntentGroupEditions(filtration, pageIndex, pageSize, withTotal, fromItemInclusive, toItemExclusive, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['GroupEditionApi.searchIntentGroupEditions']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
    }
};

/**
 * GroupEditionApi - factory interface
 * @export
 */
export const GroupEditionApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = GroupEditionApiFp(configuration)
    return {
        /**
         * 
         * @summary Получить редакцию группы интентов.
         * @param {string} id ИД редакции группы интентов.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getIntentGroupEdition(id: string, options?: any): AxiosPromise<GroupEditionModel> {
            return localVarFp.getIntentGroupEdition(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Выполнить поиск редакций групп интентов.
         * @param {object} [filtration] Параметры фильтрации.
         * @param {number} [pageIndex] 
         * @param {number} [pageSize] 
         * @param {boolean} [withTotal] 
         * @param {number} [fromItemInclusive] 
         * @param {number} [toItemExclusive] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchIntentGroupEditions(filtration?: object, pageIndex?: number, pageSize?: number, withTotal?: boolean, fromItemInclusive?: number, toItemExclusive?: number, options?: any): AxiosPromise<GroupEditionModelPaginationResponse> {
            return localVarFp.searchIntentGroupEditions(filtration, pageIndex, pageSize, withTotal, fromItemInclusive, toItemExclusive, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * GroupEditionApi - object-oriented interface
 * @export
 * @class GroupEditionApi
 * @extends {BaseAPI}
 */
export class GroupEditionApi extends BaseAPI {
    /**
     * 
     * @summary Получить редакцию группы интентов.
     * @param {string} id ИД редакции группы интентов.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GroupEditionApi
     */
    public getIntentGroupEdition(id: string, options?: RawAxiosRequestConfig) {
        return GroupEditionApiFp(this.configuration).getIntentGroupEdition(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Выполнить поиск редакций групп интентов.
     * @param {object} [filtration] Параметры фильтрации.
     * @param {number} [pageIndex] 
     * @param {number} [pageSize] 
     * @param {boolean} [withTotal] 
     * @param {number} [fromItemInclusive] 
     * @param {number} [toItemExclusive] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GroupEditionApi
     */
    public searchIntentGroupEditions(filtration?: object, pageIndex?: number, pageSize?: number, withTotal?: boolean, fromItemInclusive?: number, toItemExclusive?: number, options?: RawAxiosRequestConfig) {
        return GroupEditionApiFp(this.configuration).searchIntentGroupEditions(filtration, pageIndex, pageSize, withTotal, fromItemInclusive, toItemExclusive, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * GroupEntryApi - axios parameter creator
 * @export
 */
export const GroupEntryApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Получить запись группы интентов.
         * @param {string} id ИД записи группы интентов.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getIntentGroupEntry: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getIntentGroupEntry', 'id', id)
            const localVarPath = `/api/v1/intents/group-entries/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth20 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth20", ["elma.assistant.resources.identity.context", "elma.assistant.scopes.api.admin", "email", "offline_access", "openid", "phone", "profile"], configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Выполнить поиск записей групп интентов.
         * @param {object} [filtration] Параметры фильтрации.
         * @param {number} [pageIndex] 
         * @param {number} [pageSize] 
         * @param {boolean} [withTotal] 
         * @param {number} [fromItemInclusive] 
         * @param {number} [toItemExclusive] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchIntentGroupEntries: async (filtration?: object, pageIndex?: number, pageSize?: number, withTotal?: boolean, fromItemInclusive?: number, toItemExclusive?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/intents/group-entries`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth20 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth20", ["elma.assistant.resources.identity.context", "elma.assistant.scopes.api.admin", "email", "offline_access", "openid", "phone", "profile"], configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (filtration !== undefined) {
                for (const [key, value] of Object.entries(filtration)) {
                    localVarQueryParameter[key] = value;
                }
            }

            if (pageIndex !== undefined) {
                localVarQueryParameter['PageIndex'] = pageIndex;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['PageSize'] = pageSize;
            }

            if (withTotal !== undefined) {
                localVarQueryParameter['WithTotal'] = withTotal;
            }

            if (fromItemInclusive !== undefined) {
                localVarQueryParameter['FromItemInclusive'] = fromItemInclusive;
            }

            if (toItemExclusive !== undefined) {
                localVarQueryParameter['ToItemExclusive'] = toItemExclusive;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * GroupEntryApi - functional programming interface
 * @export
 */
export const GroupEntryApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = GroupEntryApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Получить запись группы интентов.
         * @param {string} id ИД записи группы интентов.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getIntentGroupEntry(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GroupEntryModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getIntentGroupEntry(id, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['GroupEntryApi.getIntentGroupEntry']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Выполнить поиск записей групп интентов.
         * @param {object} [filtration] Параметры фильтрации.
         * @param {number} [pageIndex] 
         * @param {number} [pageSize] 
         * @param {boolean} [withTotal] 
         * @param {number} [fromItemInclusive] 
         * @param {number} [toItemExclusive] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async searchIntentGroupEntries(filtration?: object, pageIndex?: number, pageSize?: number, withTotal?: boolean, fromItemInclusive?: number, toItemExclusive?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GroupEntryModelPaginationResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.searchIntentGroupEntries(filtration, pageIndex, pageSize, withTotal, fromItemInclusive, toItemExclusive, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['GroupEntryApi.searchIntentGroupEntries']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
    }
};

/**
 * GroupEntryApi - factory interface
 * @export
 */
export const GroupEntryApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = GroupEntryApiFp(configuration)
    return {
        /**
         * 
         * @summary Получить запись группы интентов.
         * @param {string} id ИД записи группы интентов.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getIntentGroupEntry(id: string, options?: any): AxiosPromise<GroupEntryModel> {
            return localVarFp.getIntentGroupEntry(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Выполнить поиск записей групп интентов.
         * @param {object} [filtration] Параметры фильтрации.
         * @param {number} [pageIndex] 
         * @param {number} [pageSize] 
         * @param {boolean} [withTotal] 
         * @param {number} [fromItemInclusive] 
         * @param {number} [toItemExclusive] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchIntentGroupEntries(filtration?: object, pageIndex?: number, pageSize?: number, withTotal?: boolean, fromItemInclusive?: number, toItemExclusive?: number, options?: any): AxiosPromise<GroupEntryModelPaginationResponse> {
            return localVarFp.searchIntentGroupEntries(filtration, pageIndex, pageSize, withTotal, fromItemInclusive, toItemExclusive, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * GroupEntryApi - object-oriented interface
 * @export
 * @class GroupEntryApi
 * @extends {BaseAPI}
 */
export class GroupEntryApi extends BaseAPI {
    /**
     * 
     * @summary Получить запись группы интентов.
     * @param {string} id ИД записи группы интентов.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GroupEntryApi
     */
    public getIntentGroupEntry(id: string, options?: RawAxiosRequestConfig) {
        return GroupEntryApiFp(this.configuration).getIntentGroupEntry(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Выполнить поиск записей групп интентов.
     * @param {object} [filtration] Параметры фильтрации.
     * @param {number} [pageIndex] 
     * @param {number} [pageSize] 
     * @param {boolean} [withTotal] 
     * @param {number} [fromItemInclusive] 
     * @param {number} [toItemExclusive] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GroupEntryApi
     */
    public searchIntentGroupEntries(filtration?: object, pageIndex?: number, pageSize?: number, withTotal?: boolean, fromItemInclusive?: number, toItemExclusive?: number, options?: RawAxiosRequestConfig) {
        return GroupEntryApiFp(this.configuration).searchIntentGroupEntries(filtration, pageIndex, pageSize, withTotal, fromItemInclusive, toItemExclusive, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * IdentityApi - axios parameter creator
 * @export
 */
export const IdentityApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Обработать обратный вызов от поставщика удостоверений.
         * @param {string} [code] Код авторизации.
         * @param {string} [state] Состояние запроса.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1SecurityIdentityCallbackGet: async (code?: string, state?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/security/identity/callback`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (code !== undefined) {
                localVarQueryParameter['code'] = code;
            }

            if (state !== undefined) {
                localVarQueryParameter['state'] = state;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Обработать обратный вызов от поставщика удостоверений.
         * @param {string} [code] Код авторизации.
         * @param {string} [state] Состояние запроса.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1SecurityIdentityCallbackPost: async (code?: string, state?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/security/identity/callback`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (code !== undefined) {
                localVarQueryParameter['code'] = code;
            }

            if (state !== undefined) {
                localVarQueryParameter['state'] = state;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Обработать перенаправление на поставщика удостоверений.
         * @param {string} securityKey Ключ безопасности.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1SecurityIdentityRedirectSecurityKeyGet: async (securityKey: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'securityKey' is not null or undefined
            assertParamExists('apiV1SecurityIdentityRedirectSecurityKeyGet', 'securityKey', securityKey)
            const localVarPath = `/api/v1/security/identity/redirect/{securityKey}`
                .replace(`{${"securityKey"}}`, encodeURIComponent(String(securityKey)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Обработать перенаправление на поставщика удостоверений.
         * @param {string} securityKey Ключ безопасности.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1SecurityIdentityRedirectSecurityKeyPost: async (securityKey: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'securityKey' is not null or undefined
            assertParamExists('apiV1SecurityIdentityRedirectSecurityKeyPost', 'securityKey', securityKey)
            const localVarPath = `/api/v1/security/identity/redirect/{securityKey}`
                .replace(`{${"securityKey"}}`, encodeURIComponent(String(securityKey)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * IdentityApi - functional programming interface
 * @export
 */
export const IdentityApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = IdentityApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Обработать обратный вызов от поставщика удостоверений.
         * @param {string} [code] Код авторизации.
         * @param {string} [state] Состояние запроса.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1SecurityIdentityCallbackGet(code?: string, state?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1SecurityIdentityCallbackGet(code, state, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['IdentityApi.apiV1SecurityIdentityCallbackGet']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Обработать обратный вызов от поставщика удостоверений.
         * @param {string} [code] Код авторизации.
         * @param {string} [state] Состояние запроса.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1SecurityIdentityCallbackPost(code?: string, state?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1SecurityIdentityCallbackPost(code, state, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['IdentityApi.apiV1SecurityIdentityCallbackPost']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Обработать перенаправление на поставщика удостоверений.
         * @param {string} securityKey Ключ безопасности.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1SecurityIdentityRedirectSecurityKeyGet(securityKey: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1SecurityIdentityRedirectSecurityKeyGet(securityKey, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['IdentityApi.apiV1SecurityIdentityRedirectSecurityKeyGet']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Обработать перенаправление на поставщика удостоверений.
         * @param {string} securityKey Ключ безопасности.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1SecurityIdentityRedirectSecurityKeyPost(securityKey: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1SecurityIdentityRedirectSecurityKeyPost(securityKey, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['IdentityApi.apiV1SecurityIdentityRedirectSecurityKeyPost']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
    }
};

/**
 * IdentityApi - factory interface
 * @export
 */
export const IdentityApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = IdentityApiFp(configuration)
    return {
        /**
         * 
         * @summary Обработать обратный вызов от поставщика удостоверений.
         * @param {string} [code] Код авторизации.
         * @param {string} [state] Состояние запроса.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1SecurityIdentityCallbackGet(code?: string, state?: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiV1SecurityIdentityCallbackGet(code, state, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Обработать обратный вызов от поставщика удостоверений.
         * @param {string} [code] Код авторизации.
         * @param {string} [state] Состояние запроса.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1SecurityIdentityCallbackPost(code?: string, state?: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiV1SecurityIdentityCallbackPost(code, state, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Обработать перенаправление на поставщика удостоверений.
         * @param {string} securityKey Ключ безопасности.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1SecurityIdentityRedirectSecurityKeyGet(securityKey: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiV1SecurityIdentityRedirectSecurityKeyGet(securityKey, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Обработать перенаправление на поставщика удостоверений.
         * @param {string} securityKey Ключ безопасности.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1SecurityIdentityRedirectSecurityKeyPost(securityKey: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiV1SecurityIdentityRedirectSecurityKeyPost(securityKey, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * IdentityApi - object-oriented interface
 * @export
 * @class IdentityApi
 * @extends {BaseAPI}
 */
export class IdentityApi extends BaseAPI {
    /**
     * 
     * @summary Обработать обратный вызов от поставщика удостоверений.
     * @param {string} [code] Код авторизации.
     * @param {string} [state] Состояние запроса.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IdentityApi
     */
    public apiV1SecurityIdentityCallbackGet(code?: string, state?: string, options?: RawAxiosRequestConfig) {
        return IdentityApiFp(this.configuration).apiV1SecurityIdentityCallbackGet(code, state, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Обработать обратный вызов от поставщика удостоверений.
     * @param {string} [code] Код авторизации.
     * @param {string} [state] Состояние запроса.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IdentityApi
     */
    public apiV1SecurityIdentityCallbackPost(code?: string, state?: string, options?: RawAxiosRequestConfig) {
        return IdentityApiFp(this.configuration).apiV1SecurityIdentityCallbackPost(code, state, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Обработать перенаправление на поставщика удостоверений.
     * @param {string} securityKey Ключ безопасности.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IdentityApi
     */
    public apiV1SecurityIdentityRedirectSecurityKeyGet(securityKey: string, options?: RawAxiosRequestConfig) {
        return IdentityApiFp(this.configuration).apiV1SecurityIdentityRedirectSecurityKeyGet(securityKey, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Обработать перенаправление на поставщика удостоверений.
     * @param {string} securityKey Ключ безопасности.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IdentityApi
     */
    public apiV1SecurityIdentityRedirectSecurityKeyPost(securityKey: string, options?: RawAxiosRequestConfig) {
        return IdentityApiFp(this.configuration).apiV1SecurityIdentityRedirectSecurityKeyPost(securityKey, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * InboxAttachmentApi - axios parameter creator
 * @export
 */
export const InboxAttachmentApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Удалить вложения.
         * @param {string} [externalId] Внешний ИД.
         * @param {string} [activityId] ИД активности.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteInboxAttachments: async (externalId?: string, activityId?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/inbox/attachments`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth20 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth20", ["elma.assistant.resources.identity.context", "elma.assistant.scopes.api.admin", "email", "offline_access", "openid", "phone", "profile"], configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (externalId !== undefined) {
                localVarQueryParameter['externalId'] = externalId;
            }

            if (activityId !== undefined) {
                localVarQueryParameter['activityId'] = activityId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Получить вложение.
         * @param {string} id ИД вложения.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInboxAttachment: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getInboxAttachment', 'id', id)
            const localVarPath = `/api/v1/inbox/attachments/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth20 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth20", ["elma.assistant.resources.identity.context", "elma.assistant.scopes.api.admin", "email", "offline_access", "openid", "phone", "profile"], configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Выполнить поиск вложений.
         * @param {string} [externalId] Внешний ИД.
         * @param {string} [activityId] ИД активности.
         * @param {number} [pageIndex] 
         * @param {number} [pageSize] 
         * @param {boolean} [withTotal] 
         * @param {number} [fromItemInclusive] 
         * @param {number} [toItemExclusive] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchInboxAttachments: async (externalId?: string, activityId?: string, pageIndex?: number, pageSize?: number, withTotal?: boolean, fromItemInclusive?: number, toItemExclusive?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/inbox/attachments`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth20 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth20", ["elma.assistant.resources.identity.context", "elma.assistant.scopes.api.admin", "email", "offline_access", "openid", "phone", "profile"], configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (externalId !== undefined) {
                localVarQueryParameter['ExternalId'] = externalId;
            }

            if (activityId !== undefined) {
                localVarQueryParameter['ActivityId'] = activityId;
            }

            if (pageIndex !== undefined) {
                localVarQueryParameter['PageIndex'] = pageIndex;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['PageSize'] = pageSize;
            }

            if (withTotal !== undefined) {
                localVarQueryParameter['WithTotal'] = withTotal;
            }

            if (fromItemInclusive !== undefined) {
                localVarQueryParameter['FromItemInclusive'] = fromItemInclusive;
            }

            if (toItemExclusive !== undefined) {
                localVarQueryParameter['ToItemExclusive'] = toItemExclusive;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Загрузить вложение.
         * @param {string} [activityId] 
         * @param {string} [fileId] 
         * @param {string} [contentType] 
         * @param {string} [contentDisposition] 
         * @param {{ [key: string]: Array<string>; }} [headers] 
         * @param {number} [length] 
         * @param {string} [name] 
         * @param {string} [fileName] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadInboxAttachment: async (activityId?: string, fileId?: string, contentType?: string, contentDisposition?: string, headers?: { [key: string]: Array<string>; }, length?: number, name?: string, fileName?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/inbox/attachments`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication OAuth20 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth20", ["elma.assistant.resources.identity.context", "elma.assistant.scopes.api.admin", "email", "offline_access", "openid", "phone", "profile"], configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


            if (activityId !== undefined) { 
                localVarFormParams.append('activityId', activityId as any);
            }
    
            if (fileId !== undefined) { 
                localVarFormParams.append('fileId', fileId as any);
            }
    
            if (contentType !== undefined) { 
                localVarFormParams.append('ContentType', contentType as any);
            }
    
            if (contentDisposition !== undefined) { 
                localVarFormParams.append('ContentDisposition', contentDisposition as any);
            }
    
            if (headers !== undefined) { 
                localVarFormParams.append('Headers', new Blob([JSON.stringify(headers)], { type: "application/json", }));
            }
    
            if (length !== undefined) { 
                localVarFormParams.append('Length', length as any);
            }
    
            if (name !== undefined) { 
                localVarFormParams.append('Name', name as any);
            }
    
            if (fileName !== undefined) { 
                localVarFormParams.append('FileName', fileName as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * InboxAttachmentApi - functional programming interface
 * @export
 */
export const InboxAttachmentApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = InboxAttachmentApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Удалить вложения.
         * @param {string} [externalId] Внешний ИД.
         * @param {string} [activityId] ИД активности.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteInboxAttachments(externalId?: string, activityId?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InboxAttachmentModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteInboxAttachments(externalId, activityId, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['InboxAttachmentApi.deleteInboxAttachments']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Получить вложение.
         * @param {string} id ИД вложения.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getInboxAttachment(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InboxAttachmentModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getInboxAttachment(id, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['InboxAttachmentApi.getInboxAttachment']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Выполнить поиск вложений.
         * @param {string} [externalId] Внешний ИД.
         * @param {string} [activityId] ИД активности.
         * @param {number} [pageIndex] 
         * @param {number} [pageSize] 
         * @param {boolean} [withTotal] 
         * @param {number} [fromItemInclusive] 
         * @param {number} [toItemExclusive] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async searchInboxAttachments(externalId?: string, activityId?: string, pageIndex?: number, pageSize?: number, withTotal?: boolean, fromItemInclusive?: number, toItemExclusive?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InboxAttachmentModelPaginationResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.searchInboxAttachments(externalId, activityId, pageIndex, pageSize, withTotal, fromItemInclusive, toItemExclusive, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['InboxAttachmentApi.searchInboxAttachments']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Загрузить вложение.
         * @param {string} [activityId] 
         * @param {string} [fileId] 
         * @param {string} [contentType] 
         * @param {string} [contentDisposition] 
         * @param {{ [key: string]: Array<string>; }} [headers] 
         * @param {number} [length] 
         * @param {string} [name] 
         * @param {string} [fileName] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async uploadInboxAttachment(activityId?: string, fileId?: string, contentType?: string, contentDisposition?: string, headers?: { [key: string]: Array<string>; }, length?: number, name?: string, fileName?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InboxAttachmentModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.uploadInboxAttachment(activityId, fileId, contentType, contentDisposition, headers, length, name, fileName, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['InboxAttachmentApi.uploadInboxAttachment']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
    }
};

/**
 * InboxAttachmentApi - factory interface
 * @export
 */
export const InboxAttachmentApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = InboxAttachmentApiFp(configuration)
    return {
        /**
         * 
         * @summary Удалить вложения.
         * @param {string} [externalId] Внешний ИД.
         * @param {string} [activityId] ИД активности.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteInboxAttachments(externalId?: string, activityId?: string, options?: any): AxiosPromise<InboxAttachmentModel> {
            return localVarFp.deleteInboxAttachments(externalId, activityId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Получить вложение.
         * @param {string} id ИД вложения.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInboxAttachment(id: string, options?: any): AxiosPromise<InboxAttachmentModel> {
            return localVarFp.getInboxAttachment(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Выполнить поиск вложений.
         * @param {string} [externalId] Внешний ИД.
         * @param {string} [activityId] ИД активности.
         * @param {number} [pageIndex] 
         * @param {number} [pageSize] 
         * @param {boolean} [withTotal] 
         * @param {number} [fromItemInclusive] 
         * @param {number} [toItemExclusive] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchInboxAttachments(externalId?: string, activityId?: string, pageIndex?: number, pageSize?: number, withTotal?: boolean, fromItemInclusive?: number, toItemExclusive?: number, options?: any): AxiosPromise<InboxAttachmentModelPaginationResponse> {
            return localVarFp.searchInboxAttachments(externalId, activityId, pageIndex, pageSize, withTotal, fromItemInclusive, toItemExclusive, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Загрузить вложение.
         * @param {string} [activityId] 
         * @param {string} [fileId] 
         * @param {string} [contentType] 
         * @param {string} [contentDisposition] 
         * @param {{ [key: string]: Array<string>; }} [headers] 
         * @param {number} [length] 
         * @param {string} [name] 
         * @param {string} [fileName] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadInboxAttachment(activityId?: string, fileId?: string, contentType?: string, contentDisposition?: string, headers?: { [key: string]: Array<string>; }, length?: number, name?: string, fileName?: string, options?: any): AxiosPromise<InboxAttachmentModel> {
            return localVarFp.uploadInboxAttachment(activityId, fileId, contentType, contentDisposition, headers, length, name, fileName, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * InboxAttachmentApi - object-oriented interface
 * @export
 * @class InboxAttachmentApi
 * @extends {BaseAPI}
 */
export class InboxAttachmentApi extends BaseAPI {
    /**
     * 
     * @summary Удалить вложения.
     * @param {string} [externalId] Внешний ИД.
     * @param {string} [activityId] ИД активности.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InboxAttachmentApi
     */
    public deleteInboxAttachments(externalId?: string, activityId?: string, options?: RawAxiosRequestConfig) {
        return InboxAttachmentApiFp(this.configuration).deleteInboxAttachments(externalId, activityId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Получить вложение.
     * @param {string} id ИД вложения.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InboxAttachmentApi
     */
    public getInboxAttachment(id: string, options?: RawAxiosRequestConfig) {
        return InboxAttachmentApiFp(this.configuration).getInboxAttachment(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Выполнить поиск вложений.
     * @param {string} [externalId] Внешний ИД.
     * @param {string} [activityId] ИД активности.
     * @param {number} [pageIndex] 
     * @param {number} [pageSize] 
     * @param {boolean} [withTotal] 
     * @param {number} [fromItemInclusive] 
     * @param {number} [toItemExclusive] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InboxAttachmentApi
     */
    public searchInboxAttachments(externalId?: string, activityId?: string, pageIndex?: number, pageSize?: number, withTotal?: boolean, fromItemInclusive?: number, toItemExclusive?: number, options?: RawAxiosRequestConfig) {
        return InboxAttachmentApiFp(this.configuration).searchInboxAttachments(externalId, activityId, pageIndex, pageSize, withTotal, fromItemInclusive, toItemExclusive, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Загрузить вложение.
     * @param {string} [activityId] 
     * @param {string} [fileId] 
     * @param {string} [contentType] 
     * @param {string} [contentDisposition] 
     * @param {{ [key: string]: Array<string>; }} [headers] 
     * @param {number} [length] 
     * @param {string} [name] 
     * @param {string} [fileName] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InboxAttachmentApi
     */
    public uploadInboxAttachment(activityId?: string, fileId?: string, contentType?: string, contentDisposition?: string, headers?: { [key: string]: Array<string>; }, length?: number, name?: string, fileName?: string, options?: RawAxiosRequestConfig) {
        return InboxAttachmentApiFp(this.configuration).uploadInboxAttachment(activityId, fileId, contentType, contentDisposition, headers, length, name, fileName, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * InboxChannelApi - axios parameter creator
 * @export
 */
export const InboxChannelApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Создать канал.
         * @param {InboxChannelCreationRequest} [inboxChannelCreationRequest] Запрос на создание канала.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createInboxChannel: async (inboxChannelCreationRequest?: InboxChannelCreationRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/inbox/channels`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth20 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth20", ["elma.assistant.resources.identity.context", "elma.assistant.scopes.api.admin", "email", "offline_access", "openid", "phone", "profile"], configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(inboxChannelCreationRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Удалить канал.
         * @param {string} id ИД канала.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteInboxChannel: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteInboxChannel', 'id', id)
            const localVarPath = `/api/v1/inbox/channels/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth20 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth20", ["elma.assistant.resources.identity.context", "elma.assistant.scopes.api.admin", "email", "offline_access", "openid", "phone", "profile"], configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Получить канал.
         * @param {string} id ИД канала.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInboxChannel: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getInboxChannel', 'id', id)
            const localVarPath = `/api/v1/inbox/channels/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth20 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth20", ["elma.assistant.resources.identity.context", "elma.assistant.scopes.api.admin", "email", "offline_access", "openid", "phone", "profile"], configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Получить информацию о вебхуке канала.
         * @param {string} id ИД канала.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInboxChannelWebhookInfo: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getInboxChannelWebhookInfo', 'id', id)
            const localVarPath = `/api/v1/inbox/channels/{id}/webhook-info`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth20 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth20", ["elma.assistant.resources.identity.context", "elma.assistant.scopes.api.admin", "email", "offline_access", "openid", "phone", "profile"], configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Выполнить поиск каналов.
         * @param {string} [search] Текст поиска.
         * @param {AgentStageAccountStatus} [status] Статус.
         * @param {string} [channelId] ИД канала.
         * @param {string} [operatorGroupId] ИД группы операторов.
         * @param {number} [pageIndex] 
         * @param {number} [pageSize] 
         * @param {boolean} [withTotal] 
         * @param {number} [fromItemInclusive] 
         * @param {number} [toItemExclusive] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchInboxChannels: async (search?: string, status?: AgentStageAccountStatus, channelId?: string, operatorGroupId?: string, pageIndex?: number, pageSize?: number, withTotal?: boolean, fromItemInclusive?: number, toItemExclusive?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/inbox/channels`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth20 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth20", ["elma.assistant.resources.identity.context", "elma.assistant.scopes.api.admin", "email", "offline_access", "openid", "phone", "profile"], configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (search !== undefined) {
                localVarQueryParameter['Search'] = search;
            }

            if (status !== undefined) {
                localVarQueryParameter['Status'] = status;
            }

            if (channelId !== undefined) {
                localVarQueryParameter['ChannelId'] = channelId;
            }

            if (operatorGroupId !== undefined) {
                localVarQueryParameter['OperatorGroupId'] = operatorGroupId;
            }

            if (pageIndex !== undefined) {
                localVarQueryParameter['PageIndex'] = pageIndex;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['PageSize'] = pageSize;
            }

            if (withTotal !== undefined) {
                localVarQueryParameter['WithTotal'] = withTotal;
            }

            if (fromItemInclusive !== undefined) {
                localVarQueryParameter['FromItemInclusive'] = fromItemInclusive;
            }

            if (toItemExclusive !== undefined) {
                localVarQueryParameter['ToItemExclusive'] = toItemExclusive;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Обновить канал.
         * @param {string} id ИД канала.
         * @param {InboxChannelUpdatingRequest} [inboxChannelUpdatingRequest] Запрос на обновление канала.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateInboxChannel: async (id: string, inboxChannelUpdatingRequest?: InboxChannelUpdatingRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateInboxChannel', 'id', id)
            const localVarPath = `/api/v1/inbox/channels/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth20 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth20", ["elma.assistant.resources.identity.context", "elma.assistant.scopes.api.admin", "email", "offline_access", "openid", "phone", "profile"], configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(inboxChannelUpdatingRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * InboxChannelApi - functional programming interface
 * @export
 */
export const InboxChannelApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = InboxChannelApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Создать канал.
         * @param {InboxChannelCreationRequest} [inboxChannelCreationRequest] Запрос на создание канала.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createInboxChannel(inboxChannelCreationRequest?: InboxChannelCreationRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InboxChannelCreationResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createInboxChannel(inboxChannelCreationRequest, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['InboxChannelApi.createInboxChannel']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Удалить канал.
         * @param {string} id ИД канала.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteInboxChannel(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteInboxChannel(id, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['InboxChannelApi.deleteInboxChannel']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Получить канал.
         * @param {string} id ИД канала.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getInboxChannel(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InboxChannelModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getInboxChannel(id, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['InboxChannelApi.getInboxChannel']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Получить информацию о вебхуке канала.
         * @param {string} id ИД канала.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getInboxChannelWebhookInfo(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getInboxChannelWebhookInfo(id, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['InboxChannelApi.getInboxChannelWebhookInfo']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Выполнить поиск каналов.
         * @param {string} [search] Текст поиска.
         * @param {AgentStageAccountStatus} [status] Статус.
         * @param {string} [channelId] ИД канала.
         * @param {string} [operatorGroupId] ИД группы операторов.
         * @param {number} [pageIndex] 
         * @param {number} [pageSize] 
         * @param {boolean} [withTotal] 
         * @param {number} [fromItemInclusive] 
         * @param {number} [toItemExclusive] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async searchInboxChannels(search?: string, status?: AgentStageAccountStatus, channelId?: string, operatorGroupId?: string, pageIndex?: number, pageSize?: number, withTotal?: boolean, fromItemInclusive?: number, toItemExclusive?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InboxChannelModelPaginationResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.searchInboxChannels(search, status, channelId, operatorGroupId, pageIndex, pageSize, withTotal, fromItemInclusive, toItemExclusive, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['InboxChannelApi.searchInboxChannels']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Обновить канал.
         * @param {string} id ИД канала.
         * @param {InboxChannelUpdatingRequest} [inboxChannelUpdatingRequest] Запрос на обновление канала.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateInboxChannel(id: string, inboxChannelUpdatingRequest?: InboxChannelUpdatingRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateInboxChannel(id, inboxChannelUpdatingRequest, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['InboxChannelApi.updateInboxChannel']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
    }
};

/**
 * InboxChannelApi - factory interface
 * @export
 */
export const InboxChannelApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = InboxChannelApiFp(configuration)
    return {
        /**
         * 
         * @summary Создать канал.
         * @param {InboxChannelCreationRequest} [inboxChannelCreationRequest] Запрос на создание канала.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createInboxChannel(inboxChannelCreationRequest?: InboxChannelCreationRequest, options?: any): AxiosPromise<InboxChannelCreationResponse> {
            return localVarFp.createInboxChannel(inboxChannelCreationRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Удалить канал.
         * @param {string} id ИД канала.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteInboxChannel(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteInboxChannel(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Получить канал.
         * @param {string} id ИД канала.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInboxChannel(id: string, options?: any): AxiosPromise<InboxChannelModel> {
            return localVarFp.getInboxChannel(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Получить информацию о вебхуке канала.
         * @param {string} id ИД канала.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInboxChannelWebhookInfo(id: string, options?: any): AxiosPromise<any> {
            return localVarFp.getInboxChannelWebhookInfo(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Выполнить поиск каналов.
         * @param {string} [search] Текст поиска.
         * @param {AgentStageAccountStatus} [status] Статус.
         * @param {string} [channelId] ИД канала.
         * @param {string} [operatorGroupId] ИД группы операторов.
         * @param {number} [pageIndex] 
         * @param {number} [pageSize] 
         * @param {boolean} [withTotal] 
         * @param {number} [fromItemInclusive] 
         * @param {number} [toItemExclusive] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchInboxChannels(search?: string, status?: AgentStageAccountStatus, channelId?: string, operatorGroupId?: string, pageIndex?: number, pageSize?: number, withTotal?: boolean, fromItemInclusive?: number, toItemExclusive?: number, options?: any): AxiosPromise<InboxChannelModelPaginationResponse> {
            return localVarFp.searchInboxChannels(search, status, channelId, operatorGroupId, pageIndex, pageSize, withTotal, fromItemInclusive, toItemExclusive, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Обновить канал.
         * @param {string} id ИД канала.
         * @param {InboxChannelUpdatingRequest} [inboxChannelUpdatingRequest] Запрос на обновление канала.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateInboxChannel(id: string, inboxChannelUpdatingRequest?: InboxChannelUpdatingRequest, options?: any): AxiosPromise<void> {
            return localVarFp.updateInboxChannel(id, inboxChannelUpdatingRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * InboxChannelApi - object-oriented interface
 * @export
 * @class InboxChannelApi
 * @extends {BaseAPI}
 */
export class InboxChannelApi extends BaseAPI {
    /**
     * 
     * @summary Создать канал.
     * @param {InboxChannelCreationRequest} [inboxChannelCreationRequest] Запрос на создание канала.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InboxChannelApi
     */
    public createInboxChannel(inboxChannelCreationRequest?: InboxChannelCreationRequest, options?: RawAxiosRequestConfig) {
        return InboxChannelApiFp(this.configuration).createInboxChannel(inboxChannelCreationRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Удалить канал.
     * @param {string} id ИД канала.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InboxChannelApi
     */
    public deleteInboxChannel(id: string, options?: RawAxiosRequestConfig) {
        return InboxChannelApiFp(this.configuration).deleteInboxChannel(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Получить канал.
     * @param {string} id ИД канала.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InboxChannelApi
     */
    public getInboxChannel(id: string, options?: RawAxiosRequestConfig) {
        return InboxChannelApiFp(this.configuration).getInboxChannel(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Получить информацию о вебхуке канала.
     * @param {string} id ИД канала.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InboxChannelApi
     */
    public getInboxChannelWebhookInfo(id: string, options?: RawAxiosRequestConfig) {
        return InboxChannelApiFp(this.configuration).getInboxChannelWebhookInfo(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Выполнить поиск каналов.
     * @param {string} [search] Текст поиска.
     * @param {AgentStageAccountStatus} [status] Статус.
     * @param {string} [channelId] ИД канала.
     * @param {string} [operatorGroupId] ИД группы операторов.
     * @param {number} [pageIndex] 
     * @param {number} [pageSize] 
     * @param {boolean} [withTotal] 
     * @param {number} [fromItemInclusive] 
     * @param {number} [toItemExclusive] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InboxChannelApi
     */
    public searchInboxChannels(search?: string, status?: AgentStageAccountStatus, channelId?: string, operatorGroupId?: string, pageIndex?: number, pageSize?: number, withTotal?: boolean, fromItemInclusive?: number, toItemExclusive?: number, options?: RawAxiosRequestConfig) {
        return InboxChannelApiFp(this.configuration).searchInboxChannels(search, status, channelId, operatorGroupId, pageIndex, pageSize, withTotal, fromItemInclusive, toItemExclusive, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Обновить канал.
     * @param {string} id ИД канала.
     * @param {InboxChannelUpdatingRequest} [inboxChannelUpdatingRequest] Запрос на обновление канала.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InboxChannelApi
     */
    public updateInboxChannel(id: string, inboxChannelUpdatingRequest?: InboxChannelUpdatingRequest, options?: RawAxiosRequestConfig) {
        return InboxChannelApiFp(this.configuration).updateInboxChannel(id, inboxChannelUpdatingRequest, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * InboxChatApi - axios parameter creator
 * @export
 */
export const InboxChatApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Получить чат.
         * @param {string} id ИД чата.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInboxChat: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getInboxChat', 'id', id)
            const localVarPath = `/api/v1/inbox/chats/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth20 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth20", ["elma.assistant.resources.identity.context", "elma.assistant.scopes.api.admin", "email", "offline_access", "openid", "phone", "profile"], configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Выполнить поиск чатов.
         * @param {Array<InboxChatStatus>} [statuses] Статус чата.
         * @param {Array<InboxChatParticipantStatus>} [participantStatuses] Статусы участников.
         * @param {Array<string>} [subjectIds] ИД субъектов.
         * @param {Array<string>} [subjectRoles] Роли субъектов.
         * @param {Array<string>} [tagIds] ИД тегов чата.
         * @param {string} [searchText] Имя контакта.
         * @param {number} [pageIndex] 
         * @param {number} [pageSize] 
         * @param {boolean} [withTotal] 
         * @param {number} [fromItemInclusive] 
         * @param {number} [toItemExclusive] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchInboxChats: async (statuses?: Array<InboxChatStatus>, participantStatuses?: Array<InboxChatParticipantStatus>, subjectIds?: Array<string>, subjectRoles?: Array<string>, tagIds?: Array<string>, searchText?: string, pageIndex?: number, pageSize?: number, withTotal?: boolean, fromItemInclusive?: number, toItemExclusive?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/inbox/chats`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth20 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth20", ["elma.assistant.resources.identity.context", "elma.assistant.scopes.api.admin", "email", "offline_access", "openid", "phone", "profile"], configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (statuses) {
                localVarQueryParameter['Statuses'] = statuses;
            }

            if (participantStatuses) {
                localVarQueryParameter['ParticipantStatuses'] = participantStatuses;
            }

            if (subjectIds) {
                localVarQueryParameter['SubjectIds'] = subjectIds;
            }

            if (subjectRoles) {
                localVarQueryParameter['SubjectRoles'] = subjectRoles;
            }

            if (tagIds) {
                localVarQueryParameter['TagIds'] = tagIds;
            }

            if (searchText !== undefined) {
                localVarQueryParameter['SearchText'] = searchText;
            }

            if (pageIndex !== undefined) {
                localVarQueryParameter['PageIndex'] = pageIndex;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['PageSize'] = pageSize;
            }

            if (withTotal !== undefined) {
                localVarQueryParameter['WithTotal'] = withTotal;
            }

            if (fromItemInclusive !== undefined) {
                localVarQueryParameter['FromItemInclusive'] = fromItemInclusive;
            }

            if (toItemExclusive !== undefined) {
                localVarQueryParameter['ToItemExclusive'] = toItemExclusive;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Отправить действие в чат.
         * @param {string} id ИД чата.
         * @param {InboxChatInvocationModel} [inboxChatInvocationModel] Действие чата.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendInboxInvocation: async (id: string, inboxChatInvocationModel?: InboxChatInvocationModel, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('sendInboxInvocation', 'id', id)
            const localVarPath = `/api/v1/inbox/chats/{id}/invocations`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth20 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth20", ["elma.assistant.resources.identity.context", "elma.assistant.scopes.api.admin", "email", "offline_access", "openid", "phone", "profile"], configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(inboxChatInvocationModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Отправить сообщение в чат.
         * @param {string} id ИД чата.
         * @param {InboxChatMessageModel} [inboxChatMessageModel] Сообщение чата.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendInboxMessage: async (id: string, inboxChatMessageModel?: InboxChatMessageModel, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('sendInboxMessage', 'id', id)
            const localVarPath = `/api/v1/inbox/chats/{id}/messages`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth20 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth20", ["elma.assistant.resources.identity.context", "elma.assistant.scopes.api.admin", "email", "offline_access", "openid", "phone", "profile"], configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(inboxChatMessageModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Обновить чат.
         * @param {string} id ИД чата.
         * @param {InboxChatUpdatingRequest} [inboxChatUpdatingRequest] Запрос на обновление чата.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateInboxChat: async (id: string, inboxChatUpdatingRequest?: InboxChatUpdatingRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateInboxChat', 'id', id)
            const localVarPath = `/api/v1/inbox/chats/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth20 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth20", ["elma.assistant.resources.identity.context", "elma.assistant.scopes.api.admin", "email", "offline_access", "openid", "phone", "profile"], configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(inboxChatUpdatingRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * InboxChatApi - functional programming interface
 * @export
 */
export const InboxChatApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = InboxChatApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Получить чат.
         * @param {string} id ИД чата.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getInboxChat(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InboxChatModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getInboxChat(id, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['InboxChatApi.getInboxChat']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Выполнить поиск чатов.
         * @param {Array<InboxChatStatus>} [statuses] Статус чата.
         * @param {Array<InboxChatParticipantStatus>} [participantStatuses] Статусы участников.
         * @param {Array<string>} [subjectIds] ИД субъектов.
         * @param {Array<string>} [subjectRoles] Роли субъектов.
         * @param {Array<string>} [tagIds] ИД тегов чата.
         * @param {string} [searchText] Имя контакта.
         * @param {number} [pageIndex] 
         * @param {number} [pageSize] 
         * @param {boolean} [withTotal] 
         * @param {number} [fromItemInclusive] 
         * @param {number} [toItemExclusive] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async searchInboxChats(statuses?: Array<InboxChatStatus>, participantStatuses?: Array<InboxChatParticipantStatus>, subjectIds?: Array<string>, subjectRoles?: Array<string>, tagIds?: Array<string>, searchText?: string, pageIndex?: number, pageSize?: number, withTotal?: boolean, fromItemInclusive?: number, toItemExclusive?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InboxChatModelPaginationResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.searchInboxChats(statuses, participantStatuses, subjectIds, subjectRoles, tagIds, searchText, pageIndex, pageSize, withTotal, fromItemInclusive, toItemExclusive, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['InboxChatApi.searchInboxChats']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Отправить действие в чат.
         * @param {string} id ИД чата.
         * @param {InboxChatInvocationModel} [inboxChatInvocationModel] Действие чата.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sendInboxInvocation(id: string, inboxChatInvocationModel?: InboxChatInvocationModel, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.sendInboxInvocation(id, inboxChatInvocationModel, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['InboxChatApi.sendInboxInvocation']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Отправить сообщение в чат.
         * @param {string} id ИД чата.
         * @param {InboxChatMessageModel} [inboxChatMessageModel] Сообщение чата.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sendInboxMessage(id: string, inboxChatMessageModel?: InboxChatMessageModel, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.sendInboxMessage(id, inboxChatMessageModel, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['InboxChatApi.sendInboxMessage']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Обновить чат.
         * @param {string} id ИД чата.
         * @param {InboxChatUpdatingRequest} [inboxChatUpdatingRequest] Запрос на обновление чата.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateInboxChat(id: string, inboxChatUpdatingRequest?: InboxChatUpdatingRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateInboxChat(id, inboxChatUpdatingRequest, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['InboxChatApi.updateInboxChat']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
    }
};

/**
 * InboxChatApi - factory interface
 * @export
 */
export const InboxChatApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = InboxChatApiFp(configuration)
    return {
        /**
         * 
         * @summary Получить чат.
         * @param {string} id ИД чата.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInboxChat(id: string, options?: any): AxiosPromise<InboxChatModel> {
            return localVarFp.getInboxChat(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Выполнить поиск чатов.
         * @param {Array<InboxChatStatus>} [statuses] Статус чата.
         * @param {Array<InboxChatParticipantStatus>} [participantStatuses] Статусы участников.
         * @param {Array<string>} [subjectIds] ИД субъектов.
         * @param {Array<string>} [subjectRoles] Роли субъектов.
         * @param {Array<string>} [tagIds] ИД тегов чата.
         * @param {string} [searchText] Имя контакта.
         * @param {number} [pageIndex] 
         * @param {number} [pageSize] 
         * @param {boolean} [withTotal] 
         * @param {number} [fromItemInclusive] 
         * @param {number} [toItemExclusive] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchInboxChats(statuses?: Array<InboxChatStatus>, participantStatuses?: Array<InboxChatParticipantStatus>, subjectIds?: Array<string>, subjectRoles?: Array<string>, tagIds?: Array<string>, searchText?: string, pageIndex?: number, pageSize?: number, withTotal?: boolean, fromItemInclusive?: number, toItemExclusive?: number, options?: any): AxiosPromise<InboxChatModelPaginationResponse> {
            return localVarFp.searchInboxChats(statuses, participantStatuses, subjectIds, subjectRoles, tagIds, searchText, pageIndex, pageSize, withTotal, fromItemInclusive, toItemExclusive, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Отправить действие в чат.
         * @param {string} id ИД чата.
         * @param {InboxChatInvocationModel} [inboxChatInvocationModel] Действие чата.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendInboxInvocation(id: string, inboxChatInvocationModel?: InboxChatInvocationModel, options?: any): AxiosPromise<void> {
            return localVarFp.sendInboxInvocation(id, inboxChatInvocationModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Отправить сообщение в чат.
         * @param {string} id ИД чата.
         * @param {InboxChatMessageModel} [inboxChatMessageModel] Сообщение чата.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendInboxMessage(id: string, inboxChatMessageModel?: InboxChatMessageModel, options?: any): AxiosPromise<void> {
            return localVarFp.sendInboxMessage(id, inboxChatMessageModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Обновить чат.
         * @param {string} id ИД чата.
         * @param {InboxChatUpdatingRequest} [inboxChatUpdatingRequest] Запрос на обновление чата.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateInboxChat(id: string, inboxChatUpdatingRequest?: InboxChatUpdatingRequest, options?: any): AxiosPromise<void> {
            return localVarFp.updateInboxChat(id, inboxChatUpdatingRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * InboxChatApi - object-oriented interface
 * @export
 * @class InboxChatApi
 * @extends {BaseAPI}
 */
export class InboxChatApi extends BaseAPI {
    /**
     * 
     * @summary Получить чат.
     * @param {string} id ИД чата.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InboxChatApi
     */
    public getInboxChat(id: string, options?: RawAxiosRequestConfig) {
        return InboxChatApiFp(this.configuration).getInboxChat(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Выполнить поиск чатов.
     * @param {Array<InboxChatStatus>} [statuses] Статус чата.
     * @param {Array<InboxChatParticipantStatus>} [participantStatuses] Статусы участников.
     * @param {Array<string>} [subjectIds] ИД субъектов.
     * @param {Array<string>} [subjectRoles] Роли субъектов.
     * @param {Array<string>} [tagIds] ИД тегов чата.
     * @param {string} [searchText] Имя контакта.
     * @param {number} [pageIndex] 
     * @param {number} [pageSize] 
     * @param {boolean} [withTotal] 
     * @param {number} [fromItemInclusive] 
     * @param {number} [toItemExclusive] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InboxChatApi
     */
    public searchInboxChats(statuses?: Array<InboxChatStatus>, participantStatuses?: Array<InboxChatParticipantStatus>, subjectIds?: Array<string>, subjectRoles?: Array<string>, tagIds?: Array<string>, searchText?: string, pageIndex?: number, pageSize?: number, withTotal?: boolean, fromItemInclusive?: number, toItemExclusive?: number, options?: RawAxiosRequestConfig) {
        return InboxChatApiFp(this.configuration).searchInboxChats(statuses, participantStatuses, subjectIds, subjectRoles, tagIds, searchText, pageIndex, pageSize, withTotal, fromItemInclusive, toItemExclusive, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Отправить действие в чат.
     * @param {string} id ИД чата.
     * @param {InboxChatInvocationModel} [inboxChatInvocationModel] Действие чата.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InboxChatApi
     */
    public sendInboxInvocation(id: string, inboxChatInvocationModel?: InboxChatInvocationModel, options?: RawAxiosRequestConfig) {
        return InboxChatApiFp(this.configuration).sendInboxInvocation(id, inboxChatInvocationModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Отправить сообщение в чат.
     * @param {string} id ИД чата.
     * @param {InboxChatMessageModel} [inboxChatMessageModel] Сообщение чата.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InboxChatApi
     */
    public sendInboxMessage(id: string, inboxChatMessageModel?: InboxChatMessageModel, options?: RawAxiosRequestConfig) {
        return InboxChatApiFp(this.configuration).sendInboxMessage(id, inboxChatMessageModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Обновить чат.
     * @param {string} id ИД чата.
     * @param {InboxChatUpdatingRequest} [inboxChatUpdatingRequest] Запрос на обновление чата.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InboxChatApi
     */
    public updateInboxChat(id: string, inboxChatUpdatingRequest?: InboxChatUpdatingRequest, options?: RawAxiosRequestConfig) {
        return InboxChatApiFp(this.configuration).updateInboxChat(id, inboxChatUpdatingRequest, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * InboxContactApi - axios parameter creator
 * @export
 */
export const InboxContactApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Получить контакт по идентификатору пользователя.
         * @param {string} personId Идентификатор пользователя.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInboxContact: async (personId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'personId' is not null or undefined
            assertParamExists('getInboxContact', 'personId', personId)
            const localVarPath = `/api/v1/inbox/contacts/{personId}`
                .replace(`{${"personId"}}`, encodeURIComponent(String(personId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth20 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth20", ["elma.assistant.resources.identity.context", "elma.assistant.scopes.api.admin", "email", "offline_access", "openid", "phone", "profile"], configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Выполнить поиск контактов.
         * @param {string} [search] Текст.
         * @param {Array<string>} [tagIds] Список идентификаторов тэгов.
         * @param {number} [pageIndex] 
         * @param {number} [pageSize] 
         * @param {boolean} [withTotal] 
         * @param {number} [fromItemInclusive] 
         * @param {number} [toItemExclusive] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchInboxContacts: async (search?: string, tagIds?: Array<string>, pageIndex?: number, pageSize?: number, withTotal?: boolean, fromItemInclusive?: number, toItemExclusive?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/inbox/contacts/search`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth20 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth20", ["elma.assistant.resources.identity.context", "elma.assistant.scopes.api.admin", "email", "offline_access", "openid", "phone", "profile"], configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (search !== undefined) {
                localVarQueryParameter['Search'] = search;
            }

            if (tagIds) {
                localVarQueryParameter['TagIds'] = tagIds;
            }

            if (pageIndex !== undefined) {
                localVarQueryParameter['PageIndex'] = pageIndex;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['PageSize'] = pageSize;
            }

            if (withTotal !== undefined) {
                localVarQueryParameter['WithTotal'] = withTotal;
            }

            if (fromItemInclusive !== undefined) {
                localVarQueryParameter['FromItemInclusive'] = fromItemInclusive;
            }

            if (toItemExclusive !== undefined) {
                localVarQueryParameter['ToItemExclusive'] = toItemExclusive;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * InboxContactApi - functional programming interface
 * @export
 */
export const InboxContactApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = InboxContactApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Получить контакт по идентификатору пользователя.
         * @param {string} personId Идентификатор пользователя.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getInboxContact(personId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InboxContactModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getInboxContact(personId, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['InboxContactApi.getInboxContact']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Выполнить поиск контактов.
         * @param {string} [search] Текст.
         * @param {Array<string>} [tagIds] Список идентификаторов тэгов.
         * @param {number} [pageIndex] 
         * @param {number} [pageSize] 
         * @param {boolean} [withTotal] 
         * @param {number} [fromItemInclusive] 
         * @param {number} [toItemExclusive] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async searchInboxContacts(search?: string, tagIds?: Array<string>, pageIndex?: number, pageSize?: number, withTotal?: boolean, fromItemInclusive?: number, toItemExclusive?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InboxContactModelPaginationResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.searchInboxContacts(search, tagIds, pageIndex, pageSize, withTotal, fromItemInclusive, toItemExclusive, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['InboxContactApi.searchInboxContacts']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
    }
};

/**
 * InboxContactApi - factory interface
 * @export
 */
export const InboxContactApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = InboxContactApiFp(configuration)
    return {
        /**
         * 
         * @summary Получить контакт по идентификатору пользователя.
         * @param {string} personId Идентификатор пользователя.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInboxContact(personId: string, options?: any): AxiosPromise<InboxContactModel> {
            return localVarFp.getInboxContact(personId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Выполнить поиск контактов.
         * @param {string} [search] Текст.
         * @param {Array<string>} [tagIds] Список идентификаторов тэгов.
         * @param {number} [pageIndex] 
         * @param {number} [pageSize] 
         * @param {boolean} [withTotal] 
         * @param {number} [fromItemInclusive] 
         * @param {number} [toItemExclusive] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchInboxContacts(search?: string, tagIds?: Array<string>, pageIndex?: number, pageSize?: number, withTotal?: boolean, fromItemInclusive?: number, toItemExclusive?: number, options?: any): AxiosPromise<InboxContactModelPaginationResponse> {
            return localVarFp.searchInboxContacts(search, tagIds, pageIndex, pageSize, withTotal, fromItemInclusive, toItemExclusive, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * InboxContactApi - object-oriented interface
 * @export
 * @class InboxContactApi
 * @extends {BaseAPI}
 */
export class InboxContactApi extends BaseAPI {
    /**
     * 
     * @summary Получить контакт по идентификатору пользователя.
     * @param {string} personId Идентификатор пользователя.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InboxContactApi
     */
    public getInboxContact(personId: string, options?: RawAxiosRequestConfig) {
        return InboxContactApiFp(this.configuration).getInboxContact(personId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Выполнить поиск контактов.
     * @param {string} [search] Текст.
     * @param {Array<string>} [tagIds] Список идентификаторов тэгов.
     * @param {number} [pageIndex] 
     * @param {number} [pageSize] 
     * @param {boolean} [withTotal] 
     * @param {number} [fromItemInclusive] 
     * @param {number} [toItemExclusive] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InboxContactApi
     */
    public searchInboxContacts(search?: string, tagIds?: Array<string>, pageIndex?: number, pageSize?: number, withTotal?: boolean, fromItemInclusive?: number, toItemExclusive?: number, options?: RawAxiosRequestConfig) {
        return InboxContactApiFp(this.configuration).searchInboxContacts(search, tagIds, pageIndex, pageSize, withTotal, fromItemInclusive, toItemExclusive, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * InboxMessageApi - axios parameter creator
 * @export
 */
export const InboxMessageApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Получить сообщение.
         * @param {string} id ИД сообщения.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInboxMessage: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getInboxMessage', 'id', id)
            const localVarPath = `/api/v1/inbox/messages/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth20 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth20", ["elma.assistant.resources.identity.context", "elma.assistant.scopes.api.admin", "email", "offline_access", "openid", "phone", "profile"], configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Получить индекс сообщения в чате относительно последнего сообщения.
         * @param {string} id ИД сообщения.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInboxMessageIndex: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getInboxMessageIndex', 'id', id)
            const localVarPath = `/api/v1/inbox/messages/index/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth20 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth20", ["elma.assistant.resources.identity.context", "elma.assistant.scopes.api.admin", "email", "offline_access", "openid", "phone", "profile"], configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Выполнить поиск сообщений.
         * @param {InboxMessageStatus} [status] Статус сообщения.
         * @param {InboxDirection} [direction] Направление сообщения.
         * @param {string} [activityId] ИД активности.
         * @param {string} [chatId] ИД чата.
         * @param {number} [pageIndex] 
         * @param {number} [pageSize] 
         * @param {boolean} [withTotal] 
         * @param {number} [fromItemInclusive] 
         * @param {number} [toItemExclusive] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchInboxMessages: async (status?: InboxMessageStatus, direction?: InboxDirection, activityId?: string, chatId?: string, pageIndex?: number, pageSize?: number, withTotal?: boolean, fromItemInclusive?: number, toItemExclusive?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/inbox/messages`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth20 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth20", ["elma.assistant.resources.identity.context", "elma.assistant.scopes.api.admin", "email", "offline_access", "openid", "phone", "profile"], configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (status !== undefined) {
                localVarQueryParameter['Status'] = status;
            }

            if (direction !== undefined) {
                localVarQueryParameter['Direction'] = direction;
            }

            if (activityId !== undefined) {
                localVarQueryParameter['ActivityId'] = activityId;
            }

            if (chatId !== undefined) {
                localVarQueryParameter['ChatId'] = chatId;
            }

            if (pageIndex !== undefined) {
                localVarQueryParameter['PageIndex'] = pageIndex;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['PageSize'] = pageSize;
            }

            if (withTotal !== undefined) {
                localVarQueryParameter['WithTotal'] = withTotal;
            }

            if (fromItemInclusive !== undefined) {
                localVarQueryParameter['FromItemInclusive'] = fromItemInclusive;
            }

            if (toItemExclusive !== undefined) {
                localVarQueryParameter['ToItemExclusive'] = toItemExclusive;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * InboxMessageApi - functional programming interface
 * @export
 */
export const InboxMessageApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = InboxMessageApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Получить сообщение.
         * @param {string} id ИД сообщения.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getInboxMessage(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InboxMessageModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getInboxMessage(id, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['InboxMessageApi.getInboxMessage']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Получить индекс сообщения в чате относительно последнего сообщения.
         * @param {string} id ИД сообщения.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getInboxMessageIndex(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getInboxMessageIndex(id, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['InboxMessageApi.getInboxMessageIndex']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Выполнить поиск сообщений.
         * @param {InboxMessageStatus} [status] Статус сообщения.
         * @param {InboxDirection} [direction] Направление сообщения.
         * @param {string} [activityId] ИД активности.
         * @param {string} [chatId] ИД чата.
         * @param {number} [pageIndex] 
         * @param {number} [pageSize] 
         * @param {boolean} [withTotal] 
         * @param {number} [fromItemInclusive] 
         * @param {number} [toItemExclusive] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async searchInboxMessages(status?: InboxMessageStatus, direction?: InboxDirection, activityId?: string, chatId?: string, pageIndex?: number, pageSize?: number, withTotal?: boolean, fromItemInclusive?: number, toItemExclusive?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InboxMessageModelPaginationResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.searchInboxMessages(status, direction, activityId, chatId, pageIndex, pageSize, withTotal, fromItemInclusive, toItemExclusive, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['InboxMessageApi.searchInboxMessages']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
    }
};

/**
 * InboxMessageApi - factory interface
 * @export
 */
export const InboxMessageApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = InboxMessageApiFp(configuration)
    return {
        /**
         * 
         * @summary Получить сообщение.
         * @param {string} id ИД сообщения.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInboxMessage(id: string, options?: any): AxiosPromise<InboxMessageModel> {
            return localVarFp.getInboxMessage(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Получить индекс сообщения в чате относительно последнего сообщения.
         * @param {string} id ИД сообщения.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInboxMessageIndex(id: string, options?: any): AxiosPromise<number> {
            return localVarFp.getInboxMessageIndex(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Выполнить поиск сообщений.
         * @param {InboxMessageStatus} [status] Статус сообщения.
         * @param {InboxDirection} [direction] Направление сообщения.
         * @param {string} [activityId] ИД активности.
         * @param {string} [chatId] ИД чата.
         * @param {number} [pageIndex] 
         * @param {number} [pageSize] 
         * @param {boolean} [withTotal] 
         * @param {number} [fromItemInclusive] 
         * @param {number} [toItemExclusive] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchInboxMessages(status?: InboxMessageStatus, direction?: InboxDirection, activityId?: string, chatId?: string, pageIndex?: number, pageSize?: number, withTotal?: boolean, fromItemInclusive?: number, toItemExclusive?: number, options?: any): AxiosPromise<InboxMessageModelPaginationResponse> {
            return localVarFp.searchInboxMessages(status, direction, activityId, chatId, pageIndex, pageSize, withTotal, fromItemInclusive, toItemExclusive, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * InboxMessageApi - object-oriented interface
 * @export
 * @class InboxMessageApi
 * @extends {BaseAPI}
 */
export class InboxMessageApi extends BaseAPI {
    /**
     * 
     * @summary Получить сообщение.
     * @param {string} id ИД сообщения.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InboxMessageApi
     */
    public getInboxMessage(id: string, options?: RawAxiosRequestConfig) {
        return InboxMessageApiFp(this.configuration).getInboxMessage(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Получить индекс сообщения в чате относительно последнего сообщения.
     * @param {string} id ИД сообщения.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InboxMessageApi
     */
    public getInboxMessageIndex(id: string, options?: RawAxiosRequestConfig) {
        return InboxMessageApiFp(this.configuration).getInboxMessageIndex(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Выполнить поиск сообщений.
     * @param {InboxMessageStatus} [status] Статус сообщения.
     * @param {InboxDirection} [direction] Направление сообщения.
     * @param {string} [activityId] ИД активности.
     * @param {string} [chatId] ИД чата.
     * @param {number} [pageIndex] 
     * @param {number} [pageSize] 
     * @param {boolean} [withTotal] 
     * @param {number} [fromItemInclusive] 
     * @param {number} [toItemExclusive] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InboxMessageApi
     */
    public searchInboxMessages(status?: InboxMessageStatus, direction?: InboxDirection, activityId?: string, chatId?: string, pageIndex?: number, pageSize?: number, withTotal?: boolean, fromItemInclusive?: number, toItemExclusive?: number, options?: RawAxiosRequestConfig) {
        return InboxMessageApiFp(this.configuration).searchInboxMessages(status, direction, activityId, chatId, pageIndex, pageSize, withTotal, fromItemInclusive, toItemExclusive, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * InboxOperatorGroupApi - axios parameter creator
 * @export
 */
export const InboxOperatorGroupApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Создать группу операторов.
         * @param {InboxOperatorGroupCreationRequest} [inboxOperatorGroupCreationRequest] Запрос на создание группы операторов.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createInboxOperatorGroup: async (inboxOperatorGroupCreationRequest?: InboxOperatorGroupCreationRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/inbox/operators/groups`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth20 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth20", ["elma.assistant.resources.identity.context", "elma.assistant.scopes.api.admin", "email", "offline_access", "openid", "phone", "profile"], configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(inboxOperatorGroupCreationRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Удалить группу операторов.
         * @param {string} id ИД группы операторов.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteInboxOperatorGroup: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteInboxOperatorGroup', 'id', id)
            const localVarPath = `/api/v1/inbox/operators/groups/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth20 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth20", ["elma.assistant.resources.identity.context", "elma.assistant.scopes.api.admin", "email", "offline_access", "openid", "phone", "profile"], configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Получить список групп операторов.
         * @param {string} [searchText] Текст для поиска групп.
         * @param {Array<string>} [authUserIds] Список ИД операторов.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllInboxOperatorGroups: async (searchText?: string, authUserIds?: Array<string>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/inbox/operators/groups/all`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth20 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth20", ["elma.assistant.resources.identity.context", "elma.assistant.scopes.api.admin", "email", "offline_access", "openid", "phone", "profile"], configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (searchText !== undefined) {
                localVarQueryParameter['SearchText'] = searchText;
            }

            if (authUserIds) {
                localVarQueryParameter['AuthUserIds'] = authUserIds;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Получить группу операторов.
         * @param {string} id ИД группы операторов.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInboxOperatorGroup: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getInboxOperatorGroup', 'id', id)
            const localVarPath = `/api/v1/inbox/operators/groups/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth20 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth20", ["elma.assistant.resources.identity.context", "elma.assistant.scopes.api.admin", "email", "offline_access", "openid", "phone", "profile"], configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Выполнить поиск групп операторов.
         * @param {string} [searchText] Текст для поиска групп.
         * @param {Array<string>} [authUserIds] Список ИД операторов.
         * @param {number} [pageIndex] 
         * @param {number} [pageSize] 
         * @param {boolean} [withTotal] 
         * @param {number} [fromItemInclusive] 
         * @param {number} [toItemExclusive] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchInboxOperatorGroups: async (searchText?: string, authUserIds?: Array<string>, pageIndex?: number, pageSize?: number, withTotal?: boolean, fromItemInclusive?: number, toItemExclusive?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/inbox/operators/groups`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth20 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth20", ["elma.assistant.resources.identity.context", "elma.assistant.scopes.api.admin", "email", "offline_access", "openid", "phone", "profile"], configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (searchText !== undefined) {
                localVarQueryParameter['SearchText'] = searchText;
            }

            if (authUserIds) {
                localVarQueryParameter['AuthUserIds'] = authUserIds;
            }

            if (pageIndex !== undefined) {
                localVarQueryParameter['PageIndex'] = pageIndex;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['PageSize'] = pageSize;
            }

            if (withTotal !== undefined) {
                localVarQueryParameter['WithTotal'] = withTotal;
            }

            if (fromItemInclusive !== undefined) {
                localVarQueryParameter['FromItemInclusive'] = fromItemInclusive;
            }

            if (toItemExclusive !== undefined) {
                localVarQueryParameter['ToItemExclusive'] = toItemExclusive;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Обновить группу операторов.
         * @param {string} id ИД группы операторов.
         * @param {InboxOperatorGroupUpdatingRequest} [inboxOperatorGroupUpdatingRequest] Запрос на обновление группы операторов.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateInboxOperatorGroup: async (id: string, inboxOperatorGroupUpdatingRequest?: InboxOperatorGroupUpdatingRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateInboxOperatorGroup', 'id', id)
            const localVarPath = `/api/v1/inbox/operators/groups/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth20 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth20", ["elma.assistant.resources.identity.context", "elma.assistant.scopes.api.admin", "email", "offline_access", "openid", "phone", "profile"], configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(inboxOperatorGroupUpdatingRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * InboxOperatorGroupApi - functional programming interface
 * @export
 */
export const InboxOperatorGroupApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = InboxOperatorGroupApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Создать группу операторов.
         * @param {InboxOperatorGroupCreationRequest} [inboxOperatorGroupCreationRequest] Запрос на создание группы операторов.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createInboxOperatorGroup(inboxOperatorGroupCreationRequest?: InboxOperatorGroupCreationRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InboxOperatorGroupCreationResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createInboxOperatorGroup(inboxOperatorGroupCreationRequest, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['InboxOperatorGroupApi.createInboxOperatorGroup']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Удалить группу операторов.
         * @param {string} id ИД группы операторов.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteInboxOperatorGroup(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteInboxOperatorGroup(id, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['InboxOperatorGroupApi.deleteInboxOperatorGroup']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Получить список групп операторов.
         * @param {string} [searchText] Текст для поиска групп.
         * @param {Array<string>} [authUserIds] Список ИД операторов.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllInboxOperatorGroups(searchText?: string, authUserIds?: Array<string>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<InboxOperatorGroupModel>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllInboxOperatorGroups(searchText, authUserIds, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['InboxOperatorGroupApi.getAllInboxOperatorGroups']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Получить группу операторов.
         * @param {string} id ИД группы операторов.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getInboxOperatorGroup(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InboxOperatorGroupModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getInboxOperatorGroup(id, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['InboxOperatorGroupApi.getInboxOperatorGroup']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Выполнить поиск групп операторов.
         * @param {string} [searchText] Текст для поиска групп.
         * @param {Array<string>} [authUserIds] Список ИД операторов.
         * @param {number} [pageIndex] 
         * @param {number} [pageSize] 
         * @param {boolean} [withTotal] 
         * @param {number} [fromItemInclusive] 
         * @param {number} [toItemExclusive] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async searchInboxOperatorGroups(searchText?: string, authUserIds?: Array<string>, pageIndex?: number, pageSize?: number, withTotal?: boolean, fromItemInclusive?: number, toItemExclusive?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InboxOperatorGroupModelPaginationResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.searchInboxOperatorGroups(searchText, authUserIds, pageIndex, pageSize, withTotal, fromItemInclusive, toItemExclusive, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['InboxOperatorGroupApi.searchInboxOperatorGroups']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Обновить группу операторов.
         * @param {string} id ИД группы операторов.
         * @param {InboxOperatorGroupUpdatingRequest} [inboxOperatorGroupUpdatingRequest] Запрос на обновление группы операторов.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateInboxOperatorGroup(id: string, inboxOperatorGroupUpdatingRequest?: InboxOperatorGroupUpdatingRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateInboxOperatorGroup(id, inboxOperatorGroupUpdatingRequest, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['InboxOperatorGroupApi.updateInboxOperatorGroup']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
    }
};

/**
 * InboxOperatorGroupApi - factory interface
 * @export
 */
export const InboxOperatorGroupApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = InboxOperatorGroupApiFp(configuration)
    return {
        /**
         * 
         * @summary Создать группу операторов.
         * @param {InboxOperatorGroupCreationRequest} [inboxOperatorGroupCreationRequest] Запрос на создание группы операторов.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createInboxOperatorGroup(inboxOperatorGroupCreationRequest?: InboxOperatorGroupCreationRequest, options?: any): AxiosPromise<InboxOperatorGroupCreationResponse> {
            return localVarFp.createInboxOperatorGroup(inboxOperatorGroupCreationRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Удалить группу операторов.
         * @param {string} id ИД группы операторов.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteInboxOperatorGroup(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteInboxOperatorGroup(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Получить список групп операторов.
         * @param {string} [searchText] Текст для поиска групп.
         * @param {Array<string>} [authUserIds] Список ИД операторов.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllInboxOperatorGroups(searchText?: string, authUserIds?: Array<string>, options?: any): AxiosPromise<Array<InboxOperatorGroupModel>> {
            return localVarFp.getAllInboxOperatorGroups(searchText, authUserIds, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Получить группу операторов.
         * @param {string} id ИД группы операторов.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInboxOperatorGroup(id: string, options?: any): AxiosPromise<InboxOperatorGroupModel> {
            return localVarFp.getInboxOperatorGroup(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Выполнить поиск групп операторов.
         * @param {string} [searchText] Текст для поиска групп.
         * @param {Array<string>} [authUserIds] Список ИД операторов.
         * @param {number} [pageIndex] 
         * @param {number} [pageSize] 
         * @param {boolean} [withTotal] 
         * @param {number} [fromItemInclusive] 
         * @param {number} [toItemExclusive] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchInboxOperatorGroups(searchText?: string, authUserIds?: Array<string>, pageIndex?: number, pageSize?: number, withTotal?: boolean, fromItemInclusive?: number, toItemExclusive?: number, options?: any): AxiosPromise<InboxOperatorGroupModelPaginationResponse> {
            return localVarFp.searchInboxOperatorGroups(searchText, authUserIds, pageIndex, pageSize, withTotal, fromItemInclusive, toItemExclusive, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Обновить группу операторов.
         * @param {string} id ИД группы операторов.
         * @param {InboxOperatorGroupUpdatingRequest} [inboxOperatorGroupUpdatingRequest] Запрос на обновление группы операторов.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateInboxOperatorGroup(id: string, inboxOperatorGroupUpdatingRequest?: InboxOperatorGroupUpdatingRequest, options?: any): AxiosPromise<void> {
            return localVarFp.updateInboxOperatorGroup(id, inboxOperatorGroupUpdatingRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * InboxOperatorGroupApi - object-oriented interface
 * @export
 * @class InboxOperatorGroupApi
 * @extends {BaseAPI}
 */
export class InboxOperatorGroupApi extends BaseAPI {
    /**
     * 
     * @summary Создать группу операторов.
     * @param {InboxOperatorGroupCreationRequest} [inboxOperatorGroupCreationRequest] Запрос на создание группы операторов.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InboxOperatorGroupApi
     */
    public createInboxOperatorGroup(inboxOperatorGroupCreationRequest?: InboxOperatorGroupCreationRequest, options?: RawAxiosRequestConfig) {
        return InboxOperatorGroupApiFp(this.configuration).createInboxOperatorGroup(inboxOperatorGroupCreationRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Удалить группу операторов.
     * @param {string} id ИД группы операторов.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InboxOperatorGroupApi
     */
    public deleteInboxOperatorGroup(id: string, options?: RawAxiosRequestConfig) {
        return InboxOperatorGroupApiFp(this.configuration).deleteInboxOperatorGroup(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Получить список групп операторов.
     * @param {string} [searchText] Текст для поиска групп.
     * @param {Array<string>} [authUserIds] Список ИД операторов.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InboxOperatorGroupApi
     */
    public getAllInboxOperatorGroups(searchText?: string, authUserIds?: Array<string>, options?: RawAxiosRequestConfig) {
        return InboxOperatorGroupApiFp(this.configuration).getAllInboxOperatorGroups(searchText, authUserIds, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Получить группу операторов.
     * @param {string} id ИД группы операторов.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InboxOperatorGroupApi
     */
    public getInboxOperatorGroup(id: string, options?: RawAxiosRequestConfig) {
        return InboxOperatorGroupApiFp(this.configuration).getInboxOperatorGroup(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Выполнить поиск групп операторов.
     * @param {string} [searchText] Текст для поиска групп.
     * @param {Array<string>} [authUserIds] Список ИД операторов.
     * @param {number} [pageIndex] 
     * @param {number} [pageSize] 
     * @param {boolean} [withTotal] 
     * @param {number} [fromItemInclusive] 
     * @param {number} [toItemExclusive] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InboxOperatorGroupApi
     */
    public searchInboxOperatorGroups(searchText?: string, authUserIds?: Array<string>, pageIndex?: number, pageSize?: number, withTotal?: boolean, fromItemInclusive?: number, toItemExclusive?: number, options?: RawAxiosRequestConfig) {
        return InboxOperatorGroupApiFp(this.configuration).searchInboxOperatorGroups(searchText, authUserIds, pageIndex, pageSize, withTotal, fromItemInclusive, toItemExclusive, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Обновить группу операторов.
     * @param {string} id ИД группы операторов.
     * @param {InboxOperatorGroupUpdatingRequest} [inboxOperatorGroupUpdatingRequest] Запрос на обновление группы операторов.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InboxOperatorGroupApi
     */
    public updateInboxOperatorGroup(id: string, inboxOperatorGroupUpdatingRequest?: InboxOperatorGroupUpdatingRequest, options?: RawAxiosRequestConfig) {
        return InboxOperatorGroupApiFp(this.configuration).updateInboxOperatorGroup(id, inboxOperatorGroupUpdatingRequest, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * InboxParticipantApi - axios parameter creator
 * @export
 */
export const InboxParticipantApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Получить участника, соответствующего текущему пользователю.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInboxMe: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/inbox/participants/me`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth20 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth20", ["elma.assistant.resources.identity.context", "elma.assistant.scopes.api.admin", "email", "offline_access", "openid", "phone", "profile"], configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Получить участника.
         * @param {string} id ИД участника.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInboxParticipant: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getInboxParticipant', 'id', id)
            const localVarPath = `/api/v1/inbox/participants/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth20 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth20", ["elma.assistant.resources.identity.context", "elma.assistant.scopes.api.admin", "email", "offline_access", "openid", "phone", "profile"], configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Получить статус участника в инбоксе.
         * @param {string} id ИД участника.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInboxParticipantStatus: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getInboxParticipantStatus', 'id', id)
            const localVarPath = `/api/v1/inbox/participants/{id}/status`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth20 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth20", ["elma.assistant.resources.identity.context", "elma.assistant.scopes.api.admin", "email", "offline_access", "openid", "phone", "profile"], configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Пригласить участника.
         * @param {InboxParticipantInvitationRequest} [inboxParticipantInvitationRequest] Запрос на приглашение участника.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        inviteInboxParticipant: async (inboxParticipantInvitationRequest?: InboxParticipantInvitationRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/inbox/participants/invite`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth20 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth20", ["elma.assistant.resources.identity.context", "elma.assistant.scopes.api.admin", "email", "offline_access", "openid", "phone", "profile"], configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(inboxParticipantInvitationRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Выполнить поиск участников.
         * @param {string} [searchText] Текст поиска.
         * @param {Array<InboxParticipantStatus>} [statuses] Статус.
         * @param {Array<string>} [subjectIds] ИД субъекта.
         * @param {Array<string>} [subjectRoles] Роль субъекта.
         * @param {number} [maxAssignedChatCount] Максимальное количество назначенных чатов.
         * @param {number} [pageIndex] 
         * @param {number} [pageSize] 
         * @param {boolean} [withTotal] 
         * @param {number} [fromItemInclusive] 
         * @param {number} [toItemExclusive] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchInboxParticipants: async (searchText?: string, statuses?: Array<InboxParticipantStatus>, subjectIds?: Array<string>, subjectRoles?: Array<string>, maxAssignedChatCount?: number, pageIndex?: number, pageSize?: number, withTotal?: boolean, fromItemInclusive?: number, toItemExclusive?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/inbox/participants`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth20 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth20", ["elma.assistant.resources.identity.context", "elma.assistant.scopes.api.admin", "email", "offline_access", "openid", "phone", "profile"], configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (searchText !== undefined) {
                localVarQueryParameter['SearchText'] = searchText;
            }

            if (statuses) {
                localVarQueryParameter['Statuses'] = statuses;
            }

            if (subjectIds) {
                localVarQueryParameter['SubjectIds'] = subjectIds;
            }

            if (subjectRoles) {
                localVarQueryParameter['SubjectRoles'] = subjectRoles;
            }

            if (maxAssignedChatCount !== undefined) {
                localVarQueryParameter['MaxAssignedChatCount'] = maxAssignedChatCount;
            }

            if (pageIndex !== undefined) {
                localVarQueryParameter['PageIndex'] = pageIndex;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['PageSize'] = pageSize;
            }

            if (withTotal !== undefined) {
                localVarQueryParameter['WithTotal'] = withTotal;
            }

            if (fromItemInclusive !== undefined) {
                localVarQueryParameter['FromItemInclusive'] = fromItemInclusive;
            }

            if (toItemExclusive !== undefined) {
                localVarQueryParameter['ToItemExclusive'] = toItemExclusive;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Установить активность участника в инбоксе.
         * @param {string} id ИД участника.
         * @param {InboxParticipantActivityUpdatingRequest} [inboxParticipantActivityUpdatingRequest] Запрос на обновление.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setInboxParticipantActivity: async (id: string, inboxParticipantActivityUpdatingRequest?: InboxParticipantActivityUpdatingRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('setInboxParticipantActivity', 'id', id)
            const localVarPath = `/api/v1/inbox/participants/{id}/activity`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth20 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth20", ["elma.assistant.resources.identity.context", "elma.assistant.scopes.api.admin", "email", "offline_access", "openid", "phone", "profile"], configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(inboxParticipantActivityUpdatingRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Установить статус участника в инбоксе.
         * @param {string} id ИД участника.
         * @param {InboxParticipantStatusUpdatingRequest} [inboxParticipantStatusUpdatingRequest] Запрос на обновление.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setInboxParticipantStatus: async (id: string, inboxParticipantStatusUpdatingRequest?: InboxParticipantStatusUpdatingRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('setInboxParticipantStatus', 'id', id)
            const localVarPath = `/api/v1/inbox/participants/{id}/status`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth20 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth20", ["elma.assistant.resources.identity.context", "elma.assistant.scopes.api.admin", "email", "offline_access", "openid", "phone", "profile"], configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(inboxParticipantStatusUpdatingRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Обновить участника, соответствующего текущему пользователю.
         * @param {InboxParticipantUpdatingRequest} [inboxParticipantUpdatingRequest] Запрос на обновление участника.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateInboxMe: async (inboxParticipantUpdatingRequest?: InboxParticipantUpdatingRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/inbox/participants/me`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth20 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth20", ["elma.assistant.resources.identity.context", "elma.assistant.scopes.api.admin", "email", "offline_access", "openid", "phone", "profile"], configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(inboxParticipantUpdatingRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Обновить участника.
         * @param {string} id ИД участника.
         * @param {InboxParticipantUpdatingRequest} [inboxParticipantUpdatingRequest] Запрос на обновление участника.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateInboxParticipant: async (id: string, inboxParticipantUpdatingRequest?: InboxParticipantUpdatingRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateInboxParticipant', 'id', id)
            const localVarPath = `/api/v1/inbox/participants/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth20 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth20", ["elma.assistant.resources.identity.context", "elma.assistant.scopes.api.admin", "email", "offline_access", "openid", "phone", "profile"], configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(inboxParticipantUpdatingRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * InboxParticipantApi - functional programming interface
 * @export
 */
export const InboxParticipantApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = InboxParticipantApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Получить участника, соответствующего текущему пользователю.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getInboxMe(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InboxParticipantModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getInboxMe(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['InboxParticipantApi.getInboxMe']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Получить участника.
         * @param {string} id ИД участника.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getInboxParticipant(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InboxParticipantModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getInboxParticipant(id, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['InboxParticipantApi.getInboxParticipant']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Получить статус участника в инбоксе.
         * @param {string} id ИД участника.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getInboxParticipantStatus(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InboxParticipantStatus>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getInboxParticipantStatus(id, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['InboxParticipantApi.getInboxParticipantStatus']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Пригласить участника.
         * @param {InboxParticipantInvitationRequest} [inboxParticipantInvitationRequest] Запрос на приглашение участника.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async inviteInboxParticipant(inboxParticipantInvitationRequest?: InboxParticipantInvitationRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InboxParticipantModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.inviteInboxParticipant(inboxParticipantInvitationRequest, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['InboxParticipantApi.inviteInboxParticipant']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Выполнить поиск участников.
         * @param {string} [searchText] Текст поиска.
         * @param {Array<InboxParticipantStatus>} [statuses] Статус.
         * @param {Array<string>} [subjectIds] ИД субъекта.
         * @param {Array<string>} [subjectRoles] Роль субъекта.
         * @param {number} [maxAssignedChatCount] Максимальное количество назначенных чатов.
         * @param {number} [pageIndex] 
         * @param {number} [pageSize] 
         * @param {boolean} [withTotal] 
         * @param {number} [fromItemInclusive] 
         * @param {number} [toItemExclusive] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async searchInboxParticipants(searchText?: string, statuses?: Array<InboxParticipantStatus>, subjectIds?: Array<string>, subjectRoles?: Array<string>, maxAssignedChatCount?: number, pageIndex?: number, pageSize?: number, withTotal?: boolean, fromItemInclusive?: number, toItemExclusive?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InboxParticipantModelPaginationResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.searchInboxParticipants(searchText, statuses, subjectIds, subjectRoles, maxAssignedChatCount, pageIndex, pageSize, withTotal, fromItemInclusive, toItemExclusive, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['InboxParticipantApi.searchInboxParticipants']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Установить активность участника в инбоксе.
         * @param {string} id ИД участника.
         * @param {InboxParticipantActivityUpdatingRequest} [inboxParticipantActivityUpdatingRequest] Запрос на обновление.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async setInboxParticipantActivity(id: string, inboxParticipantActivityUpdatingRequest?: InboxParticipantActivityUpdatingRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.setInboxParticipantActivity(id, inboxParticipantActivityUpdatingRequest, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['InboxParticipantApi.setInboxParticipantActivity']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Установить статус участника в инбоксе.
         * @param {string} id ИД участника.
         * @param {InboxParticipantStatusUpdatingRequest} [inboxParticipantStatusUpdatingRequest] Запрос на обновление.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async setInboxParticipantStatus(id: string, inboxParticipantStatusUpdatingRequest?: InboxParticipantStatusUpdatingRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.setInboxParticipantStatus(id, inboxParticipantStatusUpdatingRequest, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['InboxParticipantApi.setInboxParticipantStatus']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Обновить участника, соответствующего текущему пользователю.
         * @param {InboxParticipantUpdatingRequest} [inboxParticipantUpdatingRequest] Запрос на обновление участника.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateInboxMe(inboxParticipantUpdatingRequest?: InboxParticipantUpdatingRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateInboxMe(inboxParticipantUpdatingRequest, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['InboxParticipantApi.updateInboxMe']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Обновить участника.
         * @param {string} id ИД участника.
         * @param {InboxParticipantUpdatingRequest} [inboxParticipantUpdatingRequest] Запрос на обновление участника.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateInboxParticipant(id: string, inboxParticipantUpdatingRequest?: InboxParticipantUpdatingRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateInboxParticipant(id, inboxParticipantUpdatingRequest, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['InboxParticipantApi.updateInboxParticipant']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
    }
};

/**
 * InboxParticipantApi - factory interface
 * @export
 */
export const InboxParticipantApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = InboxParticipantApiFp(configuration)
    return {
        /**
         * 
         * @summary Получить участника, соответствующего текущему пользователю.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInboxMe(options?: any): AxiosPromise<InboxParticipantModel> {
            return localVarFp.getInboxMe(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Получить участника.
         * @param {string} id ИД участника.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInboxParticipant(id: string, options?: any): AxiosPromise<InboxParticipantModel> {
            return localVarFp.getInboxParticipant(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Получить статус участника в инбоксе.
         * @param {string} id ИД участника.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInboxParticipantStatus(id: string, options?: any): AxiosPromise<InboxParticipantStatus> {
            return localVarFp.getInboxParticipantStatus(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Пригласить участника.
         * @param {InboxParticipantInvitationRequest} [inboxParticipantInvitationRequest] Запрос на приглашение участника.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        inviteInboxParticipant(inboxParticipantInvitationRequest?: InboxParticipantInvitationRequest, options?: any): AxiosPromise<InboxParticipantModel> {
            return localVarFp.inviteInboxParticipant(inboxParticipantInvitationRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Выполнить поиск участников.
         * @param {string} [searchText] Текст поиска.
         * @param {Array<InboxParticipantStatus>} [statuses] Статус.
         * @param {Array<string>} [subjectIds] ИД субъекта.
         * @param {Array<string>} [subjectRoles] Роль субъекта.
         * @param {number} [maxAssignedChatCount] Максимальное количество назначенных чатов.
         * @param {number} [pageIndex] 
         * @param {number} [pageSize] 
         * @param {boolean} [withTotal] 
         * @param {number} [fromItemInclusive] 
         * @param {number} [toItemExclusive] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchInboxParticipants(searchText?: string, statuses?: Array<InboxParticipantStatus>, subjectIds?: Array<string>, subjectRoles?: Array<string>, maxAssignedChatCount?: number, pageIndex?: number, pageSize?: number, withTotal?: boolean, fromItemInclusive?: number, toItemExclusive?: number, options?: any): AxiosPromise<InboxParticipantModelPaginationResponse> {
            return localVarFp.searchInboxParticipants(searchText, statuses, subjectIds, subjectRoles, maxAssignedChatCount, pageIndex, pageSize, withTotal, fromItemInclusive, toItemExclusive, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Установить активность участника в инбоксе.
         * @param {string} id ИД участника.
         * @param {InboxParticipantActivityUpdatingRequest} [inboxParticipantActivityUpdatingRequest] Запрос на обновление.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setInboxParticipantActivity(id: string, inboxParticipantActivityUpdatingRequest?: InboxParticipantActivityUpdatingRequest, options?: any): AxiosPromise<void> {
            return localVarFp.setInboxParticipantActivity(id, inboxParticipantActivityUpdatingRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Установить статус участника в инбоксе.
         * @param {string} id ИД участника.
         * @param {InboxParticipantStatusUpdatingRequest} [inboxParticipantStatusUpdatingRequest] Запрос на обновление.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setInboxParticipantStatus(id: string, inboxParticipantStatusUpdatingRequest?: InboxParticipantStatusUpdatingRequest, options?: any): AxiosPromise<void> {
            return localVarFp.setInboxParticipantStatus(id, inboxParticipantStatusUpdatingRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Обновить участника, соответствующего текущему пользователю.
         * @param {InboxParticipantUpdatingRequest} [inboxParticipantUpdatingRequest] Запрос на обновление участника.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateInboxMe(inboxParticipantUpdatingRequest?: InboxParticipantUpdatingRequest, options?: any): AxiosPromise<void> {
            return localVarFp.updateInboxMe(inboxParticipantUpdatingRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Обновить участника.
         * @param {string} id ИД участника.
         * @param {InboxParticipantUpdatingRequest} [inboxParticipantUpdatingRequest] Запрос на обновление участника.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateInboxParticipant(id: string, inboxParticipantUpdatingRequest?: InboxParticipantUpdatingRequest, options?: any): AxiosPromise<void> {
            return localVarFp.updateInboxParticipant(id, inboxParticipantUpdatingRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * InboxParticipantApi - object-oriented interface
 * @export
 * @class InboxParticipantApi
 * @extends {BaseAPI}
 */
export class InboxParticipantApi extends BaseAPI {
    /**
     * 
     * @summary Получить участника, соответствующего текущему пользователю.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InboxParticipantApi
     */
    public getInboxMe(options?: RawAxiosRequestConfig) {
        return InboxParticipantApiFp(this.configuration).getInboxMe(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Получить участника.
     * @param {string} id ИД участника.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InboxParticipantApi
     */
    public getInboxParticipant(id: string, options?: RawAxiosRequestConfig) {
        return InboxParticipantApiFp(this.configuration).getInboxParticipant(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Получить статус участника в инбоксе.
     * @param {string} id ИД участника.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InboxParticipantApi
     */
    public getInboxParticipantStatus(id: string, options?: RawAxiosRequestConfig) {
        return InboxParticipantApiFp(this.configuration).getInboxParticipantStatus(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Пригласить участника.
     * @param {InboxParticipantInvitationRequest} [inboxParticipantInvitationRequest] Запрос на приглашение участника.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InboxParticipantApi
     */
    public inviteInboxParticipant(inboxParticipantInvitationRequest?: InboxParticipantInvitationRequest, options?: RawAxiosRequestConfig) {
        return InboxParticipantApiFp(this.configuration).inviteInboxParticipant(inboxParticipantInvitationRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Выполнить поиск участников.
     * @param {string} [searchText] Текст поиска.
     * @param {Array<InboxParticipantStatus>} [statuses] Статус.
     * @param {Array<string>} [subjectIds] ИД субъекта.
     * @param {Array<string>} [subjectRoles] Роль субъекта.
     * @param {number} [maxAssignedChatCount] Максимальное количество назначенных чатов.
     * @param {number} [pageIndex] 
     * @param {number} [pageSize] 
     * @param {boolean} [withTotal] 
     * @param {number} [fromItemInclusive] 
     * @param {number} [toItemExclusive] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InboxParticipantApi
     */
    public searchInboxParticipants(searchText?: string, statuses?: Array<InboxParticipantStatus>, subjectIds?: Array<string>, subjectRoles?: Array<string>, maxAssignedChatCount?: number, pageIndex?: number, pageSize?: number, withTotal?: boolean, fromItemInclusive?: number, toItemExclusive?: number, options?: RawAxiosRequestConfig) {
        return InboxParticipantApiFp(this.configuration).searchInboxParticipants(searchText, statuses, subjectIds, subjectRoles, maxAssignedChatCount, pageIndex, pageSize, withTotal, fromItemInclusive, toItemExclusive, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Установить активность участника в инбоксе.
     * @param {string} id ИД участника.
     * @param {InboxParticipantActivityUpdatingRequest} [inboxParticipantActivityUpdatingRequest] Запрос на обновление.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InboxParticipantApi
     */
    public setInboxParticipantActivity(id: string, inboxParticipantActivityUpdatingRequest?: InboxParticipantActivityUpdatingRequest, options?: RawAxiosRequestConfig) {
        return InboxParticipantApiFp(this.configuration).setInboxParticipantActivity(id, inboxParticipantActivityUpdatingRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Установить статус участника в инбоксе.
     * @param {string} id ИД участника.
     * @param {InboxParticipantStatusUpdatingRequest} [inboxParticipantStatusUpdatingRequest] Запрос на обновление.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InboxParticipantApi
     */
    public setInboxParticipantStatus(id: string, inboxParticipantStatusUpdatingRequest?: InboxParticipantStatusUpdatingRequest, options?: RawAxiosRequestConfig) {
        return InboxParticipantApiFp(this.configuration).setInboxParticipantStatus(id, inboxParticipantStatusUpdatingRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Обновить участника, соответствующего текущему пользователю.
     * @param {InboxParticipantUpdatingRequest} [inboxParticipantUpdatingRequest] Запрос на обновление участника.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InboxParticipantApi
     */
    public updateInboxMe(inboxParticipantUpdatingRequest?: InboxParticipantUpdatingRequest, options?: RawAxiosRequestConfig) {
        return InboxParticipantApiFp(this.configuration).updateInboxMe(inboxParticipantUpdatingRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Обновить участника.
     * @param {string} id ИД участника.
     * @param {InboxParticipantUpdatingRequest} [inboxParticipantUpdatingRequest] Запрос на обновление участника.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InboxParticipantApi
     */
    public updateInboxParticipant(id: string, inboxParticipantUpdatingRequest?: InboxParticipantUpdatingRequest, options?: RawAxiosRequestConfig) {
        return InboxParticipantApiFp(this.configuration).updateInboxParticipant(id, inboxParticipantUpdatingRequest, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * InboxRecordApi - axios parameter creator
 * @export
 */
export const InboxRecordApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Получить запись.
         * @param {string} id ИД сообщения.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInboxRecord: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getInboxRecord', 'id', id)
            const localVarPath = `/api/v1/inbox/records/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth20 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth20", ["elma.assistant.resources.identity.context", "elma.assistant.scopes.api.admin", "email", "offline_access", "openid", "phone", "profile"], configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Выполнить поиск записей.
         * @param {InboxDirection} [direction] Направление записи.
         * @param {number} [pageIndex] 
         * @param {number} [pageSize] 
         * @param {boolean} [withTotal] 
         * @param {number} [fromItemInclusive] 
         * @param {number} [toItemExclusive] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchInboxRecords: async (direction?: InboxDirection, pageIndex?: number, pageSize?: number, withTotal?: boolean, fromItemInclusive?: number, toItemExclusive?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/inbox/records`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth20 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth20", ["elma.assistant.resources.identity.context", "elma.assistant.scopes.api.admin", "email", "offline_access", "openid", "phone", "profile"], configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (direction !== undefined) {
                localVarQueryParameter['Direction'] = direction;
            }

            if (pageIndex !== undefined) {
                localVarQueryParameter['PageIndex'] = pageIndex;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['PageSize'] = pageSize;
            }

            if (withTotal !== undefined) {
                localVarQueryParameter['WithTotal'] = withTotal;
            }

            if (fromItemInclusive !== undefined) {
                localVarQueryParameter['FromItemInclusive'] = fromItemInclusive;
            }

            if (toItemExclusive !== undefined) {
                localVarQueryParameter['ToItemExclusive'] = toItemExclusive;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * InboxRecordApi - functional programming interface
 * @export
 */
export const InboxRecordApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = InboxRecordApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Получить запись.
         * @param {string} id ИД сообщения.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getInboxRecord(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InboxRecordModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getInboxRecord(id, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['InboxRecordApi.getInboxRecord']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Выполнить поиск записей.
         * @param {InboxDirection} [direction] Направление записи.
         * @param {number} [pageIndex] 
         * @param {number} [pageSize] 
         * @param {boolean} [withTotal] 
         * @param {number} [fromItemInclusive] 
         * @param {number} [toItemExclusive] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async searchInboxRecords(direction?: InboxDirection, pageIndex?: number, pageSize?: number, withTotal?: boolean, fromItemInclusive?: number, toItemExclusive?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InboxRecordModelPaginationResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.searchInboxRecords(direction, pageIndex, pageSize, withTotal, fromItemInclusive, toItemExclusive, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['InboxRecordApi.searchInboxRecords']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
    }
};

/**
 * InboxRecordApi - factory interface
 * @export
 */
export const InboxRecordApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = InboxRecordApiFp(configuration)
    return {
        /**
         * 
         * @summary Получить запись.
         * @param {string} id ИД сообщения.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInboxRecord(id: string, options?: any): AxiosPromise<InboxRecordModel> {
            return localVarFp.getInboxRecord(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Выполнить поиск записей.
         * @param {InboxDirection} [direction] Направление записи.
         * @param {number} [pageIndex] 
         * @param {number} [pageSize] 
         * @param {boolean} [withTotal] 
         * @param {number} [fromItemInclusive] 
         * @param {number} [toItemExclusive] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchInboxRecords(direction?: InboxDirection, pageIndex?: number, pageSize?: number, withTotal?: boolean, fromItemInclusive?: number, toItemExclusive?: number, options?: any): AxiosPromise<InboxRecordModelPaginationResponse> {
            return localVarFp.searchInboxRecords(direction, pageIndex, pageSize, withTotal, fromItemInclusive, toItemExclusive, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * InboxRecordApi - object-oriented interface
 * @export
 * @class InboxRecordApi
 * @extends {BaseAPI}
 */
export class InboxRecordApi extends BaseAPI {
    /**
     * 
     * @summary Получить запись.
     * @param {string} id ИД сообщения.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InboxRecordApi
     */
    public getInboxRecord(id: string, options?: RawAxiosRequestConfig) {
        return InboxRecordApiFp(this.configuration).getInboxRecord(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Выполнить поиск записей.
     * @param {InboxDirection} [direction] Направление записи.
     * @param {number} [pageIndex] 
     * @param {number} [pageSize] 
     * @param {boolean} [withTotal] 
     * @param {number} [fromItemInclusive] 
     * @param {number} [toItemExclusive] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InboxRecordApi
     */
    public searchInboxRecords(direction?: InboxDirection, pageIndex?: number, pageSize?: number, withTotal?: boolean, fromItemInclusive?: number, toItemExclusive?: number, options?: RawAxiosRequestConfig) {
        return InboxRecordApiFp(this.configuration).searchInboxRecords(direction, pageIndex, pageSize, withTotal, fromItemInclusive, toItemExclusive, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * InboxSessionApi - axios parameter creator
 * @export
 */
export const InboxSessionApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Получить сеанс.
         * @param {string} id ИД сеанса.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInboxSession: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getInboxSession', 'id', id)
            const localVarPath = `/api/v1/inbox/sessions/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth20 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth20", ["elma.assistant.resources.identity.context", "elma.assistant.scopes.api.admin", "email", "offline_access", "openid", "phone", "profile"], configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Выполнить поиск сеансов.
         * @param {InboxSessionStatus} [status] Статус сеанса.
         * @param {string} [chatId] ИД чата.
         * @param {number} [pageIndex] 
         * @param {number} [pageSize] 
         * @param {boolean} [withTotal] 
         * @param {number} [fromItemInclusive] 
         * @param {number} [toItemExclusive] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchInboxSessions: async (status?: InboxSessionStatus, chatId?: string, pageIndex?: number, pageSize?: number, withTotal?: boolean, fromItemInclusive?: number, toItemExclusive?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/inbox/sessions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth20 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth20", ["elma.assistant.resources.identity.context", "elma.assistant.scopes.api.admin", "email", "offline_access", "openid", "phone", "profile"], configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (status !== undefined) {
                localVarQueryParameter['Status'] = status;
            }

            if (chatId !== undefined) {
                localVarQueryParameter['ChatId'] = chatId;
            }

            if (pageIndex !== undefined) {
                localVarQueryParameter['PageIndex'] = pageIndex;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['PageSize'] = pageSize;
            }

            if (withTotal !== undefined) {
                localVarQueryParameter['WithTotal'] = withTotal;
            }

            if (fromItemInclusive !== undefined) {
                localVarQueryParameter['FromItemInclusive'] = fromItemInclusive;
            }

            if (toItemExclusive !== undefined) {
                localVarQueryParameter['ToItemExclusive'] = toItemExclusive;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * InboxSessionApi - functional programming interface
 * @export
 */
export const InboxSessionApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = InboxSessionApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Получить сеанс.
         * @param {string} id ИД сеанса.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getInboxSession(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InboxSessionModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getInboxSession(id, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['InboxSessionApi.getInboxSession']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Выполнить поиск сеансов.
         * @param {InboxSessionStatus} [status] Статус сеанса.
         * @param {string} [chatId] ИД чата.
         * @param {number} [pageIndex] 
         * @param {number} [pageSize] 
         * @param {boolean} [withTotal] 
         * @param {number} [fromItemInclusive] 
         * @param {number} [toItemExclusive] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async searchInboxSessions(status?: InboxSessionStatus, chatId?: string, pageIndex?: number, pageSize?: number, withTotal?: boolean, fromItemInclusive?: number, toItemExclusive?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InboxSessionModelPaginationResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.searchInboxSessions(status, chatId, pageIndex, pageSize, withTotal, fromItemInclusive, toItemExclusive, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['InboxSessionApi.searchInboxSessions']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
    }
};

/**
 * InboxSessionApi - factory interface
 * @export
 */
export const InboxSessionApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = InboxSessionApiFp(configuration)
    return {
        /**
         * 
         * @summary Получить сеанс.
         * @param {string} id ИД сеанса.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInboxSession(id: string, options?: any): AxiosPromise<InboxSessionModel> {
            return localVarFp.getInboxSession(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Выполнить поиск сеансов.
         * @param {InboxSessionStatus} [status] Статус сеанса.
         * @param {string} [chatId] ИД чата.
         * @param {number} [pageIndex] 
         * @param {number} [pageSize] 
         * @param {boolean} [withTotal] 
         * @param {number} [fromItemInclusive] 
         * @param {number} [toItemExclusive] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchInboxSessions(status?: InboxSessionStatus, chatId?: string, pageIndex?: number, pageSize?: number, withTotal?: boolean, fromItemInclusive?: number, toItemExclusive?: number, options?: any): AxiosPromise<InboxSessionModelPaginationResponse> {
            return localVarFp.searchInboxSessions(status, chatId, pageIndex, pageSize, withTotal, fromItemInclusive, toItemExclusive, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * InboxSessionApi - object-oriented interface
 * @export
 * @class InboxSessionApi
 * @extends {BaseAPI}
 */
export class InboxSessionApi extends BaseAPI {
    /**
     * 
     * @summary Получить сеанс.
     * @param {string} id ИД сеанса.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InboxSessionApi
     */
    public getInboxSession(id: string, options?: RawAxiosRequestConfig) {
        return InboxSessionApiFp(this.configuration).getInboxSession(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Выполнить поиск сеансов.
     * @param {InboxSessionStatus} [status] Статус сеанса.
     * @param {string} [chatId] ИД чата.
     * @param {number} [pageIndex] 
     * @param {number} [pageSize] 
     * @param {boolean} [withTotal] 
     * @param {number} [fromItemInclusive] 
     * @param {number} [toItemExclusive] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InboxSessionApi
     */
    public searchInboxSessions(status?: InboxSessionStatus, chatId?: string, pageIndex?: number, pageSize?: number, withTotal?: boolean, fromItemInclusive?: number, toItemExclusive?: number, options?: RawAxiosRequestConfig) {
        return InboxSessionApiFp(this.configuration).searchInboxSessions(status, chatId, pageIndex, pageSize, withTotal, fromItemInclusive, toItemExclusive, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * IntentApi - axios parameter creator
 * @export
 */
export const IntentApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Получить интент.
         * @param {string} id ИД записи интента.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getIntent: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getIntent', 'id', id)
            const localVarPath = `/api/v1/intents/intents/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth20 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth20", ["elma.assistant.resources.identity.context", "elma.assistant.scopes.api.admin", "email", "offline_access", "openid", "phone", "profile"], configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Выполнить поиск интентов.
         * @param {string} [utterance] 
         * @param {string} [groupCode] 
         * @param {string} [intentCode] 
         * @param {string} [semVer] 
         * @param {IntentEditionStatus} [status] 
         * @param {number} [pageIndex] 
         * @param {number} [pageSize] 
         * @param {boolean} [withTotal] 
         * @param {number} [fromItemInclusive] 
         * @param {number} [toItemExclusive] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchIntents: async (utterance?: string, groupCode?: string, intentCode?: string, semVer?: string, status?: IntentEditionStatus, pageIndex?: number, pageSize?: number, withTotal?: boolean, fromItemInclusive?: number, toItemExclusive?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/intents/intents`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth20 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth20", ["elma.assistant.resources.identity.context", "elma.assistant.scopes.api.admin", "email", "offline_access", "openid", "phone", "profile"], configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (utterance !== undefined) {
                localVarQueryParameter['Utterance'] = utterance;
            }

            if (groupCode !== undefined) {
                localVarQueryParameter['GroupCode'] = groupCode;
            }

            if (intentCode !== undefined) {
                localVarQueryParameter['IntentCode'] = intentCode;
            }

            if (semVer !== undefined) {
                localVarQueryParameter['SemVer'] = semVer;
            }

            if (status !== undefined) {
                localVarQueryParameter['Status'] = status;
            }

            if (pageIndex !== undefined) {
                localVarQueryParameter['PageIndex'] = pageIndex;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['PageSize'] = pageSize;
            }

            if (withTotal !== undefined) {
                localVarQueryParameter['WithTotal'] = withTotal;
            }

            if (fromItemInclusive !== undefined) {
                localVarQueryParameter['FromItemInclusive'] = fromItemInclusive;
            }

            if (toItemExclusive !== undefined) {
                localVarQueryParameter['ToItemExclusive'] = toItemExclusive;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * IntentApi - functional programming interface
 * @export
 */
export const IntentApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = IntentApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Получить интент.
         * @param {string} id ИД записи интента.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getIntent(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SingleIntentModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getIntent(id, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['IntentApi.getIntent']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Выполнить поиск интентов.
         * @param {string} [utterance] 
         * @param {string} [groupCode] 
         * @param {string} [intentCode] 
         * @param {string} [semVer] 
         * @param {IntentEditionStatus} [status] 
         * @param {number} [pageIndex] 
         * @param {number} [pageSize] 
         * @param {boolean} [withTotal] 
         * @param {number} [fromItemInclusive] 
         * @param {number} [toItemExclusive] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async searchIntents(utterance?: string, groupCode?: string, intentCode?: string, semVer?: string, status?: IntentEditionStatus, pageIndex?: number, pageSize?: number, withTotal?: boolean, fromItemInclusive?: number, toItemExclusive?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListIntentModelPaginationResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.searchIntents(utterance, groupCode, intentCode, semVer, status, pageIndex, pageSize, withTotal, fromItemInclusive, toItemExclusive, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['IntentApi.searchIntents']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
    }
};

/**
 * IntentApi - factory interface
 * @export
 */
export const IntentApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = IntentApiFp(configuration)
    return {
        /**
         * 
         * @summary Получить интент.
         * @param {string} id ИД записи интента.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getIntent(id: string, options?: any): AxiosPromise<SingleIntentModel> {
            return localVarFp.getIntent(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Выполнить поиск интентов.
         * @param {string} [utterance] 
         * @param {string} [groupCode] 
         * @param {string} [intentCode] 
         * @param {string} [semVer] 
         * @param {IntentEditionStatus} [status] 
         * @param {number} [pageIndex] 
         * @param {number} [pageSize] 
         * @param {boolean} [withTotal] 
         * @param {number} [fromItemInclusive] 
         * @param {number} [toItemExclusive] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchIntents(utterance?: string, groupCode?: string, intentCode?: string, semVer?: string, status?: IntentEditionStatus, pageIndex?: number, pageSize?: number, withTotal?: boolean, fromItemInclusive?: number, toItemExclusive?: number, options?: any): AxiosPromise<ListIntentModelPaginationResponse> {
            return localVarFp.searchIntents(utterance, groupCode, intentCode, semVer, status, pageIndex, pageSize, withTotal, fromItemInclusive, toItemExclusive, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * IntentApi - object-oriented interface
 * @export
 * @class IntentApi
 * @extends {BaseAPI}
 */
export class IntentApi extends BaseAPI {
    /**
     * 
     * @summary Получить интент.
     * @param {string} id ИД записи интента.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IntentApi
     */
    public getIntent(id: string, options?: RawAxiosRequestConfig) {
        return IntentApiFp(this.configuration).getIntent(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Выполнить поиск интентов.
     * @param {string} [utterance] 
     * @param {string} [groupCode] 
     * @param {string} [intentCode] 
     * @param {string} [semVer] 
     * @param {IntentEditionStatus} [status] 
     * @param {number} [pageIndex] 
     * @param {number} [pageSize] 
     * @param {boolean} [withTotal] 
     * @param {number} [fromItemInclusive] 
     * @param {number} [toItemExclusive] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IntentApi
     */
    public searchIntents(utterance?: string, groupCode?: string, intentCode?: string, semVer?: string, status?: IntentEditionStatus, pageIndex?: number, pageSize?: number, withTotal?: boolean, fromItemInclusive?: number, toItemExclusive?: number, options?: RawAxiosRequestConfig) {
        return IntentApiFp(this.configuration).searchIntents(utterance, groupCode, intentCode, semVer, status, pageIndex, pageSize, withTotal, fromItemInclusive, toItemExclusive, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * IntentEditionApi - axios parameter creator
 * @export
 */
export const IntentEditionApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Получить редакцию интента.
         * @param {string} id ИД редакции интента.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getIntentEdition: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getIntentEdition', 'id', id)
            const localVarPath = `/api/v1/intents/intent-editions/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth20 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth20", ["elma.assistant.resources.identity.context", "elma.assistant.scopes.api.admin", "email", "offline_access", "openid", "phone", "profile"], configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Выполнить поиск редакций интента.
         * @param {string} [utterance] 
         * @param {string} [groupCode] 
         * @param {string} [intentCode] 
         * @param {string} [semVer] 
         * @param {IntentEditionStatus} [status] 
         * @param {number} [pageIndex] 
         * @param {number} [pageSize] 
         * @param {boolean} [withTotal] 
         * @param {number} [fromItemInclusive] 
         * @param {number} [toItemExclusive] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchIntentEditions: async (utterance?: string, groupCode?: string, intentCode?: string, semVer?: string, status?: IntentEditionStatus, pageIndex?: number, pageSize?: number, withTotal?: boolean, fromItemInclusive?: number, toItemExclusive?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/intents/intent-editions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth20 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth20", ["elma.assistant.resources.identity.context", "elma.assistant.scopes.api.admin", "email", "offline_access", "openid", "phone", "profile"], configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (utterance !== undefined) {
                localVarQueryParameter['Utterance'] = utterance;
            }

            if (groupCode !== undefined) {
                localVarQueryParameter['GroupCode'] = groupCode;
            }

            if (intentCode !== undefined) {
                localVarQueryParameter['IntentCode'] = intentCode;
            }

            if (semVer !== undefined) {
                localVarQueryParameter['SemVer'] = semVer;
            }

            if (status !== undefined) {
                localVarQueryParameter['Status'] = status;
            }

            if (pageIndex !== undefined) {
                localVarQueryParameter['PageIndex'] = pageIndex;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['PageSize'] = pageSize;
            }

            if (withTotal !== undefined) {
                localVarQueryParameter['WithTotal'] = withTotal;
            }

            if (fromItemInclusive !== undefined) {
                localVarQueryParameter['FromItemInclusive'] = fromItemInclusive;
            }

            if (toItemExclusive !== undefined) {
                localVarQueryParameter['ToItemExclusive'] = toItemExclusive;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * IntentEditionApi - functional programming interface
 * @export
 */
export const IntentEditionApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = IntentEditionApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Получить редакцию интента.
         * @param {string} id ИД редакции интента.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getIntentEdition(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IntentEditionModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getIntentEdition(id, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['IntentEditionApi.getIntentEdition']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Выполнить поиск редакций интента.
         * @param {string} [utterance] 
         * @param {string} [groupCode] 
         * @param {string} [intentCode] 
         * @param {string} [semVer] 
         * @param {IntentEditionStatus} [status] 
         * @param {number} [pageIndex] 
         * @param {number} [pageSize] 
         * @param {boolean} [withTotal] 
         * @param {number} [fromItemInclusive] 
         * @param {number} [toItemExclusive] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async searchIntentEditions(utterance?: string, groupCode?: string, intentCode?: string, semVer?: string, status?: IntentEditionStatus, pageIndex?: number, pageSize?: number, withTotal?: boolean, fromItemInclusive?: number, toItemExclusive?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IntentEditionModelPaginationResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.searchIntentEditions(utterance, groupCode, intentCode, semVer, status, pageIndex, pageSize, withTotal, fromItemInclusive, toItemExclusive, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['IntentEditionApi.searchIntentEditions']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
    }
};

/**
 * IntentEditionApi - factory interface
 * @export
 */
export const IntentEditionApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = IntentEditionApiFp(configuration)
    return {
        /**
         * 
         * @summary Получить редакцию интента.
         * @param {string} id ИД редакции интента.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getIntentEdition(id: string, options?: any): AxiosPromise<IntentEditionModel> {
            return localVarFp.getIntentEdition(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Выполнить поиск редакций интента.
         * @param {string} [utterance] 
         * @param {string} [groupCode] 
         * @param {string} [intentCode] 
         * @param {string} [semVer] 
         * @param {IntentEditionStatus} [status] 
         * @param {number} [pageIndex] 
         * @param {number} [pageSize] 
         * @param {boolean} [withTotal] 
         * @param {number} [fromItemInclusive] 
         * @param {number} [toItemExclusive] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchIntentEditions(utterance?: string, groupCode?: string, intentCode?: string, semVer?: string, status?: IntentEditionStatus, pageIndex?: number, pageSize?: number, withTotal?: boolean, fromItemInclusive?: number, toItemExclusive?: number, options?: any): AxiosPromise<IntentEditionModelPaginationResponse> {
            return localVarFp.searchIntentEditions(utterance, groupCode, intentCode, semVer, status, pageIndex, pageSize, withTotal, fromItemInclusive, toItemExclusive, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * IntentEditionApi - object-oriented interface
 * @export
 * @class IntentEditionApi
 * @extends {BaseAPI}
 */
export class IntentEditionApi extends BaseAPI {
    /**
     * 
     * @summary Получить редакцию интента.
     * @param {string} id ИД редакции интента.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IntentEditionApi
     */
    public getIntentEdition(id: string, options?: RawAxiosRequestConfig) {
        return IntentEditionApiFp(this.configuration).getIntentEdition(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Выполнить поиск редакций интента.
     * @param {string} [utterance] 
     * @param {string} [groupCode] 
     * @param {string} [intentCode] 
     * @param {string} [semVer] 
     * @param {IntentEditionStatus} [status] 
     * @param {number} [pageIndex] 
     * @param {number} [pageSize] 
     * @param {boolean} [withTotal] 
     * @param {number} [fromItemInclusive] 
     * @param {number} [toItemExclusive] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IntentEditionApi
     */
    public searchIntentEditions(utterance?: string, groupCode?: string, intentCode?: string, semVer?: string, status?: IntentEditionStatus, pageIndex?: number, pageSize?: number, withTotal?: boolean, fromItemInclusive?: number, toItemExclusive?: number, options?: RawAxiosRequestConfig) {
        return IntentEditionApiFp(this.configuration).searchIntentEditions(utterance, groupCode, intentCode, semVer, status, pageIndex, pageSize, withTotal, fromItemInclusive, toItemExclusive, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * IntentEntryApi - axios parameter creator
 * @export
 */
export const IntentEntryApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Получить запись интента.
         * @param {string} id ИД записи интента.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getIntentEntry: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getIntentEntry', 'id', id)
            const localVarPath = `/api/v1/intents/intent-entries/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth20 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth20", ["elma.assistant.resources.identity.context", "elma.assistant.scopes.api.admin", "email", "offline_access", "openid", "phone", "profile"], configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Выполнить поиск записей интентов.
         * @param {object} [filtration] Параметры фильтрации.
         * @param {number} [pageIndex] 
         * @param {number} [pageSize] 
         * @param {boolean} [withTotal] 
         * @param {number} [fromItemInclusive] 
         * @param {number} [toItemExclusive] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchIntentEntries: async (filtration?: object, pageIndex?: number, pageSize?: number, withTotal?: boolean, fromItemInclusive?: number, toItemExclusive?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/intents/intent-entries`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth20 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth20", ["elma.assistant.resources.identity.context", "elma.assistant.scopes.api.admin", "email", "offline_access", "openid", "phone", "profile"], configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (filtration !== undefined) {
                for (const [key, value] of Object.entries(filtration)) {
                    localVarQueryParameter[key] = value;
                }
            }

            if (pageIndex !== undefined) {
                localVarQueryParameter['PageIndex'] = pageIndex;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['PageSize'] = pageSize;
            }

            if (withTotal !== undefined) {
                localVarQueryParameter['WithTotal'] = withTotal;
            }

            if (fromItemInclusive !== undefined) {
                localVarQueryParameter['FromItemInclusive'] = fromItemInclusive;
            }

            if (toItemExclusive !== undefined) {
                localVarQueryParameter['ToItemExclusive'] = toItemExclusive;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * IntentEntryApi - functional programming interface
 * @export
 */
export const IntentEntryApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = IntentEntryApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Получить запись интента.
         * @param {string} id ИД записи интента.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getIntentEntry(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IntentEntryModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getIntentEntry(id, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['IntentEntryApi.getIntentEntry']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Выполнить поиск записей интентов.
         * @param {object} [filtration] Параметры фильтрации.
         * @param {number} [pageIndex] 
         * @param {number} [pageSize] 
         * @param {boolean} [withTotal] 
         * @param {number} [fromItemInclusive] 
         * @param {number} [toItemExclusive] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async searchIntentEntries(filtration?: object, pageIndex?: number, pageSize?: number, withTotal?: boolean, fromItemInclusive?: number, toItemExclusive?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IntentEntryModelPaginationResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.searchIntentEntries(filtration, pageIndex, pageSize, withTotal, fromItemInclusive, toItemExclusive, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['IntentEntryApi.searchIntentEntries']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
    }
};

/**
 * IntentEntryApi - factory interface
 * @export
 */
export const IntentEntryApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = IntentEntryApiFp(configuration)
    return {
        /**
         * 
         * @summary Получить запись интента.
         * @param {string} id ИД записи интента.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getIntentEntry(id: string, options?: any): AxiosPromise<IntentEntryModel> {
            return localVarFp.getIntentEntry(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Выполнить поиск записей интентов.
         * @param {object} [filtration] Параметры фильтрации.
         * @param {number} [pageIndex] 
         * @param {number} [pageSize] 
         * @param {boolean} [withTotal] 
         * @param {number} [fromItemInclusive] 
         * @param {number} [toItemExclusive] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchIntentEntries(filtration?: object, pageIndex?: number, pageSize?: number, withTotal?: boolean, fromItemInclusive?: number, toItemExclusive?: number, options?: any): AxiosPromise<IntentEntryModelPaginationResponse> {
            return localVarFp.searchIntentEntries(filtration, pageIndex, pageSize, withTotal, fromItemInclusive, toItemExclusive, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * IntentEntryApi - object-oriented interface
 * @export
 * @class IntentEntryApi
 * @extends {BaseAPI}
 */
export class IntentEntryApi extends BaseAPI {
    /**
     * 
     * @summary Получить запись интента.
     * @param {string} id ИД записи интента.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IntentEntryApi
     */
    public getIntentEntry(id: string, options?: RawAxiosRequestConfig) {
        return IntentEntryApiFp(this.configuration).getIntentEntry(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Выполнить поиск записей интентов.
     * @param {object} [filtration] Параметры фильтрации.
     * @param {number} [pageIndex] 
     * @param {number} [pageSize] 
     * @param {boolean} [withTotal] 
     * @param {number} [fromItemInclusive] 
     * @param {number} [toItemExclusive] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IntentEntryApi
     */
    public searchIntentEntries(filtration?: object, pageIndex?: number, pageSize?: number, withTotal?: boolean, fromItemInclusive?: number, toItemExclusive?: number, options?: RawAxiosRequestConfig) {
        return IntentEntryApiFp(this.configuration).searchIntentEntries(filtration, pageIndex, pageSize, withTotal, fromItemInclusive, toItemExclusive, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * LiveChatApi - axios parameter creator
 * @export
 */
export const LiveChatApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Получить действия беседы.
         * @param {string} botUri URI аккаунта стадии бота.
         * @param {string} conversationId ИД беседы.
         * @param {string} [watermark] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLcActivities: async (botUri: string, conversationId: string, watermark?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'botUri' is not null or undefined
            assertParamExists('getLcActivities', 'botUri', botUri)
            // verify required parameter 'conversationId' is not null or undefined
            assertParamExists('getLcActivities', 'conversationId', conversationId)
            const localVarPath = `/api/v1/livechat/{botUri}/conversations/{conversationId}/activities`
                .replace(`{${"botUri"}}`, encodeURIComponent(String(botUri)))
                .replace(`{${"conversationId"}}`, encodeURIComponent(String(conversationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth20 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth20", ["elma.assistant.resources.identity.context", "elma.assistant.scopes.api.admin", "email", "offline_access", "openid", "phone", "profile"], configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (watermark !== undefined) {
                localVarQueryParameter['Watermark'] = watermark;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Получить сведения о беседе.
         * @param {string} botUri URI аккаунта стадии бота.
         * @param {string} conversationId ИД беседы.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLcConversation: async (botUri: string, conversationId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'botUri' is not null or undefined
            assertParamExists('getLcConversation', 'botUri', botUri)
            // verify required parameter 'conversationId' is not null or undefined
            assertParamExists('getLcConversation', 'conversationId', conversationId)
            const localVarPath = `/api/v1/livechat/{botUri}/conversations/{conversationId}`
                .replace(`{${"botUri"}}`, encodeURIComponent(String(botUri)))
                .replace(`{${"conversationId"}}`, encodeURIComponent(String(conversationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth20 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth20", ["elma.assistant.resources.identity.context", "elma.assistant.scopes.api.admin", "email", "offline_access", "openid", "phone", "profile"], configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Обновить токен.
         * @param {string} botUri URI аккаунта стадии бота.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        refreshLcToken: async (botUri: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'botUri' is not null or undefined
            assertParamExists('refreshLcToken', 'botUri', botUri)
            const localVarPath = `/api/v1/livechat/{botUri}/tokens/refresh`
                .replace(`{${"botUri"}}`, encodeURIComponent(String(botUri)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth20 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth20", ["elma.assistant.resources.identity.context", "elma.assistant.scopes.api.admin", "email", "offline_access", "openid", "phone", "profile"], configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Сохранить действие в беседу (для ответов от бота).
         * @param {string} botUri URI аккаунта стадии бота.
         * @param {string} conversationId ИД беседы.
         * @param {Activity} [activity] Запрос на сохранение действия.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveLcBotActivity: async (botUri: string, conversationId: string, activity?: Activity, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'botUri' is not null or undefined
            assertParamExists('saveLcBotActivity', 'botUri', botUri)
            // verify required parameter 'conversationId' is not null or undefined
            assertParamExists('saveLcBotActivity', 'conversationId', conversationId)
            const localVarPath = `/api/v1/livechat/{botUri}/v3/conversations/{conversationId}/activities`
                .replace(`{${"botUri"}}`, encodeURIComponent(String(botUri)))
                .replace(`{${"conversationId"}}`, encodeURIComponent(String(conversationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth20 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth20", ["elma.assistant.resources.identity.context", "elma.assistant.scopes.api.admin", "email", "offline_access", "openid", "phone", "profile"], configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(activity, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Сохранить действие в беседу (для ответов от бота).
         * @param {string} botUri URI аккаунта стадии бота.
         * @param {string} conversationId ИД беседы.
         * @param {string} replyToId ИД действия, на который пришел ответ.
         * @param {Activity} [activity] Запрос на сохранение действия.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveLcBotActivityWithReply: async (botUri: string, conversationId: string, replyToId: string, activity?: Activity, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'botUri' is not null or undefined
            assertParamExists('saveLcBotActivityWithReply', 'botUri', botUri)
            // verify required parameter 'conversationId' is not null or undefined
            assertParamExists('saveLcBotActivityWithReply', 'conversationId', conversationId)
            // verify required parameter 'replyToId' is not null or undefined
            assertParamExists('saveLcBotActivityWithReply', 'replyToId', replyToId)
            const localVarPath = `/api/v1/livechat/{botUri}/v3/conversations/{conversationId}/activities/{replyToId}`
                .replace(`{${"botUri"}}`, encodeURIComponent(String(botUri)))
                .replace(`{${"conversationId"}}`, encodeURIComponent(String(conversationId)))
                .replace(`{${"replyToId"}}`, encodeURIComponent(String(replyToId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth20 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth20", ["elma.assistant.resources.identity.context", "elma.assistant.scopes.api.admin", "email", "offline_access", "openid", "phone", "profile"], configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(activity, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Отправить действие в беседу (для запросов от пользователя).
         * @param {string} botUri URI аккаунта стадии бота.
         * @param {string} conversationId ИД беседы.
         * @param {Activity} [activity] Запрос на отправку действия в беседу.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendLcUserActivity: async (botUri: string, conversationId: string, activity?: Activity, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'botUri' is not null or undefined
            assertParamExists('sendLcUserActivity', 'botUri', botUri)
            // verify required parameter 'conversationId' is not null or undefined
            assertParamExists('sendLcUserActivity', 'conversationId', conversationId)
            const localVarPath = `/api/v1/livechat/{botUri}/conversations/{conversationId}/activities`
                .replace(`{${"botUri"}}`, encodeURIComponent(String(botUri)))
                .replace(`{${"conversationId"}}`, encodeURIComponent(String(conversationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth20 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth20", ["elma.assistant.resources.identity.context", "elma.assistant.scopes.api.admin", "email", "offline_access", "openid", "phone", "profile"], configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(activity, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Отправить файл в беседу.
         * @param {string} botUri URI аккаунта стадии бота.
         * @param {string} conversationId ИД беседы.
         * @param {string} [userId] ИД пользователя.
         * @param {Array<File>} [file] 
         * @param {File} [activity] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadLcFile: async (botUri: string, conversationId: string, userId?: string, file?: Array<File>, activity?: File, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'botUri' is not null or undefined
            assertParamExists('uploadLcFile', 'botUri', botUri)
            // verify required parameter 'conversationId' is not null or undefined
            assertParamExists('uploadLcFile', 'conversationId', conversationId)
            const localVarPath = `/api/v1/livechat/{botUri}/conversations/{conversationId}/upload`
                .replace(`{${"botUri"}}`, encodeURIComponent(String(botUri)))
                .replace(`{${"conversationId"}}`, encodeURIComponent(String(conversationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication OAuth20 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth20", ["elma.assistant.resources.identity.context", "elma.assistant.scopes.api.admin", "email", "offline_access", "openid", "phone", "profile"], configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (userId !== undefined) {
                localVarQueryParameter['userId'] = userId;
            }

            if (file) {
                file.forEach((element) => {
                    localVarFormParams.append('file', element as any);
                })
            }

    
            if (activity !== undefined) { 
                localVarFormParams.append('activity', activity as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * LiveChatApi - functional programming interface
 * @export
 */
export const LiveChatApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = LiveChatApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Получить действия беседы.
         * @param {string} botUri URI аккаунта стадии бота.
         * @param {string} conversationId ИД беседы.
         * @param {string} [watermark] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getLcActivities(botUri: string, conversationId: string, watermark?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ActivitySet>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getLcActivities(botUri, conversationId, watermark, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['LiveChatApi.getLcActivities']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Получить сведения о беседе.
         * @param {string} botUri URI аккаунта стадии бота.
         * @param {string} conversationId ИД беседы.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getLcConversation(botUri: string, conversationId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Conversation>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getLcConversation(botUri, conversationId, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['LiveChatApi.getLcConversation']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Обновить токен.
         * @param {string} botUri URI аккаунта стадии бота.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async refreshLcToken(botUri: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Conversation>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.refreshLcToken(botUri, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['LiveChatApi.refreshLcToken']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Сохранить действие в беседу (для ответов от бота).
         * @param {string} botUri URI аккаунта стадии бота.
         * @param {string} conversationId ИД беседы.
         * @param {Activity} [activity] Запрос на сохранение действия.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async saveLcBotActivity(botUri: string, conversationId: string, activity?: Activity, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResourceResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.saveLcBotActivity(botUri, conversationId, activity, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['LiveChatApi.saveLcBotActivity']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Сохранить действие в беседу (для ответов от бота).
         * @param {string} botUri URI аккаунта стадии бота.
         * @param {string} conversationId ИД беседы.
         * @param {string} replyToId ИД действия, на который пришел ответ.
         * @param {Activity} [activity] Запрос на сохранение действия.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async saveLcBotActivityWithReply(botUri: string, conversationId: string, replyToId: string, activity?: Activity, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResourceResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.saveLcBotActivityWithReply(botUri, conversationId, replyToId, activity, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['LiveChatApi.saveLcBotActivityWithReply']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Отправить действие в беседу (для запросов от пользователя).
         * @param {string} botUri URI аккаунта стадии бота.
         * @param {string} conversationId ИД беседы.
         * @param {Activity} [activity] Запрос на отправку действия в беседу.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sendLcUserActivity(botUri: string, conversationId: string, activity?: Activity, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResourceResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.sendLcUserActivity(botUri, conversationId, activity, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['LiveChatApi.sendLcUserActivity']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Отправить файл в беседу.
         * @param {string} botUri URI аккаунта стадии бота.
         * @param {string} conversationId ИД беседы.
         * @param {string} [userId] ИД пользователя.
         * @param {Array<File>} [file] 
         * @param {File} [activity] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async uploadLcFile(botUri: string, conversationId: string, userId?: string, file?: Array<File>, activity?: File, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResourceResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.uploadLcFile(botUri, conversationId, userId, file, activity, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['LiveChatApi.uploadLcFile']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
    }
};

/**
 * LiveChatApi - factory interface
 * @export
 */
export const LiveChatApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = LiveChatApiFp(configuration)
    return {
        /**
         * 
         * @summary Получить действия беседы.
         * @param {string} botUri URI аккаунта стадии бота.
         * @param {string} conversationId ИД беседы.
         * @param {string} [watermark] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLcActivities(botUri: string, conversationId: string, watermark?: string, options?: any): AxiosPromise<ActivitySet> {
            return localVarFp.getLcActivities(botUri, conversationId, watermark, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Получить сведения о беседе.
         * @param {string} botUri URI аккаунта стадии бота.
         * @param {string} conversationId ИД беседы.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLcConversation(botUri: string, conversationId: string, options?: any): AxiosPromise<Conversation> {
            return localVarFp.getLcConversation(botUri, conversationId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Обновить токен.
         * @param {string} botUri URI аккаунта стадии бота.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        refreshLcToken(botUri: string, options?: any): AxiosPromise<Conversation> {
            return localVarFp.refreshLcToken(botUri, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Сохранить действие в беседу (для ответов от бота).
         * @param {string} botUri URI аккаунта стадии бота.
         * @param {string} conversationId ИД беседы.
         * @param {Activity} [activity] Запрос на сохранение действия.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveLcBotActivity(botUri: string, conversationId: string, activity?: Activity, options?: any): AxiosPromise<ResourceResponse> {
            return localVarFp.saveLcBotActivity(botUri, conversationId, activity, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Сохранить действие в беседу (для ответов от бота).
         * @param {string} botUri URI аккаунта стадии бота.
         * @param {string} conversationId ИД беседы.
         * @param {string} replyToId ИД действия, на который пришел ответ.
         * @param {Activity} [activity] Запрос на сохранение действия.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveLcBotActivityWithReply(botUri: string, conversationId: string, replyToId: string, activity?: Activity, options?: any): AxiosPromise<ResourceResponse> {
            return localVarFp.saveLcBotActivityWithReply(botUri, conversationId, replyToId, activity, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Отправить действие в беседу (для запросов от пользователя).
         * @param {string} botUri URI аккаунта стадии бота.
         * @param {string} conversationId ИД беседы.
         * @param {Activity} [activity] Запрос на отправку действия в беседу.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendLcUserActivity(botUri: string, conversationId: string, activity?: Activity, options?: any): AxiosPromise<ResourceResponse> {
            return localVarFp.sendLcUserActivity(botUri, conversationId, activity, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Отправить файл в беседу.
         * @param {string} botUri URI аккаунта стадии бота.
         * @param {string} conversationId ИД беседы.
         * @param {string} [userId] ИД пользователя.
         * @param {Array<File>} [file] 
         * @param {File} [activity] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadLcFile(botUri: string, conversationId: string, userId?: string, file?: Array<File>, activity?: File, options?: any): AxiosPromise<ResourceResponse> {
            return localVarFp.uploadLcFile(botUri, conversationId, userId, file, activity, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * LiveChatApi - object-oriented interface
 * @export
 * @class LiveChatApi
 * @extends {BaseAPI}
 */
export class LiveChatApi extends BaseAPI {
    /**
     * 
     * @summary Получить действия беседы.
     * @param {string} botUri URI аккаунта стадии бота.
     * @param {string} conversationId ИД беседы.
     * @param {string} [watermark] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LiveChatApi
     */
    public getLcActivities(botUri: string, conversationId: string, watermark?: string, options?: RawAxiosRequestConfig) {
        return LiveChatApiFp(this.configuration).getLcActivities(botUri, conversationId, watermark, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Получить сведения о беседе.
     * @param {string} botUri URI аккаунта стадии бота.
     * @param {string} conversationId ИД беседы.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LiveChatApi
     */
    public getLcConversation(botUri: string, conversationId: string, options?: RawAxiosRequestConfig) {
        return LiveChatApiFp(this.configuration).getLcConversation(botUri, conversationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Обновить токен.
     * @param {string} botUri URI аккаунта стадии бота.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LiveChatApi
     */
    public refreshLcToken(botUri: string, options?: RawAxiosRequestConfig) {
        return LiveChatApiFp(this.configuration).refreshLcToken(botUri, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Сохранить действие в беседу (для ответов от бота).
     * @param {string} botUri URI аккаунта стадии бота.
     * @param {string} conversationId ИД беседы.
     * @param {Activity} [activity] Запрос на сохранение действия.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LiveChatApi
     */
    public saveLcBotActivity(botUri: string, conversationId: string, activity?: Activity, options?: RawAxiosRequestConfig) {
        return LiveChatApiFp(this.configuration).saveLcBotActivity(botUri, conversationId, activity, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Сохранить действие в беседу (для ответов от бота).
     * @param {string} botUri URI аккаунта стадии бота.
     * @param {string} conversationId ИД беседы.
     * @param {string} replyToId ИД действия, на который пришел ответ.
     * @param {Activity} [activity] Запрос на сохранение действия.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LiveChatApi
     */
    public saveLcBotActivityWithReply(botUri: string, conversationId: string, replyToId: string, activity?: Activity, options?: RawAxiosRequestConfig) {
        return LiveChatApiFp(this.configuration).saveLcBotActivityWithReply(botUri, conversationId, replyToId, activity, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Отправить действие в беседу (для запросов от пользователя).
     * @param {string} botUri URI аккаунта стадии бота.
     * @param {string} conversationId ИД беседы.
     * @param {Activity} [activity] Запрос на отправку действия в беседу.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LiveChatApi
     */
    public sendLcUserActivity(botUri: string, conversationId: string, activity?: Activity, options?: RawAxiosRequestConfig) {
        return LiveChatApiFp(this.configuration).sendLcUserActivity(botUri, conversationId, activity, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Отправить файл в беседу.
     * @param {string} botUri URI аккаунта стадии бота.
     * @param {string} conversationId ИД беседы.
     * @param {string} [userId] ИД пользователя.
     * @param {Array<File>} [file] 
     * @param {File} [activity] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LiveChatApi
     */
    public uploadLcFile(botUri: string, conversationId: string, userId?: string, file?: Array<File>, activity?: File, options?: RawAxiosRequestConfig) {
        return LiveChatApiFp(this.configuration).uploadLcFile(botUri, conversationId, userId, file, activity, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * LiveChatAccountsApi - axios parameter creator
 * @export
 */
export const LiveChatAccountsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Сгенерировать токен.
         * @param {string} agentStageAccountId ИД аккаунта бота.
         * @param {LiveChatTokenRequest} [liveChatTokenRequest] Параметры запроса.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generateLcToken: async (agentStageAccountId: string, liveChatTokenRequest?: LiveChatTokenRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'agentStageAccountId' is not null or undefined
            assertParamExists('generateLcToken', 'agentStageAccountId', agentStageAccountId)
            const localVarPath = `/api/v1/livechat/accounts/{agentStageAccountId}/tokens`
                .replace(`{${"agentStageAccountId"}}`, encodeURIComponent(String(agentStageAccountId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(liveChatTokenRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Получить настройки аккаунта бота для канала LiveChat.
         * @param {string} agentStageAccountId ИД аккаунта бота.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLcSettings: async (agentStageAccountId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'agentStageAccountId' is not null or undefined
            assertParamExists('getLcSettings', 'agentStageAccountId', agentStageAccountId)
            const localVarPath = `/api/v1/livechat/accounts/{agentStageAccountId}/settings`
                .replace(`{${"agentStageAccountId"}}`, encodeURIComponent(String(agentStageAccountId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * LiveChatAccountsApi - functional programming interface
 * @export
 */
export const LiveChatAccountsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = LiveChatAccountsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Сгенерировать токен.
         * @param {string} agentStageAccountId ИД аккаунта бота.
         * @param {LiveChatTokenRequest} [liveChatTokenRequest] Параметры запроса.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async generateLcToken(agentStageAccountId: string, liveChatTokenRequest?: LiveChatTokenRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LiveChatTokenModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.generateLcToken(agentStageAccountId, liveChatTokenRequest, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['LiveChatAccountsApi.generateLcToken']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Получить настройки аккаунта бота для канала LiveChat.
         * @param {string} agentStageAccountId ИД аккаунта бота.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getLcSettings(agentStageAccountId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LiveChatSettingsModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getLcSettings(agentStageAccountId, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['LiveChatAccountsApi.getLcSettings']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
    }
};

/**
 * LiveChatAccountsApi - factory interface
 * @export
 */
export const LiveChatAccountsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = LiveChatAccountsApiFp(configuration)
    return {
        /**
         * 
         * @summary Сгенерировать токен.
         * @param {string} agentStageAccountId ИД аккаунта бота.
         * @param {LiveChatTokenRequest} [liveChatTokenRequest] Параметры запроса.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generateLcToken(agentStageAccountId: string, liveChatTokenRequest?: LiveChatTokenRequest, options?: any): AxiosPromise<LiveChatTokenModel> {
            return localVarFp.generateLcToken(agentStageAccountId, liveChatTokenRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Получить настройки аккаунта бота для канала LiveChat.
         * @param {string} agentStageAccountId ИД аккаунта бота.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLcSettings(agentStageAccountId: string, options?: any): AxiosPromise<LiveChatSettingsModel> {
            return localVarFp.getLcSettings(agentStageAccountId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * LiveChatAccountsApi - object-oriented interface
 * @export
 * @class LiveChatAccountsApi
 * @extends {BaseAPI}
 */
export class LiveChatAccountsApi extends BaseAPI {
    /**
     * 
     * @summary Сгенерировать токен.
     * @param {string} agentStageAccountId ИД аккаунта бота.
     * @param {LiveChatTokenRequest} [liveChatTokenRequest] Параметры запроса.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LiveChatAccountsApi
     */
    public generateLcToken(agentStageAccountId: string, liveChatTokenRequest?: LiveChatTokenRequest, options?: RawAxiosRequestConfig) {
        return LiveChatAccountsApiFp(this.configuration).generateLcToken(agentStageAccountId, liveChatTokenRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Получить настройки аккаунта бота для канала LiveChat.
     * @param {string} agentStageAccountId ИД аккаунта бота.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LiveChatAccountsApi
     */
    public getLcSettings(agentStageAccountId: string, options?: RawAxiosRequestConfig) {
        return LiveChatAccountsApiFp(this.configuration).getLcSettings(agentStageAccountId, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * LiveChatConversationStateApi - axios parameter creator
 * @export
 */
export const LiveChatConversationStateApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Создать состояние беседы.
         * @param {string} botUri URI аккаунта стадии бота.
         * @param {LiveChatConversationStateCreationRequest} [liveChatConversationStateCreationRequest] Запрос на создание состояния беседы.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createLiveChatConversationState: async (botUri: string, liveChatConversationStateCreationRequest?: LiveChatConversationStateCreationRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'botUri' is not null or undefined
            assertParamExists('createLiveChatConversationState', 'botUri', botUri)
            const localVarPath = `/api/v1/livechat/{botUri}/conversations/states`
                .replace(`{${"botUri"}}`, encodeURIComponent(String(botUri)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth20 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth20", ["elma.assistant.resources.identity.context", "elma.assistant.scopes.api.admin", "email", "offline_access", "openid", "phone", "profile"], configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(liveChatConversationStateCreationRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * LiveChatConversationStateApi - functional programming interface
 * @export
 */
export const LiveChatConversationStateApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = LiveChatConversationStateApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Создать состояние беседы.
         * @param {string} botUri URI аккаунта стадии бота.
         * @param {LiveChatConversationStateCreationRequest} [liveChatConversationStateCreationRequest] Запрос на создание состояния беседы.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createLiveChatConversationState(botUri: string, liveChatConversationStateCreationRequest?: LiveChatConversationStateCreationRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createLiveChatConversationState(botUri, liveChatConversationStateCreationRequest, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['LiveChatConversationStateApi.createLiveChatConversationState']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
    }
};

/**
 * LiveChatConversationStateApi - factory interface
 * @export
 */
export const LiveChatConversationStateApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = LiveChatConversationStateApiFp(configuration)
    return {
        /**
         * 
         * @summary Создать состояние беседы.
         * @param {string} botUri URI аккаунта стадии бота.
         * @param {LiveChatConversationStateCreationRequest} [liveChatConversationStateCreationRequest] Запрос на создание состояния беседы.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createLiveChatConversationState(botUri: string, liveChatConversationStateCreationRequest?: LiveChatConversationStateCreationRequest, options?: any): AxiosPromise<object> {
            return localVarFp.createLiveChatConversationState(botUri, liveChatConversationStateCreationRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * LiveChatConversationStateApi - object-oriented interface
 * @export
 * @class LiveChatConversationStateApi
 * @extends {BaseAPI}
 */
export class LiveChatConversationStateApi extends BaseAPI {
    /**
     * 
     * @summary Создать состояние беседы.
     * @param {string} botUri URI аккаунта стадии бота.
     * @param {LiveChatConversationStateCreationRequest} [liveChatConversationStateCreationRequest] Запрос на создание состояния беседы.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LiveChatConversationStateApi
     */
    public createLiveChatConversationState(botUri: string, liveChatConversationStateCreationRequest?: LiveChatConversationStateCreationRequest, options?: RawAxiosRequestConfig) {
        return LiveChatConversationStateApiFp(this.configuration).createLiveChatConversationState(botUri, liveChatConversationStateCreationRequest, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * LiveChatStreamApi - axios parameter creator
 * @export
 */
export const LiveChatStreamApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Выполнить потоковую передачу (подключить WebSockets).
         * @param {string} botUri URI бота.
         * @param {string} conversationId ИД беседы.
         * @param {string} [watermark] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        streamLcConversation: async (botUri: string, conversationId: string, watermark?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'botUri' is not null or undefined
            assertParamExists('streamLcConversation', 'botUri', botUri)
            // verify required parameter 'conversationId' is not null or undefined
            assertParamExists('streamLcConversation', 'conversationId', conversationId)
            const localVarPath = `/api/v1/livechat/streams/{botUri}/conversations/{conversationId}`
                .replace(`{${"botUri"}}`, encodeURIComponent(String(botUri)))
                .replace(`{${"conversationId"}}`, encodeURIComponent(String(conversationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (watermark !== undefined) {
                localVarQueryParameter['Watermark'] = watermark;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * LiveChatStreamApi - functional programming interface
 * @export
 */
export const LiveChatStreamApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = LiveChatStreamApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Выполнить потоковую передачу (подключить WebSockets).
         * @param {string} botUri URI бота.
         * @param {string} conversationId ИД беседы.
         * @param {string} [watermark] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async streamLcConversation(botUri: string, conversationId: string, watermark?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.streamLcConversation(botUri, conversationId, watermark, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['LiveChatStreamApi.streamLcConversation']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
    }
};

/**
 * LiveChatStreamApi - factory interface
 * @export
 */
export const LiveChatStreamApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = LiveChatStreamApiFp(configuration)
    return {
        /**
         * 
         * @summary Выполнить потоковую передачу (подключить WebSockets).
         * @param {string} botUri URI бота.
         * @param {string} conversationId ИД беседы.
         * @param {string} [watermark] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        streamLcConversation(botUri: string, conversationId: string, watermark?: string, options?: any): AxiosPromise<void> {
            return localVarFp.streamLcConversation(botUri, conversationId, watermark, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * LiveChatStreamApi - object-oriented interface
 * @export
 * @class LiveChatStreamApi
 * @extends {BaseAPI}
 */
export class LiveChatStreamApi extends BaseAPI {
    /**
     * 
     * @summary Выполнить потоковую передачу (подключить WebSockets).
     * @param {string} botUri URI бота.
     * @param {string} conversationId ИД беседы.
     * @param {string} [watermark] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LiveChatStreamApi
     */
    public streamLcConversation(botUri: string, conversationId: string, watermark?: string, options?: RawAxiosRequestConfig) {
        return LiveChatStreamApiFp(this.configuration).streamLcConversation(botUri, conversationId, watermark, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * PersonApi - axios parameter creator
 * @export
 */
export const PersonApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Создать контакт.
         * @param {PersonCreationRequest} [personCreationRequest] Запрос на создание контакта.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createPerson: async (personCreationRequest?: PersonCreationRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/persons`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth20 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth20", ["elma.assistant.resources.identity.context", "elma.assistant.scopes.api.admin", "email", "offline_access", "openid", "phone", "profile"], configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(personCreationRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Удалить контакт.
         * @param {string} id ИД контакта.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deletePerson: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deletePerson', 'id', id)
            const localVarPath = `/api/v1/persons/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth20 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth20", ["elma.assistant.resources.identity.context", "elma.assistant.scopes.api.admin", "email", "offline_access", "openid", "phone", "profile"], configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Получить контакт.
         * @param {string} id ИД контакта.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPerson: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getPerson', 'id', id)
            const localVarPath = `/api/v1/persons/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth20 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth20", ["elma.assistant.resources.identity.context", "elma.assistant.scopes.api.admin", "email", "offline_access", "openid", "phone", "profile"], configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Объединить контакты.
         * @param {PersonMergingRequest} [personMergingRequest] Запрос на объединение контактов.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mergePersons: async (personMergingRequest?: PersonMergingRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/persons/merge`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth20 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth20", ["elma.assistant.resources.identity.context", "elma.assistant.scopes.api.admin", "email", "offline_access", "openid", "phone", "profile"], configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(personMergingRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Выполнить поиск контактов.
         * @param {string} [search] Текст.
         * @param {Array<string>} [tagIds] Список идентификаторов тэгов.
         * @param {number} [pageIndex] 
         * @param {number} [pageSize] 
         * @param {boolean} [withTotal] 
         * @param {number} [fromItemInclusive] 
         * @param {number} [toItemExclusive] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchPersons: async (search?: string, tagIds?: Array<string>, pageIndex?: number, pageSize?: number, withTotal?: boolean, fromItemInclusive?: number, toItemExclusive?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/persons/search`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth20 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth20", ["elma.assistant.resources.identity.context", "elma.assistant.scopes.api.admin", "email", "offline_access", "openid", "phone", "profile"], configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (search !== undefined) {
                localVarQueryParameter['Search'] = search;
            }

            if (tagIds) {
                localVarQueryParameter['TagIds'] = tagIds;
            }

            if (pageIndex !== undefined) {
                localVarQueryParameter['PageIndex'] = pageIndex;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['PageSize'] = pageSize;
            }

            if (withTotal !== undefined) {
                localVarQueryParameter['WithTotal'] = withTotal;
            }

            if (fromItemInclusive !== undefined) {
                localVarQueryParameter['FromItemInclusive'] = fromItemInclusive;
            }

            if (toItemExclusive !== undefined) {
                localVarQueryParameter['ToItemExclusive'] = toItemExclusive;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Обновить контакт.
         * @param {string} id ИД контакта.
         * @param {PersonUpdatingRequest} [personUpdatingRequest] Запрос на обновление контакта.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePerson: async (id: string, personUpdatingRequest?: PersonUpdatingRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updatePerson', 'id', id)
            const localVarPath = `/api/v1/persons/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth20 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth20", ["elma.assistant.resources.identity.context", "elma.assistant.scopes.api.admin", "email", "offline_access", "openid", "phone", "profile"], configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(personUpdatingRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Обновить список тегов контакта.
         * @param {string} id ИД контакта.
         * @param {PersonTagsUpdatingRequest} [personTagsUpdatingRequest] Запрос на обновление тегов контакта.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePersonTags: async (id: string, personTagsUpdatingRequest?: PersonTagsUpdatingRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updatePersonTags', 'id', id)
            const localVarPath = `/api/v1/persons/{id}/tags`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth20 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth20", ["elma.assistant.resources.identity.context", "elma.assistant.scopes.api.admin", "email", "offline_access", "openid", "phone", "profile"], configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(personTagsUpdatingRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PersonApi - functional programming interface
 * @export
 */
export const PersonApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PersonApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Создать контакт.
         * @param {PersonCreationRequest} [personCreationRequest] Запрос на создание контакта.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createPerson(personCreationRequest?: PersonCreationRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PersonCreationResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createPerson(personCreationRequest, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['PersonApi.createPerson']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Удалить контакт.
         * @param {string} id ИД контакта.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deletePerson(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deletePerson(id, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['PersonApi.deletePerson']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Получить контакт.
         * @param {string} id ИД контакта.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPerson(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PersonModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPerson(id, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['PersonApi.getPerson']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Объединить контакты.
         * @param {PersonMergingRequest} [personMergingRequest] Запрос на объединение контактов.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async mergePersons(personMergingRequest?: PersonMergingRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.mergePersons(personMergingRequest, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['PersonApi.mergePersons']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Выполнить поиск контактов.
         * @param {string} [search] Текст.
         * @param {Array<string>} [tagIds] Список идентификаторов тэгов.
         * @param {number} [pageIndex] 
         * @param {number} [pageSize] 
         * @param {boolean} [withTotal] 
         * @param {number} [fromItemInclusive] 
         * @param {number} [toItemExclusive] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async searchPersons(search?: string, tagIds?: Array<string>, pageIndex?: number, pageSize?: number, withTotal?: boolean, fromItemInclusive?: number, toItemExclusive?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PersonModelPaginationResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.searchPersons(search, tagIds, pageIndex, pageSize, withTotal, fromItemInclusive, toItemExclusive, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['PersonApi.searchPersons']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Обновить контакт.
         * @param {string} id ИД контакта.
         * @param {PersonUpdatingRequest} [personUpdatingRequest] Запрос на обновление контакта.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updatePerson(id: string, personUpdatingRequest?: PersonUpdatingRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updatePerson(id, personUpdatingRequest, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['PersonApi.updatePerson']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Обновить список тегов контакта.
         * @param {string} id ИД контакта.
         * @param {PersonTagsUpdatingRequest} [personTagsUpdatingRequest] Запрос на обновление тегов контакта.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updatePersonTags(id: string, personTagsUpdatingRequest?: PersonTagsUpdatingRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updatePersonTags(id, personTagsUpdatingRequest, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['PersonApi.updatePersonTags']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
    }
};

/**
 * PersonApi - factory interface
 * @export
 */
export const PersonApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PersonApiFp(configuration)
    return {
        /**
         * 
         * @summary Создать контакт.
         * @param {PersonCreationRequest} [personCreationRequest] Запрос на создание контакта.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createPerson(personCreationRequest?: PersonCreationRequest, options?: any): AxiosPromise<PersonCreationResponse> {
            return localVarFp.createPerson(personCreationRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Удалить контакт.
         * @param {string} id ИД контакта.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deletePerson(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.deletePerson(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Получить контакт.
         * @param {string} id ИД контакта.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPerson(id: string, options?: any): AxiosPromise<PersonModel> {
            return localVarFp.getPerson(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Объединить контакты.
         * @param {PersonMergingRequest} [personMergingRequest] Запрос на объединение контактов.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mergePersons(personMergingRequest?: PersonMergingRequest, options?: any): AxiosPromise<void> {
            return localVarFp.mergePersons(personMergingRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Выполнить поиск контактов.
         * @param {string} [search] Текст.
         * @param {Array<string>} [tagIds] Список идентификаторов тэгов.
         * @param {number} [pageIndex] 
         * @param {number} [pageSize] 
         * @param {boolean} [withTotal] 
         * @param {number} [fromItemInclusive] 
         * @param {number} [toItemExclusive] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchPersons(search?: string, tagIds?: Array<string>, pageIndex?: number, pageSize?: number, withTotal?: boolean, fromItemInclusive?: number, toItemExclusive?: number, options?: any): AxiosPromise<PersonModelPaginationResponse> {
            return localVarFp.searchPersons(search, tagIds, pageIndex, pageSize, withTotal, fromItemInclusive, toItemExclusive, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Обновить контакт.
         * @param {string} id ИД контакта.
         * @param {PersonUpdatingRequest} [personUpdatingRequest] Запрос на обновление контакта.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePerson(id: string, personUpdatingRequest?: PersonUpdatingRequest, options?: any): AxiosPromise<void> {
            return localVarFp.updatePerson(id, personUpdatingRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Обновить список тегов контакта.
         * @param {string} id ИД контакта.
         * @param {PersonTagsUpdatingRequest} [personTagsUpdatingRequest] Запрос на обновление тегов контакта.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePersonTags(id: string, personTagsUpdatingRequest?: PersonTagsUpdatingRequest, options?: any): AxiosPromise<void> {
            return localVarFp.updatePersonTags(id, personTagsUpdatingRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PersonApi - object-oriented interface
 * @export
 * @class PersonApi
 * @extends {BaseAPI}
 */
export class PersonApi extends BaseAPI {
    /**
     * 
     * @summary Создать контакт.
     * @param {PersonCreationRequest} [personCreationRequest] Запрос на создание контакта.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PersonApi
     */
    public createPerson(personCreationRequest?: PersonCreationRequest, options?: RawAxiosRequestConfig) {
        return PersonApiFp(this.configuration).createPerson(personCreationRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Удалить контакт.
     * @param {string} id ИД контакта.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PersonApi
     */
    public deletePerson(id: string, options?: RawAxiosRequestConfig) {
        return PersonApiFp(this.configuration).deletePerson(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Получить контакт.
     * @param {string} id ИД контакта.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PersonApi
     */
    public getPerson(id: string, options?: RawAxiosRequestConfig) {
        return PersonApiFp(this.configuration).getPerson(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Объединить контакты.
     * @param {PersonMergingRequest} [personMergingRequest] Запрос на объединение контактов.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PersonApi
     */
    public mergePersons(personMergingRequest?: PersonMergingRequest, options?: RawAxiosRequestConfig) {
        return PersonApiFp(this.configuration).mergePersons(personMergingRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Выполнить поиск контактов.
     * @param {string} [search] Текст.
     * @param {Array<string>} [tagIds] Список идентификаторов тэгов.
     * @param {number} [pageIndex] 
     * @param {number} [pageSize] 
     * @param {boolean} [withTotal] 
     * @param {number} [fromItemInclusive] 
     * @param {number} [toItemExclusive] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PersonApi
     */
    public searchPersons(search?: string, tagIds?: Array<string>, pageIndex?: number, pageSize?: number, withTotal?: boolean, fromItemInclusive?: number, toItemExclusive?: number, options?: RawAxiosRequestConfig) {
        return PersonApiFp(this.configuration).searchPersons(search, tagIds, pageIndex, pageSize, withTotal, fromItemInclusive, toItemExclusive, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Обновить контакт.
     * @param {string} id ИД контакта.
     * @param {PersonUpdatingRequest} [personUpdatingRequest] Запрос на обновление контакта.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PersonApi
     */
    public updatePerson(id: string, personUpdatingRequest?: PersonUpdatingRequest, options?: RawAxiosRequestConfig) {
        return PersonApiFp(this.configuration).updatePerson(id, personUpdatingRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Обновить список тегов контакта.
     * @param {string} id ИД контакта.
     * @param {PersonTagsUpdatingRequest} [personTagsUpdatingRequest] Запрос на обновление тегов контакта.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PersonApi
     */
    public updatePersonTags(id: string, personTagsUpdatingRequest?: PersonTagsUpdatingRequest, options?: RawAxiosRequestConfig) {
        return PersonApiFp(this.configuration).updatePersonTags(id, personTagsUpdatingRequest, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * PersonAccountApi - axios parameter creator
 * @export
 */
export const PersonAccountApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Получить аккаунт сотрудника.
         * @param {string} id ИД аккаунта сотрудника.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPersonAccount: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getPersonAccount', 'id', id)
            const localVarPath = `/api/v1/accounts/persons/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth20 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth20", ["elma.assistant.resources.identity.context", "elma.assistant.scopes.api.admin", "email", "offline_access", "openid", "phone", "profile"], configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Выполнить поиск аккаунтов сотрудников.
         * @param {Array<string>} [channelIds] ИД каналов.
         * @param {Array<string>} [personIds] ИД сотрудников.
         * @param {number} [pageIndex] 
         * @param {number} [pageSize] 
         * @param {boolean} [withTotal] 
         * @param {number} [fromItemInclusive] 
         * @param {number} [toItemExclusive] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchPersonAccounts: async (channelIds?: Array<string>, personIds?: Array<string>, pageIndex?: number, pageSize?: number, withTotal?: boolean, fromItemInclusive?: number, toItemExclusive?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/accounts/persons`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth20 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth20", ["elma.assistant.resources.identity.context", "elma.assistant.scopes.api.admin", "email", "offline_access", "openid", "phone", "profile"], configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (channelIds) {
                localVarQueryParameter['ChannelIds'] = channelIds;
            }

            if (personIds) {
                localVarQueryParameter['PersonIds'] = personIds;
            }

            if (pageIndex !== undefined) {
                localVarQueryParameter['PageIndex'] = pageIndex;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['PageSize'] = pageSize;
            }

            if (withTotal !== undefined) {
                localVarQueryParameter['WithTotal'] = withTotal;
            }

            if (fromItemInclusive !== undefined) {
                localVarQueryParameter['FromItemInclusive'] = fromItemInclusive;
            }

            if (toItemExclusive !== undefined) {
                localVarQueryParameter['ToItemExclusive'] = toItemExclusive;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PersonAccountApi - functional programming interface
 * @export
 */
export const PersonAccountApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PersonAccountApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Получить аккаунт сотрудника.
         * @param {string} id ИД аккаунта сотрудника.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPersonAccount(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PersonAccountModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPersonAccount(id, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['PersonAccountApi.getPersonAccount']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Выполнить поиск аккаунтов сотрудников.
         * @param {Array<string>} [channelIds] ИД каналов.
         * @param {Array<string>} [personIds] ИД сотрудников.
         * @param {number} [pageIndex] 
         * @param {number} [pageSize] 
         * @param {boolean} [withTotal] 
         * @param {number} [fromItemInclusive] 
         * @param {number} [toItemExclusive] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async searchPersonAccounts(channelIds?: Array<string>, personIds?: Array<string>, pageIndex?: number, pageSize?: number, withTotal?: boolean, fromItemInclusive?: number, toItemExclusive?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PersonAccountModelPaginationResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.searchPersonAccounts(channelIds, personIds, pageIndex, pageSize, withTotal, fromItemInclusive, toItemExclusive, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['PersonAccountApi.searchPersonAccounts']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
    }
};

/**
 * PersonAccountApi - factory interface
 * @export
 */
export const PersonAccountApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PersonAccountApiFp(configuration)
    return {
        /**
         * 
         * @summary Получить аккаунт сотрудника.
         * @param {string} id ИД аккаунта сотрудника.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPersonAccount(id: string, options?: any): AxiosPromise<PersonAccountModel> {
            return localVarFp.getPersonAccount(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Выполнить поиск аккаунтов сотрудников.
         * @param {Array<string>} [channelIds] ИД каналов.
         * @param {Array<string>} [personIds] ИД сотрудников.
         * @param {number} [pageIndex] 
         * @param {number} [pageSize] 
         * @param {boolean} [withTotal] 
         * @param {number} [fromItemInclusive] 
         * @param {number} [toItemExclusive] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchPersonAccounts(channelIds?: Array<string>, personIds?: Array<string>, pageIndex?: number, pageSize?: number, withTotal?: boolean, fromItemInclusive?: number, toItemExclusive?: number, options?: any): AxiosPromise<PersonAccountModelPaginationResponse> {
            return localVarFp.searchPersonAccounts(channelIds, personIds, pageIndex, pageSize, withTotal, fromItemInclusive, toItemExclusive, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PersonAccountApi - object-oriented interface
 * @export
 * @class PersonAccountApi
 * @extends {BaseAPI}
 */
export class PersonAccountApi extends BaseAPI {
    /**
     * 
     * @summary Получить аккаунт сотрудника.
     * @param {string} id ИД аккаунта сотрудника.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PersonAccountApi
     */
    public getPersonAccount(id: string, options?: RawAxiosRequestConfig) {
        return PersonAccountApiFp(this.configuration).getPersonAccount(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Выполнить поиск аккаунтов сотрудников.
     * @param {Array<string>} [channelIds] ИД каналов.
     * @param {Array<string>} [personIds] ИД сотрудников.
     * @param {number} [pageIndex] 
     * @param {number} [pageSize] 
     * @param {boolean} [withTotal] 
     * @param {number} [fromItemInclusive] 
     * @param {number} [toItemExclusive] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PersonAccountApi
     */
    public searchPersonAccounts(channelIds?: Array<string>, personIds?: Array<string>, pageIndex?: number, pageSize?: number, withTotal?: boolean, fromItemInclusive?: number, toItemExclusive?: number, options?: RawAxiosRequestConfig) {
        return PersonAccountApiFp(this.configuration).searchPersonAccounts(channelIds, personIds, pageIndex, pageSize, withTotal, fromItemInclusive, toItemExclusive, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * QuickBPMApi - axios parameter creator
 * @export
 */
export const QuickBPMApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1QuickbpmPost: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/quickbpm`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * QuickBPMApi - functional programming interface
 * @export
 */
export const QuickBPMApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = QuickBPMApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1QuickbpmPost(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1QuickbpmPost(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['QuickBPMApi.apiV1QuickbpmPost']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
    }
};

/**
 * QuickBPMApi - factory interface
 * @export
 */
export const QuickBPMApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = QuickBPMApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1QuickbpmPost(options?: any): AxiosPromise<void> {
            return localVarFp.apiV1QuickbpmPost(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * QuickBPMApi - object-oriented interface
 * @export
 * @class QuickBPMApi
 * @extends {BaseAPI}
 */
export class QuickBPMApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof QuickBPMApi
     */
    public apiV1QuickbpmPost(options?: RawAxiosRequestConfig) {
        return QuickBPMApiFp(this.configuration).apiV1QuickbpmPost(options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * ScenarioTemplateApi - axios parameter creator
 * @export
 */
export const ScenarioTemplateApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Получить шаблон пустого сценария.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEmptyScenarioTemplate: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/runtime/simple/templates/scenario/empty`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth20 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth20", ["elma.assistant.resources.identity.context", "elma.assistant.scopes.api.admin", "email", "offline_access", "openid", "phone", "profile"], configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Получить шаблон сценария.
         * @param {string} id ИД шаблона сценария.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getScenarioTemplate: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getScenarioTemplate', 'id', id)
            const localVarPath = `/api/v1/runtime/simple/templates/scenario/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth20 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth20", ["elma.assistant.resources.identity.context", "elma.assistant.scopes.api.admin", "email", "offline_access", "openid", "phone", "profile"], configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Выполнить поиск шаблонов сценариев.
         * @param {string} [botTemplateId] Идентификатор шаблона бота, к которому относится шаблон сценария.
         * @param {string} [searchText] Текст для поиска шаблонов.
         * @param {number} [pageIndex] 
         * @param {number} [pageSize] 
         * @param {boolean} [withTotal] 
         * @param {number} [fromItemInclusive] 
         * @param {number} [toItemExclusive] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchScenarioTemplates: async (botTemplateId?: string, searchText?: string, pageIndex?: number, pageSize?: number, withTotal?: boolean, fromItemInclusive?: number, toItemExclusive?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/runtime/simple/templates/scenario`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth20 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth20", ["elma.assistant.resources.identity.context", "elma.assistant.scopes.api.admin", "email", "offline_access", "openid", "phone", "profile"], configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (botTemplateId !== undefined) {
                localVarQueryParameter['BotTemplateId'] = botTemplateId;
            }

            if (searchText !== undefined) {
                localVarQueryParameter['SearchText'] = searchText;
            }

            if (pageIndex !== undefined) {
                localVarQueryParameter['PageIndex'] = pageIndex;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['PageSize'] = pageSize;
            }

            if (withTotal !== undefined) {
                localVarQueryParameter['WithTotal'] = withTotal;
            }

            if (fromItemInclusive !== undefined) {
                localVarQueryParameter['FromItemInclusive'] = fromItemInclusive;
            }

            if (toItemExclusive !== undefined) {
                localVarQueryParameter['ToItemExclusive'] = toItemExclusive;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ScenarioTemplateApi - functional programming interface
 * @export
 */
export const ScenarioTemplateApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ScenarioTemplateApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Получить шаблон пустого сценария.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getEmptyScenarioTemplate(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SingleScenarioTemplateModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getEmptyScenarioTemplate(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['ScenarioTemplateApi.getEmptyScenarioTemplate']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Получить шаблон сценария.
         * @param {string} id ИД шаблона сценария.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getScenarioTemplate(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SingleScenarioTemplateModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getScenarioTemplate(id, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['ScenarioTemplateApi.getScenarioTemplate']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Выполнить поиск шаблонов сценариев.
         * @param {string} [botTemplateId] Идентификатор шаблона бота, к которому относится шаблон сценария.
         * @param {string} [searchText] Текст для поиска шаблонов.
         * @param {number} [pageIndex] 
         * @param {number} [pageSize] 
         * @param {boolean} [withTotal] 
         * @param {number} [fromItemInclusive] 
         * @param {number} [toItemExclusive] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async searchScenarioTemplates(botTemplateId?: string, searchText?: string, pageIndex?: number, pageSize?: number, withTotal?: boolean, fromItemInclusive?: number, toItemExclusive?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListScenarioTemplateModelPaginationResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.searchScenarioTemplates(botTemplateId, searchText, pageIndex, pageSize, withTotal, fromItemInclusive, toItemExclusive, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['ScenarioTemplateApi.searchScenarioTemplates']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
    }
};

/**
 * ScenarioTemplateApi - factory interface
 * @export
 */
export const ScenarioTemplateApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ScenarioTemplateApiFp(configuration)
    return {
        /**
         * 
         * @summary Получить шаблон пустого сценария.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEmptyScenarioTemplate(options?: any): AxiosPromise<SingleScenarioTemplateModel> {
            return localVarFp.getEmptyScenarioTemplate(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Получить шаблон сценария.
         * @param {string} id ИД шаблона сценария.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getScenarioTemplate(id: string, options?: any): AxiosPromise<SingleScenarioTemplateModel> {
            return localVarFp.getScenarioTemplate(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Выполнить поиск шаблонов сценариев.
         * @param {string} [botTemplateId] Идентификатор шаблона бота, к которому относится шаблон сценария.
         * @param {string} [searchText] Текст для поиска шаблонов.
         * @param {number} [pageIndex] 
         * @param {number} [pageSize] 
         * @param {boolean} [withTotal] 
         * @param {number} [fromItemInclusive] 
         * @param {number} [toItemExclusive] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchScenarioTemplates(botTemplateId?: string, searchText?: string, pageIndex?: number, pageSize?: number, withTotal?: boolean, fromItemInclusive?: number, toItemExclusive?: number, options?: any): AxiosPromise<ListScenarioTemplateModelPaginationResponse> {
            return localVarFp.searchScenarioTemplates(botTemplateId, searchText, pageIndex, pageSize, withTotal, fromItemInclusive, toItemExclusive, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ScenarioTemplateApi - object-oriented interface
 * @export
 * @class ScenarioTemplateApi
 * @extends {BaseAPI}
 */
export class ScenarioTemplateApi extends BaseAPI {
    /**
     * 
     * @summary Получить шаблон пустого сценария.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ScenarioTemplateApi
     */
    public getEmptyScenarioTemplate(options?: RawAxiosRequestConfig) {
        return ScenarioTemplateApiFp(this.configuration).getEmptyScenarioTemplate(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Получить шаблон сценария.
     * @param {string} id ИД шаблона сценария.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ScenarioTemplateApi
     */
    public getScenarioTemplate(id: string, options?: RawAxiosRequestConfig) {
        return ScenarioTemplateApiFp(this.configuration).getScenarioTemplate(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Выполнить поиск шаблонов сценариев.
     * @param {string} [botTemplateId] Идентификатор шаблона бота, к которому относится шаблон сценария.
     * @param {string} [searchText] Текст для поиска шаблонов.
     * @param {number} [pageIndex] 
     * @param {number} [pageSize] 
     * @param {boolean} [withTotal] 
     * @param {number} [fromItemInclusive] 
     * @param {number} [toItemExclusive] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ScenarioTemplateApi
     */
    public searchScenarioTemplates(botTemplateId?: string, searchText?: string, pageIndex?: number, pageSize?: number, withTotal?: boolean, fromItemInclusive?: number, toItemExclusive?: number, options?: RawAxiosRequestConfig) {
        return ScenarioTemplateApiFp(this.configuration).searchScenarioTemplates(botTemplateId, searchText, pageIndex, pageSize, withTotal, fromItemInclusive, toItemExclusive, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * TagApi - axios parameter creator
 * @export
 */
export const TagApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Создать тег.
         * @param {TagCreationRequest} [tagCreationRequest] Запрос на создание тега.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createTag: async (tagCreationRequest?: TagCreationRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/general/tags`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth20 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth20", ["elma.assistant.resources.identity.context", "elma.assistant.scopes.api.admin", "email", "offline_access", "openid", "phone", "profile"], configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(tagCreationRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Удалить тег.
         * @param {string} id ИД тега.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteTag: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteTag', 'id', id)
            const localVarPath = `/api/v1/general/tags/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth20 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth20", ["elma.assistant.resources.identity.context", "elma.assistant.scopes.api.admin", "email", "offline_access", "openid", "phone", "profile"], configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Получить все теги.
         * @param {string} [search] Текст.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllTags: async (search?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/general/tags/all`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth20 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth20", ["elma.assistant.resources.identity.context", "elma.assistant.scopes.api.admin", "email", "offline_access", "openid", "phone", "profile"], configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (search !== undefined) {
                localVarQueryParameter['Search'] = search;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Выполнить поиск тегов.
         * @param {string} [search] Текст.
         * @param {number} [pageIndex] 
         * @param {number} [pageSize] 
         * @param {boolean} [withTotal] 
         * @param {number} [fromItemInclusive] 
         * @param {number} [toItemExclusive] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchTags: async (search?: string, pageIndex?: number, pageSize?: number, withTotal?: boolean, fromItemInclusive?: number, toItemExclusive?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/general/tags`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth20 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth20", ["elma.assistant.resources.identity.context", "elma.assistant.scopes.api.admin", "email", "offline_access", "openid", "phone", "profile"], configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (search !== undefined) {
                localVarQueryParameter['Search'] = search;
            }

            if (pageIndex !== undefined) {
                localVarQueryParameter['PageIndex'] = pageIndex;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['PageSize'] = pageSize;
            }

            if (withTotal !== undefined) {
                localVarQueryParameter['WithTotal'] = withTotal;
            }

            if (fromItemInclusive !== undefined) {
                localVarQueryParameter['FromItemInclusive'] = fromItemInclusive;
            }

            if (toItemExclusive !== undefined) {
                localVarQueryParameter['ToItemExclusive'] = toItemExclusive;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Обновить тег.
         * @param {string} id ИД тега.
         * @param {TagUpdatingRequest} [tagUpdatingRequest] Запрос на обновление тега.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateTag: async (id: string, tagUpdatingRequest?: TagUpdatingRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateTag', 'id', id)
            const localVarPath = `/api/v1/general/tags/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth20 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth20", ["elma.assistant.resources.identity.context", "elma.assistant.scopes.api.admin", "email", "offline_access", "openid", "phone", "profile"], configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(tagUpdatingRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TagApi - functional programming interface
 * @export
 */
export const TagApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = TagApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Создать тег.
         * @param {TagCreationRequest} [tagCreationRequest] Запрос на создание тега.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createTag(tagCreationRequest?: TagCreationRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TagCreationResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createTag(tagCreationRequest, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TagApi.createTag']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Удалить тег.
         * @param {string} id ИД тега.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteTag(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteTag(id, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TagApi.deleteTag']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Получить все теги.
         * @param {string} [search] Текст.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllTags(search?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<TagModel>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllTags(search, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TagApi.getAllTags']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Выполнить поиск тегов.
         * @param {string} [search] Текст.
         * @param {number} [pageIndex] 
         * @param {number} [pageSize] 
         * @param {boolean} [withTotal] 
         * @param {number} [fromItemInclusive] 
         * @param {number} [toItemExclusive] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async searchTags(search?: string, pageIndex?: number, pageSize?: number, withTotal?: boolean, fromItemInclusive?: number, toItemExclusive?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TagModelPaginationResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.searchTags(search, pageIndex, pageSize, withTotal, fromItemInclusive, toItemExclusive, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TagApi.searchTags']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Обновить тег.
         * @param {string} id ИД тега.
         * @param {TagUpdatingRequest} [tagUpdatingRequest] Запрос на обновление тега.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateTag(id: string, tagUpdatingRequest?: TagUpdatingRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateTag(id, tagUpdatingRequest, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TagApi.updateTag']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
    }
};

/**
 * TagApi - factory interface
 * @export
 */
export const TagApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = TagApiFp(configuration)
    return {
        /**
         * 
         * @summary Создать тег.
         * @param {TagCreationRequest} [tagCreationRequest] Запрос на создание тега.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createTag(tagCreationRequest?: TagCreationRequest, options?: any): AxiosPromise<TagCreationResponse> {
            return localVarFp.createTag(tagCreationRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Удалить тег.
         * @param {string} id ИД тега.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteTag(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteTag(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Получить все теги.
         * @param {string} [search] Текст.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllTags(search?: string, options?: any): AxiosPromise<Array<TagModel>> {
            return localVarFp.getAllTags(search, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Выполнить поиск тегов.
         * @param {string} [search] Текст.
         * @param {number} [pageIndex] 
         * @param {number} [pageSize] 
         * @param {boolean} [withTotal] 
         * @param {number} [fromItemInclusive] 
         * @param {number} [toItemExclusive] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchTags(search?: string, pageIndex?: number, pageSize?: number, withTotal?: boolean, fromItemInclusive?: number, toItemExclusive?: number, options?: any): AxiosPromise<TagModelPaginationResponse> {
            return localVarFp.searchTags(search, pageIndex, pageSize, withTotal, fromItemInclusive, toItemExclusive, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Обновить тег.
         * @param {string} id ИД тега.
         * @param {TagUpdatingRequest} [tagUpdatingRequest] Запрос на обновление тега.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateTag(id: string, tagUpdatingRequest?: TagUpdatingRequest, options?: any): AxiosPromise<void> {
            return localVarFp.updateTag(id, tagUpdatingRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * TagApi - object-oriented interface
 * @export
 * @class TagApi
 * @extends {BaseAPI}
 */
export class TagApi extends BaseAPI {
    /**
     * 
     * @summary Создать тег.
     * @param {TagCreationRequest} [tagCreationRequest] Запрос на создание тега.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TagApi
     */
    public createTag(tagCreationRequest?: TagCreationRequest, options?: RawAxiosRequestConfig) {
        return TagApiFp(this.configuration).createTag(tagCreationRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Удалить тег.
     * @param {string} id ИД тега.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TagApi
     */
    public deleteTag(id: string, options?: RawAxiosRequestConfig) {
        return TagApiFp(this.configuration).deleteTag(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Получить все теги.
     * @param {string} [search] Текст.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TagApi
     */
    public getAllTags(search?: string, options?: RawAxiosRequestConfig) {
        return TagApiFp(this.configuration).getAllTags(search, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Выполнить поиск тегов.
     * @param {string} [search] Текст.
     * @param {number} [pageIndex] 
     * @param {number} [pageSize] 
     * @param {boolean} [withTotal] 
     * @param {number} [fromItemInclusive] 
     * @param {number} [toItemExclusive] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TagApi
     */
    public searchTags(search?: string, pageIndex?: number, pageSize?: number, withTotal?: boolean, fromItemInclusive?: number, toItemExclusive?: number, options?: RawAxiosRequestConfig) {
        return TagApiFp(this.configuration).searchTags(search, pageIndex, pageSize, withTotal, fromItemInclusive, toItemExclusive, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Обновить тег.
     * @param {string} id ИД тега.
     * @param {TagUpdatingRequest} [tagUpdatingRequest] Запрос на обновление тега.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TagApi
     */
    public updateTag(id: string, tagUpdatingRequest?: TagUpdatingRequest, options?: RawAxiosRequestConfig) {
        return TagApiFp(this.configuration).updateTag(id, tagUpdatingRequest, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * TenantApi - axios parameter creator
 * @export
 */
export const TenantApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCurrentTenant: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/runtime/tenants/current`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth20 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth20", ["elma.assistant.resources.identity.context", "elma.assistant.scopes.api.admin", "email", "offline_access", "openid", "phone", "profile"], configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TenantApi - functional programming interface
 * @export
 */
export const TenantApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = TenantApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCurrentTenant(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TenantModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCurrentTenant(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TenantApi.getCurrentTenant']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
    }
};

/**
 * TenantApi - factory interface
 * @export
 */
export const TenantApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = TenantApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCurrentTenant(options?: any): AxiosPromise<TenantModel> {
            return localVarFp.getCurrentTenant(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * TenantApi - object-oriented interface
 * @export
 * @class TenantApi
 * @extends {BaseAPI}
 */
export class TenantApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TenantApi
     */
    public getCurrentTenant(options?: RawAxiosRequestConfig) {
        return TenantApiFp(this.configuration).getCurrentTenant(options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * TestInstanceApi - axios parameter creator
 * @export
 */
export const TestInstanceApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Получить score для фраз в интенте.
         * @param {TestInstanceIntentScoresRequest} [testInstanceIntentScoresRequest] Запрос на получение score для фраз.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getIntentScores: async (testInstanceIntentScoresRequest?: TestInstanceIntentScoresRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/runtime/testinstances/intent-scores`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth20 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth20", ["elma.assistant.resources.identity.context", "elma.assistant.scopes.api.admin", "email", "offline_access", "openid", "phone", "profile"], configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(testInstanceIntentScoresRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Получить состояние тестового бота.
         * @param {string} [agentId] ИД агента.
         * @param {string} [botVersionId] ИД версии бота.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getstate: async (agentId?: string, botVersionId?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/runtime/testinstances/get-state`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth20 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth20", ["elma.assistant.resources.identity.context", "elma.assistant.scopes.api.admin", "email", "offline_access", "openid", "phone", "profile"], configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (agentId !== undefined) {
                localVarQueryParameter['AgentId'] = agentId;
            }

            if (botVersionId !== undefined) {
                localVarQueryParameter['BotVersionId'] = botVersionId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Запуск тестового бота.
         * @param {TestInstanceRunningRequest} [testInstanceRunningRequest] Запрос на запуск.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        run: async (testInstanceRunningRequest?: TestInstanceRunningRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/runtime/testinstances/run`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth20 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth20", ["elma.assistant.resources.identity.context", "elma.assistant.scopes.api.admin", "email", "offline_access", "openid", "phone", "profile"], configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(testInstanceRunningRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Выполнить поиск тестовых ботов.
         * @param {string} [agentId] ИД агента.
         * @param {string} [botVersionId] ИД версии бота.
         * @param {number} [pageIndex] 
         * @param {number} [pageSize] 
         * @param {boolean} [withTotal] 
         * @param {number} [fromItemInclusive] 
         * @param {number} [toItemExclusive] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchAgentTestInstances: async (agentId?: string, botVersionId?: string, pageIndex?: number, pageSize?: number, withTotal?: boolean, fromItemInclusive?: number, toItemExclusive?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/runtime/testinstances`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth20 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth20", ["elma.assistant.resources.identity.context", "elma.assistant.scopes.api.admin", "email", "offline_access", "openid", "phone", "profile"], configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (agentId !== undefined) {
                localVarQueryParameter['AgentId'] = agentId;
            }

            if (botVersionId !== undefined) {
                localVarQueryParameter['BotVersionId'] = botVersionId;
            }

            if (pageIndex !== undefined) {
                localVarQueryParameter['PageIndex'] = pageIndex;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['PageSize'] = pageSize;
            }

            if (withTotal !== undefined) {
                localVarQueryParameter['WithTotal'] = withTotal;
            }

            if (fromItemInclusive !== undefined) {
                localVarQueryParameter['FromItemInclusive'] = fromItemInclusive;
            }

            if (toItemExclusive !== undefined) {
                localVarQueryParameter['ToItemExclusive'] = toItemExclusive;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TestInstanceApi - functional programming interface
 * @export
 */
export const TestInstanceApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = TestInstanceApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Получить score для фраз в интенте.
         * @param {TestInstanceIntentScoresRequest} [testInstanceIntentScoresRequest] Запрос на получение score для фраз.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getIntentScores(testInstanceIntentScoresRequest?: TestInstanceIntentScoresRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TestInstanceIntentScoresResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getIntentScores(testInstanceIntentScoresRequest, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TestInstanceApi.getIntentScores']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Получить состояние тестового бота.
         * @param {string} [agentId] ИД агента.
         * @param {string} [botVersionId] ИД версии бота.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getstate(agentId?: string, botVersionId?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TestInstanceResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getstate(agentId, botVersionId, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TestInstanceApi.getstate']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Запуск тестового бота.
         * @param {TestInstanceRunningRequest} [testInstanceRunningRequest] Запрос на запуск.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async run(testInstanceRunningRequest?: TestInstanceRunningRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TestInstanceResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.run(testInstanceRunningRequest, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TestInstanceApi.run']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Выполнить поиск тестовых ботов.
         * @param {string} [agentId] ИД агента.
         * @param {string} [botVersionId] ИД версии бота.
         * @param {number} [pageIndex] 
         * @param {number} [pageSize] 
         * @param {boolean} [withTotal] 
         * @param {number} [fromItemInclusive] 
         * @param {number} [toItemExclusive] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async searchAgentTestInstances(agentId?: string, botVersionId?: string, pageIndex?: number, pageSize?: number, withTotal?: boolean, fromItemInclusive?: number, toItemExclusive?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AgentTestInstanceResponsePaginationResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.searchAgentTestInstances(agentId, botVersionId, pageIndex, pageSize, withTotal, fromItemInclusive, toItemExclusive, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TestInstanceApi.searchAgentTestInstances']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
    }
};

/**
 * TestInstanceApi - factory interface
 * @export
 */
export const TestInstanceApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = TestInstanceApiFp(configuration)
    return {
        /**
         * 
         * @summary Получить score для фраз в интенте.
         * @param {TestInstanceIntentScoresRequest} [testInstanceIntentScoresRequest] Запрос на получение score для фраз.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getIntentScores(testInstanceIntentScoresRequest?: TestInstanceIntentScoresRequest, options?: any): AxiosPromise<TestInstanceIntentScoresResponse> {
            return localVarFp.getIntentScores(testInstanceIntentScoresRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Получить состояние тестового бота.
         * @param {string} [agentId] ИД агента.
         * @param {string} [botVersionId] ИД версии бота.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getstate(agentId?: string, botVersionId?: string, options?: any): AxiosPromise<TestInstanceResponse> {
            return localVarFp.getstate(agentId, botVersionId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Запуск тестового бота.
         * @param {TestInstanceRunningRequest} [testInstanceRunningRequest] Запрос на запуск.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        run(testInstanceRunningRequest?: TestInstanceRunningRequest, options?: any): AxiosPromise<TestInstanceResponse> {
            return localVarFp.run(testInstanceRunningRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Выполнить поиск тестовых ботов.
         * @param {string} [agentId] ИД агента.
         * @param {string} [botVersionId] ИД версии бота.
         * @param {number} [pageIndex] 
         * @param {number} [pageSize] 
         * @param {boolean} [withTotal] 
         * @param {number} [fromItemInclusive] 
         * @param {number} [toItemExclusive] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchAgentTestInstances(agentId?: string, botVersionId?: string, pageIndex?: number, pageSize?: number, withTotal?: boolean, fromItemInclusive?: number, toItemExclusive?: number, options?: any): AxiosPromise<AgentTestInstanceResponsePaginationResponse> {
            return localVarFp.searchAgentTestInstances(agentId, botVersionId, pageIndex, pageSize, withTotal, fromItemInclusive, toItemExclusive, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * TestInstanceApi - object-oriented interface
 * @export
 * @class TestInstanceApi
 * @extends {BaseAPI}
 */
export class TestInstanceApi extends BaseAPI {
    /**
     * 
     * @summary Получить score для фраз в интенте.
     * @param {TestInstanceIntentScoresRequest} [testInstanceIntentScoresRequest] Запрос на получение score для фраз.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TestInstanceApi
     */
    public getIntentScores(testInstanceIntentScoresRequest?: TestInstanceIntentScoresRequest, options?: RawAxiosRequestConfig) {
        return TestInstanceApiFp(this.configuration).getIntentScores(testInstanceIntentScoresRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Получить состояние тестового бота.
     * @param {string} [agentId] ИД агента.
     * @param {string} [botVersionId] ИД версии бота.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TestInstanceApi
     */
    public getstate(agentId?: string, botVersionId?: string, options?: RawAxiosRequestConfig) {
        return TestInstanceApiFp(this.configuration).getstate(agentId, botVersionId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Запуск тестового бота.
     * @param {TestInstanceRunningRequest} [testInstanceRunningRequest] Запрос на запуск.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TestInstanceApi
     */
    public run(testInstanceRunningRequest?: TestInstanceRunningRequest, options?: RawAxiosRequestConfig) {
        return TestInstanceApiFp(this.configuration).run(testInstanceRunningRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Выполнить поиск тестовых ботов.
     * @param {string} [agentId] ИД агента.
     * @param {string} [botVersionId] ИД версии бота.
     * @param {number} [pageIndex] 
     * @param {number} [pageSize] 
     * @param {boolean} [withTotal] 
     * @param {number} [fromItemInclusive] 
     * @param {number} [toItemExclusive] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TestInstanceApi
     */
    public searchAgentTestInstances(agentId?: string, botVersionId?: string, pageIndex?: number, pageSize?: number, withTotal?: boolean, fromItemInclusive?: number, toItemExclusive?: number, options?: RawAxiosRequestConfig) {
        return TestInstanceApiFp(this.configuration).searchAgentTestInstances(agentId, botVersionId, pageIndex, pageSize, withTotal, fromItemInclusive, toItemExclusive, options).then((request) => request(this.axios, this.basePath));
    }
}



