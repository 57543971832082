import React, { ChangeEventHandler, useRef } from 'react';
import { RenderFunction } from 'antd/lib/_util/getRenderPropValue';

import './index.less';

import { getDefaultIfUndefined } from '../../../utils/typeUtil';
import IbIcon from '../common/IbIcon';
import IbPopover from '../common/IbPopover';

import {
  CLEAR_BUTTON_CLASS_NAME,
  FILTER_BUTTON_CLASS_NAME,
  MAIN_CLASS_NAME,
  WINDOW_CLASS_NAME,
  CONTENT_CLASS_NAME,
  INPUT_CLASS_NAME,
  CONTROL_CLASS_NAME,
} from './const';

export interface IIbSearchProps {
  placeholder?: string;
  searchValue?: string;
  popoverVisible?: boolean;
  onPopoverVisibleChange?: (value?: boolean) => void;
  popoverContent?: React.ReactNode | RenderFunction;
  popoverClassName?: string;
  onChange?: (searchValue: string) => void;
}

const SEARCH_VALUE_DEFAULT = '';

const IbSearch: React.FC<IIbSearchProps> = ({
  placeholder,
  searchValue = SEARCH_VALUE_DEFAULT,
  popoverVisible,
  onPopoverVisibleChange,
  popoverContent,
  popoverClassName,
  onChange,
}) => {
  searchValue = getDefaultIfUndefined(searchValue, SEARCH_VALUE_DEFAULT);
  const inputRef = useRef<HTMLInputElement>(null);

  const isEmpty = !searchValue;

  const focusToInput = () => {
    inputRef.current?.focus();
  };

  const onContentInputChange: ChangeEventHandler<HTMLInputElement> = (event) => {
    onChange?.(event.currentTarget.value);
  };

  const onClearButtonClick = () => {
    onChange?.(SEARCH_VALUE_DEFAULT);
    focusToInput();
  };

  const renderClearButton = () => {
    if (isEmpty) {
      return null;
    }

    return (
      <button className={CLEAR_BUTTON_CLASS_NAME} onClick={onClearButtonClick}>
        <IbIcon iconName="close" size={16} />
      </button>
    );
  };

  const renderFilterButton = () => {
    return (
      <div className={FILTER_BUTTON_CLASS_NAME}>
        <IbIcon iconName="filter" />
      </div>
    );
  };

  const renderWindow = () => {
    return (
      <div className={WINDOW_CLASS_NAME}>
        <div className={CONTENT_CLASS_NAME}>
          <div className={INPUT_CLASS_NAME}>
            <input
              ref={inputRef}
              placeholder={isEmpty ? placeholder : undefined}
              value={searchValue}
              onChange={onContentInputChange}
            />
            <span>{searchValue}</span>
          </div>
        </div>
      </div>
    );
  };

  const renderPopover = () => {
    return (
      <IbPopover
        className={popoverClassName}
        content={popoverContent}
        placement="bottomLeft"
        trigger={['click']}
        visible={popoverVisible}
        onVisibleChange={onPopoverVisibleChange}
      >
        {renderFilterButton()}
      </IbPopover>
    );
  };

  const renderControl = () => {
    return (
      <div className={CONTROL_CLASS_NAME}>
        {renderWindow()}
        {renderClearButton()}
        {popoverContent && renderPopover()}
      </div>
    );
  };

  return <div className={MAIN_CLASS_NAME}>{renderControl()}</div>;
};

export default IbSearch;
