import React from 'react';
import {
  CompositeDecorator,
  convertFromRaw,
  DraftDecorator,
  DraftHandleValue,
  EditorState,
  KeyBindingUtil,
  Modifier,
} from 'draft-js';
import { markdownToDraft } from 'markdown-draft-js';
import { findWithRegex } from '@draft-js-plugins/utils';

import { VariableSchema } from '../../../../../api';
import { getImageDecorator, getLinkDecorator } from '../../../../utils/markdownEditor';

import { EntityType } from './types';

const variablesRegex = new RegExp('{.*?}', 'gi');
const imageAndOneSymbolRegex = new RegExp('!\\[.*?]\\(.*?\\).', 'gi');

const { isCtrlKeyCommand } = KeyBindingUtil;

const getDecorators = (variables: VariableSchema[]): DraftDecorator[] => {
  const variableDecorator: DraftDecorator = {
    strategy: (contentBlock, callback) => {
      // eslint-disable-next-line security/detect-non-literal-regexp
      findWithRegex(new RegExp(variablesRegex, 'gi'), contentBlock, callback);
    },

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    component: (props: any) => {
      const variableId = props.decoratedText.replace('{', '').replace('}', '');
      const variable = variables.find((v) => v.id === variableId);
      return variable ? (
        <span className="variable-container" contentEditable={false} data-offset-key={props.offsetKey}>
          &nbsp;{variable.name}&nbsp;
        </span>
      ) : (
        props.children
      );
    },
  };

  return [getLinkDecorator(), getImageDecorator(), variableDecorator];
};

export const markdownToEditorState = (markdown: string, variables: VariableSchema[]): EditorState => {
  // HACK: чтобы не падали сценарии, в которых картинки в ответах бота могли быть добавлены не через радактор
  const preparedMarkdown = markdown.replaceAll(imageAndOneSymbolRegex, (substring) =>
    substring.endsWith(' ') ? substring : `${substring.slice(0, -1)} ${substring.slice(-1)}`
  );
  const draft = markdownToDraft(preparedMarkdown);
  let contentState = convertFromRaw(draft);
  const editorState = EditorState.createWithContent(contentState);
  const currentSelectionState = editorState.getSelection();

  for (const block of contentState.getBlocksAsArray()) {
    const text = block.getText();
    const matchAll = Array.from(text.matchAll(variablesRegex));
    for (const match of matchAll) {
      const contentStateWithEntity = contentState.createEntity(EntityType.VARIABLE, 'IMMUTABLE');
      const entityKey = contentStateWithEntity.getLastCreatedEntityKey();

      const selectionState = currentSelectionState.merge({
        hasFocus: false,
        focusKey: block.getKey(),
        anchorKey: block.getKey(),
        anchorOffset: match.index,
        focusOffset: (match.index || 0) + match[0].length,
      });

      contentState = Modifier.replaceText(contentState, selectionState, match[0], undefined, entityKey);
    }

    if (text.endsWith('}')) {
      contentState = Modifier.insertText(contentState, contentState.getSelectionAfter(), ' ');
    }
  }

  return EditorState.createWithContent(contentState, new CompositeDecorator(getDecorators(variables)));
};

export const insertEntity = (editorState: EditorState, value: string, type: EntityType): EditorState => {
  const contentState = editorState.getCurrentContent();
  const contentStateWithEntity = contentState.createEntity(type, 'IMMUTABLE');
  const entityKey = contentStateWithEntity.getLastCreatedEntityKey();
  const currentSelectionState = editorState.getSelection();

  const afterRemovalContentState = Modifier.removeRange(contentState, currentSelectionState, 'backward');

  const targetSelection = afterRemovalContentState.getSelectionAfter();

  let resultContentState = Modifier.insertText(afterRemovalContentState, targetSelection, value, undefined, entityKey);
  resultContentState = Modifier.insertText(resultContentState, resultContentState.getSelectionAfter(), ' ');

  const newEditorState = EditorState.push(editorState, resultContentState, 'insert-fragment');
  return EditorState.forceSelection(newEditorState, resultContentState.getSelectionAfter());
};

const isCursorOnVariable = (editorState: EditorState) => {
  const selection = editorState.getSelection();
  const contentState = editorState.getCurrentContent();
  const block = contentState.getBlockForKey(selection.getAnchorKey());

  const entityKey = block.getEntityAt(selection.getAnchorOffset());
  if (!entityKey) return false;

  const entity = contentState.getEntity(entityKey);
  return entity.getType() === EntityType.VARIABLE;
};

export const handleEditorBeforeInput = (setEditorState: (editorState: EditorState) => void) => (
  chars: string,
  editorState: EditorState
): DraftHandleValue => {
  if (!isCursorOnVariable(editorState)) return 'not-handled';

  let contentState = editorState.getCurrentContent();
  contentState = Modifier.insertText(contentState, contentState.getSelectionAfter(), chars);

  const newEditorState = EditorState.push(editorState, contentState, 'insert-fragment');
  setEditorState(EditorState.forceSelection(newEditorState, newEditorState.getSelection()));
  return 'handled';
};

export const handleEditorPastedText = (setEditorState: (editorState: EditorState) => void) => (
  text: string,
  html: string | undefined,
  editorState: EditorState
): DraftHandleValue => {
  return handleEditorBeforeInput(setEditorState)(text, editorState);
};

export const handleEditorReturn = (setEditorState: (editorState: EditorState) => void, onBlur: () => void) => (
  e: React.KeyboardEvent,
  editorState: EditorState
): DraftHandleValue => {
  if (isCtrlKeyCommand(e)) {
    onBlur();
    return 'handled';
  }

  if (!isCursorOnVariable(editorState)) return 'not-handled';

  let contentState = editorState.getCurrentContent();
  contentState = Modifier.splitBlock(contentState, contentState.getSelectionAfter());

  const newEditorState = EditorState.push(editorState, contentState, 'split-block');
  setEditorState(EditorState.forceSelection(newEditorState, newEditorState.getSelection()));
  return 'handled';
};
